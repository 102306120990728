import { useContext, useEffect, useLayoutEffect, useRef } from "react";
import styled, { css, useTheme } from "styled-components";
import Button, { ButtonLink } from "../../../components/Button/Button";
import ProfileIcon from "../../../assets/placeholders/profile.png";
import {
	HoverMenu,
	HoverMenuLink,
	LinkLabel,
	ExpandMenu,
} from "../../../components/HoverMenu/HoverMenu";
import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { getCompanyRequestCount } from "../../Company/manageCompany";
import { Link, NavLink, useLocation } from "react-router-dom";
import { logout } from "../../../components/Auth/handleJWT";
import { IconWrapper } from "../../../components/Icon/Icon";
import Icon from "../../../components/Icon/Icon";
import { DynamicTransitionDuration } from "../../../globalStyles";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import {
	getCartCount,
	getCompanyOrders,
	getDraftEntryCards,
	getDraftEntryCount,
	getOrderCount,
} from "../../Checkout/manageCheckout";
import { OrderModel } from "../../Checkout/OrderInterfaces";
import { programOptions } from "../../Entries/EntriesTestData";
import Authorized from "../../../components/Auth/Authorized";
import { JurorCardConfig } from "../Judging/JudgingInterfaces";
import { getJurors } from "./managerUser";
import JudgeNowButton from "../../Judging/JudgeNowButton";
import { ActiveBar } from "../../Judging/JudgingGalleryNav";
import { UserPageHeader } from "../../../components/UserPageTemplate/UserPageTemplate";
import { getDraftEntries } from "../../Entries/manageEntry";
import { CheckoutEntryCardAlertContext } from "../../Checkout/CheckoutContext";
import assetsConfig, { gradients } from "../../../assetsConfig";
import HamburgerIcon from "../../../assets/hamburger_menu.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const MOBILE_MENU_BREAKPOINT = 1150;

const CustomActiveButton = styled(({ isActive, ...props }) => (
	<Button {...props} />
))<{
	isActive?: boolean;
}>`
	position: relative;
	// overflow: hidden;

	${(props) =>
		props.isActive &&
		css`
			color: ${({ theme }) => theme.colorCopyLightLight};

			&::after {
				content: "";
				position: absolute;
				bottom: -5px;
				left: 0;
				z-index: 10;
				height: 5px;
				width: 100%;
				background: ${({ theme }) => theme.colorActivation};
				transition: all ${DynamicTransitionDuration} ease;
			}
		`}

	${(props) =>
		!props.isActive &&
		css`
			color: ${({ theme }) => theme.colorCopyLight};
		`}
`;

export const HamburgerMenu = styled.img`
	width: 40px;
	height: 40px;
	margin: 1rem 1rem 1rem auto;
	cursor: pointer;
`;

const TopNavbar = styled.div`
	display: flex;
	flex-direction: column;
	background: ${({ theme }) => theme.colorBackgroundDarkDark};

	.topnav-wrapper {
		position: fixed;
		display: flex;
		flex-direction: column;
		width: 80vw;
		top: 1rem;
		right: 1rem;
		/* background: ${({ theme }) => theme.colorBackgroundDark}; */
		background: ${gradients.mobileMenu};
		border-bottom: 5px solid ${({ theme }) => theme.colorActivationDark};
		/* height: 844px; */
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);

		/* mobile nav toggle animation  */
		&.click-user-nav-enter {
			opacity: 0;
			transform-origin: top right;
			transform: scale(95%);
		}
		&.click-user-nav-enter-active {
			opacity: 1;
			transform-origin: top right;
			transform: scale(100%);
			transition: all 150ms ease;
		}
		&.click-user-nav-exit {
			opacity: 1;
			transform-origin: top right;
			transform: scale(100%);
		}
		&.click-user-nav-exit-active {
			opacity: 0;
			transform-origin: top right;
			transform: scale(95%);
			transition: all 150ms ease;
		}

		.topnav-section {
			display: flex;

			button {
				position: relative;
			}
		}

		.HeaderColor {
			color: ${({ theme }) => theme.colorCopyLightLight};
			font-size: 18px;
		}
	}

	// mobile nav
	@media only screen and (max-width: calc(${MOBILE_MENU_BREAKPOINT}px - 1px)) {
		.only-desktop {
			display: none;
		}

		.topnav-wrapper {
			position: fixed;
			flex-direction: column;
			width: 80vw;
			top: 1rem;
			right: 1rem;
			max-width: 390px;
			padding-top: 2rem;
			/* padding-bottom: 5rem; */
			z-index: 99999;

			.topnav-section {
				flex-direction: column;
				.credit-lib-button {
					button {
						width: 100%;
						justify-content: flex-start;
					}
				}

				.mobile {
					display: flex;
					justify-content: space-between;
					align-items: center;
					/* padding-right: 2.5rem; */
					border-bottom: 5px solid ${({ theme }) => theme.colorBorderDark};

					button {
						box-shadow: none;
					}

					.order-counter-mobile {
						order: 99;
					}
				}

				& > button:not(.new-entry-button) {
					justify-content: flex-start;
					border-bottom: 5px solid ${({ theme }) => theme.colorBorderDark};
				}

				.credit-lib {
					border-bottom: 5px solid ${({ theme }) => theme.colorBorderDark};
				}
			}

			.profile-icon {
				display: none;
			}

			.new-entry-button {
				width: 200px;
				margin: 0 auto;
				margin-top: 3rem;
				border-bottom: transparent;
				order: 99;

				span.icon-left {
					width: unset;
					min-width: unset;
					margin-right: 0.5rem;
				}
			}

			button {
				// aligns button text regardless of icon size
				span.icon-left {
					width: 35px;
					min-width: 35px;
					margin-right: 1rem;
				}

				/* blue underline for current page  */
				&.active::after {
					content: "";
					position: absolute;
					bottom: -5px;
					left: 2.5rem;
					width: 50px;
					border-bottom: 5px solid ${({ theme }) => theme.colorActivation} !important;
				}
			}
		}
	}

	// show desktop nav
	@media only screen and (min-width: ${MOBILE_MENU_BREAKPOINT}px) {
		/* height: 75px; */

		.only-mobile {
			display: none;
		}

		.topnav-wrapper {
			position: relative;
			flex-direction: row;
			top: 0;
			right: 0;
			justify-content: space-between;
			width: 100%;
			height: auto;
			background: ${({ theme }) => theme.colorBackgroundDarkDark};

			.topnav-section {
				flex-direction: row;

				&.left button {
					border-right: 5px solid ${({ theme }) => theme.colorBorderDark};
				}

				&.right {
					align-items: center;
				}

				.profile-icon {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 75px;
					height: 75px;
					cursor: pointer;
					background: ${({ theme }) => theme.colorBackgroundDarkDark};
				}

				.new-entry-button {
					margin-right: 2.1875rem;
				}
			}
		}

		&.click-user-nav-enter,
		&.click-user-nav-active {
			opacity: 1;
			transform: scale(100%);
		}
	}
`;

export const OrderCounter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${({ theme }) => theme.colorBackgroundMedium};
	color: ${({ theme }) => theme.colorCopyLightLight};
	font-size: ${({ theme }) => theme.xSmallSize};
	border: 1px solid ${({ theme }) => theme.colorBorder};
	padding: 6px;
	min-width: 30px;
`;

const UserMenu = ({ title }: { title?: string | React.ReactNode }) => {
	const { settings } = useSelector((state: RootState) => state.settings);
	const theme = useTheme();
	const { alertCounter } = useContext(CheckoutEntryCardAlertContext);
	const { user } = useCurrentUser();
	const [showNav, setShowNav] = useState(false);
	const [requestCount, setRequestCount] = useState();
	const [companyOrderCount, setCompanyOrderCount] = useState<number>();
	const [draftEntriesCount, setDraftEntriesCount] = useState<number>();
	const [cartCount, setCartCount] = useState<number>();
	const [ordersCount, setOrdersCount] = useState<number>();

	// mobile dropdown
	const [showDropdown, setShowDropdown] = useState({
		entries: false,
		alerts: false,
		profile: false,
		creditLibrary: false,
	});

	// active page link for mobile
	const [activeLinks, setActiveLinks] = useState({
		mediaLibrary: false,
		creditLibrary: false,
		programs: false,
		admin: false,
		tags: false,
	});

	// expand menu for mobile
	const [expandMobileLink, setExpandMobileLink] = useState({
		entries: false,
		alerts: false,
		profile: false,
		account: false,
	});

	const [jurors, setJurors] = useState<JurorCardConfig[]>([]);
	const location = useLocation();

	const toggleMobileLink = (link: string) => {
		const obj: any = {}; // temp obj
		Object.entries(expandMobileLink).forEach(([button, isOpen]) => {
			// only toggle "link"
			button === link ? (obj[button] = !isOpen) : (obj[button] = false);
		});
		setExpandMobileLink(obj);
	};

	const handleActiveLink = (activeLink: string) => {
		const obj: any = {}; // temp obj
		Object.entries(activeLinks).forEach(([button, isActive]) => {
			// only toggle active link
			button === activeLink ? (obj[button] = !isActive) : (obj[button] = false);
		});
		setActiveLinks(obj);
	};

	useEffect(() => {
		getJurors().then((response) => {
			if (response.status === 200) {
				setJurors(response.data);
			}
		});
	}, []);

	const nodeRef = useRef(null);

	const toggleNav = () => {
		if (window.innerWidth >= MOBILE_MENU_BREAKPOINT) {
			setShowNav(true);
		} else {
			setShowNav(false);
		}
	};

	// show desktop nav when screen is greater than breakpoint
	useEffect(() => {
		const onResize = (event: any) => {
			toggleNav();
		};
		window.addEventListener("resize", onResize);

		return () => {
			window.removeEventListener("scroll", onResize);
		};
	}, []);

	useEffect(() => {
		toggleNav();
	}, []);

	useEffect(() => {
		getCompanyOrders()
			.then((response) => {
				if (response.data.length > 0) {
					setCompanyOrderCount(response.data.length);
				}
			})
			.catch((error) => {
				// console.log("getCompanyOrders ERROR", error);
			});
	}, []);

	/* Update drafts, cart, and order counter */
	const updateCounters = () => {
		getDraftEntryCount()
			.then((response) => {
				if (response.status === 200) {
					setDraftEntriesCount(response.data);
				}
			})
			.catch((error) => {
				console.log("getEntriesCount ERROR", error);
			});

		getCartCount()
			.then((response) => {
				if (response.status === 200) {
					setCartCount(response.data);
				}
			})
			.catch((error) => {
				console.log("getCartCount ERROR", error);
			});

		getOrderCount()
			.then((response) => {
				if (response.status === 200) {
					setOrdersCount(response.data);
				}
			})
			.catch((error) => {
				console.log("getOrderCount ERROR", error);
			});
	};

	useEffect(() => {
		updateCounters();
	}, [alertCounter]);

	return (
		<TopNavbar>
			<HamburgerMenu
				className="only-mobile"
				src={HamburgerIcon}
				alt="Hamburger Icon"
				onClick={() => setShowNav(!showNav)}
			/>

			<CSSTransition
				in={showNav}
				timeout={150}
				unmountOnExit
				classNames="click-user-nav"
				nodeRef={nodeRef}
			>
				<div className="topnav-wrapper">
					<Icon
						icon="close"
						color={theme.colorCopyLightLight}
						className="only-mobile inline mt-0 mr-[1rem] mb-[1.8rem] ml-auto"
						onClick={() => setShowNav(!showNav)}
					/>

					<div className="topnav-section left">
						<CustomActiveButton
							isActive={location.pathname === "/programs"}
							onClick={() => handleActiveLink("programs")}
							className={`programs button-transparent ${
								activeLinks.programs ? "active" : ""
							}`}
							iconColor={theme.colorPrimary}
							icon="home"
							to="/programs"
							iconSize="25px"
						>
							{assetsConfig.labels.allPrograms.singular}
						</CustomActiveButton>
						<CustomActiveButton
							isActive={location.pathname === "/media"}
							onClick={() => handleActiveLink("mediaLibrary")}
							className={`media-lib button-transparent ${
								activeLinks.mediaLibrary ? "active" : ""
							}`}
							iconColor={theme.colorPrimary}
							icon="media-library"
							to="/media"
							iconSize="25px"
						>
							Media
						</CustomActiveButton>
						<div
							className="credit-lib-button relative"
							onMouseOver={() =>
								setShowDropdown({
									...showDropdown,
									creditLibrary: true,
								})
							}
							onMouseLeave={() =>
								setShowDropdown({ ...showDropdown, creditLibrary: false })
							}
						>
							<CustomActiveButton
								isActive={
									location.pathname === "/credits/company" ||
									location.pathname === "/credits/individual"
								}
								//   onClick={() => handleActiveLink("creditLibrary")}
								className={`credit-lib h-full button-transparent ${
									activeLinks.creditLibrary ? "active" : ""
								}`}
								iconColor={theme.colorPrimary}
								icon="credit-db"
								iconSize="25px"
							>
								Credits
							</CustomActiveButton>
						</div>

						<div
							className="relative"
							onMouseOver={() =>
								setShowDropdown({
									...showDropdown,
									creditLibrary: true,
								})
							}
							onMouseLeave={() =>
								setShowDropdown({ ...showDropdown, creditLibrary: false })
							}
						>
							<CSSTransition
								in={showDropdown.creditLibrary}
								timeout={150}
								unmountOnExit
								classNames="hover-menu"
							>
								<HoverMenu>
									<HoverMenuLink>
										<Link className="no-underline" to="/credits/company">
											<LinkLabel>Company Credits</LinkLabel>
										</Link>
									</HoverMenuLink>
									<HoverMenuLink>
										<Link className="no-underline" to="/credits/individual">
											<LinkLabel>Individual Credits</LinkLabel>
										</Link>
									</HoverMenuLink>
								</HoverMenu>
							</CSSTransition>
						</div>

						<CustomActiveButton
							isActive={location.pathname === "/tags"}
							onClick={() => handleActiveLink("tags")}
							className={`button-transparent ${
								activeLinks.programs ? "active" : ""
							}`}
							iconColor={theme.colorPrimary}
							icon="tag"
							to="/tags"
							iconSize="20px"
						>
							Tags
						</CustomActiveButton>

						<Authorized
							role="admin"
							authorized={
								<CustomActiveButton
									isActive={location.pathname === "/admin"}
									onClick={() => handleActiveLink("admin")}
									className={`button-transparent ${
										activeLinks.programs ? "active" : ""
									}`}
									iconColor={theme.colorPrimary}
									icon="info"
									to={settings.isDemo ? "/review" : "/library/entries"}
									iconSize="25px"
								>
									Admin
								</CustomActiveButton>
							}
						/>
					</div>

					<div className="topnav-section right">
						{jurors && jurors.length > 0 && <JudgeNowButton jurors={jurors} />}

						<div
							className="mobile relative h-full"
							onClick={() =>
								setShowDropdown({
									...showDropdown,
									entries: !showDropdown.entries,
								})
							}
							onMouseLeave={() =>
								setShowDropdown({ ...showDropdown, entries: false })
							}
						>
							<ButtonLink
								onClick={() => {
									toggleMobileLink("entries");
									handleActiveLink("entries");
								}}
								className={`h-full`}
								icon="entries"
								iconColor={theme.colorButtonLight}
								// onClick={() => setExpandMobileLink({ ...expandMobileLink, entries: !expandMobileLink.entries })}
							>
								{/* <span className="only-desktop mr-1">5 </span> <span>Entries</span> */}
								<span>{assetsConfig.labels.entry.plural}</span>
								{/* {companyOrderCount && (
                  <OrderCounter className="ml-[1rem] xxl:!hidden">
                    {companyOrderCount}
                  </OrderCounter>
                )} */}

								<Icon
									icon="caret"
									color={theme.colorCopyLightLight}
									className={`ml-auto lg:ml-[1rem] ${
										expandMobileLink.entries && "open"
									} `}
									rotation={showDropdown.entries ? "0" : "180deg"}
								/>
								{/* <StyledCaretDown
                  className={`ml-auto xxl:ml-[1rem] ${
                    expandMobileLink.entries && "open"
                  } `}
                /> */}

								{/* <IconWrapper
									color={theme.colorPrimary}
									height="21px"
									width="21px"
									className="caret-down"
								>
									<StyledCaretDown
										isCollapsed={true}
										// onClick={() => setCollapse(!collapse)}
									/>
								</IconWrapper> */}

								{/* <Icon
									icon="caret-down"
									color={theme.colorCopyLightLight}
									height="21px"
									width="21px"
									className="caret-down"
									rotation={showDropdown.entries ? "0" : "180deg"}
								/> */}
							</ButtonLink>

							<CSSTransition
								in={showDropdown.entries}
								timeout={150}
								unmountOnExit
								classNames="hover-menu"
							>
								<HoverMenu className="only-desktop">
									<HoverMenuLink>
										<Link className="no-underline" to="/programs">
											<LinkLabel>
												New {assetsConfig.labels.entry.singular}
											</LinkLabel>
										</Link>
									</HoverMenuLink>
									<HoverMenuLink>
										<Link className="no-underline" to="/entries/draft-entries">
											<LinkLabel>
												Draft {assetsConfig.labels.entry.plural}
											</LinkLabel>
										</Link>
										{draftEntriesCount ? (
											<OrderCounter>{draftEntriesCount}</OrderCounter>
										) : null}
									</HoverMenuLink>
									<HoverMenuLink>
										<Link className="no-underline" to="/entries/cart">
											<LinkLabel>
												{settings.isDemo ? "Ready to Submit" : "My Cart"}
											</LinkLabel>
										</Link>
										{cartCount ? (
											<OrderCounter>{cartCount}</OrderCounter>
										) : null}
									</HoverMenuLink>
									{!settings.isDemo && (
										<HoverMenuLink>
											<Link className="no-underline" to="/entries/orders">
												<LinkLabel>Orders</LinkLabel>
											</Link>
											{ordersCount ? (
												<OrderCounter>{ordersCount}</OrderCounter>
											) : null}
										</HoverMenuLink>
									)}
									<HoverMenuLink>
										<Link className="no-underline" to="/entries/entries-search">
											<LinkLabel>
												{assetsConfig.labels.entry.plural} Search
											</LinkLabel>
										</Link>
									</HoverMenuLink>
								</HoverMenu>
							</CSSTransition>
						</div>

						{/* ENTRIES dropdown */}
						<CSSTransition
							in={expandMobileLink.entries}
							timeout={150}
							unmountOnExit
							classNames="expand-menu"
						>
							<ExpandMenu className="mobile-hover-menu only-mobile">
								{/* <HoverMenuLink className="sub-link">
                  <LinkLabel>Entry 1</LinkLabel>
                </HoverMenuLink>
                <HoverMenuLink className="sub-link">
                  <LinkLabel>Entry 2</LinkLabel>
                </HoverMenuLink> */}
								<HoverMenuLink className="first-link sub-link">
									<Link className="no-underline" to="/programs">
										<LinkLabel>
											New {assetsConfig.labels.entry.singular}
										</LinkLabel>
									</Link>
								</HoverMenuLink>
								<HoverMenuLink className="sub-link">
									<Link className="no-underline" to="/entries/draft-entries">
										<LinkLabel>
											Draft {assetsConfig.labels.entry.plural}
										</LinkLabel>
									</Link>

									{draftEntriesCount ? (
										<OrderCounter>{draftEntriesCount}</OrderCounter>
									) : null}
								</HoverMenuLink>
								<HoverMenuLink className="sub-link">
									<Link className="no-underline" to="/entries/cart">
										<LinkLabel>
											{settings.isDemo ? "Ready to Submit" : "My Cart"}
										</LinkLabel>
									</Link>
									{cartCount ? <OrderCounter>{cartCount}</OrderCounter> : null}
								</HoverMenuLink>
								{!settings.isDemo && (
									<HoverMenuLink className="sub-link">
										<Link className="no-underline" to="/entries/orders">
											<LinkLabel>Orders</LinkLabel>
										</Link>
										{ordersCount ? (
											<OrderCounter>{draftEntriesCount}</OrderCounter>
										) : null}
									</HoverMenuLink>
								)}
								<HoverMenuLink className="sub-link">
									<Link className="no-underline" to="/entries/entries-search">
										<LinkLabel>
											{assetsConfig.labels.entry.plural} Search
										</LinkLabel>
									</Link>
								</HoverMenuLink>
							</ExpandMenu>
						</CSSTransition>

						{/* <div
              className="mobile relative h-full"
              onClick={() =>
                setShowDropdown({
                  ...showDropdown,
                  alerts: !showDropdown.alerts,
                })
              }
              onMouseLeave={() => setShowDropdown({ ...showDropdown, alerts: false })}
            >
              <ButtonLink
                onClick={() => {
                  toggleMobileLink("alerts");
                  handleActiveLink("alerts");
                }}
                className={`h-full`}
                icon="alerts"
                iconColor={theme.colorButtonLight}
                // onClick={() => setExpandMobileLink({ ...expandMobileLink, alerts: !expandMobileLink.alerts })}
              >
                <span className="only-desktop mr-1">{requestCount > 0 ? requestCount : ""} </span> <span>Alerts</span>
                {requestCount > 0 ? <OrderCounter className="ml-[1rem] xxl:!hidden">{requestCount}</OrderCounter> : ""}
                <StyledCaretDown className={`ml-auto xxl:ml-[1rem] ${expandMobileLink.alerts && "open"} `} />
              </ButtonLink>

              <CSSTransition in={showDropdown.alerts} timeout={150} unmountOnExit classNames="hover-menu">
                <HoverMenu className="only-desktop">
                  <HoverMenuLink>
                    <LinkLabel>Alert 1</LinkLabel>
                  </HoverMenuLink>
                  <HoverMenuLink>
                    <LinkLabel>Alert 2</LinkLabel>
                  </HoverMenuLink>
                  <HoverMenuLink>
                    <LinkLabel>Alert 3</LinkLabel>
                  </HoverMenuLink>
                </HoverMenu>
              </CSSTransition>
            </div> */}

						{/* ALERTS dropdown */}
						{/* <CSSTransition in={expandMobileLink.alerts} timeout={150} unmountOnExit classNames="expand-menu">
              <ExpandMenu className="mobile-hover-menu only-mobile">
                <HoverMenuLink className="sub-link">
                  <LinkLabel>Alert 1</LinkLabel>
                </HoverMenuLink>
                <HoverMenuLink className="sub-link">
                  <LinkLabel>Alert 2</LinkLabel>
                </HoverMenuLink>
              </ExpandMenu>
            </CSSTransition> */}

						{/* MY ACCOUNT container */}
						<div
							className="relative"
							onClick={() =>
								setShowDropdown({
									...showDropdown,
									profile: !showDropdown.profile,
								})
							}
							onMouseLeave={() =>
								setShowDropdown({ ...showDropdown, profile: false })
							}
						>
							<div className="mobile h-full only-mobile">
								<ButtonLink
									onClick={() => {
										toggleMobileLink("account");
										handleActiveLink("account");
									}}
									className={`h-full`}
									icon="user"
									iconColor={theme.colorButtonLight}
									// onClick={() => setExpandMobileLink({ ...expandMobileLink, account: !expandMobileLink.account })}
								>
									<p>My Account</p>
									{/* {companyOrderCount && (
                    <OrderCounter className="ml-[1rem]">
                      {companyOrderCount}
                    </OrderCounter>
                  )} */}

									<Icon
										icon="caret"
										color={theme.colorCopyLightLight}
										className={`ml-auto ${expandMobileLink.account && "open"} `}
										rotation={showDropdown.profile ? "0" : "180deg"}
									/>
									{/* <StyledCaretDown
                    className={`ml-auto ${expandMobileLink.account && "open"} `}
                  /> */}
								</ButtonLink>
							</div>
							<div
								className="relative only-desktop"
								onMouseOver={() =>
									setShowDropdown({ ...showDropdown, profile: true })
								}
								onMouseLeave={() =>
									setShowDropdown({ ...showDropdown, profile: false })
								}
								// onClick={() => navigate('/sample', {replace: true}}
							>
								<div className="profile-icon">
									{user?.profileUrl ? (
										<img
											className="h-full object-cover absolute background_image"
											src={user.profileUrl}
											alt="uploaded file"
										></img>
									) : (
										<Icon
											icon="user"
											color={theme.colorPrimary}
											width="25px"
											height="25px"
											to="/user-info"
										/>
									)}
								</div>

								<CSSTransition
									in={showDropdown.profile}
									timeout={150}
									unmountOnExit
									classNames="hover-menu"
								>
									<HoverMenu>
										<HoverMenuLink className="first-link">
											<p className="whitespace-nowrap font-semibold mt-[1rem]">
												<span className="text-colorCopyDarkDark">Welcome </span>
												{useCurrentUser().user.fullName}
											</p>
										</HoverMenuLink>

										<HoverMenuLink>
											<Link className="no-underline" to="/user-info">
												<LinkLabel>Profile Settings</LinkLabel>
											</Link>
										</HoverMenuLink>

										{!settings.isDemo && (
											<HoverMenuLink>
												<Link className="no-underline" to="/entries/orders">
													<LinkLabel>My Orders </LinkLabel>
												</Link>

												{companyOrderCount && (
													<OrderCounter>{companyOrderCount}</OrderCounter>
												)}
											</HoverMenuLink>
										)}
										<HoverMenuLink>
											<LinkLabel onClick={() => logout()}>Logout</LinkLabel>
										</HoverMenuLink>
									</HoverMenu>
								</CSSTransition>
							</div>
						</div>

						{/* MY ACCOUNT dropdown */}
						{!settings.isDemo && (
							<CSSTransition
								in={expandMobileLink.account}
								timeout={150}
								unmountOnExit
								classNames="expand-menu"
							>
								<ExpandMenu className="mobile-hover-menu">
									{/* <HoverMenuLink className="sub-link">
                  <Link className="no-underline" to="/user">
                    <LinkLabel>Profile Settings</LinkLabel>
                  </Link>
                </HoverMenuLink> */}
									<HoverMenuLink className="sub-link">
										<Link className="no-underline" to="/entries/orders">
											<LinkLabel>My Orders</LinkLabel>
										</Link>
										{companyOrderCount ? (
											<OrderCounter>{companyOrderCount}</OrderCounter>
										) : null}
									</HoverMenuLink>
								</ExpandMenu>
							</CSSTransition>
						)}

						<Button
							onClick={() => logout()}
							className="only-mobile button-transparent"
						>
							Logout
						</Button>
					</div>
				</div>
			</CSSTransition>

			{title && (
				<UserPageHeader>
					<h1 className="header">{title}</h1>
				</UserPageHeader>
			)}
		</TopNavbar>
	);
};

export default UserMenu;

interface UserMenuTypes {
	active: string;
}
