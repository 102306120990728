import styled, { useTheme, css } from "styled-components";
import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import Sort from "../../../components/Sort/Sort";
import TextField from "../../../components/FormFields/TextField";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import Button from "../../../components/Button/Button";
import axios from "axios";
import { urlUserAdmin, urlEntries } from "../../../endpoints";
import { Ancestor } from "../Program/ProgramInterfaces";
import {
	PaymentStatus,
	PaymentStatusOptions,
	ReviewStatus,
	ReviewStatusOptions,
} from "../../Checkout/OrderInterfaces";
import { ExecutionCardModel } from "../../../components/CheckoutEntryCard/CheckoutEntryCard";
import { getHierarchy } from "../../Checkout/manageCheckout";
import { getThumbnailSrc } from "../../MediaLibrary/manageMediaLibrary";
import { useHistory } from "react-router-dom";
import Pagination from "../../../utils/Pagination";
import StyledForm from "../../../components/Form/Form";
import { changePaymentStatus } from "../../Entries/manageEntry";
import Placeholder from "../../../assets/placeholders/placeholder.png";
import { CSVLink } from "react-csv";
import { AdminPageControls, SearchFieldsContainer } from "./AdminSearch";
import Table, {
	SortDirection,
	TablePlaceholder,
} from "../../../components/Table/Table";
import { CompanyTypeOptions } from "../Program/ProgramInterfaces";
import { CountryOptions } from "../../../data/CountryData";
import { JobTitleOptions } from "../../../data/JobTitleData";
import dateTostring from "../../../utils/dateToString";
import {
	getStoredValue,
	setStoredValue,
} from "../../../components/LocalStorageStorage/LocalStorageStore";
import MultiDropdown from "../../../components/MultiDropdown/MultiDropdown";
import Loading from "../../../components/Loading/Loading";
import CheckboxField from "../../../components/FormFields/CheckboxField";
import config from "../../../config";
import DropdownField, {
	DropdownOptions,
} from "../../../components/FormFields/DropdownField";
import assetsConfig from "../../../assetsConfig";
import lowerCase from "../../../utils/lowerCase";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const StyledLink = styled.a`
	color: ${({ theme }) => theme.colorActivation};
`;

export const CsvExport = styled(CSVLink)<{ disabled?: boolean }>`
	${(p) =>
		p.disabled &&
		css`
			pointer-events: none;
		`};
`;

const initialSearch = {
	fullName: undefined,
	email: undefined,
	phoneNumber: undefined,
	companyName: undefined,
	id: undefined,
	isEntryReviewer: undefined,
	onJury: undefined,
};

const getScrubbedRequest = (request: UserSearchRequest): UserSearchRequest => {
	return {
		id: request.id ?? undefined,
		fullName: request.fullName ?? undefined,
		email: request.email ?? undefined,
		phoneNumber: request.phoneNumber ?? undefined,
		companyName: request.companyName ?? undefined,
		jobTitle: request.jobTitle ?? undefined,
		city: request.city ?? undefined,
		country: request.country ?? undefined,
		isAdmin: request.isAdmin ?? undefined,
		isEntryReviewer: request.isEntryReviewer ?? undefined,
		onJury: request.onJury ? JSON.parse(request.onJury) : undefined,
	};
};
const UserSearchTab = () => {
	const [searchVal, setSearchVal] = useState<UserSearchRequest>(
		getStoredValue("userSearch") || initialSearch
	);
	const [searchResult, setSearchResult] = useState<UserSearchResult[]>([]);
	const [isActive, setActive] = useState(false);
	const [page, setPage] = useState(1);
	const [totalAmountOfPages, settotalAmountOfPages] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState(50);
	const [totalRecords, setTotalRecords] = useState(0);
	const [errors, setErrors] = useState<string[]>([]);
	const history = useHistory();
	const { settings } = useSelector((state: RootState) => state.settings);
	const [csvEntries, setCsvUsers] = useState<any[]>([]);
	const [csvLoading, setCsvLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [activeSort, setActiveSort] = useState<string | null>(null);
	const [activeSortDirection, setActiveSortDirection] = useState<SortDirection>(
		SortDirection.Asc
	);
	const JurorOptions: DropdownOptions[] = [
		{ label: "Only Jurors", value: true },
		{ label: "Non-Jurors", value: false },
	];

	const searchUsers = async (
		request: UserSearchRequest,
		activeSort?: number,
		sortDirection?: SortDirection
	) => {
		const newRequest = getScrubbedRequest(request);
		const sortField =
			activeSort !== undefined && activeSort !== null ? activeSort : undefined;
		// console.log("test", sortField, sortDirection);
		const response = await axios.post(`${urlUserAdmin}/search`, newRequest, {
			params: { page, recordsPerPage, sortField, sortDirection },
		});

		if (response.status === 200) {
			setSearchResult([]); // This cleans the results and forces a React re-render in cases of sorting.
			setSearchResult(response.data);
			const total = parseInt(response.headers["totalamountofrecords"]);
			setTotalRecords(total);
			settotalAmountOfPages(Math.ceil(total / recordsPerPage));
		}
	};

	async function PostSearch(request: UserSearchRequest) {
		try {
			const newRequest = getScrubbedRequest(request);
			setErrors([]);
			setActive(false);
			const response = await axios.post(`${urlUserAdmin}/search`, newRequest, {
				params: { page, recordsPerPage },
			});

			if (response.status === 200) {
				setSearchResult(response.data);
				const total = parseInt(response.headers["totalamountofrecords"]);
				setTotalRecords(total);
				settotalAmountOfPages(Math.ceil(total / recordsPerPage));
				setActive(true);
				setIsLoading(false);
			}

			setCsvLoading(true);

			const getUserCSV = await axios.post(
				`${urlUserAdmin}/search/export`,
				newRequest
			);

			if (getUserCSV.status === 200) {
				setCsvLoading(false); // enable export button after csv entries are retrieved
				setCsvUsers(getUserCSV.data);
			}
		} catch (error: any) {
			// console.log(error);
			//setErrors(error.response.data);
			setIsLoading(false);
		}
	}

	const fieldRequired = "This field is required";
	const formikProps = useFormik({
		initialValues: searchVal,
		onSubmit: async (value) => {
			setStoredValue("userSearch", getScrubbedRequest(value));
			setPage(1);
			await PostSearch(value);
			// console.log(value);
		},
	});

	useEffect(() => {
		if (searchResult.length > 1) {
			searchUsers(
				formikProps.values,
				activeSort !== null
					? UserSearchSortOptions[
							activeSort as keyof typeof UserSearchSortOptions
					  ]
					: undefined,
				activeSort !== null ? activeSortDirection : undefined
			);
		}
	}, [page, recordsPerPage]);

	useEffect(() => {
		if (activeSort !== null) {
			searchUsers(
				formikProps.values,
				UserSearchSortOptions[activeSort as keyof typeof UserSearchSortOptions],
				activeSortDirection
			);
		}
	}, [activeSort, activeSortDirection]);

	return (
		<>
			<FormikProvider value={formikProps}>
				<SearchFieldsContainer col={2}>
					<TextField
						name="id"
						placeholder="User Id"
						value={formikProps.values.id}
					/>
					<TextField
						name="fullName"
						placeholder="Full Name"
						value={formikProps.values.fullName}
					/>
					<MultiDropdown
						placeholder="Job Title"
						options={JobTitleOptions}
						selectedValues={(formikProps.values.jobTitle || []).map(
							(label) => ({
								label,
								value: label,
							})
						)}
						onChange={(selectedList) => {
							formikProps.setFieldValue(
								"jobTitle",
								selectedList.map((x) => x.label)
							);
						}}
					/>
					<TextField
						name="companyName"
						placeholder={assetsConfig.labels.company.singular + " Name"}
						value={formikProps.values.companyName}
					/>
					<TextField
						name="email"
						placeholder="Email"
						value={formikProps.values.email}
					/>
					<TextField
						name="phoneNumber"
						placeholder="Phone Number"
						value={formikProps.values.phoneNumber}
					/>
					<TextField
						name="city"
						placeholder="City"
						value={formikProps.values.city}
					/>
					<MultiDropdown
						placeholder={assetsConfig.labels.company.singular + " Country"}
						options={CountryOptions}
						selectedValues={(formikProps.values.country || []).map((label) => ({
							label,
							value: label,
						}))}
						onChange={(selectedList) => {
							formikProps.setFieldValue(
								"country",
								selectedList.map((x) => x.label)
							);
						}}
					/>
					{!settings.isDemo && (
						<DropdownField
							name="onJury"
							placeholder="On Jury"
							options={JurorOptions}
							value={formikProps.values.onJury}
						/>
					)}
					<div className="flex items-center gap-[.5rem]">
						<CheckboxField
							className="self-center"
							name="isAdmin"
							checked={formikProps.values.isAdmin}
							placeholder="Is Admin"
						/>
						<CheckboxField
							className="self-center"
							name="isEntryReviewer"
							placeholder={
								settings.isDemo
									? "Is Approver"
									: `Is ${lowerCase(
											assetsConfig.labels.entry.singular
									  )} Reviewer`
							}
							checked={formikProps.values.isEntryReviewer}
						/>
					</div>
				</SearchFieldsContainer>

				<div className="flex my-[1rem] gap-[1rem] justify-end flex-wrap">
					<Button
						className="button-light w-[150px]"
						type="button"
						onClick={() => {
							formikProps.setValues(initialSearch);
							setSearchVal(initialSearch);
							setSearchResult([]);
							setActive(false);
							setStoredValue("userSearch", initialSearch);
							setActiveSort(null);
							setActiveSortDirection(SortDirection.Asc);
							setPage(1);
						}}
					>
						Reset Search
					</Button>

					<CsvExport
						className="no-underline"
						data={csvEntries}
						// headers={csvHeaders}
						filename={`User Search Export ${dateTostring(new Date())}.csv`}
						disabled={csvEntries.length < 1}
					>
						<Button
							className="button-light w-[150px]"
							disabled={csvEntries.length < 1}
						>
							<div className="flex items-center gap-[.5rem]">
								Export
								{csvLoading && (
									<img
										className="w-[16px]"
										src={config.assets.loading.primary}
									/>
								)}
							</div>
						</Button>
					</CsvExport>

					<Button
						className="w-[150px]"
						type="submit"
						onClick={() => {
							setIsLoading(true);
							formikProps.submitForm();
						}}
					>
						Search
					</Button>
				</div>
			</FormikProvider>

			{/* top pagination controls */}
			{isActive && (
				<AdminPageControls
					totalRecords={totalRecords}
					totalAmountOfPages={totalAmountOfPages}
					currentPage={page}
					setPage={(newPage) => setPage(newPage)}
					setIsLoading={(isLoading) => setIsLoading(isLoading)}
				/>
			)}

			{!isLoading &&
				(searchResult && searchResult.length > 0 ? (
					<Table
						isActive={isActive}
						dualScroll
						// generate string[] from enum
						columnLabels={Object.keys(UserSearchSortOptions)
							.map((field) => field)
							.splice(
								Object.keys(UserSearchSortOptions).length / 2,
								Object.keys(UserSearchSortOptions).length - 1
							)}
						labelAlias={[
							"User Id",
							"Full Name",
							"Email",
							"Phone",
							assetsConfig.labels.company.singular + " Name",
							"Job Title",
							"City",
							"Country",
						]}
						onClickSort={(sortLabel, sortDirection) => {
							setActiveSort(sortLabel);
							setActiveSortDirection(sortDirection);
						}}
						sortTriangleSize="14px"
						{...(activeSort !== null && {
							activeSort: {
								label: activeSort,
								direction: activeSortDirection,
							},
						})}
					>
						{searchResult.map((user: UserSearchResult) => {
							return (
								<Fragment key={user.id}>
									<div className="cell">
										<p>
											<StyledLink
												href={`/edit-user/${user.id}`}
												target="_blank"
											>
												{user.id}
											</StyledLink>
										</p>
									</div>
									<div className="cell">
										<p>{user.fullName}</p>
									</div>
									<div className="cell">
										<p>{user.email}</p>
									</div>
									<div className="cell">
										<p>{user.phone}</p>
									</div>
									<div className="cell">
										<p>{user.companyName}</p>
									</div>
									<div className="cell">
										<p>{user.jobTitle}</p>
									</div>
									<div className="cell">
										<p>{user.city}</p>
									</div>
									<div className="cell">
										<p>{user.country}</p>
									</div>
								</Fragment>
							);
						})}
					</Table>
				) : (
					<TablePlaceholder active={isActive}>
						Please add a search to see result table.
					</TablePlaceholder>
				))}

			{isLoading && (
				<TablePlaceholder active={false}>
					<Loading fullScreen={false} showLogo={false} />
				</TablePlaceholder>
			)}

			{/* bottom pagination controls */}
			{isActive && (
				<AdminPageControls
					totalRecords={totalRecords}
					totalAmountOfPages={totalAmountOfPages}
					currentPage={page}
					setPage={(newPage) => setPage(newPage)}
					setIsLoading={(isLoading) => setIsLoading(isLoading)}
				/>
			)}
		</>
	);
};

export default UserSearchTab;

export interface UserSearchRequest {
	id?: string;
	fullName?: string;
	email?: string;
	phoneNumber?: string;
	companyName?: string;
	jobTitle?: string[];
	city?: string;
	country?: string[];
	isAdmin?: boolean;
	isEntryReviewer?: boolean;
	onJury?: string;
}

export interface UserSearchResult {
	id?: string;
	fullName?: string;
	email?: string;
	companyName?: string;
	jobTitle?: string;
	city: string;
	country?: string;
	phone: string;
	profileUrl?: string;
	excludeFromJuries: boolean;
}

enum UserSearchSortOptions {
	"User Id",
	"Full Name",
	"Email",
	"Phone",
	"Company Name",
	"Job Title",
	"City",
	"Country",
}
