import { useEffect, useState } from "react";
import { LoadingOverlay } from "../components/Loading/Loading";

const FontLoader = (props: {
	fontURLs: string[];
	children: React.ReactNode;
}) => {
	const { fontURLs, children } = props;
	const [fontsLoaded, setFontsLoaded] = useState(false);

	useEffect(() => {
		let loadedFontsCount = 0;
		const links: HTMLLinkElement[] = [];

		const loadFonts = () => {
			fontURLs.forEach((fontURL) => {
				const linkElement = document.createElement("link");
				linkElement.href = fontURL;
				linkElement.rel = "stylesheet";
				linkElement.onload = () => {
					loadedFontsCount++;
					if (loadedFontsCount === fontURLs.length) {
						setFontsLoaded(true);
					}
				};
				linkElement.onerror = () => {
					console.error(`Failed to load font from URL: ${fontURL}`);
					loadedFontsCount++;
					if (loadedFontsCount === fontURLs.length) {
						setFontsLoaded(true);
					}
				};
				links.push(linkElement);
				document.head.appendChild(linkElement);
			});
		};

		loadFonts();

		return () => {
			links.forEach((link) => {
				document.head.removeChild(link);
			});
		};
	}, [fontURLs]);

	// Render the application content only when fonts are loaded
	if (!fontsLoaded) {
		return <LoadingOverlay show={true} message="Loading Assets" />;
	}

	return <>{children}</>;
};

export default FontLoader;
