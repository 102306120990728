import { useEffect, useState } from "react";
import { Formik, Field, Form, useFormik, FormikProvider } from "formik";
import styled, { useTheme } from "styled-components";
import Modal, { ModalCard } from "../Modal/Modal";
import { Tag } from "../../views/MediaLibrary/mediaLibrary.model";
import { urlTags } from "../../endpoints";
import axios from "axios";
import Icon from "../Icon/Icon";
import Button, { buttonProps } from "../Button/Button";
import TextField from "../FormFields/TextField";
import * as Yup from "yup";
import { addTag, getTagsForCompany } from "../../views/TagLibrary/manageTags";

const TagSelectionContainer = styled.div`
	/* padding: 2rem; */
	gap: 1rem;
`;

const TagContainer = styled.div`
	display: flex;
	align-items: left;
	flex-wrap: wrap;
	gap: 0.25rem;
	flex-basis: 100%;
	min-width: 0;
	margin-top: 2rem;
`;

const TagBubble = styled(Button)<TagBubbleProps>`
	display: flex;
	align-items: center;
	gap: 0.1rem;
	width: fit-content;
	min-width: 75px;
	padding: 0.25rem 0.5rem;
	box-shadow: none !important;
	color: ${(p) =>
		p.selected ? p.theme.colorActivation : p.theme.colorCopyLight} !important;
	border: 1px solid
		${(p) => (p.selected ? p.theme.colorActivation : p.theme.colorCopyLight)} !important;
	border-radius: 50px;
	cursor: pointer;
`;

export const tagFieldRequired = "Tags cannot be blank or empty whitespace";

function TagSelection(props: TagSelectionProps) {
	const [allTags, setAllTags] = useState<Array<Tag>>([]);
	const theme = useTheme();

	const formikProps = useFormik({
		initialValues: { newTag: "" },
		onSubmit: () => {},
		validationSchema: Yup.object({
			newTag: Yup.string()
				.trim()
				.required(tagFieldRequired)
				.min(1)
				.max(60, "Max tag length is 60 characters"),
		}),
		validateOnBlur: false,
		validateOnChange: false,
	});

	function handleAddTag(text: string) {
		const newTag: Tag = {
			companyId: props.companyId,
			id: 0,
			tag: text,
		};
		const existingTag = allTags.find((x) => x.tag === text);

		if (existingTag) {
			if (!props.selectedTags.find((x) => x.tag === text)) {
				props.setSelectedTags([...props.selectedTags, existingTag]);
			}
			return;
		}

		addTag(props.companyId, newTag).then((response) => {
			if (response.status === 201) {
				props.setSelectedTags([...props.selectedTags, response.data]);
				setAllTags([...allTags, response.data]);
				formikProps.resetForm();
			}
		});
	}

	useEffect(() => {
		function fetchData() {
			getTagsForCompany(props.companyId).then((response) => {
				if (response.status === 200) {
					setAllTags(response.data);
				}
			});
		}
		fetchData();
	}, [props.companyId]);

	const handleToggleTag = (tag: Tag, selectedTags: Tag[]) => {
		if (selectedTags.findIndex((x) => x.id === tag.id) > -1) {
			props.setSelectedTags(selectedTags.filter((t) => t.id !== tag.id));
		} else {
			props.setSelectedTags([...selectedTags, tag]);
		}
	};

	return (
		<FormikProvider value={formikProps}>
			<TagSelectionContainer>
				<Form className="gap-[1rem] flex flex-nowrap flex-row w-full">
					<TextField
						className="w-full"
						name="newTag"
						placeholder="Add a tag"
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								handleAddTag(formikProps.values.newTag);
							}
						}}
					/>
					<Button
						type="button"
						className="w-[200px] button-gold"
						icon="plus"
						iconColor={theme.colorPrimary}
						onClick={async () => {
							formikProps
								.validateForm()
								.then((res) => {
									if (Object.keys(res).length === 0) {
										handleAddTag(formikProps.values.newTag);
									}
								})
								.catch((err) => {});
						}}
					>
						Add Tag
					</Button>
				</Form>
				<TagContainer>
					{props.selectedTags &&
						allTags.map((tag) => {
							return (
								<TagBubble
									className="button-light font-medium h-[25px] "
									key={tag.id}
									selected={
										props.selectedTags.findIndex((x) => x.id === tag.id) > -1
											? true
											: false
									}
									onClick={() => handleToggleTag(tag, props.selectedTags)}
									icon={
										props.selectedTags.findIndex((x) => x.id === tag.id) > -1
											? "close"
											: "plus"
									}
									iconColor={
										props.selectedTags.findIndex((x) => x.id === tag.id) > -1
											? theme.colorActivation
											: theme.colorCopyLight
									}
								>
									{tag.tag}
								</TagBubble>
							);
						})}
				</TagContainer>
			</TagSelectionContainer>
		</FormikProvider>
	);
}

export default TagSelection;

interface TagSelectionProps {
	className?: string;
	companyId: number;
	selectedTags: Array<Tag>;
	setSelectedTags(
		outputTags: Array<Tag>
	): React.Dispatch<React.SetStateAction<Array<Tag>>> | void;
}

interface TagBubbleProps extends buttonProps {
	selected: boolean;
}
