import { useState, useEffect } from "react";
import { GalleryBatchCard } from "../views/Admin/Judging/JudgingInterfaces";
import { useJurorHub, UseJurorHubReturnType } from "./useJurorHub";

export interface UseJudgingGalleryHubReturnType extends UseJurorHubReturnType {
  galleryBatchCards: Array<GalleryBatchCard>; // Juror
}

export function useJudgingGalleryHub(): UseJudgingGalleryHubReturnType {
  const jurorHub = useJurorHub();

  const [galleryBatchCards, setGalleryBatchCards] = useState<
    Array<GalleryBatchCard>
  >([]);

  // useEffect for handling entrySetId changes
  useEffect(() => {
    if (
      jurorHub.connection &&
      jurorHub.juryId &&
      jurorHub.entrySetId &&
      jurorHub.juror &&
      jurorHub.juror.id
    ) {
      const getGalleryBatchCards = (entrySetId: number, juryId: number) => {
        if (jurorHub.connection) {
          jurorHub.connection
            .invoke("GetJudgingGalleryCards", entrySetId, juryId)
            .then((cards) => {
              setGalleryBatchCards(cards);
            });
        }
      };
      if (!jurorHub.invalidJuror) {
        jurorHub.connection.on("judgingGalleryCardsUpdated", () => {
          jurorHub.debug && console.log("judgingGalleryCardsUpdated");
          getGalleryBatchCards(
            Number(jurorHub.entrySetId),
            Number(jurorHub.juryId)
          );
        });

        jurorHub.connection.on(
          `judgingGalleryCardsUpdated-${jurorHub.juror!.id}`,
          () => {
            jurorHub.debug &&
              console.log(`judgingGalleryCardsUpdated-${jurorHub.juror!.id}`);
            getGalleryBatchCards(
              Number(jurorHub.entrySetId),
              Number(jurorHub.juryId)
            );
          }
        );
        jurorHub.connection.on(
          `judgingGalleryCardsPushed-${jurorHub.juror?.id}`,
          (cards) => {
            if (cards) {
              jurorHub.debug &&
                console.log(`judgingGalleryCardsPushed-${jurorHub.juror?.id}`);
              setGalleryBatchCards(cards);
            }
          }
        );

        getGalleryBatchCards(
          Number(jurorHub.entrySetId),
          Number(jurorHub.juryId)
        );
      }
    }
  }, [
    jurorHub.connection,
    jurorHub.juryId,
    jurorHub.entrySetId,
    jurorHub.juror,
    jurorHub.juror?.id,
  ]);

  return {
    ...jurorHub,
    galleryBatchCards,
  };
}
