import { useContext, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import Button from "../../../../components/Button/Button";
import {
	FieldArray,
	FieldArrayRenderProps,
	FormikProvider,
	useFormik,
} from "formik";
import Icon from "../../../../components/Icon/Icon";
import { CardBody } from "../../../../components/DashboardCard/DashboardCard";
import { SelectBox } from "./EntrySetTab";
import { SquareButton } from "../../../../components/Button/Button";
import RoundDragCard from "../../../../components/RoundDragCard.tsx/RoundDragCard";
import {
	JuryCardModel,
	RoundStructureConfig,
	RoundType,
} from "../JudgingInterfaces";
import {
	finalizeRoundConfiguration,
	getRoundStructures,
	getRoundTypes,
	saveRoundStructures,
} from "../manageJudging";

import { JuryConfigTabProps } from "./JudgingConfigJury";
import { ButtonContainer } from "./JudgingConfigJury";
import { useAlert } from "../../../../components/Alert/Alerts";

export const ROUNDS_KEY = "roundStructures"; // key used for formik field name

export const RoundBankCard = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	border: solid 1px ${({ theme }) => theme.colorBorderLight};
	background: ${({ theme }) => theme.colorBackgroundLight};

	p {
		font-size: ${({ theme }) => theme.xSmallSize};
	}
`;

const RoundsTab = (props: JuryConfigTabProps) => {
	const theme = useTheme();

	const [roundStructures, setRoundStructures] = useState<
		RoundStructureConfig[]
	>([]);
	const [roundTypes, setRoundTypes] = useState<RoundType[]>([]);
	const { addNewAlert } = useAlert();

	const formikProps = useFormik({
		initialValues: {
			roundStructures: roundStructures,
		},
		onSubmit: async (value) => {},
		validateOnBlur: false,
		validateOnChange: false,
		enableReinitialize: true,
	});

	const handleSave = () => {
		saveRoundStructures(formikProps.values.roundStructures)
			.then((res) => {
				if (res.status == 200) {
					fetchRoundStructures();
					addNewAlert({
						type: "success",
						message: "Saved Successfully",
					});
				}
			})
			.catch((err) => {
				//console.log("jurors error: ", err);
				addNewAlert({
					type: "error",
					message: "Problem saving Round Structures",
				});
			});
	};

	const handleAdd = (roundType: RoundType) => {
		const newRoundStructure: RoundStructureConfig = {
			name: roundType.name,
			id: 0,
			juryId: Number(props.juryId),
			roundTypeId: roundType.id,
			jurorInstructions: roundType.defaultJurorInstructions,
			order: formikProps.values.roundStructures.length + 1,
			isFeedbackRequired: false,
			juryRoundPool: [],
			isCollapsed: true,
		};

		formikProps.setFieldValue("roundStructures", [
			...formikProps.values.roundStructures,
			newRoundStructure,
		]);
	};

	const handleExpand = (name: string, currentValue: boolean) => {
		formikProps.setFieldValue(name, !currentValue);
	};

	const renderCard = (
		round: RoundStructureConfig,
		index: number,
		arrayHelpers: FieldArrayRenderProps
	) => {
		return (
			<RoundDragCard
				key={index}
				id={round.id}
				round={round}
				index={index}
				handleRemove={arrayHelpers.remove}
				handleExpand={handleExpand}
				moveRoundCard={handleSortEnd}
				arrayHelpers={arrayHelpers}
			/>
		);
	};

	const handleSortEnd = (
		arrayHelpers: FieldArrayRenderProps,
		from: number,
		to: number
	) => {
		const updatedList = arrayHelpers.form.values.roundStructures;

		const item = updatedList[from];
		updatedList.splice(from, 1);
		updatedList.splice(to, 0, item);

		for (let i = 0; i < updatedList.length; i++) {
			updatedList[i].order = i + 1;
		}

		formikProps.setFieldValue("roundStructures", updatedList);
	};

	const fetchRoundStructures = () => {
		getRoundStructures(Number(props.juryId))
			.then((res) => {
				if (res.status == 200) {
					// console.log("Round Structures", res.data);
					for (const item of res.data) {
						item.isCollapsed = true;
					}

					setRoundStructures(res.data);
				}
			})
			.catch((err) => {
				// console.log("Round Structures error: ", err);
				addNewAlert({
					type: "error",
					message: "Problem getting Round Structures",
				});
			});
	};

	useEffect(() => {
		getRoundTypes()
			.then((res) => {
				if (res.status == 200) {
					// console.log("Round Types", res.data);
					setRoundTypes(res.data);
				}
			})
			.catch((err) => {
				// console.log("Round Types error: ", err);
				addNewAlert({
					type: "error",
					message: "Problem getting Round Types",
				});
			});

		fetchRoundStructures();
	}, []);

	// useEffect(() => {
	//   console.log("Values have changed:", formikProps.values.roundStructures);
	// }, [formikProps.values.roundStructures]);

	return (
		<FormikProvider value={formikProps}>
			<CardBody>
				<div className="flex gap-[2rem] h-full">
					<div className="flex flex-col flex-1 min-h-[450px]">
						<h2 className="mb-[1rem]">Round Pool</h2>

						<SelectBox className="flex-col flex-1 !items-start !justify-start">
							<FieldArray
								name="roundStructures"
								validateOnChange={false}
								render={(arrayHelpers) => (
									<>
										{formikProps.values.roundStructures.length > 0 ? (
											formikProps.values.roundStructures.map(
												(roundStructure, index) =>
													renderCard(roundStructure, index, arrayHelpers)
											)
										) : (
											<p className="m-auto">
												Please add a round to the round pool
											</p>
										)}
									</>
								)}
							/>
						</SelectBox>
					</div>
					<div className="flex-1">
						<h2 className="mb-[1rem]">Round Bank</h2>

						{roundTypes.flatMap((roundType, index) => (
							<RoundBankCard key={index}>
								<div className="flex items-center gap-[1rem]">
									<Icon icon="info" color={theme.colorCopyLight} />
									<p>{roundType.name}</p>
								</div>

								<SquareButton
									onClick={() => handleAdd(roundType)}
									icon="plus"
								/>
							</RoundBankCard>
						))}
					</div>
				</div>

				<ButtonContainer>
					<Button className="button-light" onClick={handleSave}>
						Save
					</Button>
					<Button
						icon="check"
						disabled={
							!props.juryCard || props.juryCard.isRoundConfigurationFinal
						}
						onClick={() => {
							finalizeRoundConfiguration(Number(props.juryId)).then((res) => {
								if (res.status == 204) {
									props.setJuryCard((prevState) => {
										return {
											...(prevState as JuryCardModel),
											isRoundConfigurationFinal: true,
										};
									});
								}
							});
						}}
					>
						{props.juryCard && props.juryCard.isRoundConfigurationFinal
							? "Round Configuration Finalized"
							: "Finalize Round Configuration"}
					</Button>
				</ButtonContainer>
			</CardBody>
		</FormikProvider>
	);
};

export default RoundsTab;
