import { DropdownOptions } from "../components/FormFields/DropdownField";

const enumToDropdownOptions = (inputEnum: any) => {
	const options: DropdownOptions[] = [];
	const enumArr = Object.entries(inputEnum);

	for (let i = enumArr.length - 1; i >= enumArr.length / 2; i--) {
		options.unshift({
			label: enumArr[i][0],
			value: enumArr[i][1],
		});
	}

	return options;
};

export default enumToDropdownOptions;
