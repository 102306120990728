import { useEffect, useState } from "react";
import { getRootProgramOptions } from "../../views/Admin/Program/manageProgram";
import DropdownField, { DropdownOptions } from "../FormFields/DropdownField";
import Button from "../Button/Button"; // import your Button component
import styled from "styled-components";
import {
  CheckoutAndPaymentStatuses,
  EntryCheckout,
  getCheckoutAndPaymentStatuses,
  ReviewStatusesAndAmount,
} from "./manageReports";
import ReportingGraphs from "./ReportingGraphs";
import ResultsTable from "./ResultsTable";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import config from "../../config";

const LoadingSpinner = styled.img`
  width: 100px;
`;

const spinnerSrc = config.assets.loading.primary;

const EntryCreationCheckout = () => {
  const [rootProgramIDs, setRootProgramIDs] = useState<DropdownOptions[]>([]);
  const [selectedProgramId, setSelectedProgramId] = useState<
    string | number | undefined
  >();
  const [selectedProgramName, setSelectedProgramName] = useState<string>();
  const [checkoutCounts, setCheckoutCounts] = useState<EntryCheckout>();
  const [paymentStatusAmounts, setPaymentStatusAmounts] = useState<
    ReviewStatusesAndAmount[]
  >([]);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [checkoutAndPaymentStatuses, setCheckoutAndPaymentStatuses] =
    useState<CheckoutAndPaymentStatuses>({
      entryCheckout: {
        pending: { entries: 0, amount: 0 },
        completed: { entries: 0, amount: 0 },
      },
      reviewStatusesAndAmount: [],
    });

  // Fetch root program options when the component mounts
  useEffect(() => {
    getRootProgramOptions().then((res) => {
      setRootProgramIDs(res.data);
    });
  }, []);

  const generateInformation = async () => {
    if (selectedProgramId) {
      setCheckoutLoading(true);
      try {
        const { entryCheckout, reviewStatusesAndAmount } =
          await getCheckoutAndPaymentStatuses(Number(selectedProgramId));
        setCheckoutCounts(entryCheckout);
        setPaymentStatusAmounts(reviewStatusesAndAmount);
      } catch (error) {
        console.error("Failed to get entry program review statuses: ", error);
      }
      setCheckoutLoading(false);
    }
  };

  const formikProps = useFormik({
    initialValues: { rootProgramIDs },
    enableReinitialize: true,
    onSubmit: async () => {},
    validationSchema: Yup.object({}),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div>
      <FormikProvider value={formikProps}>
        <div className="flex items-center space-x-4 pb-5">
          {" "}
          <DropdownField
            name="rootProgram"
            options={rootProgramIDs}
            value={selectedProgramId || undefined}
            placeholder="Select a Program"
            onChange={(value) => {
              const selectedValue = Number(value.target.value);
              const selectedOption = rootProgramIDs.find(
                (option) => option.value === selectedValue
              );
              setSelectedProgramId(selectedValue);
              setSelectedProgramName(String(selectedOption?.label || ""));
            }}
          />
          <Button onClick={generateInformation}>Generate</Button>
        </div>
        {checkoutLoading ? (
          <div className="pt-10 flex flex-col items-center justify-center">
            <div>Running Reports...</div>
            <LoadingSpinner src={spinnerSrc} />
          </div>
        ) : checkoutCounts != null ? (
          <div>
            <div className="w-1/2">
              <ResultsTable
                data={checkoutCounts}
                disableExport={true}
                showTopScroll={false}
              />
            </div>
            <ReportingGraphs reviewStatusesAndAmount={paymentStatusAmounts} />
            <ResultsTable
              data={paymentStatusAmounts}
              programName={selectedProgramName}
            />
          </div>
        ) : null}
      </FormikProvider>
    </div>
  );
};

export default EntryCreationCheckout;
