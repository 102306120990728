import { useParams, Redirect } from "react-router-dom";
import { urlUsers } from "../../../endpoints";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { LoadingContext } from "../../../App";

async function getPhysicalEntryRedirect(entryId: number) {
  return await axios.get<string>(
    `${urlUsers}/getPhysicalEntryRedirect/${entryId}`
  );
}

const JudgingRedirect = () => {
  const { entryId } = useParams<{
    entryId?: string;
  }>();
  const { setLoadingMessage } = useContext(LoadingContext);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

  useEffect(() => {
    setLoadingMessage(" ");
    setTimeout(() => {
      setLoadingMessage(null);
      setRedirectUrl("/");
    }, 10000);
  }, []);

  useEffect(() => {
    const parsedEntryId = entryId ? parseInt(entryId) : 0;

    if (parsedEntryId) {
      getPhysicalEntryRedirect(parsedEntryId)
        .then((response) => {
          if (response.status == 200 && response.data) {
            setLoadingMessage(null);
            setRedirectUrl(response.data);
          }
        })
        .catch((error) => {
          // console.log(error);
          setLoadingMessage(null);
          setRedirectUrl("/");
        });
    }
  }, [entryId]);

  return redirectUrl ? <Redirect to={redirectUrl} /> : <></>;
};

export default JudgingRedirect;
