import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import {
	CardHeader,
	CardBody,
	CardContainer,
} from "../../../../components/DashboardCard/DashboardCard";
import React, { useState, useEffect, useRef, useContext } from "react";
import Icon from "../../../../components/Icon/Icon";
import styled, { useTheme } from "styled-components";
import HierarchyNav, {
	HierarchyCard,
	SortHeader,
	generateGridArea,
	generateGridColumns,
} from "../../../../components/HierarchyNav/HierarchyNav";
import DropdownField from "../../../../components/FormFields/DropdownField";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import * as Yup from "yup";
import EntryDetailsModal from "./EntryDetailsModal";
import Button, { ButtonLink } from "../../../../components/Button/Button";
import { EntrySetCardComponent } from "./ManageGroup";
import { JuryEntrySetControlsProps, RoundControls } from "./JudgingAdminJury";
import TextField from "../../../../components/FormFields/TextField";
import { useAdminHub } from "../../../../hooks/useAdminHub";
import {
	EntrySetAdminCard,
	GalleryBatchCard,
	GalleryEntryCard,
	JudgingRoundStatus,
	VoteOption,
} from "../JudgingInterfaces";
import CheckboxField from "../../../../components/FormFields/CheckboxField";
import { CollapsibleContainer } from "../../../../components/Collapsible/Collapsible";
import Sort, { SortContainer } from "../../../../components/Sort/Sort";
import {
	StickyCardHeader,
	StickyContainer,
} from "../../../../components/StickyCard/StickyCard";
import JudgingGallerySideBar, {
	NavLink,
} from "../../../Judging/JudgingGallerySideBar";
import { InnerContainer } from "../../../Judging/JudgingGallery";
import { useHistory, useParams } from "react-router-dom";
import {
	advanceEntryInJudging,
	getEntrySetAdminBatches,
	getEntrySetAdminCard,
	getEntrySetAdminRounds,
} from "../manageJudging";
import { EntrySetAdminEntrySetCardBatches } from "../JudgingInterfaces";
import { WarningModal } from "../../../../components/Modal/Modal";

import TZDatePickerField from "../../../../components/FormFields/TZDatePickerField";
import Progressbar from "../../../../components/Progressbar/Progressbar";
import BrowserHeader from "../../../../components/BrowserHeader/BrowserHeader";
import { useAlert } from "../../../../components/Alert/Alerts";

const marginBottom = 100;

// compute height based on children height
export const DynamicHeight = (props: {
	children: React.ReactNode;
	batchCards: any[];
}) => {
	const batchContainer = useRef<HTMLDivElement>(null);
	const [height, setHeight] = useState(0);

	// https://stackoverflow.com/questions/76187282/react-resizeobserver-loop-completed-with-undelivered-notifications
	// prevents ResizeObserver infinite loop
	const observerCallback: ResizeObserverCallback = (
		entries: ResizeObserverEntry[]
	) => {
		window.requestAnimationFrame((): void | undefined => {
			if (!Array.isArray(entries) || !entries.length) {
				return;
			}
			setHeight(entries[0].contentRect.height);
		});
	};

	// update max height as batchContainer height changes
	// happens when an element inside is added, removed, or resized
	useEffect(() => {
		if (!batchContainer.current) return;
		const resizeObserver = new ResizeObserver(observerCallback);

		// listen for batchContainer height
		batchContainer.current && resizeObserver.observe(batchContainer.current);
		return () => resizeObserver.disconnect(); // clean up
	}, [batchContainer]);

	return (
		<InnerContainer
			className="w-full"
			height={String(height + marginBottom + 32) + "px"}
		>
			<JudgingGallerySideBar
				className="!z-[1]"
				navLinks={props.batchCards.reduce<NavLink[]>(
					(arr, batchCard) => [
						...arr,
						{
							label: batchCard.label,
							path: `#batch${String(batchCard.id)}`,
						},
					],
					[]
				)}
				show
			/>
			<BatchContainer className="batch-container" ref={batchContainer}>
				{props.children}
			</BatchContainer>
		</InnerContainer>
	);
};

const Score = styled.div<{ color: string }>`
	display: flex;
	gap: 1rem;
	justify-content: center;
	align-items: center;
	width: 100px;
	margin: 0 auto;
	border: 2px solid ${(p) => p.color};
	padding: 0.5rem;
`;

export const SortLabels = styled.div`
	position: sticky;
	top: 0;
	background: ${({ theme }) => theme.colorBackgroundLight};
	box-shadow: 0 4px 3px -3px ${({ theme }) => theme.colorBoxShadow};
	z-index: 50;

	@media only screen and (max-width: ${({ theme }) => theme.xl}) {
		* {
			font-size: ${({ theme }) => theme.xxSmallSize};
		}
	}
`;

export const StyledCollapsibleContainer = styled.div`
	margin-bottom: 2rem;
	* {
		font-size: 1rem;
	}
	${StickyCardHeader} {
		font-weight: ${({ theme }) => theme.fontSemiBold};
		font-size: 1rem;
	}

	.threshold-field {
		/* max-width: 125px; */
		/* color: ${({ theme }) => theme.colorCopyDarkDark}; */
		max-width: 110px;
		font-weight: ${({ theme }) => theme.fontDefaultWeight};
		border: 1px solid ${({ theme }) => theme.colorBorderLight};
	}

	@media only screen and (max-width: ${({ theme }) => theme.xl}) {
		* {
			font-size: ${({ theme }) => theme.xxSmallSize};
		}
	}
`;

const BatchContainer = styled(StickyContainer)`
	position: absolute !important;
	top: 0 !important;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	padding-left: calc(1rem + 47px);
	padding-right: 1rem;
	margin-top: 2rem;
	margin-bottom: ${marginBottom + "px"};
`;

const ControlsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	padding: 1rem calc(1rem + 47px);

	.col-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 1rem;
	}

	${RoundControls} {
		flex: 1;
		box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};

		.progress-bar {
			flex: 1;
		}
	}

	@media only screen and (max-width: ${({ theme }) => theme.lg}) {
		.col-container {
			flex-flow: row wrap;
		}
	}
`;

// for the admin entry groups view
const EntrySetControls = (props: EntrySetControlsProps) => {
	const theme = useTheme();
	const { values, setFieldValue } = useFormikContext<EntrySetAdminCard>();

	const [roundChanging, setRoundChanging] =
		useState<null | JudgingRoundStatus>();

	useEffect(() => {
		setRoundChanging(null);
	}, [values.roundStatus]);

	return (
		<ControlsContainer className={props.className}>
			<div className="col-container">
				{props.programId && (
					<Button
						className="button-light"
						onClick={() =>
							window.open(
								`/judging/admin/${props.programId}/${props.entrySetAdminCard.juryId}/live-tools`,
								"_blank"
							)
						}
					>
						Live Tools
					</Button>
				)}

				{props.roundSelection && props.roundSelection}
			</div>

			<RoundControls className="round-controls">
				<Icon
					icon={
						roundChanging === JudgingRoundStatus.Started
							? "play-spinning"
							: "play"
					}
					readonly={
						props.disabled ||
						(roundChanging !== JudgingRoundStatus.Started &&
							roundChanging !== null)
					}
					color={
						values.roundStatus === JudgingRoundStatus.Started && !props.disabled
							? theme.colorActivation
							: theme.colorCopyLight
					}
					onClick={() => {
						// setFieldValue("roundStatus", JudgingRoundStatus.Started);
						setRoundChanging(JudgingRoundStatus.Started);
						if (!props.disabled) {
							props.updateRoundStatus(
								props.entrySetAdminCard.juryId,
								props.entrySetAdminCard.id,
								props.entrySetAdminCard.activeRoundId,
								JudgingRoundStatus.Started
							);
						}
					}}
					width="30px"
					height="30px"
				/>
				<Icon
					icon={
						roundChanging === JudgingRoundStatus.Pause
							? "pause-spinning"
							: "pause-circle"
					}
					readonly={
						props.disabled ||
						(roundChanging !== JudgingRoundStatus.Pause &&
							roundChanging !== null)
					}
					color={
						values.roundStatus === JudgingRoundStatus.Pause && !props.disabled
							? theme.colorActivation
							: theme.colorCopyLight
					}
					onClick={() => {
						// setFieldValue("roundStatus", JudgingRoundStatus.Pause);
						setRoundChanging(JudgingRoundStatus.Pause);
						props.updateRoundStatus(
							props.entrySetAdminCard.juryId,
							props.entrySetAdminCard.id,
							props.entrySetAdminCard.activeRoundId,
							JudgingRoundStatus.Pause
						);
					}}
					width="30px"
					height="30px"
				/>
				<Icon
					icon={
						roundChanging === JudgingRoundStatus.Completed
							? "stop-spinning"
							: "stop"
					}
					readonly={
						props.disabled ||
						(roundChanging !== JudgingRoundStatus.Completed &&
							roundChanging !== null)
					}
					color={
						values.roundStatus === JudgingRoundStatus.Completed &&
						!props.disabled
							? theme.colorActivation
							: theme.colorCopyLight
					}
					onClick={() => {
						// setFieldValue("roundStatus", JudgingRoundStatus.Completed);
						setRoundChanging(JudgingRoundStatus.Completed);
						props.updateRoundStatus(
							props.entrySetAdminCard.juryId,
							props.entrySetAdminCard.id,
							props.entrySetAdminCard.activeRoundId,
							JudgingRoundStatus.Completed
						);
					}}
					width="30px"
					height="30px"
				/>
				{props.progress && (
					<Progressbar
						className="progress-bar !pr-0 ml-[1rem]"
						background={theme.colorBackgroundLight}
						completionPercent={props.progress}
						currentRound={props.entrySetAdminCard.activeRoundOrder}
						totalRounds={props.entrySetAdminCard.totalRounds}
					/>
				)}
			</RoundControls>

			<div className="col-container">
				<TZDatePickerField
					key={`round.${values.roundType}.deadline`}
					name="deadline"
					readOnly={props.disabled}
					placeholder="Deadline Date"
					value={values.deadline}
					showTimeSelect
				/>
				{props.showThreshold && (
					<TextField
						className="threshold"
						name="threshold"
						type="number"
						readOnly={props.disabled}
						value={values.threshold}
						placeholder="Threshold"
					/>
				)}
			</div>

			<div className="col-container">
				{props.showCountAbstainAndNoVotes && (
					<CheckboxField
						className="countAbstainAndNoVotes"
						name="countAbstainAndNoVotes"
						disabled={props.disabled}
						checked={values.countAbstainAndNoVotes}
						placeholder="Count Abstain & No Votes"
						onChange={(value) => {
							props.updateCountAbstainAndNoVotes &&
								props.updateCountAbstainAndNoVotes(
									props.entrySetAdminCard.juryId,
									props.entrySetAdminCard.id,
									props.entrySetAdminCard.activeRoundId,
									value,
									props.entrySetAdminCard
								);
						}}
					/>
				)}

				<CheckboxField
					name="entriesLocked"
					checked={values.entriesLocked}
					disabled={props.disabled}
					onChange={(isLocked) => {
						setFieldValue("entriesLocked", isLocked);
						props.updateEntriesLocked(
							props.entrySetAdminCard.juryId,
							props.entrySetAdminCard.id,
							props.entrySetAdminCard.activeRoundId,
							!values.entriesLocked
						);
					}}
					placeholder="Lock Entries"
				/>
			</div>
		</ControlsContainer>
	);
};

const EntrySetAdministration = () => {
	const theme = useTheme();
	const history = useHistory();
	const { roundId } = useParams<{
		roundId: string;
	}>();
	const {
		entrySetAdminCard,
		batchCards,
		breadcrumbs,
		disabled,
		blankEntrySetCard,
		entrySetId,
		programId,
		juryId,
		handleClickBreadcrumb,
		updateDeadline,
		updateCountAbstainAndNoVotes,
		updateEntrySetThreshold,
		updateBatchThreshold,
		updateRoundStatus,
		updateEntriesLocked,
		updateEntryLocked,
		updateEntryHidden,
		updateAdvance,
		updateVote,
	} = useAdminHub(roundId ? true : false);

	//   const [selectedEntrySetIndex, setSelectedEntrySetIndex] = useState<
	//     number | null
	//   >(0);
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [entryDetails, setEntryDetails] = useState<GalleryEntryCard>();
	const [pushEntryId, setPushEntryId] = useState(0);
	const { addNewAlert } = useAlert();
	const [isFutureRound, setIsFutureRound] = useState(false);

	const [localEntrySetAdminCard, setLocalEntrySetAdminCard] =
		useState<EntrySetAdminCard>(blankEntrySetCard);

	const [localBatchCards, setLocalBatchCards] = useState<
		Array<GalleryBatchCard>
	>([]);

	const [roundOptions, setRoundOptions] = useState<
		Array<EntrySetAdminRoundSelection>
	>([]);
	const [activeRound, SetActiveRound] = useState<EntrySetAdminRoundSelection>();

	const [activeRoundExists, setActiveRoundExists] = useState(false);

	const viewDetails = (entry: GalleryEntryCard) => {
		setEntryDetails(entry);
		setShowDetailsModal(true);
	};

	const formikProps = useFormik({
		initialValues: { ...localEntrySetAdminCard, batchCards: localBatchCards },
		enableReinitialize: true,
		onSubmit: async () => {},
		validationSchema: Yup.object({}),
		validateOnBlur: false,
		validateOnChange: false,
	});

	useEffect(() => {
		const parsedRoundId = roundId ? parseInt(roundId) : 0;
		const parsedEntrySetId = entrySetId ? parseInt(entrySetId) : 0;
		if (parsedRoundId && parsedEntrySetId) {
			getEntrySetAdminCard(parsedEntrySetId, parsedRoundId)
				.then((resp) => {
					if (resp.status === 200) {
						setLocalEntrySetAdminCard(resp.data);
					}
				})
				.catch((error) => {});

			getEntrySetAdminBatches(parsedEntrySetId, parsedRoundId)
				.then((resp) => {
					if (resp.status === 200) {
						console.log("Batch cards: ", resp.data);

						setLocalBatchCards(resp.data);
					}
				})
				.catch((error) => {});
		}
	}, [entrySetId, roundId]);

	useEffect(() => {
		if (entrySetAdminCard && !disabled) {
			setLocalEntrySetAdminCard(entrySetAdminCard);
		}
	}, [entrySetAdminCard, disabled]);

	useEffect(() => {
		if (batchCards && batchCards.length && !disabled) {
			setLocalBatchCards(batchCards);
		}
	}, [batchCards, disabled]);

	useEffect(() => {
		const parsedEntrySetId = entrySetId ? parseInt(entrySetId) : 0;
		if (parsedEntrySetId) {
			getEntrySetAdminRounds(parsedEntrySetId)
				.then((resp) => {
					if (resp.status == 200) {
						setRoundOptions(resp.data);
						setActiveRoundExists(resp.data.findIndex((x) => x.isActive) !== -1);
						SetActiveRound(resp.data.find((x) => x.isActive));
					}
				})
				.catch((error) => {});
		}
	}, [entrySetId]);

	useEffect(() => {
		const parsedRoundId = roundId ? parseInt(roundId) : 0;
		setIsFutureRound(
			(activeRound && parsedRoundId && parsedRoundId > activeRound.id) || false
		);
	}, [activeRound, roundId]);

	useEffect(() => {
		if (formikProps.values.deadline != localEntrySetAdminCard.deadline) {
			// console.log("Triggering deadline: ", formikProps.values.deadline);
			updateDeadline(
				formikProps.values.juryId,
				formikProps.values.id,
				formikProps.values.activeRoundId,
				formikProps.values.deadline
			);
		}
	}, [formikProps.values.deadline]);

	useEffect(() => {
		if (
			formikProps.values.countAbstainAndNoVotes !=
			localEntrySetAdminCard.countAbstainAndNoVotes
		) {
			// console.log("Triggering countAbstainAndNoVotes: ", formikProps.values.countAbstainAndNoVotes);
			updateCountAbstainAndNoVotes(
				formikProps.values.juryId,
				formikProps.values.id,
				formikProps.values.activeRoundId,
				formikProps.values.countAbstainAndNoVotes,
				localEntrySetAdminCard
			);
		}
	}, [formikProps.values.countAbstainAndNoVotes]);

	useEffect(() => {
		if (formikProps.values.threshold != localEntrySetAdminCard.threshold) {
			//console.log("Triggering threshold: ", formikProps.values.threshold);
			updateEntrySetThreshold(
				formikProps.values.juryId,
				formikProps.values.id,
				formikProps.values.activeRoundId,
				formikProps.values.threshold!
			);
		}
	}, [formikProps.values.threshold]);

	useEffect(() => {
		// if an entry is selected for the modal
		// and batch cards change,
		// repopulate the entry
		if (entryDetails) {
			setEntryDetails(
				localBatchCards
					.flatMap((x) => x.roundEntries)
					.find((x) => x.id === entryDetails.id)
			);
		}
	}, [localBatchCards]);

	let columns = [
		{ label: "EID", width: "90px" },
		{ label: "Votes", width: "50px" },
		{ label: "Entrant" },
		{ label: "Brand" },
		{ label: "Title" },
		{ label: "Score", width: "100px", isCenter: true },
		{ label: "Lock", width: "50px", isCenter: true },
		{ label: "Hide", width: "50px", roundType: "Grand", isCenter: true },
		{ label: "Hide", width: "50px", roundType: "Winner", isCenter: true },
		{ label: "Advance", width: "110px", hideLastRound: true, isCenter: true },
	];

	columns = columns.filter((c) => {
		if (c.roundType) return c.roundType == formikProps.values.roundType;
		if (c.hideLastRound) {
			const notLastRound =
				formikProps.values.activeRoundOrder !== formikProps.values.totalRounds;
			return notLastRound;
		}
		return true;
	});

	function entryPassed(threshold: number, score: number, advance?: boolean) {
		if (advance) {
			return true;
		}

		if (advance === false) {
			return false;
		}

		return score >= threshold;
	}

	function getStatue(score: number, voteOptions: VoteOption[]) {
		var option = voteOptions.filter((x) => x.value && score >= x.value);

		if (option && option.length > 0) {
			return option[0];
		}

		return voteOptions.find((x) => x.value == 1);
	}

	function pushEntry(entryId: number) {
		advanceEntryInJudging(entryId)
			.then((resp) => {
				if (resp.status === 200) {
					addNewAlert({
						type: "success",
						message: "Entry was pushed successfully.",
					});
				}
			})
			.catch((error) => {});
	}

	const isOneToTenRound = formikProps.values.roundType === "1-10";

	return (
		<FormikProvider value={formikProps}>
			<BrowserHeader
				title={`${formikProps.values.name} Admin${
					formikProps.values.roundType
						? `: ${formikProps.values.roundType}`
						: ""
				}`}
			/>
			<CardContainer>
				{entryDetails && (
					<EntryDetailsModal
						{...{
							entry: entryDetails,
							showDetailsModal,
							setShowDetailsModal,
							voteOptions: localEntrySetAdminCard.voteOptions,
							updateVote: updateVote,
							roundType: localEntrySetAdminCard.roundType,
							disabled: disabled,
						}}
					/>
				)}
				<WarningModal
					show={pushEntryId !== 0}
					title={"Push Entry To Current Round"}
					message={"This entry will appear in the current round of judging."}
					close={() => setPushEntryId(0)}
				>
					<Button
						onClick={() => {
							pushEntry(pushEntryId);
							setPushEntryId(0);
						}}
					>
						OK
					</Button>
					<Button onClick={() => setPushEntryId(0)} className="button-light">
						Cancel
					</Button>
				</WarningModal>
				<CardHeader className="flex-wrap items-center gap-[1rem]">
					<h2 className="card-title">
						{localEntrySetAdminCard.name} Entry Groups
					</h2>
					<Breadcrumbs navLinks={breadcrumbs} onClick={handleClickBreadcrumb} />
				</CardHeader>
				<EntrySetControls
					className="bg-colorBackgroundLight"
					show
					disabled={disabled}
					updateEntriesLocked={updateEntriesLocked}
					showCountAbstainAndNoVotes={formikProps.values.roundType === "In/Out"}
					showThreshold={isOneToTenRound}
					entrySetAdminCard={localEntrySetAdminCard}
					updateRoundStatus={updateRoundStatus}
					updateCountAbstainAndNoVotes={updateCountAbstainAndNoVotes}
					progress={Math.round(
						(formikProps.values.votesCast / formikProps.values.votesPossible) *
							100
					)}
					roundSelection={
						<DropdownField
							name="roundOption"
							className="w-[200px]"
							placeholder="Round Selection"
							hideEmptyOption={true}
							options={roundOptions.map((option) => {
								return { label: option.name, value: option.id };
							})}
							value={
								localEntrySetAdminCard && localEntrySetAdminCard.activeRoundId
							}
							onChange={(value) => {
								const parsedRoundId = roundId ? parseInt(roundId) : 0;
								const parsedValue = value.currentTarget.value
									? parseInt(value.currentTarget.value)
									: 0;
								if (
									parsedValue === parsedRoundId ||
									parsedValue === localEntrySetAdminCard.activeRoundId
								) {
									return;
								}
								const selectedRound = roundOptions.find(
									(x) => x.id === parsedValue
								);

								const manageRoundBaseUrl = `/judging/admin/${programId}/${juryId}/${localEntrySetAdminCard.id}/manageRound`;
								if (selectedRound) {
									if (selectedRound.isActive) {
										history.push(manageRoundBaseUrl);
									} else {
										history.push(`${manageRoundBaseUrl}/${selectedRound.id}`);
									}
								}
							}}
						/>
					}
					programId={programId}
				/>

				<SortLabels>
					<hr className="h-[2px] mb-[1rem]" />
					<SortHeader
						className="mr-[1rem] ml-[61px] !font-semibold"
						columns={columns}
						noSortTriangle
						responsive
					/>
				</SortLabels>

				{!isFutureRound &&
					formikProps.values.batchCards &&
					formikProps.values.batchCards.length > 0 && (
						<DynamicHeight batchCards={formikProps.values.batchCards}>
							{formikProps.values.batchCards.map((batch, batchIndex) => (
								<StyledCollapsibleContainer>
									<CollapsibleContainer
										key={batch.id}
										id={`batch${String(batch.id)}`}
										title={
											// trim program level
											batch.label.split(">").length >= 2
												? batch.label.split(">").slice(1).join(">")
												: batch.label
										}
										{...((isOneToTenRound || disabled) && {
											icons: [
												<div className="flex items-center gap-[1rem]">
													<span className="font-normal">Threshold</span>
													<TextField
														className="threshold-field"
														name={`batchCards.${batchIndex}.threshold`}
														type="number"
														disabled={disabled}
														placeholder="Threshold"
														value={batch.threshold}
														onChange={(event) => {
															updateBatchThreshold(
																formikProps.values.juryId,
																formikProps.values.id,
																formikProps.values.activeRoundId,
																batch.id,
																Number(event.target.value)
															);
														}}
														hiddenlabel
														height="34.39px"
														background="transparent"
														hideFocusBorder
														textCenter
													/>
												</div>,
											],
										})}
									>
										{batch.roundEntries.map((entry, entryIndex) => (
											<HierarchyCard
												//   className="text-center"
												key={`entry.${entryIndex}`}
												gridColumns={generateGridColumns(columns)}
												gridArea={generateGridArea(columns)}
												responsive
											>
												<span
													className="flex gap-[.5rem] items-center cursor-pointer"
													onClick={() => viewDetails(entry)}
												>
													<p className="text-colorCopyLight underline">
														{entry.entryId}
													</p>
													<Icon
														icon="eye"
														color={theme.colorCopyLight}
														width="18px"
														height="18px"
													/>
												</span>

												<p>
													{
														entry.votes.filter(
															(vote) => vote.voteOptionId !== null
														).length
													}
													/{entry.votes.length}
												</p>
												<p title={entry.companyName}>{entry.companyName}</p>
												<p title={entry.brand}>{entry.brand}</p>
												<p title={entry.title}>{entry.title}</p>
												<Score
													color={
														entryPassed(
															batch.threshold !== undefined
																? batch.threshold
																: 0,
															entry.score!,
															entry.advance
														)
															? theme.colorSuccess
															: theme.colorDanger
													}
												>
													{formikProps.values.roundType === "In/Out" &&
														entry.votes.filter(
															(v) => v.voteOption?.name === "In"
														).length +
															"/" +
															entry.votes.filter(
																(v) => v.voteOption?.name === "Out"
															).length +
															"/" +
															entry.votes.filter(
																(v) => v.voteOption?.name === "Abstain"
															).length}
													{isOneToTenRound && entry.score}
													{formikProps.values.roundType === "Statue" &&
														(getStatue(
															entry.score!,
															localEntrySetAdminCard.voteOptions
														)?.name ||
															"Out")}
													{formikProps.values.roundType === "Grand" &&
														(entry.score && entry.score >= 1
															? "Grand"
															: "Not Grand")}
													{formikProps.values.roundType === "Winner" &&
														(entry.score && entry.score >= 1
															? "Winner"
															: "Not Winner")}
													{entry.score !== undefined && (
														<Icon
															icon="caret"
															rotation={
																entryPassed(
																	batch.threshold !== undefined
																		? batch.threshold
																		: 0,
																	entry.score,
																	entry.advance
																)
																	? "180deg"
																	: "0deg"
															}
															color={
																entryPassed(
																	batch.threshold !== undefined
																		? batch.threshold
																		: 0,
																	entry.score,
																	entry.advance
																)
																	? theme.colorSuccess
																	: theme.colorDanger
															}
														/>
													)}
												</Score>

												<p className="flex justify-center">
													<Icon
														icon={entry.isLocked ? "lock" : "unlocked"}
														onClick={() => {
															if (
																localEntrySetAdminCard.entriesLocked ||
																disabled
															) {
																return;
															}
															updateEntryLocked(
																localEntrySetAdminCard.juryId,
																localEntrySetAdminCard.id,
																localEntrySetAdminCard.activeRoundId,
																entry.id,
																entry.batchId,
																!entry.isLocked
															);
														}}
														color={
															entry.isLocked
																? theme.colorPrimary
																: theme.colorCopyLight
														}
														width="25px"
														height="25px"
													/>
												</p>
												{formikProps.values.isSingleChoiceVoting && (
													<p className="flex justify-center">
														<CheckboxField
															className="checkbox-field"
															label="Hidden"
															checked={entry.isHidden}
															disabled={disabled}
															name={`isHidden${entry.id}`}
															onChange={() =>
																updateEntryHidden(
																	localEntrySetAdminCard.juryId,
																	localEntrySetAdminCard.id,
																	localEntrySetAdminCard.activeRoundId,
																	entry.id,
																	entry.batchId,
																	!entry.isHidden
																)
															}
														/>
													</p>
												)}
												{formikProps.values.activeRoundOrder !==
													formikProps.values.totalRounds && (
													<p>
														<DropdownField
															name={`batchCards[${batchIndex}].roundEntries[${entryIndex}].advance`}
															disabled={disabled}
															options={[
																{
																	value: "Choose",
																	label: "Choose",
																	statusColor: theme.colorCopyLight,
																},
																{
																	value: "true",
																	label: "Advance",
																	statusColor: theme.colorActivation,
																},
																{
																	value: "false",
																	label: "Block",
																	statusColor: theme.colorDanger,
																},
															]}
															value={
																entry.advance === undefined ||
																entry.advance === null
																	? "Choose"
																	: entry.advance
																	? "true"
																	: "false"
															}
															nonFormik
															onChange={(event) => {
																updateAdvance(
																	formikProps.values.juryId,
																	formikProps.values.id,
																	formikProps.values.activeRoundId,
																	entry.id,
																	entry.batchId,
																	event.currentTarget.value
																);
																let advance;
																switch (event.currentTarget.value) {
																	case "Choose":
																		advance = "Choose";
																		break;
																	case "true":
																		advance = "true";
																		break;
																	case "false":
																		advance = "false";
																		break;

																	default:
																		break;
																}
																formikProps.setFieldValue(
																	`batchCards[${batchIndex}].roundEntries[${entryIndex}].advance`,
																	advance
																);
															}}
															hiddenLabel
															hideEmptyOption
															showStatusColor
															height="34.39px"
														/>
													</p>
												)}
												{disabled &&
													activeRoundExists &&
													entry.score !== undefined &&
													!entryPassed(
														batch.threshold !== undefined ? batch.threshold : 0,
														entry.score,
														entry.advance
													) && (
														<Button
															onClick={() => setPushEntryId(entry.entryId)}
														>
															Push to current
														</Button>
													)}
											</HierarchyCard>
										))}
									</CollapsibleContainer>
								</StyledCollapsibleContainer>
							))}
						</DynamicHeight>
					)}
				{isFutureRound && (
					<p>This round has not yet started. Please select another round</p>
				)}
			</CardContainer>
		</FormikProvider>
	);
};

interface EntrySetAdministrationData {
	eid: number;
	jurorVoteCount: number;
	totalVoteCount: number;
	CompanyName: string;
	Brand: string;
	title: string;
	score: number;
	isLocked: boolean;
	roundOverrideStatus: RoundOverrideStatus;
}
interface EntrySetAdministrationConfig {
	name: string;
	isSelected: boolean;
}

interface EntrySetGroups {
	entrySet: EntrySetAdministrationConfig;
	entries: EntrySetAdministrationData[];
}

export interface EntrySetAdminRoundSelection {
	id: number;
	name: string;
	isActive: boolean;
}

enum RoundOverrideStatus {
	Choose,
	Advance,
	Block,
}

interface EntrySetControlsProps extends JuryEntrySetControlsProps {
	programId?: string;
}

export default EntrySetAdministration;
