import assetsConfig from "../../assetsConfig";
import { linkDTO } from "./Sidebar.model";

export const SidebarData: linkDTO[] = [
  {
    title: "Library",
    path: "/library/entries",
    icon: "home",
    className: "",
    sublinks: [
      {
        title: assetsConfig.labels.entry.plural,
        path: "/library/entries",
      },
      {
        title: "Orders",
        path: "/library/orders",
      },
      {
        title: "Users",
        path: "/library/users",
      },
      {
        title: "Companies",
        path: "/library/companies",
      },
      {
        title: "Company Credits",
        path: "/library/company-credits",
      },
      {
        title: "Individual Credits",
        path: "/library/individual-credits",
      },
      {
        title: "Jurors",
        path: "/library/jurors",
      },
    ],
  },
  {
    title: "Program",
    path: "/program/field-templates",
    icon: "library",
    className: "",
    sublinks: [
      {
        title: "Season",
        path: "/program/season",
      },
      {
        title: "Award",
        path: "/program/award",
      },
      {
        title: "Program Setup",
        path: "/program/program-setup",
      },
      {
        title: "Field Templates",
        path: "/program/field-templates",
      },
      {
        title: "Program Display",
        path: "/program/program-display",
      },
    ],
  },
  {
    title: "Settings",
    path: "/settings/commerce",
    icon: "gear",
    className: "",
    sublinks: [
      {
        title: "Commerce Settings",
        path: "/settings/commerce",
      },
      {
        title: "Pause Slide",
        path: "/settings/pause-slide",
      },
    ],
  },
  {
    title: "Judging",
    path: "/judging/config",
    icon: "statue",
    className: "",
    sublinks: [
      {
        title: "Configuration",
        path: "/judging/config",
      },
      {
        title: "Administration",
        path: "/judging/admin",
      },
      {
        title: "Judging Reports",
        path: "/judging/reports",
      },
      // {
      //   title: "Live Tools",
      //   path: "/judging/live-tools",
      // },
      // {
      //   title: "Reporting",
      //   path: "/judging/report",
      // },
    ],
  },
  {
    title: "Reports",
    path: "/reports",
    icon: "reports",
    className: "",
    sublinks: [],
  },
  {
    title: "Review",
    path: "/review",
    icon: "entry-review",
    className: "",
    sublinks: [
      {
        title: "Progress",
        path: "/review/progress",
      },
      {
        title: "Assignment",
        path: "/review/assignment",
      },
    ],
  },
  {
    title: "Logout",
    path: "/logout",
    icon: "logout",
    className: "",
    sublinks: [],
  },
];

// used for demo, removes most super admin pages
export const limitedSidebar: linkDTO[] = [
  /* {
		title: "Review",
		path: "/review",
		icon: "home",
		className: "",
		sublinks: [
			{
				title: "Progress",
				path: "/review/progress",
			},
			{
				title: "Assignment",
				path: "/review/assignment",
			},
		],
	}, */
  {
    title: "Library",
    path: "/library/entries",
    icon: "search",
    className: "",
    sublinks: [
      {
        title: assetsConfig.labels.entry.plural,
        path: "/library/entries",
      },
      // {
      // 	title: "Orders",
      // 	path: "/library/orders",
      // },
      {
        title: "Users",
        path: "/library/users",
      },
      // {
      // 	title: "Companies",
      // 	path: "/library/companies",
      // },
      {
        title: "Company Credits",
        path: "/library/company-credits",
      },
      {
        title: "Individual Credits",
        path: "/library/individual-credits",
      },
      // {
      // 	title: "Jurors",
      // 	path: "/library/jurors",
      // },
    ],
  },
  // {
  // 	title: "Program",
  // 	path: "/program/field-templates",
  // 	icon: "library",
  // 	className: "",
  // 	sublinks: [
  // 		{
  // 			title: "Season",
  // 			path: "/program/season",
  // 		},
  // 		{
  // 			title: "Award",
  // 			path: "/program/award",
  // 		},
  // 		{
  // 			title: "Program Setup",
  // 			path: "/program/program-setup",
  // 		},
  // 		{
  // 			title: "Field Templates",
  // 			path: "/program/field-templates",
  // 		},
  // 		{
  // 			title: "Program Display",
  // 			path: "/program/program-display",
  // 		},
  // 	],
  // },
  // {
  // 	title: "Settings",
  // 	path: "/settings/commerce",
  // 	icon: "gear",
  // 	className: "",
  // 	sublinks: [
  // 		{
  // 			title: "Commerce Settings",
  // 			path: "/settings/commerce",
  // 		},
  // 		{
  // 			title: "Pause Slide",
  // 			path: "/settings/pause-slide",
  // 		},
  // 	],
  // },
  {
    title: "Judging",
    path: "/judging/config",
    icon: "statue",
    className: "",
    sublinks: [
      // {
      // 	title: "Configuration",
      // 	path: "/judging/config",
      // },
      {
        title: "Administration",
        path: "/judging/admin",
      },
      {
        title: "Judging Reports",
        path: "/judging/reports",
      },
      // {
      //   title: "Live Tools",
      //   path: "/judging/live-tools",
      // },
      // {
      //   title: "Reporting",
      //   path: "/judging/report",
      // },
    ],
  },
  // {
  // 	title: "Reports",
  // 	path: "/reports",
  // 	icon: "reports",
  // 	className: "",
  // 	sublinks: [],
  // },

  {
    title: "Logout",
    path: "/logout",
    icon: "logout",
    className: "",
    sublinks: [],
  },
];
