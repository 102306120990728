import styled, { css } from "styled-components";
import Sortable, { MultiDrag, SortableEvent } from "sortablejs";
import { Checked } from "../FormFields/CheckboxField";

Sortable.mount(new MultiDrag());

export const DragCard = styled.div<{
	className?: string;
	hideOnDrag?: boolean;
	disabled?: boolean;
}>`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	margin: 1px;
	outline: 1px solid ${({ theme }) => theme.colorBorder};
	background: ${({ theme }) => theme.colorBackgroundLight};
	/* transition: all 150ms ease;
	max-height: 100px; */
	cursor: pointer;

	&.sortable-selected {
		background: ${({ theme }) => theme.colorSelected};

		.checkbox-field {
			${Checked};
		}
	}

	&.sortable-ghost {
		background: ${({ theme }) => theme.colorAwardThree};
	}

	.drag-icons {
		display: flex;
		align-items: center;
		width: 100%;

		.drag-arrows {
			margin-right: 1.25rem;
		}
	}

	${(p) =>
		p.hideOnDrag &&
		css`
			display: none;
		`};

	.collapsible {
		display: none;
	}

	.details {
		flex: 1;
	}

	.triangle,
	.caret-down,
	.close-icon {
		display: none;
		cursor: pointer;
	}

	.icon-container {
		display: none;
	}

	.checkbox-field {
		display: flex;
		justify-content: center;
		margin-left: auto;
		input {
			margin-right: 0;
		}
	}

	p {
		max-width: 130px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		line-height: 1rem;

		&.media-type,
		&.upload-date {
			font-size: ${({ theme }) => theme.xxSmallSize};
		}

		&.media-type {
			width: 110px;
		}

		&.media-file-name {
			max-width: 300px;
			font-size: ${({ theme }) => theme.xSmallSize};
			font-weight: ${({ theme }) => theme.fontSemiBold};
			white-space: pre-wrap;
			overflow-wrap: anywhere;
		}
	}

	.light {
		color: ${({ theme }) => theme.colorCopyLight};
	}

	@media only screen and (min-width: 1050px) {
		p {
			max-width: 140px;
		}
	}

	@media only screen and (min-width: 1100px) {
		p {
			max-width: 160px;
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.xl}) {
		p {
			max-width: 190px;
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.xxl}) {
		p {
			max-width: 225px;
		}
	}

	// css only for credit drag
	&.credit-drag {
		p {
			font-size: 0.875rem;
			line-height: 1.15rem;
		}
		.credits {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.credit-fields {
				flex: 1;
			}
		}

		.drag-icons {
			width: initial;
		}
	}
`;

export const DropZone = styled.div<{
	isInDropzone: boolean; // default = true, cards in dropzone can be expanded, downloaded, etc
	maxHeight?: string;
	isError?: boolean;
	success?: boolean;
	disabled?: boolean;
	hideShadow?: boolean;
}>`
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 194px; // 1 card plus border
	background: ${({ theme }) => theme.colorBackgroundLightLight};

	transition: max-height 0.15s ease;

	${(p) =>
		!p.hideShadow &&
		css`
			box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
		`};

	${(p) =>
		p.disabled &&
		css`
			background: ${({ theme }) => theme.colorFieldReadOnly};
		`};

	${(p) =>
		p.maxHeight &&
		css`
			max-height: ${p.maxHeight};
		`};

	${(p) =>
		p.isError &&
		css`
			border: 1px dashed ${({ theme }) => theme.colorDanger};
		`};

	${(p) =>
		p.success &&
		css`
			border: 1px dashed ${({ theme }) => theme.colorSuccess};
		`};

	${(p) =>
		p.isInDropzone &&
		css`
			.draggable {
				cursor: default;

				// only apply for draggable media, not credits
				&.drag-media {
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;

					.drag-arrows {
						display: none;
					}
				}

				.collapsible {
					display: flex;
					gap: 1rem;
				}

				.details {
					justify-content: flex-start;
					gap: 1rem;
					flex: 1;
					width: 100% !important;
				}

				.media-file-name,
				.field p {
					max-width: unset;
				}

				.field p {
					white-space: pre-wrap;
				}

				.caret-down,
				.close-icon {
					display: flex;
				}

				.checkbox-field {
					display: none;
				}

				.icon-container {
					display: flex;
					justify-content: end;
					align-items: center;
					gap: 0.5rem;
				}
			}
		`}
`;

export const filterDuplicates = <T extends DragItem>(arr: T[]): T[] => {
	const ids = arr.map(({ id }) => id);
	const filtered = arr.filter(({ id }, index) => !ids.includes(id, index + 1));

	return filtered;
};

export const checkMax = <T extends DragItem>(
	newCredit: T[],
	max?: number
): boolean => {
	// check if dropping a new drag item would exceed the file limit
	const wouldMaxDropped =
		max !== undefined && max !== 0
			? newCredit.length !== 0 && newCredit.length > max
			: false;

	return wouldMaxDropped;
};

export const sortableOptions = (props: SortableOptionsProps) => ({
	group: {
		name: props.group,

		// pull: ability to move from the list.
		...(props.clone && ({ pull: "clone" } as any)),

		// put: ability to add elements from other lists
		...(props.drop === false && ({ put: false } as any)),
	},
	sort: props.sortable === false ? false : true,
	multiDrag: props.allowMultiSelect,

	animation: 150,
	easing: "ease",

	selectedClass: "sortable-selected", // Class name for selected item
	ghostClass: "sortable-ghost", // Class name for the drop placeholder
	chosenClass: "sortable-chosen", // Class name for the chosen item
	dragClass: "sortable-drag", // Class name for the dragging item

	draggable: ".draggable", // only allow elements with this class to be dragged
	// preventOnFilter: true, // Call `event.preventDefault()` when triggered `filter`

	onMove: (e: any) => {
		// hide any duplicates when cloning
		if (props.clone) {
			Array.from(e.from.children as HTMLCollectionOf<HTMLElement>).forEach(
				(draggable) => {
					if (
						draggable.classList.contains("sortable-selected") &&
						!draggable.classList.contains("sortable-chosen")
					) {
						draggable.style.display = "none";
					}
				}
			);
		}

		// prevent element with .no-drag to be moved
		return e.related.className.indexOf("no-drag") === -1;
	},

	onEnd: (e: any) => {
		// show all cards after dnd is complete
		Array.from(e.from.children as HTMLCollectionOf<HTMLElement>).forEach(
			(draggable) => {
				draggable.style.display = "flex";
			}
		);

		// 	deselect items after moving to another list
		for (let i in e.items) {
			Sortable.utils.deselect(e.items[i]);
		}
	},
});

export interface DragItem {
	id: any;
}

export interface DraggableProps {
	name: string; // field name for formik, must be unqiue
	clone?: boolean; // copy the item rather than move
	drop?: boolean; // disables dropping to the list
	sortable?: boolean; // default = true, allow weight change
	allowMultiSelect?: boolean; // default = false
}

interface SortableOptionsProps extends DraggableProps {
	group: string;
}
