import { useContext, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import Button from "../../../../components/Button/Button";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import { FormikProvider, useFormik, FieldArray } from "formik";
import DropdownField from "../../../../components/FormFields/DropdownField";
import { CardBody } from "../../../../components/DashboardCard/DashboardCard";
import { SelectBox } from "./EntrySetTab";
import { SquareButton } from "../../../../components/Button/Button";
import { RoundBankCard } from "./RoundsTab";
import { ButtonContainer } from "./JudgingConfigJury";
import { JurorCardConfig, JuryCardModel } from "../JudgingInterfaces";
import {
	UserSearchRequest,
	UserSearchResult,
} from "../../Search/UserSearchTab";
import { urlUserAdmin } from "../../../../endpoints";
import axios from "axios";
import {
	addJurorToFinalizedJury,
	removeJurorFromFinalizedJury,
	finalizeJuryPool,
	getJuryPool,
	saveJurors,
} from "../manageJudging";
import { LoadingContext, ModalContext } from "../../../../App";
import { JuryConfigTabProps } from "./JudgingConfigJury";
import HierarchyNav, {
	HierarchyCard,
} from "../../../../components/HierarchyNav/HierarchyNav";
import Icon from "../../../../components/Icon/Icon";
import { useAlert } from "../../../../components/Alert/Alerts";

export const JURY_ROLES_OPTIONS = [{ value: "Juror" }, { value: "Jury Chair" }];

const FilterBox = styled.div`
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	grid-auto-flow: column;
	gap: 0;
	justify-content: space-between;
	width: 100%;
	padding: 1rem;
	color: ${({ theme }) => theme.colorCopyLightLight};
	background: ${({ theme }) => theme.colorBackgroundDarkDark};

	p {
		display: flex;
		justify-content: center;
	}
`;

const JuryPoolTab = (props: JuryConfigTabProps) => {
	const [jurors, setJurors] = useState<JurorCardConfig[]>([]);
	const [users, setUsers] = useState<UserSearchResult[]>([]);
	const { addNewAlert } = useAlert();
	const { setModal } = useContext(ModalContext);
	const { setLoadingMessage } = useContext(LoadingContext);
	const theme = useTheme();

	const StyledIconContainer = styled.div`
		vertical-align: text-bottom;
		display: inline-block;
	`;

	const formikProps = useFormik({
		initialValues: {
			juryPool: jurors,
		},
		enableReinitialize: true,
		onSubmit: async (value) => {},
	});

	const handleSave = () => {
		saveJurors(formikProps.values.juryPool)
			.then((res) => {
				if (res.status == 200) {
					getJuryPool(Number(props.juryId)).then((res) => {
						if (res.status == 200) {
							//console.log("Pool", res.data);
							setJurors(res.data);
						}
					});
					addNewAlert({
						type: "success",
						message: "saved successfully",
					});
				}
			})
			.catch((err) => {
				//console.log("jurors error: ", err);
				addNewAlert({
					type: "error",
					message: "Problem saving Jurors",
				});
			});
	};

	useEffect(() => {
		getJuryPool(Number(props.juryId))
			.then((res) => {
				if (res.status == 200) {
					//console.log("Pool", res.data);
					setJurors(res.data);
				}
			})
			.catch((err) => {
				//console.log("jurors error: ", err);
				addNewAlert({
					type: "error",
					message: "Problem getting Jurors",
				});
			});
	}, []);

	function getUsers(value: string): void {
		const request: UserSearchRequest = {
			email: value,
			fullName: value,
			id: value,
		};
		axios
			.post(`${urlUserAdmin}/searchOr`, request, {
				params: { page: 1, recordsPerPage: 999 },
			})
			.then((response) => {
				if (response.status === 200) {
					setUsers(response.data);
				}
			})
			.catch((err) => {
				//console.log("search error: ", err);
				addNewAlert({
					type: "error",
					message: "Problem searching users",
				});
			});
	}

	function createNewJurorPostFinalization(
		user: UserSearchResult,
		users: UserSearchResult[]
	) {
		setLoadingMessage("Adding Juror to Jury... Please wait.");
		const newJuror: JurorCardConfig = {
			companyCity: user.city,
			companyName: user.companyName!,
			email: user.email!,
			fullName: user.fullName!,
			id: 0,
			juryName: "",
			isActive: true,
			juryId: Number(props.juryId),
			userId: user.id!,
			jurorType: "Juror",
		};

		addJurorToFinalizedJury(newJuror)
			.then((response) => {
				setLoadingMessage(null);
				if (response.status === 201) {
					setJurors((prevState) => {
						return [...prevState, response.data];
					});
					// remove from current search results
					const newUsersList = users.filter(
						(x) => x.id !== response.data.userId
					);
					setUsers(newUsersList);
					addNewAlert({ type: "success", message: "Juror has been added." });
				}
			})
			.catch((error) => {
				addNewAlert({ type: "error", message: "Juror could not be added." });
				setLoadingMessage(null);
			});
	}

	function removeJurorPostFinalization(jurorId: number) {
		setLoadingMessage("Removing Juror from Jury... Please wait.");
		removeJurorFromFinalizedJury(jurorId)
			.then((response) => {
				setLoadingMessage(null);
				if (response.status === 200) {
					// remove from jurors list
					const newJurorsList = jurors.filter((x) => x.id !== jurorId);
					setJurors(newJurorsList);
					addNewAlert({ type: "success", message: "Juror has been removed." });
				}
			})
			.catch((error) => {
				addNewAlert({ type: "error", message: "Juror could not be removed." });
				setLoadingMessage(null);
			});
	}

	function createNewJuror(user: UserSearchResult) {
		const newJuror: JurorCardConfig = {
			companyCity: user.city,
			companyName: user.companyName!,
			email: user.email!,
			fullName: user.fullName!,
			id: 0,
			juryName: "",
			isActive: true,
			juryId: Number(props.juryId),
			userId: user.id!,
			jurorType: "Juror",
		};

		setJurors((prevState) => {
			return [...prevState, newJuror];
			//return [...formikProps.values.juryPool, newJuror];
		});
	}

	const isJuryAdded = !!jurors.filter((jd) => jd.isActive).length;

	useEffect(() => {
		getUsers("");
	}, []);

	return (
		<FormikProvider value={formikProps}>
			<CardBody>
				<div className="grid grid-cols-2 gap-[2rem] h-full">
					<div>
						<h2 className="mb-[1rem] ml-[0.25rem]">Jury Pool</h2>

						{formikProps.values.juryPool.length > 0 ? (
							<HierarchyNav
								className="flex-1 h-[50vh] overflow-y-auto"
								columns={[
									{ label: "Full Name" },
									{ label: "Company" },
									{ label: "City" },
									{ label: "Role", width: "100px" },
									{ label: null, width: "30px" },
								]}
								isCenterLabel
								noSortTriangle
								darkLabel
								noGap
							>
								{(gridColumns: string, gridArea: string) =>
									formikProps.values.juryPool.map((juror, index) => {
										const handleRemove = (id: number) => {
											if (props.juryCard?.isRoundAssignmentFinal) {
												setModal({
													title: "Remove Juror from Finalized Jury",
													message: (
														<span className="text-center max-w-[500px]">
															{`Do you want to remove ${juror.fullName} from this jury? 
                              Any votes they have cast in active rounds will be deleted and they will no longer be able to access the judging system`}
														</span>
													),
													headerIcon: "invalid",
													onClickClose: () => setModal(null),
													onConfirm: () =>
														removeJurorPostFinalization(juror.id),
													confirmText: "Yes",
												});
											} else {
												// remove from jurors list
												const newJurorsList = jurors.filter(
													(x) => x.id !== juror.id
												);
												setJurors(newJurorsList);
											}
										};
										return (
											<HierarchyCard
												key={`user.${index}`}
												gridColumns={gridColumns}
												gridArea={gridArea}
											>
												<p className="text-center truncate max-w-full">
													{juror.fullName}
												</p>
												<p className="text-center truncate max-w-full">
													{juror.companyName}
												</p>
												<p className="text-center truncate max-w-full">
													{juror.companyCity}
												</p>

												<FieldArray name="juryPool" validateOnChange={false}>
													{() => (
														<>
															<DropdownField
																options={JURY_ROLES_OPTIONS}
																name={`juryPool.${index}.jurorType`}
																value={juror.jurorType}
																placeholder="Role*"
																hiddenLabel
																height="42px"
															/>
															<SquareButton
																className="ml-auto"
																onClick={() => {
																	handleRemove(juror.id!);
																}}
																icon="close"
																iconSize="21px"
															/>
														</>
													)}
												</FieldArray>
											</HierarchyCard>
										);
									})
								}
							</HierarchyNav>
						) : (
							<SelectBox className="flex-col flex-1 !items-start !justify-start h-3/6">
								<p className="m-auto">Please add a juror to the Jury pool</p>
							</SelectBox>
						)}
					</div>
					<div>
						<h2>Select Jurors</h2>
						<SearchBar
							className="my-4 !w-2/3 !max-w-2/3 search-input"
							searchPlaceholder="Search by name or email"
							onChange={(e) => getUsers(e.currentTarget.value)}
							showBorder
						/>

						<HierarchyNav
							className="flex-1 h-[50vh] overflow-y-auto"
							columns={[
								{ label: "Full Name" },
								{ label: "Email" },
								{ label: "Company" },
								{ label: null, width: "30px" },
							]}
							isCenterLabel
							noSortTriangle
							darkLabel
							noGap
						>
							{(gridColumns: string, gridArea: string) =>
								users.map((data, index) => {
									const handleAdd = (id: string) => {
										// create new juror

										if (props.juryCard?.isRoundAssignmentFinal) {
											setModal({
												title: "Add Juror to Finalized Jury",
												message: (
													<span className="text-center max-w-[500px]">
														{`Do you want to add ${data.fullName} to the ${props.juryCard.name} Pool?`}
													</span>
												),
												headerIcon: "invalid",
												onClickClose: () => setModal(null),
												onConfirm: () =>
													createNewJurorPostFinalization(data, users),
												confirmText: "Yes",
											});
										} else {
											createNewJuror(data);
											// remove from current search results
											const newUsersList = users.filter((x) => x.id !== id);
											setUsers(newUsersList);
										}
									};
									return (
										<HierarchyCard
											key={`user.${index}`}
											gridColumns={gridColumns}
											gridArea={gridArea}
										>
											<p className="text-center truncate max-w-full">
												{data.fullName}

												{data.excludeFromJuries && (
													<StyledIconContainer>
														<Icon
															defaultCursor={true}
															icon="close"
															color={theme.colorDanger}
															width="21px"
															height="21px"
															className="ml-2"
														/>
													</StyledIconContainer>
												)}
											</p>
											<p className="text-center truncate max-w-full">
												{data.email}
											</p>
											<p className="text-center truncate max-w-full ">
												{data.companyName}
											</p>
											<SquareButton
												className="ml-auto"
												onClick={() => {
													handleAdd(data.id!);
												}}
												icon="plus"
											/>
										</HierarchyCard>
									);
								})
							}
						</HierarchyNav>
					</div>
				</div>

				<ButtonContainer>
					<Button className="button-light" onClick={handleSave}>
						Save
					</Button>
					<Button
						icon="check"
						disabled={!props.juryCard || props.juryCard.isJuryPoolFinal}
						onClick={() => {
							finalizeJuryPool(Number(props.juryId)).then((res) => {
								if (res.status === 204) {
									props.setJuryCard((prevState) => {
										return {
											...(prevState as JuryCardModel),
											isJuryPoolFinal: true,
										};
									});
								}
							});
						}}
					>
						{props.juryCard && props.juryCard.isJuryPoolFinal
							? "Jury Pool Finalized"
							: "Finalize Jury Pool"}
					</Button>
				</ButtonContainer>
			</CardBody>
		</FormikProvider>
	);
};

export default JuryPoolTab;
