import styled from "styled-components";
import SearchBar from "../SearchBar/SearchBar";

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 3.125rem 2.875rem 1.21875rem 2.875rem;
  background: ${({ theme }) => theme.colorBackgroundDarkDark};

  .card-title {
    font-family: ${({ theme }) => theme.fontBrand};
    font-weight: ${({ theme }) => theme.fontDefaultWeight};
    color: ${({ theme }) => theme.colorCopyLightLight};
    font-size: 36px;
  }
`;

export const CardBody = styled.div`
  padding: 2rem 3.09375rem;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* padding-bottom: 2.5rem; */
  background: ${({ theme }) => theme.colorBackgroundLightLight};
  box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
`;

const DashboardCard = (props: DashboardCardProps) => {
  return (
    <CardContainer>
      <CardHeader>
        <h2 className="card-title">{props.title}</h2>
        {props.hasSearch && <SearchBar label="Search Dashboard" />}
      </CardHeader>
      <CardBody>{props.children}</CardBody>
    </CardContainer>
  );
};

export default DashboardCard;

interface DashboardCardProps {
  children: React.ReactNode;
  className?: string;
  hasSearch?: boolean;
  title: string;
}
