import type { Identifier, XYCoord } from "dnd-core";
import type { FC } from "react";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import Icon from "../Icon/Icon";
import { ItemTypes, DragItem } from "../ExecutionDragCard/ExecutionDragCard";
import styled, { useTheme } from "styled-components";
// import { Collapsible } from "../StickyCard/StickyCard";
import Collapsible from "../Collapsible/Collapsible";
import { SquareButton } from "../Button/Button";
import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import TextField from "../FormFields/TextField";
import CheckboxField from "../FormFields/CheckboxField";
import { ROUNDS_KEY } from "../../views/Admin/Judging/JudgingConfig/RoundsTab";
import { RoundBankCard } from "../../views/Admin/Judging/JudgingConfig/RoundsTab";
import { RoundStructureConfig } from "../../views/Admin/Judging/JudgingInterfaces";

const RoundDragCard: FC<RoundCardProps> = ({
  id,
  round,
  index,
  handleRemove,
  handleExpand,
  moveRoundCard,
  arrayHelpers,
}) => {
  const { values } = useFormikContext<any>();
  const theme = useTheme();

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveRoundCard(arrayHelpers, dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div
      className="w-full"
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      <RoundBankCard className="flex flex-col w-full">
        <div className="flex justify-between items-center w-full gap-[1rem]">
          <Icon icon="info" color={theme.colorCopyLight} />
          <p>{round.name}</p>
          {/* <p>Round {index + 1}</p> */}
          <Icon
            icon="drag-arrows"
            color={theme.colorPrimary}
            className="flex items-center cursor-pointer ml-auto"
          />
          <Icon
            icon="expandCaret"
            color={theme.colorPrimary}
            height="20px"
            width="20px"
            rotation={round.isCollapsed ? "0" : "180deg"}
            onClick={() =>
              handleExpand(
                `${ROUNDS_KEY}.${index}.isCollapsed`,
                round.isCollapsed
              )
            }
          />

          <SquareButton
            onClick={() => handleRemove(index)}
            icon="close"
            iconRight
            iconSize="21px"
          />
          {/* <Icon
            icon="closeLarge"
            color={theme.colorPrimary}
            onClick={() => handleRemove(round.id, round.index!)}
          /> */}
        </div>

        <Collapsible className="w-full" isCollapsed={round.isCollapsed}>
          <div className="flex gap-[1rem]">
            <FieldArray name={ROUNDS_KEY} validateOnChange={false}>
              {() => (
                <>
                  <TextField
                    className="ml-[33px] mt-[1rem] flex-1"
                    name={`${ROUNDS_KEY}.${index}.jurorInstructions`}
                    placeholder="Juror instructions"
                    component="textarea"
                    height="80px"
                    value={round.jurorInstructions}
                  />
                  <CheckboxField
                    className="mt-[1rem]"
                    name={`${ROUNDS_KEY}.${index}.isFeedbackRequired`}
                    placeholder="Require Feedback"
                  />
                </>
              )}
            </FieldArray>
          </div>
        </Collapsible>
      </RoundBankCard>
    </div>
  );
};

export default RoundDragCard;

interface RoundCardProps {
  id: number;
  round: RoundStructureConfig;
  index: number;
  moveRoundCard: (
    arrayHelpers: FieldArrayRenderProps,
    from: number,
    to: number
  ) => void;
  handleRemove: (index: number) => void;
  handleExpand: (name: string, currentValue: boolean) => void;
  arrayHelpers: FieldArrayRenderProps;
}
