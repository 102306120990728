import styled, { useTheme } from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/src/stylesheets/datepicker.scss";
import * as BsIcons from "react-icons/bs";
import { useState, useRef } from "react";
import { FieldError, Label } from "./FieldTemplate";
import { css } from "styled-components";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { FormField, FocusBorder } from "./FieldTemplate";
import Icon from "../Icon/Icon";
import dateTostring, {
  dateTimeToUTC,
  longDateToString,
} from "../../utils/dateToString";
import FieldWrapper from "./FieldWrapper";

const DatePickerWrapper = styled(FormField)<{ zIndex?: number }>`
  max-width: 220px;

  .react-datepicker {
    display: flex;
  }

  // time container
  .react-datepicker__time-container {
    width: auto;
    .react-datepicker__header--time {
      height: 59px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .react-datepicker-time__header::after {
      content: " (EST)";
    }
  }

  .react-datepicker-popper {
    ${(p) =>
      p.zIndex
        ? css`
            z-index: ${p.zIndex};
          `
        : css`
            z-index: 999999;
          `}
  }

  .react-datepicker__triangle {
    left: -15px !important;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 1.25rem 1rem 0.375rem;
  width: 100%;
  height: 56px;
  background: transparent;
  /* padding: 20px 16px; */
  z-index: 0;

  &:focus {
    outline: none;
  }
`;

const TZDatePickerField = (props: DatePickerFieldProps) => {
  const theme = useTheme();
  const { values, setFieldValue, errors } = useFormikContext<any>();
  const [date, setDate] = useState<Date | null>(null);
  // const [convertedDate, setConvertedDate] = useState<Date | null>(null);
  const [focused, setFocused] = useState(false);
  const dateIconRef = useRef<any>(null);

  const handleChange = (date: Date) => {
    let utc: number | undefined = undefined;

    if (date != undefined) {
      // setDate(date);
      utc = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours() + 5, // EST is 5 hours behind UTC
        date.getMinutes()
      );
    }

    props.onChange && props.onChange(date);
    !props.nonFormik && setFieldValue(props.name, utc);
  };

  useEffect(() => {
    if (props.value) {
      const convertedDate = new Date(
        new Date(props.value).toLocaleString("en-US", {
          timeZone: "EST",
        })
      );
      setDate(convertedDate);
    } else {
      setDate(null);
    }
  }, [props.value]);

  return (
    <FieldWrapper
      className={props.className}
      name={props.name}
      success={props.success}
      error={props.error}
    >
      {(success, error) => (
        <DatePickerWrapper
          hiddenlabel={false}
          className={`datepicker-wrapper ${focused ? "focused" : ""} ${
            values[props.name] || props.value ? "hasValue" : ""
          } ${props.className ? props.className : ""}`}
          onClick={() => dateIconRef.current.onInputClick()}
          readOnly={props.readOnly}
          success={success}
          error={error}
        >
          <Label htmlFor={`date-picker-${props.name}`}>
            {props.placeholder ? props.placeholder : "Click to Select a Date"}
          </Label>
          {/* <p className="mt-[1rem]">{date && String(date)}</p> */}
          <StyledDatePicker
            dateFormat={
              props.showTimeSelect ? "yyyy/MM/dd h:mm a" : "yyyy/MM/dd"
            }
            id={`date-picker-${props.name}`}
            selected={date}
            onChange={(date: Date) => handleChange(date)}
            ref={dateIconRef}
            showTimeSelect={props.showTimeSelect || false}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            disabled={props.readOnly}
          />
          <Icon
            className="absolute right-[16px] top-[50%] translate-y-[-50%] pointer-events-none"
            icon="calendar"
            color={theme.colorCopyLight}
            width="15px"
          />
          <FocusBorder />
        </DatePickerWrapper>
      )}
    </FieldWrapper>
  );
};

export default TZDatePickerField;

interface DatePickerFieldProps {
  className?: string;
  value?: number | null;
  onChange?(date: Date): void;
  placeholder?: string;
  showTimeSelect?: boolean;
  name: string;
  nonFormik?: boolean;
  readOnly?: boolean;
  success?: string;
  error?: string;
}
