import { useHistory } from "react-router-dom";
import Icon from "../Icon/Icon";

const PrimaryLogo = (props: {
	color: string;
	width: string;
	className?: string;
}) => {
	let history = useHistory();
	return (
		<Icon
			icon="logo"
			color={props.color}
			className={`cursor-pointer ${props.className ? props.className : ""}`}
			width={props.width}
			onClick={() => history.push("/")}
		/>
	);
};

export default PrimaryLogo;
