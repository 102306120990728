import axios from "axios";
import { urlAwards, urlReports, urlSeasons } from "../../../endpoints";
import { DropdownOptions } from "../../../hooks/useAwardableAwards";

export async function getSeasonsDropdown() {
  try {
    const response = await axios.get<DropdownOptions[]>(
      `${urlSeasons}/getSeasonsDropdown`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function getAwardsDropdown() {
  try {
    const response = await axios.get<DropdownOptions[]>(
      `${urlAwards}/getAwardsDropdown`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export const getEntryReviewerCounts = async (
  seasonId: number,
  awardId: number
): Promise<EntryReviewReport[]> => {
  try {
    const response = await axios.get<EntryReviewReport[]>(
      `${urlReports}/entry-review-counts/${seasonId}/${awardId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "An error occurred while fetching entry reviewer counts: ",
      error
    );
    return [];
  }
};

export const getDeadlineUploadReport = async (
  seasonId: number,
  awardId: number
): Promise<DeadlineUploadReport[]> => {
  try {
    const response = await axios.get<DeadlineUploadReport[]>(
      `${urlReports}/program-deadlines/${seasonId}/${awardId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "An error occurred while fetching entry reviewer counts: ",
      error
    );
    return [];
  }
};

export const EntryReviewerHeaders: { [key: string]: string } = {
  reviewerName: "Reviewer",
  readyForReviewCount: "Ready For Review",
  updatedCount: "Updated",
  failReportPendingCount: "Fail Report Pending",
  failReportSentCount: "Fail Report Sent",
  withdrawnCount: "Withdrawn",
  passedCount: "Passed",
};

export interface EntryReviewReport {
  [key: string]: any;
  ReviewerName: string;
  ReadyForReviewCount: number;
  UpdatedCount: number;
  FailReportPendingCount: number;
  FailReportSentCount: number;
  WithdrawnCount: number;
  PassedCount: number;
}

interface DeadlineUploadReport {
  vertical: string;
  season: string;
  item: string;
  price: number;
}
