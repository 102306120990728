import { memo, useEffect, useRef } from "react";
import styled, { useTheme } from "styled-components";
import Button, { ButtonLink } from "../../components/Button/Button";
import {
	HoverMenu,
	HoverMenuLink,
	LinkLabel,
	ExpandMenu,
} from "../../components/HoverMenu/HoverMenu";
import { useState } from "react";
import { CSSTransition } from "react-transition-group";

import { Link } from "react-router-dom";
import { logout } from "../../components/Auth/handleJWT";
import { getCompanyOrders } from "../Checkout/manageCheckout";

import Icon from "../../components/Icon/Icon";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import ProgressCircle from "../../components/ProgressCircle/ProgressCircle";
import { TransparentDropdown } from "../../components/FormFields/DropdownField";
import { FormikProvider, useFormik } from "formik";
import { DynamicTransitionDuration } from "../../globalStyles";
import { OrderCounter } from "../Admin/User/UserMenu";
import { HamburgerMenu } from "../Admin/User/UserMenu";
import { roundTime } from "../../utils/getCountdownTimer";
import { numberToDate } from "../../utils/dateToString";
import JudgeNowButton, { AvailableEntryRounds } from "./JudgeNowButton";
import {
	JurorJuryCard,
	EntrySetAdminCard,
	JurorCardConfig,
} from "../Admin/Judging/JudgingInterfaces";
import { getJurors } from "../Admin/User/managerUser";
import AnimatedAntenna from "../../assets/AnimatedAntenna50px.gif";
import { svgString } from "../../globalStyles";
import { ReactComponent as CaretDown } from "../../assets/icons/Down.svg";
import { AwardModel } from "../Admin/Awards/Awards";
import AwardCard from "../Admin/Awards/AwardCard";
import { defaultBoxShadow, gradients } from "../../assetsConfig";
import HamburgerIcon from "../../assets/hamburger_menu.png";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const Sort = styled(TransparentDropdown)`
	box-shadow: 0 0 0 2px ${({ theme }) => theme.colorPrimary} inset,
		${defaultBoxShadow};
	height: 55px;
	padding: 8px 50px 8px 1rem !important;
	color: ${({ theme }) => theme.colorCopyLightLight};

	// gold caret down
	background: url("${svgString(CaretDown)}") 90% no-repeat;

	option {
		background: ${({ theme }) => theme.colorBackgroundDarkDark};
		color: ${({ theme }) => theme.colorCopyLightLight};
	}
`;

export const ActiveBar = styled.div<{ leftPosition: string }>`
	position: absolute;
	left: ${({ leftPosition }) => leftPosition};
	bottom: -4px;
	z-index: 10;
	height: 4px;
	width: 0px;

	background: ${({ theme }) => theme.colorActivation};
	transition: all ${DynamicTransitionDuration} ease;
`;

const JudgingGalleryTopNavBar = styled.div`
	display: flex;
	flex-direction: column;
	background: ${({ theme }) => theme.colorBackgroundDarkDark};

	.judgeNow {
		background-color: ${({ theme }) => theme.colorPrimary};
	}
	.backToEntries {
		background-color: transparent;
		border-left: 5px solid ${({ theme }) => theme.colorBorderDark};
	}
	.status_dropDown {
		background-color: ${({ theme }) => theme.colorBackgroundMedium} !important;
		border: 1px solid;
		border-color: ${({ theme }) => theme.colorBorderLight};
		padding: 8px 50px 8px 35px !important;
		text-align: center;

		option {
			color: ${({ theme }) => theme.colorCopyLightLight};
		}
	}
	${HoverMenuLink} {
		position: relative;
	}

	${HoverMenuLink}:hover {
		${ActiveBar} {
			width: 55px;
		}
	}
	.textLabel {
		color: ${({ theme }) => theme.colorFieldReadOnly};
		cursor: pointer;
	}
	.WhiteTextLabel {
		color: ${({ theme }) => theme.colorCopyLightLight};
	}
	.textLabelDanger {
		color: ${({ theme }) => theme.colorDanger};
	}

	.roundDeadline {
		font-style: italic;
		color: ${({ theme }) => theme.colorCopyLightLight};
	}
	.assignedJudgingLabel {
		color: ${({ theme }) => theme.colorActivation};
	}
	.closedJudgingLabel {
		color: ${({ theme }) => theme.colorDanger};
	}
	.openJudgingLabel {
		color: ${({ theme }) => theme.colorPrimary};
	}

	.topnav-wrapper {
		display: flex;
		flex-direction: column;
		width: 80vw;

		background: ${({ theme }) => theme.colorBackgroundDark};
		background: ${gradients.mobileMenu};
		height: 844px;
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);

		.topnav-section {
			display: flex;

			button {
				position: relative;
			}
		}
	}
	.menuBorder {
		border-color: ${({ theme }) => theme.colorBackgroundMedium};
		height: 4px;
		width: 100%;
	}

	.topnav-wrapper {
		position: relative;
		flex-direction: row;
		top: 0;
		right: 0;
		justify-content: space-between;
		width: 100%;
		height: auto;
		background: ${({ theme }) => theme.colorBackgroundDarkDark};

		.topnav-section {
			flex-direction: row;

			&.left button {
				border-right: 5px solid ${({ theme }) => theme.colorBorderDark};
			}

			&.right {
				align-items: center;
			}

			.profile-icon {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 75px;
				height: 75px;
				cursor: pointer;
				background: ${({ theme }) => theme.colorBackgroundDarkDark};
			}

			.new-entry-button {
				margin-right: 2.1875rem;
			}
		}
	}

	.topnav-mobile-section {
		position: fixed;
		width: 25%;
		right: 0;
		top: 0;
		z-index: 50;
		background-color: ${({ theme }) => theme.colorBackgroundDarkDark};
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;

		right: 1rem;
		width: fit-content;
		max-height: 98vh;
	}

	.mobileJudgeNowMenu {
		width: 100% !important;
		background-color: ${({ theme }) => theme.colorBackgroundMedium} !important;
		position: static !important;

		${HoverMenuLink} {
			border-bottom: 4px solid ${({ theme }) => theme.colorBackgroundMedium} !important;
		}
	}
	.mobileBackToEntriesButton {
		border-width: 2px;
		border-style: solid;
		white-space: nowrap;
		border-color: ${({ theme }) => theme.colorPrimary};
		background-color: ${({ theme }) => theme.colorButtonDark};
	}
	.jurorIcon {
		border: 2px solid ${({ theme }) => theme.colorPrimary};
		border-radius: 50%;
		padding: 0.5rem;
	}
	.hoverMenuDiv {
		white-space: nowrap;
		width: 100%;
		gap: 1rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: 600;
	}

	/* only for tablet/mobile (<lg 1024px) */
	@media only screen and (max-width: calc(${({ theme }) => theme.lg} - 1px)) {
		.only-desktop {
			display: none !important;
		}
	}

	// show desktop nav on size (>lg 1024px)
	@media only screen and (min-width: ${({ theme }) => theme.lg}) {
		/* height: 75px; */

		.only-mobile {
			display: none !important;
		}
	}
`;

export const ProgressIndicator = styled.div`
	height: 8px;
	width: 100%;
	background-color: ${({ theme }) => theme.colorProgressBackground};
	width: 12rem;
`;

export const ProgressFill = styled.div.attrs(
	(props: { size: number }) => props
)`
	height: 100%;
	width: ${(props) => props.size}%;
	background-color: ${({ theme }) => theme.colorPrimary};
`;

const ActiveRoundIndicator = styled.span`
	background: ${({ theme }) => theme.colorDanger};
	border-radius: 9999px;
	border: 3px solid ${({ theme }) => theme.colorBackgroundDarkDark};
	outline: 3px solid ${({ theme }) => theme.colorBackgroundDarkDark};
	outline-offset: -7px;
	width: 20px;
	height: 20px;
`;

const JudgingGalleryNav = memo((props: JudgingGalleryNavProps) => {
	const theme = useTheme();
	const [showNav, setShowNav] = useState(false);
	const { settings } = useSelector((state: RootState) => state.settings);
	const [companyOrderCount, setCompanyOrderCount] = useState<number>();
	const [availableEntryRounds, setAvailableEntryRounds] = useState<
		AvailableEntryRounds[]
	>([]);
	const [jurors, setJurors] = useState<JurorCardConfig[]>([]);
	const [votesCast, setVotesCast] = useState(
		props.jurorJuryCard?.entrySets.find(
			(x) => x.id === Number(props.entrySetId)
		)?.votesCast || 0
	);
	const [totalEntries, setTotalEntries] = useState(
		props.jurorJuryCard?.entrySets.find(
			(x) => x.id === Number(props.entrySetId)
		)?.votesPossible || 0
	);

	const [minutesWatched, setMinutesWatched] = useState(
		props.jurorJuryCard?.entrySets.find(
			(x) => x.id === Number(props.entrySetId)
		)?.videoMinutesWatched || 0
	);
	const [totalVideoMinutes, setTotalVideoMinutes] = useState(
		props.jurorJuryCard?.entrySets.find(
			(x) => x.id === Number(props.entrySetId)
		)?.totalVideoMinutes || 0
	);

	const [entryProgress, setEntryProgress] = useState(0);

	const [globalVotesCast, setGlobalVotesCast] = useState(0);
	const [globalTotalEntries, setGlobalTotalEntries] = useState(0);

	const [globalMinutesWatched, setGlobalMinutesWatched] = useState(0);
	const [globalTotalVideoMinutes, setGlobalTotalVideoMinutes] = useState(0);
	const { user } = useCurrentUser();

	useEffect(() => {
		if (props.jurorJuryCard) {
			// set ALL entry progress
			setGlobalVotesCast(props.jurorJuryCard.votesCast);
			setGlobalTotalEntries(props.jurorJuryCard.votesPossible);

			// set ALL videos watched progress
			setGlobalMinutesWatched(props.jurorJuryCard.videoMinutesWatched);
			setGlobalTotalVideoMinutes(props.jurorJuryCard.totalVideoMinutes);

			// set individual entry progress
			setVotesCast(
				props.jurorJuryCard?.entrySets.find(
					(x) => x.id === Number(props.entrySetId)
				)?.votesCast || 0
			);
			setTotalEntries(
				props.jurorJuryCard?.entrySets.find(
					(x) => x.id === Number(props.entrySetId)
				)?.votesPossible || 0
			);

			// set individual video watched progress
			setMinutesWatched(
				props.jurorJuryCard?.entrySets.find(
					(x) => x.id === Number(props.entrySetId)
				)?.videoMinutesWatched || 0
			);
			setTotalVideoMinutes(
				props.jurorJuryCard?.entrySets.find(
					(x) => x.id === Number(props.entrySetId)
				)?.totalVideoMinutes || 0
			);
		}
		getJurors().then((response) => {
			if (response.status === 200) {
				setJurors(response.data);
			}
		});
	}, [props.jurorJuryCard]);

	useEffect(() => {
		if (votesCast && totalEntries) {
			setEntryProgress(Math.round((votesCast / totalEntries) * 100));
		}
	}, [votesCast, totalEntries]);

	// mobile dropdown
	const [showDropdown, setShowDropdown] = useState({
		entries: false,
		alerts: false,
		profile: false,
		entrySet: false,
	});

	// active page link for mobile
	const [activeLinks, setActiveLinks] = useState({
		mediaLibrary: false,
		creditDB: false,
		programs: false,
		admin: false,
		entrySet: false,
	});

	// expand menu for mobile
	const [expandMobileLink, setExpandMobileLink] = useState({
		entries: false,
		alerts: false,
		profile: false,
		account: false,
	});

	const toggleMobileLink = (link: string) => {
		const obj: any = {}; // temp obj
		Object.entries(expandMobileLink).forEach(([button, isOpen]) => {
			// only toggle "link"
			button === link ? (obj[button] = !isOpen) : (obj[button] = false);
		});
		setExpandMobileLink(obj);
	};

	const handleActiveLink = (activeLink: string) => {
		const obj: any = {}; // temp obj
		Object.entries(activeLinks).forEach(([button, isActive]) => {
			// only toggle active link
			button === activeLink ? (obj[button] = !isActive) : (obj[button] = false);
		});
		setActiveLinks(obj);
	};

	useEffect(() => {
		if (window.innerWidth > 1400) {
			setShowNav(true);
		}
		// getCompanyRequestCount(setRequestCount);
	}, []);

	useEffect(() => {
		getCompanyOrders()
			.then((response) => {
				if (response.data.length > 0) {
					setCompanyOrderCount(response.data.length);
				}
			})
			.catch((error) => {
				// console.log("getCompanyOrders ERROR", error);
			});
	}, []);

	const nodeRef = useRef(null);

	// show desktop nav when screen >= 1400px
	useEffect(() => {
		const onResize = (event: any) => {
			if (window.innerWidth >= 1400) {
				setShowNav(true);
			} else {
				setShowNav(false);
			}
		};
		window.addEventListener("resize", onResize);

		return () => {
			window.removeEventListener("scroll", onResize);
		};
	}, []);

	const formikProps = useFormik({
		initialValues: {
			statusDropDownSelect: "View All",
		},
		enableReinitialize: true,
		onSubmit: async (value) => {},
	});

	const globalVotesPercentage = Math.round(
		(globalVotesCast / globalTotalEntries) * 100
	);

	const globalVideoPercentage = Math.round(
		(globalMinutesWatched / globalTotalVideoMinutes) * 100
	);

	return (
		<JudgingGalleryTopNavBar id="topBar">
			<FormikProvider value={formikProps}>
				<CSSTransition
					in={showNav}
					timeout={150}
					unmountOnExit
					classNames="click-user-nav"
					nodeRef={nodeRef}
				>
					<div className="topnav-section topnav-mobile-section only-mobile right">
						<Icon
							icon="close"
							width="35px"
							height="35px"
							color={theme.colorPrimary}
							className="only-mobile absolute top-2 right-2 inline mt-0 mr-[1rem] mb-[1.8rem] ml-auto"
							onClick={() => setShowNav(!showNav)}
						/>
						<div className="w-full flex gap-[1rem] items-center px-[2rem] mt-[2.75rem] mb-[2rem]">
							<Icon
								icon="user"
								color={theme.colorPrimary}
								width="25px"
								height="25px"
								className="jurorIcon"
								to="/user-info"
							/>
							<span className="textLabel">
								{useCurrentUser().user.fullName}
							</span>
						</div>
						<hr className="menuBorder" />
						{jurors && jurors.length > 0 && <JudgeNowButton jurors={jurors} />}
						<div className="grid grid-cols-1 !gap-[2.75rem] px-[2rem] mt-[2.75rem]">
							{globalTotalEntries !== 0 && (
								<div className="flex gap-[1rem] flex-col">
									<div className="flex items-center ">
										<ProgressCircle
											value={globalVotesPercentage}
											text={`${globalVotesPercentage}%`}
											size="70px"
											textColor={theme.colorCopyLightLight}
										/>
										<div className="flex text-colorCopyLightLight flex-col pl-[1rem] ">
											<p className="text-left whitespace-nowrap">
												Overall Entries
											</p>
											<p className=" text-sm text-left whitespace-nowrap">
												{globalVotesCast}/{globalTotalEntries} Complete
											</p>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</CSSTransition>

				<CSSTransition
					in
					timeout={150}
					unmountOnExit
					classNames="click-user-nav"
					nodeRef={nodeRef}
				>
					<div>
						<div className="topnav-wrapper">
							<div className="topnav-section left ml-[2rem]">
								<Link to="/">
									<img
										className="w-[160px] py-[1.25rem]"
										alt="Program Award"
										src={props.award?.imageDark}
									/>
								</Link>
							</div>

							{props.liveJudgingJurorActive && props.highlightedEntryUrl && (
								<div className="flex mr-auto self-center pl-6">
									<img className="self-center" src={AnimatedAntenna}></img>
									<div className="pl-1 self-center text-colorCopyLightLight">
										Live Judging in Progress
									</div>
									<Button className="ml-3 mr-3" to={props.highlightedEntryUrl}>
										Jump to Current Entry
									</Button>
								</div>
							)}

							<HamburgerMenu
								className="only-mobile"
								src={HamburgerIcon}
								alt="Hamburger Icon"
								onClick={() => setShowNav(!showNav)}
							/>

							<div className="topnav-section only-desktop right">
								{globalTotalEntries !== 0 && (
									<>
										<ProgressCircle
											value={globalVotesPercentage}
											text={`${globalVotesPercentage}%`}
											size="55px"
											textColor={theme.colorCopyLightLight}
										/>
										<div className="flex flex-col px-[1rem] mr-[1.5rem]">
											<p className="textLabel !cursor-auto text-left whitespace-nowrap ">
												Overall Entries
											</p>
											<p className="textLabel !cursor-auto text-sm text-left whitespace-nowrap ">
												{globalVotesCast}/{globalTotalEntries} Complete
											</p>
										</div>
									</>
								)}
								{globalTotalVideoMinutes !== 0 && (
									<>
										<ProgressCircle
											value={globalVideoPercentage}
											text={`${globalVideoPercentage}%`}
											size="55px"
											textColor={theme.colorCopyLightLight}
										/>
										<div className="flex flex-col px-[1rem] mr-[1.5rem]">
											<p className="textLabel !cursor-auto text-left whitespace-nowrap ">
												Overall Videos
											</p>
											<p className="textLabel !cursor-auto text-sm text-left whitespace-nowrap ">
												{globalMinutesWatched}/{globalTotalVideoMinutes} Mins.
												Watched
											</p>
										</div>
									</>
								)}
								<JudgeNowButton juryId={props.juryId} jurors={jurors} />

								<div
									className="relative"
									onClick={() =>
										setShowDropdown({
											...showDropdown,
											profile: !showDropdown.profile,
										})
									}
									onMouseLeave={() =>
										setShowDropdown({ ...showDropdown, profile: false })
									}
								>
									<div className="mobile h-full only-mobile">
										<ButtonLink
											onClick={() => {
												toggleMobileLink("account");
												handleActiveLink("account");
											}}
											className={`h-full`}
											icon="user"
											iconColor={theme.colorButtonLight}
										>
											<p>My Account</p>

											<Icon
												icon="caret"
												color={theme.colorCopyLightLight}
												className={`ml-auto ${
													expandMobileLink.account && "open"
												} `}
												rotation={showDropdown.profile ? "0" : "180deg"}
											/>
										</ButtonLink>
									</div>
									<div
										className="relative only-desktop"
										onMouseOver={() =>
											setShowDropdown({ ...showDropdown, profile: true })
										}
										onMouseLeave={() =>
											setShowDropdown({ ...showDropdown, profile: false })
										}
									>
										<div className="profile-icon">
											{user?.profileUrl ? (
												<img
													className="h-full object-cover absolute background_image"
													src={user.profileUrl}
													alt="uploaded file"
												></img>
											) : (
												<Icon
													icon="user"
													color={theme.colorPrimary}
													width="25px"
													height="25px"
													to="/user-info"
												/>
											)}
										</div>

										<CSSTransition
											in={showDropdown.profile}
											timeout={150}
											unmountOnExit
											classNames="hover-menu"
										>
											<HoverMenu>
												<HoverMenuLink className="first-link">
													<p className="whitespace-nowrap font-semibold mt-[1rem]">
														<span className="text-colorCopyDarkDark">
															Welcome{" "}
														</span>
														{user.fullName}
													</p>
												</HoverMenuLink>

												<HoverMenuLink>
													<Link className="no-underline" to="/user-info">
														<LinkLabel>Profile Settings</LinkLabel>
													</Link>
												</HoverMenuLink>

												{!settings.isDemo && (
													<HoverMenuLink>
														<Link className="no-underline" to="/entries/orders">
															<LinkLabel>My Orders </LinkLabel>
														</Link>

														{companyOrderCount && (
															<OrderCounter>{companyOrderCount}</OrderCounter>
														)}
													</HoverMenuLink>
												)}
												<HoverMenuLink>
													<LinkLabel onClick={() => logout()}>Logout</LinkLabel>
												</HoverMenuLink>
											</HoverMenu>
										</CSSTransition>
									</div>
								</div>
								<Button
									onClick={() => logout()}
									className="only-mobile button-transparent"
								>
									Logout
								</Button>
							</div>
						</div>
						{props.entrySetCard && (
							<div className="topnav-wrapper flex flex-wrap py-[1rem] !justify-start items-center px-[2rem] gap-[2rem] xxl:gap-[4rem]">
								<h1 className="playfair-500 mr-[1rem] text-colorCopyLightLight">
									{props.entrySetCard.name}
								</h1>

								{false && (
									<div className="flex gap-[0.5rem] items-center">
										<ActiveRoundIndicator />
										<p className="text-colorCopyLightLight">
											Live Judging In-Progress
										</p>
									</div>
								)}

								<div className="flex items-center">
									{props.roundName && (
										<>
											<Icon
												className="mr-[.5rem]"
												icon="clock"
												color={theme.colorPrimary}
												defaultCursor
												width="18px"
												height="18px"
											/>
											<p className="textLabel !cursor-auto font-semibold">
												{props.roundName} Round
											</p>
										</>
									)}

									{props.roundDeadline &&
										roundTime(numberToDate(props.roundDeadline)) !== 0 && (
											<>
												<p className="textLabel !cursor-auto font-semibold mr-[.25rem]">
													:
												</p>
												<p className="roundDeadline">
													Closes in{" "}
													{roundTime(numberToDate(props.roundDeadline))}
												</p>
											</>
										)}
								</div>
								{totalEntries !== 0 && (
									<div className={`textLabel !cursor-default mb-2 text-sm`}>
										<div className="flex flex-wrap mb-2 items-center gap-[0.5rem]">
											<p className="text-sm pt-[15px]">{`${entryProgress}%`}</p>
											<div>
												{/* <p>{props.entrySetCard.name} Entries</p> */}
												<ProgressIndicator className="mt-[1.1rem]">
													<ProgressFill size={entryProgress} />
												</ProgressIndicator>
											</div>
											<p className="text-sm pt-[15px]">
												{votesCast ? votesCast : 0}/
												{totalEntries ? totalEntries : 0} Complete
											</p>
										</div>
									</div>
								)}

								<div className="ml-auto flex items-center justify-between">
									{props.onSelectSort && (
										<div className="flex items-center">
											<p className="textLabel !cursor-default mr-[10px]">
												Filter by Status:
											</p>
											<Sort
												className="mr-[1rem]"
												id="statusDropDownSelect"
												options={[
													{ label: "View All", value: "all" },
													{ label: "Voted", value: "voted" },
													{ label: "Not Voted", value: "notVoted" },
												]}
												onChange={(sortOption: string) =>
													props.onSelectSort && props.onSelectSort(sortOption)
												}
											/>
										</div>
									)}
									{props.juryId && (
										<Button
											className="button-gold WhiteTextLabel"
											icon="caret"
											iconRotation="90deg"
											to={`/judge/${props.juryId}`}
										>
											<span className="hidden sm:inline">
												Back To Entry Sets
											</span>
											<span className="sm:hidden">Back</span>
										</Button>
									)}
								</div>
							</div>
						)}
					</div>
				</CSSTransition>
			</FormikProvider>
		</JudgingGalleryTopNavBar>
	);
});

export default JudgingGalleryNav;

interface JudgingGalleryNavProps {
	onSelectSort?(sortOption: string): void; // undefined if there's no sort dropdown
	// undefined for entry set round page
	roundName?: string;
	roundDeadline?: number;
	entrySetId?: string;
	entrySetCard?: EntrySetAdminCard;
	highlightedEntryUrl?: string;
	liveJudgingJurorActive?: boolean;
	jurorJuryCard?: JurorJuryCard;
	juryId: string;
	award?: AwardModel;
}
