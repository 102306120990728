import styled, { useTheme } from "styled-components";
import PlaceholderImg from "../../assets/placeholders/placeholder.png";

const GalleryCardContainer = styled.div`
	/* background: ${({ theme }) => theme.colorActivation}; */
	outline: 1px solid ${({ theme }) => theme.colorBorder};
`;

export const GalleryCardDetails = styled.div`
	display: grid;
	grid-template-columns: 1fr 3fr;
	gap: 1rem;
	padding: 1rem;

	.label {
		font-weight: bold;
		color: ${({ theme }) => theme.colorCopyLight};
	}
`;

export const GalleryCardHeader = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem;
`;

const DangerText = styled.p`
	color: ${({ theme }) => theme.colorDanger};
	font-style: italic;
`;

export const MissingText = (props: MissingTextProps) => {
	return (
		<DangerText>
			Missing {props.textLabel ? props.textLabel : "Information"}
		</DangerText>
	);
};

const GalleryCard = (props: entryDetails) => {
	const theme = useTheme();
	return (
		<GalleryCardContainer>
			<img
				className="w-full"
				src={
					props.entryCoverImageSrc.val
						? props.entryCoverImageSrc.val
						: PlaceholderImg
				}
				alt="Cover Image"
			/>

			<div className="p-[1rem]">
				<h1>
					{props.entryBrand.val ? (
						props.entryBrand.val
					) : (
						<MissingText textLabel="Brand" />
					)}
				</h1>
				<h3>
					{props.entryTitle.val ? (
						props.entryTitle.val
					) : (
						<MissingText textLabel="Entry Title" />
					)}
				</h3>
				<div className="flex flex-col gap-[.5rem] mt-[1rem]">
					<div className="flex gap-[.5rem]">
						<p className={`font-semibold text-colorCopyLight`}>Medium:</p>
						<p>
							{props.medium.val ? (
								props.medium.val
							) : (
								<MissingText textLabel="Medium" />
							)}
						</p>
					</div>
				</div>
			</div>
			{/* <GalleryCardDetails>
        {Object.values(props.entryDetails).map((detailObj: entryDetailObj) => {
					return (
						<>
							<p className="label">{String(detailObj.label)}</p>
							<p>{String(detailObj.val)}</p>
						</>
					);
				})}
      </GalleryCardDetails> */}
		</GalleryCardContainer>
	);
};

export default GalleryCard;

export interface entryDetailObj {
	label?: string;
	val: string | undefined;
}

interface entryDetails {
	entryTitle: entryDetailObj;
	entryBrand: entryDetailObj;
	entryCoverImageSrc: entryDetailObj;
	medium: entryDetailObj;
	// entrantCompany: entryDetailObj;
	// category: entryDetailObj;
	// entryType: entryDetailObj;
}

// interface GalleryCardProps {
// 	entryDetails: entryDetails;
// 	// children: React.ReactNode;
// }

interface MissingTextProps {
	textLabel?: string;
}
