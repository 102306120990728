import UserMenu from "../Admin/User/UserMenu";
import {
	NavTabs,
	NavTab,
	NavTabList,
	NavTabPanel,
} from "../../components/NavTabs/NavTabs";
import DraftEntriesTab from "./DraftEntriesTab";
import CartTab from "./CartTab";
import PaymentTab from "./Payment";
import OrdersTab from "./OrdersTab";
import { UserPageContainer } from "../../components/UserPageTemplate/UserPageTemplate";
import { useEffect, useState, createContext, useContext } from "react";
import { Link } from "react-router-dom";
import PrimaryLogo from "../../components/PrimaryLogo/PrimaryLogo";
import { EntryNavTabList } from "../Entries/EntryForm";
import MyContextProvider from "./CheckoutContext";
import PrevEntriesTab from "./PreviousEntry/PrevEntriesTab";
import BrowserHeader from "../../components/BrowserHeader/BrowserHeader";
import { useTheme } from "styled-components";
import assetsConfig from "../../assetsConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

// Checkout page with entire checkout process (draft, cart, orders)
const Checkout = (props: { tab?: string }) => {
	const { settings } = useSelector((state: RootState) => state.settings);
	const theme = useTheme();
	const tabs = settings.isDemo
		? ["draft-entries", "cart", "entries-search"]
		: ["draft-entries", "cart", "orders", "entries-search"];
	const tabNames = settings.isDemo
		? ["Draft Projects", "Ready to Submit", "Project Search"]
		: ["Draft Entries", "My Cart", "Orders", "Entries Search"];

	const defaultTab = tabs.indexOf(props.tab || "draft-entries");
	const [tabIndex, setTabIndex] = useState(defaultTab);

	useEffect(() => {
		setTabIndex(defaultTab);
	}, [defaultTab]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [tabIndex]);

	return settings.isDemo === undefined ? (
		<></>
	) : (
		<MyContextProvider>
			<BrowserHeader title={`${tabNames[tabIndex]}`} />
			<div className="flex flex-col w-full">
				<UserMenu />
				<NavTabs
					className="flex flex-col flex-1"
					selectedIndex={tabIndex}
					onSelect={(index) => setTabIndex(index)}
				>
					<EntryNavTabList>
						<PrimaryLogo
							className="mb-[66px] pt-[0.18rem]"
							color={theme.colorLogoLightLight}
							width="254px"
						/>

						<NavTab>
							<Link className="no-underline" to="/entries/draft-entries">
								Draft {assetsConfig.labels.entry.plural}
							</Link>
						</NavTab>
						<NavTab>
							<Link className="no-underline" to="/entries/cart">
								{settings.isDemo ? "Ready to Submit" : "My Cart"}
							</Link>
						</NavTab>
						{!settings.isDemo && (
							<NavTab>
								<Link className="no-underline" to="/entries/orders">
									Orders
								</Link>
							</NavTab>
						)}
						<NavTab>
							<Link className="no-underline" to="/entries/entries-search">
								{assetsConfig.labels.entry.singular} Search
							</Link>
						</NavTab>
					</EntryNavTabList>
					<NavTabPanel>
						<UserPageContainer className="flex-1">
							<DraftEntriesTab />
						</UserPageContainer>
					</NavTabPanel>
					<NavTabPanel>
						<UserPageContainer className="flex-1">
							<CartTab />
						</UserPageContainer>
					</NavTabPanel>
					{/* <NavTabPanel>
          <UserPageContainer className="flex-1">
            <PaymentTab
              paymentType="Credit Card"
              //   paymentType="Check"
              //   paymentType="Bank Transfer"
            />
          </UserPageContainer>
        </NavTabPanel> */}
					{!settings.isDemo && (
						<NavTabPanel>
							<UserPageContainer className="flex-1">
								<OrdersTab />
							</UserPageContainer>
						</NavTabPanel>
					)}
					<NavTabPanel>
						<UserPageContainer className="flex-1">
							<PrevEntriesTab />
						</UserPageContainer>
					</NavTabPanel>
				</NavTabs>
			</div>
		</MyContextProvider>
	);
};

export default Checkout;
