import { PDFDownloadLink } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import styled from "styled-components";
import InvoicePdf from "../../views/Checkout/InvoicePdf";
import { OrderModel } from "../../views/Checkout/OrderInterfaces";
import Button from "../Button/Button";
import assetsConfig from "../../assetsConfig";

const StyledDownloadLink = styled(PDFDownloadLink)`
	text-decoration: none;
	color: ${({ theme }) => theme.colorCopyDarkDark};
`;

const DownloadPDF = (props: DownloadPDFProps) => {
	const [fileName, setFileName] = useState(
		assetsConfig.companyNameShort + " Order"
	);

	useEffect(() => {
		if (props.order && props.order.id) {
			setFileName(assetsConfig.companyNameShort + " Order " + props.order.id);
		}
	}, [props.order]);

	return (
		<>
			{props.order && (
				<Button className="button-light">
					<StyledDownloadLink
						document={
							<InvoicePdf
								id={props.order!.id}
								notes={props.order!.notes}
								companyAddress1={props.order!.companyAddress1}
								companyAddress2={props.order!.companyAddress2}
								companyCity={props.order!.companyCity}
								companyCountry={props.order!.companyCountry}
								companyName={props.order!.companyName}
								companyPostalCode={props.order!.companyPostalCode}
								companyState={props.order!.companyState}
								email={props.order!.email}
								fullName={props.order!.fullName}
								lineItems={props.order!.lineItems}
								orderAmount={props.order!.orderAmount}
								phone={props.order!.phone}
								statusId={props.order!.statusId}
								transactionType={props.order!.transactionType}
								cardInfo={props.order!.cardInfo}
								invoicePDF={props.order!.invoicePDF}
							/>
						}
						fileName={fileName}
					>
						{({ blob, url, loading, error }) =>
							loading
								? "Loading document..."
								: props.title
								? props.title
								: "Download Order"
						}
					</StyledDownloadLink>
				</Button>
			)}
		</>
	);
};

interface DownloadPDFProps {
	order: OrderModel | undefined;
	title?: string;
}

export default DownloadPDF;
