import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import {
  getStoredValue,
  setStoredValue,
} from "../../../components/LocalStorageStorage/LocalStorageStore";
import { useHistory } from "react-router-dom";
import {
  DropdownOptions,
  useAwardableAwards,
} from "../../../hooks/useAwardableAwards";
import Table, {
  SortDirection,
  TablePlaceholder,
} from "../../../components/Table/Table";
import { useFormik, FormikProvider } from "formik";
import { urlEntries, urlMedia, urlPrograms } from "../../../endpoints";
import axios from "axios";
import { useEffect, useState, Fragment } from "react";
import { AdminPageControls, SearchFieldsContainer } from "./AdminSearch";
import TextField from "../../../components/FormFields/TextField";
import MultiDropdown from "../../../components/MultiDropdown/MultiDropdown";
import {
  PaymentStatusOptions,
  ReviewStatusOptions,
} from "../../Checkout/OrderInterfaces";
import DropdownField from "../../../components/FormFields/DropdownField";
import Button from "../../../components/Button/Button";
import dateTostring from "../../../utils/dateToString";
import Placeholder from "../../../assets/placeholders/placeholder.png";
import {
  getThumbnailOrPlaceholder,
  getThumbnailSrc,
  getThumbnailSrcNested,
} from "../../MediaLibrary/manageMediaLibrary";
import Loading from "../../../components/Loading/Loading";
import { MediaType } from "../../MediaLibrary/mediaLibrary.model.d";
import config from "../../../config";
import assetsConfig from "../../../assetsConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colorActivation};
`;

export const CsvExport = styled(CSVLink)<{ disabled?: boolean }>`
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `};
`;

const initialSearch = {
  fileType: undefined,
  fieldType: undefined,
  isHidden: undefined,
  entryId: undefined,
  RootPrograms: undefined,
  originalFilename: undefined,
  title: undefined,
  entryProgram: undefined,
  keyword: undefined,
  brand: undefined,
  entryType: undefined,
  paymentStatus: undefined,
  companyName: undefined,
  isCampaign: undefined,
  reviewStatus: undefined,
  email: undefined,
  parentChild: undefined,
  awardLevel: undefined,
};

const getScrubbedRequest = (
  request: MediaSearchRequest
): MediaSearchRequest => {
  let result: MediaSearchRequest = {
    fileType: request.fileType ? request.fileType : undefined,
    fieldType: request.fieldType ? request.fieldType : undefined,
    isHidden: request.isHidden ? JSON.parse(request.isHidden) : undefined,
    entryId: request.entryId ? request.entryId : undefined,
    RootPrograms: request.RootPrograms ? request.RootPrograms : undefined,
    originalFilename: request.originalFilename
      ? request.originalFilename
      : undefined,
    title: request.title ? request.title : undefined,
    entryProgram: request.entryProgram ? request.entryProgram : undefined,
    keyword: request.keyword ? request.keyword : undefined,
    brand: request.brand ? request.brand : undefined,
    entryType: request.entryType ? request.entryType : undefined,
    paymentStatus: request.paymentStatus ? request.paymentStatus : undefined,
    companyName: request.companyName ? request.companyName : undefined,
    isCampaign: request.isCampaign ? JSON.parse(request.isCampaign) : undefined,
    reviewStatus: request.reviewStatus ? request.reviewStatus : undefined,
    email: request.email ? request.email : undefined,
    parentChild: request.parentChild
      ? JSON.parse(request.parentChild)
      : undefined,
    awardLevel: request.awardLevel ? request.awardLevel : undefined,
  };

  return result;
};

const MediaSearchTab = () => {
  const [searchVal, setSearchVal] = useState<MediaSearchRequest>(
    getStoredValue("mediaSearch") || initialSearch
  );
  const [isActive, setActive] = useState(false);
  const [media, setMedia] = useState<MediaSearchResult[]>([]);
  const [page, setPage] = useState(1);
  const [totalAmountOfPages, settotalAmountOfPages] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);
  const [errors, setErrors] = useState<string[]>([]);
  const history = useHistory();
  const { settings } = useSelector((state: RootState) => state.settings);
  const [csvMedia, setCsvMedia] = useState<any[]>([]);
  const [csvLoading, setCsvLoading] = useState(false);
  const [programOptions, setProgramOptions] = useState([]);
  const awardOptions = useAwardableAwards();
  const parentChildOptions: DropdownOptions[] = [
    { value: true, label: "Parent" },
    { value: false, label: "Child" },
  ];
  const isCampaignOptions: DropdownOptions[] = [
    { value: true, label: "Campaign" },
    { value: false, label: "Single" },
  ];
  const isHiddenOptions: DropdownOptions[] = [
    { value: true, label: "True" },
    { value: false, label: "False" },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [activeSort, setActiveSort] = useState<number | null>(null);
  const [activeSortDirection, setActiveSortDirection] = useState<SortDirection>(
    SortDirection.Asc
  );
  const [mediaTemplateNames, setMediaTemplateNames] = useState<
    DropdownOptions[]
  >([]);

  useEffect(() => {
    if (media.length > 1) {
      searchMedia(
        formikProps.values,
        activeSort !== null ? activeSort : undefined,
        activeSort !== null ? activeSortDirection : undefined
      );
    }
  }, [page, recordsPerPage]);

  useEffect(() => {
    async function fetchMediaTemplateNames() {
      try {
        const response = await axios.get<DropdownOptions[]>(
          `${urlMedia}/MediaFieldTemplateNames`
        );

        const options: DropdownOptions[] = response.data;

        // Manually add "Cover Image" to the end of the array
        options.push({ value: 0, label: "Cover Image" });

        setMediaTemplateNames(options);
      } catch (error) {
        console.error("Failed to fetch media template names", error);
      }
    }

    fetchMediaTemplateNames();
  }, []);

  useEffect(() => {
    async function fetchOptions() {
      const response = await axios.get(`${urlPrograms}/GetRootProgramOptions`);
      setProgramOptions(response.data);
    }

    fetchOptions();
  }, []);

  const searchMedia = async (
    request: MediaSearchRequest,
    activeSort?: number,
    sortDirection?: SortDirection
  ) => {
    const newRequest = getScrubbedRequest(request);
    const sortField =
      activeSort !== undefined && activeSort !== null
        ? MediaSearchSortEnum[activeSort]
        : undefined;

    const response = await axios.post(`${urlMedia}/search`, newRequest, {
      params: {
        page,
        recordsPerPage,
        sortField,
        sortDirection,
      },
    });

    if (response.status === 200) {
      setMedia([]); // This cleans the results and forces a React re-render in cases of sorting.
      setMedia(response.data);
      const total = parseInt(response.headers["totalamountofrecords"]);
      setTotalRecords(total);
      settotalAmountOfPages(Math.ceil(total / recordsPerPage));
      setIsLoading(false);
    }
  };

  async function PostSearch(request: MediaSearchRequest) {
    //console.log("pre-request ", request);
    try {
      const newRequest = getScrubbedRequest(request);

      //console.log("request ", newRequest);
      setErrors([]);
      setActive(false);
      const response = await axios.post(`${urlMedia}/search`, newRequest, {
        params: { page, recordsPerPage },
      });

      if (response.status === 200) {
        setMedia(response.data);
        const total = parseInt(response.headers["totalamountofrecords"]);
        setTotalRecords(total);
        settotalAmountOfPages(Math.ceil(total / recordsPerPage));
        setActive(true);
        setIsLoading(false);
      }

      setCsvLoading(true);

      const getMediaCSV = await axios.post(
        `${urlMedia}/search/export`,
        newRequest
      );

      if (getMediaCSV.status === 200) {
        setCsvLoading(false); // enable export button after csv entries are retrieved
        setCsvMedia(getMediaCSV.data);
      }
    } catch (error: any) {
      // console.log(error);
      //setErrors(error.response.data);
      setIsLoading(false);
    }
  }

  const fieldRequired = "This field is required";
  const formikProps = useFormik({
    initialValues: searchVal,
    onSubmit: async (value) => {
      setStoredValue("mediaSearch", getScrubbedRequest(value));
      setPage(1);
      await PostSearch(value);
      // console.log(value);
    },
  });

  const mapEnum = (sortField: string) => {
    const sortEnumIndex = Object.values(MediaSearchSortOptions).indexOf(
      sortField as MediaSearchSortOptions
    );

    return sortEnumIndex !== -1 ? (sortEnumIndex as any) : null;
  };

  useEffect(() => {
    if (activeSort !== null) {
      searchMedia(formikProps.values, activeSort, activeSortDirection);
    }
  }, [activeSort, activeSortDirection]);

  return (
    <FormikProvider value={formikProps}>
      <SearchFieldsContainer>
        <MultiDropdown
          placeholder="File Type"
          options={FileTypeOptions}
          selectedValues={(formikProps.values.fileType || []).flatMap(
            (value: number) => {
              const correspondingOption = FileTypeOptions.find(
                (option: DropdownOptions) => option.value === value
              );
              return correspondingOption ? [correspondingOption] : [];
            }
          )}
          onChange={(selectedList) => {
            formikProps.setFieldValue(
              "fileType",
              selectedList.map((x) => Number(x.value))
            );
          }}
        />
        <MultiDropdown
          placeholder="Field Type"
          options={mediaTemplateNames}
          selectedValues={(formikProps.values.fieldType || []).flatMap(
            (value: number) => {
              const correspondingOption = mediaTemplateNames.find(
                (option: DropdownOptions) => option.value === value
              );
              return correspondingOption ? [correspondingOption] : [];
            }
          )}
          onChange={(selectedList) => {
            formikProps.setFieldValue(
              "fieldType",
              selectedList.map((x) => Number(x.value))
            );
          }}
        />
        <DropdownField
          name="isHidden"
          placeholder="Is Hidden?"
          options={isHiddenOptions}
          value={formikProps.values.isHidden}
        />
        <TextField
          name="entryId"
          placeholder={assetsConfig.labels.entry.singular + " Id"}
          value={formikProps.values.entryId}
        />
        {!settings.isDemo && (
          <MultiDropdown
            placeholder="Root Programs"
            options={programOptions}
            selectedValues={
              Array.isArray(formikProps.values.RootPrograms)
                ? formikProps.values.RootPrograms.flatMap((value: number) => {
                    const correspondingOption = programOptions.find(
                      (option: DropdownOptions) => option.value === value
                    );
                    return correspondingOption ? [correspondingOption] : [];
                  })
                : []
            }
            onChange={(selectedList) => {
              formikProps.setFieldValue(
                "RootPrograms",
                selectedList.map((x: DropdownOptions) => Number(x.value))
              );
            }}
          />
        )}
        <TextField
          name="originalFilename"
          placeholder="Original Filename"
          value={formikProps.values.originalFilename}
        />
        <TextField
          name="title"
          placeholder="Title"
          value={formikProps.values.title}
        />
        {!settings.isDemo && (
          <TextField
            name="entryProgram"
            placeholder={assetsConfig.labels.entry.singular + " Program"}
            value={formikProps.values.entryProgram}
          />
        )}
        <TextField
          name="keyword"
          placeholder="Keyword"
          value={formikProps.values.keyword}
        />
        <TextField
          name="brand"
          placeholder="Brand"
          value={formikProps.values.brand}
        />
        {!settings.isDemo && (
          <TextField
            name="entryType"
            placeholder={assetsConfig.labels.entry.singular + " Type"}
            value={formikProps.values.entryType}
          />
        )}
        {!settings.isDemo && (
          <MultiDropdown
            placeholder={assetsConfig.labels.entry.singular + " Payment Status"}
            options={PaymentStatusOptions}
            selectedValues={(formikProps.values.paymentStatus || []).flatMap(
              (value: number) => {
                const correspondingOption = PaymentStatusOptions.find(
                  (option: DropdownOptions) => option.value === value
                );
                return correspondingOption ? [correspondingOption] : [];
              }
            )}
            onChange={(selectedList) => {
              formikProps.setFieldValue(
                "paymentStatus",
                selectedList.map((x) => Number(x.value))
              );
            }}
          />
        )}
        <TextField
          name="companyName"
          placeholder={assetsConfig.labels.company.singular + " Name"}
          value={formikProps.values.companyName}
        />
        {!settings.isDemo && (
          <DropdownField
            name="isCampaign"
            placeholder="Campaign/Single"
            options={isCampaignOptions}
            value={formikProps.values.isCampaign}
          />
        )}
        <MultiDropdown
          placeholder="Review Status"
          options={ReviewStatusOptions}
          selectedValues={(formikProps.values.reviewStatus || []).flatMap(
            (value: number) => {
              const correspondingOption = ReviewStatusOptions.find(
                (option: DropdownOptions) => option.value === value
              );
              return correspondingOption ? [correspondingOption] : [];
            }
          )}
          onChange={(selectedList) => {
            formikProps.setFieldValue(
              "reviewStatus",
              selectedList.map((x) => Number(x.value))
            );
          }}
        />
        <TextField
          name="email"
          placeholder="Email"
          value={formikProps.values.email}
        />
        {!settings.isDemo && (
          <DropdownField
            name="parentChild"
            options={parentChildOptions}
            placeholder="Parent/Child"
            value={formikProps.values.parentChild}
          />
        )}
        {!settings.isDemo && (
          <MultiDropdown
            placeholder="Award Level"
            options={awardOptions}
            selectedValues={
              Array.isArray(formikProps.values.awardLevel)
                ? formikProps.values.awardLevel.map((value) => ({
                    value,
                    label: value,
                  }))
                : []
            }
            onChange={(selectedList) => {
              formikProps.setFieldValue(
                "awardLevel",
                selectedList.map((x) => x.label)
              );
            }}
          />
        )}
      </SearchFieldsContainer>
      <div className="my-[1rem] flex gap-[1rem] justify-end flex-wrap">
        <Button
          className="button-light w-[150px]"
          type="button"
          onClick={() => {
            formikProps.setValues(initialSearch);
            setSearchVal(initialSearch);
            setMedia([]);
            setActive(false);
            setStoredValue("mediaSearch", initialSearch);
            setActiveSort(null);
            setActiveSortDirection(SortDirection.Asc);
            setPage(1);
            setCsvLoading(false);
          }}
        >
          Reset Search
        </Button>
        <CsvExport
          className="no-underline"
          data={csvMedia}
          // headers={csvHeaders}
          filename={`Media Search Export ${dateTostring(new Date())}.csv`}
          disabled={csvMedia.length < 1}
        >
          <Button
            className="button-light w-[150px]"
            disabled={csvMedia.length < 1}
          >
            <div className="flex items-center gap-[.5rem]">
              Export
              {csvLoading && (
                <img className="w-[16px]" src={config.assets.loading.primary} />
              )}
            </div>
          </Button>
        </CsvExport>

        <Button
          className="w-[150px]"
          type="submit"
          onClick={() => {
            setIsLoading(true);
            formikProps.submitForm();
          }}
        >
          Search
        </Button>
      </div>

      {/* top pagination controls */}
      {isActive && (
        <AdminPageControls
          totalRecords={totalRecords}
          totalAmountOfPages={totalAmountOfPages}
          currentPage={page}
          setPage={(newPage) => setPage(newPage)}
          setIsLoading={(isLoading) => setIsLoading(isLoading)}
        />
      )}
      {!isLoading &&
        (media && media.length > 0 ? (
          <Table
            isActive={isActive}
            dualScroll
            columnLabels={[
              "Thumbnail",
              MediaSearchSortOptions.EntryId,
              MediaSearchSortOptions.FileType,
              MediaSearchSortOptions.Field,
              MediaSearchSortOptions.FileName,
              MediaSearchSortOptions.EntryTitle,
              MediaSearchSortOptions.Author,
              MediaSearchSortOptions.Level1,
              MediaSearchSortOptions.EntryLevelProgram,
              MediaSearchSortOptions.PaymentStatus,
              MediaSearchSortOptions.ReviewStatus,
            ]}
            labelAlias={[
              "Thumbnail",
              assetsConfig.labels.entry.singular + " Id",
              MediaSearchSortOptions.FileType,
              MediaSearchSortOptions.Field,
              MediaSearchSortOptions.FileName,
              assetsConfig.labels.entry.singular + " Title",
              MediaSearchSortOptions.Author,
              MediaSearchSortOptions.Level1,
              assetsConfig.labels.entry.singular + " Program",
              MediaSearchSortOptions.PaymentStatus,
              MediaSearchSortOptions.ReviewStatus,
            ]}
            firstColumnWidth="125px"
            onClickSort={(sortLabel, sortDirection) => {
              setActiveSort(mapEnum(sortLabel));
              setActiveSortDirection(sortDirection);
            }}
            sortTriangleSize="14px"
            {...(activeSort !== null && {
              activeSort: {
                label: Object.values(MediaSearchSortOptions)[activeSort],
                direction: activeSortDirection,
              },
            })}
          >
            {media.map((media: MediaSearchResult, index) => {
              return (
                <Fragment key={media.mediaId}>
                  <div className="cell">
                    <div className="img-container">
                      <img
                        src={
                          (media.thumbnailLink &&
                            getThumbnailOrPlaceholder(
                              MediaType[
                                media.fileType as keyof typeof MediaType
                              ],
                              media.thumbnailLink
                            )) ||
                          Placeholder
                        }
                        alt="Media Thumbnail"
                      />
                    </div>
                  </div>
                  <div className="cell">
                    <p>
                      <StyledLink
                        to={`/admin/edit-entry?entryId=${media.entryId}`}
                        target="_blank"
                      >
                        {media.entryId}
                      </StyledLink>
                    </p>
                  </div>
                  <div className="cell">
                    <p>{media.fileType}</p>
                  </div>
                  <div className="cell">
                    <p>{media.fieldName}</p>
                  </div>
                  <div className="cell">
                    <p>{media.origFileName}</p>
                  </div>
                  <div className="cell">
                    <p>{media.entryTitle}</p>
                  </div>
                  <div className="cell">
                    <p>{media.author}</p>
                  </div>
                  <div className="cell">
                    <p>{media.level1}</p>
                  </div>
                  <div className="cell">
                    <p>{media.entryProgram}</p>
                  </div>
                  <div className="cell">
                    <p>{media.paymentStatus}</p>
                  </div>
                  <div className="cell">
                    <p>{media.reviewStatus}</p>
                  </div>
                </Fragment>
              );
            })}
          </Table>
        ) : (
          <TablePlaceholder active={isActive}>
            Please add a search to see result table.
          </TablePlaceholder>
        ))}

      {isLoading && (
        <TablePlaceholder active={false}>
          <Loading fullScreen={false} showLogo={false} />
        </TablePlaceholder>
      )}

      {/* bottom pagination controls */}
      {isActive && !isLoading && (
        <AdminPageControls
          totalRecords={totalRecords}
          totalAmountOfPages={totalAmountOfPages}
          currentPage={page}
          setPage={(newPage) => setPage(newPage)}
          setIsLoading={(isLoading) => setIsLoading(isLoading)}
        />
      )}
    </FormikProvider>
  );
};

export default MediaSearchTab;

export interface MediaSearchRequest {
  fileType?: number[];
  fieldType?: number[];
  isHidden?: string;
  entryId?: number;
  RootPrograms?: number[];
  originalFilename?: string;
  title?: string;
  entryProgram?: string;
  keyword?: string;
  brand?: string;
  entryType?: string;
  paymentStatus?: number[];
  companyName?: string;
  isCampaign?: string;
  reviewStatus?: number[];
  email?: string;
  parentChild?: string;
  awardLevel?: string[];
}

export interface MediaSearchResult {
  mediaId?: string;
  thumbnailLink?: string;
  entryId?: string;
  fileType?: string;
  fieldName?: string;
  origFileName?: string;
  entryTitle?: string;
  author?: string;
  level1?: string;
  entryProgram?: string;
  paymentStatus?: string;
  reviewStatus?: string;
}

enum MediaSearchSortOptions {
  EntryId = "Entry Id",
  FileType = "File Type",
  Field = "Field",
  FileName = "File Name",
  EntryTitle = "Entry Title",
  Author = "Author",
  Level1 = "Level 1",
  EntryLevelProgram = "Entry Level Program",
  PaymentStatus = "Payment Status",
  ReviewStatus = "Review Status",
}

enum MediaSearchSortEnum {
  EntryId,
  FileType,
  Field,
  FileName,
  EntryTitle,
  Author,
  Level1,
  EntryLevelProgram,
  PaymentStatus,
  ReviewStatus,
}

export const FileTypeOptions: DropdownOptions[] = [
  { value: MediaType.Audio, label: "Audio" },
  { value: MediaType.Document, label: "Document" },
  { value: MediaType.Image, label: "Image" },
  { value: MediaType.Video, label: "Video" },
];
