import { useContext, useState } from "react";
import { useAlert } from "../components/Alert/Alerts";

// https://codepen.io/edgarv09/pen/KKabVob
const useDownloadMedia = () => {
	const { addNewAlert } = useAlert();
	const [isDownloading, setIsDownloading] = useState(false);

	const downloadFile = (url: string, fileName?: string) => {
		addNewAlert({ type: "alert", message: "Download in progress" });
		setIsDownloading(true);

		return fetch(url)
			.then((res) => res.blob())
			.then((res) => {
				const url = window.URL.createObjectURL(res);
				const a = document.createElement("a");
				a.href = url;
				a.download = fileName || Date.now().toString();

				document.body.appendChild(a);
				a.click();
				a.remove();
				setIsDownloading(false);
			})
			.catch((err) => addNewAlert({ type: "error", message: err.message }));
	};

	return { downloadFile, isDownloading };
};

export default useDownloadMedia;
