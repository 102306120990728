import React, { useRef, useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import {
	ImVolumeHigh,
	ImVolumeMedium,
	ImVolumeLow,
	ImVolumeMute,
} from "react-icons/im";
import {
	IoVolumeOff,
	IoVolumeLow,
	IoVolumeMedium,
	IoVolumeHigh,
} from "react-icons/io5";

// To use the icon in

const VolumeControlContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	margin-top: 1rem;
`;

const VolumeSlider = styled.input`
	width: 100px;
	accent-color: ${({ theme }) => theme.colorPrimary};
`;

interface VolumeControlProps {
	volume: number;
	onVolumeChange: (newVolume: number) => void;
	disabled?: boolean;
}

const VolumeControl: React.FC<VolumeControlProps> = ({
	volume,
	onVolumeChange,
	disabled,
}) => {
	const theme = useTheme();
	const [currentVolume, setCurrentVolume] = useState(volume);
	const volumeSliderRef = useRef<HTMLInputElement | null>(null);
	const sliderColor = disabled ? theme.colorFieldDisabled : theme.colorPrimary;

	useEffect(() => {
		setCurrentVolume(volume);
	}, [volume]);

	const handleVolumeChange = () => {
		const newVolume = parseFloat(volumeSliderRef.current?.value || "0");
		setCurrentVolume(newVolume);
		onVolumeChange(newVolume);
	};

	return (
		<VolumeControlContainer>
			<VolumeSlider
				ref={volumeSliderRef}
				type="range"
				min="0"
				max="1"
				step="0.01"
				value={currentVolume}
				onChange={handleVolumeChange}
				disabled={disabled}
			/>
			{currentVolume >= 0.66 ? (
				<IoVolumeHigh color={sliderColor} size={25} />
			) : currentVolume > 0.33 && currentVolume < 0.66 ? (
				<IoVolumeMedium color={sliderColor} size={25} />
			) : currentVolume === 0 ? (
				<IoVolumeOff color={theme.colorProgressBackgroundDark} size={25} />
			) : (
				<IoVolumeLow color={sliderColor} size={25} />
			)}
		</VolumeControlContainer>
	);
};

export default VolumeControl;
