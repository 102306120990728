import { useParams } from "react-router-dom";
import {
	CardContainer,
	CardHeader,
	CardBody,
} from "../../../../components/DashboardCard/DashboardCard";
import { useTheme } from "styled-components";
import {
	NavTabs,
	NavTab,
	NavTabPanel,
} from "../../../../components/NavTabs/NavTabs";
import { ConfigNavTabs } from "../../Program/ProgramConfig";
import styled from "styled-components";
import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from "react";
import EntrySetTab from "./EntrySetTab";
import JuryPoolTab from "./JuryPoolTab";
import RoundsTab from "./RoundsTab";
import RoundAssignmentTab from "./RoundAssignmentTab";
import { JuryCardModel } from "../JudgingInterfaces";
import { getJuryCardById } from "../manageJudging";

import BrowserHeader from "../../../../components/BrowserHeader/BrowserHeader";
import { useAlert } from "../../../../components/Alert/Alerts";

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 1rem;
	margin-top: 2rem;
`;

export default function JudgingConfigJury() {
	const { programId, juryId } = useParams<{
		programId: string;
		juryId: string;
	}>();
	const theme = useTheme();
	const [juryCard, setJuryCard] = useState<JuryCardModel>();
	const { addNewAlert } = useAlert();
	const isRoundAssignmentActive =
		juryCard?.isEntrySetFinal &&
		juryCard?.isJuryPoolFinal &&
		juryCard?.isRoundConfigurationFinal;

	useEffect(() => {
		getJuryCardById(Number(juryId))
			.then((res) => {
				if (res.status == 200) {
					setJuryCard(res.data);
				}
			})
			.catch((err) => {
				//console.log("jury error: ", err);
				addNewAlert({
					type: "error",
					message: "Problem getting jury card",
				});
			});
	}, [juryId]);

	return (
		<CardContainer className="h-full">
			<BrowserHeader title={`Jury Config: ${juryCard?.name}`} />
			<CardHeader className="!justify-start !items-end">
				<h2 className="card-title mr-[2rem]">
					{juryCard?.name}: Configure Entry Sets
				</h2>

				{/*TODO: get list of jury */}
				{/* <TransparentDropdown
          options={[{ value: "Some Jury 1" }, { value: "Some Jury 2" }]}
          onChange={(selectedValue) => console.log(selectedValue)}
        /> */}
			</CardHeader>

			<NavTabs>
				<ConfigNavTabs>
					<NavTab>Entry Sets</NavTab>
					<NavTab>Jury Pool</NavTab>
					<NavTab>Rounds</NavTab>
					<NavTab hide={!isRoundAssignmentActive}>Round Assignments</NavTab>
				</ConfigNavTabs>
				<NavTabPanel>
					<EntrySetTab
						programId={programId}
						juryId={juryId}
						juryCard={juryCard}
						setJuryCard={setJuryCard}
					/>
				</NavTabPanel>
				<NavTabPanel>
					<JuryPoolTab
						programId={programId}
						juryId={juryId}
						juryCard={juryCard}
						setJuryCard={setJuryCard}
					/>
				</NavTabPanel>
				<NavTabPanel>
					<RoundsTab
						programId={programId}
						juryId={juryId}
						juryCard={juryCard}
						setJuryCard={setJuryCard}
					/>
				</NavTabPanel>
				<NavTabPanel disabled={!isRoundAssignmentActive}>
					<RoundAssignmentTab
						programId={programId}
						juryId={juryId}
						juryCard={juryCard}
						setJuryCard={setJuryCard}
					/>
				</NavTabPanel>
			</NavTabs>
		</CardContainer>
	);
}

export interface JuryConfigTabProps {
	programId: string;
	juryId: string;
	juryCard?: JuryCardModel;
	setJuryCard: Dispatch<SetStateAction<JuryCardModel | undefined>>;
}
