import { useEffect, useState } from "react";
import MediaCarousel from "../../../components/MediaCarousel/MediaCarousel";
import { Execution, MediaField } from "./EntryDetail";
import { RenderMediaThumbnail } from "./MediaLightbox";

interface EntryDetailMediaSelectorProps {
	activeExecutionIndex: number;
	activeExecution: Execution;
	onMediaSelect: (media: any) => void;
	activeMediaPreview: any;
	hideHeading?: boolean;
	className?: string;
	//   showFirstMedia?: boolean;
}

const EntryDetailMediaSelector: React.FC<EntryDetailMediaSelectorProps> = ({
	activeExecutionIndex,
	activeExecution,
	onMediaSelect,
	activeMediaPreview,
	hideHeading,
	className,
}) => {
	const [selectedIndex, setSelectedIndex] = useState<number>();

	const mediaFields = activeExecution?.mediaFields || [];
	const media = mediaFields.reduce<any>((acc, cur, mediaFieldIndex) => {
		return [
			...acc,
			...cur.media.map((mediaItem, mediaIndex) => {
				return {
					...mediaItem,
					mediaFieldIndex,
					mediaIndex,
					title: cur.title,
				};
			}),
		];
	}, []);

	useEffect(() => {
		setSelectedIndex(0);
		onMediaSelect(media[0]);
	}, [activeExecutionIndex]);

	return (
		<div>
			{selectedIndex !== undefined && !hideHeading && (
				<div className="pl-[2rem] pt-4 text-colorCopyLight">
					Media {`${selectedIndex + 1}/${media.length}`}
				</div>
			)}
			<MediaCarousel darkArrows>
				{media.map((_media: any, i: number) => {
					return (
						<div className={`flex flex-col w-[176px] p-4 ${className}`} key={i}>
							<RenderMediaThumbnail
								media={_media}
								onClick={(mediaItem) => {
									onMediaSelect(mediaItem);
									setSelectedIndex(i);
								}}
								selected={
									activeMediaPreview
										? _media.id === activeMediaPreview.id!
										: false
								}
							/>

							<div className="mt-[.5rem] ml-[.5rem] text-[0.875rem] font-semibold">
								{media.title}
							</div>
						</div>
					);
				})}
			</MediaCarousel>
		</div>
	);
};

export default EntryDetailMediaSelector;
