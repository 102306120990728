import { useEffect, useState } from "react";
import assetsConfig from "../assetsConfig";

const widgetSettings = `
window.fwSettings = {
	widget_id: 36000000663,
};
!(function () {
	if ("function" != typeof window.FreshworksWidget) {
		var n = function () {
			n.q.push(arguments);
		};
		(n.q = []), (window.FreshworksWidget = n);
	}
})();
`;

const useHelpWidget = () => {
	const [showHelpWidget, setShowHelpWidget] = useState<boolean>(false);

	useEffect(() => {
		setShowHelpWidget(assetsConfig.settings.showHelpWidget);
	}, [assetsConfig.settings.showHelpWidget]);

	useEffect(() => {
		if (showHelpWidget) {
			const settings = document.createElement("script");
			settings.innerHTML = widgetSettings;
			document.head.appendChild(settings);

			const widget = document.createElement("script");
			widget.src = "https://widget.freshworks.com/widgets/36000000663.js";
			widget.async = true;
			widget.defer = true;
			document.head.appendChild(widget);

			return () => {
				document.head.removeChild(settings);
				document.head.removeChild(widget);
			};
		}
	}, [showHelpWidget]);
};

export default useHelpWidget;
