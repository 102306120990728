import { useState, useEffect, useContext } from "react";
import { HubConnection } from "@microsoft/signalr";
import { useHistory } from "react-router-dom";
import {
  GalleryBatchCard,
  LiveToolsSettings,
  GalleryEntryCard,
  JuryCardAdmin,
  LiveToolsMediaType,
  LiveToolsMediaItem,
} from "../views/Admin/Judging/JudgingInterfaces";
import AuthenticationContext from "../components/Auth/AuthenticationContext";
import { useJuryHub, UseJuryHubReturnType } from "./useJuryHub";
import { DropdownOptions } from "../components/FormFields/DropdownField";
import {
  EntrySortOptions,
  ViewType,
} from "../views/Admin/Judging/LiveTools/EntryView";

export interface UseLiveToolsSettingsHubReturnType
  extends UseJuryHubReturnType {
  mediaState: LiveToolsMediaItem;
  batches: Array<GalleryBatchCard>;
  roundEntries: Array<GalleryEntryCard>;
  settings: LiveToolsSettings;
  juryCard: JuryCardAdmin;
  fetchOgImage: (url: string) => Promise<string | undefined>;
  scrollToTop: () => void;
}

export function useLiveToolsSettingsHub(): UseLiveToolsSettingsHubReturnType {
  const history = useHistory();
  const juryHub = useJuryHub();
  const { claims } = useContext(AuthenticationContext);
  const [mediaState, setMediaState] = useState<LiveToolsMediaItem>({
    id: 0,
    juryId: 0,
    mediaType: LiveToolsMediaType.PauseSlide,
  });
  const isAdmin =
    claims.findIndex(
      (claim) => claim.name === "role" && claim.value === "admin"
    ) > -1;

  const defaultLiveToolsSettings: LiveToolsSettings = {
    id: 0,
    juryId: 0,
    juryName: "",
    entryDisplayTitlePreview: "",
    isActive: false,
    entriesLocked: true,
    displayConfiguration: ViewType.Grid,
    sort: EntrySortOptions.PreviousRoundScoreDescending,
    includeBatchDivisions: false,
    showProgressIndicators: false,
    showPhysicalComponents: false,
    selectedBatches: [],
    jurorControl: false,
    revealWinner: false,
    selectedRoundStructureId: 0,
    highlightedRoundEntryId: 0,
    awardId: 0,
  };

  const [settings, setSettings] = useState<LiveToolsSettings>(
    defaultLiveToolsSettings
  );

  const blankJuryCard = {
    id: 0,
    activeEntries: 0,
    name: "",
    votesCast: 0,
    votesPossible: 0,
    activeJurors: 0,
    entrySetIds: [],
  };

  const [juryCard, setJuryCard] = useState<JuryCardAdmin>(blankJuryCard);

  // round entry data should come in either
  // set of batches or set of round Entries
  // depending on liveToolsSettings.includeBatchDivisions
  // these sets are mutually exclusive (both won't be populated at the same time)
  const [batches, setBatches] = useState<Array<GalleryBatchCard>>([]);
  const [roundEntries, setRoundEntries] = useState<Array<GalleryEntryCard>>([]);

  // useEffect for handling juryId changes
  useEffect(() => {
    if (juryHub.connection && juryHub.juryId) {
      // Fetch and update the JurorViewCard and JurorViewEntrySetCard data
      const getLiveEntries = (
        juryId: number,
        connection: HubConnection | null
      ) => {
        if (connection) {
          connection?.invoke("GetLiveEntries", juryId);
        }
      };
      juryHub.connection.on(`mediaUpdated-${juryHub.juryId}`, (data) => {
        setMediaState(data);
      });
      juryHub.connection.on(`liveJuryUpdated-${juryHub.juryId}`, (data) => {
        setSettings(data);
        juryHub.debug && console.log(`liveJuryUpdated-${juryHub.juryId}`, data);
      });

      juryHub.connection.on(`liveBatchesUpdated-${juryHub.juryId}`, (cards) => {
        setBatches(cards);
        setRoundEntries([]);
        juryHub.debug &&
          console.log(`liveBatchesUpdated-${juryHub.juryId}`, cards);
      });
      juryHub.connection.on(`liveVotesUpdated-${juryHub.juryId}`, () => {
        getLiveEntries(Number(juryHub.juryId), juryHub.connection);
      });

      juryHub.connection.on(
        `liveRoundEntriesUpdated-${juryHub.juryId}`,
        (cards) => {
          setBatches([]);
          setRoundEntries(cards);
          juryHub.debug &&
            console.log(`liveRoundEntriesUpdated-${juryHub.juryId}`, cards);
        }
      );

      juryHub.connection.on("juryCardUpdated", (card) => {
        setJuryCard(card);
        juryHub.debug && console.log("juryCardUpdated", card);
      });

      juryHub.debug && console.log(`JoinJuryGroup`, juryHub.connection);
      juryHub.connection.invoke("JoinJuryGroup", Number(juryHub.juryId));

      juryHub.debug && console.log(`JoinLiveJury`, juryHub.connection);
      juryHub.connection.invoke("JoinLiveJury", Number(juryHub.juryId));

      juryHub.debug && console.log(`JoinMediaViewer`, juryHub.connection);
      juryHub.connection.invoke("JoinMediaViewer", Number(juryHub.juryId));
    }
    // return () => {
    //   juryHub.connection?.off(`liveBatchesUpdated-${juryHub.juryId}`);
    //   juryHub.connection?.off(`liveRoundEntriesUpdated-${juryHub.juryId}`);
    //   juryHub.connection?.off(`liveJuryUpdated-${juryHub.juryId}`);
    // };
  }, [juryHub.connection, juryHub.juryId]);

  const fetchOgImage = async (url: string) => {
    if (juryHub.connection) {
      const response = await juryHub.connection.invoke<string | undefined>(
        "FetchOgImage",
        url
      );
      return response;
    } else {
      return;
    }
  };

  const scrollToTop = async () => {
    if (juryHub.connection) {
      await juryHub.connection.invoke("ScrollToTop", Number(settings.juryId));
    }
  };

  return {
    ...juryHub,
    mediaState,
    batches,
    roundEntries,
    settings: settings,
    juryCard,
    fetchOgImage,
    scrollToTop,
  };
}
