import {
	DateType,
	EntryDateField,
} from "../../Admin/Program/ProgramInterfaces";
import { fieldRequired } from "../../../components/FieldModal/FieldModal";
import { useEffect, useState, useContext } from "react";
import { ValidateContext } from "../EntryForm";
import dateTostring, { adjustForDST } from "../../../utils/dateToString";
import TZDatePickerField from "../../../components/FormFields/TZDatePickerField";
import { AUTOSAVE_MESSAGE, DynamicEntryField } from "../DynamicFields";
import { useFormikContext } from "formik";
import { updateEntryField } from "../manageEntry";
import { SUCCESS_MESSAGE_DURATION } from "../../../components/FormFields/FieldTemplate";
import { SuccessMessageWrapper } from "../../../components/FormFields/FieldWrapper";

const EntryDateFieldForm = (props: EntryDateFieldProps) => {
	const validate = useContext(ValidateContext);
	const asterisk = props.field.programField.isRequired ? "*" : "";
	const startDateError =
		validate && !props.field.startDate && props.field.programField.isRequired
			? fieldRequired
			: undefined;

	// check if START date is before minimum date
	const minStartDateErr =
		props.field.startDate &&
		props.field.programField.minDate &&
		new Date(props.field.startDate) <
			adjustForDST(props.field.programField.minDate)
			? `
        Date cannot be before ${dateTostring(
					adjustForDST(props.field.programField.minDate)
				)}
        `
			: undefined;

	// check if START date is after max date
	const maxStartDateErr =
		props.field.startDate &&
		props.field.programField.maxDate &&
		new Date(props.field.startDate) >
			adjustForDST(props.field.programField.maxDate)
			? `Date cannot be after ${dateTostring(
					adjustForDST(props.field.programField.maxDate)
			  )}`
			: undefined;

	const endDateError =
		validate && !props.field.endDate && props.field.programField.isRequired
			? fieldRequired
			: undefined;

	// check if END date is before minimum date
	const minEndDateErr =
		props.field.endDate &&
		props.field.programField.minDate &&
		new Date(props.field.endDate) <
			adjustForDST(props.field.programField.minDate)
			? `Date cannot be before ${dateTostring(
					adjustForDST(props.field.programField.minDate)
			  )}`
			: undefined;

	// check if END date is after max date
	const maxEndDateErr =
		props.field.endDate &&
		props.field.programField.maxDate &&
		new Date(props.field.endDate) >
			adjustForDST(props.field.programField.maxDate)
			? `Date cannot be after 
       ${dateTostring(adjustForDST(props.field.programField.maxDate))}
       `
			: undefined;

	const handleDateChange = (fieldName: string, selectedDate: Date | null) => {
		// Convert the selectedDate to a Unix timestamp
		const timestamp = selectedDate ? selectedDate.getTime() : null;
		// Update only the changed field (startDate or endDate) and keep the other field intact
		const updatedField = {
			...props.field,
			[fieldName]: timestamp,
		};

		return updateEntryField(updatedField);
	};

	return (
		<div className="col-w-100" id={`wrapper-div-${props.name}`}>
			{!props.isAdmin && (
				<>
					<h3 className="subtitle font-semibold">
						{props.field.programField.title}
						{asterisk}
					</h3>
					<p className="whitespace-pre-wrap">
						{props.field.programField.entrantHelpText}
					</p>
				</>
			)}

			<div className="flex gap-[1rem]">
				<SuccessMessageWrapper>
					{(success, setSuccess) => (
						<TZDatePickerField
							name={`${props.name}.startDate`}
							showTimeSelect={props.field.programField.displayTime}
							value={props.field.startDate}
							//   value={values[props.name].startDate}
							success={success}
							error={startDateError || minStartDateErr || maxStartDateErr}
							placeholder={
								props.field.programField.dateType === DateType.Range
									? "Start Date"
									: undefined
							}
							readOnly={props.readOnly}
							onChange={(selectedDate) =>
								props.saveOnSelect &&
								handleDateChange("startDate", selectedDate).then(() =>
									setSuccess(AUTOSAVE_MESSAGE)
								)
							}
						/>
					)}
				</SuccessMessageWrapper>

				{props.field.programField.dateType === DateType.Range && (
					<SuccessMessageWrapper>
						{(success, setSuccess) => (
							<TZDatePickerField
								name={`${props.name}.endDate`}
								showTimeSelect={props.field.programField.displayTime}
								value={props.field.endDate}
								success={success}
								error={endDateError || minEndDateErr || maxEndDateErr}
								placeholder="End Date"
								readOnly={props.readOnly}
								onChange={(selectedDate) =>
									props.saveOnSelect &&
									handleDateChange("endDate", selectedDate).then(() =>
										setSuccess(AUTOSAVE_MESSAGE)
									)
								}
							/>
						)}
					</SuccessMessageWrapper>
				)}
			</div>
		</div>
	);
};

export default EntryDateFieldForm;

interface EntryDateFieldProps extends DynamicEntryField {
	field: EntryDateField;
	name: string;
	saveOnSelect?: boolean;
}
