import { GalleryBatchCard, GalleryEntryCard } from "../JudgingInterfaces";

const filterEntries = (
  searchVal: string,
  batches?: GalleryBatchCard[],
  roundEntries?: GalleryEntryCard[]
) => {
  searchVal = searchVal && searchVal.toUpperCase();

  if (batches && batches.length > 0) {
    if (!searchVal) return batches; // return unfiltered batches

    // search batches for partial title, brand, and id match
    return batches.flatMap((batch) => {
      const batchRoundEntries = batch.roundEntries.flatMap((roundEntry) => {
        if (
          (roundEntry.title &&
            roundEntry.title.toUpperCase().includes(searchVal)) ||
          (roundEntry.brand &&
            roundEntry.brand.toUpperCase().includes(searchVal)) ||
          String(roundEntry.entryId).includes(searchVal)
        ) {
          return roundEntry;
        } else return [];
      });

      // only return batch if at least one roundEntry meet the search criteria
      if (batchRoundEntries.length > 0) {
        return batch;
      } else return [];
    });
  } else if (roundEntries && roundEntries.length > 0) {
    if (!searchVal) return roundEntries; // return unfiltered round entries

    // search round entries for partial title, brand, and id match
    return roundEntries.filter((roundEntry) => {
      if (
        (roundEntry.title &&
          roundEntry.title.toUpperCase().includes(searchVal)) ||
        (roundEntry.brand &&
          roundEntry.brand.toUpperCase().includes(searchVal)) ||
        String(roundEntry.entryId).includes(searchVal)
      ) {
        return true;
      } else return false;
    });
  } else return [];
};

export default filterEntries;
