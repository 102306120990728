import { useContext, useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import styled, { useTheme } from "styled-components";
import SeasonCard from "./SeasonCard";
import { urlSeasons } from "../../../endpoints";
import axios from "axios";
import DashboardCard from "../../../components/DashboardCard/DashboardCard";

import HierarchyNav, {
	HierarchyCard,
} from "../../../components/HierarchyNav/HierarchyNav";

import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import { useAlert } from "../../../components/Alert/Alerts";

export default function Seasons() {
	const theme = useTheme();
	const { addNewAlert } = useAlert();
	const defaultActiveValue = true;
	const [seasons, setSeasons] = useState<SeasonModel[]>([]);
	const newSeason: SeasonModel = {
		name: "",
		isActive: defaultActiveValue,
	};
	const [postNewSeason, setPostNewSeason] = useState<SeasonModel>();

	const addSeason = () => {
		if (!postNewSeason) {
			// add new season
			setPostNewSeason(newSeason);
			setSeasons([newSeason, ...seasons]);
		} else if (postNewSeason.name) {
			// allow user to add another season AFTER they save
			setSeasons((prevState) => [newSeason, ...prevState]);
			setPostNewSeason(newSeason);
		} else {
			addNewAlert({
				type: "error",
				message: "Plesae save the new season before adding more",
			});
		}
	};

	useEffect(() => {
		axios
			.get<SeasonModel[]>(`${urlSeasons}`)
			.then((response) => {
				setSeasons(response.data);
			})
			.catch((error) => {
				// console.log(error);
			});
	}, []);

	return (
		<DashboardCard title="Manage Seasons" hasSearch>
			<BrowserHeader title="Manage Seasons" />
			<Button
				className="ml-auto mb-[2rem]"
				icon="plus"
				onClick={() => addSeason()}
				iconColor={theme.colorCopyLightLight}
			>
				Add A Season
			</Button>

			<HierarchyNav
				responsive={true}
				columns={[
					{ label: null, width: "150px" },
					{ label: "Season Name" },
					{ label: "Status" },
				]}
			>
				{(gridColumns: string, gridArea: string) =>
					seasons.map((season, index) => (
						<HierarchyCard
							responsive={true}
							key={season.name + index}
							gridColumns={gridColumns}
							gridArea={gridArea}
						>
							<SeasonCard
								isEditing={season.id ? false : true}
								season={season}
								onPostSeason={(season) => {
									setPostNewSeason(season);
									const removedEmptySeason = seasons.slice(1);
									setSeasons([season, ...removedEmptySeason]);
								}}
							/>
						</HierarchyCard>
					))
				}
			</HierarchyNav>
		</DashboardCard>
	);
}

export interface SeasonModel {
	id?: number;
	name: string;
	isActive: boolean;
	createdDate?: string;
	createdBy?: string;
	modifiedDate?: string;
	modifiedBy?: string;
}
