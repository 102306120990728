import { WarningModal } from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import { useTheme } from "styled-components";

export const ChangeWarning = (props: {
  show: boolean;
  title: string;
  message: string;
  noMessage?: string;
  yesMessage?: string;
  onHideModal(): void;
  onConfirm(): void;
}) => {
  return (
    <WarningModal
      show={props.show}
      title={props.title}
      message={props.message}
      close={() => props.onHideModal()}
    >
      <Button className="button-light" onClick={() => props.onHideModal()}>
        {props.noMessage || "No"}
      </Button>
      <Button
        onClick={() => {
          props.onConfirm();
          props.onHideModal();
        }}
      >
        {props.yesMessage || "Yes"}
      </Button>
    </WarningModal>
  );
};

export const EntrySavedPopup = (props: {
  show: boolean;
  onHideModal(): void;
  onConfirm(): void;
  message: string;
  title: string;
  isValid: boolean;
  confirmButtonLabel: string;
}) => {
  const theme = useTheme();
  return (
    <WarningModal
      show={props.show}
      title={props.title}
      message={props.message}
      close={() => props.onHideModal()}
      icon={
        props.isValid ? (
          <Icon
            className="my-[1rem]"
            icon="check"
            color={theme.colorActivation}
            width="50px"
            height="50px"
          />
        ) : undefined
      }
    >
      <Button className="button-light" onClick={() => props.onHideModal()}>
        Return to my entry
      </Button>
      <Button
        onClick={() => {
          props.onConfirm();
          props.onHideModal();
        }}
      >
        {props.confirmButtonLabel}
      </Button>
    </WarningModal>
  );
};
