import axios from "axios";
import { useState, useEffect } from "react";
import { urlSeasons } from "../endpoints";
import { getSeasons } from "../views/Admin/Program/manageProgram";

export interface DropdownOptions {
  value: any;
  label?: string | number;
  statusColor?: string;
}

export const getAllSeasons = async (): Promise<DropdownOptions[]> => {
  const response = await axios.get(`${urlSeasons}/getSeasonsDropdown`);
  if (response.status === 200) {
    return response.data;
  } else {
    return [];
  }
};

export const useSeasons = () => {
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOptions[]>([]);

  useEffect(() => {
    const fetchSeasons = async () => {
      const allSeasons = await getAllSeasons();
      setDropdownOptions(allSeasons);
    };

    fetchSeasons();
  }, []);

  return dropdownOptions;
};
