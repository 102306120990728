import { DropdownOptions } from "../../components/FormFields/DropdownField";
import {
	daysDifference,
	isDateWithinYearsAndDays,
} from "../../utils/dateInRange";
import { OrderModel } from "./OrderInterfaces";

enum MonthFilterOption {
	ninetyDays,
	oneEightyDays,
}

const getYearOptions = (oldestYear: number): DropdownOptions[] => {
	const currentYear = new Date().getFullYear();
	let yearOptions: DropdownOptions[] = [];
	for (let i = currentYear; i >= oldestYear; i--) {
		yearOptions.push({
			value: i,
			label: i,
		});
	}

	return yearOptions;
};

export const filterOrderByDateOptions: DropdownOptions[] = [
	{ value: MonthFilterOption.ninetyDays, label: "Past 90 days" },
	{ value: MonthFilterOption.oneEightyDays, label: "Past 180 days" },
	...getYearOptions(2022),
];

export const filterOrderByDate = (orders: OrderModel[], sortValue: number) => {
	if (sortValue === MonthFilterOption.ninetyDays) {
		return orders.filter((order) =>
			order.createdDate
				? daysDifference(new Date(order.createdDate)) <= 90
				: false
		);
	} else if (sortValue === MonthFilterOption.oneEightyDays) {
		return orders.filter((order) =>
			order.createdDate
				? daysDifference(new Date(order.createdDate)) <= 180
				: false
		);
	}
	// filter by year, e.g. 2023
	else if (sortValue.toString().length === 4) {
		return orders.filter((order) =>
			order.createdDate
				? new Date(order.createdDate).getFullYear() === sortValue
				: false
		);
	} else return orders;
};
