import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useFormikContext } from "formik";
import { FieldError } from "./FieldTemplate";
import styled, { css } from "styled-components";
import FieldWrapper from "./FieldWrapper";
import { defaultBoxShadow } from "../../assetsConfig";

const StyledRichTextField = styled.div<{ height?: string }>`
	box-shadow: ${defaultBoxShadow};
	font-family: ${({ theme }) => theme.fontCopy} !important;
	font-size: ${({ theme }) => theme.pSize} !important;
	background: ${({ theme }) => theme.colorBackgroundLightLight};

	ul,
	ol,
	li {
		font-family: ${({ theme }) => theme.fontCopy} !important;
		font-size: ${({ theme }) => theme.pSize} !important;
	}

	.ql-formats {
		margin-right: 0 !important;
	}

	.ql-snow {
		border: 0 !important;
	}

	.ql-editor {
		&.ql-editor.ql-blank::before {
			font-family: ${({ theme }) => theme.fontCopy} !important;
			color: ${({ theme }) => theme.colorCopyLight} !important;
			font-style: normal;
			font-size: ${({ theme }) => theme.pSize};
		}
		${(p) =>
			p.height &&
			css`
				height: ${p.height};
			`}
	}
`;

const EditorToolbar = () => {
	return (
		<div id="toolbar">
			{/* <span className="ql-formats">
				<select className="ql-font" defaultValue="arial">
					<option value="arial">Arial</option>
					<option value="comic-sans">Comic Sans</option>
					<option value="courier-new">Courier New</option>
					<option value="georgia">Georgia</option>
					<option value="helvetica">Helvetica</option>
					<option value="lucida">Lucida</option>
				</select>
				<select className="ql-size" defaultValue="medium">
					<option value="extra-small">Size 1</option>
					<option value="small">Size 2</option>
					<option value="medium">Size 3</option>
					<option value="large">Size 4</option>
				</select>
				<select className="ql-header" defaultValue="3">
					<option value="1">Heading</option>
					<option value="2">Subheading</option>
					<option value="3">Normal</option>
				</select>
			</span> */}
			<span className="ql-formats">
				<button className="ql-bold" />
				<button className="ql-italic" />
				<button className="ql-list" value="ordered" />
				<button className="ql-list" value="bullet" />
				<button className="ql-link" />

				{/* <button className="ql-underline" />
				<button className="ql-strike" /> */}
			</span>
			{/* <span className="ql-formats">
        <button className="ql-indent" value="-1" />
				<button className="ql-indent" value="+1" />
      </span> */}

			{/* <span className="ql-formats">
				<button className="ql-script" value="super" />
				<button className="ql-script" value="sub" />
				<button className="ql-blockquote" />
				<button className="ql-direction" />
			</span>
			<span className="ql-formats">
				<select className="ql-align" />
				<select className="ql-color" />
				<select className="ql-background" />
			</span>
			<span className="ql-formats">
				<button className="ql-link" />
				<button className="ql-image" />
				<button className="ql-video" />
			</span>
			<span className="ql-formats">
				<button className="ql-formula" />
				<button className="ql-code-block" />
				<button className="ql-clean" />
			</span> */}
			{/* <span className="ql-formats">
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    </span> */}
		</div>
	);
};

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomUndo = () => (
	<svg viewBox="0 0 18 18">
		<polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
		<path
			className="ql-stroke"
			d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
		/>
	</svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
	<svg viewBox="0 0 18 18">
		<polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
		<path
			className="ql-stroke"
			d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
		/>
	</svg>
);

const RichTextField = (props: RichTextFieldProps) => {
	const { values, setFieldValue, errors } = useFormikContext<any>();

	const handleChange = (value: any) => {
		// console.log("rich text field change", value);
		if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
			//textarea is empty
			setFieldValue(props.name, "");
		} else {
			setFieldValue(props.name, value);
		}
	};

	// Undo and redo functions for Custom Toolbar
	// function undoChange() {
	// 	this.quill.history.undo();
	// }
	// function redoChange() {
	// 	this.quill.history.redo();
	// }

	var toolbarOptions = [
		"bold",
		"italic",
		{ list: "ordered" },
		{ list: "bullet" },
		"link",
	];

	const modules = {
		toolbar: toolbarOptions,
		history: {
			delay: 500,
			maxStack: 100,
			userOnly: true,
		},
	};
	const formats = [
		// "header",
		// "font",
		// "size",
		"bold",
		"italic",
		// "underline",
		// "align",
		// "strike",
		// "script",
		// "blockquote",
		// "background",
		"list",
		"list",
		// "indent",
		"link",
		// "image",
		// "color",
		// "code-block",
	];

	useEffect(() => {
		setFieldValue(props.name, props.value); // set formik value
	}, [props.value]);

	return (
		<FieldWrapper
			className={props.className}
			name={props.name}
			success={props.success}
			error={props.error}
		>
			{(success, error) => (
				<StyledRichTextField
					{...(props.height && {
						height: props.height,
					})}
				>
					<EditorToolbar />
					<ReactQuill
						// theme="snow"
						id={`rich-text-field-${props.name}`}
						value={values[props.name] || ""}
						onChange={handleChange}
						placeholder={props.placeholder}
						modules={modules}
						formats={formats}
					/>
				</StyledRichTextField>
			)}
		</FieldWrapper>
	);
};

export default RichTextField;

interface RichTextFieldProps {
	className?: string;
	placeholder: string;
	name: string;
	height?: string;
	value?: string;
	success?: string;
	error?: string;
}
