import { useFormikContext, FieldArray } from "formik";
import {
	ProgramCreditField,
	ProgramDateField,
	ProgramListField,
	ProgramTextField,
	ProgramLinkField,
	ProgramMediaField,
	ProgramPhysicalComponentField,
	EntryTextField,
	EntryDateField,
	EntryListField,
	EntryLinkField,
	EntryPhysicalComponentField,
	EntryMediaField,
} from "../Admin/Program/ProgramInterfaces";
import EntryDateFieldForm from "./Fields/EntryDateFieldForm";
import EntryTextFieldForm from "./Fields/EntryTextFieldForm";
import EntryListFieldForm from "./Fields/EntryListFieldForm";
import EntryLinkFieldForm from "./Fields/EntryLinkFieldForm";
import EntryPhysicalComponentFieldForm from "./Fields/EntryPhysicalComponentFieldForm";
import { useContext, useEffect, useState } from "react";
import EntryMediaFieldForm from "./Fields/EntryMediaFieldForm";
import { AnimationTracker } from "../../components/AnimateReorder/AnimateReorder";
// import { TestContext } from "./EntryForm";
import { Tag } from "../MediaLibrary/mediaLibrary.model";

export const AUTOSAVE_MESSAGE = "Changes Autosaved";

export function isAProgramDateField(obj: any): obj is ProgramDateField {
	return "dateType" in obj;
}

export function isAProgramListField(obj: any): obj is ProgramListField {
	return "listType" in obj;
}

export function isAProgramTextField(obj: any): obj is ProgramTextField {
	return "textType" in obj;
}

export function isAProgramLinkField(obj: any): obj is ProgramLinkField {
	return "maxLinks" in obj;
}

export function isAProgramMediaField(obj: any): obj is ProgramMediaField {
	return "mediaType" in obj;
}

export function isAProgramPhysicalComponentField(
	obj: any
): obj is ProgramPhysicalComponentField {
	return "isRequired" in obj;
}

const DynamicFields = (props: DynamicFieldsProps) => {
	// const formErr = useContext(TestContext);

	function isAProgramCreditField(obj: any): obj is ProgramCreditField {
		return "creditType" in obj;
	}

	return (
		<>
			{props.fieldArray?.length > 0 &&
				props.fieldArray.map((field: any, index: any) => {
					if (
						!props.hideMediaFields &&
						isAProgramMediaField(field.programField)
					) {
						return (
							<AnimationTracker key={index}>
								<EntryMediaFieldForm
									companyId={props.companyId}
									field={field as EntryMediaField}
									name={`${props.name}.${index}`}
									key={index}
									refreshMedia={props.refreshMedia}
									readOnly={props.readOnly}
									tags={props.tags}
									isAdmin={props.isAdmin}
								/>
							</AnimationTracker>
						);
					}

					if (props.hideNonMediaFields) {
						return <></>;
					}

					if (isAProgramTextField(field.programField)) {
						//   console.log(`${props.name}.${index}`, field);
						return (
							<EntryTextFieldForm
								field={field as EntryTextField}
								name={`${props.name}.${index}`}
								key={index}
								readOnly={props.readOnly}
								saveOnBlur={true}
							/>
						);
					}
					if (isAProgramDateField(field.programField)) {
						return (
							<EntryDateFieldForm
								field={field as EntryDateField}
								name={`${props.name}.${index}`}
								key={index}
								readOnly={props.readOnly}
								saveOnSelect={true}
							/>
						);
					}
					if (isAProgramListField(field.programField)) {
						// console.log("List options", field.programField.listOptions);
						return (
							<EntryListFieldForm
								field={field as EntryListField}
								name={`${props.name}.${index}`}
								key={index}
								readOnly={props.readOnly}
								saveOnChange={true}
							/>
						);
					}
					if (isAProgramLinkField(field.programField)) {
						return (
							<EntryLinkFieldForm
								field={field as EntryLinkField}
								name={`${props.name}.${index}`}
								key={index}
								readOnly={props.readOnly}
								saveOnChange={true}
							/>
						);
					}
					if (isAProgramPhysicalComponentField(field.programField)) {
						return (
							<EntryPhysicalComponentFieldForm
								field={field as EntryPhysicalComponentField}
								name={`${props.name}.${index}`}
								key={index}
								readOnly={props.readOnly}
								saveOnChange={true}
							/>
						);
					}
				})}
		</>
	);
};

export default DynamicFields;

interface DynamicFieldsProps {
	name: string;
	fieldArray: any;
	refreshMedia?(): void;
	readOnly?: boolean;
	companyId?: number;
	tags: Tag[];
	isAdmin?: boolean;

	// hide media fields while keeping the index
	hideNonMediaFields?: boolean;
	hideMediaFields?: boolean;
}

export interface EntryError {
	[field: string]: string;
}

export interface DynamicEntryField {
	readOnly?: boolean;
	isAdmin?: boolean; // for new admin entry edit
}
