import { useTheme } from "styled-components";
import Icon from "../Icon/Icon";
import Modal, { ModalCard, ModalContainer } from "../Modal/Modal";
import HierarchyDropDown, { EntryAction } from "./HierarchyDropDown";
const HierarchyDropDownModal = (props: HierarchyDropDownModalProps) => {
	const theme = useTheme();
	return (
		<Modal show={props.entryId != null}>
			<ModalCard
				className="!max-h-full"
				title={
					props.action === EntryAction.Duplicate
						? "Duplicate Entry"
						: "Move Entry"
				}
				headerIcons={
					<Icon
						icon="close"
						color={theme.colorPrimary}
						onClick={() => props.setEntryId(null)}
						width="30px"
						height="30px"
					/>
				}
			>
				<div className="flex flex-col py-[2.625rem] px-[1.875rem] gap-[1.5625rem]">
					{props.action === EntryAction.Duplicate && (
						<p>
							Please select the destination for your duplicated entry and select
							Duplicate below. Please review the duplicated entry in your Drafts
							and make any changes necessary.
						</p>
					)}
					{props.action === EntryAction.Move && (
						<p>
							Please select the destination for this entry and click Move. After
							the entry has moved, review it and make any changes necessary.
						</p>
					)}
					{props.entryId && (
						<HierarchyDropDown
							entryId={props.entryId!}
							closeModal={() => props.setEntryId(null)}
							refreshEntries={props.refreshEntries}
							parentId={null}
							action={props.action}
						/>
					)}
				</div>
			</ModalCard>
		</Modal>
	);
};

export default HierarchyDropDownModal;

interface HierarchyDropDownModalProps {
	entryId: number | null;
	parentId?: number;
	setEntryId: React.Dispatch<React.SetStateAction<number | null>>;
	refreshEntries?(): any;
	action: EntryAction;
}
