import styled, { useTheme } from "styled-components";
import { useState, useEffect } from "react";
import Button from "../../../../components/Button/Button";
import Icon from "../../../../components/Icon/Icon";
import {
  GalleryQuickVote,
  JuryCardAdmin,
  LiveToolsMediaItem,
  LiveToolsMediaType,
  LiveToolsSettings,
} from "../JudgingInterfaces";
import { BatchSelectionCard } from "../../../../hooks/useAdminLiveToolsHub";
import ToggleSwitch from "../../../../components/ToggleSwitch/ToggleSwitch";

const StyledJuryHeader = styled.div`
  /* position: sticky; */
  display: flex;
  align-items: center;
  top: 0;
  right: 0;
  width: 100%;
  gap: 2rem;
  padding: 1rem 1.5rem;
  background: ${({ theme }) => theme.colorBackgroundDarkDark};
  color: ${({ theme }) => theme.colorCopyLightLight};
  z-index: 9999;

  .wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  @media only screen and (max-width: ${({ theme }) => theme.md}) {
    flex-wrap: wrap;
  }
`;

const JuryHeader = (props: {
  juryCard: JuryCardAdmin;
  roundName: string;
  selectedBatches: BatchSelectionCard[];
  liveMediaState: LiveToolsMediaItem;
  settings: LiveToolsSettings;
  updateLiveToolsSettings: (settings: LiveToolsSettings) => void;
  updateMedia: (mediaState: LiveToolsMediaItem) => void;
}) => {
  const theme = useTheme();
  const [entryCount, setEntryCount] = useState(0);

  useEffect(() => {
    if (props.selectedBatches.length) {
      const count = props.selectedBatches
        .map((batch) => batch.entryCount)
        .reduce((a, b) => a + b, 0);
      setEntryCount(count);
    } else {
      setEntryCount(0);
    }
  }, [props.selectedBatches]);

  return (
    <StyledJuryHeader>
      <div className="wrap">
        <p className="playfair">{props.juryCard.name}</p>

        <span className="ml-[1rem] text-xxSmallSize">
          <b className="text-xxSmallSize">{props.roundName}</b> | {entryCount}{" "}
          Entries
        </span>
      </div>

      <div className="wrap ml-auto !gap-[2rem]">
        {(props.settings.roundStructure?.name === "Grand" ||
          props.settings.roundStructure?.name === "Winner") && (
          <div className=" flex flex-row no-wrap !gap-[1rem]">
            <p>
              {props.settings.revealWinner ? "Hide Winner" : "Reveal Winner"}
            </p>
            <ToggleSwitch
              checked={props.settings.revealWinner}
              id="reveal-winner"
              onClick={() => {
                props.updateLiveToolsSettings({
                  ...props.settings,
                  revealWinner: !props.settings.revealWinner,
                });
              }}
              name={"reveal-winner"}
              small={true}
            />
          </div>
        )}
        <Button
          icon="pause"
          iconColor={theme.colorCopyLightLight}
          onClick={() => {
            props.updateMedia({
              ...props.liveMediaState,
              mediaType: LiveToolsMediaType.PauseSlide,
              awardLevel: undefined,
              brand: undefined,
              title: undefined,
              filePath: undefined,
              text: undefined,
              isPlaying: undefined,
            });
          }}
        >
          Pause Slide
        </Button>
        <div className="wrap !gap-[2rem]">
          {props.settings.isActive ? (
            <Icon
              icon="stop"
              color={theme.colorCopyLightLight}
              width="35px"
              height="35px"
              onClick={() => {
                props.updateMedia({
                  ...props.liveMediaState,
                  mediaType: LiveToolsMediaType.PauseSlide,
                  awardLevel: undefined,
                  brand: undefined,
                  title: undefined,
                  filePath: undefined,
                  text: undefined,
                  isPlaying: undefined,
                });
                props.updateLiveToolsSettings({
                  ...props.settings,
                  isActive: false,
                  jurorControl: false,
                });
              }}
            />
          ) : (
            <Icon
              icon="player"
              color={theme.colorCopyLightLight}
              width="35px"
              height="35px"
              onClick={() => {
                props.updateLiveToolsSettings({
                  ...props.settings,
                  isActive: true,
                });
              }}
            />
          )}

          {/* TODO Visual Indicator of Juror Control Active */}
          {props.settings.isActive && (
            <Icon
              icon="media"
              className="border-col"
              color={
                props.settings.jurorControl
                  ? theme.colorSuccess
                  : theme.colorCopyLightLight
              }
              width="35px"
              height="35px"
              onClick={() => {
                props.updateLiveToolsSettings({
                  ...props.settings,
                  jurorControl: !props.settings.jurorControl,
                });
              }}
            />
          )}
        </div>
      </div>
    </StyledJuryHeader>
  );
};
export default JuryHeader;
