import { PDFDownloadLink } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import Button from "../../../components/Button/Button";
import PhysicalEntryPDF, { PhysicalEntryPDFProps } from "./PhysicalEntryPDF";
import assetsConfig from "../../../assetsConfig";

const StyledDownloadLink = styled(PDFDownloadLink)<{ buttonClass?: string }>`
	text-decoration: none;
	color: ${({ buttonClass, theme }) =>
		buttonClass === "blue"
			? theme.colorCopyLightLight
			: theme.colorCopyDarkDark};
`;

const DownloadPhysicalEntryPDF = (props: PhysicalEntryPDFProps) => {
	const theme = useTheme();
	const [fileName, setFileName] = useState(
		assetsConfig.companyNameShort + " Order"
	);

	useEffect(() => {
		if (props.order && props.order.id) {
			setFileName("Physical Component Label for Order #" + props.order.id);
		}
	}, [props.order]);

	const pageTitle = "Order # " + props.order.id + " Physical Component PDF";

	return (
		<>
			{props.order && (
				<StyledDownloadLink
					buttonClass={props.buttonClass}
					document={
						<PhysicalEntryPDF
							pageTitle={props.pageTitle || pageTitle}
							order={props.order}
							iconColor={props.iconColor}
						/>
					}
					fileName={fileName}
				>
					{({ blob, url, loading, error }) => (
						<Button
							className={props.buttonClass || "button-light"}
							icon="document"
							iconColor={props.iconColor}
						>
							{loading
								? "Loading document..."
								: props.title
								? props.title
								: "Download Phys. Component PDF"}
						</Button>
					)}
				</StyledDownloadLink>
			)}
		</>
	);
};

export default DownloadPhysicalEntryPDF;
