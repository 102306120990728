import FieldModal from "../../../../components/FieldModal/FieldModal";
import {
  PhysicalComponentFieldTemplate,
  ProgramPhysicalComponentField,
} from "../ProgramInterfaces";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import ToggleSwitchField from "../../../../components/FormFields/ToggleSwitchField";
import { fieldRequired } from "../../../../components/FieldModal/FieldModal";
import { fieldTemplateValidation } from "../../../../components/FieldModal/FieldModal";
import { newFieldInitialVal } from "../../../../components/FieldModal/FieldModal";

const PhysicalComponentFieldForm = (props: CustomFieldProps) => {
  const formikProps = useFormik({
    initialValues: {
      // populate existing field values for update form OR use new values for new field form
      ...(props.formData ? { ...props.formData } : { ...newFieldInitialVal }),
      isRequired: props.formData ? props.formData.isRequired : false,
    },
    onSubmit: async (value) => {},
    validationSchema: Yup.object({
      ...fieldTemplateValidation,
    }),
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <FormikProvider value={formikProps}>
      <FieldModal
        {...(props.formData && {
          formData: props.formData,
        })}
        formTitle="Physical Component Field Template"
        hideModal={() => props.hideModal()}
        formType="physical"
        isUpdateForm={props.formData ? true : false}
      >
        <div>
          <p className="mb-[1rem]">Is Required?</p>
          <ToggleSwitchField
            id="isRequired"
            name="isRequired"
            checked={formikProps.values.isRequired}
          />
        </div>
      </FieldModal>
    </FormikProvider>
  );
};

export default PhysicalComponentFieldForm;

interface CustomFieldProps {
  formData?: PhysicalComponentFieldTemplate | ProgramPhysicalComponentField;
  hideModal: () => void;
}
