import React, { useEffect } from "react";
import styled, { useTheme, css } from "styled-components";
import Icon from "../Icon/Icon";
import { CSSTransition } from "react-transition-group";
import { useRef, useState } from "react";
import Button from "../Button/Button";

const BreadCrumbsInnerContainer = styled.div`
	display: flex;
	align-items: center;

	&.breadcrumbs-enter {
		transform: translateX(100%);
	}

	&.breadcrumbs-enter-active {
		transform: translateX(0);
		transition: all 1s ease;
	}
	&.breadcrumbs-enter-done {
		transform: translateX(0);
		transition: all 1s ease;
	}

	&.breadcrumbs-exit {
		transform: translateX(0);
	}

	&.breadcrumbs-exit-active {
		transform: translateX(-100%);
		transition: all 1s ease;
	}
	&.breadcrumbs-exit-done {
		transform: translateX(0);
		transition: all 1s ease;
	}
`;

const BreadCrumbsContainer = styled.div<{
	dark?: boolean;
	clickable?: boolean;
	//   size?: string;
}>`
	display: flex;
	align-items: center;
	/* height: 35px; */

	.label,
	.caret-right {
		margin-right: 1rem;
	}

	.label {
		font-size: ${({ theme }) => theme.xSmallSize};

		color: ${(p) =>
			p.dark ? p.theme.colorCopyDarkDark : p.theme.colorCopyLightLight};

		${(p) =>
			p.clickable &&
			css`
				cursor: pointer;
				user-select: none;
			`};

		@media only screen and (min-width: ${({ theme }) => theme.xl}) {
			font-size: ${({ theme }) => theme.pSize};
		}

		@media only screen and (min-width: ${({ theme }) => theme.xxl}) {
			font-size: ${({ theme }) => theme.h2Size};
		}
	}

	.caret-right {
		transform: rotate(270deg);
	}

	@media only screen and (max-width: ${({ theme }) => theme.md}) {
		flex-wrap: wrap;
		gap: 1.5rem;
	}
`;

export const VerticalDivider = styled.div<{ size?: string }>`
	border: solid ${({ theme }) => theme.colorBorder};
	border-width: 0 2px 2px 0;
	transform: rotate(-45deg);
	margin-right: 1.25rem;

	width: 15px;
	height: 15px;

	@media only screen and (min-width: ${({ theme }) => theme.xl}) {
		width: 19px;
		height: 19px;
	}

	@media only screen and (min-width: ${({ theme }) => theme.xxl}) {
		width: 23px;
		height: 23px;
	}

	${(p) =>
		p.size &&
		css`
			width: ${p.size} !important;
			height: ${p.size} !important;
			margin-right: ${p.size} !important;
		`};
`;

const Breadcrumbs = (props: BreadcrumbsProps) => {
	const theme = useTheme();

	// const handleClick = (label: string) => {
	//   props.onClick(navLink.label);
	// };
	return (
		<BreadCrumbsContainer
			className={props.className}
			dark={props.dark}
			clickable={props.onClick ? true : false}
			/* size={props.size && props.size} */
		>
			{props.navLinks.map((navLink, i) => {
				if (navLink.dropdown) {
					// dropdown on hover
				} else {
					return (
						<div className="flex items-center" key={i}>
							<p
								className={`label ${
									props.underline ? "underline cursor-pointer" : ""
								}`}
								onClick={() => props.onClick && props.onClick(navLink, i)}
							>
								{navLink.label}
							</p>
							{/* <Icon
                className="!cursor-default"
                icon="caret"
                color={theme.colorCopyLight}
                width="35px"
                height="35px"
                rotation="270deg"
              /> */}
							<VerticalDivider></VerticalDivider>
						</div>
					);
				}
			})}
		</BreadCrumbsContainer>
	);
};

export default Breadcrumbs;

export interface NavLinkProps {
	label: string;
	value?: string | number;
	isActive?: boolean;
	dropdown?: React.ReactNode;
}

interface BreadcrumbsProps {
	className?: string;
	navLinks: NavLinkProps[];
	dark?: boolean;
	onClick?: (
		breadcrumb: {
			label: string;
			value?: string | number;
		},
		index: number
	) => void;
	/* size?: string; */
	underline?: boolean;
}
