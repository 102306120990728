import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { CSVLink } from "react-csv";
import Button from "../../../components/Button/Button";
import { getStandardDate } from "../Judging/Reports/manageHeaders";
import { isNumericOrDollarAmount } from "../../../components/Reports/ResultsTable";
import { EntryReviewerHeaders, EntryReviewReport } from "./manageReview";

const DualScrollWrapper = styled.div`
	width: 100%;

	.dual-scroll-top {
		overflow-x: auto;
		overflow-y: hidden;
		height: 20px;
	}

	.scrollbar-spacer {
		height: 1px;
	}

	.dual-scroll-content {
		overflow-x: auto;
		height: auto;
	}
`;

interface ReviewResultsTableProps {
	data: EntryReviewReport[];
	disableExport?: boolean;
	showTopScroll?: boolean;
}

interface DualScrollProps {
	children: React.ReactNode;
	showTopScroll?: boolean;
}

const DualScroll: React.FC<DualScrollProps> = ({
	children,
	showTopScroll = true,
}) => {
	const topScrollRef = useRef<HTMLDivElement | null>(null);
	const contentScrollRef = useRef<HTMLDivElement | null>(null);
	const [contentScrollWidth, setContentScrollWidth] = useState(0);

	useLayoutEffect(() => {
		const topScrollElement = topScrollRef.current;
		const contentScrollElement = contentScrollRef.current;

		const syncScroll = (
			source: HTMLDivElement | null,
			target: HTMLDivElement | null
		) => {
			if (source && target) {
				target.scrollLeft = source.scrollLeft;
			}
		};

		const handleTopScroll = () => {
			syncScroll(topScrollElement, contentScrollElement);
		};

		const handleContentScroll = () => {
			syncScroll(contentScrollElement, topScrollElement);
		};

		topScrollElement?.addEventListener("scroll", handleTopScroll);
		contentScrollElement?.addEventListener("scroll", handleContentScroll);

		return () => {
			topScrollElement?.removeEventListener("scroll", handleTopScroll);
			contentScrollElement?.removeEventListener("scroll", handleContentScroll);
		};
	}, []);

	useLayoutEffect(() => {
		if (contentScrollRef.current) {
			setContentScrollWidth(contentScrollRef.current.scrollWidth);
		}
	}, [children]);

	return (
		<>
			<DualScrollWrapper>
				{showTopScroll ? (
					<div className="dual-scroll-top" ref={topScrollRef}>
						<div
							className="scrollbar-spacer"
							style={{ width: contentScrollWidth + "px" }}
						></div>
					</div>
				) : null}
				<div className="dual-scroll-content" ref={contentScrollRef}>
					{children}
				</div>
			</DualScrollWrapper>
		</>
	);
};

const ReviewResultsTable: React.FC<ReviewResultsTableProps> = ({
	data,
	disableExport = false,
	showTopScroll,
}) => {
	const theme = useTheme();
	// State for storing prepared data for CSV
	const [csvData, setCsvData] = useState<any[]>([]);

	// Get the headers dynamically based on the keys of the data object, if Headers
	// from manageHeaders don't match
	const columnKeysAndHeaders = Object.keys(data[0] || {}).map(
		(key) => [key, EntryReviewerHeaders[key] || key] as [string, string]
	);

	useEffect(() => {
		// Prepare data for CSV here if needed.
		setCsvData(prepareDataForCSV(data));
	}, [data]);

	const prepareDataForCSV = (data: EntryReviewReport[]): any[] => {
		const formattedData = data.map((entry) => ({
			...entry,
		}));

		// Initialize totalsRow with the first column set to "Totals"
		const totalsRow: Partial<EntryReviewReport> = {
			[columnKeysAndHeaders[0][0]]: "Totals",
		};

		// Calculate the totals for the other columns
		columnKeysAndHeaders.slice(1).forEach(([key]) => {
			totalsRow[key as keyof EntryReviewReport] = columnTotals[key];
		});

		formattedData.push(totalsRow as EntryReviewReport);

		return formattedData;
	};

	const csvHeaders = Object.keys(EntryReviewerHeaders).map((key) => ({
		label: EntryReviewerHeaders[key],
		key: key,
	}));

	const columnTotals = useMemo(() => {
		return columnKeysAndHeaders.reduce((acc, [key]) => {
			// Sum the values of numeric columns
			const total = data.reduce((sum, result) => {
				const value = result[key as keyof EntryReviewReport];
				if (typeof value === "number") {
					sum += value;
				}
				return sum;
			}, 0);

			acc[key] = total;
			return acc;
		}, {} as { [key: string]: number | string });
	}, [data, columnKeysAndHeaders]);

	return (
		<>
			<DualScroll showTopScroll={showTopScroll}>
				<table className="text-left table-auto border-collapse w-full text-sm search-table">
					<thead className="bg-colorBackgroundLight">
						<tr className="border-b border-gray-300">
							{columnKeysAndHeaders.map(([key, header], index) => {
								const backgroundColor =
									index === 0
										? theme.colorBackgroundMedium
										: theme.colorBackgroundLight;
								const textColor =
									index === 0 ? theme.colorCopyLightLight : "initial";
								const additionalStyle =
									header === "DueDate" ? { width: "100px" } : {};

								// Find the first non-empty, non-null, and non-boolean value to determine alignment.
								let sampleContent: string | number | boolean | null | undefined;
								for (const row of data) {
									const cellContent = row[key as keyof EntryReviewReport];
									if (
										cellContent !== null &&
										cellContent !== undefined &&
										typeof cellContent !== "boolean" &&
										cellContent !== ""
									) {
										sampleContent = cellContent;
										break;
									}
								}

								// If no suitable sampleContent found, fallback to default (text-left)
								let isContentNumericOrDollar = false;
								if (
									sampleContent !== undefined &&
									sampleContent !== null &&
									typeof sampleContent !== "boolean"
								) {
									// Determine alignment based on sample content type (number/dollar amount).
									isContentNumericOrDollar =
										isNumericOrDollarAmount(sampleContent);
								}

								return (
									<th
										key={header}
										style={{
											...additionalStyle,
											backgroundColor,
											color: textColor,
											textAlign: isContentNumericOrDollar ? "right" : "left",
										}}
										className={`p-3 ${
											index === 0
												? "bg-colorBackgroundDarkDark text-colorCopyLightLight"
												: "bg-colorBackgroundLight"
										}`}
									>
										{header}
									</th>
								);
							})}
						</tr>
					</thead>

					<tbody className="bg-colorBackgroundLightLight divide-y divide-gray-200">
						{data.map((result, rowIndex) => (
							<tr className="border-b border-gray-300" key={rowIndex}>
								{columnKeysAndHeaders.map(([key, header], colIndex) => {
									// Extract cell content using header as key
									let cellContent = result[key as keyof EntryReviewReport];

									// Check if the content is numeric or a dollar amount
									const isContentNumericOrDollar =
										(typeof cellContent === "string" ||
											typeof cellContent === "number") &&
										isNumericOrDollarAmount(cellContent); // Assuming isNumericOrDollarAmount is a utility function that checks this

									// Determine text alignment class based on content type
									let textAlignClass = isContentNumericOrDollar
										? "text-right"
										: "text-left";

									// Determine font class based on content type
									const cellFontClass = isContentNumericOrDollar
										? "font-mono"
										: "";

									return (
										<td
											className={`p-3 ${textAlignClass} ${cellFontClass}`}
											key={colIndex}
										>
											{cellContent}
										</td>
									);
								})}
							</tr>
						))}
						<tr className="border-t border-gray-400">
							{columnKeysAndHeaders.map(([key, header], colIndex) => {
								const totalValue = columnTotals[key];

								let isContentNumericOrDollar =
									isNumericOrDollarAmount(totalValue);
								let textAlignClass = "text-left";
								if (colIndex !== 0 && isContentNumericOrDollar) {
									textAlignClass = "text-right";
								}
								const cellFontClass = isContentNumericOrDollar
									? "font-mono"
									: "";

								return (
									<td
										className={`p-3 font-bold ${textAlignClass} ${cellFontClass}`}
										key={colIndex}
									>
										{colIndex === 0 ? "Totals" : totalValue}
									</td>
								);
							})}
						</tr>
					</tbody>
				</table>
			</DualScroll>
			{!disableExport && (
				<div className="py-5 w-5">
					<CSVLink
						className="no-underline text-colorCopyLightLight"
						data={csvData}
						headers={csvHeaders}
						filename={"Entry Review Numbers" + "_" + getStandardDate() + ".csv"}
					>
						<Button>Export to CSV</Button>
					</CSVLink>
				</div>
			)}
		</>
	);
};

export default ReviewResultsTable;
