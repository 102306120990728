import { useTheme } from "styled-components";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { FormikProvider, useFormik } from "formik";
import Button from "../../../../components/Button/Button";
import {
	CardContainer,
	CardHeader,
	CardBody,
} from "../../../../components/DashboardCard/DashboardCard";
import TextField from "../../../../components/FormFields/TextField";
import Icon from "../../../../components/Icon/Icon";
import Modal, { ModalContainer } from "../../../../components/Modal/Modal";
import {
	CreateJuryModel,
	RootProgramWithJuryCards,
} from "../JudgingInterfaces";
import { createJury, getJuriesByProgramId, renameJury } from "../manageJudging";
import HierarchyNav, {
	HierarchyCard,
} from "../../../../components/HierarchyNav/HierarchyNav";
import { expandAnimation } from "../../../../components/HierarchyNav/HierarchyNav";
import { SubLevel } from "../../../../components/HierarchyNav/HierarchyNav";
import BrowserHeader from "../../../../components/BrowserHeader/BrowserHeader";

export default function JudgingConfigProgram() {
	const { programId } = useParams<{ programId: string }>();
	const [showFieldModal, setShowFieldModal] = useState(false);
	const [showRenameModal, setShowRenamedModal] = useState(false);

	type ExtendedJuryModel = CreateJuryModel & {
		currentJuryId: number | undefined;
	};

	const [modalForm, setModalForm] = useState<ExtendedJuryModel>({
		programId: Number(programId),
		name: "",
		currentJuryId: undefined,
	});

	const [rootProgramWithJuryCards, SetRootProgramWithJuryCards] =
		useState<RootProgramWithJuryCards>({
			id: 0,
			juries: [],
			name: "",
		});
	const theme = useTheme();

	useEffect(() => {
		// get root program with jury cards
		getJuriesByProgramId(Number(programId)).then((res) => {
			// console.log("root program", res);

			if (res.status == 200) {
				SetRootProgramWithJuryCards(res.data);
			}
		});
	}, []);

	const formikProps = useFormik({
		initialValues: modalForm,
		enableReinitialize: true,
		onSubmit: async (value, { resetForm }) => {
			if (showRenameModal) {
				renameJury(value.currentJuryId!, value.name).then((res) => {
					if (res.status == 200) {
						SetRootProgramWithJuryCards((prevState) => ({
							...prevState,
							juries: rootProgramWithJuryCards.juries.map((jury) =>
								jury.id === value.currentJuryId
									? { ...jury, name: value.name }
									: jury
							),
						}));
						resetForm();
						handleCloseFieldModal();
					}
				});
			} else {
				createJury(value).then((res) => {
					if (res.status == 201) {
						SetRootProgramWithJuryCards((prevState) => {
							const newJuries = [...prevState.juries, res.data];
							newJuries.sort((a, b) => (a.name > b.name ? 1 : -1));
							return {
								...prevState,
								juries: newJuries,
							};
						});
						resetForm();
						handleCloseFieldModal();
					}
				});
			}
		},
		validationSchema: Yup.object({
			name: Yup.string().required("Field is required"),
		}),
		validateOnBlur: false,
		validateOnChange: false,
	});

	const handleCloseFieldModal = () => {
		formikProps.values.name = " ";
		setShowFieldModal(false);
		setShowRenamedModal(false);
	};

	return (
		<CardContainer>
			<BrowserHeader
				title={`Judging Config: ${rootProgramWithJuryCards.name}`}
			/>
			<Modal show={showFieldModal || showRenameModal}>
				<ModalContainer className="!min-w-[30rem]">
					<FormikProvider value={formikProps}>
						<Icon
							className="m-[1.5rem] ml-auto cursor-pointer"
							icon="close"
							color={theme.colorCopyLight}
							onClick={handleCloseFieldModal}
						/>

						<div className="inner-container items-right !gap-[1.5rem]">
							<h2 className="text-[1.25rem]">
								{showRenameModal ? "Rename Jury" : "Add Jury"}
							</h2>
							<TextField
								className="flex-1"
								name="name"
								placeholder="Jury Name"
								value={formikProps.values.name}
								// component="textarea"
							/>
							<div className="flex gap-[1rem] mt-[1rem]">
								<Button
									type="submit"
									className="w-full"
									onClick={formikProps.handleSubmit}
									disabled={formikProps.isSubmitting}
									iconRight={true}
									icon={showRenameModal ? "edit" : "plus"}
								>
									{showRenameModal ? "Rename" : "Add"}
								</Button>
							</div>
						</div>
					</FormikProvider>
				</ModalContainer>
			</Modal>
			<CardHeader>
				<h2 className="card-title">
					Judging Config: {rootProgramWithJuryCards.name}
				</h2>
			</CardHeader>
			<CardBody className="flex flex-col">
				<HierarchyCard>
					<div className="flex w-full items-center">
						<Button to={`/judging/config`} className="button-light mr-auto">
							Back
						</Button>

						<h2 className="mr-auto">{rootProgramWithJuryCards.name}</h2>
					</div>
				</HierarchyCard>

				<HierarchyNav
					columns={[
						{ label: null, width: "200px" },
						{ label: null, width: "200px" },
						{ label: "Jury Name" },
						{ label: "Entries", width: "100px" },
						{ label: "Jurors", width: "100px" },
						{ label: "Rounds", width: "100px" },
						{ label: "Finalized", width: "100px" },
					]}
					isCenterLabel
					noSortTriangle
					dashBorder
				>
					{(gridColumns: string, gridArea: string) =>
						rootProgramWithJuryCards.juries.length > 0 &&
						rootProgramWithJuryCards.juries.map((jury, i) => (
							<SubLevel order={i} expandAnimation={expandAnimation}>
								<HierarchyCard
									key={i}
									gridColumns={gridColumns}
									gridArea={gridArea}
								>
									<span className="horizontal-branch" />

									<Button
										to={`/judging/config/${jury.programId}/${jury.id}`}
										className=" button-light w-[175px]"
										iconLeft="edit"
									>
										Configure Jury
									</Button>
									<Button
										className="w-[175px]"
										iconLeft="edit"
										onClick={() => {
											setModalForm({
												programId: Number(programId),
												name: "",
												currentJuryId: jury.id,
											});
											setShowRenamedModal(true);
										}}
									>
										Rename
									</Button>
									<p className="text-center truncate font-semibold">
										{jury.name}
									</p>
									<div className="flex justify-center">
										{jury.isEntrySetFinal ? (
											<Icon
												icon="check"
												className="pt-[0.5rem]"
												color={theme.colorSuccess}
											/>
										) : (
											<Icon icon="close" color={theme.colorDanger} />
										)}
									</div>
									<div className="flex justify-center">
										{jury.isJuryPoolFinal ? (
											<Icon
												icon="check"
												className="pt-[0.5rem]"
												color={theme.colorSuccess}
											/>
										) : (
											<Icon icon="close" color={theme.colorDanger} />
										)}
									</div>
									<div className="flex justify-center">
										{jury.isRoundAssignmentFinal ? (
											<Icon
												icon="check"
												className="pt-[0.5rem]"
												color={theme.colorSuccess}
											/>
										) : (
											<Icon icon="close" color={theme.colorDanger} />
										)}
									</div>
									<div className="flex justify-center">
										{jury.isRoundConfigurationFinal ? (
											<Icon
												icon="check"
												className="pt-[0.5rem]"
												color={theme.colorSuccess}
											/>
										) : (
											<Icon icon="close" color={theme.colorDanger} />
										)}
									</div>
								</HierarchyCard>
							</SubLevel>
						))
					}
				</HierarchyNav>
				<Button
					className="w-[150px] ml-auto mt-[2rem]"
					icon="plus"
					iconRight
					onClick={() => setShowFieldModal(true)}
				>
					Add Jury
				</Button>
			</CardBody>
		</CardContainer>
	);
}
