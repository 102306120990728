import {
	NavTab,
	NavTabList,
	NavTabPanel,
	NavTabs,
} from "../../components/NavTabs/NavTabs";
import UserMenu from "../Admin/User/UserMenu";
import DropdownField, {
	DropdownOptions,
} from "../../components/FormFields/DropdownField";
import React, {
	useState,
	useEffect,
	useCallback,
	createContext,
	useContext,
	Fragment,
	useMemo,
} from "react";
import parse from "html-react-parser";
import * as Yup from "yup";
import TextField from "../../components/FormFields/TextField";
import Button, { CustomNavLink } from "../../components/Button/Button";
import CheckboxField from "../../components/FormFields/CheckboxField";
import StyledForm from "../../components/Form/Form";
import { UserProfileBody } from "../../components/UserPageTemplate/UserPageTemplate";
import StickyCard, {
	StickyCardBody,
	StickyCardHeader,
} from "../../components/StickyCard/StickyCard";
import { NonFormikRadio } from "../../components/FormFields/RadioField";
import styled, { useTheme, css } from "styled-components";
import { ReactComponent as CaretDown } from "../../assets/icons/Down.svg";
import SearchBar from "../../components/SearchBar/SearchBar";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ExecutionDragCard from "../../components/ExecutionDragCard/ExecutionDragCard";
import update from "immutability-helper";
import { ListContainer } from "../../components/FormFields/RadioField";
import {
	IndividualCredit,
	CompanyCredit,
} from "../Admin/Credits/CreditInterfaces";
import { useFormik, FormikProvider } from "formik";
import NewCreditLibrarySticky from "../Admin/Credits/CreditLibrary/NewCreditLibrarySticky";
import {
	CreditType,
	DateType,
	EntryCompanyCreditField,
	EntryDateField,
	EntryIndividualCreditField,
	EntryLinkField,
	EntryListField,
	EntryPhysicalComponentField,
	EntryTextField,
	ProgramModel,
} from "../Admin/Program/ProgramInterfaces";
import { CoverImageMediaJoin, ExecutionModel } from "./EntryInterfacesComplete";
import { AdminEntryModel } from "./EditEntry";
import {
	MediaItem,
	MediaType,
	Tag,
} from "../MediaLibrary/mediaLibrary.model.d";
import {
	getMedia,
	getMediaSrc,
	ThumbnailSize,
} from "../MediaLibrary/manageMediaLibrary";
import {
	getActiveCompanyCredits,
	getActiveIndividualCredits,
} from "../Admin/Credits/manageCreditLibrary";
import DynamicFields, { AUTOSAVE_MESSAGE, EntryError } from "./DynamicFields";
import CreditFields from "./CreditFields";
import { AxiosResponse } from "axios";
import { LoadingContext } from "../../App";
import {
	changePaymentStatus,
	createExecution,
	updateEntry,
	changeExecutionProgram,
	UpdatePropertyRequest,
	updateEntryProperty,
	setIsCampaign,
	updateExecutionProperty,
	deleteExecution,
	sortEntryFields,
} from "./manageEntry";
import SelectExecutionModal from "./SelectExecutionModal";
import { ChangeWarning } from "./EntryWarnings";
import { fieldRequired } from "../../components/FieldModal/FieldModal";
import { Link, useHistory } from "react-router-dom";
import AuthenticationContext from "../../components/Auth/AuthenticationContext";
import EntryValidation, { EntryValidationReturnType } from "./EntryValidation";
import dateTostring from "../../utils/dateToString";
import { PaymentStatus } from "../Checkout/OrderInterfaces";
import { useAwardableAwards } from "../../hooks/useAwardableAwards";
import PrimaryLogo from "../../components/PrimaryLogo/PrimaryLogo";
import { EntryMediaJoin } from "../Admin/Program/ProgramInterfaces";
import AddCreditModal from "../Admin/Credits/AddCreditModal";
import { getAdminHierarchy } from "../Admin/Entries/AdminEntryEdit";
import ToggleSwitchField from "../../components/FormFields/ToggleSwitchField";
import EntryReviewGalleryCard from "./EntryReviewGalleryCard";
import useTaskHandler, {
	TaskHandlerReturnType,
	TASKS_COMPLETED,
} from "../../hooks/useTaskHandler";
import { SuccessMessageWrapper } from "../../components/FormFields/FieldWrapper";
import assetsConfig, { defaultBoxShadow } from "../../assetsConfig";
import BrowserHeader from "../../components/BrowserHeader/BrowserHeader";
import useTextWrap from "../../hooks/useTextWrap";
import NewMediaLibrarySticky from "../Admin/Media/NewMediaLibrarySticky";
import DraggableMediaList, {
	DragMediaItem,
} from "../../components/Draggable/DraggableMedia";
import { useAlert } from "../../components/Alert/Alerts";
import { useSettings } from "../../hooks/UseSettings";
import lowerCase from "../../utils/lowerCase";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

export const ValidateContext = createContext<any | null>(null);
export const CreditCheckContext = createContext<any | null>(null);
export const MediaCheckContext = createContext<any | null>(null);
export const ToggleMobileMediaLibContext = createContext<{
	mediaSelect: Promise<string> | null;
	setMediaSelect: Function;
}>({ mediaSelect: null, setMediaSelect: () => {} });
export const ToggleMobileCreditLibContext = createContext<{
	creditSelect: Promise<string> | null;
	setCreditSelect: Function;
}>({ creditSelect: null, setCreditSelect: () => {} });

export const EntryNavTabList = styled(NavTabList)`
	position: sticky;
	top: -181px;
	box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
	z-index: 50;
`;

const AdminEntryEditHeader = styled.div`
	display: flex;
	gap: 2rem;
`;

export const ENTRY_FORM_ROUTES: {
	[key: string]: {
		path: string;
		index: number;
	};
} = {
	"basic-info": {
		path: "basic-info",
		index: 0,
	},
	"additional-info": {
		path: "additional-info",
		index: 1,
	},
	credits: {
		path: "credits",
		index: 2,
	},
	media: {
		path: "media",
		index: 3,
	},
	executions: {
		path: "executions",
		index: 4,
	},
	review: {
		path: "review",
		index: 5,
	},
};

const EntryNavButtons = (props: {
	prevIndex: number;
	nextIndex: number;
	setTabIndex(index: number): void;
	isOrderComplete: boolean;
}) => {
	const theme = useTheme();

	return (
		<div className="flex flex-col gap-[1rem]">
			<div className="grid grid-cols-2 gap-[1rem]">
				<Button
					className="button-light"
					icon="caret"
					iconColor={theme.colorCopyDarkDark}
					iconRotation="90deg"
					onClick={() => props.setTabIndex(props.prevIndex)}
					type="button"
				>
					Previous
				</Button>
				<Button
					icon="caret"
					iconRight
					iconColor={theme.colorCopyLightLight}
					iconRotation="-90deg"
					onClick={() => props.setTabIndex(props.nextIndex)}
					type="button"
				>
					Next
				</Button>
			</div>
			<BackToEntriesLink isOrderComplete={props.isOrderComplete} />
		</div>
	);
};

export const EntryField = (props: EntryFieldProps) => {
	const val =
		props.value instanceof Date ? dateTostring(props.value) : props.value;

	const { wrapText } = useTextWrap();

	return (
		<>
			<b className="entry-label">{props.label}</b>
			{props.value ? (
				props.hardLink ? (
					<p className="entry-info">
						<Link to={props.hardLink} target="_blank" rel="noopener noreferrer">
							{props.linkText || val}
						</Link>
					</p>
				) : (
					wrapText(<p className="entry-info">{val}</p>)
				)
			) : props.isRequired ? (
				<p className="missing-info">
					<span>Missing Required Information*</span>
				</p>
			) : (
				<p className="missing-info"></p>
			)}
		</>
	);
};

export const EntryReviewContainer = styled.div`
	display: grid;
	grid-template-columns: max-content 1fr;
	row-gap: 1rem;

	&.col-3-entry-info {
		grid-template-columns: max-content max-content 1fr;
	}

	&.col-4-entry-info {
		grid-template-columns: max-content max-content max-content 1fr;
	}

	b.entry-label {
		display: flex;
		align-items: center;
	}

	p.entry-info,
	p.missing-info,
	b.entry-label {
		/* padding-bottom: 1.3125rem; */
		padding-bottom: 1rem;
		line-height: 23px;
		border-bottom: 1px solid ${({ theme }) => theme.colorBorder};
	}

	p.entry-info,
	p.missing-info {
		padding-left: 5.375rem;
	}

	p.missing-info {
		font-style: italic;
		color: ${({ theme }) => theme.colorDanger};
		font-size: 0.8rem;
	}
`;

export const StyledCaretDown = styled(CaretDown)<{ isCollapsed: boolean }>`
	${(p) =>
		p.isCollapsed &&
		`
	transform: rotate(180deg);
`}
`;

export const ExecutionForm = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: min-content;
	grid-row-gap: 3rem;
	padding: 3.125rem;
	background: ${({ theme }) => theme.colorBackgroundLightLight};
	box-shadow: ${defaultBoxShadow};
`;

const PreviewCampaignHeader = styled(StickyCardBody)`
	background: ${({ theme }) => theme.colorBackgroundLight};
	box-shadow: ${defaultBoxShadow};
	padding-bottom: 1.5625rem;
`;

// convert Array to JSON for faster indexing
const arrToJson = (arr: Array<any>, key: string) => {
	let obj: any = {};

	arr.forEach((element) => {
		obj[element[key]] = element;
	});

	return obj;
};

const entriesNav: (
	isAdmin: boolean,
	isOrderComplete: boolean,
	isDemo: boolean
) => EntriesNavigation = (
	isAdmin: boolean,
	isOrderComplete: boolean,
	isDemo: boolean
) => {
	return isAdmin
		? {
				label: `Go to ${lowerCase(assetsConfig.labels.entry.plural)} search`,
				path: "/library/entries",
		  }
		: isOrderComplete
		? {
				label: `Go to my ${isDemo ? "drafts" : "orders"}`,
				path: isDemo ? "/entries/entries-search" : "/entries/orders",
		  }
		: { label: "Go to my drafts", path: "/entries/draft-entries" };
};

const BackToEntriesLink = (props: { isOrderComplete: boolean }) => {
	const { settings } = useSelector((state: RootState) => state.settings);
	const history = useHistory();
	const { claims } = useContext(AuthenticationContext);
	const index = claims.findIndex(
		(claim) => claim.name === "role" && claim.value === "admin"
	);
	const isAdmin = index > -1;

	const nav = entriesNav(isAdmin, props.isOrderComplete, settings.isDemo);

	return (
		<CustomNavLink
			className="ml-auto"
			label={nav.label}
			onClick={() => history.push(nav.path)}
		/>
	);
};

export const createTag = (tag: string, companyId: number): Tag => ({
	companyId: companyId,
	tag: tag,
	id: 0,
});

const EntryForm = (props: EntryFormProps) => {
	const { settings } = useSelector((state: RootState) => state.settings);

	const theme = useTheme();
	let history = useHistory();
	const { companyId } = props;
	const { claims } = useContext(AuthenticationContext);
	const [tabIndex, setTabIndex] = useState(props.tabIndex || 0);
	const [tabErrors, setTabErrors] = useState({
		basicInfoErrors: false,
		campaignErrors: false,
		creditErrors: false,
		executionErrors: false,
		reviewTabErrors: false,
	});

	const { setLoadingMessage } = useContext(LoadingContext);

	const [creditTabIndex, setCreditTabIndex] = useState(0);

	const [entryToMove, setEntryToMove] = useState<number | null>(null);

	// states for execution types
	const [selectedExecutionProgramId, setSelectedExecutionProgramId] = useState<
		number | undefined
	>();
	const [showExecutionSelection, setShowExecutionSelection] = useState(false);
	const [executionTypeOptions, setExecutionTypeOptions] = useState<
		DropdownOptions[]
	>([]);
	// END states for execution types

	const isEntryClosed = props.program.entriesClosed || false; // disables all fields except for credit fields with a program that "isUnlocked"
	const [isAdmin, setIsAdmin] = useState(false);
	const [resetCreditCheck, setResetCreditCheck] = useState(false);
	const [resetMediaCheck, setResetMediaCheck] = useState(false);
	const [hierarchy, setHierarchy] = useState<string[]>([]);
	const awardOptions = useAwardableAwards();
	const tabNames = [
		"Basic Info",
		"Additional Info",
		"Credits",
		"Media",
		"Executions",
		"Review",
	];
	// initial entry values
	const initialValues: AdminEntryModel = props;
	// const { entryErrors, setEntryErrors } = useContext(EntryErrorContext);

	const formikProps = useFormik({
		initialValues: initialValues,
		onSubmit: () => {},
		validationSchema: Yup.object({}),
		validateOnBlur: false,
		validateOnChange: false,
	});

	// media from DB
	const [media, setMedia] = useState<MediaItem[]>([]);

	const [companyCreditsArray, setCompanyCreditsArray] = useState<
		CompanyCredit[]
	>([]);

	const [individualCreditsArray, setIndividualCreditsArray] = useState<
		IndividualCredit[]
	>([]);

	// modal states for add credit/media
	const [showModal, setShowModal] = useState<{
		[modal: string]: string | boolean | number;
	}>({
		addCredit: false,
		addMedia: false,
		addExecution: false,
		markAsChild: false,
		changeExecutionProgramId: 0,
		currentExecutionId: 0,
	});
	const [mediaSelect, setMediaSelect] = useState(null);
	const [creditSelect, setCreditSelect] = useState(null);

	// execution forms
	const [executionForms, setExecutionForms] = useState<ExecutionModel[]>(
		initialValues.executions
	);
	const [draggedCard, setDraggedCard] = useState(false);
	const [reviewStatus, setReviewStatus] = useState(props.reviewStatusId);

	// update execution index prop with its actual order
	const updateExecutionOrderWithIndex = (executionForms: ExecutionModel[]) => {
		return executionForms.map((execution, i) => {
			return {
				...execution,
				index: i,
			};
		});
	};

	useEffect(() => {
		setHierarchy(
			getAdminHierarchy(
				props.executions!,
				props.program.ancestry!,
				props.isCampaign!
			)
		);

		// validateEntry(false);
	}, []);

	// update execution form field values
	useEffect(() => {
		setExecutionForms(formikProps.values.executions);
	}, [formikProps.values.executions]);

	// updates execution order when card is dragged
	useEffect(() => {
		// console.log("dragged card", draggedCard);
		if (draggedCard) {
			// update index value of each execution
			const updatedOrder = updateExecutionOrderWithIndex(executionForms);
			draggedCard && formikProps.setFieldValue("executions", updatedOrder);
			addNewAlert({
				type: "success",
				message: "Successfully updated execution order.",
			});
			setDraggedCard(false);
		}
	}, [draggedCard]);

	const moveExecutionForm = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			setExecutionForms((prevCards: ExecutionModel[]) => {
				return update(prevCards, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, prevCards[dragIndex] as ExecutionModel],
					],
				});
			});
		},
		[]
	);

	const [deleteExecutionIndex, setDeleExecutionIndex] = useState<
		number | null
	>();

	const handleDeleteExecution = (index: number | null) => {
		if (index !== null) {
			deleteExecution(executionForms[index].id, companyId)
				.then((resp) => {
					const executionsAfterDelete = executionForms.filter(
						(obj, currentCardIndex) => {
							// console.log("delete", obj, currentCardIndex === index);
							return currentCardIndex !== index;
						}
					);
					const updatedOrder = updateExecutionOrderWithIndex(
						executionsAfterDelete
					);
					formikProps.setFieldValue("executions", updatedOrder);
					setDeleExecutionIndex(null);
				})
				.catch(() => {});
		}
	};

	const renderCard = (
		card: ExecutionModel,
		index: number,
		disabled?: boolean
	) => {
		return (
			<ExecutionDragCard
				key={card.id}
				index={index}
				id={card.id}
				title={card.title}
				executionFields={card.executionFields}
				moveExecutionForm={moveExecutionForm}
				onClickDelete={() => {
					setShowDeleteExecution(true);
					setDeleExecutionIndex(index);
				}}
				disabled={disabled}
			/>
		);
	};

	// set info icon for tabs with errors
	const updateErrorTabs = (validationErrors: EntryValidationReturnType) => {
		setTabErrors({
			basicInfoErrors:
				Object.values(validationErrors.basicInfoErrors).length > 0,
			campaignErrors: Object.values(validationErrors.campaignErrors).length > 0,
			creditErrors: Object.values(validationErrors.creditErrors).length > 0,
			executionErrors:
				Object.values(validationErrors.executionErrors).length > 0 ||
				Object.values(validationErrors.entryMediaErrors).length > 0,
			reviewTabErrors:
				Object.values(validationErrors.reviewTabErrors).length > 0,
		});
	};

	const validateEntry = (save: boolean = true) => {
		const validationErrors = {
			...EntryValidation(formikProps.values, settings.isDemo),
		};

		updateErrorTabs(validationErrors);

		// if form is validated and there are no errors
		if (!save) {
			return;
		}

		if (
			!Object.values(validationErrors).some(
				(tabErr) => Object.values(tabErr).length > 0
			)
		) {
			// show valid entry popup if form isjust changed to valid
			if (!formikProps.values.isValid) {
				const updatePropertyRequest: UpdatePropertyRequest = {
					id: formikProps.values.id,
					propertyName: "isValid",
					propertyValue: true,
				};
				updateEntryProperty(updatePropertyRequest);
			}
			formikProps.setFieldValue("isValid", true);
			//   console.log("BEFORE SAVE formikProps.values", formikProps.values);
			// saveEntry({ ...formikProps.values, isValid: true });
			//console.log("Formik Values: ", formikProps.values);
		} else {
			// changing from valid to not valid
			if (formikProps.values.isValid) {
				const updatePropertyRequest: UpdatePropertyRequest = {
					id: formikProps.values.id,
					propertyName: "isValid",
					propertyValue: false,
				};
				updateEntryProperty(updatePropertyRequest);
			}
			// save entry and set isValid to false
			formikProps.setFieldValue("isValid", false);

			// saveEntry({ ...formikProps.values, isValid: false });
			//console.log("Formik Values: ", formikProps.values);
		}
	};

	const addExecution = (programId: number, clean?: boolean) => {
		return createExecution(formikProps.values.id, programId).then(
			(response) => {
				if (response.status === 200) {
					if (clean) {
						formikProps.setFieldValue("executions", [response.data]);
					} else {
						formikProps.setFieldValue("executions", [
							...formikProps.values.executions,
							{
								...response.data,
								index: formikProps.values.executions
									? formikProps.values.executions.length
									: 0,
							},
						]);
					}
				}
			}
		);
	};

	const changeAllExecutions = (programId: number) => {
		return changeExecutionProgram(
			formikProps.values.id,
			formikProps.values.companyId,
			programId,
			formikProps.values.executions
		)
			.then((response) => {
				if (response.status === 200) {
					formikProps.setFieldValue("executions", [...response.data]);
					const validationErrors = {
						...EntryValidation(
							{
								...formikProps.values,
								executions: [...response.data],
							},
							settings.isDemo
						),
					};

					updateErrorTabs(validationErrors);
				}
			})
			.catch((error) => {});
	};

	const changeExecution = (programId: number, executionId: number) => {
		const ex = formikProps.values.executions.find((x) => x.id === executionId);
		if (ex) {
			setLoadingMessage("Migrating Execution data to new selection...");
			changeExecutionProgram(
				formikProps.values.id,
				formikProps.values.companyId,
				programId,
				[ex]
			)
				.then((response) => {
					if (response.status === 200) {
						// Find the index of the execution to be replaced
						const index = formikProps.values.executions.findIndex(
							(x) => x.id === executionId
						);

						// If found, replace the execution
						if (index !== -1) {
							const updatedExecutions = [...formikProps.values.executions];
							updatedExecutions[index] = response.data[0];
							formikProps.setFieldValue("executions", updatedExecutions);
							const validationErrors = {
								...EntryValidation(
									{
										...formikProps.values,
										executions: updatedExecutions,
									},
									settings.isDemo
								),
							};
							// set info icon for tabs with errors
							setTabErrors({
								basicInfoErrors:
									Object.values(validationErrors.basicInfoErrors).length > 0,
								campaignErrors:
									Object.values(validationErrors.campaignErrors).length > 0,
								creditErrors:
									Object.values(validationErrors.creditErrors).length > 0,
								executionErrors:
									Object.values(validationErrors.executionErrors).length > 0 ||
									Object.values(validationErrors.entryMediaErrors).length > 0,
								reviewTabErrors:
									Object.values(validationErrors.reviewTabErrors).length > 0,
							});
						}
					}
					setLoadingMessage(null);
				})
				.catch((error) => {
					setLoadingMessage(null);
				});
		}
	};

	const getCompanyMedia = () => {
		getMedia(companyId)
			.then((response) => {
				// console.log("res media", response);
				if (response.status === 200) {
					setMedia(response.data);
				}
			})
			.catch((error) => {});
	};

	const updatecoverImage = (media?: any, libraryChanged: boolean = false) => {
		const coverimage: CoverImageMediaJoin = {
			...formikProps.values.coverImage,
			mediaId: media?.id,
			mediaItem: undefined,
		};

		const updatePropertyRequest: UpdatePropertyRequest = {
			id: formikProps.values.id,
			propertyName: "coverImage",
			propertyValue: coverimage,
		};

		return updateEntryProperty(updatePropertyRequest)
			.then((resp) => {
				if (resp.status === 200) {
					formikProps.setFieldValue("coverImage", {
						...coverimage,
						mediaItem: media
							? { ...media, tags: media.tags ? media.tags : [] }
							: undefined,
					});
					if (libraryChanged) {
						getCompanyMedia();
					}
				} else {
					addNewAlert({
						type: "error",
						message: "Failed to save.",
					});
				}
			})
			.catch((error: any) => {
				addNewAlert({
					type: "error",
					message: "Failed to save.",
				});
			});
	};

	const {
		reset,
		loadItems,
		onSubmit,
		handleSelectItem,
		...state
	}: TaskHandlerReturnType<UpdatePropertyRequest> =
		useTaskHandler<UpdatePropertyRequest>();

	useEffect(() => {
		setExecutionForms(formikProps.values.executions);
		loadItems(
			formikProps.values.executions.map((execution) => {
				return {
					id: execution.id,
					propertyName: "index",
					propertyValue: execution.index,
				};
			})
		);
	}, [formikProps.values.executions]);

	useEffect(() => {
		if (state.status === TASKS_COMPLETED) {
			if (state.errored.length) {
				addNewAlert({
					type: "error",
					message: "Failed to update execution order.",
				});
			} else {
				addNewAlert({
					type: "success",
					message: "Successfully updated execution order.",
				});
				const updatedOrder = updateExecutionOrderWithIndex(executionForms);
				formikProps.setFieldValue("executions", updatedOrder);
			}
			reset();
		}
	}, [state.status, state.errored]);

	// show/hide credit modal
	useEffect(() => {
		if (showModal.addCredit) {
			document.body.style.overflow = "hidden";
		}
		return () => {
			document.body.style.overflow = "unset";
		};
	}, [showModal.addCredit]);

	const shouldShowExecutionSelection =
		(!props.program.isCampaignEligible &&
			props.program.children &&
			props.program.children?.length > 1) ||
		(props.program.isCampaignEligible &&
			!props.program.isMixedCampaignEligible &&
			props.program.children &&
			props.program.children?.length > 1);

	useEffect(() => {
		// If the Entry’s Program is not Campaign Eligible
		//  AND the Entry’s Program has more than one child Program object
		// OR is Campaign Eligible AND NOT Mixed Campaign Eligible
		//  AND the Entry’s Program has more than one child Program object
		// show execution level selection on basic info tab
		if (
			shouldShowExecutionSelection ||
			(props.program.isCampaignEligible &&
				props.program.isMixedCampaignEligible &&
				!formikProps.values.isCampaign)
		) {
			setShowExecutionSelection(true);

			// if there is already an execution,
			// set selected execution to that execution's program Id
			if (props.executions && props.executions.length > 0) {
				setSelectedExecutionProgramId(props.executions[0].programId);
			}
		} else {
			setShowExecutionSelection(false);
		}
		setExecutionTypeOptions([]);
		// set execution options;
		props.program.children &&
			props.program.children?.length > 1 &&
			props.program.children.forEach((program: ProgramModel) => {
				setExecutionTypeOptions((prev) => [
					...prev,
					{
						label: program.name,
						value: program.id,
					},
				]);
			});

		// if there is only one child on the program, select that execution
		if (props.program.children && props.program.children?.length === 1) {
			setSelectedExecutionProgramId(props.program.children[0].id);
		}

		if (
			!formikProps.values.isCampaign &&
			props.executions &&
			props.executions.length === 1
		) {
			setSelectedExecutionProgramId(props.executions[0].programId);
		}
	}, [formikProps.values.isCampaign]);

	const [entryType, setEntryType] = useState<string | undefined>(undefined);

	// warning modals
	const [showDeleteExecution, setShowDeleteExecution] = useState(false);
	const [showEntryType, setShowEntryType] = useState(false);
	const [showExecutionTypeWarning, setShowExecutionTypeWarning] =
		useState(false);
	const [showDeleteEntryWarning, setShowDeleteEntryWarning] = useState(false);
	const [tempExecutionType, setTempExecutionType] = useState<
		number | undefined
	>(undefined);
	const { addNewAlert } = useAlert();
	const [validateSelectExecution, setValidateSelectExecution] = useState(true);

	// individual field errors
	const titleErr =
		validateSelectExecution && !formikProps.values.title
			? fieldRequired
			: undefined;
	const brandErr =
		validateSelectExecution && !formikProps.values.brand
			? fieldRequired
			: undefined;
	const physItemDescriptionErr =
		formikProps.values.hasPhysicalComponent &&
		!formikProps.values.physicalItemDescription
			? fieldRequired
			: undefined;
	const selectedExecutionErr =
		validateSelectExecution && !selectedExecutionProgramId
			? fieldRequired
			: undefined;
	const termsAndCondErr = !formikProps.values.termsAndConditions
		? fieldRequired
		: undefined;

	// show remaining tabs once first page is filled out
	const showTabs =
		formikProps.values.title &&
		formikProps.values.brand &&
		(formikProps.values.isCampaign && props.program.isMixedCampaignEligible
			? true
			: formikProps.values.executions.length > 0
			? true
			: false);

	const isOrderComplete =
		formikProps.values.paymentStatusId === 2 ||
		formikProps.values.paymentStatusId === 3;

	const saveEntry = (formValues: AdminEntryModel) => {
		// save entry
		updateEntry(formValues.id, formValues)
			.then((response) => {
				if (response.status === 200) {
					formikProps.setValues(response.data);
					// console.log("saved entry as draft", formValues);
					addNewAlert({ type: "success", message: "Entry saved as draft" });
				} else {
					addNewAlert({
						type: "error",
						message: "Failed to save entry as draft. Please try again later.",
					});
				}
			})
			.catch(() => {
				addNewAlert({
					type: "error",
					message: "Failed to save entry as draft. Please try again later.",
				});
			});
	};

	const entryTags = useMemo(() => {
		const tags: Tag[] = [];
		if (formikProps.values.title && formikProps.values.companyId) {
			tags.push(
				createTag(formikProps.values.title, formikProps.values.companyId)
			);
		}

		if (formikProps.values.brand && formikProps.values.companyId) {
			tags.push(
				createTag(formikProps.values.brand, formikProps.values.companyId)
			);
		}
		return tags;
	}, [
		formikProps.values.companyId,
		formikProps.values.title,
		formikProps.values.brand,
	]);

	// on check for errors, save entry as draft. Set isValid = true if no errors
	// useEffect(() => {
	//   console.log("entry errors", entryErrors);
	//   // if form is validated and there are no errors
	//   if (firstValidation) {
	//     if (Object.values(entryErrors).length === 0) {
	//       console.log("form is valid and ready for cart");
	//       formikProps.setFieldValue("isValid", true);
	//       saveEntry({ ...formikProps.values, isValid: true });
	//     } else {
	//       // just save entry w/o setting isValid to TRUE
	//       saveEntry(formikProps.values);
	//     }
	//   }
	// }, [entryErrors, firstValidation]);

	const { wrapText } = useTextWrap();

	// Entry Review Tab: Campaign Fields
	const ReviewCampaignFields = () => {
		const sortedFields = sortEntryFields(formikProps.values.campaignFields);

		return (
			<EntryReviewContainer className="col-w-100">
				{sortedFields.map((field: any) => {
					let fieldVal = "";
					// hide entry level media in additional ifno tab
					const isEntryMediaField = field.classType === "EntryMediaField";

					// set review tab values depending on field type
					switch (field.classType) {
						case "EntryTextField":
							fieldVal = (field as EntryTextField).text;
							break;
						case "EntryPhysicalComponentField":
							fieldVal = (field as EntryPhysicalComponentField)
								.physicalItemDescription;
							break;
						case "EntryDateField":
							const EntryDateField = field as EntryDateField;
							const startDate = EntryDateField.startDate
								? dateTostring(EntryDateField.startDate)
								: "";
							const endDate = EntryDateField.endDate
								? dateTostring(EntryDateField.endDate)
								: "";
							if (EntryDateField.programField.dateType === DateType.Range) {
								if (startDate && endDate)
									fieldVal =
										"Start Date: " + startDate + ", End Date: " + endDate;
							} else {
								fieldVal = startDate;
							}
							break;
						case "EntryListField":
							const EntryListField = field as EntryListField;
							if (
								EntryListField.programField.title === "Entry Type" &&
								EntryListField.selectedOption &&
								Array.isArray(EntryListField.selectedOption)
							) {
								setEntryType(EntryListField.selectedOption[0]);
							}

							if (EntryListField.selectedOption) {
								fieldVal = EntryListField.selectedOption.join(", ");
							}

							break;
						case "EntryLinkField":
							const EntryLinkField = field as EntryLinkField;
							if (EntryLinkField.links && EntryLinkField.links.length > 0) {
								fieldVal = EntryLinkField.links
									.map((link) => link.link)
									.join(", ");
							}

							break;
						// case "EntryMediaField":
						// 	if (field.mediaItems && field.mediaItems.length > 0) {
						// 		const mediaFilenames = field.mediaItems.map(
						// 			(mediaItem: EntryMediaJoin) => mediaItem.mediaItem.fileName
						// 		);
						// 		fieldVal = mediaFilenames.join(", ");
						// 	}
						// 	break;
					}

					return isEntryMediaField ? (
						<></>
					) : (
						<EntryField
							label={field.programField.title}
							value={fieldVal}
							isRequired={
								field.programField.isRequired ||
								field.programField.minQuantity > 0
							}
						/>
					);
				})}
			</EntryReviewContainer>
		);
	};

	const handleSelectExecutionProgram = (
		executionProgramId: number
	): Promise<void | string> => {
		// const id = parseInt(executionProgramId);
		const id = executionProgramId;

		// TODO: Handle execution changes
		setSelectedExecutionProgramId(id || undefined);
		//if no executions, add one
		if (
			!formikProps.values.executions ||
			formikProps.values.executions.length < 1
		) {
			return addExecution(id);
		}
		// if there are executions, remove executions
		else if (
			formikProps.values.executions &&
			formikProps.values.executions.length > 0
		) {
			if (formikProps.values.executions[0].programId !== id) {
				// console.log(
				//   `Changing Execution from: ${
				//     formikProps.values.executions[0].programId
				//   } to: ${
				//     id ||
				//     undefined
				//   }`
				// );

				return changeAllExecutions(id);
			}
		}

		return Promise.reject("Failed to select execution program");
	};

	const showHelpBubble = () => {
		const style = document.createElement("style");
		style.textContent = `
    #freshworks-container {
    display: block;
  }
      `;
		document.head.append(style);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [tabIndex]);

	// trigger page reload warning if entry is not saved as draft
	// useEffect(() => {
	// 	if (!isSaved) {
	// 		const unloadCallback = (event: any) => {
	// 			event.preventDefault();
	// 			event.returnValue = "";
	// 			return "";
	// 		};

	// 		window.addEventListener("beforeunload", unloadCallback);
	// 		return () => window.removeEventListener("beforeunload", unloadCallback);
	// 	}
	// }, [isSaved]);

	// run on page render
	useEffect(() => {
		getCompanyMedia();
		getActiveCompanyCredits(companyId)
			.then((response: AxiosResponse<CompanyCredit[], any>) => {
				if (response.status === 200) {
					setCompanyCreditsArray(response.data);
				}
			})
			.catch((error) => {});

		getActiveIndividualCredits(companyId)
			.then((response: AxiosResponse<IndividualCredit[], any>) => {
				if (response.status === 200) {
					setIndividualCreditsArray(response.data);
				}
			})
			.catch((error) => {});

		const index = claims.findIndex(
			(claim) => claim.name === "role" && claim.value === "admin"
		);
		if (index > -1) {
			setIsAdmin(true);
		}

		showHelpBubble();
	}, []);

	const initialCreditCount = {
		companyCredits: 0,
		individualCredits: 0,
	};

	// count of company/ind credits added
	const creditCount =
		formikProps.values.creditFields.length > 0
			? formikProps.values.creditFields.reduce((creditCount, creditField) => {
					if (creditField.classType === "EntryCompanyCreditField") {
						return {
							...creditCount,
							companyCredits:
								creditCount.companyCredits + creditField.credits.length,
						};
					} else if (creditField.classType === "EntryIndividualCreditField") {
						return {
							...creditCount,
							individualCredits:
								creditCount.individualCredits + creditField.credits.length,
						};
					} else return creditCount;
			  }, initialCreditCount)
			: initialCreditCount;

	// credits validation
	const isMissingReqCompanyCredits = formikProps.values.creditFields.some(
		(creditField) => {
			const displayNotApplicable =
				creditField.programField.displayNotApplicable ||
				creditField.programField.displayNotApplicable == null;

			if (
				creditField.classType === "EntryCompanyCreditField" &&
				creditField.programField.minCredits > 0 &&
				creditField.credits.length < creditField.programField.minCredits &&
				(!creditField.notApplicableAttestation || !displayNotApplicable)
			) {
				return true;
			}
		}
	);
	const isMissingReqIndCredits = formikProps.values.creditFields.some(
		(creditField) => {
			const displayNotApplicable =
				creditField.programField.displayNotApplicable ||
				creditField.programField.displayNotApplicable == null;

			if (
				creditField.classType === "EntryIndividualCreditField" &&
				creditField.programField.minCredits > 0 &&
				creditField.credits.length < creditField.programField.minCredits &&
				(!creditField.notApplicableAttestation || !displayNotApplicable)
			) {
				return true;
			}
		}
	);

	useEffect(() => {
		history.push(
			`/edit-entry/${
				Object.values(ENTRY_FORM_ROUTES).find(
					(route) => route.index === tabIndex
				)?.path
			}/${history.location.search}`,
			{
				from: history.location.pathname,
			}
		);
	}, [tabIndex]);

	useEffect(() => {
		if (props.tabIndex !== undefined) setTabIndex(props.tabIndex);
	}, [props.tabIndex]);

	// pass isSaved prop up
	// useEffect(() => {
	// 	props.setIsEntrySaved && props.setIsEntrySaved(isSaved);
	// }, [isSaved]);

	// validate entire entry on every field/property change
	useEffect(() => {
		validateEntry();
	}, [formikProps.values, settings.isDemo]);

	const entryLevelMediaFields = formikProps.values.campaignFields.some(
		(field) => field.classType === "EntryMediaField"
	) ? (
		<div className="col-w-100">
			<DynamicFields
				name="campaignFields"
				fieldArray={formikProps.values.campaignFields}
				refreshMedia={getCompanyMedia}
				readOnly={isEntryClosed}
				tags={entryTags}
				hideNonMediaFields
			/>
		</div>
	) : (
		<></>
	);

	return (
		<ValidateContext.Provider value={true}>
			<CreditCheckContext.Provider
				value={{ resetCreditCheck, setResetCreditCheck }}
			>
				<MediaCheckContext.Provider
					value={{ resetMediaCheck, setResetMediaCheck }}
				>
					<ToggleMobileMediaLibContext.Provider
						value={{ mediaSelect, setMediaSelect }}
					>
						<ToggleMobileCreditLibContext.Provider
							value={{ creditSelect, setCreditSelect }}
						>
							<div
								className="w-full"
								// onClick={() => setShowModal({ ...showModal, addCredit: false })}
							>
								<SelectExecutionModal
									show={showModal.addExecution !== false}
									childLevelDisplayLabel={
										formikProps.values.program.childLevelDisplayLabel
									}
									onClickHide={() =>
										setShowModal({ ...showModal, addExecution: false })
									}
									options={executionTypeOptions}
									addExecution={addExecution}
								/>

								{/* NEMO-1083 */}
								{/* <ChildEntryModal
                show={showModal.markAsChild !== false}
                onClickHide={() =>
                  setShowModal({ ...showModal, markAsChild: false })
                }
                markAsChildMethod={markAsChild}
              /> */}

								<ChangeWarning
									show={showDeleteExecution}
									title="Delete Execution"
									message="Do you want to delete this execution? This cannot be undone."
									yesMessage="Yes, Delete"
									onHideModal={() => setShowDeleteExecution(false)}
									onConfirm={() => handleDeleteExecution(deleteExecutionIndex!)}
								/>
								<ChangeWarning
									show={showEntryType}
									title="Change Campaign Status"
									yesMessage="Yes, Change"
									message={`Your entry will become a ${
										formikProps.values.isCampaign ? "Single Entry" : "Campaign"
									}. Anything you have already added to the ${
										formikProps.values.isCampaign ? "Executions" : "Media"
									} tab may be discarded with this change. Your media will still be in your media library for you to add when you revisit that tab.`}
									onConfirm={() => {
										// if changing to single and more than one execution
										// , remove all but first execution
										if (
											formikProps.values.isCampaign &&
											formikProps.values.executions &&
											formikProps.values.executions.length > 1
										) {
											formikProps.setFieldValue("executions", [
												formikProps.values.executions[0],
											]);
										}

										formikProps.setFieldValue(
											"isCampaign",
											!formikProps.values.isCampaign
										);

										setIsCampaign(
											formikProps.values.id,
											!formikProps.values.isCampaign
										);

										const newValue = !formikProps.values.isCampaign;
										formikProps.setFieldValue("isCampaign", newValue);

										const updatePropertyRequest: UpdatePropertyRequest = {
											id: formikProps.values.id,
											propertyName: "isCampaign",
											propertyValue: newValue ? true : false,
										};

										updateEntryProperty(updatePropertyRequest);
									}}
									onHideModal={() => setShowEntryType(false)}
								/>

								<ChangeWarning
									show={showDeleteEntryWarning}
									title="Deleting Entry"
									yesMessage="Yes, Delete"
									message="Do you really want to delete this entry?"
									onConfirm={() => {
										changePaymentStatus(props.id, 4);
									}}
									onHideModal={() => setShowDeleteEntryWarning(false)}
								/>

								<UserMenu />
								<BrowserHeader
									title={`Entry ${props.id}: ${tabNames[tabIndex]}`}
								/>
								<FormikProvider value={formikProps}>
									<NavTabs
										selectedIndex={tabIndex}
										onSelect={(index) => setTabIndex(index)}
									>
										<EntryNavTabList className="sticky top-[-181px] z-50 !h-auto">
											<div className="flex items-center mb-[66px]">
												<PrimaryLogo
													className="mr-[74px]"
													color={theme.colorLogoLightLight}
													width="254px"
												/>
												<Breadcrumbs
													navLinks={
														formikProps.values.program.ancestry
															? formikProps.values.program.ancestry?.map(
																	(hierarchyLevel) => {
																		return {
																			label: hierarchyLevel.name,
																		};
																	}
															  )
															: []
													}
												/>
											</div>
											<div className="flex">
												<NavTab isError={tabErrors.basicInfoErrors} flex>
													Basic Info
												</NavTab>

												{/*TODO: Make sure the Additional Info Tab only appears if the Program has additional info fields. */}
												<NavTab
													hide={!showTabs}
													isError={tabErrors.campaignErrors}
													flex
												>
													Additional Info
												</NavTab>
												<NavTab
													hide={!showTabs}
													isError={tabErrors.creditErrors}
													flex
												>
													Credits
												</NavTab>
												<NavTab
													// hidden in campaign entries
													hide={!showTabs || formikProps.values.isCampaign}
													isError={tabErrors.executionErrors}
													flex
												>
													Media
												</NavTab>
												<NavTab
													// hidden in single entry
													hide={!showTabs || !formikProps.values.isCampaign}
													isError={tabErrors.executionErrors}
													flex
												>
													Executions
												</NavTab>
												<NavTab
													hide={!showTabs}
													isError={tabErrors.reviewTabErrors}
													flex
												>
													Review
												</NavTab>
											</div>
										</EntryNavTabList>
										<NavTabPanel
										// Basic Info Tab
										>
											<UserProfileBody className="has-preview-card">
												<StyledForm className="oneColumn extraSpacing">
													<div
														className="col-w-100"
														id={`basic-info-wrapper-entry-title`}
													>
														<h3 className="subtitle font-semibold">
															{props.program.titleNameOverride ||
																assetsConfig.labels.entry.singular + " Title"}
															*
														</h3>
														{parse(
															props.program.titleDescriptionOverride! ||
																assetsConfig.copy.entryFormTitleDescription
														)}
														<SuccessMessageWrapper>
															{(success, setSuccess) => (
																<TextField
																	name="title"
																	placeholder={
																		props.program.titleNameOverride || "Title"
																	}
																	value={formikProps.values.title}
																	success={success}
																	error={titleErr}
																	readOnly={isEntryClosed}
																	onBlur={() => {
																		const updatePropertyRequest: UpdatePropertyRequest =
																			{
																				id: formikProps.values.id,
																				propertyName: "title",
																				propertyValue: formikProps.values.title,
																			};
																		return updateEntryProperty(
																			updatePropertyRequest
																		).then(() => setSuccess(AUTOSAVE_MESSAGE));
																	}}
																/>
															)}
														</SuccessMessageWrapper>
													</div>

													<div
														className="col-w-100"
														id={`basic-info-wrapper-entry-brand`}
													>
														<h3 className="subtitle font-semibold">
															{props.program.brandNameOverride ||
																assetsConfig.labels.entry.singular + " Brand"}
															*
														</h3>
														{parse(
															props.program.brandDescriptionOverride! ||
																assetsConfig.copy.entryFormBrandDescription
														)}

														<SuccessMessageWrapper>
															{(success, setSuccess) => (
																<TextField
																	name="brand"
																	placeholder={
																		props.program.brandNameOverride || "Brand"
																	}
																	value={formikProps.values.brand}
																	success={success}
																	error={brandErr}
																	readOnly={isEntryClosed}
																	onBlur={() => {
																		const updatePropertyRequest: UpdatePropertyRequest =
																			{
																				id: formikProps.values.id,
																				propertyName: "brand",
																				propertyValue: formikProps.values.brand,
																			};
																		return updateEntryProperty(
																			updatePropertyRequest
																		).then(() => setSuccess(AUTOSAVE_MESSAGE));
																	}}
																/>
															)}
														</SuccessMessageWrapper>
													</div>

													<div
														className="col-w-100"
														id={`basic-info-wrapper-cover-image`}
													>
														<h3 className="subtitle font-semibold">
															Cover Image*
														</h3>
														<p>
															{assetsConfig.copy.entryFormCoverImageDescription}
														</p>
														<span className="italic">
															Minimum: 1{" "}
															<span className="relative top-[-2px]">||</span>{" "}
															Maximum: 1
														</span>
														<SuccessMessageWrapper>
															{(success, setSuccess) => (
																<DraggableMediaList
																	name="coverImage"
																	list={
																		(formikProps.values.coverImage?.mediaItem
																			?.path &&
																			([
																				formikProps.values.coverImage.mediaItem,
																			] as DragMediaItem[])) ||
																		[]
																	}
																	sortable={false}
																	mediaType={MediaType.Image}
																	min={1}
																	max={1}
																	disabled={isEntryClosed}
																	success={success}
																	companyId={props.companyId}
																	onAdd={(addedMedia) =>
																		updatecoverImage(addedMedia[0]).then(() =>
																			setSuccess(AUTOSAVE_MESSAGE)
																		)
																	}
																	onRemove={(mediaId) =>
																		updatecoverImage().then(() =>
																			setSuccess(AUTOSAVE_MESSAGE)
																		)
																	}
																	onUploadSuccess={(uploaded) => {
																		if (uploaded.length === 1) {
																			updatecoverImage(uploaded[0], true).then(
																				() => setSuccess(AUTOSAVE_MESSAGE)
																			);
																		}
																	}}
																	showLocalFileUpload
																/>
															)}
														</SuccessMessageWrapper>
													</div>
													{formikProps.values.program
														.physicalComponentAllowed && (
														<div
															className="col-w-100"
															id={`basic-info-wrapper-physical-asset`}
														>
															<h3 className="subtitle font-semibold">
																Physical Asset
															</h3>
															{parse(
																formikProps.values.program
																	.physicalComponentEntrantHelpText || ""
															)}

															<div className="flex flex-col gap-[1rem]">
																<p>
																	Will you include a physical component with
																	this entry?
																</p>
																<SuccessMessageWrapper>
																	{(success, setSuccess) => (
																		<ToggleSwitchField
																			id="hasPhysicalComponent"
																			name="hasPhysicalComponent"
																			checked={
																				formikProps.values.hasPhysicalComponent
																			}
																			updateEntryProperty={(request) => {
																				const response =
																					updateEntryProperty(request);

																				response
																					.then(() =>
																						setSuccess(AUTOSAVE_MESSAGE)
																					)
																					.catch(() =>
																						addNewAlert({
																							type: "error",
																							message: "Autosave failed",
																						})
																					);

																				return response;
																			}}
																			propertyId={formikProps.values.id}
																			success={success}
																		/>
																	)}
																</SuccessMessageWrapper>
															</div>

															{formikProps.values.hasPhysicalComponent && (
																<SuccessMessageWrapper>
																	{(success, setSuccess) => (
																		<TextField
																			name="physicalItemDescription"
																			placeholder={
																				"Describe the physical component you will include"
																			}
																			value={
																				formikProps.values
																					.physicalItemDescription
																			}
																			component="textarea"
																			error={physItemDescriptionErr}
																			readOnly={isEntryClosed}
																			height="200px"
																			onBlur={() => {
																				const updatePropertyRequest: UpdatePropertyRequest =
																					{
																						id: formikProps.values.id,
																						propertyName:
																							"physicalItemDescription",
																						propertyValue:
																							formikProps.values
																								.physicalItemDescription,
																					};

																				const response = updateEntryProperty(
																					updatePropertyRequest
																				);

																				response
																					.then(() =>
																						setSuccess(AUTOSAVE_MESSAGE)
																					)
																					.catch(() =>
																						addNewAlert({
																							type: "error",
																							message: "Autosave failed",
																						})
																					);

																				return response;
																			}}
																			success={success}
																		/>
																	)}
																</SuccessMessageWrapper>
															)}
														</div>
													)}
													{!formikProps.values.program.isCampaignOnly &&
														formikProps.values.program.isCampaignEligible && (
															<div
																className="col-w-100"
																id={`basic-info-wrapper-campaign-or-single-entry`}
															>
																<h3 className="subtitle font-semibold">
																	Campaign or Single Entry*
																</h3>
																<p>
																	{
																		assetsConfig.copy
																			.entryFormCampaignOrSingleDescription
																	}
																</p>

																<ListContainer name="isCampaign">
																	<NonFormikRadio
																		name="isCampaign"
																		label="Single Entry"
																		checked={!formikProps.values.isCampaign}
																		onClick={() => {
																			if (formikProps.values.isCampaign) {
																				setShowEntryType(true);
																			}
																		}}
																		disabled={
																			props.paymentStatusId === 2 ||
																			props.paymentStatusId === 3 ||
																			isEntryClosed
																		}
																	/>
																	<NonFormikRadio
																		name="isCampaign"
																		label="Campaign Entry"
																		checked={formikProps.values.isCampaign}
																		onClick={() => {
																			if (!formikProps.values.isCampaign) {
																				setShowEntryType(true);
																			}
																		}}
																		disabled={
																			props.paymentStatusId === 2 ||
																			props.paymentStatusId === 3 ||
																			isEntryClosed
																		}
																	/>
																</ListContainer>
															</div>
														)}
													{showExecutionSelection && (
														<SuccessMessageWrapper>
															{(success, setSuccess) => (
																<>
																	{/* warning modal for changing existing execution type */}
																	<ChangeWarning
																		show={showExecutionTypeWarning}
																		title="Change Execution Type"
																		yesMessage="Yes, Change"
																		message="If you make this change, anything you have already added to the Media tab may be discarded. Your media will still be in your media library for you to add when you revisit that tab."
																		onConfirm={() => {
																			if (tempExecutionType) {
																				handleSelectExecutionProgram(
																					tempExecutionType
																				)
																					.then(() =>
																						setSuccess(AUTOSAVE_MESSAGE)
																					)
																					.catch((err) =>
																						addNewAlert({
																							type: "error",
																							message: err,
																						})
																					);
																			}
																		}}
																		onHideModal={() =>
																			setShowExecutionTypeWarning(false)
																		}
																	/>

																	<div
																		className="col-w-100"
																		id={`basic-info-wrapper-category`}
																	>
																		<h3 className="subtitle font-semibold">
																			{
																				formikProps.values.program
																					.childLevelDisplayLabel
																			}
																			*
																		</h3>
																		<p>
																			Please choose one item from the list below
																			to indicate the type of work contained
																			within the execution.
																		</p>
																		<DropdownField
																			name="selectedExecutionProgramId"
																			placeholder="Select One"
																			options={executionTypeOptions.sort(
																				(a, b) => {
																					// use regex to identify "Other" and re-order to the end
																					if (
																						a.label &&
																						String(a.label).replace(
																							/[^\w\s]/gi,
																							""
																						) === "Other"
																					) {
																						return 1;
																					} else if (
																						b.label &&
																						String(b.label).replace(
																							/[^\w\s]/gi,
																							""
																						) === "Other"
																					) {
																						return -1;
																					} else
																						return String(
																							a.label
																						).localeCompare(String(b.label));
																				}
																			)}
																			value={selectedExecutionProgramId}
																			onChange={(e) => {
																				// first time setting execution value
																				if (
																					selectedExecutionProgramId ===
																					undefined
																				) {
																					setSelectedExecutionProgramId(
																						Number(e.currentTarget.value)
																					);
																					handleSelectExecutionProgram(
																						Number(e.currentTarget.value)
																					)
																						.then(() =>
																							setSuccess(AUTOSAVE_MESSAGE)
																						)
																						.catch((err) =>
																							addNewAlert({
																								type: "error",
																								message: err,
																							})
																						);
																				}
																				// user selects tries to reset the category
																				else if (e.currentTarget.value === "") {
																					addNewAlert({
																						type: "error",
																						message: "Please select a category",
																					});
																				} else {
																					setShowExecutionTypeWarning(true);
																				}

																				// temp store dropdown value
																				setTempExecutionType(
																					Number(e.currentTarget.value)
																				);
																			}}
																			nonFormik
																			error={selectedExecutionErr}
																			readOnly={isEntryClosed}
																			success={success}
																		/>
																	</div>
																</>
															)}
														</SuccessMessageWrapper>
													)}
													<div className="flex flex-col gap-[1rem]">
														<Button
															className="w-[175px] ml-auto"
															icon="caret"
															iconRight
															iconRotation="-90deg"
															iconColor={theme.colorCopyLightLight}
															onClick={() => {
																showTabs
																	? setTabIndex(1)
																	: setValidateSelectExecution(true);
															}}
															type="button"
															disabled={!showTabs}
														>
															Next
														</Button>
														<BackToEntriesLink
															isOrderComplete={isOrderComplete}
														/>
													</div>
												</StyledForm>

												<NewMediaLibrarySticky
													className="entryPreviewMd"
													companyId={companyId}
													mediaArr={media}
													refreshMedia={(successUpload) => {
														getCompanyMedia();

														if (successUpload) {
															addNewAlert({
																type: "success",
																message: "Successfully Uploaded Media File",
															});
														}
													}}
												/>
											</UserProfileBody>
										</NavTabPanel>

										<NavTabPanel

										// Additional Info Tab
										>
											<UserProfileBody className="has-preview-card">
												<StyledForm className="oneColumn extraSpacing">
													<TextField
														name="title"
														placeholder={
															assetsConfig.labels.entry.singular + " Title"
														}
														readOnly
														value={formikProps.values.title}
													/>
													<DynamicFields
														name="campaignFields"
														fieldArray={sortEntryFields(
															formikProps.values.campaignFields
														)}
														refreshMedia={getCompanyMedia}
														readOnly={isEntryClosed}
														tags={entryTags}
														hideMediaFields
													/>

													<EntryNavButtons
														prevIndex={0}
														nextIndex={2}
														setTabIndex={setTabIndex}
														isOrderComplete={isOrderComplete}
													/>
												</StyledForm>
											</UserProfileBody>
										</NavTabPanel>
										<NavTabPanel
										// Credits Tab
										>
											<UserProfileBody className="has-preview-card">
												<StyledForm className="oneColumn extraSpacing">
													{/*TODO Set onChange field for Entry Title */}
													<TextField
														name="title"
														placeholder={
															assetsConfig.labels.entry.singular + " Title"
														}
														readOnly
														value={formikProps.values.title}
													/>

													<CreditFields
														name={"creditFields"}
														fieldArray={sortEntryFields(
															formikProps.values.creditFields
														)}
														readOnly={isEntryClosed}
													/>

													<EntryNavButtons
														prevIndex={1}
														nextIndex={formikProps.values.isCampaign ? 4 : 3}
														setTabIndex={setTabIndex}
														isOrderComplete={isOrderComplete}
													/>
												</StyledForm>

												<NewCreditLibrarySticky
													companyCreditArr={companyCreditsArray}
													indCreditArr={individualCreditsArray}
													setCompanyCreditsArray={setCompanyCreditsArray}
													setIndividualCreditsArray={setIndividualCreditsArray}
													allowMultiselect
												/>
											</UserProfileBody>
										</NavTabPanel>

										<NavTabPanel
											// Media Tab
											hide={formikProps.values.isCampaign}
										>
											<UserProfileBody className="has-preview-card">
												<StyledForm className="oneColumn extraSpacing">
													<TextField
														name="title"
														placeholder={
															assetsConfig.labels.entry.singular + " Title"
														}
														readOnly
														value={formikProps.values.title}
													/>

													{entryLevelMediaFields}

													{formikProps.values.executions &&
														formikProps.values.executions.length > 0 && (
															<DynamicFields
																name={`executions.0.executionFields`}
																fieldArray={sortEntryFields(
																	formikProps.values.executions[0]
																		.executionFields
																)}
																refreshMedia={getCompanyMedia}
																readOnly={isEntryClosed}
																tags={entryTags}
																companyId={props.companyId}
															/>
														)}

													<EntryNavButtons
														prevIndex={2}
														nextIndex={5}
														setTabIndex={setTabIndex}
														isOrderComplete={isOrderComplete}
													/>
												</StyledForm>

												<NewMediaLibrarySticky
													className="entryPreviewMd"
													companyId={companyId}
													mediaArr={media}
													allowMultiselect
													refreshMedia={(successUpload) => {
														getCompanyMedia();

														if (successUpload) {
															addNewAlert({
																type: "success",
																message: "Successfully Uploaded Media File",
															});
														}
													}}
												/>
											</UserProfileBody>
										</NavTabPanel>

										<NavTabPanel
											// Executions tab
											hide={formikProps.values.isCampaign}
										>
											<UserProfileBody className="has-preview-card">
												<StyledForm className="oneColumn extraSpacing">
													<div className="col-w-100">
														<TextField
															name="title"
															placeholder={
																assetsConfig.labels.entry.singular + " Title"
															}
															readOnly
															value={formikProps.values.title}
														/>
														{!settings.isDemo &&
															formikProps.values.executions.length < 2 && (
																<p className="text-colorDanger">
																	You must include between 2 and 10 Campaign
																	Executions for your entry to be valid. Please
																	click "Add Execution" below to add additional
																	executions, and fill in all required fields.
																</p>
															)}
													</div>

													{entryLevelMediaFields}

													<div className="col-w-100">
														<h3 className="subtitle font-semibold">
															Campaign Executions
														</h3>
														<div
															dangerouslySetInnerHTML={{
																__html:
																	assetsConfig.copy
																		.entryFormCampaignExecutionsDescription,
															}}
														></div>
													</div>

													<ChangeWarning
														show={showModal.changeExecutionProgramId !== 0}
														title="Change Execution Type"
														yesMessage="Yes, Change"
														message="Making this change could result in lost data. Please check your entry and correct any errors."
														onConfirm={() => {
															if (settings.isDemo !== undefined) {
																changeExecution(
																	Number(showModal.changeExecutionProgramId),
																	Number(showModal.currentExecutionId)
																);
																setShowModal({
																	...showModal,
																	changeExecutionProgramId: 0,
																	currentExecutionId: 0,
																});
															}
														}}
														onHideModal={() =>
															setShowModal({
																...showModal,
																changeExecutionProgramId: 0,
																currentExecutionId: 0,
															})
														}
													/>

													{/* execution forms */}
													{formikProps.values.executions &&
														formikProps.values.executions.length > 0 &&
														formikProps.values.executions.map(
															(executionObj, executionObjIndex) => {
																return (
																	<ExecutionForm
																		key={String(executionObj.id)}
																		id={String(executionObj.id)}
																	>
																		{executionTypeOptions.length > 1 && (
																			<DropdownField
																				name={`executions.${executionObjIndex}.programId`}
																				placeholder={
																					formikProps.values.program
																						.childLevelDisplayLabel
																				}
																				options={executionTypeOptions}
																				value={executionObj.programId}
																				hideEmptyOption={true}
																				onChange={(e) => {
																					setShowModal({
																						...showModal,
																						changeExecutionProgramId: Number(
																							e.currentTarget.value
																						),
																						currentExecutionId: executionObj.id,
																					});
																				}}
																				disabled={
																					!props.program.isMixedCampaignEligible
																				}
																				nonFormik
																				readOnly={isEntryClosed}
																			/>
																		)}
																		<SuccessMessageWrapper>
																			{(success, setSuccess) => (
																				<TextField
																					name={`executions.${executionObjIndex}.title`}
																					placeholder="Execution Title*"
																					value={executionObj.title}
																					success={success}
																					error={
																						!executionObj.title
																							? fieldRequired
																							: undefined
																					}
																					readOnly={isEntryClosed}
																					onBlur={() => {
																						const updatePropertyRequest: UpdatePropertyRequest =
																							{
																								id: executionObj.id,
																								propertyName: "title",
																								propertyValue:
																									executionObj.title,
																							};
																						const response =
																							updateExecutionProperty(
																								updatePropertyRequest
																							);

																						return response;
																					}}
																				/>
																			)}
																		</SuccessMessageWrapper>
																		<hr />
																		<DynamicFields
																			name={`executions.${executionObjIndex}.executionFields`}
																			fieldArray={executionObj.executionFields}
																			refreshMedia={getCompanyMedia}
																			readOnly={isEntryClosed}
																			tags={entryTags}
																			companyId={props.companyId}
																		/>
																	</ExecutionForm>
																);
															}
														)}
													{(formikProps.values.executions.length < 10 ||
														settings.isDemo) && (
														<Button
															className="w-[250px] ml-auto"
															iconLeft="plus"
															iconColor={theme.colorCopyLightLight}
															onClick={() => {
																// TODO: Create new Execution logic
																if (
																	!selectedExecutionProgramId ||
																	(props.program.isMixedCampaignEligible &&
																		props.program.children &&
																		props.program.children?.length > 1)
																) {
																	setShowModal({
																		...showModal,
																		addExecution: true,
																	});
																} else {
																	addExecution(selectedExecutionProgramId!);
																}
															}}
															disabled={isEntryClosed}
														>
															Add Execution
														</Button>
													)}

													<div className="col-w-100">
														<h3 className="subtitle font-semibold">
															Display Order
														</h3>
														<p>
															{
																assetsConfig.copy
																	.entryFormDisplayOrderDescription
															}
														</p>
														{executionForms &&
															executionForms.length > 0 &&
															executionForms.map((card, i) =>
																renderCard(card, i, isEntryClosed)
															)}

														<Button
															className="w-[250px] button-light ml-auto mt-[1rem]"
															icon="check"
															iconColor={theme.colorActivation}
															onClick={() => {
																setDraggedCard(true);
																const list = executionForms.map(
																	(execution, i) => {
																		return {
																			id: execution.id,
																			propertyName: "Index",
																			propertyValue: i,
																		};
																	}
																);
																onSubmit(updateExecutionProperty, list);
																setTimeout(() => {
																	window.scrollTo({
																		top: document.body.scrollHeight,
																		behavior: "instant" as ScrollBehavior,
																	});
																}, 100);
															}}
															disabled={isEntryClosed}
														>
															Save Display Order
														</Button>
													</div>

													<EntryNavButtons
														prevIndex={2}
														nextIndex={5}
														setTabIndex={setTabIndex}
														isOrderComplete={isOrderComplete}
													/>
												</StyledForm>

												<NewMediaLibrarySticky
													className="entryPreviewMd"
													companyId={companyId}
													mediaArr={media}
													refreshMedia={(successUpload) => {
														getCompanyMedia();

														if (successUpload) {
															addNewAlert({
																type: "success",
																message: "Successfully Uploaded Media File",
															});
														}
													}}
													allowMultiselect
												/>
											</UserProfileBody>
										</NavTabPanel>

										<NavTabPanel

										// Review Tab
										>
											<UserProfileBody className="has-preview-card">
												{tabIndex === 5 && (
													<>
														<StyledForm className="oneColumn !gap-[4rem]">
															<div className="col-w-100">
																<h3 className="subtitle font-semibold">
																	Review Your{" "}
																	{assetsConfig.labels.entry.singular}
																</h3>

																<p>
																	{assetsConfig.copy.entryFormReviewDescription}
																</p>
															</div>

															<div className="col-w-100">
																<h3 className="subtitle font-semibold">
																	Basic Info
																</h3>
																<EntryReviewContainer className="col-w-100">
																	<EntryField
																		label="Title"
																		value={formikProps.values.title}
																		isRequired={true}
																	/>

																	<EntryField
																		label="Brand"
																		value={formikProps.values.brand}
																		isRequired={true}
																	/>

																	{formikProps.values.executions &&
																		formikProps.values.executions.length >
																			0 && (
																			<EntryField
																				label={
																					formikProps.values.program
																						.childLevelDisplayLabel
																				}
																				value={
																					formikProps.values.executions
																						.length === 1
																						? formikProps.values.executions[0]
																								.program.name
																						: formikProps.values.executions.every(
																								(execution, _, array) =>
																									execution.programId ===
																									array[0].programId
																						  )
																						? formikProps.values.executions[0]
																								.program.name
																						: "Mixed Campaign"
																				}
																				isRequired={true}
																			/>
																		)}

																	{formikProps.values.hasPhysicalComponent && (
																		<EntryField
																			label="Physical Asset"
																			value={
																				formikProps.values
																					.physicalItemDescription
																			}
																			isRequired={true}
																		/>
																	)}
																</EntryReviewContainer>
															</div>

															{/* <h3 className="subtitle font-semibold">Media Assets</h3> */}
															{/* {formikProps.values.media.length > 0 ? (
								(formikProps.values.media as MediaProps[]).map((mediaObj) => {
									console.log(
										"formikProps.values.media",
										formikProps.values.media
									);
									return (
										<EntryMediaCard
											mediaFileName={mediaObj.fileName!}
											mediaImage={mediaObj.imgSrc}
											mediaType="Image"
											// mediaCardTitle="Title Example"
										/>
									);
								})
							) : (
								// <EntryMediaCard
								// 	mediaFileName="Placeholder.jpg"
								// 	mediaImage={PlaceholderImg}
								// 	mediaType="Image"
								// 	mediaCardTitle="No Media Assets Added"
								// />
								<p className="text-colorDanger">No Media Assets Added</p>
							)} */}

															{/*TODO: The styling for the below entries is for basic info, but this styling will match any
					new fields that come over that populate the addtional info fields. We'll componentize this later on*/}

															<div className="col-w-100">
																<h3 className="subtitle font-semibold">
																	Additional Info
																</h3>
																<ReviewCampaignFields />
															</div>

															<div className="col-w-100">
																<h3 className="subtitle font-semibold">
																	Company Credits
																</h3>
																<EntryReviewContainer className="col-w-100 col-3-entry-info">
																	{/*TODO: Make company card more reusable and flexbible depending on the information available. */}
																	{creditCount.companyCredits > 0 &&
																		formikProps.values.creditFields.map(
																			(
																				creditField:
																					| EntryCompanyCreditField
																					| EntryIndividualCreditField
																			) => {
																				if (
																					creditField.classType ===
																					"EntryCompanyCreditField"
																				) {
																					return (
																						creditField!.credits?.length > 0 &&
																						creditField.credits
																							?.sort(
																								(a, b) => a.weight! - b.weight!
																							)
																							.map((creditObj: any, index) => (
																								<Fragment key={index}>
																									<b className="entry-label">
																										{creditObj.credit.name}
																									</b>
																									<p className="entry-info">
																										{creditObj.credit.city}
																									</p>
																									<p className="entry-info">
																										{creditObj.credit
																											.otherCompanyType ||
																											creditObj.credit
																												.companyType}
																									</p>
																								</Fragment>
																							))
																					);
																				}
																			}
																		)}

																	<div className="flex flex-col gap-[1rem]">
																		{creditCount.companyCredits === 0 && (
																			<p>No Company Credits Added</p>
																		)}

																		{isMissingReqCompanyCredits && (
																			<p className="text-colorDanger italic">
																				Missing Required Information*
																			</p>
																		)}
																	</div>
																</EntryReviewContainer>
															</div>

															<div className="col-w-100">
																<h3 className="subtitle font-semibold">
																	Individual Credits
																</h3>
																<EntryReviewContainer className="col-w-100 col-3-entry-info">
																	{/*TODO: Make company card more reusable and flexbible depending on the information available. */}

																	{creditCount.individualCredits > 0 &&
																		formikProps.values.creditFields.map(
																			(
																				creditField:
																					| EntryIndividualCreditField
																					| EntryCompanyCreditField
																			) => {
																				if (
																					creditField.classType ===
																					"EntryIndividualCreditField"
																				) {
																					return (
																						creditField!.credits?.length > 0 &&
																						creditField!.credits
																							.sort(
																								(a, b) => a.weight! - b.weight!
																							)
																							.map((creditObj: any, index) => (
																								<Fragment key={index}>
																									<b className="entry-label">
																										{creditObj.credit.fullName}
																									</b>
																									<p className="entry-info">
																										{creditObj.credit
																											.otherJobTitle ||
																											creditObj.credit.jobTitle}
																									</p>
																									<p className="entry-info">
																										{
																											creditObj.credit
																												.companyName
																										}
																									</p>
																								</Fragment>
																							))
																					);
																				}
																			}
																		)}

																	<div className="flex flex-col gap-[1rem]">
																		{creditCount.individualCredits === 0 && (
																			<p>No Individual Credits Added</p>
																		)}

																		{isMissingReqIndCredits && (
																			<p className="text-colorDanger italic">
																				Missing Required Information*
																			</p>
																		)}
																	</div>
																</EntryReviewContainer>
															</div>
															{!settings.isDemo && (
																<div className="col-w-100">
																	<h3 className="subtitle font-semibold">
																		Terms and Conditions*
																	</h3>

																	<p
																		dangerouslySetInnerHTML={{
																			__html:
																				assetsConfig.copy.entryFormReviewTOS,
																		}}
																	></p>

																	<SuccessMessageWrapper>
																		{(success, setSuccess) => (
																			<CheckboxField
																				placeholder="I agree to these conditions*"
																				name="termsAndConditions"
																				disabled={
																					formikProps.values.paymentStatusId ===
																						PaymentStatus.PaymentPending ||
																					formikProps.values.paymentStatusId ===
																						PaymentStatus.Paid ||
																					isEntryClosed
																				}
																				error={termsAndCondErr}
																				checked={
																					formikProps.values.termsAndConditions
																				}
																				onCheckedChanged={(newValue) => {
																					const updatePropertyRequest: UpdatePropertyRequest =
																						{
																							id: formikProps.values.id,
																							propertyName:
																								"termsAndConditions",
																							propertyValue: newValue,
																						};
																					updateEntryProperty(
																						updatePropertyRequest
																					)
																						.then(() =>
																							setSuccess(AUTOSAVE_MESSAGE)
																						)
																						.catch(() =>
																							addNewAlert({
																								type: "error",
																								message: "Failed to save.",
																							})
																						);
																				}}
																				success={success}
																			/>
																		)}
																	</SuccessMessageWrapper>
																</div>
															)}
															<div className="flex flex-col gap-[1rem]">
																<div className="grid grid-cols-2 gap-[1rem]">
																	<Button
																		className="button-light"
																		icon="caret"
																		iconRotation="90deg"
																		iconColor={theme.colorCopyDarkDark}
																		onClick={() =>
																			setTabIndex(
																				formikProps.values.isCampaign ? 4 : 3
																			)
																		}
																	>
																		Previous
																	</Button>
																	<Button
																		type="button"
																		// icon="check"
																		iconColor={theme.colorCopyLightLight}
																		onClick={() => {
																			history.push(
																				entriesNav(
																					isAdmin,
																					isOrderComplete,
																					settings.isDemo
																				).path
																			);
																		}}
																	>
																		{
																			entriesNav(
																				isAdmin,
																				isOrderComplete,
																				settings.isDemo
																			).label
																		}
																	</Button>
																</div>
															</div>
														</StyledForm>

														<StickyCard
															title={
																"Preview " + assetsConfig.labels.entry.singular
															}
															className="entryPreviewMd"

															// headerIcons={[<ExpandArrowsIcon />]}
														>
															<div className="overflow-auto">
																<StickyCardBody className="flex flex-col gap-[1.875rem]">
																	<div>
																		{/* <h3 className="font-semibold mb-[.625rem]">
                                    Entry Preview
                                  </h3> */}
																		<p className="mb-[1.25rem]">
																			Please review this card to ensure your
																			{lowerCase(
																				assetsConfig.labels.entry.singular
																			)}{" "}
																			appears as intended.
																		</p>

																		<EntryReviewGalleryCard
																			entryTitle={{
																				val: formikProps.values.title,
																			}}
																			titleEllipse={67}
																			brandEllipse={37}
																			entryBrand={{
																				val: formikProps.values.brand,
																			}}
																			entryCoverImageSrc={{
																				val: formikProps.values.coverImage
																					?.mediaItem?.path
																					? getMediaSrc(
																							formikProps.values.coverImage
																								.mediaItem,
																							ThumbnailSize.MEDIUM
																					  )
																					: "",
																			}}
																			medium={{
																				val:
																					formikProps.values.program.name || "",
																			}}
																			// entrantCompany={{
																			//   val: company ? company.name : "",
																			// }}
																			// category={{
																			//   val:
																			//     executionTypeOptions &&
																			//     selectedExecutionProgramId
																			//       ? String(
																			//           executionTypeOptions?.find(
																			//             (executionType) =>
																			//               executionType.value ===
																			//               selectedExecutionProgramId
																			//           )?.label
																			//         ) || ""
																			//       : "",
																			// }}
																			// entryType={{
																			//   val: entryType,
																			// }}
																		></EntryReviewGalleryCard>
																	</div>

																	{/* <Button
										className="button-light ml-auto"
										icon="edit"
										iconColor={theme.colorActivation}
									>
										Crop Image
									</Button> */}
																</StickyCardBody>
															</div>
														</StickyCard>
													</>
												)}
											</UserProfileBody>
										</NavTabPanel>
									</NavTabs>
								</FormikProvider>
							</div>
						</ToggleMobileCreditLibContext.Provider>
					</ToggleMobileMediaLibContext.Provider>
				</MediaCheckContext.Provider>
			</CreditCheckContext.Provider>
		</ValidateContext.Provider>
	);
};

export default EntryForm;

interface EntryFieldProps {
	label: string;
	value?: string | number | Date | Boolean | React.ReactNode;
	isRequired: boolean; // shows red required text if value is empty and is required
	hardLink?: string; // optional absolute path for hyperlink
	linkText?: string; // optional text for hyperlink
}

// indexed objects for credits
export interface CompanyCreditObj {
	[id: string]: CompanyCredit;
}

export interface IndividualCreditObj {
	[id: string]: IndividualCredit;
}

export interface Item {
	id: number;
	text: string;
}

export interface ContainerState {
	cards: Item[];
}

interface EntryFormProps extends AdminEntryModel {
	tabIndex?: number;
	// setIsEntrySaved?(isSaved: boolean): void; // for entrants
}

interface EntriesNavigation {
	label: string;
	path: string;
}
