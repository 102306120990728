import { DropdownOptions } from "../components/FormFields/DropdownField";

export const CountryData: string[] = [
  "United States",
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo (Brazzaville)",
  "Congo (Kinshasa)",
  "Cook Islands",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea-Bissau",
  "Guinea",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Honduras",
  "Hong Kong S.A.R., China",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao S.A.R., China",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands Antilles",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "North Korea",
  "North Macedonia",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Barthélemy",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "U.S. Virgin Islands",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States Minor Outlying Islands",

  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican (Holy See)",
  "Venezuela",
  "Viet Nam",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const CountryOptions: DropdownOptions[] = [
  { value: "United States" },
  { value: "Afghanistan" },
  { value: "Åland Islands" },
  { value: "Albania" },
  { value: "Algeria" },
  { value: "American Samoa" },
  { value: "Andorra" },
  { value: "Angola" },
  { value: "Anguilla" },
  { value: "Antarctica" },
  { value: "Antigua and Barbuda" },
  { value: "Argentina" },
  { value: "Armenia" },
  { value: "Aruba" },
  { value: "Australia" },
  { value: "Austria" },
  { value: "Azerbaijan" },
  { value: "Bahamas" },
  { value: "Bahrain" },
  { value: "Bangladesh" },
  { value: "Barbados" },
  { value: "Belarus" },
  { value: "Belgium" },
  { value: "Belize" },
  { value: "Benin" },
  { value: "Bermuda" },
  { value: "Bhutan" },
  { value: "Bolivia" },
  { value: "Bonaire, Sint Eustatius and Saba" },
  { value: "Bosnia and Herzegovina" },
  { value: "Botswana" },
  { value: "Bouvet Island" },
  { value: "Brazil" },
  { value: "British Indian Ocean Territory" },
  { value: "British Virgin Islands" },
  { value: "Brunei" },
  { value: "Bulgaria" },
  { value: "Burkina Faso" },
  { value: "Burundi" },
  { value: "Cambodia" },
  { value: "Cameroon" },
  { value: "Canada" },
  { value: "Cape Verde" },
  { value: "Cayman Islands" },
  { value: "Central African Republic" },
  { value: "Chad" },
  { value: "Chile" },
  { value: "China" },
  { value: "Christmas Island" },
  { value: "Cocos (Keeling) Islands" },
  { value: "Colombia" },
  { value: "Comoros" },
  { value: "Congo (Brazzaville)" },
  { value: "Congo (Kinshasa)" },
  { value: "Cook Islands" },
  { value: "Costa Rica" },
  { value: "Côte d'Ivoire" },
  { value: "Croatia" },
  { value: "Cuba" },
  { value: "Curaçao" },
  { value: "Cyprus" },
  { value: "Czechia" },
  { value: "Denmark" },
  { value: "Djibouti" },
  { value: "Dominica" },
  { value: "Dominican Republic" },
  { value: "Ecuador" },
  { value: "Egypt" },
  { value: "El Salvador" },
  { value: "Equatorial Guinea" },
  { value: "Eritrea" },
  { value: "Estonia" },
  { value: "Eswatini" },
  { value: "Ethiopia" },
  { value: "Falkland Islands" },
  { value: "Faroe Islands" },
  { value: "Fiji" },
  { value: "Finland" },
  { value: "France" },
  { value: "French Guiana" },
  { value: "French Polynesia" },
  { value: "French Southern Territories" },
  { value: "Gabon" },
  { value: "Gambia" },
  { value: "Georgia" },
  { value: "Germany" },
  { value: "Ghana" },
  { value: "Gibraltar" },
  { value: "Greece" },
  { value: "Greenland" },
  { value: "Grenada" },
  { value: "Guadeloupe" },
  { value: "Guam" },
  { value: "Guatemala" },
  { value: "Guernsey" },
  { value: "Guinea-Bissau" },
  { value: "Guinea" },
  { value: "Guyana" },
  { value: "Haiti" },
  { value: "Heard Island and McDonald Islands" },
  { value: "Honduras" },
  { value: "Hong Kong S.A.R., China" },
  { value: "Hungary" },
  { value: "Iceland" },
  { value: "India" },
  { value: "Indonesia" },
  { value: "Iran" },
  { value: "Iraq" },
  { value: "Ireland" },
  { value: "Isle of Man" },
  { value: "Israel" },
  { value: "Italy" },
  { value: "Jamaica" },
  { value: "Japan" },
  { value: "Jersey" },
  { value: "Jordan" },
  { value: "Kazakhstan" },
  { value: "Kenya" },
  { value: "Kiribati" },
  { value: "Kuwait" },
  { value: "Kyrgyzstan" },
  { value: "Laos" },
  { value: "Latvia" },
  { value: "Lebanon" },
  { value: "Lesotho" },
  { value: "Liberia" },
  { value: "Libya" },
  { value: "Liechtenstein" },
  { value: "Lithuania" },
  { value: "Luxembourg" },
  { value: "Macao S.A.R., China" },
  { value: "Madagascar" },
  { value: "Malawi" },
  { value: "Malaysia" },
  { value: "Maldives" },
  { value: "Mali" },
  { value: "Malta" },
  { value: "Marshall Islands" },
  { value: "Martinique" },
  { value: "Mauritania" },
  { value: "Mauritius" },
  { value: "Mayotte" },
  { value: "Mexico" },
  { value: "Micronesia" },
  { value: "Moldova" },
  { value: "Monaco" },
  { value: "Mongolia" },
  { value: "Montenegro" },
  { value: "Montserrat" },
  { value: "Morocco" },
  { value: "Mozambique" },
  { value: "Myanmar" },
  { value: "Namibia" },
  { value: "Nauru" },
  { value: "Nepal" },
  { value: "Netherlands Antilles" },
  { value: "Netherlands" },
  { value: "New Caledonia" },
  { value: "New Zealand" },
  { value: "Nicaragua" },
  { value: "Niger" },
  { value: "Nigeria" },
  { value: "Niue" },
  { value: "Norfolk Island" },
  { value: "North Korea" },
  { value: "North Macedonia" },
  { value: "Northern Mariana Islands" },
  { value: "Norway" },
  { value: "Oman" },
  { value: "Pakistan" },
  { value: "Palau" },
  { value: "Palestine" },
  { value: "Panama" },
  { value: "Papua New Guinea" },
  { value: "Paraguay" },
  { value: "Peru" },
  { value: "Philippines" },
  { value: "Pitcairn" },
  { value: "Poland" },
  { value: "Portugal" },
  { value: "Puerto Rico" },
  { value: "Qatar" },
  { value: "Réunion" },
  { value: "Romania" },
  { value: "Russia" },
  { value: "Rwanda" },
  { value: "Saint Barthélemy" },
  { value: "Saint Helena" },
  { value: "Saint Kitts and Nevis" },
  { value: "Saint Lucia" },
  { value: "Saint Martin (French part)" },
  { value: "Saint Pierre and Miquelon" },
  { value: "Saint Vincent and the Grenadines" },
  { value: "Samoa" },
  { value: "San Marino" },
  { value: "Sao Tome and Principe" },
  { value: "Saudi Arabia" },
  { value: "Senegal" },
  { value: "Serbia" },
  { value: "Seychelles" },
  { value: "Sierra Leone" },
  { value: "Singapore" },
  { value: "Sint Maarten (Dutch part)" },
  { value: "Slovakia" },
  { value: "Slovenia" },
  { value: "Solomon Islands" },
  { value: "Somalia" },
  { value: "South Africa" },
  { value: "South Georgia and the South Sandwich Islands" },
  { value: "South Korea" },
  { value: "South Sudan" },
  { value: "Spain" },
  { value: "Sri Lanka" },
  { value: "Sudan" },
  { value: "Suriname" },
  { value: "Svalbard and Jan Mayen" },
  { value: "Sweden" },
  { value: "Switzerland" },
  { value: "Syria" },
  { value: "Taiwan" },
  { value: "Tajikistan" },
  { value: "Tanzania" },
  { value: "Thailand" },
  { value: "Timor-Leste" },
  { value: "Togo" },
  { value: "Tokelau" },
  { value: "Tonga" },
  { value: "Trinidad and Tobago" },
  { value: "Tunisia" },
  { value: "Turkey" },
  { value: "Turkmenistan" },
  { value: "Turks and Caicos Islands" },
  { value: "Tuvalu" },
  { value: "U.S. Virgin Islands" },
  { value: "Uganda" },
  { value: "Ukraine" },
  { value: "United Arab Emirates" },
  { value: "United Kingdom" },
  { value: "United States Minor Outlying Islands" },

  { value: "Uruguay" },
  { value: "Uzbekistan" },
  { value: "Vanuatu" },
  { value: "Vatican (Holy See)" },
  { value: "Venezuela" },
  { value: "Viet Nam" },
  { value: "Wallis and Futuna" },
  { value: "Western Sahara" },
  { value: "Yemen" },
  { value: "Zambia" },
  { value: "Zimbabwe" },
];
