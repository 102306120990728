import React from "react";
import styled from "styled-components";
import { lightBoxShadow } from "../../assetsConfig";

const LightboxContainer = styled.div`
	display: flex;
	background: white;
	border-radius: 4px;
	box-shadow: ${lightBoxShadow};
	padding: 4rem 2rem;
	max-width: 80vw;

	@media only screen and (min-width: ${({ theme }) => theme.lg}) {
		/* max-width: 992px; */
		padding: 3rem 4rem;
	}

	@media only screen and (min-width: ${({ theme }) => theme.xl}) {
		max-width: ${({ theme }) => theme.xl};
		padding: 5rem 8rem;
	}
`;

interface LightboxProps {
	className?: string;
	children?: React.ReactNode;
}

const Lightbox = (props: LightboxProps) => {
	return (
		<LightboxContainer className={props.className}>
			{props.children}
		</LightboxContainer>
	);
};

export default Lightbox;
