import {
	useState,
	useEffect,
	Fragment,
	useContext,
	useMemo,
	useCallback,
} from "react";
import styled, { useTheme } from "styled-components";
import { IndividualCredit } from "../CreditInterfaces";
import Icon from "../../../../components/Icon/Icon";
import { CreditType } from "../../Program/ProgramInterfaces";
import {
	deleteIndividualCredit,
	getIndividualCredits,
	getLinkedEntriesByIndividualCreditId,
	updateIndividualCredit,
	uploadIndividualCreditCsvFile,
} from "../manageCreditLibrary";
import SortCreditLibrary from "./SortCreditLibrary";
import FilterCreditLibrary from "./FilterCreditLibrary";
import CreditDetails from "./CreditDetails";

import FileUpload from "../../../../components/FormFields/FileUpload";
import { CreditListProps } from "../CreditLibrary";
import {
	CreditFieldContainer,
	CreditListContainer,
	LibraryContainer,
	LibraryInnerContainer,
} from "../../../../components/Library/Library";
import {
	SpinnerContainer,
	FileObj,
} from "../../../../components/LocalFileUpload.tsx/LocalFileUpload";
import ProgressCircle from "../../../../components/ProgressCircle/ProgressCircle";
import AddCreditModal from "../AddCreditModal";
import { CSVStatus, UploadProgress } from "./CompanyCreditList";
import TagSelectionModal from "../../../../components/Tags/TagSelectionModal";
import useTaskHandler, {
	BottomBar,
	PENDING,
	SelectableContainer,
	TASKS_COMPLETED,
	TaskHandlerReturnType,
} from "../../../../hooks/useTaskHandler";
import { FormikProvider, useFormik } from "formik";
import Button from "../../../../components/Button/Button";
import ToggleSwitchField from "../../../../components/FormFields/ToggleSwitchField";
import { Tag } from "../../../MediaLibrary/mediaLibrary.model";
import assetsConfig from "../../../../assetsConfig";
import { useAlert } from "../../../../components/Alert/Alerts";
export const IndividualCreditGridCSS = `
  display: grid;
  grid-template-areas: "b c d e";
  grid-template-columns: 1fr 1fr 1fr 150px;

  :first-child {
    padding-left: 10px;
  }
`;

const IndividualCreditContainer = styled(CreditListContainer)`
	.children {
		${IndividualCreditGridCSS};
	}
`;

const IndividualCreditList = (props: CreditListProps) => {
	const theme = useTheme();
	const { addNewAlert } = useAlert();
	const [individualCredits, setIndividualCredits] = useState<
		IndividualCredit[]
	>([]);
	const [filteredCredits, setFilteredCredits] = useState<IndividualCredit[]>(
		[]
	);
	const [sortedCredits, setSortedCredits] = useState<IndividualCredit[]>([]);
	const [csvStatus, setCsvStatus] = useState<CSVStatus>(CSVStatus.Idle);

	const handleGetIndividualCredits = () => {
		if (props.companyId) {
			getIndividualCredits(props.companyId).then((res) => {
				if (res.status === 200) {
					setIndividualCredits(res.data);
				}
			});
		}
	};

	const handleDeleteIndividualCredit = (credit: IndividualCredit) => {
		getLinkedEntriesByIndividualCreditId(props.companyId, credit.id!).then(
			(res) => {
				if (res.status === 200) {
					deleteIndividualCredit(credit, res.data)
						.then(() => {
							addNewAlert({
								type: "success",
								message: "Successfully deleted individual credit",
							});
							handleGetIndividualCredits();
						})
						.catch((e) =>
							addNewAlert({
								type: "error",
								message: e.message,
							})
						);
				}
			}
		);
	};

	const [creditForm, setCreditForm] = useState<IndividualCredit>();

	const [showIndividualCreditDetails, setShowIndividualCreditDetails] =
		useState(false);

	const [isUploading, setIsUploading] = useState(false);

	// multi-select functionality
	const [showTagModal, setShowTagModal] = useState(false);

	const deleteCredit = useCallback(
		(credit: IndividualCredit): Promise<IndividualCredit> => {
			return new Promise((resolve, reject) => {
				getLinkedEntriesByIndividualCreditId(props.companyId, credit.id!)
					.then((res) => {
						if (res.status === 200) {
							deleteIndividualCredit(credit, res.data)
								.then(() => {
									resolve(credit);
								})
								.catch((e) => reject(`Error Deleting Credit: ${e.message}`));
						}
					})
					.catch((e) => reject(`Error Deleting Credit: ${e.message}`));
			});
		},
		[]
	);

	const formikProps = useFormik({
		initialValues: {
			allActive: false,
		},
		onSubmit: async (value) => {},
		enableReinitialize: true,
	});

	/*
  state.pending: Processing items array
  state.completed: Completed items array
  state.errored: Errored items array (includes item and error)
  state.next: currently processing item
  state.prev: last processed item
  */
	const {
		reset,
		loadItems,
		onSubmit,
		handleSelectItem,
		...state
	}: TaskHandlerReturnType<IndividualCredit> =
		useTaskHandler<IndividualCredit>();

	// Add deletion tasks when 'Delete Selected' is clicked
	const handleDeleteSelected = () => {
		onSubmit(deleteCredit);
	};

	const handleEditIconClick = (data: IndividualCredit) => {
		setCreditForm(data);
		setShowIndividualCreditDetails(true);
	};

	const handleEditCredit = useCallback((credit: IndividualCredit) => {
		return new Promise<IndividualCredit>((resolve, reject) => {
			updateIndividualCredit(credit.id!, credit)
				.then(() => {
					resolve(credit);
				})
				.catch((e) => reject(`Error Editing Credit: ${e.message}`));
		});
	}, []);

	const handleTagSelected = (
		tags: Tag[],
		selectedCredit: IndividualCredit[]
	) => {
		const newArray = selectedCredit.map((credit) => {
			return { ...credit, tags: tags };
		});
		onSubmit(handleEditCredit, newArray);
	};

	const handleToggleActiveSelected = (
		isActive: boolean,
		selectedCredit: IndividualCredit[]
	) => {
		const newArray = selectedCredit.map((credit) => {
			return { ...credit, isActive: isActive };
		});
		onSubmit(handleEditCredit, newArray);
	};

	const commonTags = useMemo(() => {
		if (state.items.length === 0) {
			return [];
		}

		const firstItemTags = state.items[0].tags || [];

		for (let i = 1; i < state.items.length; i++) {
			const mediaItem = state.items[i];

			// Compare tags arrays. If they differ, return empty array.
			if (
				!firstItemTags.every(
					(tag) =>
						mediaItem.tags &&
						mediaItem.tags.findIndex((x) => x.id === tag.id) > -1
				)
			) {
				return [];
			}
		}

		// If we made it through the loop without returning, all tags are the same.
		return firstItemTags;
	}, [state.items]);

	const allActive = useMemo(() => {
		if (state.items.length === 0) {
			return false;
		}
		return state.items.every((x) => x.isActive);
	}, [state.items]);

	useEffect(() => {
		if (state.status === PENDING) {
			setIsUploading(true);
		}
		if (state.status === TASKS_COMPLETED) {
			// If there are no tasks left, refresh the credit list
			handleGetIndividualCredits();
			loadItems([]);
			setIsUploading(false);
		}
	}, [state.status]);

	const handleFileUpload = (files: FileObj[]) => {
		// console.log(files);

		if (files.length > 1) {
			addNewAlert({
				type: "error",
				message: "Only one file can be uploaded at a time.",
			});

			return;
		}

		let latestFile = files.reduce((prev, curr) =>
			prev.id > curr.id ? prev : curr
		);

		setCsvStatus(CSVStatus.Processing);
		uploadIndividualCreditCsvFile(props.companyId, latestFile.file)
			.then((response) => {
				addNewAlert({
					type: "success",
					message: "Successfully imported CSV file.",
				});
				handleGetIndividualCredits();
				setCsvStatus(CSVStatus.Complete);
			})
			.catch((e) => {
				if (e?.response?.status == 400) {
					let badRequestMessage = e.response.data[0];

					addNewAlert({
						type: "error",
						message: badRequestMessage,
					});
					setCsvStatus(CSVStatus.Idle);

					return;
				}

				if (e?.response?.status == 500 && e.response?.data?.detail) {
					let detail = e.response?.data?.detail;

					addNewAlert({
						type: "error",
						message: detail,
					});
					setCsvStatus(CSVStatus.Idle);
					return;
				}

				addNewAlert({
					type: "error",
					message: "An error occurred, please try again.",
				});
				setCsvStatus(CSVStatus.Idle);
			});
	};
	useEffect(() => {
		if (csvStatus === CSVStatus.Complete) {
			setTimeout(() => {
				setCsvStatus(CSVStatus.Idle);
			}, 500);
		}
	}, [csvStatus]);

	useEffect(() => {
		handleGetIndividualCredits();
	}, [props.companyId, props.refreshCredits]);

	return (
		<FormikProvider value={formikProps}>
			<LibraryContainer>
				<FileUpload
					className="fileUploadBg"
					id="FileUpload-IndividualCreditList"
					onChange={(files) => handleFileUpload(files)}
					allowedMediaType={"csv"}
					fileTypeName={"CSV"}
					multiple={false}
					hideUpload={true}
					whileLoading={csvStatus !== CSVStatus.Idle}
					resetAfterOnChange={true}
					children={
						<div className="relative w-full">
							<SpinnerContainer
								show={csvStatus !== CSVStatus.Idle}
								background={theme.colorBackgroundLightLight}
							>
								{csvStatus !== CSVStatus.Idle && (
									<UploadProgress uploadProgress={csvStatus} />
								)}
							</SpinnerContainer>
						</div>
					}
				/>
				<FilterCreditLibrary
					credits={individualCredits}
					setCredits={(credits) => setFilteredCredits(credits)}
					creditType={CreditType.Individual}
				/>

				<p className="m-[2rem] mb-0">
					{assetsConfig.copy.creditLibraryInstructions}
				</p>

				<LibraryInnerContainer>
					<SortCreditLibrary
						credits={filteredCredits} // filter then sort
						setCredits={(credits) => setSortedCredits([...credits])}
						creditType={CreditType.Individual}
					/>

					{showTagModal && state.items.length && (
						<TagSelectionModal
							closeModal={(tags, cancelled) => {
								if (!cancelled) {
									handleTagSelected(tags, state.items);
								}
								setShowTagModal(false);
							}}
							companyId={props.companyId}
							inputTags={commonTags}
							title="Add Tags to Credits"
						/>
					)}

					<IndividualCreditContainer>
						{sortedCredits.map((credit: IndividualCredit) => (
							<SelectableContainer
								state={state}
								table={true}
								item={credit}
								key={credit.id}
								onClick={(event) => {
									if (event.shiftKey) {
										handleSelectItem(credit);
										window?.getSelection()?.removeAllRanges();
									}
								}}
							>
								<CreditFieldContainer className="!pl-[2rem]">
									<span className="font-bold">{credit.fullName}</span>
									<span>{credit.email}</span>
								</CreditFieldContainer>
								<CreditFieldContainer>
									<span className="font-bold">{credit.companyName}</span>
									<span>
										{credit.jobTitle === "Other"
											? credit.otherJobTitle
											: credit.jobTitle}
									</span>
								</CreditFieldContainer>
								<CreditFieldContainer>
									<span className="font-bold">{credit.companyCity}</span>
									<span>{credit.country}</span>
								</CreditFieldContainer>

								<div className="flex items-center gap-[1rem] pr-[2rem]">
									{credit.isActive ? (
										<p className="font-semibold text-xxSmallSize w-[50px]">
											Active
										</p>
									) : (
										<p className="text-colorCopyLight text-xxSmallSize w-[50px]">
											Inactive
										</p>
									)}
									<Icon
										icon="trash"
										color={theme.colorPrimary}
										onClick={() => handleDeleteIndividualCredit(credit)}
									/>
									<Icon
										icon="edit"
										color={theme.colorPrimary}
										width="20px"
										height="20px"
										onClick={() => handleEditIconClick(credit)}
									/>
								</div>
							</SelectableContainer>
						))}
						{showIndividualCreditDetails && creditForm && (
							<CreditDetails
								companyId={props.companyId}
								credit={creditForm}
								creditType={CreditType.Individual}
								onClickHide={() => setShowIndividualCreditDetails(false)}
								refreshCredits={handleGetIndividualCredits}
								isAdmin={props.isAdmin}
							/>
						)}
					</IndividualCreditContainer>
				</LibraryInnerContainer>
				<BottomBar show={state.items && state.items.length ? true : false}>
					<p className={`bar-text`}>{`${state.items.length} File${
						state.items.length > 1 ? "s" : ""
					} Selected`}</p>
					<ToggleSwitchField
						id="allActive"
						className="mx-[1.5rem]"
						name="allActive"
						disabled={state.status === PENDING}
						checked={allActive}
						label={allActive ? "Active" : "Inactive"}
						small
						onClick={() => handleToggleActiveSelected(!allActive, state.items)}
					/>
					<Button
						className="button-transparent flex-col w-[175px] max-w-[175px]"
						onClick={() =>
							state.status === PENDING ? {} : handleDeleteSelected()
						}
						icon="trash"
						iconSize="20px"
						iconColor={theme.colorPrimary}
					>
						Delete
					</Button>
					<Button
						className="button-transparent flex-col w-[175px] max-w-[175px]"
						onClick={() =>
							state.status === PENDING ? {} : setShowTagModal(true)
						}
						icon="tag"
						iconSize="20px"
						iconColor={theme.colorPrimary}
					>
						Tag
					</Button>
					<Button
						className="button-transparent flex-col w-[175px] max-w-[175px]"
						onClick={() => loadItems([])}
						icon="deselect"
						iconSize="20px"
						iconColor={theme.colorPrimary}
					>
						Deselect All
					</Button>
				</BottomBar>
			</LibraryContainer>
		</FormikProvider>
	);
};

export default IndividualCreditList;
