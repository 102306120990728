import { useTheme } from "styled-components";
import Modal, { ModalCard } from "../../components/Modal/Modal";
import { Tag } from "../MediaLibrary/mediaLibrary.model";
import Icon from "../../components/Icon/Icon";
import TextField from "../../components/FormFields/TextField";
import { FormikProvider, useFormik } from "formik";
import TagSelection, {
	tagFieldRequired,
} from "../../components/Tags/TagSelection";
import Button from "../../components/Button/Button";
import * as Yup from "yup";
import { useContext } from "react";
import { addTag } from "./manageTags";
import { useAlert } from "../../components/Alert/Alerts";

const AddTagModal = (props: AddTagModalProps) => {
	const theme = useTheme();
	const { addNewAlert } = useAlert();
	const formikProps = useFormik({
		initialValues: { newTag: "" },
		onSubmit: async (values) => {
			handleAddTag(values.newTag);
		},
		validationSchema: Yup.object({
			newTag: Yup.string()
				.trim()
				.required(tagFieldRequired)
				.min(1)
				.test("checkForDuplicates", "This tag already exists", (val) => {
					const isDuplicate = props.tagArr.some((tagObj) => tagObj.tag === val);

					return val === undefined || isDuplicate ? false : true;
				}),
			// .max(60, "Max tag length is 60 characters"),
		}),
		validateOnBlur: false,
		validateOnChange: false,
	});

	const handleAddTag = (text: string) => {
		const newTag: Tag = {
			companyId: props.companyId,
			id: 0,
			tag: text,
		};

		addTag(props.companyId, newTag).then((response) => {
			if (response.status === 201) {
				props.refreshTags();
				props.onClickHide();

				addNewAlert({
					type: "success",
					message: "Successfully created new tag",
				});
				formikProps.resetForm();
			}
		});
	};

	return (
		<FormikProvider value={formikProps}>
			<Modal show={props.show}>
				<ModalCard
					title="Add Tags"
					headerIcons={
						<Icon
							icon="close"
							color={theme.colorPrimary}
							onClick={() => {
								formikProps.resetForm();
								props.onClickHide();
							}}
							width="35px"
							height="35px"
						/>
					}
					iconColor={theme.colorPrimary}
				>
					<div className="flex flex-col gap-[2rem] p-[2rem]">
						<TextField
							className="w-full"
							name="newTag"
							max={60}
							type="string"
							placeholder="Add a tag"
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									formikProps.submitForm();
								}
							}}
						/>
						<Button
							type="button"
							className="w-[200px] button-gold ml-auto"
							icon="plus"
							iconColor={theme.colorPrimary}
							onClick={formikProps.submitForm}
							disabled={formikProps.errors.newTag ? true : false}
						>
							Add Tag
						</Button>
					</div>
				</ModalCard>
			</Modal>
		</FormikProvider>
	);
};

export default AddTagModal;

interface AddTagModalProps {
	show: boolean;
	onClickHide(): void;
	refreshTags(): void;
	companyId: number;
	tagArr: Tag[]; // existing tags
}
