import { Field } from "formik";
import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import CheckMark from "../../assets/icons/Check-white.svg";
import { useFormikContext } from "formik";
import { string } from "yup/lib/locale";
import { FieldError } from "./FieldTemplate";
import FieldWrapper from "./FieldWrapper";

const FormField = styled.div<{ disabled: boolean }>`
	display: flex;
	align-items: center;
`;

const CheckBoxCSS = css`
	position: relative;
	margin-right: 0.5rem;
	width: 20px;
	height: 20px;
	cursor: pointer;

	&:before {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		content: "";
		display: inline-block;
		width: 100%;
		height: 100%;
		background: white;
		border: solid 1px ${({ theme }) => theme.colorCopyLight};
	}
`;

export const Checked = css`
	&:before {
		content: "";
		background: url(${CheckMark}) center / 70% no-repeat,
			${({ theme }) => theme.colorActivation};
		text-align: center;
		color: ${({ theme }) => theme.colorBackgroundLightLight};
		font-size: 0.65rem;
		border: transparent;
	}
`;

const PartiallyChecked = css`
	&:before {
		content: "__";
		background: ${({ theme }) => theme.colorActivation};
		text-align: center;
		color: ${({ theme }) => theme.colorBackgroundLightLight};
		font-size: 0.65rem;
		border: transparent;
	}
`;

export const FakeCheckbox = styled.div<{ checked?: boolean }>`
	${CheckBoxCSS};

	${(p) =>
		p.checked &&
		css`
			&: {
				${Checked};
			}
		`};
`;

export const RegularCheckBox = styled.input<{
	partialcheck?: boolean;
	checked?: boolean;
}>`
	${CheckBoxCSS};

	/* disabled state */
	${(p) =>
		(p.readOnly || p.disabled) &&
		css`
			cursor: default;
			&:checked:before {
				background: url(${CheckMark}) center / 70% no-repeat,
					${p.theme.colorFieldReadOnly};
			}

			&:before {
				background: ${p.theme.colorFieldReadOnly};
			}
		`}

	${(p) =>
		p.checked &&
		css`
			&: {
				${Checked};
			}
		`};

	/* partially checked state */
	${(p) =>
		p.partialcheck &&
		css`
			${PartiallyChecked};
		`};
`;

export const CheckBox = styled(Field)<{ partialcheck?: boolean }>`
	${CheckBoxCSS};

	/* disabled state */
	${(p) =>
		(p.readOnly || p.disabled) &&
		css`
			cursor: default;
			&:checked:before {
				background: url(${CheckMark}) center / 70% no-repeat,
					${p.theme.colorFieldReadOnly};
			}

			&:before {
				background: ${p.theme.colorFieldReadOnly};
			}
		`}

	/* partially checked state */
	${(p) =>
		p.partialcheck &&
		css`
			${PartiallyChecked};
		`};

	${(p) =>
		p.checked &&
		css`
			&: {
				${Checked};
			}
		`};
`;

const Label = styled.label<{ disabled?: boolean }>`
	font-size: ${({ theme }) => theme.h5Size};
	color: ${({ theme }) => theme.colorCopyDarkDark};

	/* disabled OR read only state */
	${(p) =>
		p.disabled &&
		`
      cursor: default;
      color: ${p.theme.colorLabelDisabled} !important;
    `}
`;

const CheckboxField = (props: CheckboxFieldProps) => {
	const { values, setFieldValue, errors } = useFormikContext<any>();
	const [checked, setChecked] = useState(props.checked || false);
	const checkBoxRef = useRef<any>(null);

	const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		// prevent double clicking
		e.stopPropagation();
		e.preventDefault();

		const newCheckedValue = !checked;

		// Call the passed callback function with the new value
		props.onCheckedChanged && props.onCheckedChanged(newCheckedValue);

		// Updating the local state and formik value afterward

		setChecked(newCheckedValue);
		props.onChange && props.onChange(newCheckedValue);
		!props.nonFormik && setFieldValue(props.name, newCheckedValue);
	};

	useEffect(() => {
		if (!props.nonFormik) {
			props.checked !== undefined && setFieldValue(props.name, props.checked); // set formik value
		} else {
			props.checked !== undefined && setChecked(props.checked);
		}

		// props.onSetChecked &&
		// 	props.checked !== undefined &&
		// 	props.onSetChecked(props.checked);
	}, [props.checked]);

	return props.nonFormik ? (
		<FieldWrapper
			className={`flex items-center form-field cursor-pointer ${
				props.className ? props.className : ""
			}`}
			name={props.name}
			success={props.success}
			error={props.error}
			{...(!props.disabled && {
				onClick: handleClick,
			})}
		>
			{() => (
				<>
					<RegularCheckBox
						id={"Checkbox." + props.name}
						aria-label={props.placeholder}
						name={props.name}
						type="checkbox"
						disabled={props.disabled}
						checked={checked}
						onChange={() => {}}
						partialcheck={props.partialCheck}
					/>
					<Label
						className="cursor-pointer"
						htmlFor={"Checkbox." + props.name}
						disabled={props.disabled}
					>
						{props.placeholder}
					</Label>
				</>
			)}
		</FieldWrapper>
	) : (
		<FieldWrapper
			className={props.className}
			name={props.name}
			success={props.success}
			error={props.error}
			{...(!props.disabled && {
				onClick: handleClick,
			})}
		>
			{() => (
				<FormField disabled={props.disabled ? props.disabled : false}>
					<CheckBox
						id={"Checkbox." + props.name}
						aria-label={props.label}
						name={props.name}
						type="checkbox"
						disabled={props.disabled}
						checked={
							values[props.name] || props.checked // typically Formik will handle the checked state, but this can be used for deeply nested checkbox fields (e.g. FieldArray) that cannot be accessed by values[props.name]
						}
						onChange={() => {}}
						partialcheck={props.partialCheck}
					/>
					<Label
						className="cursor-pointer"
						htmlFor={props.name}
						disabled={props.disabled}
					>
						{props.placeholder}
					</Label>
				</FormField>
			)}
		</FieldWrapper>
	);
};

export default CheckboxField;

interface CheckboxFieldProps {
	name: string;
	placeholder?: string;
	label?: string;
	style?: string;
	disabled?: boolean;
	color?: String;
	className?: string;
	checked?: boolean;
	partialCheck?: boolean; // a partially checked state
	onChange?: (isChecked: boolean) => void;
	nonFormik?: boolean;
	success?: string;
	error?: string;
	onCheckedChanged?: (newValue: boolean) => void;
}
