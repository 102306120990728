import { useState, useEffect, useRef, useContext } from "react";
import useFileHandlers, {
	ALLOWED_MEDIA_TYPES,
	MEDIA_UPLOAD_ERR,
	api,
} from "../../../hooks/useFileHandlers";

import { FileDrop } from "react-file-drop";
import {
	FileObj,
	SpinnerContainer,
} from "../../../components/LocalFileUpload.tsx/LocalFileUpload";
import { StyledFileUploadNew } from "../../../components/FormFields/FileUpload";
import Icon from "../../../components/Icon/Icon";
import styled, { useTheme } from "styled-components";
import {
	ThumbnailSize,
	checkS3ForImg,
} from "../../MediaLibrary/manageMediaLibrary";
import { bytesToGB } from "../../../utils/KBtoMB";
import {
	MediaItem,
	MediaType,
	Tag,
} from "../../MediaLibrary/mediaLibrary.model.d";
import { allProgress } from "./allProgress";
import ProgressCircle from "../../../components/ProgressCircle/ProgressCircle";
import axios from "axios";
import { allProgressSettled } from "./allProgressSettled";
import TagSelectionModal from "../../../components/Tags/TagSelectionModal";
import config from "../../../config";
import { useAlert } from "../../../components/Alert/Alerts";

const getAcceptedMediaTypes = (excludeCsv: boolean) => {
	if (excludeCsv) {
		return ALLOWED_MEDIA_TYPES.filter((type) => type !== "text/csv").join(", ");
	}
	return ALLOWED_MEDIA_TYPES.join(", ");
};

export const UploadProgress = (props: {
	filesUploaded: number;
	thumbnailLoaded: number;
	fileCount: number;
}) => {
	const [startUploadProgress, setStartUploadProgress] = useState(0);
	const fileUploadedPercent = props.filesUploaded / props.fileCount;
	const thumbnailUploadedPercent = props.thumbnailLoaded / props.fileCount;

	const totalProgress =
		Number(
			(
				(fileUploadedPercent * 0.7 + thumbnailUploadedPercent * 0.3) *
				100
			).toFixed(2)
		) || startUploadProgress;

	const statusMessage =
		totalProgress === 100
			? "Finalizing"
			: fileUploadedPercent === 1
			? "Generating Thumbnail"
			: "Uploading Media";

	useEffect(() => {
		setTimeout(() => {
			setStartUploadProgress(20);
		}, 250);
	}, []);

	return (
		<div className="flex gap-[2rem] items-center">
			<ProgressCircle
				value={Math.ceil(totalProgress)}
				text={`${Math.ceil(totalProgress)}%`}
				size="90px"
				showFullProgress
			/>
			<div className="flex flex-col gap-[.5rem] text-left">
				<h2>Upload Progress</h2>
				<b>
					{props.filesUploaded}/{props.fileCount} Files Uploaded
				</b>
				<p>{statusMessage + "..."}</p>
				<p>
					You have an upload in progress. <br /> If you leave this page, your
					upload progress will be lost.
				</p>
			</div>
			<img
				className="!w-[90px]"
				src={config.assets.loading.tertiary}
				alt="loading..."
			/>
		</div>
	);
};

// a general media upload field, currently used in media library
const MediaUpload = (props: MediaUploadProps) => {
	const theme = useTheme();
	const fileInputRef = useRef<any>(null);
	const { addNewAlert } = useAlert();
	const {
		files,
		pending,
		next,
		uploading,
		uploaded,
		status,
		onSubmit,
		onChange,
		reset,
	} = useFileHandlers({ companyId: props.companyId });

	const [showLoading, setShowLoading] = useState(false);
	const [showTagModal, setShowTagModal] = useState(false);

	// for progress indicator
	const [filesUploaded, setFilesUploaded] = useState<number>(0);
	const [thumbnailLoaded, setThumbnailLoaded] = useState(0);
	//   const [loadingMessage, setLoadingMessage] = useState<null | string>(null);

	const upload = async (tags: Tag[]) => {
		setShowLoading(true);
		props.uploadFileMetadata &&
			props.uploadFileMetadata(
				files.map((file: FileObj) => ({
					id: file.id,
					type: file.file.type,
					fileName: file.file.name,
				}))
			);

		let uploadFilePromises: any[] = [];
		files.forEach((fileObj: FileObj, i: number) => {
			uploadFilePromises.push(
				api.uploadFile(fileObj.file, props.companyId, tags)
			);
		});

		// wait for file upload to settle (success or failure)
		const results = await allProgressSettled(
			uploadFilePromises,
			(fileCount) => setFilesUploaded(fileCount)
			// setLoadingMessage(`${filesUploaded}/${files.length} Files Uploaded`)
		);

		// check if any promises were rejected
		const anyRejected = results.some((result) => result.status === "rejected");
		const anySuccessfull = results.some(
			(result) => result.status === "fulfilled"
		);

		if (anySuccessfull) {
			const imgThumbnailPromises: any[] = [];

			results.forEach((result) => {
				if (result.status === "fulfilled") {
					// push promise to check for img thumbnail
					if (
						result.value.data.type === MediaType.Image ||
						result.value.data.type === MediaType.Video
					) {
						imgThumbnailPromises.push(
							checkS3ForImg(
								result.value.data,
								ThumbnailSize.LARGE,
								result.value.data.type
							)
						);
					} else {
						// push resolved promise
						imgThumbnailPromises.push(
							new Promise((resolve) => resolve(result.value.data))
						);
					}
				}
			});

			// wait for S3 to resolve thumbnails before rendering dropped image card
			allProgress(imgThumbnailPromises, (thumbnailCount) =>
				setThumbnailLoaded(thumbnailCount)
			)
				.then(() => {
					props.onUploadSuccess();

					// reset loading states, small delay for the progress animation
					setTimeout(() => {
						setShowLoading(false);
						setFilesUploaded(0);
						setThumbnailLoaded(0);
						reset();
					}, 250);
				})
				.catch((e) => {
					// media is uploaded but thumbnail generation timed out
					props.onUploadSuccess();
					setShowLoading(false);
					reset();
				});
		}

		if (anyRejected) {
			const rejectedPromises = results.filter(
				(result) => result.status === "rejected"
			);

			let errorMessage = MEDIA_UPLOAD_ERR;

			for (const rejectedPromise of rejectedPromises) {
				if (
					rejectedPromise.status === "rejected" &&
					axios.isAxiosError(rejectedPromise.reason)
				) {
					if (rejectedPromise.reason.response?.status === 409) {
						errorMessage = "Some file(s) already exists!";
						break;
					}
				}
			}

			addNewAlert({
				type: "error",
				message: errorMessage,
			});

			if (!anySuccessfull) {
				setShowLoading(false);
				reset();
				props.onUploadError && props.onUploadError();
			}
		}
	};

	useEffect(() => {
		if (files.length > 0) {
			// const fileSizeinGB = bytesToGB(files[0].file.size);

			// if (fileSizeinGB > 1.5) {
			// 	addNewAlert({
			// 		type: "error",
			// 		message: FILE_SIZE_MESSAGE,
			// 	});
			// } else {
			// 	setShowTagModal(true);
			// }

			setShowTagModal(true);
		} else {
			setShowLoading(false);
		}
	}, [files]);

	// send trigger to media lib, isUploading will be false once getCompanyMedia() is finished
	useEffect(() => {
		if (props.isUploading && showLoading) props.isUploading(true);
	}, [showLoading]);

	return (
		<div className="relative">
			{showTagModal && files.length && (
				<TagSelectionModal
					closeModal={(tags, cancelled) => {
						if (!cancelled) {
							upload(tags);
						}
						setShowTagModal(false);
					}}
					companyId={props.companyId}
					inputTags={[]}
					title="Add Tags to Uploaded Media"
				/>
			)}
			<StyledFileUploadNew
				className={`flex h-full border-none border-[1px] border-[lightgrey]`}
				fill={theme.colorBackgroundLightLight}
				disabled={showLoading}
			>
				<FileDrop
					onDrop={(droppedFiles, e) => onChange(droppedFiles)}
					onTargetClick={() => fileInputRef.current.click()}
				>
					<p>Drag & Drop or Click to Upload Media</p>

					<input
						className="hidden"
						type="file"
						accept={getAcceptedMediaTypes(true)}
						name="img-loader-input"
						multiple
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							onChange(e.target.files);

							fileInputRef.current.value = null; // reset file input after "onChange" (checks the file and uploads)
						}}
						ref={fileInputRef}
						disabled={showLoading}
					/>
					<div className="flex items-center gap-[.5rem] mt-[1rem]">
						<Icon
							className="search-icon"
							icon="search"
							color={theme.colorPrimary}
							width="15px"
							height="15px"
						/>
						<p className="underline text-colorPrimary font-semibold">
							Browse Files
						</p>
					</div>

					<SpinnerContainer
						show={showLoading}
						background={theme.colorBackgroundLightLight}
					>
						{showLoading && (
							<UploadProgress
								filesUploaded={filesUploaded}
								thumbnailLoaded={thumbnailLoaded}
								fileCount={files.length}
							/>
						)}
					</SpinnerContainer>

					{/* <SpinnerContainer show={showLoading}>
            <img src={Spinner} alt="loading..." />
          </SpinnerContainer> */}
				</FileDrop>
			</StyledFileUploadNew>
			{/* show custom error or formik error */}
			{/* {isMinNotDropped ? (
        <FieldError>{isMinNotDropped}</FieldError>
      ) : (
        errors &&
        errors[props.name] && <FieldError>{errors[props.name]}</FieldError>
      )} */}
		</div>
	);
};

export default MediaUpload;

interface MediaUploadProps {
	onUploadSuccess: () => void;
	onUploadError?: () => void;
	onThumbnailCreated?(): void;
	isUploading?(uploading: boolean): void;
	uploadFileMetadata?(metadata: FileMetaData[]): void;
	companyId: number;
}

export interface FileMetaData {
	id: number;
	fileName: string;
	type: string;
}
