import DashboardCard from "../../../components/DashboardCard/DashboardCard";
import {
	NavTabs,
	NavTab,
	NavTabList,
	NavTabPanel,
} from "../../../components/NavTabs/NavTabs";
import {
	CardContainer,
	CardHeader,
	CardBody,
} from "../../../components/DashboardCard/DashboardCard";
import SearchBar from "../../../components/SearchBar/SearchBar";
import { ConfigNavTabs } from "./ProgramConfig";
import { ProgramHierarchyContainer } from "./ProgramRootAdminNav";
import Button from "../../../components/Button/Button";
import styled, { useTheme } from "styled-components";
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import { SortContainer } from "../../../components/Sort/Sort";
import Sort from "../../../components/Sort/Sort";
import * as AiIcons from "react-icons/ai";
import dateTostring from "../../../utils/dateToString";

import {
	FieldTemplatesFormData,
	FieldTemplatesFormDataType,
} from "../../../data/FieldTemplatesFormData";
import DateFieldForm from "./FieldTemplateForms/DateFieldForm";
import TextFieldForm from "./FieldTemplateForms/TextFieldTemplate";
import MediaFieldForm from "./FieldTemplateForms/MediaFieldForm";
import CreditFieldForm from "./FieldTemplateForms/CreditFieldForm";
import ListFieldForm from "./FieldTemplateForms/ListFieldForm";
import LinkFieldForm from "./FieldTemplateForms/LinkFieldForm";
import PhysicalComponentFieldForm from "./FieldTemplateForms/PhysicalComponentFieldForm";

// import DeadlineField from "./FieldTemplateForms/Deadline";
// import EntrantCreditField from "./FieldTemplateForms/EntrantCredit";
// import EntryNameField from "./FieldTemplateForms/EntryName";
// import EntryTypeSpecialJudgingField from "./FieldTemplateForms/EntryTypeSpecialJudging";
// import OpenCloseDateField from "./FieldTemplateForms/OpenCloseDate";
// import PermissionFormUploadField from "./FieldTemplateForms/PermissionFormUpload";
// import EligibilityPeriodField from "./FieldTemplateForms/EligibilityPeriod";
// import CampaignField from "./FieldTemplateForms/CampaignField";
// import CountryOfOriginField from "./FieldTemplateForms/CountryOfOrigin";
// import CoverImageField from "./FieldTemplateForms/CoverImage";
// import FieldTemplateList from "./FieldTemplateList";

import RenderTemplateTab from "./FieldTemplateTabs/RenderTemplateTab";
import {
	CreditFieldTemplate,
	DateFieldTemplate,
	ListFieldTemplate,
	TextFieldTemplate,
	MediaFieldTemplate,
	PhysicalComponentFieldTemplate,
	LinkFieldTemplate,
} from "./ProgramInterfaces";
import { useHistory, useLocation } from "react-router-dom";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";

const FieldTemplates = () => {
	const [showFieldModal, setShowFieldModal] = useState(false);
	const [modalForm, setModalForm] = useState("");
	const [reloadTab, setReloadTab] = useState(new Date().getTime()); // use date as unique key to reload field templates

	// reload field templates when template is created/updated
	const handleCloseModal = (updateId: any) => {
		const test = new Date().getTime();
		setReloadTab(test);
		setShowFieldModal(false);
	};

	// map each field form to its component
	const formFieldList: {
		// "formName" must be in "FieldTemplatesFormDataType" keys
		[formName in FieldTemplatesFormDataType as string]: {
			formComponent: React.ReactNode;
		};
	} = {
		dateFieldForm: {
			formComponent: (
				templateData?: DateFieldTemplate // update form if template data exists
			) => (
				<DateFieldForm
					key={new Date().getTime()} // re-renders component when isNewField changes
					{...(templateData && {
						formData: templateData,
					})} // edit template if formdata is passed in
					hideModal={() =>
						handleCloseModal(
							templateData ? templateData.id : String(new Date())
						)
					}
				/>
			),
		},
		textFieldForm: {
			formComponent: (templateData?: TextFieldTemplate) => (
				<TextFieldForm
					//   key={new Date().getTime()}
					key={new Date().getTime()}
					{...(templateData && {
						formData: templateData,
					})}
					hideModal={() =>
						handleCloseModal(
							templateData ? templateData.id : String(new Date())
						)
					}
				/>
			),
		},
		mediaFieldForm: {
			formComponent: (templateData?: MediaFieldTemplate) => (
				<MediaFieldForm
					key={new Date().getTime()}
					{...(templateData && {
						formData: templateData,
					})}
					hideModal={() =>
						handleCloseModal(
							templateData ? templateData.id : String(new Date())
						)
					}
				/>
			),
		},
		creditFieldForm: {
			formComponent: (templateData?: CreditFieldTemplate) => (
				<CreditFieldForm
					key={new Date().getTime()}
					{...(templateData && {
						formData: templateData,
					})}
					hideModal={() =>
						handleCloseModal(
							templateData ? templateData.id : String(new Date())
						)
					}
				/>
			),
		},
		listForm: {
			formComponent: (
				templateData?: ListFieldTemplate // update form if template data exists
			) => (
				<ListFieldForm
					key={new Date().getTime()} // re-renders component when isNewField changes
					{...(templateData && {
						formData: templateData,
					})}
					hideModal={() =>
						handleCloseModal(
							templateData ? templateData.id : String(new Date())
						)
					}
				/>
			),
		},
		externalLinkForm: {
			formComponent: (templateData?: LinkFieldTemplate) => (
				<LinkFieldForm
					key={new Date().getTime()}
					{...(templateData && {
						formData: templateData,
					})}
					hideModal={() =>
						handleCloseModal(
							templateData ? templateData.id : String(new Date())
						)
					}
				/>
			),
		},
		physicalEntriesForm: {
			formComponent: (templateData?: PhysicalComponentFieldTemplate) => (
				<PhysicalComponentFieldForm
					key={new Date().getTime()}
					{...(templateData && {
						formData: templateData,
					})}
					hideModal={() =>
						handleCloseModal(
							templateData ? templateData.id : String(new Date())
						)
					}
				/>
			),
		},
		// eligibilityPeriodForm: {
		// 	formComponent: (
		// 		<EligibilityPeriodField
		// 			formData={FieldTemplatesFormData.eligibilityPeriodForm}
		// 			hideModal={() => handleCloseModal(templateData ? templateData.id : String(new Date()))}
		// 			isFieldsUnlockable={
		// 				FieldTemplatesFormData.eligibilityPeriodForm.isFieldsUnlockable
		// 			}
		// 		/>
		// 	),
		// },

		// entryTypeSpecialJudgingForm: {
		// 	formComponent: (
		// 		<EntryTypeSpecialJudgingField
		// 			formData={FieldTemplatesFormData.entryTypeSpecialJudgingForm}
		// 			hideModal={() => handleCloseModal(templateData ? templateData.id : String(new Date()))}
		// 			isFieldsUnlockable={
		// 				FieldTemplatesFormData.entryTypeSpecialJudgingForm.isFieldsUnlockable
		// 			}
		// 		/>
		// 	),
		// },
		// countryOfOriginForm: {
		// 	formComponent: (
		// 		<CountryOfOriginField
		// 			formData={FieldTemplatesFormData.countryOfOriginForm}
		// 			hideModal={() => handleCloseModal(templateData ? templateData.id : String(new Date()))}
		// 			isFieldsUnlockable={
		// 				FieldTemplatesFormData.countryOfOriginForm.isFieldsUnlockable
		// 			}
		// 		/>
		// 	),
		// },

		// openCloseDateForm: {
		// 	formComponent: (
		// 		<OpenCloseDateField
		// 			formData={FieldTemplatesFormData.openCloseDateForm}
		// 			hideModal={() => handleCloseModal(templateData ? templateData.id : String(new Date()))}
		// 			isFieldsUnlockable={
		// 				FieldTemplatesFormData.openCloseDateForm.isFieldsUnlockable
		// 			}
		// 		/>
		// 	),
		// },
		// deadlineForm: {
		// 	formComponent: (
		// 		<DeadlineField
		// 			formData={FieldTemplatesFormData.deadlineForm}
		// 			hideModal={() => handleCloseModal(templateData ? templateData.id : String(new Date()))}
		// 			isFieldsUnlockable={
		// 				FieldTemplatesFormData.deadlineForm.isFieldsUnlockable
		// 			}
		// 		/>
		// 	),
		// },
		// entrantCreditForm: {
		// 	formComponent: (
		// 		<EntrantCreditField
		// 			formData={FieldTemplatesFormData.entrantCreditForm}
		// 			hideModal={() => handleCloseModal(templateData ? templateData.id : String(new Date()))}
		// 			isFieldsUnlockable={
		// 				FieldTemplatesFormData.entrantCreditForm.isFieldsUnlockable
		// 			}
		// 		/>
		// 	),
		// },
		// coverImageForm: {
		// 	formComponent: (
		// 		<CoverImageField
		// 			formData={FieldTemplatesFormData.coverImageForm}
		// 			hideModal={() => handleCloseModal(templateData ? templateData.id : String(new Date()))}
		// 			isFieldsUnlockable={
		// 				FieldTemplatesFormData.coverImageForm.isFieldsUnlockable
		// 			}
		// 		/>
		// 	),
		// },
		// entryNameForm: {
		// 	formComponent: (
		// 		<EntryNameField
		// 			formData={FieldTemplatesFormData.entryNameForm}
		// 			hideModal={() => handleCloseModal(templateData ? templateData.id : String(new Date()))}
		// 			isFieldsUnlockable={
		// 				FieldTemplatesFormData.entryNameForm.isFieldsUnlockable
		// 			}
		// 		/>
		// 	),
		// },

		// campaignFieldForm: {
		// 	formComponent: (
		// 		<CampaignField
		// 			formData={FieldTemplatesFormData.campaignFieldForm}
		// 			hideModal={() => handleCloseModal(templateData ? templateData.id : String(new Date()))}
		// 			isFieldsUnlockable={
		// 				FieldTemplatesFormData.campaignFieldForm.isFieldsUnlockable
		// 			}
		// 		/>
		// 	),
		// },
		// permissionFormUpload: {
		// 	formComponent: (
		// 		<PermissionFormUploadField
		// 			formData={FieldTemplatesFormData.permissionFormUpload}
		// 			hideModal={() => handleCloseModal(templateData ? templateData.id : String(new Date()))}
		// 			isFieldsUnlockable={
		// 				FieldTemplatesFormData.permissionFormUpload.isFieldsUnlockable
		// 			}
		// 		/>
		// 	),
		// },
	};

	const handleModal = (formName: string, templateData?: any) => {
		const formField = formFieldList[formName];

		try {
			if (typeof formField.formComponent === "function") {
				// render edit/new field modal, depending on the button clicked
				setModalForm(
					formField.formComponent(templateData ? templateData : undefined)
				);
			} else {
				// console.log("not form component", typeof formField.formComponent);
			}
		} catch (e) {
			// console.log(e);
		}
	};
	console.log({ modalForm });

	useEffect(() => {
		modalForm && setShowFieldModal(!showFieldModal);
	}, [modalForm]);

	return (
		<CardContainer>
			<BrowserHeader title={`Field Templates`} />
			<Modal show={showFieldModal}>{modalForm}</Modal>

			<CardHeader>
				<h2 className="card-title">Field Templates</h2>
				{/* <SearchBar /> */}
			</CardHeader>
			<NavTabs>
				<ConfigNavTabs>
					<NavTab>Credits</NavTab>
					<NavTab>Dates</NavTab>
					<NavTab>List</NavTab>
					<NavTab>Text</NavTab>
					<NavTab>Link</NavTab>
					<NavTab>Media</NavTab>
					<NavTab>Physical Components</NavTab>
				</ConfigNavTabs>

				<NavTabPanel>
					<RenderTemplateTab
						key={reloadTab}
						formFieldType="creditFieldForm"
						onClickEditField={(templateData: CreditFieldTemplate) =>
							handleModal("creditFieldForm", templateData)
						}
						onClickNewField={(formFieldType: string) =>
							handleModal("creditFieldForm")
						}
					/>
				</NavTabPanel>
				<NavTabPanel>
					<RenderTemplateTab
						key={reloadTab}
						formFieldType="dateFieldForm"
						onClickEditField={(templateData: DateFieldTemplate) =>
							handleModal("dateFieldForm", templateData)
						}
						onClickNewField={(formFieldType: string) =>
							handleModal("dateFieldForm")
						}
					/>
				</NavTabPanel>
				<NavTabPanel>
					<RenderTemplateTab
						key={reloadTab}
						formFieldType="listForm"
						onClickEditField={(templateData: ListFieldTemplate) =>
							handleModal("listForm", templateData)
						}
						onClickNewField={(formFieldType: string) => handleModal("listForm")}
					/>
				</NavTabPanel>
				<NavTabPanel>
					<RenderTemplateTab
						key={reloadTab}
						formFieldType="textFieldForm"
						onClickEditField={(templateData: TextFieldTemplate) =>
							handleModal("textFieldForm", templateData)
						}
						onClickNewField={(formFieldType: string) =>
							handleModal("textFieldForm")
						}
					/>
				</NavTabPanel>

				<NavTabPanel>
					<RenderTemplateTab
						key={reloadTab}
						formFieldType="externalLinkForm"
						onClickEditField={(templateData: LinkFieldTemplate) =>
							handleModal("externalLinkForm", templateData)
						}
						onClickNewField={(formFieldType: string) =>
							handleModal("externalLinkForm")
						}
					/>
				</NavTabPanel>
				<NavTabPanel>
					<RenderTemplateTab
						key={reloadTab}
						formFieldType="mediaFieldForm"
						onClickEditField={(templateData: MediaFieldTemplate) =>
							handleModal("mediaFieldForm", templateData)
						}
						onClickNewField={(formFieldType: string) =>
							handleModal("mediaFieldForm")
						}
					/>
				</NavTabPanel>
				<NavTabPanel>
					<RenderTemplateTab
						key={reloadTab}
						formFieldType="physicalEntriesForm"
						onClickEditField={(templateData: MediaFieldTemplate) =>
							handleModal("physicalEntriesForm", templateData)
						}
						onClickNewField={(formFieldType: string) =>
							handleModal("physicalEntriesForm")
						}
					/>
				</NavTabPanel>
			</NavTabs>
		</CardContainer>
	);
};

export default FieldTemplates;
