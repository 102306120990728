import { FormikProvider, useFormik } from "formik";
import { useState, useEffect, useContext } from "react";
import { useTheme } from "styled-components";

import {
	duplicate,
	getProgramChildrenByParentId,
	moveEntry,
} from "../../views/Checkout/manageCheckout";
import Button from "../Button/Button";
import DropdownField from "../FormFields/DropdownField";
import { DropdownOptions } from "../FormFields/DropdownField";
import Icon from "../Icon/Icon";
import styled from "styled-components";
import { AxiosResponse } from "axios";
import config from "../../config";
import { useAlert } from "../Alert/Alerts";

const LoadingSpinner = styled.img`
	width: 18px;
	display: inline-flex;
`;

const HierarchyDropDown = (props: HierarchyDropDownProps) => {
	const theme = useTheme();
	const { addNewAlert } = useAlert();
	const [loading, setLoading] = useState(false);
	const [options, setOptions] = useState<HierarchySelection[]>([]);
	const [selection, setSelection] = useState<HierarchySelection>();

	useEffect(() => {
		setOptions([]);
		setSelection(undefined);
		getProgramChildrenByParentId(props.parentId).then((response) => {
			if (response.status === 200) {
				if (response.data.length === 1) {
					setSelection(response.data[0]);
				} else {
					setOptions(response.data);
				}
			}
		});
	}, [props.parentId]);

	const formikProps = useFormik({
		initialValues: {},
		onSubmit: () => {},
	});

	return (
		<FormikProvider value={formikProps}>
			{options && options.length > 1 && (
				<div className="p-[1rem]">
					<DropdownField
						name={options[0].currentLevelDisplayLabel || "Program"}
						// nonFormik
						placeholder={options[0].currentLevelDisplayLabel || "Program"}
						options={options.map((option) => {
							return { label: option.name, value: option.id };
						})}
						onChange={(event) =>
							setSelection(
								options.find((x) => event.target.value == x.id.toString())
							)
						}
					/>
				</div>
			)}

			{selection && !selection.isExecution && (
				<HierarchyDropDown
					entryId={props.entryId}
					parentId={selection.id}
					closeModal={props.closeModal}
					refreshEntries={props.refreshEntries}
					action={props.action}
				/>
			)}

			{selection && selection.isExecution && (
				<Button
					disabled={loading}
					type="button"
					className="w-[200px] m-[1rem] ml-auto"
					onClick={() => {
						setLoading(true);

						switch (props.action) {
							case EntryAction.Duplicate:
								duplicate(selection.parentId!, props.entryId, selection.id)
									.then((response) => {
										if (response.status === 201) {
											console.log("Data! ", response.data);
											setLoading(false);
											props.refreshEntries &&
												addNewAlert({
													type: "success",
													message: "Entry has been duplicated",
												});
											!props.refreshEntries &&
												addNewAlert({
													type: "success",
													message: "Entry has been duplicated to Draft Entries",
												});

											props.refreshEntries && props.refreshEntries();
											props.closeModal();
										} else {
											setLoading(false);
											addNewAlert({
												type: "error",
												message: "Entry could not be Duplicated",
											});
										}
									})
									.catch((e) => {
										setLoading(false);
										addNewAlert({
											type: "error",
											message: "Entry could not be Duplicated",
										});
									});
								break;
							case EntryAction.Move:
								moveEntry(selection.parentId!, props.entryId, selection.id)
									.then((response) => {
										if (response.status === 200) {
											//setLoading(false);
											addNewAlert({
												type: "success",
												message:
													"Entry has been Moved. Reload the page to see changes",
											});
											props.refreshEntries && props.refreshEntries();
											//props.closeModal();
											setTimeout(() => {
												window.location.reload();
											}, 1000);
										} else {
											setLoading(false);
											addNewAlert({
												type: "error",
												message: "Unable to Move Entry",
											});
										}
									})
									.catch((e: any) => {
										setLoading(false);
										console.log("error", e);
										addNewAlert({
											type: "error",
											message: `${e?.response?.data || "Unable to Move Entry"}`,
										});
									});
								break;
							default:
								break;
						}
					}}
				>
					<div className="w-full flex gap-[.5rem] justify-center items-center">
						{loading ? (
							<LoadingSpinner src={config.assets.loading.primary} />
						) : (
							<Icon icon="copy" color={theme.colorCopyLightLight} />
						)}
						{props.action === EntryAction.Duplicate ? "Duplicate" : "Move"}
					</div>
				</Button>
			)}
		</FormikProvider>
	);
};

export default HierarchyDropDown;

interface HierarchyDropDownProps {
	entryId: number;
	parentId: number | null;
	closeModal(): void;
	refreshEntries?(): any;
	action: EntryAction;
}

export interface HierarchySelection {
	id: number;
	name: string;
	currentLevelDisplayLabel?: string;
	isExecution: boolean;
	parentId?: number;
}

export enum EntryAction {
	Duplicate = "Duplicate",
	Move = "Move",
}
