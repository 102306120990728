export const ADD_ALERT = "ADD_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export interface Alert {
	id: string;
	message: string;
	type: "success" | "error" | "alert";
}

export interface AddAlertAction {
	type: typeof ADD_ALERT;
	payload: Alert;
}

export interface RemoveAlertAction {
	type: typeof REMOVE_ALERT;
	payload: string;
}

export type AlertActionTypes = AddAlertAction | RemoveAlertAction;
