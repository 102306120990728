import Button from "../../../components/Button/Button";
import TextField from "../../../components/FormFields/TextField";
import styled, { useTheme } from "styled-components";
import { SeasonModel } from "./Seasons";
import { Formik, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import ToggleSwitchField from "../../../components/FormFields/ToggleSwitchField";
import { urlSeasons } from "../../../endpoints";
import axios from "axios";

const SeasonCard = (props: SeasonCardProps) => {
	const theme = useTheme();
	const [errors, setErrors] = useState<string[]>([]);
	const [isEditing, setIsEditing] = useState(props.isEditing || false);

	async function PostSeason(seasonData: SeasonModel) {
		try {
			setErrors([]);
			// if id is blank, then add season
			// else, update season
			if (!seasonData.id) {
				const response = await axios.post(`${urlSeasons}`, seasonData);
				if (response.status == 201) {
					setIsEditing(false);
					props.onPostSeason && props.onPostSeason(response.data);
					//setSeason(seasonData);
				}
			} else {
				const response = await axios.put(
					`${urlSeasons}/${seasonData.id}`,
					seasonData
				);
				if (response.status == 204) {
					setIsEditing(false);

					//setSeason(seasonData);
				}
			}
		} catch (error: any) {
			// console.log(error);
			setErrors(error.response.data);
		}
	}

	let fieldRequired = "This field is required";

	const formikProps = useFormik({
		initialValues: props.season,
		enableReinitialize: true,
		onSubmit: async (value) => {
			await PostSeason(value);
			// console.log(value);
		},
		validationSchema: Yup.object({
			name: Yup.string().required(fieldRequired),
		}),
		validateOnChange: false,
		validateOnBlur: false,
	});

	return (
		<FormikProvider value={formikProps}>
			<div>
				{isEditing ? (
					<Button
						className="button-light"
						icon="check"
						iconColor={theme.colorActivation}
						disabled={!formikProps.values.name}
						onClick={async () => {
							await PostSeason(formikProps.values);
							// console.log("formik props", formikProps.values);
						}}
					>
						Save
					</Button>
				) : (
					<Button
						className="button-light"
						icon="edit"
						onClick={() => setIsEditing(true)}
						iconColor={theme.colorActivation}
					>
						Edit
					</Button>
				)}
			</div>

			<TextField
				name="name"
				// placeholder="Season Name"
				readOnly={!isEditing}
				disabled={false}
				hiddenlabel={true}
				// hiddenlabel={!isEditing}
				// placeholder="Season Name"
				// onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
				// 	handleEntryData(e, "entrySynopsis")
				// }
				value={props.season.name}
			/>
			{isEditing ? (
				<ToggleSwitchField
					name="isActive"
					id="isActive"
					small
					checked={formikProps.values.isActive}
					onClick={(e: React.ChangeEvent<HTMLFormElement>) => {
						formikProps.setFieldValue("isActive", !formikProps.values.isActive);
					}}
				/>
			) : formikProps.values.isActive ? (
				<p className="active">Active</p>
			) : (
				<p className="inactive">Inactive</p>
			)}
		</FormikProvider>
	);
};

export default SeasonCard;

interface SeasonCardProps {
	season: SeasonModel;
	isEditing?: boolean;
	onPostSeason?(season: SeasonModel): void;
}
