import { Link, useParams } from "react-router-dom";
import CreditLibrary from "../Credits/CreditLibrary";
import { Header, getCompany } from "./CompanyMediaLibrary";
import { CreditType } from "../Program/ProgramInterfaces";
import Button from "../../../components/Button/Button";
import { useTheme } from "styled-components";
import { useEffect, useState } from "react";
import { CompanyModel } from "../../Company/company.model";

const CompanyCreditLibrary = (props: { creditType: CreditType }) => {
	const { companyId } = useParams<{
		companyId?: string;
	}>();

	const [company, setCompany] = useState<CompanyModel | null>(null);
	const theme = useTheme();

	useEffect(() => {
		getCompany(Number(companyId)).then((response) => {
			setCompany(response.data);
		});
	}, [companyId]);

	return (
		<>
			<Header>
				<div>
					<h1 className="playfair-500 mb-[1rem]">
						{CreditType[props.creditType]} Credit Library
					</h1>
					{company && (
						<Link className="link_text" to={`/edit-company/${companyId}`}>
							{company.name} ({companyId})
						</Link>
					)}
				</div>

				<Button
					className="button-gold !text-colorCopyLightLight h-fit"
					icon="credit-db"
					iconColor={theme.colorPrimary}
					iconSize="20px"
					to={`/admin/edit-entry/credit-library/${
						props.creditType === CreditType.Company ? "individual" : "company"
					}/${companyId}`}
				>
					{props.creditType === CreditType.Company
						? "Individual Credit Library"
						: "Company Credit Library"}
				</Button>
			</Header>
			<CreditLibrary
				metaDescription={`Credit Library: [${companyId}] ${company?.name}`}
				creditType={props.creditType}
			/>
		</>
	);
};

export default CompanyCreditLibrary;
