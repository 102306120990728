import styled, { css, useTheme } from "styled-components";
import Button from "../Button/Button";
import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import {
	OrderLineItem,
	OrderModel,
	PaymentStatus,
} from "../../views/Checkout/OrderInterfaces";
import {
	EntryModel,
	ExecutionModel,
} from "../../views/Entries/EntryInterfacesComplete";
import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from "react";
import {
	Ancestor,
	ProgramDeadline,
} from "../../views/Admin/Program/ProgramInterfaces";
import { useHistory } from "react-router-dom";
import {
	addToCart,
	deleteEntry,
	getDeadlinePrice,
	getFinalPrice,
	getHierarchy,
	removeFromCart,
} from "../../views/Checkout/manageCheckout";
import { getEntryById } from "../../views/Entries/manageEntry";
import { LineItem } from "../../views/Checkout/DraftEntriesTab";
import {
	getThumbnailSrc,
	ThumbnailSize,
} from "../../views/MediaLibrary/manageMediaLibrary";
import Placeholder from "../../assets/placeholders/placeholder.png";
import Icon from "../Icon/Icon";
import { Adjustment } from "../../views/Admin/Program/ProgramInterfaces";
import Modal, { ModalCard, ModalContainer } from "../Modal/Modal";
import HierarchyDropDown from "../HierarchyDropDownModal/HierarchyDropDown";
import useTextWrap from "../../hooks/useTextWrap";
import { commafy } from "../../utils/commafy";
import { useAlert } from "../Alert/Alerts";
import assetsConfig from "../../assetsConfig";
import dateTostring, {
	adjustForDST,
	dateTimeTostring,
} from "../../utils/dateToString";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const EntryCardContainer = styled.div`
	display: flex;
	gap: 2rem;
	padding: 1.5rem;
	border: 2px solid ${({ theme }) => theme.colorBorderLight};
	background: ${({ theme }) => theme.colorBackgroundLightLight};
	flex-wrap: wrap;
	justify-content: flex-start;
	.info {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		.complete,
		.incomplete {
			font-weight: bold;
		}

		.complete {
			color: ${({ theme }) => theme.colorActivation};
		}

		.incomplete {
			color: ${({ theme }) => theme.colorDanger};
		}

		.icon-text {
			display: flex;
			align-items: center;
			gap: 1rem;
		}
	}

	@media only screen and (max-width: ${({ theme }) => theme.lg}) {
		justify-content: flex-start;
		gap: 1rem;
	}

	.additionalFees {
		position: relative;
		&::before {
			position: absolute;
			content: "+";
			left: -15px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
`;

export const showTwoDecimals = (num: number) => {
	return (Math.round(num * 100) / 100).toFixed(2);
};

const ExecutionCard = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 1rem;
	padding: 2.25rem;
	background: ${({ theme }) => theme.colorBackgroundLight};
	border: 1px solid ${({ theme }) => theme.colorBorderLight};
`;

export const CampaignExecutionCard = (props: CampaignExecutionCardProps) => {
	return (
		<ExecutionCard>
			<img src={props.imgSrc} alt="Execution Card Thumbnail" />
			<b>{props.title}</b>
			<h2 className="mt-[1rem]">${showTwoDecimals(props.price)}</h2>
		</ExecutionCard>
	);
};

// sublevel lines to the campaign execution cards
export const DottedLines = styled.div`
	position: absolute;
	left: 5%;
	top: 0;
	width: 5%;
	height: 50%;
	border-left: 2px dashed ${({ theme }) => theme.colorBorderLight};
	border-bottom: 2px dashed ${({ theme }) => theme.colorBorderLight};
`;

const ExeuctionCounter = styled.div<{ small?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${({ theme }) => theme.colorBackgroundMedium};
	color: ${({ theme }) => theme.colorCopyLightLight};
	font-size: ${({ theme }) => theme.xSmallSize};
	border: 1px solid ${({ theme }) => theme.colorBorder};
	padding: 6px;
	width: 40px;
	height: 40px;
	font-size: 1.5rem;
	font-weight: ${({ theme }) => theme.fontSemiBold};

	${(p) =>
		p.small &&
		css`
			width: 25px;
			height: 25px;
			font-size: 0.75rem;
		`}
`;

const CheckoutEntryCard = (props: CheckoutEntryCardProps) => {
	const [price, setPrice] = useState(0.0);
	const [displayStatus, setDisplayStatus] = useState("");
	const [hierarchy, setHierarchy] = useState<string[]>([]);
	const history = useHistory();
	const theme = useTheme();
	const { addNewAlert } = useAlert();
	const { wrapText } = useTextWrap();
	const { settings } = useSelector((state: RootState) => state.settings);

	useEffect(() => {
		if (props.totalLineAmount!) {
			setPrice(props.totalLineAmount!);
		} else {
			setPrice(
				getFinalPrice(
					getDeadlinePrice(props.entry.deadlines, props.entry.adjustment),
					props.entry.isCampaign!
				)
			);
		}
		if (!props.entry.isValid || props.entry.reviewStatusId == 3) {
			setDisplayStatus("Action Required");
		} else if (props.entry.paymentStatusId! == 0 && settings.isDemo) {
			setDisplayStatus("Ready to Submit");
		} else if (props.entry.paymentStatusId! == 0) {
			setDisplayStatus("Ready for Cart");
		} else {
			setDisplayStatus("Complete");
		}
		setHierarchy(
			getHierarchy(
				props.entry.executions!,
				props.entry.ancestry!,
				props.entry.isCampaign!
			)
		);
	}, [props, settings.isDemo]);

	const handleAddToCart = (entryId: number) => {
		addToCart(entryId)
			.then(() => {
				props.onRemove && props.onRemove();
			})
			.catch((e) => {
				if (settings.isDemo) {
					addNewAlert({
						type: "error",
						message: "Unable to add to ready to submit",
					});
				} else {
					addNewAlert({ type: "error", message: "Unable to add to cart" });
				}
			});
	};

	const handleRemoveFromCart = (entryId: number) => {
		removeFromCart(entryId)
			.then(() => {
				props.onRemove && props.onRemove();
			})
			.catch((e) => {
				if (settings.isDemo) {
					addNewAlert({
						type: "error",
						message: "Unable to send to drafts",
					});
				} else {
					addNewAlert({
						type: "error",
						message: "Unable to remove from cart",
					});
				}
			});
	};

	let basePriceStr = settings
		? null
		: price
		? "$" + parseInt(showTwoDecimals(price))
		: null;

	return (
		<EntryCardContainer>
			{props.entry.isCampaign && (
				<div className="flex flex-col items-center gap-[.35rem]">
					<ExeuctionCounter small={settings.isDemo}>
						{props.entry.executions!.length}
					</ExeuctionCounter>
					<span className={settings.isDemo ? "text-[.75rem]" : undefined}>
						Executions
					</span>
				</div>
			)}
			<div className="min-w-[200px]">
				<div className="pt-[56.25%] relative">
					<img
						src={
							(props.entry.coverImage &&
								getThumbnailSrc(props.entry.coverImage!)) ||
							Placeholder
						}
						className="absolute top-0 left-0 w-full h-full object-cover"
						alt="Entry Cover"
					/>
				</div>
			</div>
			<div className="info">
				{wrapText(
					<p
						className={`break-all  md:w-auto xl:w-[300px] xxl:w-[400px] font-semibold ${
							props.entry.title ? "" : "text-colorDanger italic"
						}`}
					>
						{props.entry.title ||
							(assetsConfig.labels.entry.singular === "Entry"
								? "Missing Entry Title"
								: "Missing Title")}
					</p>
				)}

				{settings.isDemo &&
					wrapText(
						<p
							className={`break-all md:w-auto xl:w-[300px] xxl:w-[400px] font-semibold ${
								props.entry.brand ? "" : "text-colorDanger italic"
							}`}
						>
							{props.entry.brand || "Missing Brand"}
						</p>
					)}

				<p>
					{assetsConfig.labels.entry.singular} ID: {props.entry.id}
				</p>

				{settings.isDemo && props.entry.updatedDate && (
					<p className="italic">
						Last Updated: {dateTostring(props.entry.updatedDate)}
					</p>
				)}
				<p>
					Status:{" "}
					<span
						className={`${
							props.entry.isValid === false || props.entry.reviewStatusId === 3
								? "incomplete"
								: "complete"
						}`}
					>
						{displayStatus}
					</span>
				</p>
			</div>
			{!settings.isDemo && (
				<div className="info !gap-0 min-w-[270px]">
					{props.entry.hierarchy &&
						props.entry.hierarchy.map((program, i) => {
							return (
								<p className={`${i === 0 ? "font-semibold" : ""}`} key={i}>
									{program}
								</p>
							);
						})}
				</div>
			)}

			<div className="info">
				{(props.entry.paymentStatusId == 0 ||
					props.entry.paymentStatusId == 2 ||
					props.entry.paymentStatusId == 3) &&
					!props.hideEditLink && (
						<Link
							to={{
								pathname: `/edit-entry/`,
								state: {
									from: history.location.pathname,
								},
								search: `?entryId=${props.entry.id}`,
							}}
							className="icon-text"
						>
							<Icon
								icon="edit"
								color={theme.colorPrimary}
								width="15px"
								height="15px"
							/>
							Edit {assetsConfig.labels.entry.singular}
						</Link>
					)}
				<a
					href=""
					className="icon-text"
					onClick={(e) => {
						e.preventDefault();
						props.setEntryIdToDuplicate &&
							props.setEntryIdToDuplicate(props.entry.id);
					}}
				>
					<Icon
						icon="copy"
						color={theme.colorPrimary}
						width="15px"
						height="15px"
					/>
					Duplicate {assetsConfig.labels.entry.singular}
				</a>

				{props.entry.paymentStatusId == 0 && !props.hideDeleteLink && (
					<a
						href=""
						className="icon-text"
						onClick={(e) => {
							e.preventDefault();
							props.showDeletePopup && props.showDeletePopup(props.entry.id);
						}}
					>
						<Icon
							icon="trash"
							color={theme.colorPrimary}
							width="15px"
							height="15px"
						/>
						Delete {assetsConfig.labels.entry.singular}
					</a>
				)}
			</div>

			<div className="info ml-auto">
				{settings.isDemo ? (
					<></>
				) : (
					<h2 className="ml-auto">${commafy(price)}</h2>
				)}
				{props.showAddCartButton && !props.entry.programEntriesClosed && (
					<Button
						className="mt-auto"
						onClick={() => handleAddToCart(props.entry.id)}
						disabled={
							props.entry.paymentStatusId !== 0 ||
							props.entry.buttonDisabled ||
							!props.entry.isValid
						}
						icon="plus"
					>
						{settings.isDemo ? "Ready to Submit" : "Add to Cart"}
					</Button>
				)}

				{props.showRemoveCartButton &&
					props.entry.paymentStatusId == 1 &&
					!props.entry.buttonDisabled && (
						<Button
							className="mt-auto button-light"
							onClick={() => handleRemoveFromCart(props.entry.id)}
							icon="close"
							iconSize="20px"
						>
							{settings.isDemo ? "Send to Drafts" : "Remove from Cart"}
						</Button>
					)}
			</div>
		</EntryCardContainer>
	);
};

export default CheckoutEntryCard;

export interface EntryCardProps {
	id: number;
	title?: string;
	brand?: string;
	award?: string;
	isCampaign?: boolean;
	programTitle?: string;
	programEntriesClosed?: boolean;
	programId?: number;
	executions?: ExecutionCardModel[];
	companyId?: number;
	reviewStatusId?: number;
	paymentStatusId?: number;
	isValid?: boolean;
	coverImage?: string;
	totalLineAmount?: number;
	deadlines: ProgramDeadline[];
	buttonDisabled?: boolean;
	ancestry?: Ancestor[];
	hierarchy?: string[];
	adjustment?: Adjustment;
	physicalItemDescription?: string;
	qrCodeUrl?: string;
	entryType?: string;
	updatedDate?: Date;
}

export interface ExecutionCardModel {
	id: number;
	programId: number;
	entryModelId: number;
	title: string;
	programName: string;
}

interface CampaignExecutionCardProps {
	imgSrc: string;
	title: string;
	price: number;
}

interface CheckoutEntryCardProps extends LineItem {
	showDeletePopup?(entryToDeleteId: number): void;
	showAddCartButton?: boolean;
	showRemoveCartButton?: boolean;
	setEntryIdToDuplicate: Dispatch<SetStateAction<number | null>>;
	onRemove?(): void; // triggered when entry card is added/removed from cart
	hideEditLink?: boolean;
	hideDeleteLink?: boolean;
}
