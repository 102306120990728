import { useContext, useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import styled, { useTheme } from "styled-components";
import DashboardCard from "../../../components/DashboardCard/DashboardCard";
import axios from "axios";
import { urlAwards } from "../../../endpoints";
import AwardCard from "./AwardCard";
import HierarchyNav, {
	HierarchyCard,
} from "../../../components/HierarchyNav/HierarchyNav";

import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import { useAlert } from "../../../components/Alert/Alerts";

const AwardFilter = styled.div`
	display: flex;
	margin-left: calc(
		2.235rem + 130px + 3.27125rem
	); /* left pad + button width + button margin */
	margin-bottom: 1.3125rem;
	margin-top: 2.5rem;

	.name-filter,
	.logo-filter,
	.status-filter {
		display: flex;
		align-items: center;
		margin-right: 3.0875rem;
	}

	.name-filter {
		width: 200px;
	}

	.logo-filter {
		width: 380px;
	}
`;

export default function Awards() {
	const theme = useTheme();
	const defaultActiveValue = true;
	const [errors, setErrors] = useState<string[]>([]);
	const { addNewAlert } = useAlert();
	const [awards, setAwards] = useState<AwardModel[]>([]);
	useEffect(() => {
		axios
			.get<AwardModel[]>(`${urlAwards}`)
			.then((response) => {
				// console.log("awards", response.data);
				setAwards(response.data);
			})
			.catch((error) => {
				// console.log(error);
			});
	}, []);

	const newAward: AwardModel = {
		name: "",
		isActive: defaultActiveValue,
		imageLightData: undefined,
		imageDarkData: undefined,
	};
	const [postNewAward, setPostNewAward] = useState<AwardModel>();

	const addAward = () => {
		const newAward: AwardModel = {
			name: "",
			isActive: defaultActiveValue,
			imageLightData: undefined,
			imageDarkData: undefined,
		};
		// setAwards((prevState) => [newAward, ...prevState]);

		if (!postNewAward) {
			// add new season
			setPostNewAward(newAward);
			setAwards([newAward, ...awards]);
		} else if (postNewAward.name) {
			// allow user to add another award AFTER they save
			setAwards((prevState) => [newAward, ...prevState]);
			setPostNewAward(newAward);
		} else {
			addNewAlert({
				type: "error",
				message: "Plesae save the new season before adding more",
			});
		}
	};

	let fieldRequired = "This field is required";

	return (
		<DashboardCard title="Manage Awards" hasSearch>
			<BrowserHeader title="Manage Awards" />
			<Button
				className="ml-auto mb-[2rem]"
				icon="plus"
				onClick={() => addAward()}
				iconColor={theme.colorCopyLightLight}
			>
				Add An Award
			</Button>
			<HierarchyNav
				responsive={true}
				childrenHeight="255px"
				columns={[
					{ label: null, width: "150px" },
					{ label: "Award Name" },
					{ label: "Sponsor Logo", width: "400px" },
					{ label: "Status" },
				]}
			>
				{(gridColumns: string, gridArea: string) =>
					awards.map((award, index) => (
						<HierarchyCard
							responsive={true}
							key={index}
							className="!max-h-max "
							gridColumns={gridColumns}
							gridArea={gridArea}
						>
							<AwardCard
								key={index}
								isEditing={award.id ? false : true}
								award={award}
								onPostAward={(award) => {
									setPostNewAward(award);
									const removedEmptyAward = awards.slice(1);
									setAwards([award, ...removedEmptyAward]);
								}}
							/>
						</HierarchyCard>
					))
				}
			</HierarchyNav>
		</DashboardCard>
	);
}

export interface AwardModel {
	id?: number;
	name: string;
	isActive: boolean;
	imageLight?: string;
	imageDark?: string;
	imageLightData?: File;
	imageDarkData?: File;
	createdDate?: string;
	createdBy?: string;
	modifiedDate?: string;
	modifiedBy?: string;
}
