import axios from "axios";
import { useState, useEffect } from "react";
import { urlAwards } from "../endpoints";

export interface DropdownOptions {
  value: any;
  label?: string | number;
  statusColor?: string;
}

export const getAllAwards = async (): Promise<DropdownOptions[]> => {
  const response = await axios.get(`${urlAwards}/getAwardsDropdown`);
  if (response.status === 200) {
    return response.data;
  } else {
    return [];
  }
};

export const useProgramAwards = () => {
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOptions[]>([]);

  useEffect(() => {
    const fetchAwards = async () => {
      const allAwards = await getAllAwards();
      setDropdownOptions(allAwards);
    };

    fetchAwards();
  }, []);

  return dropdownOptions;
};
