import { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import styled, { useTheme } from "styled-components";
import Modal, { ModalCard } from "../Modal/Modal";
import { Tag } from "../../views/MediaLibrary/mediaLibrary.model";
import { urlTags } from "../../endpoints";
import axios from "axios";
import Icon from "../Icon/Icon";
import Button, { buttonProps } from "../Button/Button";
import TextField from "../FormFields/TextField";
import TagSelection from "./TagSelection";

function TagSelectionModal(props: TagSelectionModalProps) {
  const theme = useTheme();
  const [selectedTags, setSelectedTags] = useState<Array<Tag>>(props.inputTags);

  return (
    <Modal show={true}>
      <ModalCard
        className="h-[500px]"
        title={props.title}
        headerIcons={
          <Icon
            icon="close"
            color={theme.colorPrimary}
            onClick={() => props.closeModal(props.inputTags, true)}
            width="35px"
            height="35px"
          />
        }
      >
        <div className="p-[2rem]">
          <TagSelection
            companyId={props.companyId}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
        </div>

        <Button
          className="mt-auto"
          icon="check"
          iconColor={theme.colorCopyLightLight}
          onClick={() => props.closeModal(selectedTags, false)}
          iconSize="15px"
          type="submit"
        >
          Save
        </Button>
      </ModalCard>
    </Modal>
  );
}

export default TagSelectionModal;

interface TagSelectionModalProps {
  companyId: number;
  inputTags: Array<Tag>;
  closeModal(outputTags: Array<Tag>, cancelled: boolean): void;
  title: string;
}
