import { Helmet } from "react-helmet";
import assetsConfig from "../../assetsConfig";

export const titleDivider = " | ";

const BrowserHeader = ({ title }: { title: string }) => {
	return (
		<Helmet>
			<title>{title + titleDivider + assetsConfig.companyName}</title>
		</Helmet>
	);
};

export default BrowserHeader;
