import FieldModal from "../../../../components/FieldModal/FieldModal";
import TextField from "../../../../components/FormFields/TextField";
import DropdownField from "../../../../components/FormFields/DropdownField";
import { ListFieldTemplate, ProgramListField } from "../ProgramInterfaces";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { listTypeOptions } from "../ProgramInterfaces";
import { fieldRequired } from "../../../../components/FieldModal/FieldModal";
import { fieldTemplateValidation } from "../../../../components/FieldModal/FieldModal";
import { useEffect } from "react";
import { newFieldInitialVal } from "../../../../components/FieldModal/FieldModal";

const ListFieldForm = (props: CustomFieldProps) => {
  const formikProps = useFormik({
    initialValues: {
      // populate existing field values for update form OR use new values for new field form
      ...(props.formData ? { ...props.formData } : { ...newFieldInitialVal }),
      listType: props.formData ? String(props.formData.listType) : "",
      maxQuantity: props.formData ? props.formData.maxQuantity : "",
      minQuantity: props.formData ? props.formData.minQuantity : "",
      listOptions: props.formData ? String(props.formData.listOptions) : "",
    },
    onSubmit: async (value) => {},
    validationSchema: Yup.object({
      ...fieldTemplateValidation,
      listType: Yup.number().typeError(fieldRequired).required(fieldRequired),
      minQuantity: Yup.number()
        .required(fieldRequired)
        .min(
          0,
          "Minimum number of selections must be greater than or equal to 0"
        ),
      maxQuantity: Yup.number().min(
        Yup.ref("minQuantity"),
        "Maximum number of selections cannot be less than minimum number of selections"
      ),
      listOptions: Yup.string().required(fieldRequired),
    }),
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <FormikProvider value={formikProps}>
      <FieldModal
        {...(props.formData && {
          formData: props.formData,
        })}
        formTitle="List Field Template"
        hideModal={() => props.hideModal()}
        formType="list"
        isUpdateForm={props.formData ? true : false}
      >
        <DropdownField
          className="min-w-[50%]"
          placeholder="Select List Type"
          name="listType"
          options={listTypeOptions}
          value={formikProps.values.listType}
        />
        <TextField
          className="min-w-[50%]"
          placeholder="Minimum Number of Selections"
          name="minQuantity"
          type="number"
          value={formikProps.values.minQuantity}
        />
        <TextField
          className="min-w-[50%]"
          placeholder="Maximum Number of Selections"
          name="maxQuantity"
          type="number"
          value={formikProps.values.maxQuantity}
        />
        <TextField
          name="listOptions"
          placeholder="List Options"
          value={formikProps.values.listOptions}
        />
      </FieldModal>
    </FormikProvider>
  );
};

export default ListFieldForm;

interface CustomFieldProps {
  formData?: ListFieldTemplate | ProgramListField;
  hideModal: () => void;
}
