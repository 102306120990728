import { EntrySetDefinitionConfig } from "../../views/Admin/Judging/JudgingInterfaces";
import { useContext, useEffect, useRef, useState } from "react";
import { ReactSortable, Sortable, Store } from "react-sortablejs";
import {
	DragCard,
	DraggableProps,
	DropZone,
	sortableOptions,
} from "./Draggable";
import { useTheme } from "styled-components";
import Icon from "../Icon/Icon";
import { FakeCheckbox } from "../FormFields/CheckboxField";

const DragDefinition = (props: DragDefinitionProps) => {
	const theme = useTheme();
	const programLevels = props.entrySetDefinition.filterString
		? props.entrySetDefinition.filterString.replaceAll("|", "\n")
		: undefined;

	return (
		<DragCard
			className="draggable"
			draggable
			key={props.entrySetDefinition.id}
			data-id={props.entrySetDefinition.id}
		>
			<div className="drag-icons gap-[1rem]">
				<Icon
					className="drag-arrows"
					icon="drag-arrows"
					color={theme.colorPrimary}
				/>

				<div className="flex flex-col credit-field">
					<p className="font-semibold !whitespace-pre-wrap w-[195px]">
						{props.entrySetDefinition.entryType}
					</p>
				</div>

				{programLevels && (
					<p className="!whitespace-pre-wrap !max-w-none w-full">
						{programLevels}
					</p>
				)}

				<Icon
					icon="closeLarge"
					color={theme.colorPrimary}
					className="close-icon ml-auto"
					onClick={() => props.onRemove && props.onRemove()}
				/>
			</div>

			{props.allowMultiSelect && (
				<FakeCheckbox
					className="checkbox-field"
					id={`multiSelect${props.entrySetDefinition.id!}`}
					aria-label={"multiselect checkbox"}
					checked={props.entrySetDefinition.isCheckedForDrag}
				/>
			)}
		</DragCard>
	);
};

const DragDefinitionsList = (props: DragDefinitionsListProps) => {
	const ref = useRef<EntrySetDefinitionConfig[] | null>(null);
	const prevDefinitions = ref.current;
	const [definitions, setDefinitions] = useState<EntrySetDefinitionConfig[]>(
		[]
	);

	const handleDefintions = (
		newDefinitions: EntrySetDefinitionConfig[],
		sortable: Sortable | null,
		store: Store
	) => {
		// only run setDefinitions once on drag end
		// https://github.com/SortableJS/react-sortablejs/issues/210
		if (
			(store.dragging &&
				store.dragging.props &&
				JSON.stringify(store.dragging.props.list) !==
					JSON.stringify(newDefinitions)) ||
			props.list.length === 0
		) {
			if (prevDefinitions !== null) {
				// on reorder
				if (prevDefinitions.length === newDefinitions.length) {
					// set list immediately in the front-end
					setDefinitions(newDefinitions);
					ref.current = newDefinitions;
					return;
				}
				// on add
				else {
					const addedDefinitions = newDefinitions.filter((element) => {
						return (
							prevDefinitions.findIndex((prev) => element.id === prev.id) === -1
						);
					});

					props.onAdd && props.onAdd(addedDefinitions);
					setDefinitions(addedDefinitions);
					ref.current = addedDefinitions;
					return;
				}
			}
		}

		ref.current = newDefinitions;
	};

	useEffect(() => {
		// add small delay to perform dnd on front-end before updating list
		setTimeout(() => {
			if (props.list) {
				ref.current = props.list;
				setDefinitions(props.list);
			}
		}, 50);
	}, [props.list]);

	return (
		<DropZone
			className="!h-[500px] !max-h-[500px] overflow-y-auto"
			isInDropzone={props.drop === undefined ? true : props.drop}
		>
			<ReactSortable
				className="h-full"
				list={definitions}
				setList={(newDefinitions, sortabble, store) =>
					handleDefintions(newDefinitions, sortabble, store)
				}
				{...sortableOptions({
					group: "entry-set-definition",
					name: props.name,
					clone: props.clone,
					drop: props.drop,
					sortable: props.sortable,
					allowMultiSelect: props.allowMultiSelect,
				})}
			>
				{definitions.length > 0 ? (
					definitions.map((item) => (
						<DragDefinition
							key={item.id}
							entrySetDefinition={item}
							onRemove={() => props.onRemove && props.onRemove(item.id)}
							allowMultiSelect={props.allowMultiSelect}
						/>
					))
				) : (
					<></>
				)}
			</ReactSortable>
		</DropZone>
	);
};

export default DragDefinitionsList;

interface DragDefinitionsListProps extends DraggableProps {
	className?: string;
	list: EntrySetDefinitionConfig[];
	onAdd?(entrySetDefinition: EntrySetDefinitionConfig[]): void;
	onRemove?(entrySetDefinitionId: number): void;
}

interface DragDefinitionProps {
	entrySetDefinition: EntrySetDefinitionConfig;
	onRemove?(): void;
	onCheck?(entrySetDefinition: EntrySetDefinitionConfig): void;
	allowMultiSelect?: boolean;
	isDropped?: boolean;
}
