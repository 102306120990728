import styled from "styled-components";
import { DynamicTransitionDuration } from "../../globalStyles";
import { defaultBoxShadow } from "../../assetsConfig";

export const LinkLabel = styled.span`
	color: ${({ theme }) => theme.colorCopyLight};
	transition: color ${DynamicTransitionDuration} ease;
	cursor: pointer;
	&:hover {
		color: ${({ theme }) => theme.colorCopyDarkDark};
		font-weight: ${({ theme }) => theme.fontSemiBold};
	}
`;

export const HoverMenuLink = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.75rem 1.8125rem;
	color: ${({ theme }) => theme.colorCopyLight};
	border-top: 4px solid ${({ theme }) => theme.colorBackgroundLight};

	&.first-link {
		border: 0;
	}

	&.sub-link {
		padding: 1.5rem calc(2.4375rem + 1.8125rem);
		background: ${({ theme }) => theme.colorBackgroundMedium};
		border-top: none;
		border-bottom: 1.5px solid ${({ theme }) => theme.colorCopyDarkDark};
		span {
			color: ${({ theme }) => theme.colorCopyLightLight};
		}
	}
`;

export const ExpandMenu = styled.div`
	transform-origin: top;

	&.expand-menu-enter {
		/* height: 0; */
		max-height: 0;
		/* transform: scaleY(0); */
	}
	&.expand-menu-enter-active {
		/* height: 100%; */
		max-height: 999px;
		/* transform: scaleY(100%); */
		transition: all 150ms ease;
	}
	&.expand-menu-exit {
		/* height: 100%; */
		max-height: 999px;
		/* transform: scaleY(100%); */
	}
	&.expand-menu-exit-active {
		/* height: 0; */
		max-height: 0;
		/* transform: scaleY(0); */
		transition: all 150ms ease;
	}
`;

const dropdownSpacing = ".5rem"; //spacing b/w dropdown and hovered element

export const StyledHoverMenu = styled.div<{ caretColor?: string }>`
	position: absolute;
	display: flex;
	flex-direction: column;
	min-width: 300px;
	max-width: 85vw;
	max-height: 60vh;
	top: calc(100% + ${dropdownSpacing});
	right: 10%;
	background: ${({ theme }) => theme.colorBackgroundLightLight};
	z-index: 999;
	box-shadow: ${defaultBoxShadow};

	@media only screen and (min-width: ${({ theme }) => theme.md}) {
		max-height: 80vh;
	}

	${HoverMenuLink}:first-of-type {
		border: 0;
	}

	&:before {
		content: "";
		position: absolute;
		top: -${dropdownSpacing};
		width: 100%;
		height: ${dropdownSpacing};
		background: transparent;
	}
	/* caret up  */
	&:after {
		content: "";
		position: absolute;
		right: 5px;
		bottom: 100%;
		width: 0;
		height: 0;
		border-left: 3px solid transparent;
		border-right: 3px solid transparent;
		border-bottom: 3px solid
			${(p) =>
				p.caretColor ? p.caretColor : p.theme.colorBackgroundLightLight};
		z-index: 999;
	}

	&.hover-menu-enter {
		opacity: 0;
	}
	&.hover-menu-enter-active {
		opacity: 1;
		transition: all 150ms;
	}
	&.hover-menu-exit {
		opacity: 1;
	}
	&.hover-menu-exit-active {
		opacity: 0;
		transition: opacity 150ms;
	}
`;

export const HoverMenu = (props: HoverMenuProps) => {
	return (
		<StyledHoverMenu className={props.className} caretColor={props.caretColor}>
			{props.children}
		</StyledHoverMenu>
	);
};

interface HoverMenuProps {
	children?: React.ReactNode;
	className?: string;
	caretColor?: string;
}
