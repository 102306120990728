import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styled, { css, useTheme } from "styled-components";
import { DynamicPadding, DynamicTransitionDuration } from "../../globalStyles";
import { Link } from "react-router-dom";
import Icon from "../Icon/Icon";

export const EntryFormHeaderCSS = css`
	/* height: 241px; */
	${DynamicPadding}
	padding-bottom: 0 !important;
	background: ${({ theme }) => theme.gradientBlackGray};
	border-bottom: solid 5px ${({ theme }) => theme.colorActivationDark};
`;

export const NavTabList = styled(TabList)`
	${EntryFormHeaderCSS};
`;

export const NavTabPanel = styled(TabPanel)<NavProps>`
	${(p) =>
		p.hide &&
		`
    display: none;
  `}

	&.react-tabs__tab-panel--selected {
		display: flex;
		flex-direction: column;
		flex: 1;
	}
`;

const StyledTab = styled(Tab)<NavProps>`
	position: relative;
	display: inline-block;
	padding: 0.75rem 0;
	margin-right: calc(${({ theme }) => theme.pSize} * 1.5);
	color: ${({ theme }) => theme.colorCopyLightLight};
	border-bottom: 5px solid transparent;
	transition: border ${DynamicTransitionDuration} ease;
	cursor: pointer;
	top: 5px;
	user-select: none;

	a {
		color: ${({ theme }) => theme.colorCopyLightLight};
	}

	${(p) =>
		p.flex &&
		css`
			display: flex;
			align-items: center;
		`}

	${(p) =>
		p.hide &&
		css`
			display: none;
		`}

  @media only screen and (min-width: ${({ theme }) => theme.md}) {
		margin-right: calc(${({ theme }) => theme.pSize} * 3);
	}

	&[aria-selected="true"] {
		&,
		a {
			font-weight: ${({ theme }) => theme.fontSemiBold};
		}

		border-bottom: 5px solid ${({ theme }) => theme.colorActivation};
	}

	&:focus {
		outline: none;
	}

	&:hover {
		border-bottom: 5px solid ${({ theme }) => theme.colorActivation};
	}
`;

export const NavTab = ({ ...props }) => {
	const theme = useTheme();

	return (
		<StyledTab {...props}>
			{props.children}
			{!props.hide && props.isError && (
				<Icon
					className="mr-[.25rem]"
					icon="invalid"
					color={theme.colorPrimary}
				/>
			)}
		</StyledTab>
	);
};

NavTab.tabsRole = "Tab"; // Required to use custom Tab

export const NavTabs = styled(Tabs)`
	&.credit-db {
		${NavTabList} {
			height: initial;
			/* padding: 3.4375rem 2.6875rem; */
			padding: 1.5rem 2rem;
		}

		${NavTabPanel}.react-tabs__tab-panel--selected {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 2rem;
		}
	}
`;

interface NavProps {
	hide?: boolean;
	flex?: boolean; // used only for the entry form with the invalid icon
}
