import styled, { css } from "styled-components";
import { Field } from "formik";
import { useFormikContext } from "formik";
import { FieldError } from "./FieldTemplate";
import { DynamicTransitionDuration } from "../../globalStyles";
import { useEffect, useRef } from "react";
import { CheckBox, RegularCheckBox } from "./CheckboxField";
import CheckMark from "../../assets/icons/Check-white.svg";
import FieldWrapper from "./FieldWrapper";

const RadioCSS = css`
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	display: inline-block;
	width: 20px;
	height: 20px;
	padding: 3px !important;
	background-clip: content-box;
	border: 2px solid ${({ theme }) => theme.colorCopyDarkDark};
	border-radius: 50%;
	transition: all ${DynamicTransitionDuration} ease;
	cursor: pointer;

	&:checked {
		background-color: ${({ theme }) => theme.colorActivation};
		border: 2px solid ${({ theme }) => theme.colorActivation};
	}
`;

const StyledRadio = styled(Field)`
	${RadioCSS};

	${(p) =>
		p.disabled &&
		css`
			&:checked {
				background-color: ${({ theme }) => theme.colorFieldDisabled};
			}

			border: 2px solid ${({ theme }) => theme.colorFieldDisabled} !important;
			cursor: default;
		`}

	${(p) =>
		p.error &&
		css`
			border: 2px solid ${({ theme }) => theme.colorDanger};
		`}
`;

const NonFormikRadioInput = styled.input<{
	disabled?: boolean;
	error?: boolean;
}>`
	${RadioCSS};

	${(p) =>
		p.disabled &&
		css`
			&:checked {
				background-color: ${({ theme }) => theme.colorFieldDisabled};
			}

			border: 2px solid ${({ theme }) => theme.colorFieldDisabled} !important;
			cursor: default;
		`}

	${(p) =>
		p.error &&
		css`
			border: 2px solid ${({ theme }) => theme.colorDanger};
		`}
`;

const Label = styled.label<{ disabled?: boolean }>`
	display: flex;
	align-items: center;
	gap: 0.9rem;
	color: ${({ theme }) => theme.colorCopyDarkDark};
	cursor: pointer;

	${(p) =>
		p.disabled &&
		css`
			color: ${({ theme }) => theme.colorCopyLight};
			cursor: default;
		`};
`;

// container used for checkboxes and radios
const StyledListContainer = styled.div<StyledListContainerProps>`
	display: grid;
	grid-template-columns: 1fr 1fr; // default is 2 columns
	column-gap: 11rem;
	column-gap: 0.9375rem;
	width: 100%;
	row-gap: 1.5rem;

	${(p) =>
		p.error &&
		css`
			${StyledRadio}, ${NonFormikRadioInput} {
				border: 2px solid ${({ theme }) => theme.colorDanger} !important;

				&:checked {
					background-color: ${({ theme }) => theme.colorDanger};
				}
			}

			${RegularCheckBox}, ${CheckBox} {
				&:before {
					border: solid 1px ${({ theme }) => theme.colorDanger};
				}

				&:checked:before {
					background: url(${CheckMark}) center / 70% no-repeat,
						${({ theme }) => theme.colorDanger};
					color: ${({ theme }) => theme.colorDanger};
				}
			}
		`}

	/* use vertical grid direction if "rows" prop is defined  */
  ${(p) =>
		p.rows &&
		css`
			 {
				grid-template-rows: repeat(${p.rows}, 1fr);
				grid-auto-flow: column;
				row-gap: 0.9375rem;
			}
		`}

  // use custom column count
${(p) =>
		p.cols &&
		css`
			 {
				grid-template-columns: repeat(${p.cols}, 1fr);
			}
		`}
`;

export const ListContainer = (props: ListContainerProps) => {
	return (
		<FieldWrapper
			attributes={{ role: "group", "aria-labelledby": props.name }}
			className={props.className}
			name={props.name}
			success={props.success}
			error={props.error}
		>
			{(success, error) => (
				<StyledListContainer
					{...(props.rows && {
						rows: props.rows,
					})}
					{...(props.cols && {
						cols: props.cols,
					})}
					{...(props.className && {
						className: props.className,
					})}
					success={success}
					error={error}
				>
					{props.children}
				</StyledListContainer>
			)}
		</FieldWrapper>
	);
};

const RadioField = (props: RadioFieldProps) => {
	const { values, setFieldValue } = useFormikContext<any>();
	const inputRef = useRef<any>(null);

	const handleClick = () => {
		if (inputRef.current) {
			props.onClick && props.onClick();
			inputRef.current.click();
		}
		/* setFieldValue(props.name, props.value); */
	};

	useEffect(() => {
		// only parse number strings or boolean strings
		if (
			values[props.name] !== undefined &&
			(typeof props.value === "number" || typeof props.value === "boolean")
		) {
			// parse number strings
			if (
				typeof props.value === "number" &&
				typeof parseInt(values[props.name]) === "number" &&
				!isNaN(parseInt(values[props.name]))
			) {
				const incorrectVal =
					parseInt(values[props.name]) !== values[props.name];

				if (incorrectVal) {
					// console.log(
					//   `parsing radio value: ${values[props.name]} (${typeof values[
					//     props.name
					//   ]}) into ${parseInt(values[props.name])} (${typeof parseInt(
					//     values[props.name]
					//   )})`
					// );

					setFieldValue(props.name, parseInt(values[props.name]));
				}
			}

			// parse boolean strings
			if (typeof props.value === "boolean" && values[props.name] === "true") {
				setFieldValue(props.name, true);
			} else if (
				typeof props.value === "boolean" &&
				values[props.name] === "false"
			) {
				setFieldValue(props.name, false);
			}
		}
	}, [values[props.name]]);

	return (
		<Label
			key={props.name + props.value}
			className={props.className}
			onClick={!props.disabled ? handleClick : undefined} // make entire radio (including label) clickable>
			disabled={props.disabled}
		>
			<StyledRadio
				innerRef={inputRef}
				type="radio"
				id={`radioField-${props.name}`}
				name={props.name}
				value={props.value}
				disabled={props.disabled || false}
			/>
			{props.label}
		</Label>
	);
};

export const NonFormikRadio = (props: NonFormikRadioProps) => {
	return (
		<div
			className={`flex items-center gap-[.5rem] ${
				props.className ? props.className : ""
			}
			${!props.disabled && "cursor-pointer"}
			`}
			{...(!props.disabled && {
				onClick: () => props.onClick(),
			})}
		>
			<NonFormikRadioInput
				type="radio"
				id={`radioField-${props.name}`}
				name={props.name}
				disabled={props.disabled || false}
				checked={props.checked}
			/>
			<Label
				className={`${!props.disabled && "cursor-pointer"}`}
				disabled={props.disabled || false}
			>
				{props.label}
			</Label>
		</div>
	);
};

export default RadioField;

interface RadioFieldProps {
	className?: string;
	label: string;
	name: string;
	value: string | number | boolean;
	onClick?(): void;
	checked?: boolean;
	disabled?: boolean;
}

interface NonFormikRadioProps {
	className?: string;
	label: string;
	name: string;
	// NOTE: non formik radios don't HAVE a value prop b/c the EXTERNAL onClick handler will handle the value
	// value: string | number | boolean;
	onClick(): void;
	checked: boolean;
	disabled?: boolean;
}

interface StyledListContainerProps {
	rows?: number;
	cols?: number;
	role?: string;
	error?: string;
	success?: string;
}

interface ListContainerProps extends StyledListContainerProps {
	className?: string;
	children: React.ReactNode;
	name: string;
	error?: string;
	success?: string;
}
