import { useState, useEffect, useRef } from "react";
import ProgramCard from "../../../components/ProgramCard/ProgramCard";
// import { ProgramTestData } from "./ProgramTestData";
import styled, { useTheme } from "styled-components";
// import DragNDrop from "../../../components/DragNDrop/DragNDrop";
import { Responsive, WidthProvider } from "react-grid-layout";
import Button from "../../../components/Button/Button";
import { CSSTransition } from "react-transition-group";
import { ProgramDescription } from "../../../components/ProgramCard/ProgramCard";
import { DynamicTransitionDuration } from "../../../globalStyles";
import { useHistory, useParams } from "react-router-dom";
import { ProgramDisplay } from "./ProgramInterfaces";
import ProgramDisplayForm from "./ProgramDisplayForm";
import {
	getProgramDisplayById,
	getProgramDisplays,
} from "./manageProgramDisplays";
import {
	CardBody,
	CardContainer,
	CardHeader,
} from "../../../components/DashboardCard/DashboardCard";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";

// responsive width for grid
const ResponsiveGridLayout = WidthProvider(Responsive);

const StyledResponsiveGridLayout = styled(ResponsiveGridLayout)`
	&.editing-layout {
		transform: scale(50%);
		transform-origin: top left;
		transition: transform ${DynamicTransitionDuration} ease;
		border: 2px dashed ${({ theme }) => theme.colorBackgroundDarkDark};
	}

	.collapse-card-enter {
		&.program-card {
			height: unset;
			min-height: 360px;
		}
	}

	.collapse-card-enter-active {
		${ProgramDescription} {
			transform: scaleY(0);
		}

		&.program-card {
			height: unset;
			min-height: 360px;
		}
	}

	.collapse-card-exit {
		${ProgramDescription} {
			transform: scaleY(0);
		}

		&.program-card {
			height: unset;
			min-height: 360px;
		}
	}

	.collapse-card-enter-done {
		${ProgramDescription} {
			transform: scaleY(0);
			max-height: 0;
			display: none;
		}

		&.program-card {
			height: unset;
			min-height: 360px;
		}
	}

	.collapse-card-exit-active {
		${ProgramDescription} {
			transform: scaleY(100%);
		}
	}
`;

const PageContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	/* padding: 1.3125rem 5.40625rem; */

	@media only screen and (min-width: 2503px) {
		width: 70%;
	}

	@media only screen and (min-width: 2503px) {
		width: 70%;
	}
`;

const ProgramCardsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 4.375rem;
	column-gap: 1.25rem;
	flex-wrap: wrap;
	/* padding: 0 3rem; */
	margin: 2.25rem 0;
	width: 100%;
	max-width: 1800px;

	@media only screen and (min-width: 1500px) {
		grid-template-columns: 1fr 1fr;
	}

	@media only screen and (min-width: 1800px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
`;

export default function Programs() {
	const theme = useTheme();
	const history = useHistory<any>();
	const newProgramDisplayRef = useRef<any>(null);
	const [editingLayout, setEditingLayout] = useState(false);
	const params = useParams<{ programId: string }>();
	const [programs, setPrograms] = useState<null | ProgramDisplay[]>(null);
	const [programUpdate, setProgramUpdate] = useState<any>({});

	const fieldRequired = "This field is required";

	const defaultGridLayout = {
		threeCol: [
			{ i: "0", x: 0, y: 0, w: 1, h: 10 },
			{ i: "1", x: 1, y: 0, w: 1, h: 10 },
			{ i: "2", x: 2, y: 0, w: 1, h: 10 },
			{ i: "3", x: 0, y: 10, w: 1, h: 10 },
			{ i: "4", x: 1, y: 10, w: 1, h: 10 },
			{ i: "5", x: 2, y: 10, w: 1, h: 10 },
		],
		twoCol: [
			{ i: "0", x: 0, y: 0, w: 1, h: 10 },
			{ i: "1", x: 1, y: 0, w: 1, h: 10 },
			{ i: "2", x: 0, y: 10, w: 1, h: 10 },
			{ i: "3", x: 1, y: 10, w: 1, h: 10 },
			{ i: "4", x: 0, y: 20, w: 1, h: 10 },
			{ i: "5", x: 1, y: 20, w: 1, h: 10 },
		],
		oneCol: [
			{ i: "0", x: 0, y: 0, w: 1, h: 10 },
			{ i: "1", x: 0, y: 10, w: 1, h: 10 },
			{ i: "2", x: 0, y: 20, w: 1, h: 10 },
			{ i: "3", x: 0, y: 30, w: 1, h: 10 },
			{ i: "4", x: 0, y: 40, w: 1, h: 10 },
			{ i: "5", x: 0, y: 50, w: 1, h: 10 },
		],
	};

	const [layouts, setLayouts] = useState(defaultGridLayout);

	const resetLayout = () => {
		setLayouts(defaultGridLayout);
	};

	const handleLayoutChange = (layout: any, layouts: any) => {
		// console.log("original layout", layout);
		// console.log("original layoutS", layouts);
		// saveToLS("layouts", JSON.stringify(layouts));
		setLayouts(layouts);
	};

	const handleEditLayout = () => {
		setEditingLayout(!editingLayout);
	};

	useEffect(() => {
		// console.log("hsitory", history);
		// debugger;
		getProgramDisplays().then((res) => {
			// console.log(res);
			const programDisplayArr: ProgramDisplay[] = res.data.flatMap(
				(programDisplay: ProgramDisplay) => {
					if (programDisplay.programDepthLevel === 0) {
						return programDisplay;
					} else return [];
				}
			);
			// console.log("programDisplayArr", programDisplayArr);
			setPrograms(programDisplayArr);
		});
	}, []);

	useEffect(() => {
		if (params && params.hasOwnProperty("programId")) {
			// console.log("program id", params.programId);
			getProgramDisplayById(Number(params.programId)).then((res) =>
				setProgramUpdate(res.data)
			);
		}
	}, [params]);

	// useEffect(() => {
	//   if (history.location.state && newProgramDisplayRef.current) {
	//     console.log(
	//       "scroll ref",
	//       programs,
	//       history,
	//       newProgramDisplayRef.current
	//     );
	//     newProgramDisplayRef.current.scrollIntoView({ behavior: "smooth" });
	//   }
	//   console.log("scroll ref", programs, history, newProgramDisplayRef.current);
	// }, [programs, history, newProgramDisplayRef.current]);

	return (
		<CardContainer>
			<BrowserHeader title="Program Card Setup" />
			<CardHeader className="flex-wrap gap-[1rem]">
				<h2 className="card-title">Program Setup</h2>
				<Button
					icon="plus"
					iconColor={theme.colorCopyLightLight}
					to="/program/new-program-display"
				>
					New Program Display
				</Button>
			</CardHeader>
			<CardBody>
				{Object.keys(programUpdate).length > 0 && <ProgramDisplayForm />}

				{Object.keys(programUpdate).length === 0 && programs && (
					<>
						<div className="flex items-center justify-between  mb-[2rem]">
							{/* <div className="flex justify-end items-center flex-wrap gap-[1rem]">
              <Button
                className={editingLayout ? "button-activation" : "button-light"}
                onClick={() => handleEditLayout()}
              >
                {editingLayout ? "Save Layout" : "Edit Layout"}
              </Button>
              <Button className="button-light" onClick={() => resetLayout()}>
                Reset Layout
              </Button>
            </div> */}
						</div>

						{/* <StyledResponsiveGridLayout
            className={`layout ${editingLayout && "editing-layout"} mt-[3rem]`}
            layouts={layouts}
            breakpoints={{ threeCol: 1600, twoCol: 1100, oneCol: 360 }}
            cols={{ threeCol: 3, twoCol: 2, oneCol: 1 }}
            rowHeight={editingLayout ? 20 : 80}
            margin={[20, 20]}
            compactType="horizontal"
            isDraggable={editingLayout}
            isResizable={false}
            autoSize={true}
            maxRows={2}c
            onLayoutChange={(layout, layouts) =>
              handleLayoutChange(layout, layouts)
            }
            transformScale={0.5}
          > */}
						<div className="flex gap-[2rem] flex-wrap justify-center">
							{programs
								.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)) // sort programs by sortOrder
								.map((programDisplay: ProgramDisplay, i: number) => {
									// console.log(
									//   "porgram id",
									//   program,
									//   program.id === history.location.state.programDisplayId,
									//   program.id,
									//   history.location.state.programDisplayId
									// );
									return (
										<div key={i}>
											<CSSTransition
												in={editingLayout}
												timeout={150}
												classNames="collapse-card"
											>
												<ProgramCard
													{...programDisplay}
													className="program-card"
													key={i}
													isAdmin={true}
												/>
											</CSSTransition>
										</div>
									);
								})}
						</div>

						{/* </StyledResponsiveGridLayout> */}
					</>
				)}
			</CardBody>
		</CardContainer>
	);
}
