import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  CardBody,
  CardContainer,
  CardHeader,
} from "../../../components/DashboardCard/DashboardCard";
import {
  NavTab,
  NavTabPanel,
  NavTabs,
} from "../../../components/NavTabs/NavTabs";
import { ConfigNavTabs } from "../Program/ProgramConfig";
import EntrySearchTab from "../Search/EntrySearchTab";
import Commerce from "./Commerce";
import PaymentThreshold from "./Commerce";
import PauseSlide from "./PauseSlide";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";

const Settings = (props: { tab: string }) => {
  const tabs = ["Commerce", "pause-slide"];
  const tabNames = ["Commerce", "Pause Slide"];

  const defaultTab = tabs.indexOf(props.tab || "Commerce" || "pause-slide");
  const [tabIndex, setTabIndex] = useState(defaultTab);

  useEffect(() => {
    setTabIndex(defaultTab);
  }, [defaultTab]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tabIndex]);

  return (
    <CardContainer>
      <BrowserHeader title={`Settings: ${tabNames[tabIndex]}`} />
      <CardHeader>
        <h2 className="card-title">Settings</h2>
      </CardHeader>
      <NavTabs
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <ConfigNavTabs>
          <NavTab>
            <Link className="no-underline" to="/settings/commerce">
              Commerce
            </Link>
          </NavTab>
          <NavTab>
            <Link className="no-underline" to="/settings/pause-slide">
              Pause Slide
            </Link>
          </NavTab>
        </ConfigNavTabs>
        <NavTabPanel>
          <CardBody>
            <Commerce />
          </CardBody>
        </NavTabPanel>
        <NavTabPanel>
          <CardBody>
            <PauseSlide />
          </CardBody>
        </NavTabPanel>
      </NavTabs>
    </CardContainer>
  );
};

export default Settings;

export interface SettingsModel {
  id?: number;
  paymentThreshold?: number;
  pauseSlidePath?: string;
  isDemo?: boolean;
}
