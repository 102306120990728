import styled, { css } from "styled-components";
import CheckMark from "../../assets/icons/Check-white.svg";
import { DynamicTransitionDuration } from "../../globalStyles";
import { defaultBoxShadow } from "../../assetsConfig";

// display duration for the success message
export const SUCCESS_MESSAGE_DURATION = 3000;

export const FocusBorder = styled.div`
	position: absolute;
	top: 0;
	height: 100%;
	background: ${({ theme }) => theme.colorCopyLight};
	width: 2px;
	transition: all 50ms ease;
`;

export const Label = styled.label`
	position: absolute;
	left: 16px;
	top: 20px;
	font-size: 1rem;
	color: ${({ theme }) => theme.colorCopyLight};
	user-select: none;
	z-index: 1;
	transition: all 150ms ease;
	cursor: text;
	pointer-events: none;
`;

export const FieldError = styled.div<{ position?: string }>`
	position: ${(p) => (p.position ? p.position : "absolute")};
	margin: 0.5rem 0 0 1rem;
	color: ${({ theme }) => theme.colorDanger};
`;

export const FieldSuccess = styled(FieldError)`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	color: ${({ theme }) => theme.colorCopyDarkDark};

	&.success-message-enter {
		left: 5px;
		opacity: 0;
	}
	&.success-message-enter-active,
	&.success-message-enter-done {
		transition: all ${DynamicTransitionDuration} ease;
		opacity: 1;
		left: 0;
	}
	&.success-message-exit {
		opacity: 1;
		left: 0;
	}
	&.success-message-exit-active {
		transition: all ${DynamicTransitionDuration} ease;
		opacity: 0;
		left: 5px;
	}
`;

export const FormField = styled.div<FormFieldProps>`
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	background: ${({ theme }) => theme.colorBackgroundLightLight};
	cursor: pointer;

	${(p) =>
		!p.hideShadow &&
		css`
			box-shadow: ${defaultBoxShadow};
		`}

	${(p) =>
		p.background &&
		css`
			background: ${p.background};
		`}

  input {
		background: transparent; // remove default input background
	}

	.dollar-symbol {
		display: none;
		position: absolute;
		padding-top: 1.25rem;
		left: 1rem;
		height: 50px;
		align-items: center;
	}

	&.hasValue,
	&.focused {
		${Label} {
			font-size: 85%;
			top: 6px;
		}

		${FocusBorder} {
			width: 4px;
		}

		${(p) =>
			p.iscurrency &&
			css`
				.dollar-symbol {
					display: flex;
				}
			`}
	}

	&.hasValue {
		${Label} {
			color: ${({ theme }) => theme.colorCopyLight};
		}

		${(p) => css`
			${FocusBorder} {
				background: ${p.error
					? p.theme.colorDanger
					: p.success
					? p.theme.colorSuccess
					: p.theme.colorCopyLight};
			}
		`}
	}

	&.focused {
		${Label} {
			color: ${({ theme }) => theme.colorActivation};
		}

		/* ${FocusBorder} {
			background: ${({ theme }) => theme.colorActivation};
			transition: background 150ms ease;
		} */

		${(p) => css`
			${FocusBorder} {
				background: ${p.error
					? p.theme.colorDanger
					: p.success
					? p.theme.colorSuccess
					: p.theme.colorActivation};
			}
		`}
	}

	/* disabled OR read only state */
	${(p) =>
		(p.readOnly || p.disabled) &&
		css`
			background: ${p.disabled
				? p.theme.colorFieldDisabled
				: p.theme.colorFieldReadOnly};

			input {
				color: ${p.theme.colorTextDisabled};
			}

			${Label} {
				color: ${p.theme.colorLabelDisabled} !important;
			}

			${FocusBorder} {
				display: none;
			}
		`}

	${(p) =>
		p.hiddenlabel &&
		css`
			${Label} {
				display: none;
			}
		`}

	${(p) =>
		p.error &&
		css`
			${FocusBorder} {
				background: ${({ theme }) => theme.colorDanger};
			}
		`}

		/* @keyframes focusBorder {
		from {
			${FocusBorder} {
				background: initial;
			}
		}
		to {
			${FocusBorder} {
				background: ${({ theme }) => theme.colorSuccess} !important;
			}
		}
	} */

	${(p) =>
		p.success &&
		css`
			${FocusBorder} {
				background: ${({ theme }) => theme.colorSuccess};
				transition: background 150ms ease;
			}
		`}

	@media only screen and (min-width: ${({ theme }) => theme.md}) {
		width: initial;
	}

	${(p) =>
		p.statusColor &&
		css`
			${FocusBorder} {
				background: ${p.statusColor} !important;
				width: 4px !important;
			}
		`}
`;

interface FormFieldProps {
	className?: string;
	disabled?: boolean;
	readOnly?: boolean;
	hiddenlabel: boolean;
	error?: string;
	success?: string;
	iscurrency?: boolean;
	validate?(): void;
	statusColor?: string; // overrides focus border color
	background?: string;
	hideShadow?: boolean;
}
