import styled, { useTheme, css } from "styled-components";
import { IconWrapper } from "../Button/Button";
import { defaultBoxShadow } from "../../assetsConfig";

export const Card = styled.div<{ maxHeight?: string; background?: string }>`
	display: flex;
	flex-direction: column;
	position: sticky;
	width: 100%;
	top: calc(2.75rem + 55.391px);
	height: max-content;
	min-height: 509px;
	max-height: calc(100vh - (2.75rem * 2) - 55.391px);
	background: ${({ theme }) => theme.colorBackgroundLightLight};
	box-shadow: ${defaultBoxShadow};

	.card-title {
		flex: 1;
		overflow-wrap: anywhere;
		font-size: ${({ theme }) => theme.h2Size};
		color: ${({ theme }) => theme.colorCopyLightLight};
	}

	@media (max-width: 991px) {
		position: relative;
	}

	${(p) =>
		p.maxHeight &&
		css`
			max-height: ${p.maxHeight};
		`}

	${(p) =>
		p.background &&
		css`
			background: ${p.background};
		`}
`;

export const StickyCardHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;
	background: ${({ theme }) => theme.colorBackgroundDarkDark};
	font-size: ${({ theme }) => theme.h2Size};
	color: ${({ theme }) => theme.colorCopyLightLight};
	padding: 1.25rem 1.5rem;
`;

export const StickyHeaderBar = styled.div`
	display: flex;
	gap: 3rem;
	background: ${({ theme }) => theme.gradientBlackGray};
	padding: 1.25rem 1.5rem;
`;

export const StickyCardBody = styled.div`
	padding: 3.125rem;
	padding-top: 1.5625rem;
	overflow: auto;
`;

// container for 2 sticky cards
export const StickyContainer = styled.div<{
	maxH?: string;
}>`
	position: sticky;
	width: 100%;
	height: fit-content;
	top: calc(2.75rem + 55.391px);
	min-height: 509px;

	${Card} {
		display: flex;
		flex-direction: column;
		position: relative;
		top: 0;
		min-height: 0;
		max-height: calc(((100vh - 2.75rem * 3) - 65px));

		${(p) =>
			p.maxH &&
			css`
				max-height: ${p.maxH};
			`}

		${StickyCardBody} {
			overflow: auto;
		}

		@media only screen and (max-width: ${({ theme }) => theme.sm}) {
			max-height: 100%;
		}
	}
`;

const StickyCard = (props: StickyCardProps) => {
	const theme = useTheme();

	return (
		<Card
			id={props.id}
			className={`${props.className ? props.className : ""}`}
			maxHeight={props.maxHeight || undefined}
			background={props.background}
		>
			{(props.title || props.titleElement) && (
				<StickyCardHeader>
					<span className="card-title">
						{props.title || props.titleElement}
					</span>
					<div className="flex flex-wrap gap-[1.5625rem]">
						{props.rightTitle && (
							<p className="right-title">{props.rightTitle}</p>
						)}
						{/* array of icons */}
						{props.headerIcons &&
							Array.isArray(props.headerIcons) &&
							props.headerIcons.map((Icon1, i) => {
								return (
									<div
										key={`icon.${i}`}
										className="icon-wrapper flex items-center"
									>
										{Icon1}
									</div>
								);
							})}

						{/* 1 icon */}
						{props.headerIcons && !Array.isArray(props.headerIcons) && (
							<IconWrapper color={props.iconColor || theme.colorPrimary}>
								{props.headerIcons}
							</IconWrapper>
							// <Icon icon={{props.headerIcons}} color={props.iconColor || theme.colorCopyLightLight}/>
						)}
					</div>
				</StickyCardHeader>
			)}

			{props.children}
		</Card>
	);
};

export default StickyCard;

interface StickyCardProps {
	id?: string;
	title?: string;
	rightTitle?: string;
	children?: React.ReactNode;
	className?: string;
	headerIcons?: React.ReactNode[] | React.ReactElement;
	iconColor?: string;
	isContainer?: boolean;
	maxHeight?: string;
	titleElement?: React.ReactNode[] | React.ReactElement;
	background?: string;
}
