import { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams, useLocation, useHistory, Prompt } from "react-router-dom";
import { EntryModel } from "./EntryInterfacesComplete";
import { getEntryById, getNewEntryByProgramId } from "./manageEntry";
import { getClaims } from "../../components/Auth/handleJWT";
import Loading from "../../components/Loading/Loading";
import { ExecutionModel } from "../Entries/EntryInterfacesComplete";
import EntryForm, { ENTRY_FORM_ROUTES } from "./EntryForm";
import AdminEntryEdit from "../Admin/Entries/AdminEntryEdit";
import AdminMediaEdit from "../Admin/Entries/AdminMediaEdit";
import AdminCreditEdit from "../Admin/Entries/AdminCreditEdit";

// sort executions based on index
export const getCorrectExecutionOrder = (executions: ExecutionModel[]) => {
	return executions.sort((a, b) => a.index - b.index);
};

// export const useUserVisited = () => {
// 	const location = useLocation<State>();
// 	const prev =
// 		location.state && location.state.hasOwnProperty("from")
// 			? location.state.from
// 			: undefined;

// 	// true = user is navigating from another tab within the entry form
// 	// false = user is editing entry from another route
// 	const hasUserVisited = prev
// 		? Object.values(ENTRY_FORM_ROUTES).some((route) =>
// 				prev.includes(route.path)
// 		  )
// 		: false;

// 	return { prev, hasUserVisited };
// };

const EditEntry = (props: EditEntryProps) => {
	const [entry, setEntry] = useState<AdminEntryModel | undefined>();
	const history = useHistory();
	const location = useLocation<State>();
	const params = new URLSearchParams(location.search);
	const entryId = params.get("entryId");
	const programId = params.get("programId");
	const [tabIndex, setTabIndex] = useState(0);
	const { tab } = useParams<{ tab: string }>();
	// const [isEntryFormSaved, setIsEntryFormSaved] = useState(true);
	// const { hasUserVisited } = useUserVisited();

	// determine if the user viewing the entry is an admin
	const claims = getClaims();
	const isAdmin = claims.some(
		(claim) => claim.name === "role" && claim.value === "admin"
	);

	useEffect(() => {
		if (entry?.id) {
			const redirectedTab = tab ? tab : "basic-info";

			if (!isAdmin) {
				history.push(`/edit-entry/${redirectedTab}/?entryId=${entry?.id}`, {
					from: history.location.pathname,
				});
			}
		}
	}, [entry]);

	useEffect(() => {
		const pathIndex = Object.values(ENTRY_FORM_ROUTES).find(
			(route) => route.path === tab
		)?.index;

		if (pathIndex !== undefined) setTabIndex(pathIndex);
	}, [tab]);

	useEffect(() => {
		if (entryId) {
			getEntryById(Number(entryId)).then(async (response) => {
				// if 401 or 500, redirect to login
				// in case of error, response will be an Error object that has a response property
				const status = response?.response?.status || response?.status;

				if (status === 401 || status === 500) {
					history.push("/");
				}
				if (status === 200) {
					handleEntryResponse(response);
				}
			});
		} else if (programId) {
			getNewEntryByProgramId(programId.toString()).then(
				async (response: AxiosResponse<EntryModel, any>) => {
					if (response.status === 201) {
						handleEntryResponse(response);
					}
				}
			);
		} else {
			history.push("/");
		}

		function handleEntryResponse(response: any) {
			const hasExecution =
				response.data.executions && response.data.executions.length > 0;
			setEntry({
				...response.data,
				...(hasExecution && {
					...getCorrectExecutionOrder(response.data.executions),
				}),
			});
		}
	}, []);

	const adminEditPage = () => {
		if (props.pageType === AdminEditPageType.EditEntry && entry) {
			return <AdminEntryEdit {...entry} />;
		} else if (props.pageType === AdminEditPageType.Media && entry) {
			return <AdminMediaEdit {...entry} />;
		} else if (props.pageType === AdminEditPageType.Credits && entry) {
			return <AdminCreditEdit {...entry} />;
		} else return <></>;
	};

	return entry ? (
		isAdmin && props.pageType !== undefined ? (
			adminEditPage()
		) : (
			<>
				{/* <Prompt
					when={!isEntryFormSaved && !hasUserVisited}
					message="If you leave this page, you may lose any work in progress."
				/> */}
				<EntryForm
					{...entry}
					tabIndex={tabIndex}
					// setIsEntrySaved={(isSaved) => setIsEntryFormSaved(isSaved)}
				/>
			</>
		)
	) : (
		<>
			<Loading />
		</>
	);
};

export default EditEntry;

interface EditEntryProps {
	pageType?: AdminEditPageType;
}

interface State {
	entry: number;
	from?: string;
}

export interface AdminEntryModel extends EntryModel {
	judgingNote?: string;
	entryReviewNote?: string;
	juryGroup?: number;
}

export enum AdminEditPageType {
	EditEntry,
	Media,
	Credits,
}
