import { useState, useEffect } from "react";
import Sort from "../../../../components/Sort/Sort";
import styled from "styled-components";
import { CreditType } from "../../Program/ProgramInterfaces";
import { IndividualCreditGridCSS } from "./IndividualCreditList";
import { CompanyCreditGridCSS } from "./CompanyCreditList";

export enum SortDirection {
  Asc,
  Desc,
  None,
}

export enum CreditSortType {
  UploadDateAsc,
  UploadDateDesc,
}

export const creditSortOptions = [
  {
    value: CreditSortType.UploadDateAsc,
    label: "Date Added (Oldest-newest)",
  },
  {
    value: CreditSortType.UploadDateDesc,
    label: "Date Added (Newest-Oldest)",
  },
];

const CompanyCreditSortLabels = [
  {
    sortKey: "name",
    label: "Company Name A to Z",
  },
  {
    sortKey: "city",
    label: "Company City (A-Z)",
  },
  {
    sortKey: "isActive",
    label: "Status",
  },
];

const IndividualCreditSortLabels = [
  {
    sortKey: "fullName",
    label: "Name A to Z",
  },

  {
    sortKey: "companyName",
    label: "Company Name (A-Z)",
  },
  {
    sortKey: "companyCity",
    label: "Company City (A-Z)",
  },
  {
    sortKey: "isActive",
    label: "Status",
  },
];

const getOppositeSortDirection = (sortDirection: SortDirection) => {
  if (
    sortDirection === SortDirection.None ||
    sortDirection === SortDirection.Desc
  ) {
    return SortDirection.Asc;
  } else {
    return SortDirection.Desc;
  }
};

export const handleSort = (
  credits: any[],
  sortDirection: SortDirection,
  sortKey: string
) => {
  const oppositeSortDirection = getOppositeSortDirection(sortDirection);
  // sort by isActive status
  //   if (sortKey === "isActive") {
  //     if (oppositeSortDirection === SortDirection.Asc) {
  //       return credits.sort((a, b) => (a.isActive ? 1 : -1));
  //     } else return credits.sort((a, b) => (a.isActive ? -1 : 1));
  //   }
  // sort by credit key (e.g. companyName)
  //   else {
  const sortedData = credits.sort((credit1: any, credit2: any) => {
    if (
      (credit1[sortKey] === null || credit1[sortKey] === undefined) &&
      (credit2[sortKey] === null || credit2[sortKey] === undefined)
    ) {
      // both values are null/undefined, keep the same order
      return 0;
    } else if (credit1[sortKey] === null || credit1[sortKey] === undefined) {
      // value 1 is null/undefined

      return oppositeSortDirection === SortDirection.Asc ? -1 : 1;
    } else if (credit2[sortKey] === null || credit2[sortKey] === undefined) {
      // value 2 is null/undefined
      return oppositeSortDirection === SortDirection.Asc ? 1 : -1;
    }
    // both values are defined
    return oppositeSortDirection === SortDirection.Asc
      ? credit1[sortKey].localeCompare(credit2[sortKey])
      : credit2[sortKey].localeCompare(credit1[sortKey]);
  });
  return sortedData;
  //   }
};

const CreditSort = (props: {
  className?: string;
  sortLabel: string;
  sortKey: string;
  credits: any[];
  setActiveSort(sort: ActiveSort): void;
}) => {
  const [sortDirection, setSortDirection] = useState(SortDirection.None);

  return (
    <Sort
      className={`"text-xxSmallSize" ${props.className ? props.className : ""}`}
      label={props.sortLabel}
      ascending={sortDirection === SortDirection.Asc}
      triangleSize="12px"
      onClick={() => {
        if (props.sortKey !== "isActive") {
          props.setActiveSort({
            sortKey: props.sortKey,
            direction: sortDirection,
          });

          setSortDirection(getOppositeSortDirection(sortDirection));
        }
      }}
      noSortTriangle={props.sortKey === "isActive"}
    />
  );
};

const SortContainer = styled.div<{ creditType: CreditType }>`
  ${(p) =>
    p.creditType ? `${IndividualCreditGridCSS}` : `${CompanyCreditGridCSS}`};

  color: ${({ theme }) => theme.colorCopyLightLight};
  padding: 1rem 0;
  background: ${({ theme }) => theme.colorBackgroundDarkDark};
`;

const SortCreditLibrary = (props: SortCreditLibraryProps) => {
  const [activeSort, setActiveSort] = useState<null | ActiveSort>(null); // only 1 sort can be active at a time
  const isCompanyCredit = props.creditType === CreditType.Company;

  useEffect(() => {
    if (activeSort) {
      props.setCredits(
        handleSort(props.credits, activeSort.direction, activeSort.sortKey)
      );
    } else {
      props.setCredits(props.credits);
    }
  }, [props.credits, activeSort]);

  return (
    <SortContainer creditType={props.creditType}>
      {/* <span className="!pl-[2rem]">Individual Credits</span> */}

      {(isCompanyCredit
        ? CompanyCreditSortLabels
        : IndividualCreditSortLabels
      ).map((sortType, i) => (
        <CreditSort
          className={i === 0 ? "pl-[2rem]" : ""}
          key={sortType.sortKey}
          sortLabel={sortType.label}
          sortKey={sortType.sortKey}
          credits={props.credits}
          setActiveSort={setActiveSort}
        />
      ))}
    </SortContainer>
  );
};

export default SortCreditLibrary;

interface SortCreditLibraryProps {
  credits: any[];
  setCredits(credits: any[]): void;
  creditType: CreditType;
}

interface ActiveSort {
  sortKey: string;
  direction: SortDirection;
}
