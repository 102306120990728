import { Fragment, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
	getStoredValue,
	setStoredValue,
} from "../../../components/LocalStorageStorage/LocalStorageStore";
import {
	DropdownOptions,
	useAwardableAwards,
} from "../../../hooks/useAwardableAwards";
import { Ancestor } from "../Program/ProgramInterfaces";
import { useFormik, FormikProvider } from "formik";
import axios from "axios";
import { urlCredits } from "../../../endpoints";
import { AdminPageControls, SearchFieldsContainer } from "./AdminSearch";
import TextField from "../../../components/FormFields/TextField";
import MultiDropdown from "../../../components/MultiDropdown/MultiDropdown";
import {
	PaymentStatusOptions,
	ReviewStatusOptions,
} from "../../Checkout/OrderInterfaces";
import DropdownField from "../../../components/FormFields/DropdownField";
import { CountryOptions } from "../../../data/CountryData";
import { useSeasons } from "../../../hooks/useSeasons";
import Button from "../../../components/Button/Button";
import EntryExportColumnsModal, {
	EntryExportColumn,
} from "./EntryExportColumnsModal";
import dateTostring from "../../../utils/dateToString";
import { getHierarchy } from "../../Checkout/manageCheckout";
import styled from "styled-components";
import Table, { TablePlaceholder } from "../../../components/Table/Table";
import Loading from "../../../components/Loading/Loading";
import { useProgramAwards } from "../../../hooks/useProgramAwards";
import config from "../../../config";
import { getCompanyCreditFieldTemplatesOptions } from "../Program/manageFieldTemplates";
import assetsConfig from "../../../assetsConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const StyledLink = styled(Link)`
	color: ${({ theme }) => theme.colorActivation};
`;

const initialSearch: CompanyCreditsSearchRequest = {
	entryId: undefined,
	entryTitle: undefined,
	entryBrand: undefined,
	entryAward: undefined,
	entrySeason: undefined,
	authorCompany: undefined,
	entryPaymentStatus: [],
	entryReviewStatus: [],
	entryAwardLevel: undefined,
	isCampaign: undefined,
	companyName: undefined,
	companyType: undefined,
	companyCity: undefined,
	companyCountry: undefined,
	creditFieldTemplateId: undefined,
};

const getScrubbedRequest = (
	request: CompanyCreditsSearchRequest
): CompanyCreditsSearchRequest => {
	let result = {
		entryId: request.entryId ? Number(request.entryId) : undefined,
		entryTitle: request.entryTitle ? request.entryTitle : undefined,
		entryBrand: request.entryBrand ? request.entryBrand : undefined,
		entryAward: request.entryAward ? request.entryAward : undefined,
		entrySeason: request.entrySeason ? request.entrySeason : undefined,
		authorCompany: request.authorCompany ? request.authorCompany : undefined,
		entryPaymentStatus: request.entryPaymentStatus
			? request.entryPaymentStatus
			: undefined,
		entryReviewStatus: request.entryReviewStatus
			? request.entryReviewStatus
			: undefined,
		entryAwardLevel: request.entryAwardLevel
			? request.entryAwardLevel
			: undefined,
		isCampaign: request.isCampaign ? JSON.parse(request.isCampaign) : undefined,
		companyName: request.companyName ? request.companyName : undefined,
		companyType: request.companyType ? request.companyType : undefined,
		companyCity: request.companyCity ? request.companyCity : undefined,
		companyCountry: request.companyCountry ? request.companyCountry : undefined,
		creditFieldTemplateId: request.creditFieldTemplateId
			? request.creditFieldTemplateId
			: undefined,
	};

	return result;
};

const CompanyCreditsSearchTab = () => {
	const [searchVal, setSearchVal] = useState<CompanyCreditsSearchRequest>(
		getStoredValue("companyCreditsSearch") || initialSearch
	);
	const [isActive, setActive] = useState(false);
	const [companyCredits, setCompanyCredits] = useState<
		CompanyCreditSearchResult[]
	>([]);
	const [page, setPage] = useState(1);
	const [totalAmountOfPages, settotalAmountOfPages] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState(50);
	const [totalRecords, setTotalRecords] = useState(0);
	const [errors, setErrors] = useState<string[]>([]);
	const history = useHistory();
	const { settings } = useSelector((state: RootState) => state.settings);
	const [csvEntries, setCsvEntries] = useState<any[]>([]);
	const [csvLoading, setCsvLoading] = useState(false);
	const awardOptions = useAwardableAwards();
	const programAwards = useProgramAwards();
	const seasonOptions = useSeasons();
	const [isLoading, setIsLoading] = useState(false);
	const isCampaignOptions: DropdownOptions[] = [
		{ value: true, label: "Campaign" },
		{ value: false, label: "Single" },
	];
	const [showExportColumnsModal, setShowExportColumnsModal] = useState(false);
	const [selectedExportColumns, setSelectedExportColumns] = useState<
		EntryExportColumn[]
	>(getStoredValue("selectedCompanyCreditExportColumns") || []);

	const [creditFieldTemplateOptions, setCreditFieldTemplateOptions] = useState<
		DropdownOptions[]
	>([]);

	useEffect(() => {
		getCompanyCreditFieldTemplatesOptions()
			.then((resp) => {
				if (resp.status === 200) {
					setCreditFieldTemplateOptions(resp.data);
				}
			})
			.catch((error) => {
				console.log("getCompanyCreditFieldTemplatesOptions error", error);
			});
	}, []);

	useEffect(() => {
		if (companyCredits.length > 1) {
			searchCompanyCredits(formikProps.values);
		}
	}, [page, recordsPerPage]);

	function handleSelectedColumns(columns: EntryExportColumn[]) {
		setCsvLoading(true);

		setSelectedExportColumns(columns);

		setStoredValue("selectedCompanyCreditExportColumns", columns);

		const newRequest = getScrubbedRequest(formikProps.values);

		const queryParams = columns.map((p) => "columns=" + p.column).join("&");

		axios
			.post(`${urlCredits}/search/export/company?${queryParams}`, newRequest, {
				responseType: "blob",
			})
			.then((response) => {
				const temp = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");

				link.href = temp;
				link.setAttribute(
					"download",
					`Company Credit Search Export ${dateTostring(new Date())}.csv`
				);
				document.body.appendChild(link);
				link.click();

				setCsvLoading(false);
			})
			.catch((error: any) => {
				return error;
			});
	}

	const searchCompanyCredits = async (request: CompanyCreditsSearchRequest) => {
		const newRequest = getScrubbedRequest(request);
		const response = await axios.post(
			`${urlCredits}/search-company-credits`,
			newRequest,
			{
				params: { page, recordsPerPage },
			}
		);

		if (response.status === 200) {
			setCompanyCredits([]); // This cleans the results and forces a React re-render in cases of sorting.
			setCompanyCredits(response.data);
			const total = parseInt(response.headers["totalamountofrecords"]);
			setTotalRecords(total);
			settotalAmountOfPages(Math.ceil(total / recordsPerPage));
			setIsLoading(false);
		}
	};

	async function PostSearch(request: CompanyCreditsSearchRequest) {
		//console.log("pre-request ", request);
		try {
			const newRequest = getScrubbedRequest(request);

			//console.log("request ", newRequest);
			setErrors([]);
			setActive(false);
			const response = await axios.post(
				`${urlCredits}/search-company-credits`,
				newRequest,
				{
					params: { page, recordsPerPage },
				}
			);

			if (response.status === 200) {
				setCompanyCredits(response.data);
				const total = parseInt(response.headers["totalamountofrecords"]);
				setTotalRecords(total);
				settotalAmountOfPages(Math.ceil(total / recordsPerPage));
				setActive(true);
				setIsLoading(false);
			}
		} catch (error: any) {
			// console.log(error);
			//setErrors(error.response.data);
			setIsLoading(false);
		}
	}

	const fieldRequired = "This field is required";
	const formikProps = useFormik({
		initialValues: searchVal,
		onSubmit: async (value) => {
			setStoredValue("companyCreditsSearch", getScrubbedRequest(value));
			setPage(1);
			await PostSearch(value);
			// console.log(value);
		},
	});

	return (
		<FormikProvider value={formikProps}>
			<SearchFieldsContainer>
				<TextField
					name="entryId"
					placeholder={assetsConfig.labels.entry.singular + " Id"}
					value={formikProps.values.entryId}
				/>
				<TextField
					name="authorCompany"
					placeholder={"Author " + assetsConfig.labels.company.singular}
					value={formikProps.values.authorCompany}
				/>
				<TextField
					name="companyName"
					placeholder="Company Name"
					value={formikProps.values.companyName}
				/>
				<TextField
					name="entryTitle"
					placeholder={assetsConfig.labels.entry.singular + " Title"}
					value={formikProps.values.entryTitle}
				/>
				{!settings.isDemo && (
					<MultiDropdown
						placeholder={assetsConfig.labels.entry.singular + " Payment Status"}
						options={PaymentStatusOptions}
						selectedValues={(
							formikProps.values.entryPaymentStatus || []
						).flatMap((value: number) => {
							const correspondingOption = PaymentStatusOptions.find(
								(option: DropdownOptions) => option.value === value
							);
							return correspondingOption ? [correspondingOption] : [];
						})}
						onChange={(selectedList) => {
							formikProps.setFieldValue(
								"entryPaymentStatus",
								selectedList.map((x) => Number(x.value))
							);
						}}
					/>
				)}
				<TextField
					name="companyType"
					placeholder="Company Type"
					value={formikProps.values.companyType}
				/>
				<TextField
					name="entryBrand"
					placeholder={assetsConfig.labels.entry.singular + " Brand"}
					value={formikProps.values.entryBrand}
				/>
				<MultiDropdown
					placeholder="Review Status"
					options={ReviewStatusOptions}
					selectedValues={(formikProps.values.entryReviewStatus || []).flatMap(
						(value: number) => {
							const correspondingOption = ReviewStatusOptions.find(
								(option: DropdownOptions) => option.value === value
							);
							return correspondingOption ? [correspondingOption] : [];
						}
					)}
					onChange={(selectedList) => {
						formikProps.setFieldValue(
							"entryReviewStatus",
							selectedList.map((x) => Number(x.value))
						);
					}}
				/>
				<TextField
					name="companyCity"
					placeholder="Company City"
					value={formikProps.values.companyCity}
				/>
				{!settings.isDemo && (
					<MultiDropdown
						placeholder={assetsConfig.labels.entry.singular + " Award"}
						options={programAwards}
						selectedValues={
							Array.isArray(formikProps.values.entryAward)
								? formikProps.values.entryAward.flatMap((value: number) => {
										const correspondingOption = programAwards.find(
											(option: DropdownOptions) => option.value === value
										);
										return correspondingOption ? [correspondingOption] : [];
								  })
								: []
						}
						onChange={(selectedList) => {
							formikProps.setFieldValue(
								"entryAward",
								selectedList.map((x) => x.value)
							);
						}}
					/>
				)}
				{!settings.isDemo && (
					<MultiDropdown
						placeholder={assetsConfig.labels.entry.singular + " Award Level"}
						options={awardOptions}
						selectedValues={
							Array.isArray(formikProps.values.entryAwardLevel)
								? formikProps.values.entryAwardLevel.map((value) => ({
										value,
										label: value,
								  }))
								: []
						}
						onChange={(selectedList) => {
							formikProps.setFieldValue(
								"entryAwardLevel",
								selectedList.map((x) => x.value)
							);
						}}
					/>
				)}
				<MultiDropdown
					placeholder="Company Country"
					options={CountryOptions}
					selectedValues={(formikProps.values.companyCountry || []).map(
						(label) => ({
							label,
							value: label,
						})
					)}
					onChange={(selectedList) => {
						formikProps.setFieldValue(
							"companyCountry",
							selectedList.map((x) => x.label)
						);
					}}
				/>
				{!settings.isDemo && (
					<MultiDropdown
						placeholder={assetsConfig.labels.entry.singular + " Season"}
						options={seasonOptions}
						selectedValues={
							Array.isArray(formikProps.values.entrySeason)
								? formikProps.values.entrySeason.flatMap((value: number) => {
										const correspondingOption = seasonOptions.find(
											(option: DropdownOptions) => option.value === value
										);
										return correspondingOption ? [correspondingOption] : [];
								  })
								: []
						}
						onChange={(selectedList) => {
							formikProps.setFieldValue(
								"entrySeason",
								selectedList.map((x) => x.value)
							);
						}}
					/>
				)}

				{!settings.isDemo && (
					<DropdownField
						name="isCampaign"
						placeholder="Campaign/Single"
						options={isCampaignOptions}
						value={formikProps.values.isCampaign}
					/>
				)}

				<MultiDropdown
					placeholder="Field Type"
					options={creditFieldTemplateOptions}
					selectedValues={
						Array.isArray(formikProps.values.creditFieldTemplateId)
							? formikProps.values.creditFieldTemplateId.flatMap(
									(value: number) => {
										const correspondingOption = creditFieldTemplateOptions.find(
											(option: DropdownOptions) => option.value === value
										);
										return correspondingOption ? [correspondingOption] : [];
									}
							  )
							: []
					}
					onChange={(selectedList) => {
						formikProps.setFieldValue(
							"creditFieldTemplateId",
							selectedList.map((x) => x.value)
						);
					}}
				/>
			</SearchFieldsContainer>
			<div className="my-[1rem] flex gap-[1rem] justify-end flex-wrap">
				<Button
					className="button-light w-[150px]"
					type="button"
					onClick={() => {
						formikProps.setValues(initialSearch);
						setSearchVal(initialSearch);
						setCompanyCredits([]);
						setActive(false);
						setStoredValue("companyCreditsSearch", initialSearch);
						setPage(1);
					}}
				>
					Reset Search
				</Button>
				<EntryExportColumnsModal
					show={showExportColumnsModal}
					selectedExportColumns={selectedExportColumns}
					onClickHide={() => setShowExportColumnsModal(false)}
					onSelectionChange={(columns) => handleSelectedColumns(columns)}
					localStorageKey="selectedCompanyCreditExportColumns"
					getExportGroupsEndpoint={`${urlCredits}/search/export/company/columns`}
				/>
				<Button
					className="button-light w-[150px]"
					disabled={companyCredits.length < 1}
					onClick={() => setShowExportColumnsModal(true)}
				>
					<div className="flex items-center gap-[.5rem]">
						Export
						{csvLoading && (
							<img className="w-[16px]" src={config.assets.loading.primary} />
						)}
					</div>
				</Button>

				<Button
					className="w-[150px]"
					type="submit"
					onClick={() => {
						setIsLoading(true);
						formikProps.submitForm();
					}}
				>
					Search
				</Button>
			</div>
			{/* top pagination controls */}
			{isActive && (
				<AdminPageControls
					totalRecords={totalRecords}
					totalAmountOfPages={totalAmountOfPages}
					currentPage={page}
					setPage={(newPage) => setPage(newPage)}
					setIsLoading={(isLoading) => setIsLoading(isLoading)}
				/>
			)}
			{!isLoading &&
				(companyCredits && companyCredits.length > 0 ? (
					<Table
						isActive={isActive}
						dualScroll
						columnLabels={[
							"Entry Id",
							"Entry Title",
							"Entry Brand",
							"Program Level 1",
							"Program Level 2",
							"Program Level 3",
							"Program Level 4",
							"Program Level 5",
							"Award Level",
							"Credit Field Name",
							"Credit Company Name",
							"Company Type",
							"Credit Company City",
							"Credit Company Country",
						]}
						labelAlias={[
							assetsConfig.labels.entry.singular + " Id",
							assetsConfig.labels.entry.singular + " Title",
							assetsConfig.labels.entry.singular + " Brand",
							"Program Level 1",
							"Program Level 2",
							"Program Level 3",
							"Program Level 4",
							"Program Level 5",
							"Award Level",
							"Credit Field Name",
							"Credit Company Name",
							"Company Type",
							"Credit Company City",
							"Credit Company Country",
						]}
						firstColumnWidth="125px"
					>
						{companyCredits.map((credit: CompanyCreditSearchResult, index) => {
							const hierarchy =
								(credit.batchLabel && credit.batchLabel.split(" > ")) || [];
							return (
								<Fragment key={credit.entryId}>
									<div className="cell">
										<p>
											<StyledLink
												to={`/admin/edit-entry?entryId=${credit.entryId}`}
												target="_blank"
											>
												{credit.entryId}
											</StyledLink>
										</p>
									</div>
									<div className="cell">
										<p>
											<StyledLink
												to={`/admin/edit-entry?entryId=${credit.entryId}`}
												target="_blank"
											>
												{credit.entryTitle}
											</StyledLink>
										</p>
									</div>
									<div className="cell">
										<p>{credit.entryBrand}</p>
									</div>
									<div className="cell">
										<p>{credit.ancestry.at(0)}</p>
									</div>
									<div className="cell">
										<p>{credit.ancestry.at(1)}</p>
									</div>
									<div className="cell">
										<p>{credit.ancestry.at(2)}</p>
									</div>
									<div className="cell">
										<p>{credit.ancestry.at(3)}</p>
									</div>
									<div className="cell">
										<p>{credit.ancestry.at(4)}</p>
									</div>
									<div className="cell">
										<p>{credit?.awardLevel}</p>
									</div>
									<div className="cell">
										<p>{credit?.creditFieldName}</p>
									</div>
									<div className="cell">
										<p>{credit?.creditCompanyName}</p>
									</div>
									<div className="cell">
										<p>{credit?.creditCompanyType}</p>
									</div>
									<div className="cell">
										<p>{credit?.creditCompanyCity}</p>
									</div>
									<div className="cell">
										<p>{credit?.creditCompanyCountry}</p>
									</div>
								</Fragment>
							);
						})}
					</Table>
				) : (
					<TablePlaceholder active={isActive}>
						Please add a search to see result table.
					</TablePlaceholder>
				))}

			{isLoading && (
				<TablePlaceholder active={false}>
					<Loading fullScreen={false} showLogo={false} />
				</TablePlaceholder>
			)}

			{/* bottom pagination controls */}
			{isActive && !isLoading && (
				<AdminPageControls
					totalRecords={totalRecords}
					totalAmountOfPages={totalAmountOfPages}
					currentPage={page}
					setPage={(newPage) => setPage(newPage)}
					setIsLoading={(isLoading) => setIsLoading(isLoading)}
				/>
			)}
		</FormikProvider>
	);
};

export interface CompanyCreditsSearchRequest {
	entryId?: number;
	entryTitle?: string;
	entryBrand?: string;
	entryAward?: number[];
	entrySeason?: number[];
	authorCompany?: string;
	entryPaymentStatus?: number[];
	entryReviewStatus?: number[];
	entryAwardLevel?: string;
	isCampaign?: string;
	companyName?: string;
	companyType?: string;
	companyCity?: string;
	companyCountry?: string[];
	creditFieldTemplateId?: number[];
}

export interface CompanyCreditSearchResult {
	entryId: number;
	entryTitle: string;
	entryBrand: string;
	ancestry: string[];
	awardLevel: string;
	batchLabel: string;
	creditFieldName: string;
	creditCompanyName: string;
	creditCompanyType: string;
	creditCompanyCity: string;
	creditCompanyCountry: string;
}

export default CompanyCreditsSearchTab;
