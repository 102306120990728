function addEllipsisAfterLetterCount(text: string, letterCount: number) {
	if (text.length <= letterCount) {
		return text;
	} else {
		const truncatedText = text.slice(0, letterCount) + "...";
		return truncatedText;
	}
}

export default addEllipsisAfterLetterCount;
