import { CardBody } from "../../../../components/DashboardCard/DashboardCard";
import { useTheme, css } from "styled-components";
import TextField from "../../../../components/FormFields/TextField";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import Icon from "../../../../components/Icon/Icon";
import {
	CreateEntrySetModel,
	EntrySetConfig,
	EntrySetDefinitionConfig,
} from "../JudgingInterfaces";
import {
	createEntrySet,
	deleteEntrySet,
	finalizeEntrySet,
	getEntrySetDefinitionsByRootProgramId,
	getEntrySetsByJuryId,
	saveEntrySetDefinitions,
	saveEntrySetName,
	updateEntrySetProperty,
} from "../manageJudging";

import DragEntrySetDefinitionCard from "../../../../components/DragEntrySetDefinition/DragEntrySetDefinition";
import { ButtonContainer, JuryConfigTabProps } from "./JudgingConfigJury";
import Button from "../../../../components/Button/Button";
import { JuryCardModel } from "../JudgingInterfaces";
import Loading from "../../../../components/Loading/Loading";
import { FocusBorder } from "../../../../components/FormFields/FieldTemplate";
import Modal, { ModalCard } from "../../../../components/Modal/Modal";
import DragEntrySetList, {
	eSet,
} from "../../../../components/Draggable/DraggableEntrySets";
import { fieldRequired } from "../../../../components/FieldModal/FieldModal";
import DragDefinitionsList from "../../../../components/Draggable/DraggableDefinitions";
import { useAlert } from "../../../../components/Alert/Alerts";

export const EntrySetContainer = styled(CardBody)`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	background: ${({ theme }) => theme.colorBackgroundLight};
`;

export const StyledEntrySetCard = styled.div<{ isSelected: boolean }>`
	position: relative;
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	background: ${({ theme }) => theme.colorBackgroundLight};
	padding: 0.5rem;
	padding-left: calc(0.5rem + 2px);
	border: solid 1px ${({ theme }) => theme.colorBorderLight};
	cursor: pointer;

	${FocusBorder} {
		left: 0;
	}

	${(p) =>
		!p.isSelected &&
		css`
			&:hover {
				& > ${FocusBorder} {
					width: 4px;
					background: ${({ theme }) => theme.colorActivation};
				}
			}
		`};

	${(p) =>
		p.isSelected &&
		css`
			background: ${({ theme }) => theme.colorSelected};
		`};

	.entry-set-display {
		/* display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden; */
		display: flex;
		align-items: center;
		min-height: 56px;
		margin-right: auto;
	}
`;

const EntrySetDescriptionModal = (props: {
	selectedEntrySet?: EntrySetConfig;
	show: boolean;
	setShow(show: boolean): void;
	updateEntrySet(eSet: EntrySetConfig): void;
}) => {
	const { selectedEntrySet } = props;
	const theme = useTheme();
	const { addNewAlert } = useAlert();

	const entrySetDescription = useFormik({
		initialValues: {
			selectedEntrySet: selectedEntrySet,
		},
		onSubmit: async (value) => {
			if (value.selectedEntrySet) {
				updateEntrySetProperty({
					id: value.selectedEntrySet.id,
					propertyName: "description",
					propertyValue: value.selectedEntrySet.description,
				})
					.then((res) => {
						if (res.status == 200) {
							addNewAlert({
								type: "success",
								message: "saved successfully",
							});

							props.setShow(false);
							props.updateEntrySet(value.selectedEntrySet!);
						}
					})
					.catch((err) =>
						addNewAlert({
							type: "error",
							message: err.message,
						})
					);
			} else {
				addNewAlert({
					type: "error",
					message: "Failed to save value",
				});
			}
		},
		validationSchema: Yup.object({}),
		enableReinitialize: true,
	});

	return (
		<FormikProvider value={entrySetDescription}>
			{selectedEntrySet && (
				<Modal key={selectedEntrySet.id} show={props.show}>
					<ModalCard
						title={"Description for " + selectedEntrySet.name}
						headerIcons={
							<Icon
								icon="close"
								color={theme.colorPrimary}
								onClick={() => props.setShow(false)}
								width="35px"
								height="35px"
							/>
						}
						iconColor={theme.colorPrimary}
					>
						<div className="flex flex-col gap-[1rem] p-[2rem] h-full">
							<p>
								Set or edit the description for the entry set{" "}
								<b>{selectedEntrySet.name}</b>
							</p>
							<TextField
								name="selectedEntrySet.description"
								value={
									entrySetDescription.values.selectedEntrySet &&
									entrySetDescription.values.selectedEntrySet.description
								}
								placeholder="Description"
								component="textarea"
							/>
							<div className="flex gap-[1rem] ml-auto ">
								<Button
									className="button-gold"
									icon="close"
									iconSize="18px"
									iconColor={theme.colorPrimary}
									onClick={entrySetDescription.resetForm}
								>
									Discard Changes
								</Button>
								<Button icon="check" onClick={entrySetDescription.submitForm}>
									Save
								</Button>
							</div>
						</div>
					</ModalCard>
				</Modal>
			)}
		</FormikProvider>
	);
};

export const EntrySetSection = styled.div`
	display: flex;
	width: 100%;
	align-self: stretch;
	flex: 1;
	gap: 3rem;
	padding: 1.5rem;
	border: 1px solid ${({ theme }) => theme.colorBorderLight};
	min-width: 75%;
	min-height: 400px;
`;

export const SelectBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	width: 100%;
	background: ${({ theme }) => theme.colorBackgroundLightLight};
	border: 1px dashed ${({ theme }) => theme.colorBorderLight};
`;

export default function EntrySetTab(props: JuryConfigTabProps) {
	const [entrySetDefinitions, setEntrySetDefinitions] = useState<
		EntrySetDefinitionConfig[]
	>([]);

	const [entrySets, setEntrySets] = useState<EntrySetConfig[]>([]);
	const [entrySetForm, setEntrySetForm] = useState<CreateEntrySetModel>({
		juryId: Number(props.juryId),
		name: "",
	});
	const [isLoading, setIsLoading] = useState(true);
	const { addNewAlert } = useAlert();
	const [showDescriptionModal, setShowDescriptionModal] = useState(false);
	const [selectedEntrySet, setSelectedEntrySet] = useState<EntrySetConfig>();

	const formikProps = useFormik({
		initialValues: entrySetForm,
		onSubmit: async (value, { resetForm }) => {
			createEntrySet(value).then((res) => {
				if (res.status == 201) {
					addNewAlert({
						type: "success",
						message: "Successfully created entry set",
					});

					setEntrySets((prevState) => {
						const newSets = [...prevState, res.data];
						return newSets;
					});
					resetForm();
				}
			});
		},
		validationSchema: Yup.object({
			name: Yup.string().required(fieldRequired),
		}),
		validateOnBlur: false,
		validateOnChange: false,
	});

	const handleSaveEntrySetDefinitions = () => {
		saveEntrySetDefinitions(entrySetDefinitions)
			.then((res) => {
				if (res.status == 200) {
					addNewAlert({
						type: "success",
						message: "saved successfully",
					});
				} else {
					addNewAlert({
						type: "error",
						message: "save unsuccessful",
					});
				}
			})
			.catch((err) => {
				addNewAlert({
					type: "error",
					message: "save unsuccessful",
				});
			});
	};

	const handleAddDefinition = (
		droppedEntrySetDefinition: EntrySetDefinitionConfig[]
	) => {
		console.log("add", droppedEntrySetDefinition);
		const dropped = entrySetDefinitions.map((entrySetDefinition) => {
			const match = droppedEntrySetDefinition.some(
				({ id }) => id === entrySetDefinition.id
			);

			if (match) console.log("match", droppedEntrySetDefinition);

			return match
				? {
						...entrySetDefinition,
						entrySetId: selectedEntrySet?.id,
						isCheckedForDrag: false,
				  }
				: entrySetDefinition;
		});

		setEntrySetDefinitions(dropped);
	};

	const handleRemoveDefinition = (entrySetDefinitionToRemove: number) => {
		const newEntrySetDefinitions = entrySetDefinitions.map(
			(entrySetDefinition) =>
				entrySetDefinition.id === entrySetDefinitionToRemove
					? { ...entrySetDefinition, entrySetId: undefined }
					: entrySetDefinition
		);

		setEntrySetDefinitions(newEntrySetDefinitions);
	};

	const handleSaveName = (editedEntrySet: eSet) => {
		// Save the edited name and exit edit mode
		saveEntrySetName({
			entrySetId: editedEntrySet.entrySetId,
			name: editedEntrySet.name,
		})
			.then((res) => {
				if (res.status == 200) {
					addNewAlert({
						type: "success",
						message: "saved successfully",
					});
					setEntrySets((prevState) => {
						let newList = [...prevState].map((entrySet) => {
							if (entrySet.id === editedEntrySet.entrySetId) {
								return { ...entrySet, name: editedEntrySet.name };
							} else return entrySet;
						});

						return newList;
					});
				} else {
					addNewAlert({
						type: "error",
						message: "save unsuccessful",
					});
				}
			})
			.catch((_err) => {
				addNewAlert({
					type: "error",
					message: "save unsuccessful",
				});
			});
	};

	const handleDelete = (id: number) => {
		deleteEntrySet(id)
			.then((res) => {
				if (res.status == 200) {
					addNewAlert({
						type: "success",
						message: "Entry Set deleted successfully!",
					});
					setEntrySets((prevState) => {
						return prevState.filter((eSet) => eSet.id !== id);
					});
				} else {
					addNewAlert({
						type: "error",
						message: "An error ocurred while deleting this Entry Set!",
					});
				}
			})
			.catch((err) => {
				addNewAlert({
					type: "error",
					message: "An error ocurred while deleting this Entry Set!",
				});
			});
	};

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await getEntrySetDefinitionsByRootProgramId(
					Number(props.programId)
				);

				setEntrySetDefinitions(response.data);
			} catch (err) {
				console.log("definitions error: ", err);
				addNewAlert({
					type: "error",
					message: "Problem getting entry set definitions",
				});
			}

			try {
				const response = await getEntrySetsByJuryId(Number(props.juryId));
				setEntrySets(response.data);
				setIsLoading(false);
			} catch (err) {
				addNewAlert({
					type: "error",
					message: "Problem getting entry sets",
				});
			}
		}

		fetchData();
	}, [props.programId, props.juryId]);

	return isLoading ? (
		<Loading showLogo={false} fullScreen={false} />
	) : (
		<>
			<EntrySetDescriptionModal
				show={showDescriptionModal}
				setShow={setShowDescriptionModal}
				selectedEntrySet={selectedEntrySet}
				updateEntrySet={(eSet) => {
					// update entry set so formik has correct reset value
					setEntrySets(
						entrySets.map((entrySet) =>
							entrySet.id === eSet.id ? eSet : entrySet
						)
					);
					setSelectedEntrySet(eSet);
				}}
			/>
			<FormikProvider value={formikProps}>
				<EntrySetContainer>
					<h2>Add an Entry Set</h2>
					<div className="flex">
						<TextField
							className="w-[350px]"
							name="name"
							placeholder="Entry Set Name"
						/>
						<Button
							icon="plus"
							onClick={formikProps.handleSubmit}
							disabled={formikProps.isSubmitting}
						>
							Add
						</Button>
					</div>
				</EntrySetContainer>

				<CardBody>
					<div className="flex gap-[2rem] items-center]">
						<div className="self-stretch flex-[0_0_350px]">
							<h2 className="mb-[1rem]">Entry Sets</h2>
							<div className="w-full h-full max-h-[439.14px] overflow-auto">
								<DragEntrySetList
									entrySets={entrySets}
									setSelectedEntrySet={setSelectedEntrySet}
									onEdit={handleSaveName}
									onDelete={handleDelete}
								/>
							</div>
						</div>
						{selectedEntrySet ? (
							<EntrySetSection>
								<div className="flex-1">
									<h2 className="mb-[1rem]">
										Entry Set Definitions for {selectedEntrySet.name}
									</h2>

									<DragDefinitionsList
										name="dropped-definition"
										list={entrySetDefinitions.flatMap((eSetDef) =>
											eSetDef.entrySetId === selectedEntrySet.id ? eSetDef : []
										)}
										onAdd={handleAddDefinition}
										onRemove={handleRemoveDefinition}
										sortable={false}
									/>
								</div>
								<div className="flex-1">
									<div className="flex items-center mb-[1rem]">
										<h2 className="mb-[1rem]">Select Entry Set Definition</h2>
										<Button
											className="button-light ml-auto"
											icon="edit"
											onClick={() => setShowDescriptionModal(true)}
										>
											Description
										</Button>
									</div>

									<div className="!h-[500px] overflow-y-auto">
										<DragDefinitionsList
											name="select-definition"
											list={entrySetDefinitions.flatMap((eSetDef) =>
												eSetDef.entrySetId ? [] : eSetDef
											)}
											allowMultiSelect
											drop={false}
										/>
									</div>
								</div>
							</EntrySetSection>
						) : (
							<SelectBox className="min-h-[250px]">
								Please select an entry set to add Entry Set Definitions
							</SelectBox>
						)}
					</div>
					<ButtonContainer>
						<Button
							className="button-light"
							onClick={() => handleSaveEntrySetDefinitions()}
						>
							Save
						</Button>
						<Button
							icon="check"
							disabled={!props.juryCard || props.juryCard.isEntrySetFinal}
							onClick={() => {
								finalizeEntrySet(Number(props.juryId)).then((res) => {
									if (res.status == 204) {
										props.setJuryCard((prevState) => {
											return {
												...(prevState as JuryCardModel),
												isEntrySetFinal: true,
											};
										});
									}
								});
							}}
						>
							{props.juryCard && props.juryCard.isEntrySetFinal
								? "Entry Set Finalized"
								: "Finalize Entry Set"}
						</Button>
					</ButtonContainer>
				</CardBody>
			</FormikProvider>
		</>
	);
}

export {};
