import React, { useState, useEffect } from "react";
import { Field } from "formik";
import styled, { css, useTheme } from "styled-components";
import { FocusBorder, Label, FormField } from "./FieldTemplate";
import { ReactComponent as CaretDown } from "../../assets/icons/Down-gray.svg";
import { svgString } from "../../globalStyles";
import { useFormikContext } from "formik";
import { FieldError } from "./FieldTemplate";
import Icon from "../Icon/Icon";
import { renderToStaticMarkup } from "react-dom/server";
import { createElement } from "react";
import { ReactComponent as DownWhite } from "../../assets/icons/Down-White.svg";
import FieldWrapper from "./FieldWrapper";

export const rotate180 = {
	transform: "rotate(180deg)",
};

export const StyledDropdownField = styled(Field)<{
	caretDown?: boolean;
	hiddenLabel?: boolean;
	height?: string;
}>`
	width: 100%;
	height: 56px;
	padding: 20px 16px 6px;
	font-size: ${({ theme }) => theme.pSize};
	/* border: 1px solid ${({ theme }) => theme.colorBorder};
  border-radius: 2px; */
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: url("${svgString(CaretDown, rotate180)}") 96% no-repeat
		${({ theme }) => theme.colorTextField};

	cursor: pointer;
	line-height: 1.25;
	position: relative;

	${(p) =>
		p.caretDown &&
		css`
			background: url("${svgString(CaretDown)}") 96% no-repeat
				${p.theme.colorTextField};
		`}

	${(p) =>
		p.disabled &&
		css`
			background: transparent;
			color: ${p.theme.colorTextDisabled};
		`}

  ${(p) =>
		(p.disabled || p.readOnly) &&
		css`
			cursor: default;
		`}

	${(p) =>
		p.hiddenLabel &&
		css`
			padding: 6px 16px 6px;
		`}

	${(p) =>
		p.height &&
		css`
			height: ${p.height};
		`}


	&:focus {
		outline: none;
	}

	&:after {
		content: "";
	}

	${(p) =>
		p.statusColor &&
		css`
			height: ${p.height};
		`}
`;

const DropdownField = (props: dropdownProps) => {
	const { values, setFieldValue, errors } = useFormikContext<any>();
	const [dropdownOpened, setDropdownOpened] = useState(false);
	const [statusColor, setStatusColor] = useState<null | string>(null);

	const setDefaultStatusColor = (val: any) => {
		props.showStatusColor &&
			props.options.forEach((option) => {
				if (option.statusColor && String(option.value) === String(val)) {
					setStatusColor(option.statusColor);
				}
			});
	};

	const handleOnDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		props.onChange && props.onChange(e); // handle state externally if onChange is specified

		if (e.currentTarget.value === "") {
			setDropdownOpened(false);
			setFieldValue(props.name, undefined);
		} else {
			setDropdownOpened(true);
			!props.nonFormik &&
				setFieldValue(
					props.name,
					Number(e.currentTarget.value) ||
						Number(e.currentTarget.value) === 0 ||
						Number(e.currentTarget.value) === 1
						? parseInt(e.currentTarget.value)
						: e.currentTarget.value
				);
		}

		props.showStatusColor && setDefaultStatusColor(e.currentTarget.value);
	};

	// set default value
	useEffect(() => {
		props.value &&
			!props.nonFormik &&
			setFieldValue(
				props.name,
				Number(props.value) ||
					Number(props.value) === 0 ||
					Number(props.value) === 1
					? parseInt(props.value)
					: props.value
			);

		props.showStatusColor && setDefaultStatusColor(props.value);
	}, [props.value]);

	// remove focus on dropdown
	useEffect(() => {
		if (!values[props.name] && !props.value) {
			setDropdownOpened(false);
		}
	}, [values[props.name], props.value]);

	const hasValue =
		(values[props.name] !== undefined &&
			values[props.name] !== null &&
			values[props.name] !== "") ||
		(props.value !== undefined && props.value !== null && props.value !== "");

	return (
		<FieldWrapper
			className={props.className}
			name={props.name}
			success={props.success}
			error={props.error}
		>
			{(success, error) => (
				<FormField
					className={`${dropdownOpened ? "focused" : ""} ${
						hasValue ? "hasValue" : ""
					} ${props.className ? props.className : ""}`}
					disabled={props.disabled}
					readOnly={props.readOnly}
					hiddenlabel={props.hiddenLabel ? true : false}
					statusColor={statusColor || undefined}
					success={success}
					error={error}
				>
					<div className="flex content-center relative">
						<Label htmlFor={props.name}>{props.placeholder}</Label>

						<StyledDropdownField
							as="select"
							name={props.name}
							id={props.name}
							placeholder={props.placeholder}
							disabled={props.disabled || props.readOnly ? true : false}
							value={
								props.value !== undefined
									? props.value
									: values[props.name] !== undefined
									? values[props.name]
									: ""
							}
							onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
								e.preventDefault();
								handleOnDropdownChange(e);
							}}
							caretDown={hasValue || dropdownOpened ? true : false}
							hiddenLabel={props.hiddenLabel || undefined}
							height={props.height || undefined}
						>
							{!props.hideEmptyOption && <option value=""></option>}
							{props.options.map((option, i) => {
								return (
									<option
										key={String(i)}
										value={option.value}
										id={`${i}`}
										selected={
											props.value === option.value ||
											values[props.name] === option.value
										}
									>
										{option.label ? option.label : option.value}
									</option>
								);
							})}
						</StyledDropdownField>
						<FocusBorder></FocusBorder>
					</div>
				</FormField>
			)}
		</FieldWrapper>
	);
};

const StyledTransparentDropdown = styled.select<{ fontSize?: string }>`
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: url("${svgString(CaretDown)}") 90% no-repeat;
	color: ${({ theme }) => theme.colorCopyDarkDark};
	/* font-size: 1.5rem; */
	padding-right: 3rem;

	cursor: pointer;
	line-height: 2.5;
	position: relative;

	option {
		color: ${({ theme }) => theme.colorCopyDarkDark};
	}

	&:focus {
		outline: none;
	}

	&:after {
		content: "";
	}

	${(p) =>
		p.fontSize &&
		css`
			font-size: ${p.fontSize};
		`}
`;

// Dropdown without white background styling
export const TransparentDropdown = (props: TransparentDropdownProps) => {
	return (
		<StyledTransparentDropdown
			className={props.className ? props.className : ""}
			onChange={(e) => props.onChange(e.target.value)}
			fontSize={props.fontSize || undefined}
			value={props.value}
			defaultValue={props.value}
		>
			{props.placeholder && (
				<option key="placeholder" value="">
					{props.placeholder}
				</option>
			)}
			{props.options.map((option, index) => (
				<option key={index} value={option.value} id={`${index}`}>
					{option.label ? option.label : option.value}
				</option>
			))}
		</StyledTransparentDropdown>
	);
};

export default DropdownField;

interface dropdownProps {
	name: string;
	placeholder?: string;
	hideEmptyOption?: boolean;
	options: DropdownOptions[];
	className?: string;
	value?: any;
	disabled?: boolean;
	readOnly?: boolean;
	onChange?: (value: React.ChangeEvent<HTMLSelectElement>) => void;
	caretSize?: string;
	nonFormik?: boolean;
	error?: string;
	success?: string;
	hiddenLabel?: boolean;
	height?: string;
	showStatusColor?: boolean;
}

export interface DropdownOptions {
	value: any;
	label?: string | number;
	statusColor?: string;
}

export interface TransparentDropdownOptions {
	value: any;
	label?: string | number;
}

interface TransparentDropdownProps {
	options: TransparentDropdownOptions[];
	value?: string | number;
	id: string;
	onChange(selectedValue: number | string): void;
	fontSize?: string;
	className?: string;
	placeholder?: string;
}
