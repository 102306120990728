import React, { useRef, useState } from "react";
import * as AiIcons from "react-icons/ai";
import styled, { useTheme } from "styled-components";
import { string } from "yup/lib/locale";
import DropdownField from "../FormFields/DropdownField";
import { InputKeyUp } from "../FormFields/TextField";
import { DropdownOptions } from "../FormFields/DropdownField";
import Icon from "../Icon/Icon";
import { FocusBorder } from "../FormFields/FieldTemplate";
import stringToId from "../../utils/stringToId";

const NoFocusBorderDropdown = styled(DropdownField)`
	width: 150px;
	border-left: 1px solid ${({ theme }) => theme.colorBorderLight};
	border-right: 1px solid ${({ theme }) => theme.colorBorderLight};
	box-shadow: none;
	${FocusBorder} {
		display: none;
	}
`;

const StyledSearchBar = styled.div<{ showBorder?: boolean }>`
	display: flex;
	box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};

	${(p) =>
		p.showBorder &&
		`
       border: 1px solid ${p.theme.colorBorder};
    `}
`;

const SearchInput = styled.input`
	font-size: ${({ theme }) => theme.pSize};
	height: 56px;
	padding: 0.5rem 1rem;
	&:focus {
		outline: none;
	}
`;

const SearchIcon = styled.div<{ transparentBG?: boolean; background?: string }>`
	display: flex;
	align-items: center;
	padding: 1rem;
	background: ${(p) =>
		p.transparentBG
			? "transparent"
			: p.background
			? p.background
			: p.theme.colorBackgroundLight};
	border-left: 1px solid ${({ theme }) => theme.colorBorderLight};
	cursor: pointer;
`;

const SearchBar = (props: SearchBarProps) => {
	const theme = useTheme();
	const inputRef = useRef<any>(null);
	const [dropdown, setDropdown] = useState("");

	function searchPlaceholderSet(): string {
		if (props.searchPlaceholder != null) {
			return props.searchPlaceholder;
		} else {
			return "Search";
		}
	}

	const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
		props.onKeyUp && props.onKeyUp(e);
		if (props.clearSearchOnEnter && inputRef.current && e.key === "Enter") {
			inputRef.current.value = "";
		}
	};

	const handleClick = () => {
		if (props.onClickSearch) {
			props.onClickSearch(inputRef.current.value);
			inputRef.current.value = "";
		}
	};

	const modifiedLabel = stringToId(props.label ?? "");;

	return (
		<StyledSearchBar className={props.className} showBorder={props.showBorder}>
			<SearchInput
				className={`w-full ${
					props.transparentBG ? "bg-transparent text-colorCopyLightLight" : ""
				} `}
				type="text"
				id={props.searchPlaceholder ? stringToId(props.searchPlaceholder) : modifiedLabel}
				aria-label={props.label}
				placeholder={searchPlaceholderSet()}
				onChange={props.onChange}
				ref={inputRef}
				value={props.value}
				onKeyUp={props.onKeyUp}
			/>
			{props.dropdown && (
				<NoFocusBorderDropdown
					// caretSize="10.5%"
					name={props.dropdown.name}
					options={props.dropdown.options}
					nonFormik
					onChange={(e) => {
						props.dropdown?.onChange(e.currentTarget.value);
						setDropdown(e.currentTarget.value);
					}}
					hiddenLabel
					hideEmptyOption
					value={dropdown}
				/>
			)}
			<SearchIcon
				className="text-[1rem]"
				transparentBG={props.transparentBG}
				background={props.searchIconBG}
				onClick={handleClick}
			>
				<Icon
					icon={props.icon ? props.icon : "search"}
					color={
						props.transparentBG
							? theme.colorCopyLightLight
							: theme.colorCopyDarkDark
					}
					width="15px"
				/>
			</SearchIcon>
		</StyledSearchBar>
	);
};

export default SearchBar;

interface SearchBarProps {
	className?: string;
	label?: string;
	onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
	value?: string;
	dropdown?: {
		name: string;
		options: DropdownOptions[];
		onChange(value: any): void;
	};
	searchPlaceholder?: string;
	onKeyUp?(e: React.KeyboardEvent<HTMLInputElement>): void;
	showBorder?: boolean;
	transparentBG?: boolean;
	searchIconBG?: string;
	clearSearchOnEnter?: boolean;
	icon?: string;
	onClickSearch?(searchVal: string): void;
}

// name="filterMedia" placeholder="File Type" options={["png", "jpg", "mp4"]}
