import { FormikProvider, useFormik } from "formik";
import Button from "../../../components/Button/Button";
import TextField from "../../../components/FormFields/TextField";
import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";

import { useTheme } from "styled-components";
import { SettingsModel } from "./Settings";
import { useSettings } from "../../../hooks/UseSettings";
import axios from "axios";
import { urlSettingsAdmin } from "../../../endpoints";
import { useAlert } from "../../../components/Alert/Alerts";
import ToggleSwitchField from "../../../components/FormFields/ToggleSwitchField";

const Commerce = () => {
	const { addNewAlert } = useAlert();
	const theme = useTheme();
	const { settings, setSettings } = useSettings();

	async function UpdateSettings(updatedSettings: SettingsModel) {
		try {
			const response = await axios.put(`${urlSettingsAdmin}`, updatedSettings);
			if (response.status === 204) {
				addNewAlert({ type: "success", message: "Settings Updated" });
				setSettings(updatedSettings);
			}
		} catch (error: any) {
			// console.log(error);
			addNewAlert({ type: "error", message: error.response.data });
		}
	}

	const fieldRequired = "This field is required";
	const formikProps = useFormik({
		initialValues: settings,
		enableReinitialize: true,
		onSubmit: async (value) => {
			await UpdateSettings(value);
			// console.log(value);
		},
	});

	return (
		<>
			<div>
				<FormikProvider value={formikProps}>
					<div className="flex gap-[3.5rem]">
						<TextField
							name="paymentThreshold"
							placeholder="Payment Threshold"
							disabled={false}
							value={formikProps.values.paymentThreshold}
						/>
						<ToggleSwitchField
							id="isDemo"
							name="isDemo"
							label="Is Demo?"
							checked={formikProps.values.isDemo || false}
						/>
					</div>

					<div className="max-w-[650px] grid grid-cols-0 justify-end gap-[1rem] mt-[1rem] ml-auto">
						<Button
							onClick={formikProps.submitForm}
							disabled={formikProps.isSubmitting}
						>
							Save
						</Button>
					</div>
				</FormikProvider>
			</div>
		</>
	);
};

export default Commerce;
