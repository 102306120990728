import { DateTime } from "luxon";

const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const stdTimezoneOffset = (date: Date) => {
	var jan = new Date(date.getFullYear(), 0, 1);
	var jul = new Date(date.getFullYear(), 6, 1);
	return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

export const isDSTObserved = () => {
	return new Date().getTimezoneOffset() < stdTimezoneOffset(new Date());
};

// returns hour offset in MS
export const hourToMS = (day: number) => {
	return day * 60 * 60 * 1000;
};

// JS Date Obj incorrect generates time as DST, so +1 hour adjustment fix
export const adjustForDST = (date: Date | number | string) => {
	return isDSTObserved()
		? new Date(date)
		: new Date(date).getTime() + hourToMS(1);
};

// returns Date object in 00/00/0000 string format
const dateTostring = (date: Date | number) => {
	const dateFormat = new Date(date);
	return dateFormat.toLocaleDateString("en-US", {
		timeZone: "EST",
		month: "2-digit",
		day: "2-digit",
		year: "numeric",
	});
};

export const longDateToString = (date: Date) => {
	const dateFormat = new Date(date);
	return dateFormat.toLocaleDateString("en-US", {
		timeZone: "EST",
		dateStyle: "long",
	});
};

export const numberToDate = (number: number) => {
	const convertedDate = new Date(
		new Date(number).toLocaleString("en-US", {
			timeZone: "EST",
		})
	);

	return convertedDate;
};

export const dateTimeToUTC = (date: Date) => {
	const dateFormat = new Date(date);
	// console.log("tostring", date.toUTCString());
	const userTimezoneOffset = dateFormat.getTimezoneOffset() * 60000;
	return new Date(date.getTime() - userTimezoneOffset);
};

export const dateTimeTostring = (
	date: Date | number,
	showTimeZone: boolean = false,
	timeZone: string = "EST"
) => {
	const dateFormat = new Date(date);
	const timeZoneName = showTimeZone ? " " + timeZone : "";

	return (
		dateFormat.toLocaleString("en-US", {
			timeZone: timeZone,
			month: "2-digit",
			day: "2-digit",
			year: "numeric",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
		}) + timeZoneName
	);
};

export const isNumeric = (n: any) => {
	return !isNaN(parseFloat(n)) && isFinite(n);
};

export const isValidTimeStamp = (timestamp: any) => {
	const removeSpaces = timestamp.replaceAll(" ", "");
	// 13 digits for valid unix timestamp (ms)
	const newTimestamp =
		!isNaN(removeSpaces) && removeSpaces.length === 13
			? new Date(Number(removeSpaces)).getTime()
			: false;
	return isNumeric(newTimestamp);
};

// format date as `${month}|${day}|${year}`
export const formatDate = (date: Date) => {
	let day: string | number = date.getDate();
	let month: string | number = date.getMonth() + 1;
	let year = date.getFullYear();
	if (day < 10) {
		day = "0" + day;
	}
	if (month < 10) {
		month = "0" + month;
	}
	let formattedDate = `${month}|${day}|${year}`;
	return formattedDate;
};

export function militaryToStandard(
	militaryTime: string,
	showSeconds?: boolean
) {
	const [h, m, s] = militaryTime.split(":");
	const hours = Number(h);
	const seconds = s !== undefined && showSeconds ? Number(s) : null;

	return `${hours > 12 ? `0${hours - 12}` : hours}:${m}${
		seconds ? `:${seconds}` : ""
	} ${hours >= 12 ? "PM" : "AM"}`;
}

// format 2023-06-15 into 06/15/2023
export function formatDateString(date: string) {
	const [y, M, d] = date.split("-");
	const year = Number(y);
	const month = Number(M);
	const days = Number(d);

	return `${month}/${days}/${year}`;
}

export default dateTostring;
