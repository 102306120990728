import { combineReducers } from "redux";
import alertReducer from "../components/Alert/alertReducer";
import settingsReducer from "../components/Settings/settingsReducer";

const rootReducer = combineReducers({
	alerts: alertReducer,
	settings: settingsReducer,
});

export default rootReducer;
