import { useEffect, useRef, useState } from "react";
import { claim } from "../components/Auth/auth.models";
import { getClaims } from "../components/Auth/handleJWT";
import axios from "axios";
import { urlUsers } from "../endpoints";

export interface NemoUser {
  id: string;
  userName: string;
  companyId?: number;
  fullName: string;
  jobTitle: string;
  email: string;
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  postalCode?: string;
  profileUrl?: string;
  phone: string;
  country?: string;
  createdDate?: string;
  createdBy?: string;
  updatedDate?: string;
  modifiedBy?: string;
  modifiedByName?: string;
  programNotifications?: boolean;
  clioMuse?: boolean;
  clioAds?: boolean;
  clioFriends?: boolean;
  isAdmin?: boolean;
  isEntryReviewer: boolean;
  excludeFromJuries: boolean;
  raceAndEthnicity?: string;
  reportedGender?: string;
  preferredJobTitle?: string;
}

export interface ChangePasswordModel {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const useCurrentUser = () => {
  const cache = useRef<NemoUser>();
  const [claims] = useState<claim[]>(getClaims());

  const [user, setUser] = useState<NemoUser>({
    id: "",
    fullName: "",
    userName: "",
    jobTitle: "",
    email: "",
    address1: "",
    address2: null,
    profileUrl: "",
    city: "",
    state: "",
    postalCode: "",
    phone: "",
    programNotifications: true,
    clioMuse: true,
    clioAds: true,
    clioFriends: true,
    isEntryReviewer: false,
    excludeFromJuries: false,
  });

  useEffect(() => {
    function getUserId() {
      return claims.find((claim) => claim.name === "userId")?.value;
    }
    const userId = getUserId();
    if (!userId) {
      return;
    }

    const fetchData = async () => {
      try {
        if (cache.current) {
          const data = cache.current;
          setUser(data);
        } else {
          const response = await axios.get<NemoUser>(
            `${urlUsers}/getCurrentUser`
          );
          const data = response.data;
          cache.current = data; // set response in cache;
          setUser(data);
          //console.log(data);
        }
      } catch (error: any) {
        // console.log(error);
      }
    };
    fetchData();
  }, []);

  const updateUser = async (updatedUser: any) => {
    let user: NemoUser = { ...updatedUser };
    try {
      const response = await axios.put(`${urlUsers}/${user.id}`, user);
      if (response.status == 204) {
        setUser(user);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const changePassword = (model: ChangePasswordModel) => {
    const PostData = async (model: ChangePasswordModel) => {
      try {
        const response = await axios.post<ChangePasswordModel>(
          `${urlUsers}/changePassword`,
          model
        );
        return response;
      } catch (error: any) {
        throw error;
      }
    };

    return PostData(model);
  };

  return { user, updateUser, changePassword };
};
