import { SettingsState } from "./settingsReducer";

export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
// export const FETCH_SETTINGS_FAILURE = "FETCH_SETTINGS_FAILURE";

export interface FetchSettingsSuccessAction {
	type: typeof FETCH_SETTINGS_SUCCESS;
	payload: SettingsState;
}

// export interface FetchSettingsFailureAction {
// 	type: typeof FETCH_SETTINGS_FAILURE;
// 	payload: string;
// }

export type SettingsActionTypes = FetchSettingsSuccessAction;
