import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import Modal, { ModalCard, ModalContainer } from "../../components/Modal/Modal";
import Icon from "../../components/Icon/Icon";
import Breadcrumbs, {
	NavLinkProps,
} from "../../components/Breadcrumbs/Breadcrumbs";
import { StickyCardHeader } from "../../components/StickyCard/StickyCard";
import { BatchHierarchyDefinition } from "../Admin/Judging/JudgingInterfaces";

const BatchDefModal = (props: BatchDefModalProps) => {
	const theme = useTheme();

	return (
		<Modal show={props.programLevels !== null}>
			<StickyCardHeader>
				{props.programLevels && (
					<Breadcrumbs
						navLinks={props.programLevels.map((level) => ({
							label: level.name,
						}))}
					/>
				)}

				<Icon
					icon="close"
					color={theme.colorPrimary}
					width="35px"
					height="35px"
					onClick={() => props.onHide()}
				/>
			</StickyCardHeader>
			<ModalContainer className="!min-w-[30rem]">
				<div className="inner-container !gap-[.5rem] !pt-[3rem] !mt-0 w-[780px]">
					{props.programLevels &&
						props.programLevels.map((level) => (
							<>
								<h3>{level.name}</h3>
								{level.definition && (
									<p
										className="text-colorCopyLight mb-[1rem]"
										dangerouslySetInnerHTML={{
											__html: level.definition,
										}}
									></p>
								)}
							</>
						))}
				</div>
			</ModalContainer>
		</Modal>
	);
};

export default BatchDefModal;

interface BatchDefModalProps {
	programLevels: BatchHierarchyDefinition[] | null;
	onHide(): void;
}

export interface ProgramLevelDefinition {
	label: string;
	definition: string;
}
