// credits to https://www.sitepoint.com/react-toggle-switch-reusable-component/

import { useEffect, useState } from "react";
import styled from "styled-components";
import { Field } from "formik";
import * as AiIcons from "react-icons/ai";
import { useFormikContext } from "formik";
import { DynamicTransitionDuration } from "../../globalStyles";
import { UpdatePropertyRequest } from "../../views/Entries/manageEntry";
import { AxiosResponse } from "axios";
import FieldWrapper from "./FieldWrapper";

export const StyledSwitchField = styled.div`
  position: relative;
  margin-right: 10px;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  .toggle-switch-checkbox {
    display: none;
  }
  .toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
    margin: 0;
    &:focus {
      outline: none;
      > span {
        box-shadow: 0 0 2px 5px red;
      }
    }
    > span:focus {
      outline: none;
    }
  }
  .toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin ${DynamicTransitionDuration} ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 10px;
      background: ${({ theme }) => theme.colorActivation}60;
      color: ${({ theme }) => theme.colorBackgroundLightLight};
    }
  }
  .toggle-switch-disabled {
    background-color: ${({ theme }) => theme.colorFieldDisabled};
    cursor: default;
    &:before {
      background-color: ${({ theme }) => theme.colorFieldDisabled};
      cursor: default;
    }

    &.toggle-switch-switch {
      background-color: ${({ theme }) => theme.colorFieldDisabled} !important;
    }
  }
  .toggle-switch-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: ${({ theme }) => theme.colorFieldDisabledDark};
    color: ${({ theme }) => theme.colorBackgroundLightLight};
    text-align: right;
  }
  .toggle-switch-switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: ${({ theme }) => theme.colorBackgroundLightLight};
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border-radius: 20px;
    transition: all ${DynamicTransitionDuration} ease-in 0s;
  }
  .toggle-switch-checkbox:checked + .toggle-switch-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: -1px;
      background: ${({ theme }) => theme.colorActivation};
    }
  }
  &.small-switch {
    width: 40px;
    .toggle-switch-inner {
      &:after,
      &:before {
        content: "";
        height: 20px;
        line-height: 20px;
      }
    }
    .toggle-switch-switch {
      top: -0.25rem;
      width: 1.5rem;
      height: 1.5rem;
      right: 20px;
      margin: 2px 0;
      box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
    }
  }
  @media screen and (max-width: 991px) {
    transform: scale(0.9);
  }
  @media screen and (max-width: 767px) {
    transform: scale(0.825);
  }
  @media screen and (max-width: 575px) {
    transform: scale(0.75);
  }
`;

const FormikInput = styled(Field)``;

const ToggleSwitchField = (props: SwitchProps) => {
  const { values, setFieldValue, submitForm } = useFormikContext<any>();

  const optionLabels = ["Yes", "No"];
  const handleKeyPress = (e: any) => {
    props.name && setFieldValue(props.name, e.target.checked); // set formik value
    if (e.keyCode !== 32) return;
    e.preventDefault();
    // props.onChange && props.onChange(!props.checked);
  };

  const handleCheck = (e: any) => {
    props.name && setFieldValue(props.name, e.target.checked);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  useEffect(() => {
    if (props.name) {
      setFieldValue(props.name, props.checked);
      if (props.updateEntryProperty && props.propertyId) {
        const updatePropertyRequest: UpdatePropertyRequest = {
          id: props.propertyId,
          propertyName: props.name,
          propertyValue: props.checked,
        };
        props.updateEntryProperty(updatePropertyRequest);
      }
    }
  }, [props.checked]);

  return (
    <FieldWrapper
      className={`flex ${props.className ? props.className : ""}`}
      name={props.name}
      success={props.success}
      error={props.error}
      successMessagePosition="relative"
    >
      {() => (
        <div className="flex items-center gap-[1rem]">
          {props.label && <p>{props.label}</p>}
          <StyledSwitchField
            className={`toggle-switch ${props.small ? " small-switch" : ""}`}
          >
            <FormikInput
              type="checkbox"
              name={props.name}
              className="toggle-switch-checkbox"
              id={`toggle-switch-${props.id}`}
              // checked={props.name && values[props.name]}
              checked={props.checked}
              onChange={(e: any) => handleCheck(e)}
              disabled={props.disabled}
              onClick={props.onClick}
            />

            <label
              className="toggle-switch-label"
              tabIndex={props.disabled ? -1 : 1}
              onKeyDown={(e) => handleKeyPress(e)}
              htmlFor={`toggle-switch-${props.id}`}
            >
              <span
                className={
                  props.disabled
                    ? "toggle-switch-inner toggle-switch-disabled"
                    : "toggle-switch-inner"
                }
                data-yes={optionLabels[0]}
                data-no={optionLabels[1]}
                tabIndex={-1}
              />
              <span
                className={
                  props.disabled
                    ? "toggle-switch-switch toggle-switch-disabled"
                    : "toggle-switch-switch"
                }
                tabIndex={-1}
              />
            </label>
          </StyledSwitchField>
        </div>
      )}
    </FieldWrapper>
  );
};

export const NonFormikToggleSwitch = (props: NonFormikSwitch) => {
  //   const { values, setFieldValue, submitForm } = useFormikContext<any>();

  const optionLabels = ["Yes", "No"];
  const handleKeyPress = (e: any) => {
    // props.name && setFieldValue(props.name, e.target.checked); // set formik value
    if (e.keyCode !== 32) return;
    e.preventDefault();
    // props.onChange && props.onChange(!props.checked);
  };
  const handleCheck = (e: any) => {
    // props.name && setFieldValue(props.name, e.target.checked);
  };

  //   useEffect(() => {
  //     props.name && setFieldValue(props.name, props.checked);
  //   }, [props.checked]);

  return (
    <FieldWrapper
      className={`flex items-center gap-[1rem] ${
        props.className ? props.className : ""
      }`}
      name=""
      success={props.success}
      error={props.error}
    >
      {() => (
        <>
          {props.label && <p>{props.label}</p>}
          <StyledSwitchField
            className={`toggle-switch ${props.small ? " small-switch" : ""}`}
          >
            <input
              type="checkbox"
              className="toggle-switch-checkbox"
              id={`toggle-switch-${props.id}`}
              // checked={props.name && values[props.name]}
              checked={props.checked}
              onChange={(e: any) => handleCheck(e)}
              disabled={props.disabled}
              onClick={() => props.onClick && props.onClick()}
            />

            <label
              className="toggle-switch-label"
              tabIndex={props.disabled ? -1 : 1}
              onKeyDown={(e) => handleKeyPress(e)}
              htmlFor={`toggle-switch-${props.id}`}
            >
              <span
                className={
                  props.disabled
                    ? "toggle-switch-inner toggle-switch-disabled"
                    : "toggle-switch-inner"
                }
                data-yes={optionLabels[0]}
                data-no={optionLabels[1]}
                tabIndex={-1}
              />
              <span
                className={
                  props.disabled
                    ? "toggle-switch-switch toggle-switch-disabled"
                    : "toggle-switch-switch"
                }
                tabIndex={-1}
              />
            </label>
          </StyledSwitchField>
        </>
      )}
    </FieldWrapper>
  );
};

export default ToggleSwitchField;

interface NonFormikSwitch {
  className?: string;
  id: string;
  checked: boolean;
  label?: string;
  optionLabels?: Array<string>;
  small?: boolean;
  disabled?: boolean;
  onClick?: Function;
  nonFormik?: boolean;
  success?: string;
  error?: string;
}

interface SwitchProps extends NonFormikSwitch {
  name: string;
  updateEntryProperty?: (
    request: UpdatePropertyRequest
  ) => Promise<AxiosResponse<any, any>>;
  propertyId?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
