import { useState, useEffect, useContext } from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { useParams, useHistory } from "react-router-dom";
import { getToken } from "../components/Auth/handleJWT";
import {
  Juror,
  EntrySetAdminCard,
  GalleryQuickVote,
  JudgingRoundStatus,
  JurorViewCard,
  GalleryBatchCard,
  JuryCardAdmin,
} from "../views/Admin/Judging/JudgingInterfaces";
import { getEntryDetailsForJuror } from "../views/Entries/manageEntry";
import {
  PreviousNextEntry,
  EntryDetailsModel,
} from "../views/Judging/EntryDetail/EntryDetail";
import { useCurrentUser } from "./useCurrentUser";
import AuthenticationContext from "../components/Auth/AuthenticationContext";
import { useJuryHub, UseJuryHubReturnType } from "./useJuryHub";
import { NavLinkProps } from "../components/Breadcrumbs/Breadcrumbs";

export interface UseAdminHubReturnType extends UseJuryHubReturnType {
  juryCard: JuryCardAdmin;
  entrySetAdminCard: EntrySetAdminCard; // Admin
  batchCards: Array<GalleryBatchCard>; // Admin
  breadcrumbs: NavLinkProps[];
  disabled: boolean;
  handleClickBreadcrumb: (
    breadcrumb: {
      label: string;
      value?: string | number;
    },
    index: number
  ) => void;
  refreshdata: (juryId: number) => void;
  updateDeadline: (
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    deadline?: number
  ) => void;
  updateCountAbstainAndNoVotes: (
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    countAbstainAndNoVotes: boolean,
    entrySetCard: EntrySetAdminCard
  ) => void;
  updateEntrySetThreshold: (
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    threshold: number
  ) => void;
  updateBatchThreshold: (
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    batchId: number,
    threshold: number
  ) => void;
  updateRoundStatus: (
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    status: JudgingRoundStatus
  ) => void;
  updateEntriesLocked: (
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    isLocked: boolean
  ) => void;
  updateEntryLocked: (
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    roundEntryId: number,
    batchId: number,
    isLocked: boolean
  ) => void;
  updateEntryHidden: (
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    roundEntryId: number,
    batchId: number,
    isHidden: boolean
  ) => void;
  updateAdvance: (
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    roundEntryId: number,
    batchId: number,
    value?: any
  ) => void;
  finalize: (entrySetId: number, juryId: number) => void;
}

export function useAdminHub(disabled?: boolean): UseAdminHubReturnType {
  const history = useHistory();
  const juryHub = useJuryHub(disabled);
  const { claims } = useContext(AuthenticationContext);
  const isAdmin =
    claims.findIndex(
      (claim) => claim.name === "role" && claim.value === "admin"
    ) > -1;

  const blankJuryCard = {
    id: 0,
    activeEntries: 0,
    name: "",
    votesCast: 0,
    votesPossible: 0,
    activeJurors: 0,
    entrySetIds: [],
  };

  const [juryCard, setJuryCard] = useState<JuryCardAdmin>(blankJuryCard);

  const [entrySetAdminCard, setEntrySetAdminCard] = useState<EntrySetAdminCard>(
    juryHub.blankEntrySetCard
  );

  const [batchCards, setBatchCards] = useState<Array<GalleryBatchCard>>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<NavLinkProps[]>([
    { label: "Landing Page", value: `/judging/admin` },
  ]);

  // Breadcrumbs
  useEffect(() => {
    const crumbs: NavLinkProps[] = [
      { label: "Landing Page", value: `/judging/admin` },
    ];

    if (juryHub.programId) {
      crumbs.push({
        label: "Program Judging List Page",
        value: `/judging/admin/${juryHub.programId}`,
      });

      if (juryHub.juryId) {
        crumbs.push({
          label: "Jury Page",
          value: `/judging/admin/${juryHub.programId}/${juryHub.juryId}`,
        });
      }
    }

    setBreadcrumbs(crumbs);
  }, [juryHub.programId, juryHub.juryId]);

  // useEffect for handling juryId changes
  useEffect(() => {
    if (juryHub.connection && juryHub.juryId && !disabled) {
      juryHub.connection.on("juryCardUpdated", (card) => {
        setJuryCard(card);
        juryHub.debug && console.log("juryCardUpdated", card);
      });

      juryHub.debug && console.log(`JoinJuryGroup`, juryHub.connection);
      juryHub.connection.invoke("JoinJuryGroup", Number(juryHub.juryId));
    }
    // return () => {
    //   if (connection && juryId) {
    //     connection.invoke("LeaveJuryGroup", Number(juryId));
    //     connection.off("juryCardUpdated");
    //   }
    // };
  }, [juryHub.connection, juryHub.juryId, disabled]);

  // useEffect for handling entrySetId changes
  useEffect(() => {
    if (
      juryHub.connection &&
      juryHub.juryId &&
      juryHub.entrySetId &&
      !disabled
    ) {
      // Fetch and update the entrySetCard data
      juryHub.connection.on(
        `entrySetCardUpdated-${juryHub.entrySetId}`,
        (card) => {
          setEntrySetAdminCard(card);
          juryHub.debug &&
            console.log(`entrySetCardUpdated-${juryHub.entrySetId}`, card);
        }
      );

      juryHub.debug && console.log(`joinEntrySetGroup`, juryHub.connection);
      juryHub.connection.invoke(
        "joinEntrySetGroup",
        Number(juryHub.entrySetId),
        Number(juryHub.juryId)
      );
    }
    // return () => {
    //   if (connection && juryId && entrySetId) {
    //     juryHub.debug && console.log("Closing entrySetId", connection, entrySetId);
    //     connection.off(`entrySetCardUpdated-${entrySetId}`);
    //     connection.invoke("leaveEntrySetGroup", Number(entrySetId));
    //   }
    // };
  }, [juryHub.connection, juryHub.juryId, juryHub.entrySetId, disabled]);

  // useEffect for handling entrySetId and entrySetCard changes
  useEffect(() => {
    if (juryHub.connection && entrySetAdminCard.id && !disabled) {
      const getBatchCards = (
        entrySetId: number,
        connection: HubConnection | null
      ) => {
        if (connection) {
          connection
            .invoke("GetEntrySetAdminBatchCards", entrySetId)
            .then((cards) => {
              setBatchCards(cards);
              juryHub.debug && console.log("GetEntrySetAdminBatchCards", cards);
            });
        }
      };

      juryHub.connection.on("entrySetBatchCardsUpdated", () => {
        getBatchCards(Number(juryHub.entrySetId), juryHub.connection);
        juryHub.debug && console.log("entrySetBatchCardsUpdated");
      });

      getBatchCards(Number(juryHub.entrySetId), juryHub.connection);
    }
  }, [juryHub.connection, entrySetAdminCard.id, disabled]);

  const handleClickBreadcrumb = (
    breadcrumb: {
      label: string;
      value?: string | number;
    },
    index: number
  ) => {
    breadcrumb.value?.toString() && history.push(breadcrumb.value?.toString());
  };

  function refreshdata(juryId: number) {
    if (juryHub.connection) {
      juryHub.connection.invoke("RefreshBatchHierarchyDefinitions", juryId);
    }
  }

  function updateDeadline(
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    deadline?: number
  ) {
    if (juryHub.connection) {
      juryHub.connection.invoke(
        "UpdateDeadline",
        activeRoundId,
        entrySetId,
        juryId,
        deadline
      );
    }
  }

  function updateCountAbstainAndNoVotes(
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    countAbstainAndNoVotes: boolean,
    entrySetCard: EntrySetAdminCard
  ) {
    if (juryHub.connection) {
      juryHub.connection.invoke(
        "UpdateCountAbstainAndNoVotes",
        activeRoundId,
        entrySetId,
        juryId,
        countAbstainAndNoVotes,
        entrySetCard
      );
    }
  }

  function updateEntrySetThreshold(
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    threshold: number
  ) {
    if (juryHub.connection) {
      juryHub.connection.invoke(
        "UpdateEntrySetThreshold",
        activeRoundId,
        entrySetId,
        juryId,
        threshold
      );
    }
  }

  function updateBatchThreshold(
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    batchId: number,
    threshold: number
  ) {
    if (juryHub.connection) {
      juryHub.connection.invoke(
        "UpdateBatchThreshold",
        batchId,
        activeRoundId,
        entrySetId,
        juryId,
        threshold
      );
    }
  }

  function updateRoundStatus(
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    status: JudgingRoundStatus
  ) {
    if (juryHub.connection) {
      juryHub.connection.invoke(
        "updateRoundStatus",
        activeRoundId,
        entrySetId,
        juryId,
        status
      );
    }
  }

  // all entries lock
  function updateEntriesLocked(
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    isLocked: boolean
  ) {
    if (juryHub.connection) {
      juryHub.connection.invoke(
        "UpdateEntriesLocked",
        activeRoundId,
        entrySetId,
        juryId,
        isLocked
      );
    }
  }

  // single entry lock
  function updateEntryLocked(
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    roundEntryId: number,
    batchId: number,
    isLocked: boolean
  ) {
    if (juryHub.connection) {
      juryHub.connection.invoke(
        "UpdateEntryLocked",
        roundEntryId,
        batchId,
        activeRoundId,
        entrySetId,
        juryId,
        isLocked
      );
    }
  }

  function updateAdvance(
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    roundEntryId: number,
    batchId: number,
    value?: any
  ) {
    let advance: boolean | undefined;
    switch (value) {
      case "Choose":
        advance = undefined;
        break;
      case "true":
        advance = true;
        break;
      case "false":
        advance = false;
        break;

      default:
        break;
    }

    if (juryHub.connection) {
      juryHub.connection.invoke(
        "UpdateAdvance",
        roundEntryId,
        batchId,
        activeRoundId,
        entrySetId,
        juryId,
        advance
      );
    }
  }
  function updateEntryHidden(
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    roundEntryId: number,
    batchId: number,
    isHidden: boolean
  ) {
    if (juryHub.connection) {
      juryHub.debug && console.log(`Toggle isHidden ${isHidden}`);
      juryHub.connection.invoke(
        "UpdateEntryHidden",
        roundEntryId,
        batchId,
        activeRoundId,
        entrySetId,
        juryId,
        isHidden
      );
    }
  }

  function finalize(entrySetId: number, juryId: number) {
    if (juryHub.connection) {
      juryHub.connection
        .invoke("StartFirstRound", entrySetId, juryId)
        .then(() => {
          history.goBack();
        });
    }
  }

  return {
    ...juryHub,
    juryCard,
    entrySetAdminCard,
    batchCards,
    breadcrumbs,
    disabled: disabled ? true : false,
    handleClickBreadcrumb,
    refreshdata,
    updateDeadline,
    updateCountAbstainAndNoVotes,
    updateEntrySetThreshold,
    updateBatchThreshold,
    updateRoundStatus,
    updateEntriesLocked,
    updateEntryLocked,
    updateEntryHidden,
    updateAdvance,
    finalize,
  };
}
