import { pdf } from "@react-pdf/renderer";
import axios from "axios";
import { getClaims } from "../../components/Auth/handleJWT";
import {
  urlAuthorize,
  urlCompanies,
  urlEntries,
  urlGetOrderCount,
  urlGetOrders,
  urlMedia,
  urlOrder,
} from "../../endpoints";
import { OrderModel, PaymentStatus } from "./OrderInterfaces";
import InvoicePdf from "./InvoicePdf";
import { EntryModel } from "../Entries/EntryInterfacesComplete";
import {
  Adjustment,
  Ancestor,
  ProgramDeadline,
} from "../Admin/Program/ProgramInterfaces";
import {
  EntryCardProps,
  ExecutionCardModel,
} from "../../components/CheckoutEntryCard/CheckoutEntryCard";
import { getThumbnailSrc } from "../MediaLibrary/manageMediaLibrary";
import { HierarchySelection } from "../../components/HierarchyDropDownModal/HierarchyDropDown";
import PhysicalEntryPDF from "../Admin/Orders/PhysicalEntryPDF";
import theme from "tailwindcss/defaultTheme";
import { CompanyModel } from "../Company/company.model";
import QRCode from "qrcode";

export async function createOrderandPdf(value: OrderModel) {
  // console.log("Start of Order", value);
  // console.log("company ID", getCompany());
  value.CompanyId = parseInt(getCompany() || "");
  return await axios.post(`${urlAuthorize}`, value).then((response: any) => {
    // console.log("After Order: ", response);
    if (
      response.data.authCode === "I00001" &&
      response.data.isError === false
    ) {
      // console.log("Start of PDF Creation: ", response.data.order);
      // updateOrderPDF(response.data.order);
      return updateOrderPDF(response.data.order).then((res) => {
        return res;
      });
    }
    return response;
  });
}

export async function updateOrder(value: any) {
  return axios.put(`${urlOrder}/${value.id}`, value);
}

async function getCompanyModel(companyId: number) {
  const { data } = await axios.get<CompanyModel>(
    `${urlCompanies}/${companyId}`
  );

  return data;
}

export async function updateOrderPDF(value: OrderModel) {
  let pdfPromises: Promise<OrderPdf>[] = [];

  const newPdf = (
    <InvoicePdf
      id={value.id}
      notes={value.notes}
      companyAddress1={value.companyAddress1}
      companyAddress2={value.companyAddress2}
      companyCity={value.companyCity}
      companyCountry={value.companyCountry}
      companyName={value.companyName}
      companyPostalCode={value.companyPostalCode}
      companyState={value.companyState}
      email={value.email}
      fullName={value.fullName}
      lineItems={value.lineItems}
      orderAmount={value.orderAmount}
      phone={value.phone}
      statusId={value.statusId}
      transactionType={value.transactionType}
      cardInfo={value.cardInfo}
      invoicePDF={value.invoicePDF}
    />
  );

  pdfPromises.push(orderPdfPromise("PdfFile", pdf(newPdf).toBlob()));

  if (value.hasPhysicalComponent) {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    for (const line of value.lineItems.filter(
      (lineItem) => lineItem.programHasPhysicalComponent
    )) {
      const entryPath = `admin/edit-entry?entryId=${line.entry.id}`;
      const path = `${baseUrl}${entryPath}`;

      line.entry.qrCodeUrl = await QRCode.toDataURL(path);
    }

    let company = await getCompanyModel(Number(value.companyId));

    const physicalEntryPdf = (
      <PhysicalEntryPDF
        order={value}
        iconColor={theme.colorActivation}
        pageTitle={"Order # " + value.id + " Physical Component PDF"}
        company={company}
      />
    );

    pdfPromises.push(
      orderPdfPromise(
        "PhysicalComponentPdfFile",
        pdf(physicalEntryPdf).toBlob()
      )
    );
    pdfPromises.push(
      orderPdfPromise(
        "PhysicalComponentPdfFile",
        pdf(physicalEntryPdf).toBlob()
      )
    );
  }

  return Promise.all(pdfPromises).then((response) => {
    let finalBlobPdfs = response;

    const formData = new FormData();
    formData.append("OrderId", value.id!.toString());

    finalBlobPdfs.forEach((pdf) => {
      formData.append(pdf.identifier, pdf.pdf);
    });

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return axios
      .post(`${urlMedia}/upload-pdf`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log("afterpdf creation", response);
        return response;
      })
      .catch((error: any) => {
        // console.log(error);
        return error;
      });
  });
}

export async function getOrder(orderId: number) {
  const response = axios.get<OrderModel>(`${urlOrder}/getOrder/${orderId}`);
  return response;
}

function getCompany() {
  const claims = getClaims();
  return claims.find((claim) => claim.name === "company")?.value;
}

export async function getCompanyOrders() {
  const response = axios.get<OrderModel[]>(`${urlGetOrders}${getCompany()}`);
  return response;
}

export async function getOrderCount() {
  const response = axios.get<number>(`${urlGetOrderCount}${getCompany()}`);
  return response;
}

export async function getCheckoutEntries() {
  const response = axios.get<EntryModel[]>(
    `${urlEntries}/getEntriesByPaymentStatus?companyId=${getCompany()}&paymentStatusId=${
      PaymentStatus.ReadyForCheckout
    }`
  );
  return response;
}

export function getDeadlinePrice(
  deadlines: ProgramDeadline[],
  adjustment: Adjustment | undefined
): PriceInfo {
  // Determine the current date to use for the calculation
  const now = new Date();
  let now_utc = Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
    now.getUTCMilliseconds()
  );

  if (adjustment) {
    //console.log("Adjustment Set: ", now_utc, adjustment);
    // Use the adjustment date if an adjustment is present and its expiration date is earlier than the current date
    now_utc = adjustment.adjustmentDate;
  }

  let price = 0.0;
  let multiplier = 0.0;
  // Return 0 if there are no deadlines
  if (deadlines.length == 0) {
    return {
      deadlinePrice: 0,
      deadlineMultiplier: 0,
    };
  }

  // If the current date is later than the date of the last deadline,
  // then the entry will be charged the price of the last deadline
  if (now_utc > deadlines[deadlines.length - 1].date) {
    price = deadlines[deadlines.length - 1].price;
    multiplier = deadlines[deadlines.length - 1].campaignMultiplier!;

    return {
      deadlinePrice: price,
      deadlineMultiplier: multiplier,
    };
  }

  // Iterate through the deadlines and return the price of the first deadline
  // whose date is later than the current date
  for (let index = 0; index < deadlines.length; index++) {
    if (now_utc <= deadlines[index].date) {
      return {
        deadlinePrice: deadlines[index].price,
        deadlineMultiplier: deadlines[index].campaignMultiplier!,
      };
    }
  }

  // If the current date is earlier than the date of the first deadline,
  // then the entry will be charged the price of the first deadline
  if (price === 0.0) {
    price = deadlines[0].price;
    multiplier = deadlines[0].campaignMultiplier!;
  }
  return {
    deadlinePrice: price,
    deadlineMultiplier: multiplier,
  };
}

export function getFinalPrice(priceInfo: PriceInfo, isCampaign: boolean) {
  const { deadlinePrice, deadlineMultiplier } = priceInfo;

  if (!isCampaign) {
    return deadlinePrice;
  }

  if (deadlineMultiplier !== null && deadlineMultiplier > 0) {
    return Math.ceil((deadlinePrice * deadlineMultiplier) / 25) * 25;
  } else {
    return Math.ceil((deadlinePrice * 1.5) / 25) * 25;
  }
}

export function getHierarchy(
  executions: ExecutionCardModel[],
  ancestry: Ancestor[],
  isCampaign: boolean
) {
  let hierarchy: string[] = [];
  let sameTypeEx = true;
  const firstExecutionProgramId =
    (executions && executions.length > 0 && executions[0].programId) || 0;

  executions?.forEach((execution) => {
    if (
      firstExecutionProgramId &&
      execution.programId != firstExecutionProgramId
    ) {
      sameTypeEx = false;
    }
  });

  ancestry?.forEach((ancestor) => {
    hierarchy.push(ancestor.name);
  });

  if (isCampaign && !sameTypeEx) {
    hierarchy.push("Mixed Campaign");
  } else if (executions && executions.length > 0) {
    hierarchy.push(executions[0].programName);
  }

  return hierarchy;
}

export function deleteEntry(entryId: number) {
  const response = axios.delete(`${urlEntries}/${entryId}`);
  return response;
}

export async function getCheckoutEntryCards() {
  const response = axios.get<EntryCardProps[]>(
    `${urlEntries}/getEntryCardsByPaymentStatus?companyId=${getCompany()}&paymentStatusId=${
      PaymentStatus.ReadyForCheckout
    }`
  );
  return response;
}

export async function getDraftEntryCards() {
  const response = axios.get<EntryCardProps[]>(
    `${urlEntries}/getEntryCardsByPaymentStatus?companyId=${getCompany()}&paymentStatusId=${
      PaymentStatus.Incomplete
    }`
  );
  return response;
}

export async function getDraftEntryCount() {
  const response = axios.get<number>(
    `${urlEntries}/getEntryCountByPaymentStatus?companyId=${getCompany()}&paymentStatusId=${
      PaymentStatus.Incomplete
    }`
  );
  return response;
}

export async function getCartCount() {
  const response = axios.get<number>(
    `${urlEntries}/getEntryCountByPaymentStatus?companyId=${getCompany()}&paymentStatusId=${
      PaymentStatus.ReadyForCheckout
    }`
  );
  return response;
}

// export async function getOrderCounts() {
//   const response = axios.get<number>(`${urlGetOrders}${getCompany()}`);
//   return response;
// }

export async function get() {
  const response = axios.get<number>(
    `${urlEntries}/getEntryCountByPaymentStatus?companyId=${getCompany()}&paymentStatusId=${
      PaymentStatus.ReadyForCheckout
    }`
  );
  return response;
}

export async function getProgramChildrenByParentId(parentId: number | null) {
  const response = axios.get<HierarchySelection[]>(
    `${urlEntries}/getProgramChildrenByParentId?parentId=${parentId || ""}`
  );
  return response;
}

export async function duplicate(
  programId: number,
  oldEntryId: number,
  newExecutionProgramId: number
) {
  const response = axios.get<EntryCardProps>(
    `${urlEntries}/duplicate?programId=${programId}&oldEntryId=${oldEntryId}&newExecutionProgramId=${newExecutionProgramId}`
  );
  return response;
}

export async function moveEntry(
  newProgramId: number,
  entryId: number,
  newExecutionProgramId: number
) {
  const response = axios.get(
    `${urlEntries}/moveEntry?newProgramId=${newProgramId}&entryId=${entryId}&newExecutionProgramId=${newExecutionProgramId}`
  );
  return response;
}

export async function addToCart(entryId: number) {
  const response = axios.get(`${urlEntries}/addToCart/${entryId}`);
  return response;
}

export async function removeFromCart(entryId: number) {
  const response = axios.get(`${urlEntries}/removeFromCart/${entryId}`);
  return response;
}

export async function submitProject(entryId: number) {
  const response = axios.get(`${urlEntries}/submitProject/${entryId}`);
  return response;
}

function orderPdfPromise(
  pdfIdentifier: string,
  promise: Promise<Blob>
): Promise<OrderPdf> {
  return promise.then((r) => {
    let orderPdf: OrderPdf = {
      identifier: pdfIdentifier,
      pdf: r,
    };

    return orderPdf;
  });
}

interface OrderPdf {
  identifier: string;
  pdf: Blob;
}

export interface PriceInfo {
  deadlinePrice: number;
  deadlineMultiplier: number;
}
