import useDownloadMedia from "../../utils/useDownloadMedia";
import { getClaims } from "../Auth/handleJWT";
import { SpinnerSrc } from "../DragAccordion/DragMediaAccordion";
import { useTheme } from "styled-components";
import Icon from "../Icon/Icon";

export const DownloadMediaButton = (props: MediaDownloadButtonProps) => {
  const theme = useTheme();
  const claims = getClaims();
  const isAdmin = claims.some(
    (claim) => claim.name === "role" && claim.value === "admin"
  );
  const { downloadFile, isDownloading } = useDownloadMedia();

  return isAdmin ? (
    <div
      onClick={() =>
        isDownloading
          ? {}
          : downloadFile(
              process.env.REACT_APP_S3_HOST + props.path,
              props.fileName
            )
      }
      className="cursor-pointer"
    >
      {props.component ? (
        props.component(isDownloading)
      ) : isDownloading ? (
        <img className="w-[25px]" src={SpinnerSrc} alt="loading..." />
      ) : (
        <Icon
          icon="download"
          color={isDownloading ? theme.colorCopyLightLight : theme.colorPrimary}
          height="20px"
          width="20px"
        />
      )}
    </div>
  ) : (
    <></>
  );
};

interface MediaDownloadButtonProps {
  fileName: string;
  path: string;
  component?: (isDownloading: boolean) => React.ReactNode;
}
