import styled, { useTheme } from "styled-components";
import UserMenu from "../User/UserMenu";
import { CreditType } from "../Program/ProgramInterfaces";
import CompanyCreditList from "./CreditLibrary/CompanyCreditList";
import IndividualCreditList from "./CreditLibrary/IndividualCreditList";
import { getClaims } from "../../../components/Auth/handleJWT";
import {
	UserPageContainer,
	UserPageHeader,
} from "../../../components/UserPageTemplate/UserPageTemplate";
import { useParams } from "react-router-dom";
import Button from "../../../components/Button/Button";
import { useState } from "react";
import AddCreditModal from "./AddCreditModal";
import Modal, { ModalCard } from "../../../components/Modal/Modal";
import Icon from "../../../components/Icon/Icon";
import {
	downloadCompanyCreditSampleCsvFile,
	downloadIndividualCreditSampleCsvFile,
} from "./manageCreditLibrary";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import assetsConfig from "../../../assetsConfig";

const CreditUploadInstructionsContainer = styled.div`
	padding: 2rem;

	li {
		padding-left: 0.5rem;
	}
	.list {
		margin-bottom: 1rem;
	}
	.boldedHeader {
		margin-top: 1rem;
		font-weight: bold;
	}
	overflow-y: auto;
`;

const ButtonContainer = styled.div`
	display: grid;
	justify-content: flex-end;
	gap: 1rem;
	margin-left: auto;

	@media only screen and (min-width: ${({ theme }) => theme.lg}) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

// upon successful call to update credits,
// refresh only the updated credit (instead of calling getCompanyCredits())
// to preserve the ordering
export const replaceFrontEndCredits = () => {};

const CreditLibrary = (props: CreditLibraryProps) => {
	const claims = getClaims();
	const isAdmin = claims.some(
		(claim) => claim.name === "role" && claim.value === "admin"
	);
	const { companyId } = useParams<{
		companyId?: string;
	}>();

	const theme = useTheme();
	const parsedCompanyId = companyId
		? parseInt(companyId)
		: Number(claims.find((claim) => claim.name === "company")?.value || "0");
	const isCompanyCredit = props.creditType === CreditType.Company;
	const [showAddCreditModal, setShowAddCreditModal] =
		useState<null | CreditType>(null);

	// states to refresh list of credits after adding a new credit
	const [refreshCompCredits, setRefreshCompCredits] = useState(false);
	const [refreshIndCredits, setRefreshIndCredits] = useState(false);

	const [
		showCreditUploadInstructionsModal,
		setShowCreditUploadInstructionsModal,
	] = useState(false);

	const handleOpenInstructionsModal = () => {
		setShowCreditUploadInstructionsModal(true);
	};

	const handleCloseInstructionsModal = () => {
		setShowCreditUploadInstructionsModal(false);
	};

	return (
		<div className="flex flex-col w-full">
			<BrowserHeader
				title={
					props.metaDescription ||
					`${isCompanyCredit ? "Company" : "Individual"} Credits`
				}
			/>
			{parsedCompanyId && (
				<AddCreditModal
					show={showAddCreditModal !== null}
					onClickHide={() => setShowAddCreditModal(null)}
					companyId={parsedCompanyId}
					setCompanyCreditsArray={() =>
						setRefreshCompCredits(!refreshCompCredits)
					}
					setIndividualCreditsArray={() =>
						setRefreshIndCredits(!refreshIndCredits)
					}
					defaultCreditTab={
						showAddCreditModal !== null
							? showAddCreditModal
							: CreditType.Company
					}
				/>
			)}
			{!companyId && <UserMenu />}
			<UserPageHeader className="flex items-center gap-[2rem] flex-wrap">
				<h1 className="header whitespace-nowrap">
					{isCompanyCredit ? "Company" : "Individual"} Credits
				</h1>

				<ButtonContainer>
					<Button
						className="button-gold !text-colorCopyLightLight"
						icon="info"
						iconSize="18px"
						iconColor={theme.colorPrimary}
						onClick={handleOpenInstructionsModal} // Add onClick event handler
					>
						Credit Upload Instructions
					</Button>
					<Button
						className="button-gold !text-colorCopyLightLight"
						icon="download"
						iconSize="18px"
						iconColor={theme.colorPrimary}
						onClick={
							props.creditType === CreditType.Company
								? downloadCompanyCreditSampleCsvFile
								: downloadIndividualCreditSampleCsvFile
						}
					>
						Credit Template File
					</Button>
					<Button
						icon="plus"
						onClick={() =>
							setShowAddCreditModal(
								isCompanyCredit ? CreditType.Company : CreditType.Individual
							)
						}
					>
						Add {isCompanyCredit ? "Company" : "Individual"} Credit
					</Button>
				</ButtonContainer>
			</UserPageHeader>

			<UserPageContainer userPage={companyId ? false : true}>
				{props.creditType === CreditType.Company ? (
					<CompanyCreditList
						companyId={parsedCompanyId}
						isAdmin={isAdmin}
						refreshCredits={refreshCompCredits}
					/>
				) : (
					<IndividualCreditList
						companyId={parsedCompanyId}
						isAdmin={isAdmin}
						refreshCredits={refreshIndCredits}
					/>
				)}
			</UserPageContainer>

			<Modal show={showCreditUploadInstructionsModal}>
				<ModalCard
					title="Credit Upload Instructions"
					headerIcons={
						<Icon
							icon="close"
							color={theme.colorPrimary}
							onClick={handleCloseInstructionsModal}
							width="35px"
							height="35px"
						/>
					}
					iconColor={theme.colorPrimary}
				>
					{/* Add content for the modal here */}
					<CreditUploadInstructionsContainer
						dangerouslySetInnerHTML={{
							__html: assetsConfig.copy.creditUploadInstructions,
						}}
					></CreditUploadInstructionsContainer>
					<Button
						className="mt-auto"
						iconColor={theme.colorCopyLightLight}
						onClick={handleCloseInstructionsModal}
						icon="check"
						iconSize="15px"
					>
						OK
					</Button>
				</ModalCard>
			</Modal>
		</div>
	);
};

interface CreditLibraryProps {
	creditType: CreditType;
	metaDescription?: string;
}

export default CreditLibrary;

export interface CreditListProps {
	companyId: number;
	isAdmin: boolean;
	refreshCredits: boolean;
}
