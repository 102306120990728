import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
	CardBody,
	CardContainer,
	CardHeader,
} from "../../../components/DashboardCard/DashboardCard";
import {
	NavTab,
	NavTabPanel,
	NavTabs,
} from "../../../components/NavTabs/NavTabs";
import { ConfigNavTabs } from "../Program/ProgramConfig";
import Progress from "./Progress";
import Assignment from "./Assignment";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import assetsConfig from "../../../assetsConfig";

const Review = (props: { tab: string }) => {
	const tabs = ["progress", "assignment"];
	const tabNames = ["Progress", "Assignment"];

	const defaultTab = tabs.indexOf(props.tab || "progress");
	const [tabIndex, setTabIndex] = useState(defaultTab);

	useEffect(() => {
		setTabIndex(defaultTab);
	}, [defaultTab]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [tabIndex]);

	return (
		<CardContainer>
			<BrowserHeader
				title={`${assetsConfig.labels.entryReview.singular}: ${tabNames[tabIndex]}`}
			/>
			<CardHeader>
				<h2 className="card-title">
					{assetsConfig.labels.entryReview.singular}
				</h2>
			</CardHeader>
			<NavTabs
				selectedIndex={tabIndex}
				onSelect={(index) => setTabIndex(index)}
			>
				<ConfigNavTabs>
					<NavTab>
						<Link className="no-underline" to="/review/progress">
							Progress
						</Link>
					</NavTab>
					<NavTab>
						<Link className="no-underline" to="/review/assignment">
							Assignment
						</Link>
					</NavTab>
				</ConfigNavTabs>
				<NavTabPanel>
					<CardBody>
						<Progress />
					</CardBody>
				</NavTabPanel>
				<NavTabPanel>
					<CardBody>
						<Assignment />
					</CardBody>
				</NavTabPanel>
			</NavTabs>
		</CardContainer>
	);
};

export default Review;
