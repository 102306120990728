import axios, { AxiosRequestConfig } from "axios";
import { getClaims, saveToken } from "../../components/Auth/handleJWT";
import { urlCompanies, urlManageCompanies } from "../../endpoints";
import { AgencyNetworkModel, CompanyModel, HoldingCompanyModel, companyUserModel } from "./company.model";

export async function getCompanyInfo() {
  try {
    const response = await axios.get<CompanyModel>(
      `${urlCompanies}/${getCompany()}`
    );
    if (response.status == 200) {
      return response.data;
    }
  } catch (error: any) {
    // console.log(error);
  }
}

export const getUsersByCompany = async (
  setValues: (arg0: companyUserModel[]) => void
) => {
  try {
    const response = await axios.get(
      `${urlManageCompanies}/getUsersByCompany?companyId=${getCompany()}`
    );
    if (response.status == 200) {
      setValues(response.data as companyUserModel[]);
      // console.log(response.data);
    }
  } catch (error: any) {
    // console.log(error);
  }
};

export async function changeRole(userId: string, role: string) {
  try {
    const response = await axios.get(
      `${urlManageCompanies}/changerole?companyId=${getCompany()}&userId=${userId}&role=${role}`
    );
    if (response.status != 204) {
      return false;
    }
    return true;
  } catch (error: any) {
    // console.log(error);
    return false;
  }
}

export const getCompanyRequestCount = async (
  setValue: (arg0: number) => void
) => {
  try {
    const response = await axios.get(
      `${urlManageCompanies}/getCompanyRequestCount?companyId=${getCompany()}`
    );
    if (response.status == 200) {
      setValue(response.data);
    }
  } catch (error: any) {
    // console.log(error);
  }
};

export async function getCompanyRequestUsers() {
  try {
    const response = await axios.get<companyUserModel>(
      `${urlManageCompanies}/getCompanyRequestUsers?companyId=${getCompany()}`
    );
    if (response.status == 200) {
      return response.data;
    }
  } catch (error: any) {
    // console.log(error);
  }
}

export async function acceptCompanyRequest(userId: string) {
  try {
    const response = await axios.get(
      `${urlManageCompanies}/acceptCompanyRequest?companyId=${getCompany()}&userId=${userId}`
    );
    if (response.status != 204) {
      return false;
    }
    return true;
  } catch (error: any) {
    // console.log(error);
    return false;
  }
}

export async function declineCompanyRequest(userId: string) {
  try {
    const response = await axios.get(
      `${urlManageCompanies}/declineCompanyRequest?companyId=${getCompany()}&userId=${userId}`
    );
    if (response.status != 204) {
      return false;
    }
    return true;
  } catch (error: any) {
    // console.log(error);
    return false;
  }
}

export async function inviteToCompany(email: string) {
  try {
    const response = await axios.get(
      `${urlManageCompanies}/inviteToCompany?companyId=${getCompany()}&email=${email}`
    );
    if (response.status != 204) {
      return false;
    }
    return true;
  } catch (error: any) {
    // console.log(error);
    return false;
  }
}

export async function createCompany(value: CompanyModel) {
  return await axios.post(`${urlCompanies}/create`, value);
}

export async function updateCompany(value: CompanyModel) {
  value.id = Number(getCompany());
  return await axios.put(
    `${urlManageCompanies}?companyId=${getCompany()}`,
    value
  );
}

export async function updateCompanyLogo(
  value: FormData,
  setValue: (arg0: string) => void
) {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  axios
    .post(
      `${urlManageCompanies}/updateCompanyLogo?companyId=${getCompany()}`,
      value,
      {
        headers: headers,
      }
    )
    .then((response) => {
      if (response.status == 200) {
        setValue(response.data);
      }
    })
    .catch((error: any) => {
      // console.log(error);
    });
}

export async function enableUser(userId: string, enable: boolean) {
  try {
    const response = await axios.get(
      `${urlManageCompanies}/enableUser?companyId=${getCompany()}&userId=${userId}&enable=${enable}`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export function getCompany() {
  const claims = getClaims();
  return claims.find((claim) => claim.name === "company")?.value;
}

export async function getAgencyNetworks() {
  try {
    const response = await axios.get<AgencyNetworkModel[]>(
      `${urlCompanies}/getAgencyNetworks`
    );
    if (response.status == 200) {
      return response.data;
    }
  } catch (error: any) {
    // console.log(error);
  }
}

export async function getHoldingCompanies() {
  try {
    const response = await axios.get<HoldingCompanyModel[]>(
      `${urlCompanies}/getHoldingCompanies`
    );
    if (response.status == 200) {
      return response.data;
    }
  } catch (error: any) {
    // console.log(error);
  }
}