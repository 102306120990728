// credits to https://www.sitepoint.com/react-toggle-switch-reusable-component/

import { useState } from "react";
import styled from "styled-components";
import { DynamicTransitionDuration } from "../../globalStyles";

const StyledSwitch = styled.div`
  position: relative;
  margin-right: 10px;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  .toggle-switch-checkbox {
    display: none;
  }
  .toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid ${({ theme }) => theme.colorFieldDisabledDark};
    border-radius: 20px;
    margin: 0;
    &:focus {
      outline: none;
      > span {
        box-shadow: 0 0 2px 5px red;
      }
    }
    > span:focus {
      outline: none;
    }
  }
  .toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin ${DynamicTransitionDuration} ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 10px;
      background: ${({ theme }) => theme.colorActivation}60;
      color: ${({ theme }) => theme.colorBackgroundLightLight};
    }
  }
  .toggle-switch-disabled {
    background-color: ${({ theme }) => theme.colorFieldDisabled};
    cursor: default;
    &:before {
      background-color: ${({ theme }) => theme.colorFieldDisabled};
      cursor: default;
    }
  }
  .toggle-switch-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: ${({ theme }) => theme.colorFieldDisabledDark};
    color: ${({ theme }) => theme.colorBackgroundLightLight};
    text-align: right;
  }
  .toggle-switch-switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: ${({ theme }) => theme.colorBackgroundLightLight};
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid ${({ theme }) => theme.colorFieldDisabledDark};
    border-radius: 20px;
    transition: all ${DynamicTransitionDuration} ease-in 0s;
  }
  .toggle-switch-checkbox:checked + .toggle-switch-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: -0.5rem;
      background: ${({ theme }) => theme.colorActivation};
    }
  }
  &.small-switch {
    width: 40px;
    .toggle-switch-inner {
      &:after,
      &:before {
        content: "";
        height: 20px;
        line-height: 20px;
      }
    }
    .toggle-switch-switch {
      top: -0.25rem;
      width: 1.5rem;
      height: 1.5rem;
      right: 20px;
      margin: 2px;
      box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
    }
  }
  @media screen and (max-width: 991px) {
    transform: scale(0.9);
  }
  @media screen and (max-width: 767px) {
    transform: scale(0.825);
  }
  @media screen and (max-width: 575px) {
    transform: scale(0.75);
  }
`;

const ToggleSwitch = (props: SwitchProps) => {
  const optionLabels = props.optionLabels || ["Yes", "No"];
  const [checked, setChecked] = useState(props.checked);
  const handleKeyPress = (e: any) => {
    if (e.keyCode !== 32) return;
    e.preventDefault();
    setChecked(e.target.checked);
    props.onClick(e.target.checked);
  };

  const handleChange = async (e: any) => {
    e.preventDefault();
    setChecked(e.target.checked);
    await props.onClick(e.target.checked);
  };

  return (
    <StyledSwitch
      className={`toggle-switch ${props.small ? " small-switch" : ""}`}
    >
      <input
        type="checkbox"
        name={props.name}
        className="toggle-switch-checkbox"
        id={`toggle-switch-${props.id}`}
        checked={checked}
        onClick={handleChange}
        disabled={props.disabled}
      />
      {props.id ? (
        <label
          className="toggle-switch-label"
          tabIndex={props.disabled ? -1 : 1}
          onKeyDown={(e) => handleKeyPress(e)}
          htmlFor={`toggle-switch-${props.id}`}
        >
          <span
            className={
              props.disabled
                ? "toggle-switch-inner toggle-switch-disabled"
                : "toggle-switch-inner"
            }
            data-yes={optionLabels[0]}
            data-no={optionLabels[1]}
            tabIndex={-1}
          />
          <span
            className={
              props.disabled
                ? "toggle-switch-switch toggle-switch-disabled"
                : "toggle-switch-switch"
            }
            tabIndex={-1}
          />
        </label>
      ) : null}
    </StyledSwitch>
  );
};

export default ToggleSwitch;

interface SwitchProps {
  id: string;
  name?: string;
  checked: boolean;
  onClick: Function;
  optionLabels?: Array<string>;
  small?: boolean;
  disabled?: boolean;
}
