import {
	useContext,
	useEffect,
	useRef,
	useState,
	useCallback,
	createRef,
} from "react";
import styled, { useTheme, css } from "styled-components";
import { getClaims } from "../../components/Auth/handleJWT";
import Icon from "../../components/Icon/Icon";
import { FakeCheckbox } from "../../components/FormFields/CheckboxField";
import Sortable from "sortablejs";
import { ReactSortable, Store } from "react-sortablejs";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Button from "../Button/Button";
import FieldWrapper from "../FormFields/FieldWrapper";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import {
	CreditCheckContext,
	ToggleMobileCreditLibContext,
} from "../../views/Entries/EntryForm";
import {
	DragCard,
	DraggableProps,
	DropZone,
	checkMax,
	filterDuplicates,
	sortableOptions,
} from "./Draggable";
import {
	CompanyCredit,
	IndividualCredit,
} from "../../views/Admin/Credits/CreditInterfaces";
import { CreditType } from "../../views/Admin/Program/ProgramInterfaces";
import { SpinnerContainer } from "../LocalFileUpload.tsx/LocalFileUpload";
import { SpinnerSrc } from "../DragAccordion/DragMediaAccordion";
import { useAlert } from "../Alert/Alerts";

export const indCreditCardHeight = 89;
export const companyCreditCardHeight = 70;

export const CREDIT_UPLOAD_COUNT_MESSAGE = (max: number) =>
	`Maximum upload limit for this field: ${max} credit(s)`;

export const DropText = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 194px;

	/* position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%); */
`;

export const DragCredit = (props: DragCreditProps) => {
	const theme = useTheme();
	const claims = getClaims();
	const isAdmin = claims.some(
		(claim) => claim.name === "role" && claim.value === "admin"
	);
	const ref = useRef<HTMLDivElement | null>(null);

	const { credit } = props;
	const isCompanyCredit = props.creditType === CreditType.Company;
	const creditName = credit
		? isCompanyCredit
			? (credit as CompanyCredit).name
			: (credit as IndividualCredit).fullName
		: "";
	const creditType = credit
		? isCompanyCredit
			? (credit as CompanyCredit).companyType === "Other"
				? (credit as CompanyCredit).otherCompanyType
				: (credit as CompanyCredit).companyType
			: (credit as IndividualCredit).jobTitle === "Other"
			? (credit as IndividualCredit).otherJobTitle
			: (credit as IndividualCredit).jobTitle
		: "";
	const creditCityOrCompanyName = credit
		? isCompanyCredit
			? (credit as CompanyCredit).city
			: (credit as IndividualCredit).companyName
		: "";

	const creditCity = credit
		? isCompanyCredit
			? (credit as CompanyCredit).city
			: (credit as IndividualCredit).companyCity
		: "";

	const creditContactEmail =
		!isCompanyCredit && (credit as IndividualCredit).email;

	const creditCountry = isCompanyCredit && (credit as CompanyCredit).country;

	const handleRemoveCredit = () => {
		if (props.onRemoveCredit) {
			props.onRemoveCredit(credit.id!);
		}
	};

	const handleClick = () => {
		if (props.onClick) {
			props.onClick();
		}
	};

	return (
		<DragCard
			className="draggable credit-drag"
			key={credit.id}
			data-id={credit.id}
			data-filename={creditName}
			data-credittype={props.creditType}
			ref={ref}
			onClick={handleClick}
			disabled={props.readOnly}
		>
			<div className="drag-icons">
				{!props.isMobile && (
					<Icon
						className="drag-arrows"
						icon="drag-arrows"
						color={theme.colorPrimary}
						readonly={props.readOnly}
					/>
				)}
			</div>

			<div className="details credits">
				<div className="credit-fields">
					<p className="font-semibold">{creditName}</p>
					{!isCompanyCredit ? (
						(credit as IndividualCredit).companyName ? (
							<p>{(credit as IndividualCredit).companyName}</p>
						) : (
							<p className="light">Company Name</p>
						)
					) : (
						<p>{creditCityOrCompanyName}</p>
					)}
					{!isCompanyCredit ? (
						credit.country ? (
							<p>{credit.country}</p>
						) : (
							<p className="light">Company Country</p>
						)
					) : (
						""
					)}
				</div>

				<div className="credit-fields">
					<p className="font-semibold">{creditType}</p>

					{isCompanyCredit ? (
						<p>{creditCountry}</p>
					) : creditCity ? (
						<p>{creditCity}</p>
					) : (
						<p className="light">Company City</p>
					)}

					{!isCompanyCredit ? (
						creditContactEmail ? (
							<p>{creditContactEmail}</p>
						) : (
							<p className="light">Email</p>
						)
					) : (
						""
					)}
				</div>

				<div className="icon-container">
					<Icon
						className="close-icon"
						icon="closeLarge"
						color={theme.colorPrimary}
						onClick={handleRemoveCredit}
						readonly={props.readOnly}
					/>
				</div>

				{props.allowMultiSelect && (
					<FakeCheckbox
						className="checkbox-field"
						id={`multiSelect.${credit.id}`}
						aria-label={"multiselect checkbox"}
						checked={props.checked}
					/>
				)}
			</div>
		</DragCard>
	);
};

const DraggableCredits = (props: DraggableCreditListProps) => {
	const theme = useTheme();
	const { errors, values, setFieldValue } = useFormikContext<any>();
	const { resetCreditCheck, setResetCreditCheck } =
		useContext(CreditCheckContext);
	const { addNewAlert } = useAlert();
	const { width } = useWindowDimensions();
	const ref = useRef<DragCreditType[] | null>(null);
	const sortableRef = useRef<any>(null);
	const [minHeight, setMinHeight] = useState(194);
	const [isLoading, setIsLoading] = useState(false);
	const cardHeight =
		props.creditType === CreditType.Company
			? companyCreditCardHeight
			: indCreditCardHeight;

	// for mobile menu
	const { creditSelect, setCreditSelect } = useContext(
		ToggleMobileCreditLibContext
	);
	// replaces drag/drop with multiselect
	const isMobile = width <= Number(theme.lg.replaceAll("px", ""));

	const [creditList, setCreditList] = useState<DragCreditType[]>([]);
	const prevCreditList = ref.current;

	// check if the min # of files are uplaoded
	const isMinNotDropped =
		props.min && props.min > 0 && creditList.length < props.min
			? `At least ${props.min} file is required.`
			: undefined;

	const filterCreditTypeAndClassification = (arr: DragCreditType[]) => {
		const filtered = arr.filter((credit) => {
			const creditType = credit.hasOwnProperty("name")
				? CreditType.Company
				: CreditType.Individual;
			const isAllowedType = creditType === props.creditType;

			// required classification is a falsy value or is classified as "Additional Credits"
			const noClassification =
				!props.allowedCreditClassification ||
				props.allowedCreditClassification === "Additional Credits";

			const creditClassification =
				creditType === CreditType.Company
					? (credit as CompanyCredit).companyType === "Other"
						? (credit as CompanyCredit).otherCompanyType
						: (credit as CompanyCredit).companyType
					: (credit as IndividualCredit).jobTitle === "Other"
					? (credit as IndividualCredit).otherJobTitle
					: (credit as IndividualCredit).jobTitle;

			const isAllowedClassification =
				noClassification ||
				creditClassification === props.allowedCreditClassification;

			if (!isAllowedType) {
				addNewAlert({
					type: "error",
					message: `This field only accepts ${
						CreditType[props.creditType]
					} credits`,
				});
			} else if (!isAllowedClassification) {
				addNewAlert({
					type: "error",
					message: `This field only accepts ${props.allowedCreditClassification} credits`,
				});
			}

			return isAllowedType && isAllowedClassification;
		});

		return filtered;
	};

	const handleRemove = (creditId: number) => {
		if (!props.disabled) {
			if (props.onRemove) {
				setIsLoading(true);
				props
					.onRemove(creditId)
					.then(() => setIsLoading(false))
					.catch(() => setIsLoading(false));
			}
		}
	};

	const handleCreditList = (
		arr: DragCreditType[],
		sortable: Sortable | null,
		store: Store
	) => {
		const isStateDiff =
			store.dragging &&
			store.dragging.props &&
			JSON.stringify(store.dragging.props.list) !== JSON.stringify(arr);

		// allow drop when there's only 1 credit in the library and a single credit is dropped
		const isSingleCreditInLibrary =
			store.dragging &&
			store.dragging.props &&
			store.dragging.props.list.length === 1;

		// only run setCreditList once on drag end
		// https://github.com/SortableJS/react-sortablejs/issues/210
		if (isStateDiff || isSingleCreditInLibrary) {
			if (prevCreditList !== null) {
				// on reorder
				if (prevCreditList.length === arr.length) {
					setIsLoading(true);
					// set credit list immediately in the front-end
					setCreditList(arr);

					if (props.onReorder) {
						props
							.onReorder(arr)
							.then(() => setIsLoading(false))
							.catch(() => setIsLoading(false));
					} else setIsLoading(false);

					ref.current = arr;
					return;
				}
				// on add
				else {
					if (checkMax(arr, props.max)) {
						addNewAlert({
							type: "error",
							message: CREDIT_UPLOAD_COUNT_MESSAGE(props.max!),
						});
						setCreditList(prevCreditList);

						return;
					}

					setIsLoading(true);

					const filteredCreditType = filterCreditTypeAndClassification(arr);
					const filteredDuplicates = filterDuplicates(filteredCreditType);

					// compare original with filtered credit arr to get the added credit
					const addedCredits = filteredDuplicates.filter((element) => {
						return (
							prevCreditList.findIndex(
								(prevCredit) => element.id === prevCredit.id
							) === -1
						);
					});

					// set credit list immediately in the front-end
					setCreditList(filteredDuplicates);

					if (addedCredits.length > 0 && props.onAdd) {
						props
							.onAdd(addedCredits)
							.then(() => setIsLoading(false))
							.catch(() => setIsLoading(false));
					} else setIsLoading(false);

					ref.current = filteredDuplicates;
					return;
				}
			}
		}

		ref.current = arr;
	};

	// multiselect for mobile credit library
	const handleMultiSelect = (arr: DragCreditType[]): Promise<string> => {
		return new Promise((resolve, reject) => {
			const isInvalidDrop = arr.some((credit) => {
				{
					const creditType = credit.hasOwnProperty("name")
						? CreditType.Company
						: CreditType.Individual;
					return creditType !== props.creditType;
				}
			});

			if (checkMax(arr, props.max)) {
				// existing files already meet file limit
				reject(CREDIT_UPLOAD_COUNT_MESSAGE(props.max!));
			} else if (isInvalidDrop) {
				reject(
					`This field only accepts ${CreditType[props.creditType]} credits`
				);
			} else {
				// checks the dragged over credits arr and filters out any duplicates within the dropped credit
				const filterDuplicates = (arr as DragCreditType[]).filter((arrObj) => {
					return !creditList.some((credit) => credit.id === arrObj.id);
				});
				const newDroppedCredit = [...creditList, ...filterDuplicates];

				if (props.onAdd) {
					props.onAdd(filterDuplicates);
				}

				setCreditList(newDroppedCredit);
				setResetCreditCheck(true);
				resolve("Sucessfully added credit");
			}
		});
	};

	useEffect(() => {
		if (props.companyCreditArr) {
			const dragCredits = props.companyCreditArr as DragCompanyCredit[];
			ref.current = dragCredits;
			setCreditList(dragCredits);
		} else if (props.indCreditArr) {
			const dragCredits = props.indCreditArr as DragIndividualCredit[];
			ref.current = dragCredits;
			setCreditList(dragCredits);
		}
	}, [props.companyCreditArr, props.indCreditArr]);

	useEffect(() => {
		if (sortableRef.current) {
			const isMaxDropped = creditList.length === props.max;
			const emptySpace = isMaxDropped ? 0 : 1.5 * cardHeight;
			const newMinHeight =
				emptySpace +
				(creditList.length > 0 ? creditList.length * cardHeight : 0);
			setMinHeight(newMinHeight);
		}
	}, [sortableRef, creditList]);

	return (
		<FieldWrapper
			className={props.className}
			name={props.name}
			success={props.success}
			error={props.error && !props.hideErrMessage ? props.error : undefined}
			hideSuccessMessage={props.hideErrMessage}
		>
			{(success, error) => (
				<>
					{isMobile && (
						<Button
							className="w-full mb-[1rem]"
							icon="plus"
							onClick={() => setCreditSelect(() => handleMultiSelect)}
							disabled={props.disabled}
						>
							Add Credits
						</Button>
					)}

					<SpinnerContainer show={isLoading} background="rgba(0,0,0,.5)">
						<img src={SpinnerSrc} />
					</SpinnerContainer>

					<DropZone
						isInDropzone={props.drop === undefined ? true : props.drop}
						success={success !== undefined}
						isError={error !== undefined}
						disabled={props.disabled}
						hideShadow={props.hideShadow}
					>
						<ReactSortable
							ref={sortableRef}
							{...(props.dynamicHeight && {
								style: { minHeight: minHeight + "px" },
							})}
							className="h-full"
							list={creditList}
							setList={(arr, sortabble, store) =>
								handleCreditList(arr, sortabble, store)
							}
							{...sortableOptions({
								group: "credits",
								name: props.name,
								clone: props.clone,
								drop: props.drop,
								sortable: props.sortable,
								allowMultiSelect: props.allowMultiSelect,
							})}
							disabled={props.disabled}
						>
							{creditList.length > 0 ? (
								creditList.map((item) => (
									<DragCredit
										key={item.id}
										credit={item}
										creditType={props.creditType}
										allowMultiSelect={props.allowMultiSelect}
										onRemoveCredit={(creditId) => handleRemove(creditId)}
										readOnly={props.disabled}
									/>
								))
							) : (
								<></>
							)}
							{creditList.length === 0 ? (
								<DropText>
									{!isMobile && (
										<Icon
											icon="drag-arrows"
											color={theme.colorPrimary}
											className="mr-[1rem]"
											readonly={props.disabled}
										/>
									)}

									<p className="whitespace-pre">
										{props.placeholder || "No Credits"}
									</p>
								</DropText>
							) : (
								<></>
							)}
							{/* {creditList.length > 0 ? (
								creditList.map((item) => (
									<DragCredit
										key={item.id}
										credit={item}
										creditType={props.creditType}
										allowMultiSelect={props.allowMultiSelect}
										onRemoveCredit={(creditId) => handleRemove(creditId)}
									/>
								))
							) : (
								<DropText>
									{!isMobile && (
										<Icon
											icon="drag-arrows"
											color={theme.colorPrimary}
											className="mr-[1rem]"
										/>
									)}

									<p className="whitespace-pre">
										{props.placeholder || "No Credits"}
									</p>
								</DropText>
							)} */}
						</ReactSortable>
					</DropZone>
				</>
			)}
		</FieldWrapper>
	);
};

export default DraggableCredits;

interface DragCreditProps {
	className?: string;
	credit: CompanyCredit | IndividualCredit;
	creditType: CreditType;
	onRemoveCredit?(creditId: number): void;
	allowMultiSelect?: boolean;
	fileEllipsis?: number;
	isMobile?: boolean; // replaces drag/drop with multiselect
	onClick?(): void;
	checked?: boolean;
	readOnly?: boolean;
}

interface DraggableCreditListProps extends DraggableProps {
	// credit drag props
	companyCreditArr?: CompanyCredit[];
	indCreditArr?: IndividualCredit[];
	creditType: CreditType;
	allowedCreditClassification?: string;

	min?: number;
	max?: number;
	success?: string;
	error?: string;
	disabled?: boolean; //disable clicking/dragging functionality

	className?: string;
	height?: string;

	companyId?: number;

	onAdd?(creditArr: DragCreditType[]): Promise<void>; // return arr after removing duplicates
	onRemove?(creditId: number): Promise<void>;
	onReorder?(creditArr: DragCreditType[]): Promise<void>; // return arr after removing duplicates
	onUploadSuccess?: (() => void) | ((uploaded: any) => void);
	onUploadError?: (() => void) | ((uploaded: any) => void);

	dynamicHeight?: boolean; // adjust height of list depending on children
	placeholder?: string;
	hideErrMessage?: boolean;
	hideShadow?: boolean;
}

interface DragIndividualCredit extends IndividualCredit {
	id: number;
}

interface DragCompanyCredit extends CompanyCredit {
	id: number;
}

export type DragCreditType = DragCompanyCredit | DragIndividualCredit;
