import { Redirect } from "react-router-dom";
import { useEffect } from "react";
import { logout } from "../components/Auth/handleJWT";

export default function Logout() {
	useEffect(() => {
		logout();
	}, []);
	return <></>;
}
