import axios from "axios";
import { urlJurorAdmin, urlUserAdmin, urlUsers } from "../../../endpoints";
import { NemoUser } from "../../../hooks/useCurrentUser";
import {
	Juror,
	JurorCardConfig,
	JurorEntrySetSelectionCard,
	JurorJuryCard,
} from "../Judging/JudgingInterfaces";
import { DropdownOptions } from "../../../components/FormFields/DropdownField";

export async function getUser(userId: string) {
	const response = axios.get<NemoUser>(`${urlUserAdmin}/getUser/${userId}`);
	return response;
}

export async function updateUser(updatedUser: NemoUser) {
	try {
		const response = await axios.put(`${urlUserAdmin}/${updatedUser.id}`, {
			...updatedUser,
		});
		if (response.status == 204) {
			return response;
		}
	} catch (error: any) {
		throw error;
	}
}

export async function updateIsEntryReviewer(
	userId: string,
	isEntryReviewer: boolean
) {
	const response = await axios.get(
		`${urlUserAdmin}/updateIsEntryReviewer/${userId}?isEntryReviewer=${isEntryReviewer}`
	);
	return response;
}

export async function updateExludeFromJuries(
	userId: string,
	excludeFromJuries: boolean
) {
	const response = await axios.get(
		`${urlUserAdmin}/updateExludeFromJuries/${userId}?excludeFromJuries=${excludeFromJuries}`
	);
	return response;
}

export async function getEntryReviewers() {
	const response = axios.get<DropdownOptions[]>(
		`${urlUserAdmin}/getEntryReviewers`
	);
	return response;
}

export async function UpdateUserProfileImg(
	profileImg: FormData,
	userId: string
) {
	const headers = {
		"Content-Type": "multipart/form-data",
	};

	const response = await axios.post(
		`${urlUserAdmin}/updateUserProfileImg/${userId}`,
		profileImg,
		{
			headers: headers,
		}
	);
	return response;
}

export async function makeAdmin(userId: string) {
	try {
		const response = await axios.get(
			`${urlUserAdmin}/makeAdmin?userId=${userId}`
		);

		return response;
	} catch (error: any) {
		throw error;
	}
}

export async function removeAdmin(userId: string) {
	try {
		const response = await axios.get(
			`${urlUserAdmin}/removeAdmin/?userId=${userId}`
		);

		return response;
	} catch (error: any) {
		throw error;
	}
}

export async function getJurors() {
	const response = axios.get<JurorCardConfig[]>(
		`${urlUsers}/getCurrentUserJurors`
	);
	return response;
}

export async function getJurorEntrySetSelectionCards(juryId: number = 0) {
	return await axios.get<JurorEntrySetSelectionCard[]>(
		`${urlUsers}/getEntrySetCardsByUser?juryId=${juryId}`
	);
}
