import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import styled from "styled-components";
import TZDatePickerField from "../FormFields/TZDatePickerField";
import Button from "../Button/Button";
import ResultsTable from "./ResultsTable";
import { getOrderReports, OrderReport } from "./manageReports";
import config from "../../config";

const LoadingSpinner = styled.img`
  width: 100px;
`;

const spinnerSrc = config.assets.loading.primary;

const OrderUpload = () => {
  const [orderReports, setOrderReports] = useState<OrderReport[]>();
  const [orderReportLoading, setOrderReportLoading] = useState(false);

  let date = new Date();

  const formikProps = useFormik({
    initialValues: {
      startDate: Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours() + 5, // EST is 5 hours behind UTC
        date.getMinutes()
      ),
      endDate: Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours() + 5, // EST is 5 hours behind UTC
        date.getMinutes()
      ),
    },
    enableReinitialize: true,
    onSubmit: async () => {},
    validateOnBlur: false,
  });

  const generateInformation = async () => {
    setOrderReportLoading(true);
    try {
      const reports = await getOrderReports(
        formikProps.values.startDate,
        formikProps.values.endDate
      );
      setOrderReports(reports);
    } catch (error) {
      console.error("Failed to get order report: ", error);
    }
    setOrderReportLoading(false);
  };

  return (
    <div>
      <FormikProvider value={formikProps}>
        {/* Date picker section */}
        <div className="flex items-center space-x-4 pb-5">
          <div className="flex gap-[1rem] items-center">
            <TZDatePickerField
              name="startDate"
              placeholder="Start Date"
              value={formikProps.values.startDate}
            />
            <TZDatePickerField
              name="endDate"
              placeholder="End Date"
              value={formikProps.values.endDate}
            />
          </div>
          <Button onClick={generateInformation}>Generate</Button>
        </div>

        {/* Loading and Results display section */}
        {orderReportLoading ? (
          <div className="pt-10 flex flex-col items-center justify-center">
            <div>Running Order Report...</div>
            <LoadingSpinner src={spinnerSrc} />
          </div>
        ) : orderReports && orderReports.length > 0 ? (
          <ResultsTable data={orderReports} />
        ) : null}
      </FormikProvider>
    </div>
  );
};

export default OrderUpload;
