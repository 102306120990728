import { Formik, FormikProvider, useFormik } from "formik";
import styled, { useTheme } from "styled-components";
import { useContext, useEffect, useState, useLayoutEffect } from "react";
import Button from "../../../components/Button/Button";
import StyledForm from "../../../components/Form/Form";
import DisplayErrors from "../../../utils/DisplayErrors";
import * as Yup from "yup";
import TextField from "../../../components/FormFields/TextField";
import DropdownField from "../../../components/FormFields/DropdownField";
import { JobTitleOptions } from "../../../data/JobTitleData";
import { CanadaStateOptions, USStateOptions } from "../../../data/StateData";
import { CountryOptions } from "../../../data/CountryData";
import { saveToken, getClaims } from "../../../components/Auth/handleJWT";
import { urlUsers } from "../../../endpoints";
import AuthenticationContext from "../../../components/Auth/AuthenticationContext";
import {
	authenticationResponse,
	newUserCredentials,
} from "../../../components/Auth/auth.models";
import axios from "axios";
import { useHistory } from "react-router-dom";
import CheckboxField from "../../../components/FormFields/CheckboxField";
import ProfileIcon from "../../../assets/placeholders/profile.png";
import {
	UserProfileHeader,
	UserProfileBody,
} from "../../../components/UserPageTemplate/UserPageTemplate";
import { useCurrentUser, NemoUser } from "../../../hooks/useCurrentUser";

import config from "../../../config";
import assetsConfig from "../../../assetsConfig";
import { useAlert } from "../../../components/Alert/Alerts";

const CheckBoxTip = styled.div`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.colorCopyDarkDark};
	span {
		margin-left: 0.5rem;
		font-size: ${({ theme }) => theme.xSmallSize};
		color: ${({ theme }) => theme.colorCopyLight};
	}
`;

const LoadingSpinnder = styled.img`
	width: 15%;
	display: inline-flex;
`;

export async function updateProfileUrl(
	value: FormData,
	setValue: (arg0: string) => void
) {
	const headers = {
		"Content-Type": "multipart/form-data",
	};
	axios
		.post(`${urlUsers}/updateUserProfile`, value, {
			headers: headers,
		})
		.then((response) => {
			if (response.status == 200) {
				setValue(response.data);
			}
		})
		.catch((error: any) => {
			// console.log(error);
		});
}

const UserInfo = () => {
	const theme = useTheme();

	let fieldRequired = "This field is required";
	const { user, updateUser } = useCurrentUser();
	const [errors, setErrors] = useState<string[]>([]);
	const [programState, setProgramState] = useState(false);
	const [firstValidation, setFirstValidation] = useState(false);
	const [profileUrl, setProfileUrl] = useState(ProfileIcon);
	const { update } = useContext(AuthenticationContext);
	const history = useHistory();
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const { addNewAlert } = useAlert();

	useLayoutEffect(() => {
		if (Boolean(user.profileUrl)) {
			setProfileUrl(user.profileUrl!);
		}
		return;
	}, [user]);

	const initalValues = {
		...user,
	};

	const formikProps = useFormik({
		initialValues: initalValues,
		enableReinitialize: true,
		onSubmit: async (value) =>
			await updateUser(value)
				.then((res) => {
					// console.log("updateUser response", res);
					addNewAlert({
						type: "success",
						message: "Successfully updated information",
					});
				})
				.catch((error: any) => {
					let errorMessage = "An error occurred.";
					if (error.response && error.response.data) {
						errorMessage = error.response.data;
					}
					addNewAlert({ type: "error", message: errorMessage });
				}),
		validationSchema: Yup.object({
			fullName: Yup.string()
				.required(fieldRequired)
				.max(100, "Full Name Maximum is 100 Characters"),
			jobTitle: Yup.string().required(fieldRequired),
			email: Yup.string().required(fieldRequired),
			country: Yup.string().required(fieldRequired),
			address1: Yup.string().required(fieldRequired),
			city: Yup.string().required(fieldRequired),
			state: Yup.string().when("country", {
				is: (value: string) => value === "United States" || value === "Canada",
				then: Yup.string().required(fieldRequired),
			}),
			postalCode: Yup.string().when("country", {
				is: (value: string) => value === "United States" || value === "Canada",
				then: Yup.string().required(fieldRequired),
			}),
			phone: Yup.string().required(fieldRequired),
		}),
		validateOnBlur: false,
		validateOnChange: firstValidation,
	});

	function handleStateChange() {
		setProgramState(!programState);
	}

	async function updateSecondContact(credentials: any) {
		try {
			setErrors([]);
			// console.log(credentials);
			//TODO Update secondary Contact Info information
		} catch (error: any) {
			// console.log(error);
			//setErrors(error.response.data);
		}
	}

	const inviteButton = (
		<Button
			className="mt-[1rem] md:mt-0"
			icon="plus"
			iconColor={theme.colorCopyLightLight}
		>
			Invite Others
		</Button>
	);

	const checkForErrors = () => {
		if (Object.values(formikProps.errors).length > 0) {
			// console.log("form has error");
			addNewAlert({
				type: "error",
				message: "Please review the fields above for errors.",
			});
		}
	};

	return (
		<div>
			{/*TODO Create variables that auto fill user information based on HTTP protocol. Will have placeholders in meantime.*/}
			<UserProfileHeader
				img={profileUrl}
				heading={formikProps.values.fullName}
				subheading={formikProps.values.jobTitle}
				setValue={setProfileUrl}
				updateImage={updateProfileUrl}
				// contentRight={inviteButton}
			/>

			<UserProfileBody>
				{/*TODO: See about practicability of changing user form to component. Could be overly complicated, but will reevaluate after backend and futher pages. */}
				<FormikProvider value={formikProps}>
					<StyledForm className="twoColumn !gap-y-[2.5rem]">
						<div className="col-w-100">
							<h3 className="subtitle">Contact Information</h3>
						</div>

						<TextField name="fullName" placeholder="Full Name* " />

						<DropdownField
							name="jobTitle"
							placeholder="Job Title* "
							options={JobTitleOptions}
						/>

						<TextField name="email" placeholder="Email*" />

						<div className="col-w-100">
							<h3 className="subtitle">Address</h3>
							<p className="sectionText">
								{assetsConfig.copy.userInfoAddressMessage}
							</p>
						</div>

						<DropdownField
							name="country"
							placeholder="Country* "
							options={CountryOptions}
						/>

						<TextField name="address1" placeholder="Address 1*" />

						<TextField name="address2" placeholder="Address 2" />

						<TextField name="city" placeholder="City* " />

						{formikProps.values.country === "United States" ||
						formikProps.values.country === "Canada" ? (
							<DropdownField
								name="state"
								placeholder="State/Province/Region*"
								options={
									formikProps.values.country === "United States"
										? USStateOptions
										: CanadaStateOptions
								}
							/>
						) : (
							<TextField name="state" placeholder="State/Province/Region" />
						)}

						<TextField
							name="postalCode"
							placeholder={
								formikProps.values.country === "United States" ||
								formikProps.values.country === "Canada"
									? "Zip/Postal Code* "
									: "Zip/Postal Code "
							}
						/>

						<TextField name="phone" placeholder="Phone* " />

						<div className="col-w-100"></div>
						<Button
							className="xs:w-[300px] md:w-[350px] ml-auto button-activation"
							type="submit"
							icon="caret"
							iconRotation="-90deg"
							iconRight
							disabled={
								//Object.values(formikProps.errors).length !== 0 ||
								formikProps.isSubmitting
							}
							onClick={() => setFirstValidation(true)}
						>
							{formikProps.isSubmitting ? (
								<LoadingSpinnder src={config.assets.loading.primary} />
							) : (
								"Save Changes"
							)}
						</Button>
						{/* </div> */}
					</StyledForm>
				</FormikProvider>
				{/* <DisplayErrors errors={errors} /> */}
			</UserProfileBody>
		</div>
	);
};

export default UserInfo;
