import HierarchyNav, {
	HierarchyCard,
	SortHeader,
	generateGridArea,
	generateGridColumns,
} from "../../../../components/HierarchyNav/HierarchyNav";
import {
	CardBody,
	CardContainer,
	CardHeader,
} from "../../../../components/DashboardCard/DashboardCard";
import { useContext, useState, Fragment } from "react";
import Icon from "../../../../components/Icon/Icon";

import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import {
	BatchLabelGroup,
	BatchLabelGroupEntry,
	BatchLabelJury,
	EntrySetConfig,
} from "../JudgingInterfaces";
import DropdownField, {
	DropdownOptions,
} from "../../../../components/FormFields/DropdownField";
import { useEffect } from "react";
import styled, { useTheme, css } from "styled-components";
import Button from "../../../../components/Button/Button";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import {
	getBatchLabelGroups,
	getBatchLabelJurors,
	updateJuryGroup,
} from "../manageJudging";
import { useAdminHub } from "../../../../hooks/useAdminHub";
import {
	DynamicHeight,
	SortLabels,
	StyledCollapsibleContainer,
} from "./EntrySetAdministration";
import { CollapsibleContainer } from "../../../../components/Collapsible/Collapsible";
import Modal, {
	ModalCard,
	ModalContainer,
} from "../../../../components/Modal/Modal";
import { defaultBoxShadow } from "../../../../assetsConfig";
import BrowserHeader from "../../../../components/BrowserHeader/BrowserHeader";
import { useAlert } from "../../../../components/Alert/Alerts";

const StyledJurorList = styled.div`
	display: grid;
	grid-template-columns: 2fr 1fr;
	gap: 1rem;
	width: fit-content;
	margin: 1.5rem;
`;

const StyledEntrySetCard = styled.div<{ isSelected?: boolean }>`
	display: flex;
	align-items: center;
	height: 75px;
	padding: 1.25rem 1rem;
	border-left: 2px solid ${({ theme }) => theme.colorPrimary};
	box-shadow: ${defaultBoxShadow};
	cursor: pointer;

	${(p) =>
		p.isSelected &&
		`
      border-left: 5px solid ${p.theme.colorActivation};
    `};
`;

const JurorList = (props: { jurors: BatchLabelJury[] }) => {
	return (
		<div className="overflow-y-auto w-full">
			<StyledJurorList>
				<p className="font-semibold">Name</p>
				<p className="text-center font-semibold">Group</p>
				{props.jurors.map((juror, i) => (
					<Fragment key={i}>
						<p className="truncate">{juror.name}</p>
						<p className="text-center">{juror.juryGroup}</p>
					</Fragment>
				))}
			</StyledJurorList>
		</div>
	);
};

export const EntrySetCardComponent = (props: {
	setEditEntry(): void;
	name: string;
	isSelected: boolean;
}) => {
	const { addNewAlert } = useAlert();

	const formikProps = useFormik({
		initialValues: {
			name: props.name,
		},
		enableReinitialize: true,
		onSubmit: async () => {},
		validationSchema: Yup.object({}),
		validateOnBlur: false,
		validateOnChange: false,
	});

	return (
		<FormikProvider value={formikProps}>
			<StyledEntrySetCard
				onClick={() => props.setEditEntry()}
				isSelected={props.isSelected}
			>
				{formikProps.values.name}
			</StyledEntrySetCard>
		</FormikProvider>
	);
};

const GroupAssignmentCard = (props: {
	entry: BatchLabelGroupEntry;
	numberOfJuryGroups: number;
}) => {
	const { addNewAlert } = useAlert();

	const formikProps = useFormik({
		initialValues: props.entry,
		enableReinitialize: true,
		onSubmit: async () => {},
		validationSchema: Yup.object({}),
		validateOnBlur: false,
		validateOnChange: false,
	});
	const options: DropdownOptions[] = [];

	for (let index = 1; index <= props.numberOfJuryGroups; index++) {
		options.push({ value: index });
	}

	return (
		<FormikProvider value={formikProps}>
			<p>{props.entry.id}</p>
			<p>{props.entry.companyName}</p>
			<p>{props.entry.brand}</p>
			<p>{props.entry.title}</p>
			<DropdownField
				className="w-[60px] mx-auto"
				name="juryGroup"
				placeholder="Jury Group"
				options={options}
				onChange={(event) => {
					updateJuryGroup(props.entry.id, Number(event.target.value))
						.then((response) => {
							if (response.status == 200) {
								addNewAlert({
									type: "success",
									message: "Jury Group updated.",
								});
							} else {
								addNewAlert({
									type: "error",
									message: "Failed to update Jury Group.",
								});
							}
						})
						.catch((error) => {
							addNewAlert({
								type: "error",
								message: "Failed to update Jury Group.",
							});
						});
				}}
				hiddenLabel
				height="30px"
			/>
		</FormikProvider>
	);
};

const ManageGroup = () => {
	const theme = useTheme();
	const { programId, juryId, entrySetId, finalize, entrySetAdminCard } =
		useAdminHub();
	const [selectedEntrySetIndex, setSelectedEntrySetIndex] = useState<
		number | null
	>(null);

	const [jurors, setJurors] = useState<BatchLabelJury[]>([]);
	const [batchlabelGroups, setBatchlabelGroups] = useState<BatchLabelGroup[]>(
		[]
	);

	const [numberOfJuryGroups, setNumberOfJuryGroups] = useState(0);
	const [numberOfEntries, setNumberOfEntries] = useState(0);

	const [showJurors, setShowJurors] = useState(false);

	const columns = [
		{ label: "Eid", width: "75px" },
		{ label: "Entrant Company" },
		{ label: "Brand" },
		{ label: "Title" },
		{ label: "Jury Group", width: "100px", isCenter: true },
	];

	useEffect(() => {
		getBatchLabelJurors(Number(juryId)).then((response) => {
			if (response.status == 200) {
				// get the number of jury groups available.
				const highestJuryGroup = response.data.reduce(
					(maxJuryGroup, batchLabelJury) => {
						return batchLabelJury.juryGroup > maxJuryGroup
							? batchLabelJury.juryGroup
							: maxJuryGroup;
					},
					0
				);

				setNumberOfJuryGroups(highestJuryGroup);
				setJurors(response.data);
			}
		});

		getBatchLabelGroups(Number(entrySetId)).then((response) => {
			if (response.status == 200) {
				const totalCount = response.data.reduce((sum, batchLabelGroup) => {
					return sum + batchLabelGroup.batchLabelGroupEntries.length;
				}, 0);
				setNumberOfEntries(totalCount);
				setBatchlabelGroups(response.data);
			}
		});
	}, [juryId, entrySetId]);

	return (
		<CardContainer>
			<BrowserHeader title={`${entrySetAdminCard.name} Groups`} />
			<Modal show={showJurors}>
				<ModalCard
					title="Jurors"
					headerIcons={
						<Icon
							icon="close"
							color={theme.colorPrimary}
							onClick={() => setShowJurors(false)}
							width="30px"
							height="30px"
						/>
					}
				>
					{jurors && jurors.length > 0 && <JurorList jurors={jurors} />}
				</ModalCard>
			</Modal>
			<CardHeader>
				<div className="flex flex-col">
					<h2 className="card-title">
						Group Assignment for Entry Set: {entrySetAdminCard.name}
					</h2>
					<p className="text-colorCopyLight mt-[.5rem]">
						{numberOfEntries} Entries
					</p>
				</div>
				<div className="flex gap-[1rem]">
					<Button
						className="button-gold !text-colorCopyLightLight"
						onClick={() => setShowJurors(true)}
					>
						Show Jurors
					</Button>
					<Button
						className=""
						icon="check"
						onClick={() => finalize(Number(entrySetId), Number(juryId))}
					>
						Finalize
					</Button>
				</div>
			</CardHeader>

			<SortLabels>
				<hr className="h-[2px] mb-[1rem]" />
				<SortHeader
					className="mr-[1rem] ml-[61px] !font-semibold"
					columns={columns}
					noSortTriangle
					responsive
				/>
			</SortLabels>

			{batchlabelGroups && batchlabelGroups.length > 0 && (
				<DynamicHeight batchCards={batchlabelGroups}>
					{batchlabelGroups.map((batchLabelGroup, i) => (
						<StyledCollapsibleContainer>
							<CollapsibleContainer
								key={i}
								id={`batchLabelGroup${String(i)}`}
								title={batchLabelGroup.label}
							>
								{batchLabelGroup.batchLabelGroupEntries.map(
									(entry, entryIndex) => (
										<HierarchyCard
											key={`entry.${entryIndex}`}
											gridColumns={generateGridColumns(columns)}
											gridArea={generateGridArea(columns)}
											responsive
										>
											<GroupAssignmentCard
												key={entryIndex}
												entry={entry}
												numberOfJuryGroups={numberOfJuryGroups}
											/>
										</HierarchyCard>
									)
								)}
							</CollapsibleContainer>
						</StyledCollapsibleContainer>
					))}
				</DynamicHeight>
			)}
		</CardContainer>
	);
};

export default ManageGroup;

interface EntryGroup {
	id: number;
	entryTitle: string;
	companyName: string;
	brand: string;
	group: number;
	assignedJuror: string;
	isSelected: boolean;
}

interface EntrySetGroups {
	entrySet: EntrySetConfig;
	entries: EntryGroup[];
}

interface JurorListProps {
	jurorList: Array<{
		name: string;
		group: number;
	}>;
}
