// https://stackoverflow.com/questions/42341331/es6-promise-all-progress

// helper function for indicating progress when resolving promises
export function allProgress(
  proms: Promise<any>[],
  progress_cb: (percent: number) => void
) {
  let d = 0;
  progress_cb(0);
  for (const p of proms) {
    p.then(() => {
      d++;
      // progress_cb((d * 100) / proms.length);
      progress_cb(d);
    })
    .catch(error=> {console.log(error)});
  }
  return Promise.all(proms);
}