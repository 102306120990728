import Multiselect from "multiselect-react-dropdown";
import { DropdownOptions } from "../FormFields/DropdownField";
import styled from "styled-components";
import { useState } from "react";
import { FocusBorder } from "../FormFields/FieldTemplate";
import stringToId from "../../utils/stringToId";

const StyledMultiSelect = styled.div<{ isSelected: boolean }>`
	position: relative;
	height: fit-content;

	.multiselect-container {
		box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
		min-height: 56px;

		.search-wrapper {
			border: 0;
			/* padding: 0; */
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			padding: 0.5rem 1rem;
			min-height: inherit;

			input {
				margin-top: 0;
				line-height: 0px;
				width: 100%;

				&::-webkit-input-placeholder {
					/* position: absolute;
        top: 20px;
        left: 16px;
        transition: all 150ms ease; */
					color: ${({ theme }) => theme.colorCopyLight};
				}
			}

			.chip {
				border-radius: 50px;
				background: ${({ theme }) => theme.colorCopyLight};
			}
		}

		.optionContainer {
			border-radius: 0;
			box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
			/* .option:hover {
        background: ${({ theme }) => theme.colorActivation};
      } */
		}
	}

	${(p) =>
		p.isSelected &&
		`
    ${FocusBorder} {
        width: 4px;
          background: ${p.theme.colorActivation};
    }
    `}
`;

const MultiDropdown = (props: MultiDropdownProps) => {
	const [selectedItems, setSelectedItems] = useState(0);
	// if label is undefined, set label to value
	const generateLabels = (options: DropdownOptions[]) => {
		return options.map((option) => ({
			id: "lorem",
			key: "lorem",
			value: option.value,
			label: option.label === undefined ? option.value : option.label,
		}));
	};

	// when items ia added or removed from dropdown list
	const handleAddOrRemove = (selectedList: MultiDropdownOptions[]) => {
		//   console.log("sselected list", selectedList);
		setSelectedItems(selectedList.length);
		props.onChange(selectedList);
	};
	return (
		<StyledMultiSelect isSelected={selectedItems > 0}>
			<Multiselect
				displayValue="label"
				onRemove={(selectedList: MultiDropdownOptions[]) =>
					handleAddOrRemove(selectedList)
				}
				onSelect={(selectedList: MultiDropdownOptions[]) =>
					handleAddOrRemove(selectedList)
				}
				//   onSearch={() => {}}
				//   onKeyPressFn={() => {}}
				options={generateLabels(props.options)}
				id={`${stringToId(props.placeholder)}-dropdown`}
				selectedValues={
					props.selectedValues && props.selectedValues.length > 0
						? generateLabels(props.selectedValues)
						: undefined
				}
				showCheckbox
				placeholder={props.placeholder}
				closeIcon="cancel"
				avoidHighlightFirstOption
			/>
			<FocusBorder />
		</StyledMultiSelect>
	);
};

export default MultiDropdown;

interface MultiDropdownProps {
	placeholder: string;
	options: DropdownOptions[];
	onChange(selectedList: MultiDropdownOptions[]): void;
	selectedValues?: DropdownOptions[];
}

interface MultiDropdownOptions {
	value: any;
	label: string | number;
}
