import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
	CardBody,
	CardContainer,
	CardHeader,
} from "../../../../components/DashboardCard/DashboardCard";
import {
	NavTab,
	NavTabPanel,
	NavTabs,
} from "../../../../components/NavTabs/NavTabs";
import EntryCreationCheckout from "../../../../components/Reports/EntryCreationCheckout";
import { ConfigNavTabs } from "../../Program/ProgramConfig";
import JuryVotes from "./JuryVotes";
import BrowserHeader from "../../../../components/BrowserHeader/BrowserHeader";

const Reports = (props: { tab: string; metaDescription?: string }) => {
	const tabs = ["jury-votes"];

	const defaultTab = tabs.indexOf(props.tab || "jury-votes");
	const [tabIndex, setTabIndex] = useState(defaultTab);

	useEffect(() => {
		setTabIndex(defaultTab);
	}, [defaultTab]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [tabIndex]);

	return (
		<CardContainer>
			<BrowserHeader title={props.metaDescription || "Judging Reports"} />
			<CardHeader>
				<h2 className="card-title">Judging Reports</h2>
			</CardHeader>
			<NavTabs
				selectedIndex={tabIndex}
				onSelect={(index) => setTabIndex(index)}
			>
				<ConfigNavTabs>
					{/*           <NavTab>
            <Link className="no-underline" to="/reports/all">
              All Reports
            </Link>
          </NavTab> */}
					<NavTab>
						<Link className="no-underline" to="/judging/reports/jury-votes">
							Jury Votes
						</Link>
					</NavTab>
				</ConfigNavTabs>
				{/*         <NavTabPanel>
          <CardBody></CardBody>
        </NavTabPanel> */}
				<NavTabPanel>
					<CardBody>
						<JuryVotes />
					</CardBody>
				</NavTabPanel>
			</NavTabs>
		</CardContainer>
	);
};

export default Reports;
