import Button from "../../../../components/Button/Button";
import { useContext, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { getRootJudgingAdminPrograms } from "../manageJudging";

import DashboardCard from "../../../../components/DashboardCard/DashboardCard";

import HierarchyNav, {
	HierarchyCard,
} from "../../../../components/HierarchyNav/HierarchyNav";
import BrowserHeader from "../../../../components/BrowserHeader/BrowserHeader";
import { useAlert } from "../../../../components/Alert/Alerts";

export default function JudgingAdminLanding() {
	const rootProgramData: Array<JudgingRootProgramData> = [];
	const { addNewAlert } = useAlert();
	const [JudgingRootProgramsData, setJudgingRootProgramsData] =
		useState<Array<JudgingRootProgramData>>(rootProgramData);
	const theme = useTheme();

	useEffect(() => {
		getRootJudgingAdminPrograms()
			.then((res) => {
				if (res.status === 200) {
					//console.log("Pool", res.data);
					setJudgingRootProgramsData(res.data);
				}
			})
			.catch((err) => {
				//console.log("jurors error: ", err);
				addNewAlert({
					type: "error",
					message: "Problem getting Programs",
				});
			});
	}, []);

	return (
		<DashboardCard title="Judging Administration">
			<BrowserHeader title="Judging Administration" />
			{JudgingRootProgramsData && JudgingRootProgramsData.length > 0 && (
				<HierarchyNav
					responsive={true}
					columns={[
						{ label: "Program Title" },
						{ label: "Juries" },
						{ label: "Unassigned Entry Set Definitions" },
						{ label: null },
					]}
					noSortTriangle
				>
					{(gridColumns: string, gridArea: string) =>
						JudgingRootProgramsData.map((program, index) => (
							<HierarchyCard
								responsive={true}
								key={index}
								gridColumns={gridColumns}
								gridArea={gridArea}
							>
								<p>{program.name}</p>
								<p className="pl-[1.5rem]">{program.juryCount}</p>

								{program.allEntrySetsDefinitionsAssigned &&
								program.juryCount > 0 ? (
									<p>All Entry Set Definitions Assigned</p>
								) : (
									<p className="text-colorDanger">
										Unassigned Entry Set Definitions
									</p>
								)}

								<Button
									className="button-light ml-auto"
									iconLeft="eye"
									iconSize="20px"
									to={`/judging/admin/${program.id}`}
								>
									View
								</Button>
							</HierarchyCard>
						))
					}
				</HierarchyNav>
			)}
		</DashboardCard>
	);
}

interface JudgingRootProgramData {
	id: number;
	name: string;
	juryCount: number;
	allEntrySetsDefinitionsAssigned: boolean;
}
