import { FieldArray, useFormikContext } from "formik";
import { useTheme } from "styled-components";
import Button from "../../../components/Button/Button";
import TextField from "../../../components/FormFields/TextField";
import Icon from "../../../components/Icon/Icon";
import { EntryLinkField, LinkSet } from "../../Admin/Program/ProgramInterfaces";
import { ValidateContext } from "../EntryForm";
import {
  AUTOSAVE_MESSAGE,
  DynamicEntryField,
  EntryError,
} from "../DynamicFields";
import React, {
  useContext,
  useEffect,
  useState,
  Fragment,
  useRef,
} from "react";
import { FieldError } from "../../../components/FormFields/FieldTemplate";
import { fieldRequired } from "../../../components/FieldModal/FieldModal";
import { updateEntryField } from "../manageEntry";
import { CSSTransition } from "react-transition-group";
import { SuccessMessageWrapper } from "../../../components/FormFields/FieldWrapper";

const EntryLinkFieldForm = (props: EntryLinkFieldProps) => {
  const theme = useTheme();
  const validate = useContext(ValidateContext);
  const { setFieldValue } = useFormikContext<any>();
  const [showMaxErr, setShowMaxErr] = useState(false);
  const asterisk =
    props.field.programField.minLinks && props.field.programField.minLinks >= 1
      ? "*"
      : "";

  const noLinksErr =
    validate &&
    !props.field.programField.minLinks &&
    props.field.programField.minLinks &&
    props.field.programField.minLinks > 0;

  const minLinkErr =
    validate &&
    props.field.programField.minLinks &&
    props.field.programField.minLinks > 0 &&
    props.field.links &&
    props.field.links.length < props.field.programField.minLinks;

  const isMax =
    props.field.links &&
    props.field.links.length === props.field.programField.maxLinks;

  useEffect(() => {
    showMaxErr &&
      setTimeout(() => {
        setShowMaxErr(false);
      }, 2000);
  }, [showMaxErr]);

  const saveData = async (fieldData = props.field) => {
    if (props.saveOnChange) {
      try {
        const linkField = fieldData as EntryLinkField;
        return await updateEntryField(linkField);
      } catch (error) {
        console.error("Failed to update entry field", error);
      }
    }
  };

  return (
    <div className="col-w-100" id={`wrapper-div-${props.name}`}>
      {!props.isAdmin && (
        <>
          <h3 className="subtitle font-semibold">
            {props.field.programField.title}
            {asterisk}
          </h3>
          <p className="whitespace-pre-wrap">
            {props.field.programField.entrantHelpText}
          </p>
        </>
      )}

      <FieldArray name={`${props.name}.links`}>
        {({ insert, remove, push }) => {
          const newLink = {
            link: "",
            username: "",
            password: "",
            entryLinkFieldId: props.field.id,
          };

          return (
            <>
              <div className="flex flex-col gap-[2rem]">
                {props.field.links &&
                  props.field.links.length > 0 &&
                  (props.field.links as LinkSet[]).map((linkSet, index) => {
                    return (
                      <Fragment key={index}>
                        <div className="flex items-center w-full" key={index}>
                          <div className="flex flex-col gap-[2rem] items-center flex-1">
                            <SuccessMessageWrapper>
                              {(success, setSuccess) => (
                                <TextField
                                  className="w-full"
                                  placeholder="URL"
                                  name={`${props.name}.links.${index}.link`}
                                  value={linkSet.link}
                                  readOnly={props.readOnly}
                                  onBlur={() =>
                                    saveData().then((resp) => {
                                      if (resp?.status === 200) {
                                        setFieldValue(props.name, resp.data);
                                        setSuccess(AUTOSAVE_MESSAGE);
                                      }
                                    })
                                  }
                                  success={success}
                                  hideSuccessMessage
                                />
                              )}
                            </SuccessMessageWrapper>

                            <div className="flex gap-[1rem] w-full">
                              <SuccessMessageWrapper>
                                {(success, setSuccess) => (
                                  <TextField
                                    className="flex-1"
                                    name={`${props.name}.links.${index}.username`}
                                    placeholder="User Name (optional, leave blank if N/A)"
                                    value={linkSet.username || ""}
                                    readOnly={props.readOnly}
                                    onBlur={() =>
                                      saveData().then((resp) => {
                                        if (resp?.status === 200) {
                                          setFieldValue(props.name, resp.data);
                                          setSuccess(AUTOSAVE_MESSAGE);
                                        }
                                      })
                                    }
                                    success={success}
                                    hideSuccessMessage
                                  />
                                )}
                              </SuccessMessageWrapper>

                              <SuccessMessageWrapper>
                                {(success, setSuccess) => (
                                  <TextField
                                    className="flex-1"
                                    name={`${props.name}.links.${index}.password`}
                                    placeholder="Password (optional, leave blank if N/A)"
                                    value={linkSet.password || ""}
                                    readOnly={props.readOnly}
                                    onBlur={() =>
                                      saveData().then((resp) => {
                                        if (resp?.status === 200) {
                                          setFieldValue(props.name, resp.data);
                                          setSuccess(AUTOSAVE_MESSAGE);
                                        }
                                      })
                                    }
                                    success={success}
                                    hideSuccessMessage
                                  />
                                )}
                              </SuccessMessageWrapper>
                            </div>
                          </div>
                          <Icon
                            className="ml-[1rem]"
                            icon="close"
                            width="30px"
                            height="30px"
                            color={
                              props.readOnly
                                ? theme.colorFieldDisabled
                                : theme.colorPrimary
                            }
                            onClick={() => {
                              remove(index);

                              const updatedField = JSON.parse(JSON.stringify(props.field));
                              updatedField.links =  props.field.links.filter((_, i) => i !== index);

                              saveData(updatedField).then((resp) => {
                                if (resp?.status === 200) {
                                  setFieldValue(props.name, resp.data);
                                }
                              });
                            }}
                            readonly={props.readOnly}
                          />
                        </div>

                        <hr />
                      </Fragment>
                    );
                  })}
              </div>

              <Button
                type="button"
                className="w-[200px] button-light"
                icon="plus"
                iconColor={theme.colorActivation}
                onClick={() => {
                  isMax && setShowMaxErr(true);

                  (!isMax || !props.field.programField.maxLinks) &&
                    push(newLink);

                  saveData();
                }}
                disabled={props.readOnly}
              >
                Add Link
              </Button>
            </>
          );
        }}
      </FieldArray>
      {noLinksErr || minLinkErr ? (
        <FieldError>
          {`A minimum of ${props.field.programField.minLinks} links must be added`}
          {props.field.links && props.field.links.length}
        </FieldError>
      ) : (
        showMaxErr && (
          <FieldError>
            {`A maximum of ${props.field.programField.maxLinks} links can be added`}
          </FieldError>
        )
      )}
    </div>
  );
};

export default EntryLinkFieldForm;

interface EntryLinkFieldProps extends DynamicEntryField {
  field: EntryLinkField;
  name: string;
  saveOnChange?: boolean;
}
