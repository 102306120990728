import { useState } from "react";
import { Link } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import {
  HoverMenuLink,
  LinkLabel,
  StyledHoverMenu,
} from "../../components/HoverMenu/HoverMenu";
import Icon from "../../components/Icon/Icon";
import { ActiveBar } from "./JudgingGalleryNav";
import { HashLink } from "react-router-hash-link";
import { VerticalDivider } from "../../components/Breadcrumbs/Breadcrumbs";

const StyledJudgingGallerySideBar = styled.div<{ isOpen: boolean }>`
  position: sticky;
  margin-top: 2rem;
  top: 3rem;
  height: fit-content;
  z-index: 1000;
  transform: translateX(${({ isOpen }) => (isOpen ? "0px" : "-80vw")});
  transition: transform 0.5s ease-in-out;

  ${StyledHoverMenu}:after {
    display: none;
  }

  ${HoverMenuLink} {
    position: relative;
    border-bottom: 4px solid ${({ theme }) => theme.colorBackgroundLight} !important;

    &:hover {
      ${ActiveBar} {
        width: 55px;
      }
      font-weight: ${({ theme }) => theme.fontDefaultWeight} !important;
    }
  }

  .directory {
    background-color: ${({ theme }) => theme.colorActivation};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    p {
      writing-mode: vertical-rl;
      color: ${({ theme }) => theme.colorCopyLightLight};
      transform: rotate(180deg);
      font-weight: 600;
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.lg}) {
    transform: translateX(${({ isOpen }) => (isOpen ? "0px" : "-800px")});
  }
`;

const JudgingGallerySideBar = (props: JudgingGallerySideBarProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  // don't show side bar if there is no content to render
  if (!props.navLinks) return null;

  return props.show ? (
    <StyledJudgingGallerySideBar className={props.className} isOpen={isOpen}>
      <div className="flex bg-colorCopyLightLight shadow-md">
        <div className="w-[80vw] lg:w-[800px] h-[40vh] overflow-y-auto">
          {props.navLinks &&
            props.navLinks.length > 0 &&
            props.navLinks.map((link, i) => (
              <HashLink
                key={i}
                className="no-underline"
                to={link.path}
                onClick={() => setIsOpen((prv) => !prv)}
              >
                <HoverMenuLink className="!border-t-0">
                  <LinkLabel className="text-sm whitespace-pre-wrap break-words font-semibold">
                    {link.label}
                  </LinkLabel>
                  <ActiveBar leftPosition="29px" />
                </HoverMenuLink>
              </HashLink>
            ))}
        </div>
        <div
          className="directory px-3 py-6"
          onClick={() => setIsOpen((prv) => !prv)}
        >
          <Icon
            icon="caret"
            color={theme.colorCopyLightLight}
            height="15px"
            width="15px"
            className="caret mb-2"
            rotation={isOpen ? "90deg" : "-90deg"}
          />
          <p>Directory</p>
        </div>
      </div>
    </StyledJudgingGallerySideBar>
  ) : (
    <></>
  );
};

export default JudgingGallerySideBar;

interface JudgingGallerySideBarProps {
  className?: string;
  navLinks?: NavLink[];
  show: boolean;
}

export interface NavLink {
  label: string;
  path: string;
}
