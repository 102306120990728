import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import { WarningModal } from "../../components/Modal/Modal";

const InfoModal = (props: {
  show: boolean;
  roundType: string;
  jurorInstructions: string;
  onHide(): void;
}) => {
  const theme = useTheme();

  return (
    <WarningModal
      show={props.show}
      title={`${props.roundType} Round`}
      message={props.jurorInstructions}
      close={() => props.onHide()}
      icon={
        <Icon
          className="mb-[1rem]"
          icon="info"
          color={theme.colorPrimary}
          width="90px"
          height="90px"
        />
      }
    >
      <Button
        className="absolute left-0 bottom-0 w-full mt-auto"
        icon="check"
        onClick={() => props.onHide()}
      >
        OK
      </Button>
    </WarningModal>
  );
};

export default InfoModal;
