import axios from "axios";
import { urlTags } from "../../endpoints";
import { Tag } from "../MediaLibrary/mediaLibrary.model";

// Fetches all available tags for a company
export async function getTagsForCompany(companyId: number) {
	return await axios.get(`${urlTags}?companyId=${companyId}`);
}

export async function addTag(companyId: number, newTag: Tag) {
	return await axios.post(`${urlTags}?companyId=${companyId}`, newTag);
}
export async function editTag(companyId: number, tag: Tag) {
	return await axios.put(`${urlTags}/${tag.id}?companyId=${companyId}`, tag);
}

export async function deleteTag(companyId: number, tag: Tag) {
	return await axios.delete(`${urlTags}/${tag.id}?companyId=${companyId}`);
}
