export const daysDifference = (inputDate: Date) => {
	// Set time to midnight to ignore hours
	const today = new Date().setHours(0, 0, 0, 0);
	const input = new Date(inputDate).setHours(0, 0, 0, 0);

	return (today - input) / (1000 * 60 * 60 * 24);
};

// Function to check if a date is within a specified number of years and days
export const isDateWithinYearsAndDays = (date: Date, years: number) => {
	const currentDate = new Date();
	const inputDate = new Date(date);

	// Adjust for leap years
	let leapYearAdjustment = 0;
	for (
		let year = inputDate.getFullYear();
		year < currentDate.getFullYear();
		year++
	) {
		if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
			leapYearAdjustment++;
		}
	}

	// Calculate the day difference
	const dayDifference =
		Math.floor(
			(currentDate.setHours(0, 0, 0, 0) - inputDate.setHours(0, 0, 0, 0)) /
				(1000 * 60 * 60 * 24)
		) + leapYearAdjustment;

	// Check if the input date is within the specified number of years and days
	return dayDifference <= years * (365 + leapYearAdjustment);
};
