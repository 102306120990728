import { useContext, useEffect, useRef, useState } from "react";
import styled, { useTheme, css } from "styled-components";
import {
	getPlaceholder,
	getThumbnailSrc,
} from "../../views/MediaLibrary/manageMediaLibrary";
import { getClaims } from "../../components/Auth/handleJWT";
import {
	MediaItem,
	MediaType,
	Tag,
} from "../../views/MediaLibrary/mediaLibrary.model.d";
import Icon from "../../components/Icon/Icon";
import Image from "../../components/Image/Image";
import { DownloadMediaButton } from "../../components/DownloadMedia/DownloadMedia";
import addEllipsisAfterLetterCount from "../../utils/addEllipsisAfterLetterCount";
import dateTostring from "../../utils/dateToString";
import HideMediaButton from "../../components/HideMediaButton/HideMediaButton";
import Collapsible from "../../components/Collapsible/Collapsible";
import MediaEnlargeModal from "../../components/MediaEnlargeModal/MediaEnlargeModal";
import { KBtoMB } from "../../utils/KBtoMB";
import {
	Checked,
	FakeCheckbox,
} from "../../components/FormFields/CheckboxField";
import { FILE_UPLOAD_COUNT_MESSAGE } from "../../hooks/useFileHandlers";

import Sortable from "sortablejs";
import { ReactSortable, Store } from "react-sortablejs";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Button from "../Button/Button";
import FieldWrapper from "../FormFields/FieldWrapper";
import { useFormikContext } from "formik";
import { updateEntryField } from "../../views/Entries/manageEntry";
import LocalFileUpload, {
	SpinnerContainer,
	SpinnerSrc,
	UploadState,
} from "../LocalFileUpload.tsx/LocalFileUpload";
import {
	MediaCheckContext,
	ToggleMobileMediaLibContext,
} from "../../views/Entries/EntryForm";
import {
	DragCard,
	DraggableProps,
	DropZone,
	checkMax,
	filterDuplicates,
	sortableOptions,
} from "./Draggable";
import useTaskHandler, {
	TaskHandlerReturnType,
} from "../../hooks/useTaskHandler";
import { useAlert } from "../Alert/Alerts";

export const DragMedia = (props: DragMediaProps) => {
	const theme = useTheme();
	const claims = getClaims();
	const isAdmin = claims.some(
		(claim) => claim.name === "role" && claim.value === "admin"
	);
	const [collapse, setCollapse] = useState(true);
	const ref = useRef<HTMLDivElement | null>(null);
	// const [mobileChecked, setMobileChecked] = useState<boolean>(false);
	// const [selected, setSelected] = useState<boolean>(false);

	// const attrObserver = new MutationObserver((mutations) => {
	// 	mutations.forEach((mu) => {
	// 		if (mu.type !== "attributes" && mu.attributeName !== "class") return;

	// 		if ((mu.target as HTMLElement).classList.contains("sortable-selected")) {
	// 			setSelected(true);
	// 		} else {
	// 			setSelected(false);
	// 		}
	// 	});
	// });

	// useEffect(() => {
	// 	if (ref.current) {
	// 		attrObserver.observe(ref.current, { attributes: true });
	// 	}
	// }, [ref.current]);

	const handleRemoveMedia = () => {
		if (props.onRemoveMedia) {
			props.onRemoveMedia(props.mediaObj.id!);
		}
	};

	const handleClick = () => {
		if (props.onClick) {
			// if (props.isMobile) {
			// 	setMobileChecked(!mobileChecked);
			// }
			props.onClick();
		}
	};

	return (
		<DragCard
			className="draggable drag-media"
			key={props.mediaObj.id}
			data-id={props.mediaObj.id}
			data-filename={props.mediaObj.fileName}
			data-mediatype={props.mediaObj.type}
			ref={ref}
			onClick={handleClick}
		>
			<div className="drag-icons">
				{!props.isMobile && (
					<Icon
						className="drag-arrows"
						icon="drag-arrows"
						color={theme.colorPrimary}
					/>
				)}

				<div className="w-[85px] h-[50px] mr-[1rem] overflow-hidden">
					<Image
						className="object-cover"
						src={getThumbnailSrc(props.mediaObj)}
						placeholderSrc={getPlaceholder(props.mediaObj.type)}
						alt={props.mediaObj.fileName}
						refetchOnError
						// lazy
					/>
				</div>
				<div className="flex flex-col flex-1">
					<p className="field media-file-name pb-[1px]">
						{props.fileEllipsis
							? addEllipsisAfterLetterCount(props.mediaObj.fileName, 80)
							: props.mediaObj.fileName}
					</p>
					<div className="flex">
						<p className="field media-type">{MediaType[props.mediaObj.type]}</p>
						{props.mediaObj.createdDate && (
							<p className="field upload-date">
								{dateTostring(new Date(props.mediaObj.createdDate))}
							</p>
						)}
					</div>
				</div>

				<div className="icon-container ml-auto">
					{isAdmin && (
						<HideMediaButton
							className="mr-[.25rem]"
							hideForWinnersGallery={props.mediaObj.hideForWinnersGallery}
							mediaId={props.mediaObj.id!}
						/>
					)}

					<Icon
						icon="expandCaret"
						className="caret-down"
						color={theme.colorPrimary}
						height="20px"
						width="20px"
						rotation={collapse ? "0" : "180deg"}
						onClick={() => setCollapse(!collapse)}
					/>
					<DownloadMediaButton
						fileName={props.mediaObj.fileName}
						path={props.mediaObj.path}
					/>

					<Icon
						className="close-icon"
						icon="closeLarge"
						color={theme.colorPrimary}
						onClick={handleRemoveMedia}
					/>
				</div>
			</div>

			<div className="details">
				<Collapsible
					className="collapsible flex flex-col gap-[1rem] w-full items-center"
					isCollapsed={collapse}
				>
					<MediaEnlargeModal media={props.mediaObj} />

					<div className="grid grid-cols-2 gap-[1rem] w-fit">
						<p>File size:</p>
						<p>{KBtoMB(Number(props.mediaObj.size))}</p>
					</div>
				</Collapsible>

				{props.allowMultiSelect && (
					<FakeCheckbox
						className="checkbox-field"
						id={`multiSelect.${props.mediaObj.id}`}
						aria-label={"multiselect checkbox"}
						checked={props.checked}
					/>
				)}
			</div>
		</DragCard>
	);
};

const DraggableMediaList = (props: DraggableMediaListProps) => {
	const theme = useTheme();
	const { errors, values, setFieldValue } = useFormikContext<any>();
	const { addNewAlert } = useAlert();
	const { width } = useWindowDimensions();
	const ref = useRef<DragMediaItem[] | null>(null);
	const sortableRef = useRef<any>(null);
	const [minHeight, setMinHeight] = useState(194);
	const [isLoading, setIsLoading] = useState(false);

	// for mobile menu
	const { setResetMediaCheck } = useContext(MediaCheckContext);
	const { setMediaSelect } = useContext(ToggleMobileMediaLibContext);
	// replaces drag/drop with multiselect
	const isMobile = width <= Number(theme.lg.replaceAll("px", ""));

	const [mediaList, setMediaList] = useState<DragMediaItem[]>([]);
	const prevMediaList = ref.current;

	// check if the min # of files are uplaoded
	const isMinNotDropped =
		props.min && props.min > 0 && mediaList.length < props.min
			? `At least ${props.min} file is required.`
			: undefined;

	const filterMediaType = (arr: DragMediaItem[]) => {
		if (props.mediaType === undefined) {
			return arr; // allow all media types
		} else {
			const filtered = arr.filter(({ type }) => {
				const isAllowedMediaType = type === props.mediaType;

				if (!isAllowedMediaType)
					addNewAlert({
						type: "error",
						message: `This field only accepts ${
							MediaType[props.mediaType!]
						} files`,
					});

				return isAllowedMediaType;
			});

			return filtered;
		}
	};

	// extract [x] from formikProps.values.executions[x].executionFields[y].mediaItems
	const executionOrderRegex = props.name.match(
		/(?<=executions.)(.*)(?=.executionFields)/g
	);
	const executionOrder =
		executionOrderRegex && executionOrderRegex.length > 0
			? executionOrderRegex[0]
			: null;
	// extract [y] from formikProps.values.executions[x].executionFields[y].mediaItems
	const executionFieldOrderRegex = props.name.match(/[^executionFields.]*$/g);
	const executionFieldOrder =
		executionFieldOrderRegex && executionFieldOrderRegex.length > 0
			? executionFieldOrderRegex[0]
			: null;

	const entryField =
		executionOrder && executionFieldOrder
			? values["executions"][executionOrder]["executionFields"][
					executionFieldOrder
			  ]
			: null;

	const updateFormikPropsMedia = (
		droppedMedia: DragMediaItem[]
	): Promise<any> => {
		if (executionOrder && executionFieldOrder) {
			const formikExecutions = values["executions"];
			const executionFieldsCopy =
				values["executions"][executionOrder]["executionFields"];
			if (entryField && droppedMedia.length > 0) {
				// add the weights of each media item (after they're removed/added)
				const mediaWithWeight = droppedMedia.map((media, i) => ({
					mediaItem: { ...media, weight: i },
					fieldId: entryField.id,
					mediaId: media.id,
				}));
				// replace mediaItems (formik value)
				executionFieldsCopy.splice(executionFieldOrder, 1, {
					...executionFieldsCopy[executionFieldOrder!], // formikProps.values.executions[i].executionFields[i]
					mediaItems: mediaWithWeight, // formikProps.values.executions[i].executionFields[i].mediaItems
				});
				// replace formik executions
				formikExecutions.splice([executionOrder], 1, {
					...formikExecutions[executionOrder!], // formikProps.values.executions[i]
					executionFields: executionFieldsCopy, // formikProps.values.executions[i].executionFields
				});

				const mediaWeightMap = droppedMedia.reduce((weightMap, media, i) => {
					weightMap[media.id!] = i;
					return weightMap;
				}, {} as { [mediaId: string]: number });

				// entry field with reordered weights
				const reorderedEntryField = {
					...entryField,
					mediaItems: entryField.mediaItems.map((mediaItem: any) => {
						return {
							...mediaItem,
							weight: mediaWeightMap[mediaItem.mediaId],
						};
					}),
				};

				return updateEntryField(reorderedEntryField)
					.then((resp) => {
						if (resp.status === 200) {
							setFieldValue("executions", formikExecutions);
							return Promise.resolve();
						} else {
							addNewAlert({
								type: "error",
								message: "Failed to save.",
							});
						}
					})
					.catch((error: any) => {
						addNewAlert({
							type: "error",
							message: "Failed to save.",
						});
					});
			} else {
				// delete media from executions
				// replace mediaItems (formik value)
				executionFieldsCopy.splice(executionFieldOrder, 1, {
					...executionFieldsCopy[executionFieldOrder],
					mediaItems: [],
				});
				// replace formik executions
				formikExecutions.splice([executionOrder], 1, {
					...formikExecutions[executionOrder], // formikProps.values.executions[i]
					executionFields: executionFieldsCopy, // formikProps.values.executions[i].executionFields
				});

				return updateEntryField(entryField)
					.then((resp) => {
						if (resp.status === 200) {
							setFieldValue("executions", formikExecutions);
							return Promise.resolve();
						} else {
							addNewAlert({
								type: "error",
								message: "Failed to save.",
							});
						}
					})
					.catch((error: any) => {
						addNewAlert({
							type: "error",
							message: "Failed to save.",
						});
					});
			}
		}

		return Promise.reject();
	};

	const handleRemove = (mediaId: string) => {
		if (!props.disabled) {
			if (props.onRemove) {
				setIsLoading(true);
				props
					.onRemove(mediaId)
					.then(() => setIsLoading(false))
					.catch(() => setIsLoading(false));
			}
		}
	};

	const handleMediaList = (
		newMedia: DragMediaItem[],
		sortable: Sortable | null,
		store: Store
	) => {
		const isStateDiff =
			store.dragging &&
			store.dragging.props &&
			JSON.stringify(store.dragging.props.list) !== JSON.stringify(newMedia);

		// allow drop when there's only 1 media in the library and a single media is dropped
		const isSingleMediaInLibrary =
			store.dragging &&
			store.dragging.props &&
			store.dragging.props.list.length === 1;

		// only run setMediaList once on drag end
		// https://github.com/SortableJS/react-sortablejs/issues/210
		if (isStateDiff || isSingleMediaInLibrary) {
			if (prevMediaList !== null) {
				// on reorder
				if (
					prevMediaList.length === newMedia.length &&
					!isSingleMediaInLibrary
				) {
					setIsLoading(true);
					// set media list immediately in the front-end
					setMediaList(newMedia);

					updateFormikPropsMedia(newMedia)
						.then(() => {
							props.onReorder && props.onReorder(newMedia);
							setIsLoading(false);
						})
						.catch(() => {
							addNewAlert({
								type: "error",
								message: "Failed to save.",
							});
							setIsLoading(false);

							// reset media list if failed res from backend
							setMediaList(prevMediaList);
						});
				}
				// on add
				else {
					if (checkMax(newMedia, props.max)) {
						addNewAlert({
							type: "error",
							message: FILE_UPLOAD_COUNT_MESSAGE(props.max!),
						});
						setMediaList(prevMediaList);
						setIsLoading(false);
						return;
					}

					setIsLoading(true);

					const filteredMediaType = filterMediaType(newMedia);
					const filteredDuplicates = filterDuplicates(filteredMediaType);

					// compare original with filtered media arr to get the added media
					const addedMedia = filteredDuplicates.filter(
						(element) =>
							prevMediaList.findIndex((prev) => element.id === prev.id) === -1
					);

					// set media list immediately in the front-end
					setMediaList(filteredDuplicates);

					if (addedMedia.length > 0 && props.onAdd) {
						props
							.onAdd(addedMedia)
							.then(() => setIsLoading(false))
							.catch(() => setIsLoading(false));
					} else setIsLoading(false);

					ref.current = filteredDuplicates;
					return;
				}
			}
		}

		ref.current = newMedia;
	};

	// multiselect for mobile media library
	const handleMultiSelect = (newMedia: DragMediaItem[]): Promise<string> => {
		return new Promise((resolve, reject) => {
			const isInvalidDrop =
				props.mediaType &&
				newMedia.some((media) => media.type !== props.mediaType);

			if (checkMax(newMedia, props.max)) {
				// existing files already meet file limit
				reject(FILE_UPLOAD_COUNT_MESSAGE(props.max!));
			} else if (isInvalidDrop) {
				reject(`This field only accepts ${MediaType[props.mediaType!]} files`);
			} else {
				// checks the dragged over media arr and filters out any duplicates within the dropped media
				const filterDuplicates = (newMedia as DragMediaItem[]).filter(
					(newMediaObj) => {
						return !mediaList.some(
							(droppedMediaObj) => droppedMediaObj.id === newMediaObj.id
						);
					}
				);
				const newDroppedMedia = [...mediaList, ...filterDuplicates];

				if (props.onAdd) {
					props.onAdd(filterDuplicates);
				}

				setMediaList(newDroppedMedia);
				setResetMediaCheck(true);
				resolve("Sucessfully added media");
			}
		});
	};

	useEffect(() => {
		if (props.list) {
			ref.current = props.list;
			setMediaList(props.list);
		}
	}, [props.list]);

	useEffect(() => {
		if (sortableRef.current) {
			const newMinHeight =
				194 + (mediaList.length > 0 ? mediaList.length * 82 : 0);
			setMinHeight(newMinHeight);
		}
	}, [sortableRef, mediaList]);

	return (
		<>
			{isMobile && (
				<Button
					icon="plus"
					onClick={() => setMediaSelect(() => handleMultiSelect)}
				>
					Add Media
				</Button>
			)}

			<FieldWrapper
				className={props.className}
				name={props.name}
				success={props.success}
				error={
					errors && errors.hasOwnProperty(props.name) && errors[props.name]
						? (errors[props.name] as string)
						: isMinNotDropped
						? isMinNotDropped
						: undefined
				}
			>
				{(success, error) => (
					<>
						<SpinnerContainer show={isLoading} background="rgba(0,0,0,.5)">
							<img src={SpinnerSrc} />
						</SpinnerContainer>

						<DropZone
							isInDropzone={props.drop === undefined ? true : props.drop}
							success={success !== undefined}
							isError={error !== undefined}
							disabled={props.disabled || isLoading}
							hideShadow={props.hideShadow}
						>
							<ReactSortable
								ref={sortableRef}
								{...(props.dynamicHeight && {
									style: { minHeight: minHeight + "px" },
								})}
								className="h-full"
								list={mediaList}
								setList={(newMedia, sortabble, store) =>
									handleMediaList(newMedia, sortabble, store)
								}
								{...sortableOptions({
									group: "media",
									name: props.name,
									clone: props.clone,
									drop: props.drop,
									sortable: props.sortable,
									allowMultiSelect: props.allowMultiSelect,
								})}
							>
								{mediaList.map((item) => (
									<DragMedia
										key={item.id}
										mediaObj={item}
										allowMultiSelect={props.allowMultiSelect}
										onRemoveMedia={(mediaId) => handleRemove(mediaId)}
										fileEllipsis={props.fileEllipsis}
									/>
								))}

								{props.showLocalFileUpload ? (
									<LocalFileUpload
										droppedMedia={mediaList}
										companyId={props.companyId}
										min={props.min}
										max={props.max}
										tags={props.tags}
										mediaType={props.mediaType}
										disabled={props.disabled}
										onUploadSuccess={(uploaded) => {
											props.onUploadSuccess && props.onUploadSuccess(uploaded);
										}}
									/>
								) : (
									<></>
								)}
							</ReactSortable>
						</DropZone>
					</>
				)}
			</FieldWrapper>
		</>
	);
};

export default DraggableMediaList;

interface DragMediaProps {
	className?: string;
	mediaObj: MediaItem; // specific media object
	onRemoveMedia?(mediaId: string): void;
	allowMultiSelect?: boolean;
	fileEllipsis?: number;
	isMobile?: boolean; // replaces drag/drop with multiselect
	onClick?(): void;
	checked?: boolean;
}

interface DraggableMediaListProps extends DraggableProps, UploadState {
	list?: DragMediaItem[];
	mediaType?: MediaType; // only allow selected media type to be dropped

	min?: number;
	max?: number;
	success?: string;
	disabled?: boolean; //disable clicking/dragging functionality

	className?: string;

	companyId?: number;
	tags?: Tag[];

	onAdd?(mediaArr: DragMediaItem[]): Promise<void>; // return mediaArr after removing duplicates
	onRemove?(mediaId: string): Promise<void>;
	onReorder?(mediaArr: DragMediaItem[]): Promise<void>; // return mediaArr after removing duplicates

	showLocalFileUpload?: boolean;
	dynamicHeight?: boolean; // adjust height of list depending on children
	fileEllipsis?: number;

	hideShadow?: boolean;
}

export interface DragMediaItem extends MediaItem {
	id: string;
}
