import { useTheme } from "styled-components";
import Button from "../../components/Button/Button";
import { WarningModal } from "../../components/Modal/Modal";
import { Tag } from "../MediaLibrary/mediaLibrary.model";

const DeleteTagWarning = (props: DeleteTagWarningProps) => {
	const theme = useTheme();
	return (
		<WarningModal
			show={props.show}
			title="Delete Tag"
			message="This tag is attached to at least one credit or media asset. Deleting this credit will remove it from all credits and media assets it is attached to. If you would like to continue to delete this tag, select Delete."
			close={() => props.setShow(false)}
		>
			<Button
				className="button-light"
				icon="close"
				iconColor={theme.colorCopyDarkDark}
				iconSize="20px"
				onClick={() => props.setShow(false)}
			>
				Cancel
			</Button>
			<Button icon="trash" onClick={() => props.onConfirm()}>
				Delete
			</Button>
		</WarningModal>
	);
};

export default DeleteTagWarning;

interface DeleteTagWarningProps {
	show: boolean;
	setShow(show: boolean): void;
	onConfirm(): void;
}
