import { Form, Formik, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import styled, { ThemeConsumer, useTheme } from "styled-components";
import Button from "../../components/Button/Button";
import CheckboxField from "../../components/FormFields/CheckboxField";
import TextField from "../../components/FormFields/TextField";
import Lightbox from "../../components/Lightbox/Lightbox";
import StyledForm from "../../components/Form/Form";
import { useContext, useEffect, useState } from "react";
import AuthenticationContext from "../../components/Auth/AuthenticationContext";
import {
	userCredentials,
	authenticationResponse,
} from "../../components/Auth/auth.models";
import { urlUsers } from "../../endpoints";
import axios from "axios";
import { getClaims, saveToken } from "../../components/Auth/handleJWT";
import { useHistory, useLocation } from "react-router-dom";
import DisplayErrors from "../../utils/DisplayErrors";
import Modal from "../../components/Modal/Modal";

import { PatternBG } from "../../globalStyles";
import { fieldRequired } from "../../components/FieldModal/FieldModal";
import { urlResetPassword } from "../../endpoints";
import Icon from "../../components/Icon/Icon";
import config from "../../config";
import BrowserHeader from "../../components/BrowserHeader/BrowserHeader";
import assetsConfig from "../../assetsConfig";
import { useAlert } from "../../components/Alert/Alerts";

export const CustomBG = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	${PatternBG}
`;

const Logo = styled.img`
	width: 11.39rem;
	margin-bottom: 4rem;
`;

export const LoginModalCard = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	top: 0;
	padding: 2.625rem;
	width: 510px;
	background: ${({ theme }) => theme.colorBackgroundLightLight};
`;

export default function Login() {
	const { addNewAlert } = useAlert();
	const { update } = useContext(AuthenticationContext);
	const [showModal, setShowModal] = useState(false);
	const history = useHistory();
	const location = useLocation();
	const theme = useTheme();

	async function login(credentials: userCredentials) {
		try {
			const response = await axios.post<authenticationResponse>(
				`${urlUsers}/login`,
				credentials
			);
			saveToken(response.data);
			update(getClaims());

			// history.push("/");
		} catch (error: any) {
			addNewAlert({ type: "error", message: error.response.data });
		}
	}

	const formikProps = useFormik({
		initialValues: {
			email: "",
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.email("Invalid email format.")
				.required(fieldRequired),
		}),
		onSubmit: () => {
			axios
				.get(`${urlResetPassword}?email=${formikProps.values.email}`)
				.then((res) => {
					// console.log("res", res);
					setShowModal(false);
					addNewAlert({
						type: "success",
						message: `Password reset link is sent to ${formikProps.values.email}.`,
					});
					formikProps.setFieldValue("email", "");
				})
				.catch((err) => {
					// console.log("err", err);
					addNewAlert({
						type: "error",
						message: `Error. Please double check your email.`,
					});
				});
		},
		validateOnBlur: false,
		validateOnChange: false,
	});

	// show success message for password reset
	useEffect(() => {
		if (location.state) {
			if ((location.state as any).alert) {
				addNewAlert({
					type: "success",
					message: (location.state as any).alert,
				});
				history.replace({ state: {} });
			}
		}
	}, []);

	return (
		<CustomBG>
			<BrowserHeader title="Login" />
			<Lightbox className="flex-col items-center mx-auto h-fit self-center !max-w-[650px]">
				<Modal show={showModal}>
					<LoginModalCard>
						<Icon
							icon="close"
							color={theme.colorPrimary}
							className="ml-auto cursor-pointer"
							onClick={() => {
								setShowModal(false);
							}}
							width="35px"
							height="35px"
						/>
						<div>
							<h2 className="mb-[.5rem]">Forgot Password</h2>
							<p>
								If you have forgotten or need to reset your password, please
								enter your email address below and follow the instructions in
								the email you receive.{" "}
							</p>
							<p>
								If you continue to have issues or do not receive the email,
								please contact {assetsConfig.companyNameShort} Support at{" "}
								{assetsConfig.urls.contactEmail}.
							</p>
						</div>

						{/* TO DO: UPDATE FORMIK FOR RESET PASSWORD */}
						<FormikProvider value={formikProps}>
							<TextField
								className="mb-[1rem]"
								name="email"
								placeholder="Email*"
							/>
							<Button type="submit" onClick={() => formikProps.submitForm()}>
								Send Password Reset Link
							</Button>
						</FormikProvider>
					</LoginModalCard>
				</Modal>

				<Logo
					src={config.assets.logos.primary}
					alt={assetsConfig.companyNameShort}
				/>
				<h1 className="title">Login to your account</h1>
				<p className="mb-[2rem] text-center">
					{assetsConfig.copy.loginMessage}
				</p>
				{/* <DisplayErrors errors={errors} /> */}
				<Formik
					initialValues={{
						email: "",
						password: "",
					}}
					onSubmit={async (value) => await login(value)}
					validationSchema={Yup.object({
						email: Yup.string().required("Email is required"),
						password: Yup.string().required("Password is required"),
					})}
					validateOnBlur={false}
					validateOnChange={false}
				>
					{(formikProps) => (
						<StyledForm className="oneColumn">
							<TextField name="email" placeholder="Email Address*" />
							<TextField
								type="password"
								name="password"
								placeholder="Password*"
							/>
							<div className="flex justify-center flex-wrap space-x-[1rem] my-[3rem]">
								<Button
									className="button-light flex-1"
									onClick={() => (window.location.href = "/register")}
									type="button"
									icon="plus"
								>
									Create Account
								</Button>
								<Button
									className="flex-1"
									disabled={formikProps.isSubmitting}
									onClick={async () => {
										formikProps.validateForm(formikProps.values);
										await login(formikProps.values);
									}}
									type="submit"
									icon="caret"
									iconRotation="-90deg"
									iconRight
								>
									Log in
								</Button>
							</div>
							<div className="flex justify-end flex-wrap ">
								<a onClick={() => setShowModal(true)}>Forgot Password?</a>
							</div>
						</StyledForm>
					)}
				</Formik>
			</Lightbox>
		</CustomBG>
	);
}
