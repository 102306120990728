import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import { DarkPatternBG } from "../../../globalStyles";
import { useLiveToolsSettingsHub } from "../../../hooks/useLiveToolsSettingsHub";
import { GalleryEntryCard } from "../../Admin/Judging/JudgingInterfaces";
import AwardSummaryBox from "../../Admin/Judging/LiveTools/AwardSummaryBox";
import { PageContainer } from "../../EntrantProgram/EntrantProgram";
import { ProgressFill, ProgressIndicator } from "../JudgingGalleryNav";
import ScoreboardView from "./ScoreboardView";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
const displayPercentage = (num: number) => {
	return String(Math.round(num * 100)) + "%";
};
const DarkPageContainer = styled(PageContainer)`
	${DarkPatternBG}
`;
const ScoreboardHeader = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 2rem;
	padding: 1rem 2rem;
	background: ${({ theme }) => theme.colorBackgroundDarkDark};
	border-bottom: solid 5px ${({ theme }) => theme.colorActivationDark};
`;
const ScoreboardVotes = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 2rem;
	color: ${({ theme }) => theme.colorCopyLightLight};
	background: ${({ theme }) => theme.colorButtonNavy};
	margin-bottom: 2rem;
`;

const Scoreboard = () => {
	const { batches, settings, roundEntries, juryCard, connection } =
		useLiveToolsSettingsHub();
	const [entries, setEntries] = useState<GalleryEntryCard[]>([]);
	const breadcrumbs = settings.entryDisplayTitlePreview
		.split(" > ")
		.map((crumb) => {
			return { label: crumb };
		});

	useEffect(() => {
		setEntries(
			batches.length ? batches.flatMap((x) => x.roundEntries) : roundEntries
		);
	}, [batches, roundEntries]);

	useEffect(() => {
		if (connection) {
			connection.on("ScrollToTop", () => {
				window.scrollTo(0, 0);
			});

			return () => {
				connection.off("ScrollToTop");
			};
		}
	}, [connection]);

	return (
		<DarkPageContainer>
			<BrowserHeader title={`Scoreboard: ${juryCard?.name}`} />
			<ScoreboardHeader>
				<Link className="flex items-center mr-[3rem]" to="/">
					<img
						className="w-[160px]"
						alt="Program Award"
						src={settings.award?.imageDark}
					/>
				</Link>
				<Breadcrumbs
					className="mr-auto"
					navLinks={breadcrumbs}
					// onClick={handleClickBreadcrumb}
				/>
				{settings?.showProgressIndicators && (
					<div className="flex flex-col gap-[.5rem] mb-[1rem] text-colorCopyLightLight">
						<p className="text-sm ml-[40px]">Overall Progress</p>
						<div className="flex items-center">
							<p className="w-[40px] text-sm">{`${displayPercentage(
								juryCard.votesCast / juryCard.votesPossible
							)}`}</p>
							<ProgressIndicator>
								<ProgressFill
									size={Math.round(
										(juryCard.votesCast / juryCard.votesPossible) * 100
									)}
								/>
							</ProgressIndicator>
						</div>
					</div>
				)}
			</ScoreboardHeader>
			{(settings.roundStructure &&
				settings.roundStructure.name !== "Grand" &&
				settings.roundStructure.name !== "Winner" && (
					<ScoreboardVotes>
						<p>
							{settings.selectedBatches &&
								settings.selectedBatches
									.map((batch) => batch.entryCount)
									.reduce((a, b) => a + b, 0)}{" "}
							Entries
						</p>
						<div className="flex flex-wrap md:flex-nowrap ml-auto gap-[.25rem]">
							<AwardSummaryBox
								roundEntries={entries}
								roundType={settings.roundStructure?.name || ""}
								voteOptions={
									settings.roundStructure?.roundType?.voteOptions || []
								}
								awardOnly={false}
								includeAbstain={false}
								revealResults={true}
								votedOnly={settings.roundStructure?.name !== "Statue"}
								includeUnvoted={
									// !entries.flatMap((x) => x.votes).every((x) => x.voteOptionId)
									false
								}
							/>
						</div>
					</ScoreboardVotes>
				)) || <div className="mb-[2rem]"></div>}

			<div className="max-w-[1800px] mx-auto mt-[2rem]">
				<ScoreboardView
					batches={batches}
					roundEntries={roundEntries}
					settings={{
						...settings,
						revealWinner: settings.revealWinner,
					}}
				/>
			</div>
		</DarkPageContainer>
	);
};
export default Scoreboard;
