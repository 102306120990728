import Button from "../../../../components/Button/Button";
import DashboardCard, {
	CardHeader,
	CardBody,
	CardContainer,
} from "../../../../components/DashboardCard/DashboardCard";
import styled, { css, keyframes, useTheme } from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import Icon from "../../../../components/Icon/Icon";
import HierarchyNav, {
	HierarchyCard,
	SubLevel,
} from "../../../../components/HierarchyNav/HierarchyNav";
import { expandAnimation } from "../../../../components/HierarchyNav/HierarchyNav";
import { useContext, useEffect, useState } from "react";
import ProgramCard from "../../../../components/ProgramCard/ProgramCard";
import { getProgramJudgingList } from "../manageJudging";

import BrowserHeader from "../../../../components/BrowserHeader/BrowserHeader";
import { useAlert } from "../../../../components/Alert/Alerts";

const JuryCard = (props: JuryCardProps) => {
	const theme = useTheme();
	const history = useHistory();
	const { votesCast, votesPossible } = props.juryCard;
	const progress =
		votesPossible && !isNaN(votesCast / votesPossible)
			? Math.round((votesCast / votesPossible) * 100) + "%"
			: "N/A";

	return (
		<>
			<p className=" truncate font-semibold">{props.juryCard.name}</p>
			<p className="text-center">{props.juryCard.activeEntries}</p>
			<p className="text-center">{progress}</p>

			<div
				className="flex items-center justify-end cursor-pointer gap-[.5rem]"
				onClick={() =>
					history.push(`/judging/admin/${props.programId}/${props.juryCard.id}`)
				}
			>
				<p>Entry Set</p>
				<Icon
					icon="caret"
					color={theme.colorBackgroundDarkDark}
					rotation="-90deg"
				/>
			</div>
		</>
	);
};

export default function JudgingAdminProgram() {
	const theme = useTheme();
	const { programId } = useParams<{ programId: string }>();
	const { addNewAlert } = useAlert();
	const [programCard, setProgramCard] = useState<ProgramCardAdmin>();

	useEffect(() => {
		getProgramJudgingList(Number(programId))
			.then((res) => {
				if (res.status === 200) {
					setProgramCard(res.data);
				}
			})
			.catch((err) => {
				//console.log("jurors error: ", err);
				addNewAlert({
					type: "error",
					message: "Problem getting Programs",
				});
			});
	}, []);

	return (
		<DashboardCard title={`${programCard?.name} Judging Administration`}>
			<BrowserHeader title={`Judging Admin: ${programCard?.name}`} />
			<HierarchyCard className="!flex gap-[1rem] justify-between">
				<div className="flex items-center gap-[1rem]">
					<Button className="button-light" to={`/judging/admin`}>
						Back
					</Button>
					<h2>{programCard?.name}</h2>
				</div>

				<p>{programCard?.juryCards.length} Juries</p>

				{programCard?.allEntrySetDefinitionsAssigned ? (
					<p className="active">All Entry Set Defintions Assigned</p>
				) : (
					<p className="inactive">Unassigned Entry Set Definitions</p>
				)}
			</HierarchyCard>
			{programCard?.juryCards && programCard.juryCards.length > 0 && (
				<HierarchyNav
					columns={[
						{ label: "Jury Name" },
						{ label: "Entries" },
						{ label: "Progress" },
						{ label: null, width: "100px" },
					]}
					isCenterLabel
					noSortTriangle
					dashBorder
				>
					{(gridColumns: string, gridArea: string) =>
						programCard.juryCards.map((juryCard, i) => (
							<SubLevel order={i} expandAnimation={expandAnimation}>
								<HierarchyCard
									className="text-center h-[80.391px]"
									key={i}
									gridColumns={gridColumns}
									gridArea={gridArea}
								>
									<span className="horizontal-branch" />
									<JuryCard
										key={i}
										juryCard={juryCard}
										programId={Number(programId)}
									/>
								</HierarchyCard>
							</SubLevel>
						))
					}
				</HierarchyNav>
			)}
		</DashboardCard>
	);
}

export interface ProgramCardAdmin {
	id: number;
	name: string;
	allEntrySetDefinitionsAssigned: boolean;
	juryCards: JuryCardAdmin[];
}

interface JuryCardAdmin {
	id: number;
	name: string;
	activeEntries: number;
	votesCast: number;
	votesPossible: number;
}

interface JuryCardProps {
	juryCard: JuryCardAdmin;
	programId: number;
}
