import { Link, useParams } from "react-router-dom";
import { Header, getCompany } from "../Entries/CompanyMediaLibrary";
import { CompanyModel } from "../../Company/company.model";
import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import Button from "../../../components/Button/Button";
import TagLibrary from "../../TagLibrary/TagLibrary";

const CompanyTagLibrary = () => {
	const { companyId } = useParams<{
		companyId?: string;
	}>();

	const [company, setCompany] = useState<CompanyModel | null>(null);
	const theme = useTheme();
	const [addTagModal, setAddTagModal] = useState(false);

	useEffect(() => {
		getCompany(Number(companyId)).then((response) => {
			setCompany(response.data);
		});
	}, [companyId]);

	return (
		<>
			<Header>
				<div>
					<h1 className="playfair-500 mb-[1rem]">Company Tag Library</h1>
					{company && (
						<Link className="link_text" to={`/edit-company/${companyId}`}>
							{company.name} ({companyId})
						</Link>
					)}
				</div>
				<Button
					className="ml-auto my-auto h-fit"
					icon="plus"
					onClick={() => setAddTagModal(true)}
				>
					Add Tags
				</Button>
			</Header>

			<TagLibrary
				metaDescription={`Tag Library: [${companyId}] ${company?.name}`}
				showAddTagModal={addTagModal}
				setshowAddTagModal={setAddTagModal}
			/>
		</>
	);
};

export default CompanyTagLibrary;
