import Button from "../../../components/Button/Button";
import { useState, useEffect } from "react";
import { ProgramDeadline } from "./ProgramInterfaces";
import TextField from "../../../components/FormFields/TextField";
import { useTheme } from "styled-components";
import Icon from "../../../components/Icon/Icon";
import { FieldError } from "../../../components/FormFields/FieldTemplate";
import { FieldArray, useFormikContext, getIn, Field } from "formik";
import TZDatePickerField from "../../../components/FormFields/TZDatePickerField";

const DeadlineFields = (props: DeadlineFieldsProps) => {
  const theme = useTheme();
  const { values, errors, resetForm } = useFormikContext<any>();

  return (
    <>
      <FieldArray name={props.name} validateOnChange={false}>
        {({ insert, remove, push }) => (
          <>
            {values.deadlines?.length > 0 &&
              (values.deadlines as ProgramDeadline[]).map((deadline, index) => {
                return (
                  <div className="flex gap-[1rem] items-center" key={index}>
                    <TZDatePickerField
                      name={`deadlines.${index}.date`}
                      placeholder="Date"
                      value={deadline.date}
                      showTimeSelect
                    />
                    <TextField
                      placeholder="Name"
                      name={`deadlines.${index}.name`}
                      value={deadline.name}
                    />
                    <TextField
                      name={`deadlines.${index}.price`}
                      placeholder="Price"
                      type="number"
                      value={deadline.price}
                      iscurrency
                    />
                    <TextField
                      name={`deadlines.${index}.campaignMultiplier`}
                      placeholder="Camp. Multiplier"
                      type="number"
                      value={deadline.campaignMultiplier}
                      validatePositive={true}
                    />
                    <Icon
                      className="ml-[1rem]"
                      icon="close"
                      color={theme.colorPrimary}
                      onClick={() => remove(index)}
                    />
                  </div>
                );
              })}
            {errors.deadlines &&
              Array.isArray(errors.deadlines) &&
              typeof errors.deadlines[0] === "object" &&
              errors.deadlines[0].name !== "" &&
              errors.deadlines[0].price !== "" && (
                <FieldError>Date, name, and price cannot be empty.</FieldError>
              )}
            <Button
              type="button"
              className="w-[200px] button-light"
              icon="plus"
              iconColor={theme.colorActivation}
              onClick={() =>
                push({
                  name: "",
                  date: null,
                  price: "",
                  programId: 0,
                })
              }
            >
              Add Deadline
            </Button>
          </>
        )}
      </FieldArray>
    </>
  );
};

interface DeadlineFieldsProps {
  name: string;
}

export default DeadlineFields;
