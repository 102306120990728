import rootReducer from "../reducers";
import { configureStore } from "@reduxjs/toolkit";
import {
	createStateSyncMiddleware,
	initMessageListener,
} from "redux-state-sync";

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(createStateSyncMiddleware({}) as any),
});

initMessageListener(store);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
