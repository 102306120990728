import {
  NavTab,
  NavTabList,
  NavTabPanel,
  NavTabs,
} from "../../../components/NavTabs/NavTabs";
import UserMenu from "../User/UserMenu";
import DropdownField, {
  DropdownOptions,
} from "../../../components/FormFields/DropdownField";
import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
  Fragment,
  useMemo,
} from "react";
import parse from "html-react-parser";
import * as Yup from "yup";
import TextField from "../../../components/FormFields/TextField";
import Button, {
  CustomNavLink,
  SquareButton,
} from "../../../components/Button/Button";
import CheckboxField from "../../../components/FormFields/CheckboxField";
import StyledForm from "../../../components/Form/Form";
import { UserProfileBody } from "../../../components/UserPageTemplate/UserPageTemplate";
import StickyCard, {
  Card,
  StickyCardHeader,
  StickyContainer,
  StickyHeaderBar,
} from "../../../components/StickyCard/StickyCard";
import RadioField, {
  NonFormikRadio,
} from "../../../components/FormFields/RadioField";
import styled, { useTheme, css } from "styled-components";
import Modal from "../../../components/Modal/Modal";
import { ReactComponent as CaretDown } from "../../../assets/icons/Down.svg";
import SearchBar from "../../../components/SearchBar/SearchBar";
import GalleryCard from "../../../components/GalleryCard/GalleryCard";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import ExecutionDragCard from "../../../components/ExecutionDragCard/ExecutionDragCard";
import update from "immutability-helper";
import { ListContainer } from "../../../components/FormFields/RadioField";
import { IndividualCredit, CompanyCredit } from "../Credits/CreditInterfaces";
import { useFormik, FormikProvider } from "formik";
import {
  Ancestor,
  CreditType,
  DateType,
  EntryCompanyCreditField,
  EntryDateField,
  EntryIndividualCreditField,
  EntryLinkField,
  EntryListField,
  EntryPhysicalComponentField,
  EntryTextField,
  ProgramModel,
} from "../Program/ProgramInterfaces";
import {
  CoverImageMediaJoin,
  ExecutionModel,
} from "../../Entries/EntryInterfacesComplete";
import { AdminEntryModel } from "../../Entries/EditEntry";
import {
  MediaItem,
  MediaType,
  Tag,
} from "../../MediaLibrary/mediaLibrary.model.d";
import {
  getMedia,
  getMediaSrc,
  ThumbnailSize,
} from "../../MediaLibrary/manageMediaLibrary";
import {
  getActiveCompanyCredits,
  getActiveIndividualCredits,
} from "../Credits/manageCreditLibrary";
import DynamicFields, {
  EntryError,
  isAProgramMediaField,
} from "../../Entries/DynamicFields";
import CreditFields from "../../Entries/CreditFields";
import { AxiosResponse } from "axios";

import {
  addRevisionNote,
  changePaymentStatus,
  changeReviewStatus,
  createExecution,
  getAwardableAwards,
  updateEntry,
  markEntryAsChild,
  updateEntryProperty,
  UpdatePropertyRequest,
  updateExecutionProperty,
  sortEntryFields,
} from "../../Entries/manageEntry";
import SelectExecutionModal from "../../Entries/SelectExecutionModal";
import ChildEntryModal from "../../Entries/ChildEntryModal";
import AddIndividualCreditForm from "../Credits/AddIndividualCreditForm";
import AddCompanyCreditForm from "../Credits/AddCompanyCreditForm";
import { EntrySavedPopup, ChangeWarning } from "../../Entries/EntryWarnings";
import { fieldRequired } from "../../../components/FieldModal/FieldModal";
import { Link, Prompt, useHistory } from "react-router-dom";
import AuthenticationContext from "../../../components/Auth/AuthenticationContext";
import EntryValidation from "../../Entries/EntryValidation";
import { Execution } from "../Program/ProgramInterfaces";
import dateTostring, {
  dateTimeTostring,
  isValidTimeStamp,
  numberToDate,
} from "../../../utils/dateToString";
import {
  PaymentStatusOptions,
  ReviewStatusOptions,
  ReviewStatus,
  PaymentStatus,
} from "../../Checkout/OrderInterfaces";
import { useAwardableAwards } from "../../../hooks/useAwardableAwards";
import Sort from "../../../components/Sort/Sort";
import PrimaryLogo from "../../../components/PrimaryLogo/PrimaryLogo";
import { EntryMediaJoin } from "../Program/ProgramInterfaces";
import Icon from "../../../components/Icon/Icon";
import { ModalCard } from "../../../components/Modal/Modal";
import { getHierarchy } from "../../Checkout/manageCheckout";
import HierarchyDropDownModal from "../../../components/HierarchyDropDownModal/HierarchyDropDownModal";
import { EntryAction } from "../../../components/HierarchyDropDownModal/HierarchyDropDown";
import AddCreditModal from "../Credits/AddCreditModal";
import {
  ExecutionForm,
  ToggleMobileMediaLibContext,
  ValidateContext,
  createTag,
} from "../../Entries/EntryForm";
import { CreditCheckContext } from "../../Entries/EntryForm";
import { MediaCheckContext } from "../../Entries/EntryForm";
import {
  AdminEditPageContainer,
  EntryMediaContainer,
  ExecutionContainer,
  getAdminHierarchy,
} from "./AdminEntryEdit";
import ImagePlaceholder from "../../../assets/placeholders/image-landscape.png";
import { SectionBody } from "./AdminEntryEdit";
import RenderAdminEntryFields, {
  CampaignFieldDisplay,
} from "./RenderAdminEntryFields";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import DraggableMediaList, {
  DragMediaItem,
} from "../../../components/Draggable/DraggableMedia";
import NewMediaLibrarySticky from "../Media/NewMediaLibrarySticky";
import { useAlert } from "../../../components/Alert/Alerts";
import assetsConfig from "../../../assetsConfig";

const AdminMediaEdit = (props: AdminEntryModel) => {
  const theme = useTheme();
  const { addNewAlert } = useAlert();
  const { companyId } = props;
  const [media, setMedia] = useState<MediaItem[]>([]);
  const [hierarchy, setHierarchy] = useState<string[]>([]);

  const [mediaSelect, setMediaSelect] = useState(null);
  const [resetMediaCheck, setResetMediaCheck] = useState(false);

  const formikProps = useFormik({
    initialValues: props,
    onSubmit: () => {},
    validationSchema: Yup.object({}),
    validateOnBlur: false,
    validateOnChange: false,
  });

  const getCompanyMedia = () => {
    getMedia(companyId).then((response) => {
      // console.log("res media", response);
      if (response.status === 200) {
        setMedia(response.data);
      }
    });
  };

  // states for execution types
  const [selectedExecutionProgramId, setSelectedExecutionProgramId] = useState<
    number | undefined
  >();

  // modal states for add credit/media
  const [showModal, setShowModal] = useState<{
    [modal: string]: string | boolean | number;
  }>({
    addCredit: false,
    addMedia: false,
    addExecution: false,
    markAsChild: false,
  });

  // execution forms
  const [executionForms, setExecutionForms] = useState<ExecutionModel[]>(
    props.executions
  );
  const [draggedCard, setDraggedCard] = useState(false);
  const [showDeleteExecution, setShowDeleteExecution] = useState(false);

  // update execution index prop with its actual order
  const updateExecutionOrderWithIndex = (executionForms: ExecutionModel[]) => {
    return executionForms.map((execution, i) => {
      return {
        ...execution,
        index: i,
      };
    });
  };

  // update execution form field values
  useEffect(() => {
    setExecutionForms(formikProps.values.executions);
  }, [formikProps.values.executions]);

  // updates execution order when card is dragged
  useEffect(() => {
    // console.log("dragged card", draggedCard);
    if (draggedCard) {
      // update index value of each execution
      const updatedOrder = updateExecutionOrderWithIndex(executionForms);
      draggedCard && formikProps.setFieldValue("executions", updatedOrder);
      addNewAlert({
        type: "success",
        message: "Successfully updated execution order.",
      });
      setDraggedCard(false);
    }
  }, [draggedCard]);

  const moveExecutionForm = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setExecutionForms((prevCards: ExecutionModel[]) => {
        return update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex] as ExecutionModel],
          ],
        });
      });
    },
    []
  );

  const [deleteExecutionIndex, setDeleExecutionIndex] = useState<
    number | null
  >();

  const handleDeleteExecution = (index: number | null) => {
    if (index !== null) {
      const executionsAfterDelete = executionForms.filter(
        (obj, currentCardIndex) => {
          // console.log("delete", obj, currentCardIndex === index);
          return currentCardIndex !== index;
        }
      );
      const updatedOrder = updateExecutionOrderWithIndex(executionsAfterDelete);
      formikProps.setFieldValue("executions", updatedOrder);
      setDeleExecutionIndex(null);
    }
  };

  const renderCard = (
    card: ExecutionModel,
    index: number,
    disabled?: boolean
  ) => {
    return (
      <ExecutionDragCard
        key={card.id}
        index={index}
        id={card.id}
        title={card.title}
        executionFields={card.executionFields}
        moveExecutionForm={moveExecutionForm}
        onClickDelete={() => {
          setShowDeleteExecution(true);
          setDeleExecutionIndex(index);
        }}
        disabled={disabled}
      />
    );
  };

  const addExecution = (programId: number, clean?: boolean) => {
    createExecution(formikProps.values.id, programId).then((response) => {
      if (response.status === 200) {
        if (clean) {
          formikProps.setFieldValue("executions", [response.data]);
        } else {
          formikProps.setFieldValue("executions", [
            ...formikProps.values.executions,
            {
              ...response.data,
              index: formikProps.values.executions
                ? formikProps.values.executions.length
                : 0,
            },
          ]);
        }
      }
    });
  };

  const updatecoverImage = (media?: any, libraryChanged: boolean = false) => {
    const coverimage: CoverImageMediaJoin = {
      ...formikProps.values.coverImage,
      mediaId: media?.id,
      mediaItem: undefined,
    };

    const updatePropertyRequest: UpdatePropertyRequest = {
      id: formikProps.values.id,
      propertyName: "coverImage",
      propertyValue: coverimage,
    };

    return updateEntryProperty(updatePropertyRequest)
      .then((resp) => {
        if (resp.status === 200) {
          addNewAlert({
            type: "success",
            message: "saved successfully",
          });
          formikProps.setFieldValue("coverImage", {
            ...coverimage,
            mediaItem: media
              ? { ...media, tags: media.tags ? media.tags : [] }
              : undefined,
          });
          if (libraryChanged) {
            getCompanyMedia();
          }
        } else {
          addNewAlert({
            type: "error",
            message: "Failed to save.",
          });
        }
      })
      .catch((error: any) => {
        console.log("Failed to save: ", error);

        addNewAlert({
          type: "error",
          message: "Failed to save.",
        });
      });
  };

  useEffect(() => {
    setHierarchy(
      getAdminHierarchy(
        props.executions!,
        props.program.ancestry!,
        props.isCampaign!
      )
    );
  }, []);

  useEffect(() => {
    getCompanyMedia();
  }, []);

  const entryTags = useMemo(() => {
    const tags: Tag[] = [];
    if (formikProps.values.title && formikProps.values.companyId) {
      tags.push(
        createTag(formikProps.values.title, formikProps.values.companyId)
      );
    }

    if (formikProps.values.brand && formikProps.values.companyId) {
      tags.push(
        createTag(formikProps.values.brand, formikProps.values.companyId)
      );
    }
    return tags;
  }, [
    formikProps.values.companyId,
    formikProps.values.title,
    formikProps.values.brand,
  ]);

  const hasEntryMediaFields = formikProps.values.campaignFields.some((field) =>
    isAProgramMediaField(field.programField)
  );

  return (
    <MediaCheckContext.Provider value={{ resetMediaCheck, setResetMediaCheck }}>
      <ToggleMobileMediaLibContext.Provider
        value={{ mediaSelect, setMediaSelect }}
      >
        <BrowserHeader
          title={`Edit Media: [${props.id}] ${props.title ? props.title : ""}`}
        />
        <FormikProvider value={formikProps}>
          <ChangeWarning
            show={showDeleteExecution}
            title="Delete Execution"
            message="Do you want to delete this execution? This cannot be undone."
            yesMessage="Yes, Delete"
            onHideModal={() => setShowDeleteExecution(false)}
            onConfirm={() => handleDeleteExecution(deleteExecutionIndex!)}
          />

          <AdminEditPageContainer
            entryDetails={{
              eid: formikProps.values.id,
              coverImgSrc: formikProps.values.coverImage.mediaItem
                ? getMediaSrc(formikProps.values.coverImage.mediaItem)
                : ImagePlaceholder,
              title: formikProps.values.title,
              brand: formikProps.values.brand,
              author: formikProps.values.company?.name || "",
              awardLevel: formikProps.values.awardLevel,
              ancestry: hierarchy,
              reviewStatusId: formikProps.values.reviewStatusId,
            }}
          >
            <div className="admin-form">
              <StickyCard
                className="!relative !top-0 !max-h-full !min-h-fit"
                title="Cover Image"
              >
                <SectionBody className="flex flex-col gap-[1rem] !overflow-hidden">
                  <DraggableMediaList
                    name="coverImage"
                    list={
                      (formikProps.values.coverImage?.mediaItem?.path &&
                        ([
                          formikProps.values.coverImage.mediaItem,
                        ] as DragMediaItem[])) ||
                      []
                    }
                    mediaType={MediaType.Image}
                    tags={entryTags}
                    min={1}
                    max={1}
                    companyId={companyId}
                    onAdd={(addedMedia) => updatecoverImage(addedMedia[0])}
                    onRemove={(mediaId) => updatecoverImage()}
                    onUploadSuccess={(uploaded) => {
                      if (uploaded.length === 1) {
                        updatecoverImage(uploaded[0], true);
                      }
                    }}
                    showLocalFileUpload
                  />
                </SectionBody>
              </StickyCard>

              <StickyCard
                className="!relative !top-0 !max-h-full"
                title="Media/Execution"
              >
                <SectionBody className="flex flex-col !p-0">
                  {hasEntryMediaFields && (
                    <EntryMediaContainer>
                      <h1 className="playfair-500">
                        {assetsConfig.labels.entry.singular} Level Media
                      </h1>
                      <RenderAdminEntryFields
                        name="campaignFields"
                        fields={sortEntryFields(
                          formikProps.values.campaignFields
                        )}
                        onDiscard={() => formikProps.resetForm()}
                        hideNonMediaFields
                        showEditMedia
                      />
                    </EntryMediaContainer>
                  )}

                  {formikProps.values.executions &&
                    formikProps.values.executions.map((execution, i) => {
                      return (
                        <ExecutionContainer
                          key={String(execution.id)}
                          id={String(execution.id)}
                        >
                          {formikProps.values.isCampaign && (
                            <CampaignFieldDisplay
                              value={execution.title}
                              onSave={() => {
                                const updatePropertyRequest: UpdatePropertyRequest =
                                  {
                                    id: execution.id,
                                    propertyName: "title",
                                    propertyValue: execution.title,
                                  };

                                return updateExecutionProperty(
                                  updatePropertyRequest
                                );
                              }}
                              onDiscard={() => formikProps.resetForm()}
                              name={`executions.${i}.title`}
                              display={
                                <h1 className="playfair-500">
                                  {execution.title
                                    ? execution.title
                                    : "No Execution Title"}
                                </h1>
                              }
                              form={
                                <TextField
                                  name={`executions.${i}.title`}
                                  value={execution.title}
                                  hiddenlabel
                                  readOnly={false}
                                />
                              }
                            />
                          )}
                          <div className="flex flex-col gap-[.5rem]">
                            <h3 className="mr-[1rem]">
                              {execution.program.childLevelDisplayLabel + "*"}
                            </h3>

                            <p>{execution.program.name}</p>
                          </div>

                          <RenderAdminEntryFields
                            name={`executions.${i}.executionFields`}
                            fields={execution.executionFields}
                            onDiscard={() => formikProps.resetForm()}
                            showEditMedia
                          />
                        </ExecutionContainer>
                      );
                    })}

                  {/* <ExecutionContainer>
                  {formikProps.values.executions.length < 10 && (
                    <Button
                      className="w-[250px] ml-auto mt-[1rem]"
                      iconLeft="plus"
                      iconColor={theme.colorCopyLightLight}
                      onClick={() => {
                        // TODO: Create new Execution logic
                        if (
                          !selectedExecutionProgramId ||
                          (props.program.isMixedCampaignEligible &&
                            props.program.children &&
                            props.program.children?.length > 1)
                        ) {
                          setShowModal({
                            ...showModal,
                            addExecution: true,
                          });
                        } else {
                          addExecution(selectedExecutionProgramId!);
                        }
                      }}
                    >
                      Add Execution
                    </Button>
                  )}
                  <div className="col-w-100 mb-[1rem]">
                    <h3 className="subtitle font-semibold">Display Order</h3>
                    <p>
                      In this section, you may change the order your executions
                      display in your campaign entry. This will impact how the
                      entry displays for jurors and in the Winners Gallery if
                      your entry is awarded.
                    </p>
                  </div>
                  <div>
                    {executionForms &&
                      executionForms.length > 0 &&
                      executionForms.map((card, i) => renderCard(card, i))}

                    <Button
                      className="w-[250px] button-light ml-auto mt-[1rem]"
                      icon="check"
                      iconColor={theme.colorActivation}
                      onClick={() => setDraggedCard(true)}
                    >
                      Save Display Order
                    </Button>
                  </div>
                </ExecutionContainer> */}
                </SectionBody>
              </StickyCard>
            </div>

            <NewMediaLibrarySticky
              className="side-sticky"
              companyId={companyId}
              mediaArr={media}
              refreshMedia={(successUpload) => {
                getCompanyMedia();

                if (successUpload) {
                  addNewAlert({
                    type: "success",
                    message: "Successfully Uploaded Media File",
                  });
                }
              }}
              allowMultiselect
            />
          </AdminEditPageContainer>
        </FormikProvider>
      </ToggleMobileMediaLibContext.Provider>
    </MediaCheckContext.Provider>
  );
};

export default AdminMediaEdit;
