export function allProgressSettled(
    proms: Promise<any>[],
    progress_cb: (percent: number) => void
  ) {
    let d = 0;
    progress_cb(0);
    for (const p of proms) {
      p.finally(() => {
        d++;
        progress_cb(d);
      })
      .catch(error=> {console.log(error)});
    }
    return Promise.allSettled(proms);
  }