import axios from "axios";
import { FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import FileUpload from "../../../components/FormFields/FileUpload";
import TextField from "../../../components/FormFields/TextField";
import { urlPrograms } from "../../../endpoints";
import { MediaType } from "../../MediaLibrary/mediaLibrary.model.d";
import { defaultBoxShadow } from "../../../assetsConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const PauseSlide = () => {
	const [pauseSlides, setPauseSlides] = useState<PauseSlideModel[]>([]);
	const { settings } = useSelector((state: RootState) => state.settings);
	const [defaultPauseSlide, setDefaultPauseSlide] = useState<PauseSlideModel>();
	const [errors, setErrors] = useState<string[]>([]);

	useEffect(() => {
		if (settings.pauseSlidePath) {
			setDefaultPauseSlide({
				title: "Default Pause Slide",
				id: 0,
				pauseSlide: settings.pauseSlidePath,
				pauseSlideFile: undefined,
			});
		}
	}, [settings.pauseSlidePath]);

	async function PostSlide(pauseSlideData: PauseSlideModel) {
		try {
			setErrors([]);
			// start formdata
			const formData = new FormData();
			formData.append("Id", pauseSlideData.id?.toString() || "");
			formData.append("Title", pauseSlideData.title || "");
			formData.append("PauseSlideFile", pauseSlideData.pauseSlideFile || "");

			const headers = {
				"Content-Type": "multipart/form-data",
			};

			if (pauseSlideData.id != null) {
				const response = await axios.put(
					`${urlPrograms}/setProgramSlide`,
					formData,
					{
						headers: headers,
					}
				);
				if (response.status == 204) {
					//setAward(awardData);
				}
			}
		} catch (error: any) {
			// console.log(error);
			setErrors(error.response.data);
		}
	}

	useEffect(() => {
		const fetchRootProgramSlides = async () => {
			try {
				const response = await axios.get<PauseSlideModel[]>(
					`${urlPrograms}/getRootProgramSlides`
				);
				setPauseSlides([
					...(defaultPauseSlide ? [defaultPauseSlide] : []),
					...response.data.map((item) => ({
						title: item.title,
						id: item.id,
						pauseSlide: item.pauseSlide,
					})),
				]);
			} catch (error) {
				console.error("Failed to fetch root program slides", error);
			}
		};

		if (defaultPauseSlide) {
			fetchRootProgramSlides();
		}
	}, [defaultPauseSlide]);

	const formikProps = useFormik({
		initialValues: {
			title: "",
			id: 0,
			pauseSlide: "",
			pauseSlideFile: undefined,
		},
		enableReinitialize: true,
		onSubmit: async (value) => {
			// console.log(value);
		},
	});

	return (
		<>
			<div>
				<FormikProvider value={formikProps}>
					<div className="flex flex-wrap items-start">
						{pauseSlides.map((slide, index) => (
							<div
								key={index}
								className="flex w-full max-w-[550px] mx-4 justify-between items-center p-[15px] pb-[19px] bg-colorBackgroundLight shadow-lg rounded mb-4"
								style={{ boxShadow: defaultBoxShadow }}
							>
								<span className="font-medium text-gray-800 w-36 pr-[20px]">
									{slide.title}
								</span>
								<FileUpload
									className="w-[450px]"
									id="FileUpload-PauseSlide"
									imgOnly
									onChange={(files) => {
										const updatedValues = {
											...formikProps.values,
											pauseSlideFile: files[0].file,
											id: slide.id,
											title: slide.title,
										};
										PostSlide(updatedValues);
									}}
									{...(slide.pauseSlide && {
										value: {
											fileType: "image",
											fileName: slide.title,
											src:
												process.env.REACT_APP_S3_HOST + slide.pauseSlide || "",
											initial: true,
										},
									})}
									allowedMediaType={MediaType.Image}
								/>
							</div>
						))}
					</div>
				</FormikProvider>
			</div>
		</>
	);
};

export interface PauseSlideModel {
	title: string;
	id: number;
	pauseSlide?: string;
	pauseSlideFile?: File;
}

export default PauseSlide;
