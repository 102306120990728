import { ADD_ALERT, REMOVE_ALERT, AlertActionTypes, Alert } from "./types";

interface AlertState {
	alerts: Alert[];
}

const initialState: AlertState = {
	alerts: [],
};

const alertReducer = (
	state = initialState,
	action: AlertActionTypes
): AlertState => {
	switch (action.type) {
		case ADD_ALERT:
			return {
				...state,
				alerts: [action.payload, ...state.alerts],
			};
		case REMOVE_ALERT:
			return {
				...state,
				alerts: state.alerts.filter((alert) => alert.id !== action.payload),
			};
		default:
			return state;
	}
};

export default alertReducer;
