import { DropdownOptions } from "../components/FormFields/DropdownField";

export const StateData: string[] = [
	"Alabama",
	"Alaska",
	"American Samoa",
	"Arizona",
	"Arkansas",
	"California",
	"Colorado",
	"Connecticut",
	"Delaware",
	"District of Columbia",
	"Florida",
	"Georgia",
	"Guam",
	"Hawaii",
	"Idaho",
	"Illinois",
	"Indiana",
	"Iowa",
	"Kansas",
	"Kentucky",
	"Louisiana",
	"Maine",
	"Maryland",
	"Massachusetts",
	"Michigan",
	"Minnesota",
	"Minor Outlying Islands",
	"Mississippi",
	"Missouri",
	"Montana",
	"Nebraska",
	"Nevada",
	"New Hampshire",
	"New Jersey",
	"New Mexico",
	"New York",
	"North Carolina",
	"North Dakota",
	"Northern Mariana Islands",
	"Ohio",
	"Oklahoma",
	"Oregon",
	"Pennsylvania",
	"Puerto Rico",
	"Rhode Island",
	"South Carolina",
	"South Dakota",
	"Tennessee",
	"Texas",
	"U.S. Virgin Islands",
	"Utah",
	"Vermont",
	"Virginia",
	"Washington",
	"West Virginia",
	"Wisconsin",
	"Wyoming",
];

export const USStateOptions: DropdownOptions[] = [
	{ value: "Alabama" },
	{ value: "Alaska" },
	{ value: "American Samoa" },
	{ value: "Arizona" },
	{ value: "Arkansas" },
	{ value: "California" },
	{ value: "Colorado" },
	{ value: "Connecticut" },
	{ value: "Delaware" },
	{ value: "District of Columbia" },
	{ value: "Florida" },
	{ value: "Georgia" },
	{ value: "Guam" },
	{ value: "Hawaii" },
	{ value: "Idaho" },
	{ value: "Illinois" },
	{ value: "Indiana" },
	{ value: "Iowa" },
	{ value: "Kansas" },
	{ value: "Kentucky" },
	{ value: "Louisiana" },
	{ value: "Maine" },
	{ value: "Maryland" },
	{ value: "Massachusetts" },
	{ value: "Michigan" },
	{ value: "Minnesota" },
	{ value: "Minor Outlying Islands" },
	{ value: "Mississippi" },
	{ value: "Missouri" },
	{ value: "Montana" },
	{ value: "Nebraska" },
	{ value: "Nevada" },
	{ value: "New Hampshire" },
	{ value: "New Jersey" },
	{ value: "New Mexico" },
	{ value: "New York" },
	{ value: "North Carolina" },
	{ value: "North Dakota" },
	{ value: "Northern Mariana Islands" },
	{ value: "Ohio" },
	{ value: "Oklahoma" },
	{ value: "Oregon" },
	{ value: "Pennsylvania" },
	{ value: "Puerto Rico" },
	{ value: "Rhode Island" },
	{ value: "South Carolina" },
	{ value: "South Dakota" },
	{ value: "Tennessee" },
	{ value: "Texas" },
	{ value: "U.S. Virgin Islands" },
	{ value: "Utah" },
	{ value: "Vermont" },
	{ value: "Virginia" },
	{ value: "Washington" },
	{ value: "West Virginia" },
	{ value: "Wisconsin" },
	{ value: "Wyoming" },
];

export const CanadaStateOptions: DropdownOptions[] = [
	{ value: "Alberta" },
	{ value: "British Columbia" },
	{ value: "Manitoba" },
	{ value: "New Brunswick" },
	{ value: "Newfoundland and Labrador" },
	{ value: "Northwest Territories" },
	{ value: "Nova Scotia" },
	{ value: "Nunavut" },
	{ value: "Ontario" },
	{ value: "Prince Edward Island" },
	{ value: "Quebec" },
	{ value: "Saskatchewan" },
	{ value: "Yukon" },
];
