import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Juror,
  EntrySetAdminCard,
  GalleryQuickVote,
  JurorViewCard,
  LiveToolsSettings,
  JurorJuryCard,
} from "../views/Admin/Judging/JudgingInterfaces";
import { getEntryDetailsForJuror } from "../views/Entries/manageEntry";
import {
  PreviousNextEntry,
  EntryDetailsModel,
} from "../views/Judging/EntryDetail/EntryDetail";
import { useCurrentUser } from "./useCurrentUser";
import { useJuryHub, UseJuryHubReturnType } from "./useJuryHub";
import { HubConnection } from "@microsoft/signalr";
import {
  EntrySortOptions,
  ViewType,
} from "../views/Admin/Judging/LiveTools/EntryView";

export interface UseJurorHubReturnType extends UseJuryHubReturnType {
  next: PreviousNextEntry | undefined;
  previous: PreviousNextEntry | undefined;
  jurorJuryCard: JurorJuryCard | undefined;
  entryDetails: EntryDetailsModel | null;
  entrySetCard: EntrySetAdminCard; // Juror
  juror: Juror | undefined;
  invalidJuror: boolean;
  vote: GalleryQuickVote | undefined;
  isRoundEntryLocked: boolean;
  isRoundEntryHidden: boolean;
  highlightedEntryUrl: string;
  settings: LiveToolsSettings;
}

export function useJurorHub(): UseJurorHubReturnType {
  const history = useHistory();
  const juryHub = useJuryHub();

  const { user } = useCurrentUser();
  const [next, setNext] = useState<PreviousNextEntry>();
  const [previous, setPrevious] = useState<PreviousNextEntry>();
  const [entryDetails, setEntryDetails] = useState<EntryDetailsModel | null>(
    null
  );

  const [juror, setJuror] = useState<Juror>();
  const [invalidJuror, setInvalidJuror] = useState(false);
  const [highlightedEntryUrl, setHighlightedEntryUrl] = useState("");
  const [jurorJuryCard, setJurorJuryCard] = useState<JurorJuryCard>();
  const [entrySetCard, setEntrySetCard] = useState<EntrySetAdminCard>(
    juryHub.blankEntrySetCard
  );

  const [vote, setVote] = useState<GalleryQuickVote>();
  const [isRoundEntryLocked, setIsRoundEntryLocked] = useState(true);
  const [isRoundEntryHidden, setIsRoundEntryHidden] = useState(false);

  const defaultLiveToolsSettings: LiveToolsSettings = {
    id: 0,
    juryId: 0,
    juryName: "",
    entryDisplayTitlePreview: "",
    isActive: false,
    entriesLocked: true,
    displayConfiguration: ViewType.Grid,
    sort: EntrySortOptions.PreviousRoundScoreDescending,
    includeBatchDivisions: false,
    showProgressIndicators: false,
    showPhysicalComponents: false,
    selectedBatches: [],
    jurorControl: false,
    revealWinner: false,
    selectedRoundStructureId: 0,
    highlightedRoundEntryId: 0,
    awardId: 0,
  };

  const [settings, setSettings] = useState<LiveToolsSettings>(
    defaultLiveToolsSettings
  );

  useEffect(() => {
    if (
      juryHub.connection &&
      juryHub.juryId &&
      juryHub.entrySetId &&
      juror &&
      juror.id
    ) {
      juryHub.connection.on(`jurorViewCardUpdated-${juror!.id}`, () => {
        getJurorViewCard(Number(juryHub.juryId), juryHub.connection);
      });
    }
  }, [juryHub.connection, juryHub.juryId, juror, juror?.id]);

  // Fetch and update the JurorViewCard and JurorViewEntrySetCard data
  const getJurorViewCard = (
    juryId: number,
    connection: HubConnection | null
  ) => {
    if (connection) {
      connection?.invoke("GetJurorViewCard", juryId).then((card) => {
        setJurorJuryCard(card);
      });
    }
  };

  // useEffect for handling juryId changes
  useEffect(() => {
    if (juryHub.connection && juryHub.juryId) {
      const getJuror = (juryId: number) => {
        if (juryHub.connection) {
          juryHub.connection
            .invoke("GetJurorForCurrentUser", juryId)
            .then((data) => setJuror(data));
        }
      };

      juryHub.connection.on(`liveJuryUpdated-${juryHub.juryId}`, (data) => {
        setSettings(data);
        juryHub.debug && console.log(`liveJuryUpdated-${juryHub.juryId}`, data);
      });

      juryHub.connection.on(`jurorViewCardUpdated-${juryHub.juryId}`, () => {
        getJurorViewCard(Number(juryHub.juryId), juryHub.connection);
      });

      juryHub.debug && console.log(`JoinLiveJury`, juryHub.connection);
      juryHub.connection.invoke("JoinLiveJury", Number(juryHub.juryId));

      getJuror(Number(juryHub.juryId));
      getJurorViewCard(Number(juryHub.juryId), juryHub.connection);
    }
  }, [juryHub.connection, juryHub.juryId]);

  useEffect(() => {
    const getHighlightedRoundEntry = (
      juryId: number,
      roundEntryId: number,
      currentPageVote?: string
    ) => {
      if (juryHub.connection) {
        juryHub.connection
          ?.invoke("GetHighlightedEntryForJuror", juryId, roundEntryId)
          .then((entry) => {
            if (entry) {
              //   debugger;
              if (Number(currentPageVote) === entry.voteId) {
                setHighlightedEntryUrl("");
              } else {
                const url = `/judge/${entry.juryId}/${entry.entrySetId}/${entry.batchId}/${entry.entryId}/${entry.voteId}`;

                juryHub.debug && console.log("Highlighted entry found", url);
                setHighlightedEntryUrl(url);
              }
            } else {
              setHighlightedEntryUrl("");
            }
          });
      }
    };
    if (
      settings.highlightedRoundEntryId &&
      settings.isActive &&
      settings.jurorControl
    ) {
      getHighlightedRoundEntry(
        Number(juryHub.juryId),
        settings.highlightedRoundEntryId,
        juryHub.voteId
      );
    } else {
      setHighlightedEntryUrl("");
    }
  }, [
    juryHub.voteId,
    settings.highlightedRoundEntryId,
    settings.isActive,
    settings.jurorControl,
    juryHub.juryId,
  ]);

  // useEffect for handling entrySetId changes
  useEffect(() => {
    if (juryHub.connection && juryHub.juryId && juryHub.entrySetId) {
      // Fetch and update the entrySetCard data

      juryHub.connection.on(
        `entrySetCardUpdatedForJuror-${juryHub.entrySetId}`,
        (card) => {
          setEntrySetCard(card);
          juryHub.debug &&
            console.log(
              `entrySetCardUpdatedForJuror-${juryHub.entrySetId}`,
              card
            );
        }
      );

      juryHub.connection.invoke(
        "joinEntrySetGroup",
        Number(juryHub.entrySetId),
        Number(juryHub.juryId)
      );
    }
    // return () => {
    //   if (connection && juryId && entrySetId && connection.state === HubConnectionState.Connected) {
    //     juryHub.debug && console.log("Closing entrySetId", connection, entrySetId);
    //     if (!invalidJuror) {
    //       connection.off(`entrySetCardUpdatedForJuror-${entrySetId}`);
    //     }
    //     connection.invoke("leaveEntrySetGroup", Number(entrySetId));
    //   }
    // };
  }, [juryHub.connection, juryHub.juryId, juryHub.entrySetId]);

  // useEffect for handling juryId, entrySetId, batchId, entryId, and voteId changes
  useEffect(() => {
    if (
      juryHub.connection &&
      juryHub.juryId &&
      juryHub.entrySetId &&
      juryHub.batchId &&
      juryHub.entryId &&
      juryHub.voteId
    ) {
      // Fetch and update the vote, next, and previous data
      juryHub.debug &&
        console.log(`Getting Vote/Entry info`, juryHub.connection);

      juryHub.connection.on(`roundEntryLocked-${juryHub.entryId}`, (x) => {
        setIsRoundEntryLocked(x);
        juryHub.debug && console.log(`roundEntryLocked-${juryHub.entryId}`, x);
      });

      juryHub.connection.on(`roundEntryHidden-${juryHub.entryId}`, (x) => {
        setIsRoundEntryHidden(x);
        juryHub.debug && console.log(`roundEntryHidden-${juryHub.entryId}`, x);
      });

      juryHub.connection.on(`nextEntry-${juryHub.entryId}`, (e) => {
        setNext(e);
        juryHub.debug && console.log(`nextEntry-${juryHub.entryId}`, e);
      });

      juryHub.connection.on(`previousEntry-${juryHub.entryId}`, (e) => {
        setPrevious(e);
        juryHub.debug && console.log(`previousEntry-${juryHub.entryId}`, e);
      });

      juryHub.connection.on(`voteUpdated-${juryHub.voteId}`, (v) => {
        setVote(v);
        juryHub.debug && console.log(`voteUpdated-${juryHub.voteId}`, v);
      });

      juryHub.connection.on(`invalidJuror-${juryHub.entryId}`, () => {
        setInvalidJuror(true);
        juryHub.debug && console.log("Invalid Juror");
      });

      juryHub.connection.on(`roundEntryUpdated-${juryHub.entryId}`, () => {
        getEntryDetails(Number(juryHub.entryId), Number(juryHub.juryId));
        juryHub.debug && console.log("Round Entry updated");
      });

      const getVote = (
        juryId: number,
        entrySetId: number,
        batchId: number,
        entryId: number
      ) => {
        if (juryHub.connection) {
          juryHub.connection
            .invoke("GetVote", juryId, entrySetId, batchId, entryId)
            .then((v) => setVote(v));
        }
      };

      getVote(
        Number(juryHub.juryId),
        Number(juryHub.entrySetId),
        Number(juryHub.batchId),
        Number(juryHub.entryId)
      );

      const getEntryDetails = (entryId: number, juryId: number) => {
        getEntryDetailsForJuror(entryId, juryId)
          .then((response) => {
            if (response.status === 200) {
              juryHub.debug && console.log("details", response);
              setEntryDetails(response.data);
            } else {
              setEntryDetails(null);
            }
          })
          .catch((error) => {
            setEntryDetails(null);
            // Alert here?
          });
      };

      getEntryDetails(Number(juryHub.entryId), Number(juryHub.juryId));
      //setConnection(connection);
    }

    return () => {
      juryHub.debug &&
        console.log(
          `Cleaning Vote/Entry subscriptions`,
          juryHub.voteId,
          vote,
          juryHub.connection
        );
      juryHub.connection?.off(`roundEntryLocked-${juryHub.entryId}`);
      juryHub.connection?.off(`roundEntryHidden-${juryHub.entryId}`);
      juryHub.connection?.off(`nextEntry-${juryHub.entryId}`);
      juryHub.connection?.off(`previousEntry-${juryHub.entryId}`);
      juryHub.connection?.off(`voteUpdated-${juryHub.voteId}`);
      juryHub.connection?.off(`invalidJuror-${juryHub.entryId}`);

      setNext(undefined);
      setPrevious(undefined);
      setVote(undefined);
    };
  }, [
    juryHub.connection,
    juryHub.voteId,
    juryHub.juryId,
    juryHub.entrySetId,
    juryHub.batchId,
    juryHub.entryId,
  ]);

  useEffect(() => {
    if (juryHub.connection) {
      juryHub.connection.on(`invalidJuror`, () => {
        setInvalidJuror(true);
        juryHub.debug && console.log("Invalid Juror");
      });
    }

    // Clean up the connection on unmount
    // return () => {
    //   if (connection) {
    //     connection.off(`invalidJuror`);
    //   }
    // };
  }, [juryHub.connection]);

  return {
    ...juryHub,
    jurorJuryCard,
    entryDetails,
    entrySetCard,
    isRoundEntryLocked,
    isRoundEntryHidden,
    juror,
    invalidJuror,
    next,
    previous,
    vote,
    highlightedEntryUrl,
    settings,
  };
}
