import React, { useEffect, useRef } from "react";

const useTextWrap = () => {
	const ref = useRef<any>(null);

	useEffect(() => {
		if (ref.current) {
			const offset = 10;
			const textWidth = ref.current.offsetWidth;
			const parentWidth = ref.current.parentElement?.offsetWidth;

			// if text overflows container, wrap it even if it's in the middle of a sentence
			if (textWidth + offset > parentWidth) {
				ref.current.style.overflowWrap = "anywhere";
			}
		}
	}, []);

	const wrapText = (e: React.ReactNode) => {
		return <span ref={ref}>{e}</span>;
	};

	return { wrapText };
};

export default useTextWrap;
