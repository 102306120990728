import { isSubStringInString } from "../../utils/isSubstringInString";
import { MediaType, MediaItem, Tag } from "./mediaLibrary.model.d";

export enum MediaSortType {
  FileNameAsc,
  FileNameDesc,
  FileTypeAsc,
  FileTypeDesc,
  UploadDateAsc,
  UploadDateDesc,
}

// used by media and credits
export const filterByTags = (
  arr: any[],
  tagFilter: string[],
  allowPartialMatch?: boolean // allow a partial string match for tags
) => {
  return arr.filter((item) => {
    if (item.tags && item.tags.length > 0) {
      // check if tags fit search criteria
      return tagFilter.every((searchTag) => {
        return item.tags?.some((itemTag: Tag) =>
          allowPartialMatch
            ? itemTag.tag.toUpperCase().includes(searchTag.toUpperCase())
            : itemTag.tag.toUpperCase() === searchTag.toUpperCase()
        );
      });
    } else return false;
  });
};

export const filterMediaByType = (
  mediaLibrary: MediaItem[],
  type: MediaType
) => {
  return mediaLibrary.filter((mediaObj) => mediaObj.type === type);
};

export const filterMediaByActive = (mediaLibrary: MediaItem[]) => {
  return mediaLibrary.filter((mediaObj) => mediaObj.isActive);
};

export const filterMediaByUploadDate = (
  mediaLibrary: MediaItem[],
  date: Date
) => {
  return mediaLibrary.filter((mediaObj) => {
    const getOnlyDate = mediaObj.createdDate
      ? mediaObj.createdDate.match(/.+?(?=T)/g)
      : null;

    const mediaDate =
      getOnlyDate && getOnlyDate.length === 1 ? getOnlyDate[0] : null;

    // set time to 0 to compare only the dates
    return mediaDate
      ? new Date(`${mediaDate} 00:00:00`).getTime() === date.getTime()
      : false;
  });
};

export const filterMediaByFileName = (
  mediaLibrary: MediaItem[],
  fileName: string
) => {
  return mediaLibrary.filter((mediaObj) =>
    isSubStringInString(mediaObj.fileName, fileName)
  );
};

export const filterMediaByFileAndTags = (
  mediaLibrary: MediaItem[],
  fileName: string,
  tags: string[]
) => {
  return mediaLibrary.filter((mediaObj) => {
    const isFileMatch = isSubStringInString(mediaObj.fileName, fileName);
    const isTagMatch = tags.every((searchTag) => {
      return mediaObj.tags?.some((mediaTag: Tag) =>
        mediaTag.tag.toUpperCase().includes(searchTag.toUpperCase())
      );
    });

    return isFileMatch || isTagMatch;
  });
};

export const mediaSortOptions = [
  {
    value: MediaSortType.FileNameAsc,
    label: "File Name (A-Z)",
  },
  {
    value: MediaSortType.FileNameDesc,
    label: "File Name (Z-A)",
  },
  {
    value: MediaSortType.UploadDateAsc,
    label: "Upload Date (Oldest - Newest)",
  },
  {
    value: MediaSortType.UploadDateDesc,
    label: "Upload Date (Newest - Oldest)",
  },
];

export const sortMedia = (
  keyName: keyof MediaItem,
  order: "asc" | "desc",
  media: MediaItem[]
) => {
  return media.sort((a, b) => {
    // sort by asc or desc
    if (order === "asc") {
      return String(a[keyName]!).localeCompare(String(b[keyName]!), undefined, {
        numeric: true,
      });
    } else {
      return String(b[keyName]!).localeCompare(String(a[keyName]!), undefined, {
        numeric: true,
      });
    }
  });
};

export const handleMediaSort = (
  sortType: MediaSortType,
  media: MediaItem[]
) => {
  switch (MediaSortType[sortType] as any) {
    case MediaSortType.FileNameAsc:
      return sortMedia("fileName", "asc", media);
    case MediaSortType.FileNameDesc:
      return sortMedia("fileName", "desc", media);
    case MediaSortType.UploadDateAsc:
      return sortMedia("createdDate", "asc", media);
    case MediaSortType.UploadDateDesc:
      return sortMedia("createdDate", "desc", media);
    default:
      return [];
  }
};
