import { useCallback, useState } from "react";
import useWindowDimensions from "./useWindowDimensions";

// calculate the maximum allowed height of the media/credit list
const useListHeight = () => {
	const { height } = useWindowDimensions();
	const [listHeight, setListHeight] = useState(300);

	const usedHeight = useCallback(
		(node) => {
			if (!node) return;

			const resizeObserver = new ResizeObserver(() => {
				const container = node.parentNode.getBoundingClientRect();
				const pt = getComputedStyle(node.parentNode).paddingTop.replaceAll(
					"px",
					""
				);
				const pb = getComputedStyle(node.parentNode).paddingTop.replaceAll(
					"px",
					""
				);
				const containerHeight = container.height - Number(pt) - Number(pb);

				// the area containing the "multiselect" text and sort dropdown
				const area = node.getBoundingClientRect().height;

				if (!isNaN(containerHeight) || !isNaN(area)) {
					setListHeight(containerHeight - area);
				}
			});

			resizeObserver.observe(node);
		},
		[height]
	);

	return { usedHeight, listHeight };
};

export default useListHeight;
