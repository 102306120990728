import { FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useState, useEffect, useContext } from "react";
import {
	CardContainer,
	CardHeader,
	CardBody,
} from "../../../components/DashboardCard/DashboardCard";
import { getProgramDisplayById } from "./manageProgramDisplays";
import { useHistory, useParams } from "react-router-dom";
import InfoPopup from "../../../components/InfoPopup/InfoPopup";
import TextField from "../../../components/FormFields/TextField";
import RichTextField from "../../../components/FormFields/RichTextField";
import ToggleSwitchField from "../../../components/FormFields/ToggleSwitchField";
import Button from "../../../components/Button/Button";
import FileUpload, {
	FileUploadValue,
} from "../../../components/FormFields/FileUpload";
import { updateProgramDisplay } from "./manageProgramDisplays";

import { ProgramDisplay, ProgramModel } from "./ProgramInterfaces";
import { DisplayDate } from "./ProgramInterfaces";
import { createProgramDisplay } from "./manageProgramDisplays";
import DropdownField from "../../../components/FormFields/DropdownField";
import { getRootProgramOptions, getRootPrograms } from "./manageProgram";
import { programNames } from "../../Entries/EntriesTestData";
import { DropdownOptions } from "../../../components/FormFields/DropdownField";
import { getProgramById } from "./manageProgram";
import { deleteProgramDisplay } from "./manageProgramDisplays";
import Modal, { ModalContainer } from "../../../components/Modal/Modal";
import Icon from "../../../components/Icon/Icon";
import { useTheme } from "styled-components";
import { Label } from "../../../components/FormFields/FieldTemplate";
import { MediaType } from "../../MediaLibrary/mediaLibrary.model.d";
import TZDatePickerField from "../../../components/FormFields/TZDatePickerField";
import axios from "axios";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import { useAlert } from "../../../components/Alert/Alerts";

const ProgramDisplayForm = ({
	metaDescription,
}: {
	metaDescription?: string;
}) => {
	const theme = useTheme();
	const history = useHistory();
	const { id } = useParams<{ id: string }>();
	const [rootProgramIDs, setRootProgramIDs] = useState<DropdownOptions[]>([]);
	const { addNewAlert } = useAlert();
	const [showModal, setShowModal] = useState(false);
	const [initialValues, setInitialValues] = useState<ProgramDisplay>({
		isActive: false,
		sortOrder: 0,
		displayDates: [],
		showCountdown: false,
		showDisplayDates: false,
		specialNote: "",
	});
	const fieldRequired = "This field is required";

	const formikProps = useFormik<any>({
		initialValues: initialValues,
		onSubmit: async (value) => {
			if (value.id) {
				// console.log("updating program");
				value.program = undefined;
				updateProgramDisplay(value)
					.then((res) => {
						// console.log("res", res);
						if (res?.status === 204) {
							addNewAlert({
								type: "success",
								message: "Program Display Updated",
							});
							window.location.reload();
						}
					})
					.catch((err) =>
						addNewAlert({
							type: "error",
							message: err,
						})
					);
			} else {
				// console.log("creating program");
				createProgramDisplay(value)
					.then((res) => {
						// console.log("res", res);
						if (res?.status === 201) {
							addNewAlert({
								type: "success",
								message: "Program Display Created",
							});
						}

						history.push("/program/program-display/", {
							programDisplayId: res.data.id,
						});
					})
					.catch((err) =>
						addNewAlert({
							type: "error",
							message: err,
						})
					);
			}
		},
		validationSchema: Yup.object({
			sortOrder: Yup.number().required(fieldRequired),
			programId: Yup.string().required(fieldRequired),
		}),
		enableReinitialize: true,
		validateOnBlur: false,
		validateOnChange: false,
	});

	const handleDeleteProgramDisplay = () => {
		if (initialValues.id) {
			deleteProgramDisplay(initialValues.id)
				.then((res) => {
					history.push("/program/program-display");
				})
				.catch((error) =>
					addNewAlert({ type: "error", message: error.response.data })
				);
		} else {
			addNewAlert({
				type: "error",
				message: "System Error. Unable to delete program display.",
			});
		}
	};

	useEffect(() => {
		// get root program dropdown options
		getRootProgramOptions().then((res) => {
			// console.log("root programs", res);
			setRootProgramIDs(res.data);
		});
	}, []);

	useEffect(() => {
		if (id) {
			getProgramDisplayById(parseInt(id)).then((resp) => {
				if (resp.status === 200) {
					setInitialValues(resp.data);
				}
			});
		}
	}, [id]);

	const [entryKitFile, setEntryKitFile] = useState<
		undefined | FileUploadValue
	>();
	const [bestPracticesFile, setBestPracticesFile] = useState<
		undefined | FileUploadValue
	>();

	useEffect(() => {
		if (formikProps.values.entryKit) {
			setEntryKitFile({
				fileName: "entryKit.pdf",
				fileType: "application/pdf",
				src: formikProps.values.entryKit,
				initial: true,
			});
		}
	}, [formikProps.values.entryKit]);

	useEffect(() => {
		if (formikProps.values.bestPractices) {
			setBestPracticesFile({
				fileName: "bestPractices.pdf",
				fileType: "application/pdf",
				src: formikProps.values.bestPractices,
				initial: true,
			});
		}
	}, [formikProps.values.bestPractices]);

	return (
		<FormikProvider value={formikProps}>
			<BrowserHeader title={metaDescription || `Edit Program Card`} />
			<Modal show={showModal}>
				<ModalContainer>
					<Icon
						className="m-[1.5rem] ml-auto cursor-pointer"
						icon="close"
						color={theme.colorCopyLight}
						onClick={() => setShowModal(false)}
					/>

					<div className="inner-container items-center">
						<h1>Delete Program Display</h1>
						<p>
							Are you sure you want to delete{" "}
							<em>{formikProps.values?.program?.name}</em>? This action{" "}
							<b>cannot</b> be undone.
						</p>
						<div className="flex gap-[1rem] mt-[1rem]">
							<Button className="button-light">Cancel</Button>
							<Button
								className="button-danger"
								onClick={() => handleDeleteProgramDisplay()}
							>
								Delete
							</Button>
						</div>
					</div>
				</ModalContainer>
			</Modal>
			<CardContainer>
				<CardHeader>
					<h2 className="card-title">
						{formikProps.values.program
							? `Edit ${formikProps.values.program.name}`
							: "New Program Display"}
					</h2>
					{/* <div className="flex items-center gap-[.5rem]">
                <h2 className="text-colorCopyLightLight">Display Order</h2>
                <TextField
                  className="!w-[65px]"
                  type="number"
                  name="sortOrder"
                />
              </div> */}
				</CardHeader>

				<CardBody>
					<div className="flex flex-col gap-[1.8125rem]">
						<DropdownField
							name="programId"
							placeholder="Select Program"
							options={rootProgramIDs}
						/>
						<div className="flex gap-[.5rem] items-center">
							<InfoPopup>
								<p>Info popup message</p>
							</InfoPopup>
							<h3 className="font-semibold">Display Status</h3>
							<ToggleSwitchField
								name="isActive"
								id="isActive"
								checked={formikProps.values.isActive}
							/>
						</div>
						<TextField
							className="!w-[150px]"
							placeholder="Display Order"
							type="number"
							name="sortOrder"
							value={formikProps.values.sortOrder}
						/>
						<h3 className="font-semibold">Special Note</h3>
						<RichTextField
							name="specialNote"
							placeholder="Special Note"
							height="150px"
							value={formikProps.values.specialNote}
						/>
						<div className="flex gap-[.5rem] items-center">
							<InfoPopup>
								<p>Info popup message</p>
							</InfoPopup>
							<h3 className="font-semibold">Show Display Dates</h3>
							<ToggleSwitchField
								name="showDisplayDates"
								id="showDisplayDates"
								checked={formikProps.values.showDisplayDates || false}
							/>
						</div>
						<FieldArray name="displayDates">
							{({ insert, remove, push }) => (
								<>
									{formikProps.values.displayDates?.length > 0 &&
										(formikProps.values.displayDates as DisplayDate[]).map(
											(displayDate, index) => (
												<div className="flex gap-[1rem]" key={index}>
													<TZDatePickerField
														name={`displayDates.${index}.date`}
														placeholder="Display Date"
														value={displayDate.date}
														showTimeSelect
													/>
													<TextField
														placeholder="Label"
														name={`displayDates.${index}.label`}
														value={displayDate.label}
													/>
													<Icon
														className="ml-[1rem]"
														icon="close"
														color={theme.colorCopyLight}
														onClick={() => remove(index)}
													/>
												</div>
											)
										)}

									<Button
										type="button"
										className="w-[200px] button-light"
										icon="plus"
										iconColor={theme.colorActivation}
										onClick={() =>
											push({
												label: "",
												date: null,
												programDisplayId: formikProps.values.id,
											})
										}
									>
										Add Display Date
									</Button>
								</>
							)}
						</FieldArray>

						<div className="flex gap-[.5rem] items-center">
							<InfoPopup>
								<p>Info popup message</p>
							</InfoPopup>
							<h3 className="font-semibold">Display Countdown</h3>
							<ToggleSwitchField
								name="showCountdown"
								id="showCountdown"
								checked={formikProps.values.showCountdown}
							/>
						</div>

						<h3 className="font-semibold">Entry Kit</h3>
						<FileUpload
							className="!w-[750px]"
							id="FileUpload-ProgramDisplay-entryKit"
							allowedMediaType={MediaType.Document}
							remove={() => {
								formikProps.setFieldValue("entryKit", undefined);
								formikProps.setFieldValue("entryKitData", undefined);
								setEntryKitFile(undefined);
							}}
							value={entryKitFile}
							onChange={(files) => {
								if (!files || files.length === 0) {
									return;
								}
								formikProps.setFieldValue("entryKitData", files[0].file);
							}}
						/>
						<h3 className="font-semibold">Best Practices</h3>
						<FileUpload
							className="!w-[750px]"
							id="FileUpload-ProgramDisplay-bestPractices"
							allowedMediaType={MediaType.Document}
							remove={() => {
								formikProps.setFieldValue("bestPractices", undefined);
								formikProps.setFieldValue("bestPracticesData", undefined);
								setBestPracticesFile(undefined);
							}}
							value={bestPracticesFile}
							onChange={(files) => {
								if (!files || files.length === 0) {
									return;
								}
								formikProps.setFieldValue("bestPracticesData", files[0].file);
							}}
						/>
						<div className="flex gap-[1rem] ml-auto">
							{/* <Button className="button-light w-[150px]">Preview</Button> */}
							<Button
								className="button-danger w-[150px]"
								type="submit"
								onClick={() => setShowModal(true)}
							>
								Delete
							</Button>
							<Button
								className="w-[150px]"
								type="submit"
								onClick={() => formikProps.submitForm()}
							>
								Save
							</Button>
						</div>
					</div>
				</CardBody>
			</CardContainer>
		</FormikProvider>
	);
};

export default ProgramDisplayForm;
