import { useTheme } from "styled-components";
import Icon from "../../../components/Icon/Icon";
import Modal, { ModalCard } from "../../../components/Modal/Modal";
import { useContext } from "react";

import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import TextField from "../../../components/FormFields/TextField";
import Button from "../../../components/Button/Button";
import { JuryInformation } from "./EditUser";
import axios from "axios";
import { urlJurorAdmin } from "../../../endpoints";
import { useParams } from "react-router-dom";
import { useAlert } from "../../../components/Alert/Alerts";

async function updateJurorNote(userId: string, note: string) {
	const payload: UpdateJurorNotePayload = { note };

	return axios.put(`${urlJurorAdmin}/update-note/${userId}`, payload);
}

interface UpdateJurorNotePayload {
	note: string;
}

const SaveJurorNotesModal = (props: SaveJurorNotesModalProps) => {
	const theme = useTheme();
	const { userId } = useParams<{ userId: string }>();
	const { addNewAlert } = useAlert();
	const formikProps = useFormik({
		initialValues: props.juryInfo ? props.juryInfo : {},
		onSubmit: async (value) => {
			if (value.jurorNote !== undefined && value.juryId) {
				updateJurorNote(userId, value.jurorNote)
					.then((res) => {
						if (res.status === 200) {
							addNewAlert({
								type: "success",
								message: "saved successfully",
							});

							// hide modal after new juror notes are fetched
							props.onUpdateNotes().then((res) => {
								if (res.status === 200) {
									props.setShow(false);
								}
							});
						} else {
							addNewAlert({
								type: "error",
								message: "Failed to save juror notes",
							});
						}
					})
					.catch((err) =>
						addNewAlert({
							type: "error",
							message: err.message,
						})
					);
			}
		},
		validationSchema: Yup.object({}),
		enableReinitialize: true,
	});

	return (
		<FormikProvider value={formikProps}>
			<Modal show={props.show}>
				<ModalCard
					title="Juror Notes"
					headerIcons={
						<Icon
							icon="close"
							color={theme.colorPrimary}
							onClick={() => props.setShow(false)}
							width="35px"
							height="35px"
						/>
					}
					iconColor={theme.colorPrimary}
				>
					<div className="flex flex-col gap-[1rem] p-[2rem] h-full">
						<div className="flex justify-between">
							<b className="playfair-500">{formikProps.values.programName}</b>
							<span>{props.userName}</span>
						</div>

						<span>{formikProps.values.juryName}</span>
						<TextField
							name="jurorNote"
							value={formikProps.values.jurorNote}
							placeholder="Juror Notes"
							component="textarea"
						/>
						<div className="flex gap-[1rem] ml-auto ">
							<Button
								className="button-gold"
								icon="close"
								iconSize="18px"
								iconColor={theme.colorPrimary}
								onClick={formikProps.resetForm}
							>
								Discard Changes
							</Button>
							<Button icon="check" onClick={formikProps.submitForm}>
								Save
							</Button>
						</div>
					</div>
				</ModalCard>
			</Modal>
		</FormikProvider>
	);
};

export default SaveJurorNotesModal;

interface SaveJurorNotesModalProps {
	userName: string;
	juryInfo: JuryInformation | null;
	show: boolean;
	setShow(show: boolean): void;
	onUpdateNotes(): Promise<any>;
}
