import axios from "axios";
import { useState, useEffect } from "react";
import { GiConsoleController } from "react-icons/gi";
import { authenticationResponse } from "../components/Auth/auth.models";
import { getClaims } from "../components/Auth/handleJWT";
import { urlManageCompanies, urlSettings } from "../endpoints";
import { SettingsModel } from "../views/Admin/Settings/Settings";
import { CompanyModel } from "../views/Company/company.model";
import { getCompanyInfo } from "../views/Company/manageCompany";
import { useDispatch } from "react-redux";
import { fetchSettingsSuccess } from "../components/Settings/actions";
import { SettingsState } from "../components/Settings/settingsReducer";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export const useSettings = () => {
	const dispatch = useDispatch();
	const [settings, setSettings] = useState<SettingsModel>({});

	// fallback in case settings fails to retrieve
	const setFallback = () => {
		setSettings({
			isDemo: false,
		});
	};

	async function getSettingsInfo() {
		try {
			const response = await axios.get<SettingsModel>(`${urlSettings}`);
			if (response.status == 200) {
				return response.data;
			} else setFallback();
		} catch (error: any) {
			setFallback();
			// console.log(error);
		}
	}

	useEffect(() => {
		const getSettingsAsync = async () => {
			let results = await getSettingsInfo();
			if (results) {
				setSettings(results);
				//console.log(results);
			} else {
				setFallback();
			}
		};
		getSettingsAsync();
	}, []);

	useEffect(() => {
		if (Object.values(settings).length !== 0) {
			// if fetched settings has isDemo property
			if (settings.hasOwnProperty("isDemo")) {
				dispatch(fetchSettingsSuccess({ ...(settings as SettingsState) }));
			} else {
				dispatch(fetchSettingsSuccess({ ...settings, isDemo: false }));
			}
		}
	}, [settings]);

	return { settings, setSettings };
};
