import { useEffect, useState } from "react";
import styled, { useTheme, css } from "styled-components";
import Icon from "../../../../components/Icon/Icon";
import { GalleryBody, StyledGalleryCard } from "../../../Judging/GalleryCard";
import {
	GalleryContainer,
	StyledVerticalDivider,
	splitBatchLabels,
} from "../../../Judging/JudgingGallery";
import { ActiveBar } from "../../../Judging/JudgingGalleryNav";
import { getThumbnailSrc } from "../../../MediaLibrary/manageMediaLibrary";

import Loading from "../../../../components/Loading/Loading";

import StickyCard, {
	StickyCardBody,
} from "../../../../components/StickyCard/StickyCard";
import Collapsible from "../../../../components/Collapsible/Collapsible";
import {
	GalleryBatchCard,
	GalleryEntryCard,
	LiveToolsSettings,
} from "../JudgingInterfaces";
import AwardSummaryBox from "./AwardSummaryBox";
import filterEntries from "./EntryFilter";
import ScoreboardView from "../../../Judging/Scoreboard/ScoreboardView";
import { StyledTitleSlideScoreIndicator } from "./MediaViewer";
import { voteButtonColor } from "../../../Judging/GalleryCard";

const gridCardWidth = 430;

const EntryContainer = styled(GalleryContainer)<{
	viewType: ViewType;
	scoreboardView?: boolean;
}>`
	margin-bottom: 365px;
	padding: 0 1rem;

	${(p) =>
		p.viewType === ViewType.List
			? `
          display: flex;
          flex-direction: column;
          gap: 1rem;  
        `
			: p.scoreboardView
			? `
          @media only screen and (min-width: ${p.theme.sm}) {
            display: grid;
            grid-template-columns: repeat(auto-fill, ${gridCardWidth + "px"});
            grid-auto-rows: 1fr;
          }
        `
			: `
          @media only screen and (min-width: ${p.theme.sm}) {
            display: grid;
            grid-template-columns: repeat(auto-fill, 250px);
          }
        `}
`;

const StyledEntryGridCard = styled(StyledGalleryCard)<{
	highlighted?: boolean;
	scoreboardView?: boolean;
	isLocked?: boolean;
}>`
	display: flex;
	min-height: 275px;
	max-width: 250px;
	border-bottom: 2px solid ${({ theme }) => theme.colorBorderLight};
	cursor: pointer;

	@media only screen and (min-width: ${({ theme }) => theme.sm}) {
		min-height: 300px;
	}

	${(p) =>
		p.scoreboardView &&
		css`
			border-bottom: 0;
			max-width: ${gridCardWidth + "px"};
			min-height: 0 !important;

			${ActiveBar} {
				background: transparent;
			}

			.award-container {
				display: flex;
				width: 100%;
				flex-wrap: wrap;
				gap: 0.25rem;
				padding: 1rem;
				background: ${p.theme.colorBackgroundDarkDark};
				color: ${p.theme.colorCopyLightLight};
			}

			.cover-img {
				width: 216px;
				height: 100%;
				object-fit: cover;
				aspect-ratio: 1 / 1;
				overflow-clip-margin: unset;
			}
		`};

	${ActiveBar} {
		bottom: -2px;
	}

	&:hover {
		${GalleryBody} {
			filter: none;
		}

		${ActiveBar} {
			width: 100%;
		}
	}

	${(p) =>
		p.highlighted &&
		`
      ${ActiveBar} {
        background: ${p.theme.colorPrimary};
        width: 100%;
      }
    `}

	${(p) =>
		p.isLocked &&
		`
      filter: brightness(85%);
    `}
`;

const StyledEntryListCard = styled.div<{
	highlighted?: boolean;
	scoreboardView?: boolean;
	isLocked?: boolean;
}>`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	gap: 2rem;
	/* padding: 1rem 1.5rem; */
	background: ${({ theme }) => theme.colorBackgroundLightLight};
	box-shadow: 0 2px 4px 0 ${({ theme }) => theme.colorBoxShadow};
	cursor: pointer;

	${(p) =>
		p.scoreboardView &&
		css`
			.award-container {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				gap: 0.25rem;
				padding: 1rem;
				background: ${p.theme.colorBackgroundDarkDark};
				color: ${p.theme.colorCopyLightLight};
			}

			.list-view-img {
				object-fit: cover;
				aspect-ratio: 1 / 1;
				overflow-clip-margin: unset;
			}

			@media only screen and (min-width: 1600px) {
				.award-container {
					display: flex;
					justify-content: center;
					min-width: 400px;
				}

				h2 {
					font-size: 2rem !important;
				}
				p {
					font-size: 1.25rem !important;
					line-height: 27px;
				}

				.list-view-img {
					flex: 0 0 300px;
					height: 169px;
				}
			}
		`};

	${ActiveBar} {
		bottom: -2px;
	}

	&:hover {
		${ActiveBar} {
			width: 100%;
		}
	}

	img {
		width: 178px;
		height: 100px;
	}

	${(p) =>
		p.highlighted &&
		`
      ${ActiveBar} {
        background: ${p.theme.colorPrimary};
        width: 100%;
      }
    `}

	${(p) =>
		p.isLocked &&
		`
      filter: brightness(85%);
    `}
`;

const PhysicalComponentFlag = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;
	width: 45px;
	height: 45px;
	border-left: 45px solid transparent;
	border-top: 45px solid ${({ theme }) => theme.colorBackgroundDarkDark};

	.cube {
		position: absolute;
		top: -42px;
		right: 3px;
	}
`;

export function countExplicitNoGrandJurors(
	galleryEntryCards: GalleryEntryCard[]
): number | undefined {
	let noGrandJurors = new Set<number>();
	let grandOrAbstainJurors = new Set<number>();

	for (let card of galleryEntryCards) {
		for (let vote of card.votes) {
			if (!vote.voteOption) {
				// If any voteOption is undefined, return undefined
				return undefined;
			} else if (vote.voteOption?.value === 0) {
				noGrandJurors.add(vote.jurorId);
			} else if (
				vote.voteOption?.value === 1 ||
				vote.voteOption?.value === null
			) {
				grandOrAbstainJurors.add(vote.jurorId);
			}
		}
	}

	// Find jurors who voted "No Grand" but did not vote "Grand" or "Abstain"
	let explicitNoGrandJurors = [...noGrandJurors].filter(
		(jurorId) => !grandOrAbstainJurors.has(jurorId)
	);

	return explicitNoGrandJurors.length;
}

export function IsNoGrandMajority(
	galleryEntryCards: GalleryEntryCard[]
): boolean {
	for (let card of galleryEntryCards) {
		if (card.score! > 0 || !card.votes.every((x) => x.voteOptionId)) {
			return false;
		}
	}
	return true;
}

const EntryGridCard = (props: EntryCard) => {
	const theme = useTheme();
	return (
		<StyledEntryGridCard
			id={String(props.id)}
			tint={false}
			onClick={() => props.onClick()}
			highlighted={props.highlighted}
			scoreboardView={props.scoreboardView}
			isLocked={props.isLocked}
		>
			{props.hasPhysicalComponent && (
				<PhysicalComponentFlag>
					<Icon
						className="cube"
						icon="cube"
						color={theme.colorPrimary}
						width="18px"
						height="18px"
					/>
				</PhysicalComponentFlag>
			)}
			<div
				className={
					props.scoreboardView ? "flex flex-auto" : "flex flex-col h-full"
				}
			>
				<img
					className="cover-img"
					src={(props.coverImage && getThumbnailSrc(props.coverImage!)) || ""}
					alt="Cover Image"
				/>

				{props.isLocked && (
					<Icon
						className="absolute bottom-[.5rem] right-[.5rem] z-[10]"
						icon="lock"
						color={
							props.scoreboardView
								? theme.colorCopyLightLight
								: theme.colorPrimary
						}
						width="20px"
						height="20px"
					/>
				)}
				<GalleryBody className="relative !border-0 !p-[1.5rem_1rem] !pb-[2rem]">
					<div className="section">
						<p className="subtext mb-[.25rem] break-words">{props.brand}</p>
						<h2 className="playfair-500 text-[1.25rem] break-words">
							{props.title}
						</h2>
					</div>
					<p className="absolute right-[.5rem] bottom-[.5rem] text-xxxSmallSize text-colorCopyLight">
						{props.entryId}
					</p>

					<div className="text-xxSmallSize leading-[1rem] text-colorCopyLight mt-auto text-right">
						{props.scoreboardView &&
							props.batchHierarchyDefinitions &&
							props.batchHierarchyDefinitions.length > 1 &&
							props.batchHierarchyDefinitions.map(
								(level, i) =>
									i !== 0 && (
										<>
											<span className="whitespace-break-spaces text-xxSmallSize">
												{level.name === "Single Entry" ? "Single" : level.name}
											</span>
											{i !== props.batchHierarchyDefinitions!.length - 1
												? " | "
												: ""}
										</>
									)
							)}
					</div>
				</GalleryBody>
				{!props.scoreboardView && (
					<div className="text-xxxSmallSize text-colorCopyLight p-[.5rem]">
						<p
							className="whitespace-pre-wrap"
							dangerouslySetInnerHTML={{
								__html: props.companyName,
							}}
						></p>
					</div>
				)}
			</div>

			{props.scoreboardView && (
				// for the grand/winner round, the vote results only show if revealWinner is true and all votes have been cast
				// otherwise vote results show when all votes have been cast for the entry
				<div className={`award-container`}>
					<AwardSummaryBox
						roundEntry={props}
						roundType={props.settings.roundStructure?.name || ""}
						voteOptions={
							props.settings.roundStructure?.roundType?.voteOptions || []
						}
						awardOnly={
							props.settings.roundStructure?.name === "Grand" ||
							props.settings.roundStructure?.name === "Winner" ||
							false
						}
						includeAbstain={
							(props.settings.roundStructure?.name !== "Grand" &&
								props.settings.roundStructure?.name !== "Winner") ||
							true
						}
						revealResults={
							(props.settings.roundStructure?.name === "Grand" ||
							props.settings.roundStructure?.name === "Winner"
								? props.settings.revealWinner
								: true) && props.votes.every((x) => x.voteOptionId)
						}
						votedOnly={false}
						includeUnvoted={false}
						scoreAndAbstainOnly={props.settings.roundStructure?.name === "1-10"}
					/>
				</div>
			)}

			<ActiveBar leftPosition="0" />
		</StyledEntryGridCard>
	);
};

const EntryListCard = (props: EntryCard) => {
	const theme = useTheme();
	return (
		<StyledEntryListCard
			id={String(props.id)}
			onClick={() => props.onClick()}
			highlighted={props.highlighted}
			scoreboardView={props.scoreboardView}
			isLocked={props.isLocked}
		>
			{props.isLocked && (
				<Icon
					className="absolute bottom-[.5rem] right-[.5rem] z-[10]"
					icon="lock"
					color={theme.colorPrimary}
					width="20px"
					height="20px"
				/>
			)}

			{props.hasPhysicalComponent && (
				<PhysicalComponentFlag>
					<Icon
						className="cube"
						icon="cube"
						color={theme.colorPrimary}
						width="18px"
						height="18px"
					/>
				</PhysicalComponentFlag>
			)}

			<div className="flex items-center p-[1rem_1.5rem] flex-1 gap-[1rem]">
				<img
					className="list-view-img"
					src={(props.coverImage && getThumbnailSrc(props.coverImage!)) || ""}
					alt="Cover Image"
				/>
				<div className="flex flex-col lg:w-[300px] xxl:w-[400px]">
					<p className="subtext mb-[.25rem]">{props.brand}</p>
					<h2 className="playfair-500 text-[1.25rem]">{props.title}</h2>
					<p className="mt-auto text-xxSmallSize text-colorCopyLight">
						{props.entryId}
					</p>
				</div>
				<div className="flex flex-col">
					{props.batchHierarchyDefinitions?.flatMap((level, i) =>
						i !== 0 ? (
							<p
								key={`entry.${props.entryId}.hierarchyLabel.${i}`}
								className="text-xxSmallSize"
							>{`${level.label} : ${level.name}`}</p>
						) : (
							[]
						)
					)}
				</div>
				{!props.scoreboardView && (
					<div className="text-right flex flex-col ml-auto">
						<p
							className="text-xxSmallSize whitespace-pre-wrap"
							dangerouslySetInnerHTML={{
								__html: props.companyName,
							}}
						></p>
					</div>
				)}
			</div>

			{props.scoreboardView && (
				// for the grand/winner round, the vote results only show if revealWinner is true and all votes have been cast
				// otherwise vote results show when all votes have been cast for the entry
				<div className={`award-container`}>
					<AwardSummaryBox
						roundEntry={props}
						roundType={props.settings.roundStructure?.name || ""}
						voteOptions={
							props.settings.roundStructure?.roundType?.voteOptions || []
						}
						awardOnly={
							props.settings.roundStructure?.name === "Grand" ||
							props.settings.roundStructure?.name === "Winner" ||
							false
						}
						includeAbstain={
							(props.settings.roundStructure?.name !== "Grand" &&
								props.settings.roundStructure?.name !== "Winner") ||
							true
						}
						revealResults={
							(props.settings.roundStructure?.name === "Grand" ||
							props.settings.roundStructure?.name === "Winner"
								? props.settings.revealWinner
								: true) && props.votes.every((x) => x.voteOptionId)
						}
						votedOnly={false}
						includeUnvoted={false}
						scoreAndAbstainOnly={props.settings.roundStructure?.name === "1-10"}
						size="80px"
					/>
				</div>
			)}
			<ActiveBar leftPosition="0" />
		</StyledEntryListCard>
	);
};

const SmallGalleryContainer = styled(GalleryContainer)<{
	viewType: ViewType;
	width: string;
}>`
	${(p) =>
		p.viewType === ViewType.List
			? `
         display: flex;
`
			: `
  @media only screen and (min-width: ${p.theme.sm}) {
    display: grid;
    grid-template-columns: repeat(auto-fill, ${p.width});
  }
`}
`;

export const BatchContainer = (props: {
	id: number;
	showBatchDivider: boolean;
	children: React.ReactNode;
	title: string;
	entryCount: number;
	viewType: ViewType;
	cardWidth: string;
	background?: string;
}) => {
	const theme = useTheme();
	const [isCollapsed, setIsCollapsed] = useState(false);
	const batchLabels = splitBatchLabels(props.title);

	return props.showBatchDivider ? (
		<StickyCard
			className={`!relative !top-0 !max-h-fit !min-h-0`}
			titleElement={
				<div className="flex items-center flex-wrap gap-[.5rem]">
					{batchLabels.map((label, i) => (
						<>
							<h2
								key={`batch.${String(props.id)}.label.${i}`}
								className="whitespace-nowrap text-xSmallSize xl:text-pSize xxl:text-h2Size"
							>
								{label}
							</h2>
							{i !== batchLabels.length - 1 && (
								<StyledVerticalDivider
									key={`batch.${String(props.id)}.divider.${i}`}
								/>
							)}
						</>
					))}
				</div>
			}
			rightTitle={`${props.entryCount} Entries`}
			headerIcons={[
				<Icon
					icon="caret"
					color={theme.colorCopyLightLight}
					height="21px"
					width="21px"
					className="caret"
					rotation={isCollapsed ? "180deg" : "0"}
					onClick={() => setIsCollapsed(!isCollapsed)}
				/>,
			]}
			background={props.background}
		>
			<Collapsible isCollapsed={isCollapsed}>
				<StickyCardBody className="flex flex-col gap-[1.875rem] !p-[.75rem_1rem] sm:!p-[2.5rem_1.5rem]">
					<SmallGalleryContainer
						viewType={props.viewType}
						width={props.cardWidth}
					>
						{props.children}
					</SmallGalleryContainer>
				</StickyCardBody>
			</Collapsible>
		</StickyCard>
	) : (
		<>{props.children}</>
	);
};

const EntryViewContainer = (props: {
	showBatchDivider: boolean;
	children: React.ReactNode;
	viewType: ViewType;
}) => {
	if (props.showBatchDivider) {
		return (
			<div className="flex flex-col gap-[2rem] p-[0_2.5rem]">
				{props.children}
			</div>
		);
	} else
		return (
			<EntryContainer key={props.viewType} viewType={props.viewType}>
				{props.children}
			</EntryContainer>
		);
};

/* for the grand round in the scoreboard, show a tally of "no
              grand" and "abstain" votes */
const ScoreCount = (props: {
	className?: string;
	id: string;
	count?: number;
	voteName: string;
	highlighted: boolean;
	background: string;
}) => {
	return (
		<StyledEntryGridCard
			className={`${
				props.className ? props.className : ""
			} !bg-colorBackgroundDarkDark !max-w-full p-[2rem]`}
			id={props.id}
			tint={false}
			scoreboardView={true}
		>
			<div className="flex justify-center items-center h-full w-full">
				<StyledTitleSlideScoreIndicator
					className="flex-col gap-[.5rem]"
					background={props.background}
				>
					<p className="text-[1.5rem] font-bold">{props.count || "-"}</p>
					<p>{props.voteName}</p>
				</StyledTitleSlideScoreIndicator>
			</div>
		</StyledEntryGridCard>
	);
};

function checkDiff(a: any[]) {
	return new Set(a).size !== 1;
}

const EntryView = (props: EntryViewProps) => {
	const theme = useTheme();
	const [filteredBatches, setFilteredBatches] = useState<GalleryBatchCard[]>(
		props.batches
	);
	const [filteredEntries, setFilteredEntries] = useState<GalleryEntryCard[]>(
		props.roundEntries
	);

	// obj: if batch division is enabled
	const objEntries =
		filteredBatches && filteredBatches.length > 0
			? filteredBatches.map((batch) => {
					return (
						<BatchContainer
							key={batch.id}
							id={batch.id}
							showBatchDivider={props.settings.includeBatchDivisions}
							title={batch.label}
							entryCount={batch.roundEntries.length}
							viewType={props.settings.displayConfiguration}
							cardWidth={props.scoreboardView ? gridCardWidth + "px" : "250px"}
						>
							{batch.roundEntries.map((roundEntryCard) => {
								return props.settings.displayConfiguration === ViewType.List ? (
									<EntryListCard
										key={roundEntryCard.id}
										onClick={() =>
											props.onClickCard && props.onClickCard(roundEntryCard)
										}
										{...roundEntryCard}
										highlighted={
											roundEntryCard.id ===
											props.settings.highlightedRoundEntryId
										}
										settings={props.settings}
										scoreboardView={props.scoreboardView}
									/>
								) : (
									<EntryGridCard
										key={roundEntryCard.id}
										onClick={() =>
											props.onClickCard && props.onClickCard(roundEntryCard)
										}
										{...roundEntryCard}
										highlighted={
											roundEntryCard.id ===
											props.settings.highlightedRoundEntryId
										}
										settings={props.settings}
										scoreboardView={props.scoreboardView}
									/>
								);
							})}
						</BatchContainer>
					);
			  })
			: null;

	// arr: if batch division is disabled
	const arrEntries =
		filteredEntries && filteredEntries.length > 0
			? filteredEntries.map((roundEntryCard) => {
					return props.settings.displayConfiguration === ViewType.List ? (
						<EntryListCard
							key={roundEntryCard.id}
							onClick={() =>
								props.onClickCard && props.onClickCard(roundEntryCard)
							}
							{...roundEntryCard}
							highlighted={
								roundEntryCard.id === props.settings.highlightedRoundEntryId
							}
							settings={props.settings}
							scoreboardView={props.scoreboardView}
						/>
					) : (
						<EntryGridCard
							key={roundEntryCard.id}
							onClick={() =>
								props.onClickCard && props.onClickCard(roundEntryCard)
							}
							{...roundEntryCard}
							highlighted={
								roundEntryCard.id === props.settings.highlightedRoundEntryId
							}
							settings={props.settings}
							scoreboardView={props.scoreboardView}
						/>
					);
			  })
			: null;

	useEffect(() => {
		if (props.settings.includeBatchDivisions && props.batches.length > 0) {
			// filter batches
			const filteredBatches = filterEntries(
				props.searchVal || "",
				props.batches,
				undefined
			);
			setFilteredBatches(filteredBatches as GalleryBatchCard[]);
		} else if (
			!props.settings.includeBatchDivisions &&
			props.roundEntries.length > 0
		) {
			// filter round entries (no batch divisions)
			const filteredEntries = filterEntries(
				props.searchVal || "",
				undefined,
				props.roundEntries
			);

			setFilteredEntries(filteredEntries as GalleryEntryCard[]);
		}
	}, [props.batches, props.roundEntries, props.searchVal]);

	const isNoGrandMajority =
		filteredEntries &&
		filteredEntries.length > 0 &&
		IsNoGrandMajority(filteredEntries);

	return props.roundEntries.length > 0 || props.batches.length > 0 ? (
		props.settings.includeBatchDivisions ? (
			<div className="flex flex-col gap-[2rem] p-[0_2.5rem] mb-[300px]">
				{objEntries}
			</div>
		) : (
			<EntryContainer
				key={props.settings.displayConfiguration}
				viewType={props.settings.displayConfiguration}
				scoreboardView={props.scoreboardView}
			>
				{arrEntries}
				{/* for the grand round, show 1 "no grand"/abstain card per entry set */}
				{props.scoreboardView &&
					// check if multiple entry sets are selected
					!checkDiff(
						props.settings.selectedBatches.map((batch) => batch.entrySetId)
					) &&
					filteredEntries &&
					filteredEntries.length > 0 && (
						<>
							{filteredEntries[0].roundTypeId === 4 && (
								<ScoreCount
									id={`entrySet.${filteredEntries[0].entrySetId}.noGrand`}
									className={
										props.settings.displayConfiguration === ViewType.List
											? "h-[201px]"
											: ""
									}
									count={
										props.settings.revealWinner
											? countExplicitNoGrandJurors(filteredEntries)
											: undefined
									}
									voteName="No Grand"
									highlighted={props.settings.revealWinner && isNoGrandMajority}
									background={
										props.settings.revealWinner && isNoGrandMajority
											? voteButtonColor("No Grand", theme)
											: "transparent"
									}
								/>
							)}
							{(filteredEntries[0].roundTypeId === 4 ||
								filteredEntries[0].roundTypeId === 5) && (
								<ScoreCount
									id={`entrySet.${filteredEntries[0].entrySetId}.abstain`}
									className={
										props.settings.displayConfiguration === ViewType.List
											? "h-[201px]"
											: ""
									}
									count={
										props.settings.revealWinner
											? filteredEntries[0].votes.reduce(
													(count, vote) =>
														vote.voteOption && vote.voteOption.value === null
															? count + 1
															: count,
													0
											  )
											: undefined
									}
									voteName="Abstain"
									highlighted={false}
									background="transparent"
								/>
							)}
						</>
					)}
			</EntryContainer>
		)
	) : (
		<Loading />
	);
};

export default EntryView;

interface EntryViewProps {
	settings: LiveToolsSettings;
	batches: Array<GalleryBatchCard>;
	roundEntries: Array<GalleryEntryCard>;
	onClickCard?: (roundEntry: GalleryEntryCard) => void;
	updateHighlightedRoundEntryId?: (roundEntryId?: number) => void;
	searchVal?: string;
	scoreboardView?: boolean;
}

export enum ViewType {
	List,
	Grid,
}

export enum EntrySortOptions {
	PreviousRoundScoreDescending,
	PreviousRoundScoreAscending,
	CurrentScoreDescending,
	CurrentScoreAscending,
	EntryBrandDescending,
	EntryBrandAscending,
	EntryTitleDescending,
	EntryTitleAscending,
}

interface EntryCard extends GalleryEntryCard {
	onClick: () => void;
	highlighted: boolean;
	settings: LiveToolsSettings;
	scoreboardView?: boolean;
}
