import styled, { useTheme } from "styled-components";
import { useState, useEffect, useContext, useRef, useCallback } from "react";
import {
	StickyHeaderBar,
	StickyCardBody,
	StickyCardHeader,
} from "../../../components/StickyCard/StickyCard";
import SearchBar from "../../../components/SearchBar/SearchBar";
import Button from "../../../components/Button/Button";
import {
	MediaItem,
	MediaType,
	SortedMediaType,
	mediaTypeOptions,
} from "../../MediaLibrary/mediaLibrary.model.d";
import StickyCard from "../../../components/StickyCard/StickyCard";
import Modal from "../../../components/Modal/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormikProvider } from "formik";
import { ModalCard } from "../../../components/Modal/Modal";
import useFileHandlers from "../../../hooks/useFileHandlers";
import Icon from "../../../components/Icon/Icon";
import {
	MediaCheckContext,
	ToggleMobileMediaLibContext,
} from "../../Entries/EntryForm";
import DropdownField from "../../../components/FormFields/DropdownField";
import {
	MediaSortType,
	filterMediaByFileName,
	filterByTags,
	filterMediaByType,
	handleMediaSort,
	mediaSortOptions,
	filterMediaByFileAndTags,
} from "../../MediaLibrary/MediaSort";
import TagFilter from "./TagFilter";
import { getClaims } from "../../../components/Auth/handleJWT";
import MediaUpload from "./MediaUpload";
import { useParams } from "react-router-dom";
import { getCompany } from "../../Company/manageCompany";

import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { CSSTransition } from "react-transition-group";
import DraggableMediaList, {
	DragMedia,
	DragMediaItem,
} from "../../../components/Draggable/DraggableMedia";
import useListHeight from "../../../hooks/useListHeight";
import VirtualizeList from "../../../components/VirtualizeList/VirtualizeList";
import { useAlert } from "../../../components/Alert/Alerts";
import assetsConfig from "../../../assetsConfig";

const AddMediaForm = (props: AddMediaProps) => {
	const theme = useTheme();
	const { companyId } = useParams<{
		companyId?: string;
	}>();
	const parsedCompanyId = companyId
		? parseInt(companyId)
		: props.companyId
		? props.companyId
		: Number(getCompany() || "0");
	const { addNewAlert } = useAlert();
	const [initialValues, setInitialvalues] = useState({
		companyId: 1,
		fileName: "",
		path: "",
		type: "",
		size: "",
	});
	// const [uploadedFile, setUploadedFile] = useState<FileObj | null>(null);
	// const [uploadtoMediaLib, setUploadtoMediaLib] = useState<boolean>(false);
	let fieldRequired = "This field is required";

	const formikProps = useFormik({
		initialValues: initialValues,
		onSubmit: async () => {},
		validationSchema: Yup.object({}),
		validateOnBlur: false,
		validateOnChange: false,
	});

	return (
		<div className="p-[2rem] overflow-y-auto">
			<FormikProvider value={formikProps}>
				<div className="mb-[2rem]">
					<p>
						Click on the box below or drag files from your desktop into the box
						below to upload files to your media library. You may upload images,
						video files, mp3 files or PDFs.{" "}
					</p>
					<p>
						Once you add them here, click the "Add Media" button. This will save
						your files to your library. Once saved, you will be able to drag
						them from your library into any entry.{" "}
					</p>
				</div>

				<MediaUpload
					onUploadSuccess={() => {
						props.onUploadSuccess(true);
						addNewAlert({
							type: "success",
							message: "Successfully uploaded media",
						});
					}}
					companyId={parsedCompanyId}
				/>
			</FormikProvider>
		</div>
	);
};

export const MobileLibrary = styled.div`
	position: fixed;
	top: 0;
	z-index: 999999999999999;
	width: 100%;
	left: 0;
	/* left: 50%;
	transform: translateX(-50%); */

	&.mobile-library-enter {
		opacity: 0;
	}
	&.mobile-library-enter-active {
		opacity: 1;
		transition: opacity ease 300ms;
	}

	&.mobile-library-enter-done {
		opacity: 1;
	}

	&.mobile-library-exit {
		opacity: 1;
	}
	&.mobile-library-exit-active {
		opacity: 0;
		transition: opacity ease 300ms;
	}
`;

export const DraggableList = styled.div<{ height: number }>`
	height: ${(p) => p.height + "px"};
	overflow-y: auto;
`;

const NewMediaLibrarySticky = (props: MediaLibraryStickyProps) => {
	const { width } = useWindowDimensions();
	const { usedHeight, listHeight } = useListHeight();
	const claims = getClaims();
	const isAdmin = claims.some(
		(claim) => claim.name === "role" && claim.value === "admin"
	);
	const { addNewAlert } = useAlert();

	const { mediaArr = [] } = props;
	const theme = useTheme();
	const [filteredMedia, setFilteredMedia] = useState<MediaItem[]>(mediaArr);
	const [showAddMediaModal, setShowAddMediaModal] = useState(false);
	const { resetMediaCheck, setResetMediaCheck } = useContext(MediaCheckContext);
	const { mediaSelect, setMediaSelect } = useContext(
		ToggleMobileMediaLibContext
	);

	const [searchVal, setSearchVal] = useState("");
	const [filterVal, setFilterVal] = useState<MediaType | null>(null);
	const [sortVal, setSortVal] = useState<MediaSortType | null>(null);
	//   const [tagSearch, setTagSearch] = useState<string[]>([]);

	// replaces drag/drop with multiselect
	const isMobile = width <= Number(theme.lg.replaceAll("px", ""));
	const nodeRef = useRef(null);

	const applyFilterSearchSort = () => {
		let result = [...mediaArr];

		// Filter
		if (filterVal !== null) {
			result = filterMediaByType(result, filterVal);
		}

		// Search by filename OR tags
		if (searchVal !== "") {
			// result = filterMediaByFileName(result, searchVal);
			const tagSearch = [searchVal];
			result = filterMediaByFileAndTags(result, searchVal, tagSearch);
		}

		// Sort
		if (sortVal !== null) {
			result = handleMediaSort(sortVal, result) || result;
		}

		// Tags
		// if (tagSearch.length > 0) {
		//   result = filterByTags(result, tagSearch);
		// }

		setFilteredMedia(result);
	};

	useEffect(() => {
		applyFilterSearchSort();
	}, [searchVal, filterVal, sortVal, mediaArr]);

	const resetCheckedMedia = () => {
		setFilteredMedia(
			filteredMedia.map((media) => {
				return {
					...media,
					isCheckedForDrag: false,
				};
			})
		);
	};

	const getCheckedMedia = () => {
		const checkedMedia = filteredMedia.filter((mediaObj) => {
			// console.log("mediaObj", mediaObj);
			return mediaObj.isCheckedForDrag;
		});
		return checkedMedia;
	};

	useEffect(() => {
		if (resetMediaCheck) {
			resetCheckedMedia();
			setResetMediaCheck(false);
		}
	}, [resetMediaCheck]);

	const addMediaModal = (
		<Modal show={showAddMediaModal}>
			<ModalCard
				title="Add Media"
				headerIcons={
					<Icon
						icon="close"
						color={theme.colorPrimary}
						onClick={() => setShowAddMediaModal(false)}
						width="35px"
						height="35px"
					/>
				}
				iconColor={theme.colorPrimary}
			>
				<AddMediaForm
					companyId={props.companyId}
					onUploadSuccess={(successUpload) => {
						props.refreshMedia(successUpload); // re-renders media lib when new media uploaded
						setShowAddMediaModal(false);
					}}
				/>
			</ModalCard>
		</Modal>
	);

	const checkedMedia = getCheckedMedia();

	const handleMediaSelect = useCallback(
		(mediaSelect) => {
			if (mediaSelect !== null) {
				mediaSelect(checkedMedia)
					.then((res: any) => {
						addNewAlert({
							type: "success",
							message: res,
						});
						setMediaSelect(null);
					})
					.catch((err: any) =>
						addNewAlert({
							type: "error",
							message: err,
						})
					);
			}
		},
		[mediaSelect, checkedMedia]
	);

	const mobileMediaList =
		filteredMedia.length > 0 ? (
			<>
				{filteredMedia.map((mediaObj, index) => (
					<DragMedia
						key={mediaObj.id! + index}
						mediaObj={mediaObj}
						onClick={() =>
							setFilteredMedia(
								filteredMedia.map((filterMedia, filterIndex) => {
									return index === filterIndex
										? {
												...mediaObj,
												isCheckedForDrag: !mediaObj.isCheckedForDrag,
										  }
										: filterMedia;
								})
							)
						}
						checked={mediaObj.isCheckedForDrag}
						allowMultiSelect
						isMobile
						fileEllipsis={80}
					/>
				))}
			</>
		) : (
			<div className="w-full p-2 text-center mt-4">No files available</div>
		);

	return isMobile ? (
		<CSSTransition
			classNames="mobile-library"
			in={mediaSelect !== null}
			timeout={300}
			nodeRef={nodeRef}
			unmountOnExit
		>
			<MobileLibrary ref={nodeRef}>
				{addMediaModal}
				<StickyCard
					className={`${props.className ? props.className : ""} ${
						isMobile ? "!max-h-[70vh] !min-h-[70vh] !top-0" : ""
					}`}
				>
					<StickyCardHeader className="!p-[1.5rem_2rem]">
						{/* {"Media Library"} */}

						<div className="flex items-center gap-[1rem] flex-wrap w-full">
							<span className="text-[1.375rem] mr-auto">Media Library</span>
							{isAdmin && props.companyId && (
								<Button
									className="button-gold !text-colorCopyLightLight !p-[1rem_1.5rem]"
									icon="media-library"
									iconColor={theme.colorPrimary}
									onClick={() =>
										window.open(
											`/admin/edit-entry/media-library/${props.companyId}`
										)
									}
									iconSize="18px"
								>
									{assetsConfig.labels.company.singular} Media
								</Button>
							)}
							<Button
								className={`w-[175px] ${!isAdmin ? "ml-auto" : ""}`}
								icon="plus"
								iconColor={theme.colorCopyLightLight}
								onClick={() => setShowAddMediaModal(true)}
							>
								Add Media
							</Button>
							<Icon
								icon="close"
								color={theme.colorPrimary}
								width="30px"
								height="30px"
								onClick={() => {
									setMediaSelect(null);
									setResetMediaCheck(true);
								}}
							/>
						</div>
					</StickyCardHeader>
					<StickyHeaderBar className="!p-[1.5rem_2rem]">
						<SearchBar
							className="w-full"
							onKeyUp={(e) => setSearchVal(e.currentTarget.value)}
							label="Search Media"
							dropdown={{
								name: "mediaType",
								options: [
									{ value: "all", label: "All Media" },
									...mediaTypeOptions,
								],
								onChange: (value) => {
									if (value === "all") {
										setFilterVal(null);
									} else {
										setFilterVal(Number(value));
									}
								},
							}}
						/>
					</StickyHeaderBar>
					<div className="p-[2rem] overflow-hidden flex-1">
						<div ref={usedHeight}>
							<DropdownField
								className="mb-[1rem]"
								name="sort"
								nonFormik
								placeholder="Sort By"
								options={mediaSortOptions}
								onChange={(e) =>
									setSortVal(
										e.currentTarget.value === ""
											? null
											: MediaSortType[
													e.currentTarget.value as keyof typeof MediaSortType
											  ]
									)
								}
								value={sortVal !== null ? MediaSortType[sortVal] : null}
							/>

							{/* <TagFilter onSearch={(tags) => setTagSearch(tags)} /> */}
							{props.allowMultiselect ||
								(isMobile && (
									<p className="w-fit ml-auto pb-[0.625rem]">Multiselect</p>
								))}
						</div>

						<DraggableList height={listHeight}>{mobileMediaList}</DraggableList>
					</div>

					<Button
						className="mt-auto"
						icon="check"
						onClick={() => handleMediaSelect(mediaSelect)}
						disabled={checkedMedia.length === 0}
					>
						Ok
					</Button>
				</StickyCard>
			</MobileLibrary>
		</CSSTransition>
	) : (
		<>
			{addMediaModal}

			<StickyCard className={props.className || ""}>
				<StickyCardHeader className="!p-[1.5rem_2rem]">
					{"Media Library"}

					<div className="flex gap-[1rem] flex-wrap sm:flex-nowrap">
						{isAdmin && props.companyId && (
							<Button
								className="button-gold !text-colorCopyLightLight !p-[1rem_1.5rem]"
								icon="media-library"
								iconColor={theme.colorPrimary}
								onClick={() =>
									window.open(
										`/admin/edit-entry/media-library/${props.companyId}`
									)
								}
								iconSize="18px"
							>
								Company Media
							</Button>
						)}
						<Button
							className="w-[175px]"
							icon="plus"
							iconColor={theme.colorCopyLightLight}
							onClick={() => setShowAddMediaModal(true)}
						>
							Add Media
						</Button>
					</div>
				</StickyCardHeader>
				<StickyHeaderBar className="!p-[1.5rem_2rem]">
					<SearchBar
						className="w-full"
						onKeyUp={(e) => setSearchVal(e.currentTarget.value)}
						label="Search Media"
						dropdown={{
							name: "mediaType",
							options: [
								{ value: "all", label: "All Media" },
								...mediaTypeOptions,
							],
							onChange: (value) => {
								if (value === "all") {
									setFilterVal(null);
								} else {
									setFilterVal(Number(value));
								}
							},
						}}
					/>
				</StickyHeaderBar>
				<div className="p-[2rem] overflow-hidden flex-1">
					<div ref={usedHeight}>
						<DropdownField
							className="mb-[1rem]"
							name="sort"
							nonFormik
							placeholder="Sort By"
							options={mediaSortOptions}
							onChange={(e) =>
								setSortVal(
									e.currentTarget.value === ""
										? null
										: MediaSortType[
												e.currentTarget.value as keyof typeof MediaSortType
										  ]
								)
							}
							value={sortVal !== null ? MediaSortType[sortVal] : null}
						/>

						{/* <TagFilter onSearch={(tags) => setTagSearch(tags)} /> */}
						{props.allowMultiselect && (
							<p className="w-fit ml-auto mb-[0.625rem]">Multiselect</p>
						)}
					</div>

					<DraggableList height={listHeight}>
						<DraggableMediaList
							list={filteredMedia as DragMediaItem[]}
							name="media-library"
							clone
							sortable={false}
							drop={false}
							allowMultiSelect={props.allowMultiselect}
							fileEllipsis={80}
							hideShadow
						/>
					</DraggableList>

					{/* <VirtualizeList
						list={filteredMedia as DragMediaItem[]}
						height={listHeight}
						itemHeight={84}
					>
						{(virtualList) => (
							<DraggableMediaList
								list={virtualList}
								name="media-library"
								clone
								sortable={false}
								drop={false}
								allowMultiSelect={props.allowMultiselect}
								fileEllipsis={80}
							/>
						)}
					</VirtualizeList> */}
				</div>
			</StickyCard>
		</>
	);
};

interface MediaLibraryStickyProps {
	className?: string;
	mediaArr: MediaItem[];
	onMediaCheck?(id?: string, isCheckedForDrag?: boolean): void;
	allowMultiselect?: boolean;
	refreshMedia(successUpload: boolean): void;
	companyId?: number;
	setDroppedMedia?(mediaArr: MediaItem[]): void;
}

export default NewMediaLibrarySticky;

interface AddMediaProps {
	onUploadSuccess(successUpload: boolean): void;
	onUploadError?(): void;
	companyId?: number;
}
