import styled, { useTheme } from "styled-components";
import CheckboxField from "../../../../components/FormFields/CheckboxField";
import { FormikProvider, useFormik } from "formik";
import Icon from "../../../../components/Icon/Icon";
import useEffect from "react";
import { LiveToolsSettings } from "../JudgingInterfaces";

const StyledGridHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 1rem 1.5rem 2rem 1.5rem;
  padding: 1rem 1.5rem;
  background: ${({ theme }) => theme.colorBackgroundLight};
  border-left: 3px solid ${({ theme }) => theme.colorActivation};
`;

const EntryGridHeader = (props: {
  settings: LiveToolsSettings;
  updateEntriesLocked: (isLocked: boolean) => void;
  onClickFilter(): void;
}) => {
  const theme = useTheme();
  const formikProps = useFormik({
    initialValues: {
      lockAll: props.settings.entriesLocked,
    },
    enableReinitialize: true,
    onSubmit: () => {},
  });

  return (
    <FormikProvider value={formikProps}>
      <StyledGridHeader>
        <b className="max-w-full break-words">
          {props.settings.entryDisplayTitlePreview}
        </b>
        <div className="flex items-center gap-[1rem] ml-auto">
          <CheckboxField
            className="ml-auto"
            name="lockAll"
            placeholder="Lock All"
            checked={formikProps.values.lockAll}
            onChange={(isChecked) => props.updateEntriesLocked(isChecked)}
          />
          <Icon
            icon="filter"
            color={theme.colorPrimary}
            onClick={() => props.onClickFilter()}
          />
        </div>
      </StyledGridHeader>
    </FormikProvider>
  );
};

export default EntryGridHeader;
