import Button from "../../../components/Button/Button";
import TextField from "../../../components/FormFields/TextField";
import styled, { useTheme } from "styled-components";
import { AwardModel } from "./Awards";
import { Formik, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import ToggleSwitchField from "../../../components/FormFields/ToggleSwitchField";
import { urlAwards } from "../../../endpoints";
import axios from "axios";
import FileUpload from "../../../components/FormFields/FileUpload";
import Icon from "../../../components/Icon/Icon";
import { MediaType } from "../../MediaLibrary/mediaLibrary.model.d";

const StyledAwardCard = styled.div`
	display: flex;
	align-items: center;
	padding: 0.6875rem 2.235rem;
	border: solid 1px ${({ theme }) => theme.colorBorderLight};
	background: ${({ theme }) => theme.colorBackgroundLight};

	&.editing {
		.edit-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 0.5rem;

			& > * {
				display: flex;
				gap: 0.25rem;
			}

			.discard {
				color: ${({ theme }) => theme.colorDanger};
			}

			.save {
				color: ${({ theme }) => theme.colorSuccess};
			}
		}
		.icon-container {
			display: flex;
			gap: 0.5rem;
			position: absolute;
			top: 0;
			right: 0;
			padding: 0.5rem;
			background: black;
		}
	}

	.edit-container {
		display: flex;
		gap: 0.5rem;
		width: 130px;
		margin-right: 3.27125rem;
	}

	.award-name {
		width: 200px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin-right: 3.0875rem;
	}

	.award-logos {
		display: flex;
		flex-wrap: wrap;
		/* width: 380px; */
		/* margin-right: 3.0875rem; */

		.img-light,
		.img-dark {
			/* width: 181.57px; */
			height: 104px;
			object-fit: cover;
			border: 2px solid ${({ theme }) => theme.colorBorder};
		}
	}

	.active {
		color: ${({ theme }) => theme.colorActivation};
		font-weight: bold;
	}

	.inactive {
		color: ${({ theme }) => theme.colorCopyLight};
	}
`;

const AwardCard = (props: AwardCardProps) => {
	const theme = useTheme();
	const [errors, setErrors] = useState<string[]>([]);
	const [isEditing, setIsEditing] = useState(false);

	async function PostAward(awardData: AwardModel) {
		try {
			setErrors([]);

			// start formdata
			const formData = new FormData();
			formData.append("Id", awardData.id?.toString() || "");
			formData.append("Name", awardData.name);
			formData.append("IsActive", awardData.isActive ? "True" : "False");
			formData.append("ImageLight", awardData.imageLight || "");
			formData.append("ImageDark", awardData.imageDark || "");
			formData.append("ImageLightData", awardData.imageLightData || "");
			formData.append("ImageDarkData", awardData.imageDarkData || "");
			formData.append("CreatedDate", awardData.createdDate || "");
			formData.append("UpdatedDate", awardData.modifiedDate || "");
			formData.append("CreatedBy", awardData.createdBy || "");
			formData.append("ModifiedBy", awardData.modifiedBy || "");

			const headers = {
				"Content-Type": "multipart/form-data",
			};

			// if id is blank, then add award
			// else, update award
			if (!awardData.id) {
				const response = await axios.post(`${urlAwards}`, formData, {
					headers: headers,
				});
				if (response.status == 201) {
					setIsEditing(false);
					props.onPostAward && props.onPostAward(response.data);
					//setAward(awardData);
				}
			} else {
				const response = await axios.put(
					`${urlAwards}/${awardData.id}`,
					formData,
					{
						headers: headers,
					}
				);
				if (response.status == 204) {
					setIsEditing(false);
					//setAward(awardData);
				}
			}
		} catch (error: any) {
			// console.log(error);
			setErrors(error.response.data);
		}
	}

	let fieldRequired = "This field is required";

	const formikProps = useFormik({
		initialValues: props.award,
		enableReinitialize: true,
		onSubmit: async (value) => {
			await PostAward(value);
		},
		validationSchema: Yup.object({
			name: Yup.string().required(fieldRequired),
			//logo: Yup.string().required(fieldRequired),
		}),
		validateOnBlur: false,
		validateOnChange: false,
	});

	useEffect(() => {
		props.isEditing && setIsEditing(props.isEditing);
	}, [props.isEditing]);

	return (
		<FormikProvider value={formikProps}>
			<div>
				{isEditing ? (
					<Button
						className=""
						icon="check"
						onClick={() => formikProps.submitForm()}
					>
						Save
					</Button>
				) : (
					<Button
						className="button-light"
						icon="edit"
						iconColor={theme.colorActivation}
						onClick={() => setIsEditing(true)}
					>
						Edit
					</Button>
				)}
			</div>

			<TextField
				className="award-name"
				name="name"
				placeholder="Award Name"
				value={formikProps.values.name}
				readOnly={!isEditing}
			/>

			<div className="flex flex-wrap gap-[1.276875rem]">
				<div className="flex-1 relative">
					<FileUpload
						imgOnly
						id="FileUpload-AwardCard-imageLight"
						onChange={(files) =>
							formikProps.setFieldValue("imageLightData", files[0].file)
						}
						{...(props.award.imageLight && {
							value: {
								fileName: "imageDark",
								fileType: "image",
								src: props.award.imageLight,
								initial: true,
							},
							disabled: !isEditing,
						})}
						allowedMediaType={MediaType.Image}
					/>
				</div>

				<div className="flex-1 relative">
					<FileUpload
						imgOnly
						id="FileUpload-AwardCard-imageDark"
						onChange={(files) =>
							formikProps.setFieldValue("imageDarkData", files[0].file)
						}
						{...(props.award.imageDark && {
							value: {
								fileName: "imageLight",
								fileType: "image",
								src: props.award.imageDark,
								initial: true,
							},
							disabled: !isEditing,
						})}
						allowedMediaType={MediaType.Image}
					/>
				</div>
			</div>
			<div>
				{isEditing ? (
					<ToggleSwitchField
						className="ml-auto"
						id="isActive"
						name="isActive"
						small
						checked={formikProps.values.isActive}
						onClick={(e: React.ChangeEvent<HTMLFormElement>) => {
							formikProps.setFieldValue(
								"isActive",
								!formikProps.values.isActive
							);
						}}
					/>
				) : formikProps.values.isActive ? (
					<p className="active">Active</p>
				) : (
					<p className="inactive">Inactive</p>
				)}
			</div>
		</FormikProvider>
	);
};

export default AwardCard;

interface AwardCardProps {
	award: AwardModel;
	isEditing?: boolean;
	onPostAward?(award: AwardModel): void;
}
