import styled, { useTheme } from "styled-components";
import { DynamicTransitionDuration } from "../../globalStyles";
import { useEffect, useRef, useState } from "react";
import StickyCard, {
  StickyCardBody,
  StickyHeaderBar,
} from "../StickyCard/StickyCard";
import Icon from "../Icon/Icon";

const StyledCollapsible = styled.div<{
  isCollapsed: boolean;
  maxHeight: number;
}>`
  transition: max-height ${DynamicTransitionDuration} ease;
  overflow: hidden;

  ${(p) =>
    p.isCollapsed // default is false
      ? `
          max-height: 0;
        `
      : `
          max-height: ${String(p.maxHeight) + "px"};
        `}
`;

const Collapsible = (props: CollapsibleProps) => {
  const collapsibleContainer = useRef<HTMLDivElement>(null);
  const innerContainer = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState(0);
  useEffect(() => {
    if (collapsibleContainer.current) {
      if (props.isCollapsed) {
        setMaxHeight(0);
      } else {
        setMaxHeight(collapsibleContainer.current.scrollHeight);
      }
    }
  }, [props.isCollapsed]);

  // https://stackoverflow.com/questions/76187282/react-resizeobserver-loop-completed-with-undelivered-notifications
  // prevents ResizeObserver infinite loop
  const observerCallback: ResizeObserverCallback = (
    entries: ResizeObserverEntry[]
  ) => {
    window.requestAnimationFrame((): void | undefined => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      setMaxHeight(entries[0].contentRect.height);
    });
  };

  // update max height as innerContainer height changes
  // happens when an element inside is added, removed, or resized
  useEffect(() => {
    if (!collapsibleContainer.current) return;
    const resizeObserver = new ResizeObserver(observerCallback);

    // const resizeObserver = new ResizeObserver((elements) => {
    //   setMaxHeight(elements[0].contentRect.height);
    // });

    // listen for innerContainer height
    innerContainer.current && resizeObserver.observe(innerContainer.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [collapsibleContainer]);

  return (
    <StyledCollapsible
      className={props.className}
      isCollapsed={props.isCollapsed}
      maxHeight={maxHeight}
      ref={collapsibleContainer}
    >
      <div ref={innerContainer}>{props.children}</div>
    </StyledCollapsible>
  );
};

export const CollapsibleContainer = (props: CollapsibleContainerProps) => {
  const theme = useTheme();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = () => {
    if (props.onClickCollapse) {
      props.onClickCollapse(!isCollapsed);
    } else {
      setIsCollapsed(!isCollapsed);
    }
  };

  useEffect(() => {
    setIsCollapsed(props.isCollapsed || false);
  }, [props.isCollapsed]);

  return (
    <StickyCard
      title={props.title}
      headerIcons={[
        ...(props.icons ? props.icons : []),
        <Icon
          icon="caret"
          color={theme.colorCopyLightLight}
          height="21px"
          width="21px"
          className="caret order-last"
          rotation={isCollapsed ? "180deg" : "0"}
          onClick={handleCollapse}
        />,
      ]}
      className={`${
        props.className ? props.className : ""
      } !min-h-0 !max-h-full`}
      id={props.id}
      maxHeight="initial"
    >
      <Collapsible isCollapsed={isCollapsed}>{props.children}</Collapsible>
    </StickyCard>
  );
};

export default Collapsible;

interface CollapsibleProps {
  className?: string;
  children: React.ReactNode;
  isCollapsed: boolean;
}

interface CollapsibleContainerProps {
  className?: string;
  id?: string;
  title: string;
  children: React.ReactNode;
  onClickCollapse?(isCurrentCollapsed: boolean): void;
  isCollapsed?: boolean; // default is false
  icons?: React.ReactNode[];
}
