import React, { useState, createContext } from "react";

// context to update draft, cart, and order counters

export interface CheckoutEntryCardAlertType {
  alertCounter: number;
  addCounter(): void;
}

export const CheckoutEntryCardAlertContext =
  createContext<CheckoutEntryCardAlertType>({
    alertCounter: 0,
    addCounter: () => {},
  });

const CheckoutContextProvider = (props: { children: React.ReactNode }) => {
  const [alertCounter, setAlertCounter] = useState(0);
  const addCounter = () => {
    setAlertCounter(alertCounter + 1);
  };

  const contextValue: CheckoutEntryCardAlertType = {
    alertCounter,
    addCounter,
  };

  return (
    <CheckoutEntryCardAlertContext.Provider value={contextValue}>
      {props.children}
    </CheckoutEntryCardAlertContext.Provider>
  );
};

export default CheckoutContextProvider;
