import React, { useEffect, useState } from "react";
import {
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Image,
	Font,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import { defaultColors, lightTheme, pdfColors } from "../../assetsConfig";
import { Ancestor } from "../Admin/Program/ProgramInterfaces";
import { useTheme } from "styled-components";
import QRCode from "qrcode";
import config from "../../config";
import assetsConfig from "../../assetsConfig";

Font.register({
	family: assetsConfig.fonts.pdf.fontFamily,
	fonts: assetsConfig.fonts.pdf.fonts,
});

const rem = 16;

// Create styles
const styles = StyleSheet.create({
	page: {
		display: "flex",
		flexDirection: "column",
		width: "100vw",
		flexGrow: 1,
		backgroundColor: "white",
		fontSize: "12px",
		fontFamily: "Open Sans",
		// paddingBottom: rem + "px",
	},
	flexCol: {
		display: "flex",
		flexDirection: "column",
	},
	flexRow: {
		display: "flex",
		flexDirection: "row",
	},
	itemsCenter: {
		alignItems: "center",
	},
	justifyCenter: {
		justifyContent: "center",
	},

	justifyBetween: {
		justifyContent: "space-between",
	},
	bold: {
		fontWeight: 900,
	},
	entryCoverImg: {
		width: "100px",
		height: "56px",
		objectFit: "cover",
	},
	entryFirstRow: {
		justifyContent: "space-between",
		marginBottom: "7.5px",
	},
	logo: {
		width: "200px",
		height: "35px",
	},
	mtAuto: {
		marginTop: "auto",
	},
	mlAuto: {
		marginLeft: "auto",
	},
	wFull: {
		width: "100%",
	},
	mrHalf: {
		marginRight: "8px",
	},
	mr1: {
		marginRight: rem + "px",
	},
	mr3: {
		marginRight: "48px",
	},
	mb1: {
		marginBottom: rem + "px",
	},
	mb3: {
		marginBottom: "48px",
	},
	mb4: {
		marginBottom: "64px",
	},
	mbHalf: {
		marginBottom: "8px",
	},
	mt1: {
		marginTop: rem + "px",
	},
	mt2: {
		marginTop: "32px",
	},
	mtHalf: {
		marginTop: "8px",
	},
	headingText: {
		fontSize: rem + "px",
		textTransform: "uppercase",
		color: lightTheme.colorCopyLight,
	},
	subHeadingText: {
		fontSize: "14px",
	},

	textBlue: {
		color: pdfColors.textHighlight,
	},
	textGray: {
		color: lightTheme.colorCopyLight,
	},
	textDanger: {
		color: defaultColors.colorDanger,
	},
	textSmall: {
		fontSize: rem * 0.6 + "px",
	},
	textXSmall: {
		fontSize: rem * 0.5 + "px",
	},

	pageBorderTop: {
		width: "100vw",
		height: rem * 1.5 + "px",
		backgroundColor: pdfColors.textHighlight,
		marginBottom: rem / 2 + "px",
	},
	pageBorderBottom: {
		width: "100vw",
		height: rem + "px",
		backgroundColor: defaultColors.colorAwardGold,
		marginTop: "auto",
	},
	paymentBottom: {
		padding: "0 47px",
		marginLeft: "auto",
		marginTop: "auto",
	},
	wrap: {
		flexWrap: "wrap",
	},

	hr: {
		width: "100%",
		borderBottom: "1px",
		borderBottomColor: lightTheme.colorBorderLight,
	},
	cutOut: {
		margin: rem + "px",
		border: `1px dashed ${lightTheme.colorBorderDark}`,
	},
	border: {
		border: `1px solid ${lightTheme.colorBorderDark}`,
	},
	section: {
		width: "100%",
		paddingVertical: rem + "px",
		paddingHorizontal: rem * 2 + "px",
	},
	sectionSmallPadding: {
		width: "100%",
		paddingVertical: rem / 2 + "px",
		paddingHorizontal: rem + "px",
	},
	textActivation: {
		color: lightTheme.colorActivation,
	},
	checkBox: {
		width: rem / 2 + "px",
		height: rem / 2 + "px",
		border: `1px solid ${lightTheme.colorBorderDark}`,
		top: "1.5px",
	},
	mxAuto: {
		marginHorizontal: "auto",
	},
	divider: {
		borderBottom: `1px solid ${lightTheme.colorBorderLight}`,
	},
	dashedDivider: {
		borderBottom: `1px dashed ${lightTheme.colorBorderLight}`,
	},
	qrCode: {
		width: "80x",
		height: "80x",
		marginLeft: "auto",
	},
	shippingLabel: {
		width: "75vw",
		marginHorizontal: "auto",
		marginTop: rem + "px",
	},
	m1: {
		margin: rem + "px",
	},
});

const Sections = (props: {
	sections: PDFSection[];
	styles?: { [key: string]: string };
}) => {
	return (
		<>
			{props.sections.map((section) => (
				<>
					<View
						style={{ ...styles.section, ...props.styles, ...section.styles }}
					>
						<Text style={{ ...styles.subHeadingText }}>{section.label}</Text>
						<Text>{section.value}</Text>
					</View>
					{section.divider && <View style={styles.divider} />}
				</>
			))}
		</>
	);
};

const ShippingLabel = (props: ShippingLabelProps) => {
	return (
		<View style={styles.shippingLabel}>
			<Text style={{ ...styles.headingText, ...styles.mxAuto, ...styles.mb1 }}>
				{props.instructions.label}
			</Text>
			<Sections
				styles={{
					paddingVertical: "0",
					paddingHorizontal: "0",
					...styles.textSmall,
				}}
				sections={props.instructions.sections}
			/>

			<View style={styles.cutOut}>
				<View
					style={{
						...styles.flexRow,
						...styles.sectionSmallPadding,
						...styles.divider,
						...styles.textXSmall,
					}}
				>
					<View
						style={{
							marginVertical: "auto",
						}}
					>
						<Image
							style={{
								width: "150px",
								height: "26.25px",
								...styles.mbHalf,
							}}
							src={config.assets.logos.localPng}
						/>

						<Text
							style={{
								...styles.headingText,
								...styles.mxAuto,
								...styles.textGray,
								...styles.textSmall,
							}}
						>
							{props.shippingLabel.label}
						</Text>
					</View>

					<View
						style={{
							marginLeft: "auto",
						}}
					>
						<Text>{props.shippingLabel.sections[0].label}</Text>
						<Text>{props.shippingLabel.sections[0].value}</Text>
					</View>
				</View>
				<Sections
					styles={styles.sectionSmallPadding}
					sections={[props.shippingLabel.sections[1]]}
				/>
				{/* <Sections sections={props.shippingLabel.sections} /> */}

				<View style={{ ...styles.sectionSmallPadding, ...styles.textXSmall }}>
					<Text style={styles.subHeadingText}>Order Id: {props.order.id}</Text>
					<Text style={{ ...styles.textActivation, ...styles.mbHalf }}>
						{props.order.orderInstructions}
					</Text>
					{props.order.entries.map((entry) => (
						<View
							key={entry.id}
							style={{
								...styles.flexRow,
								...styles.mbHalf,
							}}
						>
							<View
								style={{
									...styles.checkBox,
									...styles.mrHalf,
								}}
							/>
							<Text>Entry {entry.id} - </Text>
							<View
								style={{
									...styles.flexRow,
									flexWrap: "wrap",
									maxWidth: "85%",
								}}
							>
								{entry.ancestry.map((hierarchy, index) => {
									return (
										<Text key={index}>
											{hierarchy.name}{" "}
											{index < entry.ancestry!.length - 1 && "/ "}
										</Text>
									);
								})}
							</View>
						</View>
					))}
				</View>
			</View>
		</View>
	);
};

const EntryManifest = (props: EntryManifestProps) => {
	return (
		<View style={styles.section} wrap={false}>
			<Text style={{ ...styles.headingText, ...styles.mb1, ...styles.mxAuto }}>
				{props.pageLabel}
			</Text>

			<View
				key={props.entry.id}
				style={{
					...styles.flexRow,
					...styles.justifyBetween,
				}}
			>
				<View>
					<Text>{props.entry.brand}</Text>
					<Text style={styles.subHeadingText}>{props.entry.title}</Text>
					<Text>Entry ID: {props.entry.id}</Text>
					{props.entry.ancestry.map((hierarchy, index) => {
						return <Text key={index}>{hierarchy.name}</Text>;
					})}
				</View>
				<Image style={styles.entryCoverImg} src={props.entry.coverImage} />
			</View>
		</View>
	);
};

const EntrantDetails = (props: EntrantDetailsProps) => {
	const details = props.details;
	return (
		<View style={styles.section} wrap={false}>
			<View style={styles.divider} />
			<Text
				style={{
					...styles.subHeadingText,
					...styles.mbHalf,
					...styles.mt1,
					...styles.textGray,
				}}
			>
				{props.pageLabel}
			</Text>
			<View
				key={details.companyId}
				style={{
					...styles.flexRow,
					...styles.mbHalf,
					...styles.justifyBetween,
				}}
			>
				<View>
					<Text style={{ ...styles.subHeadingText }}>
						{details.entryContactName}
					</Text>
					<Text>{details.companyName}</Text>
					<Text>{details.companyAddress1}</Text>
					<Text>{details.companyAddress2}</Text>
					<Text>
						{details.companyCity}, {details.companyState}{" "}
						{details.companyPostalCode}
					</Text>
					<Text>{details.companyCountry}</Text>
				</View>
				<View>
					<Text>{details.entryContactPhone}</Text>
					<Text>{details.entryContactEmail}</Text>
				</View>
			</View>
		</View>
	);
};
const AdminSection = (props: AdminSectionProps) => {
	const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);

	const baseUrl = process.env.REACT_APP_BASE_URL;
	const path = `judgingredirect/${props.entry.id}`;
	const QRvalue = `${baseUrl}${path}`;

	useEffect(() => {
		QRCode.toDataURL(QRvalue)
			.then((url) => {
				setQrCodeUrl(url);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [QRvalue]);

	return (
		<>
			<Text style={{ ...styles.mxAuto, ...styles.textXSmall }}>
				{props.dividerLabelTop}
			</Text>
			<View
				style={{
					...styles.dashedDivider,
					...styles.mbHalf,
					...styles.mtHalf,
				}}
			/>
			<Text style={{ ...styles.mxAuto, ...styles.textXSmall }}>
				{props.dividerLabelBottom}
			</Text>
			<View style={styles.mt1}>
				<Image
					style={{ ...styles.mxAuto, width: "100px", height: "17.5px" }}
					src={config.assets.logos.localPng}
				/>
				{/* <Text
          style={{
            ...styles.mxAuto,
            ...styles.mt1,
            fontSize: rem * 0.65 + "px",
          }}
        >
          {props.dividerLabelBottom}
        </Text> */}

				<View style={styles.section}>
					<View
						key={props.entry.id}
						style={{
							...styles.flexRow,
							...styles.mbHalf,
							...styles.justifyBetween,
							...styles.border,
							...styles.section,
						}}
						wrap={false}
					>
						<View>
							<Text>{props.entry.brand}</Text>
							<Text style={styles.subHeadingText}>{props.entry.title}</Text>
							<Text>Entry ID: {props.entry.id}</Text>
							{props.entry.ancestry.map((hierarchy, index) => {
								return <Text key={index}>{hierarchy.name}</Text>;
							})}
							<Text style={{ ...styles.textSmall, ...styles.mt1 }}>
								{props.entry.physicalItemDescription}
							</Text>
						</View>
						<View>
							<Image
								style={{ ...styles.entryCoverImg, ...styles.mb1 }}
								src={props.entry.coverImage}
							/>

							{qrCodeUrl && <Image style={styles.qrCode} src={qrCodeUrl} />}
						</View>
					</View>
				</View>
			</View>
		</>
	);
};

const PhysicalEntryPDF = (props: PhysicalEntryPDFProps) => {
	const theme = useTheme();
	const PhysicalPDF_TestData = {
		id: 1,

		company: {
			companyId: 3,
			companyName: "Spruce Technology",
			companyAddress1: "101 Park Avenue",
			companyAddress2: "10F",
			companyCity: "Murray Hill",
			companyCountry: "United States",
			companyPostalCode: "12654",
			companyState: "NY",
			entryContactName: "Jun Huang",
			entryContactEmail: "junhuang@gmail.com",
			entryContactPhone: "1234567890",
		},

		order: {
			id: 1,
			orderInstructions: "Mark the entries that are included in this package.",
			entries: [
				{
					id: 111,
					title: "Jun's awesome entry",
					brand: "some brand",
					ancestry: [
						{ id: 1, name: "level 1 lorem" },
						{ id: 2, name: "level 2 lorem" },
						{ id: 3, name: "level 3 lorem ipsum" },
					],
					coverImage: config.assets.placeholders.image,
					physicalItemDescription:
						"Some physical item \n description orem ipsum",
				},
				{
					id: 222,
					title: "Jun's awesome entry",
					brand: "some brand",
					ancestry: [
						{ id: 1, name: "level 1" },
						{ id: 2, name: "level 2" },
						{ id: 3, name: "level 3" },
						{ id: 4, name: "level 4 lorem ipsum lorem ipsum" },
					],
					coverImage: config.assets.placeholders.image,
					physicalItemDescription:
						"Some physical item \n description orem ipsum",
				},
				{
					id: 333,
					title: "Jun's awesome entry",
					brand: "some brand",
					ancestry: [
						{ id: 1, name: "level 1" },
						{ id: 2, name: "level 2" },
						{ id: 3, name: "level 3" },
					],
					coverImage: config.assets.placeholders.image,
					physicalItemDescription:
						"Some physical item \n description orem ipsum",
				},
				{
					id: 444,
					title: "Jun's awesome entry",
					brand: "some brand",
					ancestry: [
						{ id: 1, name: "level 1" },
						{ id: 2, name: "level 2" },
						{ id: 3, name: "level 3" },
					],
					coverImage: config.assets.placeholders.image,
					physicalItemDescription:
						"Some physical item \n description orem ipsum",
				},
				{
					id: 555,
					title: "Jun's awesome entry",
					brand: "some brand",
					ancestry: [
						{ id: 1, name: "level 1" },
						{ id: 2, name: "level 2" },
						{ id: 3, name: "level 3" },
					],
					coverImage: config.assets.placeholders.image,
					physicalItemDescription:
						"Some physical item \n description orem ipsum",
				},
				{
					id: 666,
					title: "Jun's awesome entry",
					brand: "some brand",
					ancestry: [
						{ id: 1, name: "level 1 lorem ipsum" },
						{ id: 2, name: "level 2 lorem ipsum" },
						{ id: 3, name: "level 3 lorem ipsum world is awesome" },
					],
					coverImage: config.assets.placeholders.image,
					physicalItemDescription:
						"Some physical item \n description orem ipsum",
				},
				{
					id: 777,
					title: "Jun's awesome entry",
					brand: "some brand",
					ancestry: [
						{ id: 1, name: "level 1 lorem ipsum" },
						{ id: 2, name: "level 2 lorem ipsum" },
						{ id: 3, name: "level 3 lorem ipsum world is awesome" },
					],
					coverImage: config.assets.placeholders.image,
					physicalItemDescription:
						"Some physical item \n description orem ipsum",
				},
				{
					id: 888,
					title: "Jun's awesome entry",
					brand: "some brand",
					ancestry: [
						{ id: 1, name: "level 1 lorem ipsum" },
						{ id: 2, name: "level 2 lorem ipsum" },
						{ id: 3, name: "level 3 lorem ipsum world is awesome" },
					],
					coverImage: config.assets.placeholders.image,
					physicalItemDescription:
						"Some physical item \n description orem ipsum",
				},
				{
					id: 999,
					title: "Jun's awesome entry",
					brand: "some brand",
					ancestry: [
						{ id: 1, name: "level 1 lorem ipsum" },
						{ id: 2, name: "level 2 lorem ipsum" },
						{ id: 3, name: "level 3 lorem ipsum world is awesome" },
					],
					coverImage: config.assets.placeholders.image,
					physicalItemDescription:
						"Some physical item \n description orem ipsum",
				},
				{
					id: 100,
					title: "Jun's awesome entry",
					brand: "some brand",
					ancestry: [
						{ id: 1, name: "level 1 lorem ipsum world is awesome" },
						{
							id: 2,
							name: "level 2 lorem ipsum world is awesome worldisawesome sdasdsad",
						},
						{ id: 3, name: "level 3 loremipsum worldisawesome sdasdsad" },
					],
					coverImage: config.assets.placeholders.image,
					physicalItemDescription:
						"Some physical item \n description orem ipsum",
				},
			],
		},

		// admin section
		adminSection: {
			dividerLabelTop:
				"Do not cut, fold, or permanently attach to your submission.",
			dividerLabelBottom: "For Administrative Purposes Only",
		},

		shippingInstructions: {
			label: "Shipping Instructions",
			sections: [
				{
					value: `1. Print the Shipping Label below and all Entry Manifest Pages`,
				},
				{
					value: `2. Pack your physical entry and include the appropriate Entry Manifest Pages with your entry packaging`,
				},
				{
					value: `3. Cut out and attach the Shipping Label to your package along with appropriate postage`,
				},
			],
		},

		shippingLabel: {
			label: "Shipping Label",

			sections: [
				{
					//   label: "Return Address:",
					label: "From: ",
					value:
						"Spruce Technology \n 101 Park Avenue \n 10th Floor \n New York, NY 10001 \n United States",
					divider: true,
				},
				{
					label: "Ship To:",
					value:
						assetsConfig.companyNameLong +
						" \n 104 West 27th Street \n 10th Floor \n New York, NY 10001 \n United States",
					divider: true,
					styles: {
						fontSize: rem + "px",
						fontWeight: "700",
					},
				},
			],
		},
	};

	return (
		<PDFViewer height={1200}>
			<Document
				title={`Physical Entry Order ${String(PhysicalPDF_TestData.order.id)}`}
				author={assetsConfig.companyNameShort}
				keywords={assetsConfig.companyNameShort + ", Physical Entry PDF"}
			>
				<Page size="A4" style={styles.page}>
					<View style={styles.pageBorderTop} />
					<View
						style={{
							...styles.flexRow,
							...styles.justifyBetween,
							...styles.wFull,
							...styles.itemsCenter,
							...styles.section,
							...styles.mbHalf,
						}}
					>
						<Image
							style={{ ...styles.logo }}
							src={config.assets.logos.localPng}
						/>
						<Text
							style={{ ...styles.headingText, color: theme.colorCopyDarkDark }}
						>
							{props.pageTitle}
						</Text>
					</View>
					<View style={styles.divider} />

					<ShippingLabel
						order={PhysicalPDF_TestData.order}
						instructions={{
							label: PhysicalPDF_TestData.shippingInstructions.label,
							sections: PhysicalPDF_TestData.shippingInstructions.sections,
						}}
						shippingLabel={{
							label: PhysicalPDF_TestData.shippingLabel.label,
							sections: PhysicalPDF_TestData.shippingLabel.sections,
						}}
					/>

					{PhysicalPDF_TestData.order.entries.map((entry) => (
						<View break>
							<EntryManifest pageLabel="Entry Manifest" entry={entry} />
							<EntrantDetails
								pageLabel="Entrant Details"
								details={PhysicalPDF_TestData.company}
							/>
							<AdminSection
								dividerLabelTop={
									PhysicalPDF_TestData.adminSection.dividerLabelTop
								}
								dividerLabelBottom={
									PhysicalPDF_TestData.adminSection.dividerLabelBottom
								}
								entry={entry}
							/>
						</View>
					))}

					{/* <View style={styles.pageBorderBottom}></View> */}
				</Page>
			</Document>
		</PDFViewer>
	);
};

export default PhysicalEntryPDF;

interface EntryItem {
	id: number;
	title: string;
	brand: string;
	ancestry: Ancestor[];
	coverImage: string;
	physicalItemDescription?: string;
}

interface PhysicalEntryOrderSection {
	id: number;
	orderInstructions: string;
	entries: EntryItem[];
}

interface PDFSection {
	label?: string;
	value: string;
	divider?: boolean;
	styles?: {
		[key: string]: string | undefined;
	};
	//   fontSize?: string;
}

interface PhysicalEntryPDFProps {
	pageTitle: string;
	//   id: number;
	//   companyId: number;
	//   companyName: string;
	//   companyAddress1: string;
	//   companyAddress2?: string;
	//   companyCity: string;
	//   companyCountry: string;
	//   companyPostalCode: string;
	//   companyState: string;
	//   entryContactName: string;
	//   entryContactEmail: string;
	//   entryContactPhone: string;
}

interface EntryManifestProps {
	pageLabel: string;
	entry: EntryItem;
}

interface ShippingLabelProps {
	order: PhysicalEntryOrderSection;
	instructions: {
		label: string;
		sections: PDFSection[];
	};
	shippingLabel: {
		label: string;
		sections: PDFSection[];
	};
}

interface EntrantDetailsProps {
	pageLabel: string;
	details: {
		companyId: number;
		companyName: string;
		companyAddress1: string;
		companyAddress2?: string;
		companyCity: string;
		companyCountry: string;
		companyPostalCode: string;
		companyState: string;

		entryContactName: string;
		entryContactEmail: string;
		entryContactPhone: string;
	};
}

interface AdminSectionProps {
	entry: EntryItem;
	dividerLabelTop: string;
	dividerLabelBottom: string;
}
