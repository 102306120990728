import styled, { useTheme, css } from "styled-components";
import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import Sort from "../../../components/Sort/Sort";
import TextField from "../../../components/FormFields/TextField";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import Button from "../../../components/Button/Button";
import axios from "axios";
import { urlUserAdmin, urlEntries, urlJurorAdmin } from "../../../endpoints";
import { Ancestor } from "../Program/ProgramInterfaces";
import {
  PaymentStatus,
  PaymentStatusOptions,
  ReviewStatus,
  ReviewStatusOptions,
} from "../../Checkout/OrderInterfaces";
import { ExecutionCardModel } from "../../../components/CheckoutEntryCard/CheckoutEntryCard";
import { getHierarchy } from "../../Checkout/manageCheckout";
import { getThumbnailSrc } from "../../MediaLibrary/manageMediaLibrary";
import { useHistory } from "react-router-dom";
import Pagination from "../../../utils/Pagination";
import StyledForm from "../../../components/Form/Form";
import { changePaymentStatus } from "../../Entries/manageEntry";
import Placeholder from "../../../assets/placeholders/placeholder.png";
import { CSVLink } from "react-csv";
import { AdminPageControls, SearchFieldsContainer } from "./AdminSearch";
import Table, {
  SortDirection,
  TablePlaceholder,
} from "../../../components/Table/Table";
import { CompanyTypeOptions } from "../Program/ProgramInterfaces";
import { CountryOptions } from "../../../data/CountryData";
import { JobTitleOptions } from "../../../data/JobTitleData";
import dateTostring from "../../../utils/dateToString";
import {
  getStoredValue,
  setStoredValue,
} from "../../../components/LocalStorageStorage/LocalStorageStore";
import MultiDropdown from "../../../components/MultiDropdown/MultiDropdown";
import Loading from "../../../components/Loading/Loading";
import CheckboxField from "../../../components/FormFields/CheckboxField";
import config from "../../../config";
import { useSeasons } from "../../../hooks/useSeasons";
import { useProgramAwards } from "../../../hooks/useProgramAwards";
import {
  DropdownOptions,
  useAwardableAwards,
} from "../../../hooks/useAwardableAwards";
import { ReportedGenderOptions } from "../../../data/ReportedGenderData";
import { RaceAndEthnicityOptions } from "../../../data/RaceAndEthnicityData";
import DropdownField from "../../../components/FormFields/DropdownField";
import { JURY_ROLES_OPTIONS } from "../Judging/JudgingConfig/JuryPoolTab";

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colorActivation};
`;

export const CsvExport = styled(CSVLink)<{ disabled?: boolean }>`
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `};
`;

const initialSearch = {
  userId: undefined,
  fullName: undefined,
  juryName: undefined,
  email: undefined,
  jobTitle: undefined,
  juryAward: undefined,
  reportedGender: undefined,
  preferredJobTitle: undefined,
  jurySeason: undefined,
  raceAndEthnicity: undefined,
  jurorRole: undefined,
  exclusion: undefined,
  companyCountry: undefined,
  companyName: undefined,
};

const getScrubbedRequest = (
  request: JurorSearchRequest
): JurorSearchRequest => {
  return {
    userId: request.userId ? request.userId : undefined,
    fullName: request.fullName ? request.fullName : undefined,
    juryName: request.juryName ? request.juryName : undefined,
    email: request.email ? request.email : undefined,
    jobTitle: request.jobTitle ? request.jobTitle : undefined,
    juryAward: request.juryAward ? request.juryAward : undefined,
    reportedGender: request.reportedGender ? request.reportedGender : undefined,
    preferredJobTitle: request.preferredJobTitle
      ? request.preferredJobTitle
      : undefined,
    jurySeason: request.jurySeason ? request.jurySeason : undefined,
    raceAndEthnicity: request.raceAndEthnicity
      ? request.raceAndEthnicity
      : undefined,
    jurorRole: request.jurorRole ? request.jurorRole : undefined,
    companyCountry: request.companyCountry ? request.companyCountry : undefined,
    companyName: request.companyName ? request.companyName : undefined,
    exclusion: request.exclusion ? JSON.parse(request.exclusion) : undefined,
  };
};

const JurorsSearchTab = () => {
  const [searchVal, setSearchVal] = useState<JurorSearchRequest>(
    getStoredValue("jurorSearch") || initialSearch
  );
  const [searchResult, setSearchResult] = useState<JurorSearchResult[]>([]);
  const [isActive, setActive] = useState(false);
  const [page, setPage] = useState(1);
  const [totalAmountOfPages, settotalAmountOfPages] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);
  const [errors, setErrors] = useState<string[]>([]);
  const history = useHistory();
  const [csvEntries, setCsvUsers] = useState<any[]>([]);
  const [csvLoading, setCsvLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeSort, setActiveSort] = useState<string | null>(null);
  const [activeSortDirection, setActiveSortDirection] = useState<SortDirection>(
    SortDirection.Asc
  );
  const seasonOptions = useSeasons();
  const programAwards = useProgramAwards();
  const exclusionDropdowns: DropdownOptions[] = [
    { label: "Excluded", value: true },
    { label: "Not Excluded", value: false },
  ];

  const searchJurors = async (
    request: JurorSearchRequest,
    activeSort?: number,
    sortDirection?: SortDirection
  ) => {
    const newRequest = getScrubbedRequest(request);
    const sortField =
      activeSort !== undefined && activeSort !== null ? activeSort : undefined;
    // console.log("test", sortField, sortDirection);
    const response = await axios.post(`${urlJurorAdmin}/search`, newRequest, {
      params: { page, recordsPerPage, sortField, sortDirection },
    });

    if (response.status === 200) {
      setSearchResult([]); // This cleans the results and forces a React re-render in cases of sorting.
      setSearchResult(response.data);
      const total = parseInt(response.headers["totalamountofrecords"]);
      setTotalRecords(total);
      settotalAmountOfPages(Math.ceil(total / recordsPerPage));
    }
  };

  async function PostSearch(request: JurorSearchRequest) {
    try {
      const newRequest = getScrubbedRequest(request);
      setErrors([]);
      setActive(false);

      const response = await axios.post(`${urlJurorAdmin}/search`, newRequest, {
        params: { page, recordsPerPage },
      });

      if (response.status === 200) {
        setSearchResult(response.data);
        const total = parseInt(response.headers["totalamountofrecords"]);
        setTotalRecords(total);
        settotalAmountOfPages(Math.ceil(total / recordsPerPage));
        setActive(true);
        setIsLoading(false);
      }

      setCsvLoading(true);

      const getJurorCSV = await axios.post(
        `${urlJurorAdmin}/search/export`,
        newRequest
      );

      if (getJurorCSV.status === 200) {
        setCsvLoading(false); // enable export button after csv entries are retrieved
        setCsvUsers(getJurorCSV.data);
      }
    } catch (error: any) {
      // console.log(error);
      //setErrors(error.response.data);
      setIsLoading(false);
    }
  }

  const fieldRequired = "This field is required";
  const formikProps = useFormik({
    initialValues: searchVal,
    onSubmit: async (value) => {
      setStoredValue("jurorSearch", getScrubbedRequest(value));
      setPage(1);
      await PostSearch(value);
      // console.log(value);
    },
  });

  useEffect(() => {
    if (searchResult.length > 1) {
      searchJurors(
        formikProps.values,
        activeSort !== null
          ? JurorSearchSortOptions[
              activeSort as keyof typeof JurorSearchSortOptions
            ]
          : undefined,
        activeSort !== null ? activeSortDirection : undefined
      );
    }
  }, [page, recordsPerPage]);

  useEffect(() => {
    if (activeSort !== null) {
      searchJurors(
        formikProps.values,
        JurorSearchSortOptions[
          activeSort as keyof typeof JurorSearchSortOptions
        ],
        activeSortDirection
      );
    }
  }, [activeSort, activeSortDirection]);

  return (
    <>
      <FormikProvider value={formikProps}>
        <SearchFieldsContainer col={3}>
          <TextField
            name="userId"
            placeholder="User Id"
            value={formikProps.values.userId}
          />
          <TextField
            name="fullName"
            placeholder="Full Name"
            value={formikProps.values.fullName}
          />
          <TextField
            name="juryName"
            placeholder="Jury Name"
            value={formikProps.values.juryName}
          />
          <TextField
            name="email"
            placeholder="Email"
            value={formikProps.values.email}
          />
          <MultiDropdown
            placeholder="Job Title"
            options={JobTitleOptions}
            selectedValues={(formikProps.values.jobTitle || []).map(
              (label: string) => ({
                label,
                value: label,
              })
            )}
            onChange={(selectedList) => {
              formikProps.setFieldValue(
                "jobTitle",
                selectedList.map((x) => x.label)
              );
            }}
          />
          <MultiDropdown
            placeholder="Jury Award"
            options={programAwards}
            selectedValues={
              Array.isArray(formikProps.values.juryAward)
                ? formikProps.values.juryAward.flatMap((value: number) => {
                    const correspondingOption = programAwards.find(
                      (option: DropdownOptions) => option.value === value
                    );
                    return correspondingOption ? [correspondingOption] : [];
                  })
                : []
            }
            onChange={(selectedList) => {
              formikProps.setFieldValue(
                "juryAward",
                selectedList.map((x) => x.value)
              );
            }}
          />
          <DropdownField
            name="reportedGender"
            placeholder="Reported Gender"
            options={ReportedGenderOptions}
            value={formikProps.values.reportedGender}
          />
          <TextField
            name="preferredJobTitle"
            placeholder="Preferred Job Title"
            value={formikProps.values.preferredJobTitle}
          />
          <MultiDropdown
            placeholder="Jury Season"
            options={seasonOptions}
            selectedValues={
              Array.isArray(formikProps.values.jurySeason)
                ? formikProps.values.jurySeason.flatMap((value: number) => {
                    const correspondingOption = seasonOptions.find(
                      (option: DropdownOptions) => option.value === value
                    );
                    return correspondingOption ? [correspondingOption] : [];
                  })
                : []
            }
            onChange={(selectedList) => {
              formikProps.setFieldValue(
                "jurySeason",
                selectedList.map((x) => x.value)
              );
            }}
          />
          <DropdownField
            name="raceAndEthnicity"
            placeholder="Race and Ethnicity"
            options={RaceAndEthnicityOptions}
            value={formikProps.values.raceAndEthnicity}
          />

          <TextField
            name="companyName"
            placeholder="Company Name"
            value={formikProps.values.companyName}
          />

          <DropdownField
            name="jurorRole"
            placeholder="Juror Role"
            options={JURY_ROLES_OPTIONS}
            value={formikProps.values.jurorRole}
          />

          <MultiDropdown
            placeholder="Company Country"
            options={CountryOptions}
            selectedValues={(formikProps.values.companyCountry || []).map(
              (label) => ({
                label,
                value: label,
              })
            )}
            onChange={(selectedList) => {
              formikProps.setFieldValue(
                "companyCountry",
                selectedList.map((x) => x.label)
              );
            }}
          />
          <DropdownField
            name="exclusion"
            placeholder="Exclusion"
            options={exclusionDropdowns}
            value={formikProps.values.exclusion}
          />
        </SearchFieldsContainer>

        <div className="flex my-[1rem] gap-[1rem] justify-end flex-wrap">
          <Button
            className="button-light w-[150px]"
            type="button"
            onClick={() => {
              formikProps.setValues(initialSearch);
              setSearchVal(initialSearch);
              setSearchResult([]);
              setActive(false);
              setStoredValue("jurorSearch", initialSearch);
              setActiveSort(null);
              setActiveSortDirection(SortDirection.Asc);
              setPage(1);
            }}
          >
            Reset Search
          </Button>

          <CsvExport
            className="no-underline"
            data={csvEntries}
            // headers={csvHeaders}
            filename={`Juror Search Export ${dateTostring(new Date())}.csv`}
            disabled={csvEntries.length < 1}
          >
            <Button
              className="button-light w-[150px]"
              disabled={csvEntries.length < 1}
            >
              <div className="flex items-center gap-[.5rem]">
                Export
                {csvLoading && (
                  <img
                    className="w-[16px]"
                    src={config.assets.loading.primary}
                  />
                )}
              </div>
            </Button>
          </CsvExport>

          <Button
            className="w-[150px]"
            type="submit"
            onClick={() => {
              setIsLoading(true);
              formikProps.submitForm();
            }}
          >
            Search
          </Button>
        </div>
      </FormikProvider>

      {/* top pagination controls */}
      {isActive && (
        <AdminPageControls
          totalRecords={totalRecords}
          totalAmountOfPages={totalAmountOfPages}
          currentPage={page}
          setPage={(newPage) => setPage(newPage)}
          setIsLoading={(isLoading) => setIsLoading(isLoading)}
        />
      )}

      {!isLoading &&
        (searchResult && searchResult.length > 0 ? (
          <Table
            isActive={isActive}
            dualScroll
            // generate string[] from enum
            columnLabels={Object.keys(JurorSearchSortOptions)
              .map((field) => field)
              .splice(
                Object.keys(JurorSearchSortOptions).length / 2,
                Object.keys(JurorSearchSortOptions).length - 1
              )}
            onClickSort={(sortLabel, sortDirection) => {
              setActiveSort(sortLabel);
              setActiveSortDirection(sortDirection);
            }}
            sortTriangleSize="14px"
            {...(activeSort !== null && {
              activeSort: {
                label: activeSort,
                direction: activeSortDirection,
              },
            })}
          >
            {searchResult.map((juror: JurorSearchResult) => {
              return (
                <Fragment key={juror.fullName}>
                  <div className="cell">
                    <p>
                      <StyledLink
                        href={`/edit-user/${juror.userId}`}
                        target="_blank"
                      >
                        {juror.fullName || ""}
                      </StyledLink>
                    </p>
                  </div>
                  <div className="cell">
                    <p>{juror.companyName || "N/A"}</p>
                  </div>
                  <div className="cell">
                    <p>{juror.jobTitle || ""}</p>
                  </div>
                  <div className="cell">
                    <p>{juror.email || ""}</p>
                  </div>
                  <div className="cell">
                    <p>{juror.award || ""}</p>
                  </div>
                  <div className="cell">
                    <p>{juror.season || ""}</p>
                  </div>
                  <div className="cell">
                    <p>{juror.juryName || ""}</p>
                  </div>
                  <div className="cell">
                    <p>{juror.jurorRole || ""}</p>
                  </div>
                  <div className="cell">
                    <p>{juror.isExcluded || ""}</p>
                  </div>
                </Fragment>
              );
            })}
          </Table>
        ) : (
          <TablePlaceholder active={isActive}>
            Please add a search to see result table.
          </TablePlaceholder>
        ))}

      {isLoading && (
        <TablePlaceholder active={false}>
          <Loading fullScreen={false} showLogo={false} />
        </TablePlaceholder>
      )}

      {/* bottom pagination controls */}
      {isActive && (
        <AdminPageControls
          totalRecords={totalRecords}
          totalAmountOfPages={totalAmountOfPages}
          currentPage={page}
          setPage={(newPage) => setPage(newPage)}
          setIsLoading={(isLoading) => setIsLoading(isLoading)}
        />
      )}
    </>
  );
};

export interface JurorSearchRequest {
  userId?: string;
  fullName?: string;
  juryName?: string;
  email?: string;
  jobTitle?: string[];
  juryAward?: number[];
  reportedGender?: string;
  preferredJobTitle?: string;
  jurySeason?: number[];
  raceAndEthnicity?: string;
  jurorRole?: string;
  exclusion?: string;
  companyCountry?: string[];
  companyName?: string;
}

export interface JurorSearchResult {
  fullName?: string;
  companyName?: string;
  jobTitle?: string;
  email?: string;
  award?: string;
  season?: string;
  juryName?: string;
  jurorRole?: string;
  isExcluded?: string;
  userId?: string;
}

export enum JurorSearchSortOptions {
  "Full Name",
  "Company Name",
  "Job Title",
  "Email",
  "Award",
  "Season",
  "Jury Name",
  "Juror Role",
  "Is Excluded",
}

export default JurorsSearchTab;
