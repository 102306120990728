import styled from "styled-components";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Table, { TablePlaceholder } from "../Table/Table";
import Sort from "../Sort/Sort";
import { getHierarchy } from "../../views/Checkout/manageCheckout";
import { getThumbnailSrc } from "../../views/MediaLibrary/manageMediaLibrary";
import Placeholder from "../../assets/placeholders/placeholder.png";
import {
  PaymentStatus,
  ReviewStatusOptions,
} from "../../views/Checkout/OrderInterfaces";
import Pagination from "../../utils/Pagination";
import { EntrySearchResult } from "../../views/Admin/Search/EntrySearchTab";
import { Link } from "react-router-dom";
import axios from "axios";
import { urlEntries } from "../../endpoints";

const EntryGrid = (props: EntryGridProps) => {
  const StyledDualScroll = styled.div<{ width: number }>`
    .top-scroll,
    .scroll-view {
      overflow-x: auto;
      width: 100%;
      max-height: 500px;
    }
    .top-scroll {
      height: 17px;
    }

    .scroll-div1 {
      width: ${(p) => p.width + "px"};
      overflow-x: auto;
      height: 17px;
    }
  `;
  const [entries, setEntries] = useState<EntrySearchResult[]>([]);
  const [isActive, setActive] = useState(true);
  const [totalAmountOfPages, settotalAmountOfPages] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(
    props.recordsPerPage ?? 10
  );
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getCompanyEntries(Number(props.companyId)).then((response) => {
      if (response?.status === 200) {
        setEntries(response.data);
        const total = parseInt(response.headers["totalamountofrecords"]);
        setTotalRecords(total);
        settotalAmountOfPages(Math.ceil(total / recordsPerPage));
      }
    });
  }, [props.companyId, page]);

  async function getCompanyEntries(companyId: number) {
    return await axios.post(
      `${urlEntries}/search`,
      {
        companyId: companyId,
      },
      {
        params: { page, recordsPerPage },
      }
    );
  }

  const DualScroll = (props: { children: React.ReactNode }) => {
    const topScrollRef = useRef<any>(null);
    const viewScrollRef = useRef<any>(null);

    const table = viewScrollRef.current
      ? viewScrollRef.current.querySelector(".search-table")
      : null;

    useEffect(() => {
      if (table) {
        table.addEventListener("scroll", (e: React.UIEvent<HTMLElement>) => {
          if (topScrollRef.current) {
            topScrollRef.current.scrollTo({
              left: e.currentTarget.scrollLeft,
              behavior: "instant",
            });
          }
        });
      }
    }, [table]);

    return (
      <StyledDualScroll width={table ? table.scrollWidth : table}>
        <div
          className="top-scroll"
          ref={topScrollRef}
          onScroll={(e) => {
            if (table) {
              table.scrollTo({
                left: e.currentTarget.scrollLeft,
                behavior: "instant",
              });
            }
          }}
        >
          <div className="scroll-div1"></div>
        </div>

        <div className="scroll-view max-h-p" ref={viewScrollRef}>
          {props.children}
        </div>
      </StyledDualScroll>
    );
  };

  const StyledLink = styled(Link)`
    color: ${({ theme }) => theme.colorActivation};
  `;

  return (
    <>
      {entries && entries.length > 0 && (
        <Table
          className="search-table"
          columnLabels={[
            "Cover Image",
            "Entry Id",
            "Title",
            "Level 1",
            "Level 2",
            "Level 3",
            "Level 4",
            "Level 5",
            "Brand",
            "Author",
            "Payment Status",
            "Review Status",
            "Execution Count",
            "Award Level",
          ]}
          isActive={isActive}
          dualScroll
        >
          {entries.map((entry: EntrySearchResult, index) => {
            const hierarchy = getHierarchy(
              entry.executions,
              entry.ancestry,
              entry.isCampaign
            );

            return (
              <Fragment key={entry.id}>
                <div className="cell">
                  <div className="img-container">
                    <img
                      src={
                        (entry.coverImage &&
                          getThumbnailSrc(entry.coverImage!)) ||
                        Placeholder
                      }
                      alt="Entry Cover Image"
                    />
                  </div>
                </div>
                <div className="cell">
                  <p>
                    <StyledLink
                      to={`/admin/edit-entry?entryId=${entry.id}`}
                      target="_blank"
                    >
                      {entry.id}
                    </StyledLink>
                  </p>
                </div>
                <div className="cell">
                  <p>
                    <StyledLink
                      to={`/admin/edit-entry?entryId=${entry.id}`}
                      target="_blank"
                    >
                      {entry.title}
                    </StyledLink>
                  </p>
                </div>
                <div className="cell">
                  <p>{hierarchy.at(0)}</p>
                </div>
                <div className="cell">
                  <p>{hierarchy.at(1)}</p>
                </div>
                <div className="cell">
                  <p>{hierarchy.at(2)}</p>
                </div>
                <div className="cell">
                  <p>{hierarchy.at(3)}</p>
                </div>
                <div className="cell">
                  <p>{hierarchy.at(4)}</p>
                </div>
                <div className="cell">
                  <p>{entry.brand}</p>
                </div>
                <div className="cell">
                  <p>{entry.companyName}</p>
                </div>
                <div className="cell">
                  <p>{PaymentStatus[entry.paymentStatusId]}</p>
                </div>
                <div className="cell">
                  <p>{ReviewStatusOptions[entry.reviewStatusId].label}</p>
                </div>
                <div className="cell">
                  <p>{entry.executions && entry.executions.length}</p>
                </div>
                <div className="cell">
                  <p>{entry.awardLevel}</p>
                </div>
              </Fragment>
            );
          })}
        </Table>
      )}

      {isActive && entries.length > 0 && (
        <div className="mb-2 mr-1 flex items-center gap-[2rem] justify-end mt-[1rem]">
          <h3 className="text-center">Total Results: {totalRecords}</h3>
          {totalAmountOfPages > 1 && (
            <Pagination
              currentPage={page}
              totalAmountOfPages={totalAmountOfPages}
              onChange={(newPage) => setPage(newPage)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default EntryGrid;

export interface EntryGridProps {
  companyId: string;
  recordsPerPage?: number;
}
