import styled, { useTheme } from "styled-components";
import { useState } from "react";
import Icon from "../Icon/Icon";

const StyledText = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
  color: ${({ theme }) => theme.colorCopyLight};

  .hover-text {
    display: none;
    position: absolute;
    top: -1.5rem;
    padding: 3px;
    color: ${({ theme }) => theme.colorCopyLightLight};
    background: ${({ theme }) => theme.colorBackgroundMedium};
    font-size: ${({ theme }) => theme.xxSmallSize};
    font-weight: ${({ theme }) => theme.fontDefaultWeight};
    width: fit-content;
    white-space: nowrap;
  }

  .copy-icon {
    position: absolute;
    left: 0;
    opacity: 0;
    transition: all 150ms ease;
  }

  &:hover {
    .hover-text {
      display: flex;
    }

    .copy-icon {
      margin-left: 0.25rem;
      opacity: 1;
    }

    .display-icon {
      right: -0.5rem;
      opacity: 0;
      transition: all 150ms ease;
    }
  }
`;

const ClipboardText = (props: ClipboardTextProps) => {
  const theme = useTheme();
  const [displayText, setDisplayText] = useState(props.toolTip);

  const handleCopyText = (text: string) => {
    navigator.clipboard.writeText(text);
    setDisplayText(props.copiedToolTip);
  };

  return (
    <StyledText
      onMouseEnter={() => setDisplayText(props.toolTip)}
      onClick={() => handleCopyText(props.copyText)}
    >
      <Icon
        className="copy-icon"
        icon="copy"
        color={theme.colorCopyLight}
        width="15px"
        height="15px"
      />
      {props.icon && <span className="display-icon">{props.icon}</span>}
      <p className={`ml-[.5rem] ${props.className ? props.className : ""}`}>
        {props.displayText}
      </p>
      <span className="hover-text">{displayText}</span>
    </StyledText>
  );
};

export default ClipboardText;

interface ClipboardTextProps {
  displayText: string;
  copyText: string;
  toolTip: string;
  copiedToolTip: string;
  className?: string;
  icon?: React.ReactNode;
}
