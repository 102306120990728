import FieldModal from "../../../../components/FieldModal/FieldModal";
import DropdownField from "../../../../components/FormFields/DropdownField";
import { MediaFieldTemplate, ProgramMediaField } from "../ProgramInterfaces";
import TextField from "../../../../components/FormFields/TextField";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { mediaTypeOptions } from "../../../MediaLibrary/mediaLibrary.model.d";
import { fieldRequired } from "../../../../components/FieldModal/FieldModal";
import { fieldTemplateValidation } from "../../../../components/FieldModal/FieldModal";
import { newFieldInitialVal } from "../../../../components/FieldModal/FieldModal";

const MediaFieldForm = (props: CustomFieldProps) => {
  const formikProps = useFormik({
    initialValues: {
      // populate existing field values for update form OR use new values for new field form
      ...(props.formData ? { ...props.formData } : { ...newFieldInitialVal }),
      mediaType: props.formData ? String(props.formData.mediaType) : "",
      minQuantity: props.formData ? props.formData.minQuantity : "",
      maxQuantity: props.formData ? props.formData.maxQuantity : "",
    },
    onSubmit: async (value) => {},
    validationSchema: Yup.object({
      ...fieldTemplateValidation,
      mediaType: Yup.number().typeError(fieldRequired).required(fieldRequired),
      minQuantity: Yup.number()
        .min(0, "Minimum quantity must be greater than or equal to 0")
        .required(fieldRequired),
      maxQuantity: Yup.number()
        .min(
          Yup.ref("minQuantity"),
          "Maximum quantity cannot be less than minimum quantity"
        )
        .required(fieldRequired),
    }),
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <FormikProvider value={formikProps}>
      <FieldModal
        {...(props.formData && {
          formData: props.formData,
        })}
        formTitle="Media Field Template"
        hideModal={() => props.hideModal()}
        formType="media"
        isUpdateForm={props.formData ? true : false}
      >
        <DropdownField
          className="min-w-[50%]"
          placeholder="Select Media Type"
          name="mediaType"
          options={mediaTypeOptions}
          value={formikProps.values.mediaType}
          {...(props.formData &&
            props.formData.mediaType !== undefined && {
              readOnly: true,
            })}
        />
        <TextField
          className="min-w-[50%]"
          placeholder="Minimum Number of Files"
          name="minQuantity"
          type="number"
          value={formikProps.values.minQuantity}
        />
        <TextField
          className="min-w-[50%]"
          placeholder="Maximum Number of Files"
          name="maxQuantity"
          type="number"
          value={formikProps.values.maxQuantity}
        />
      </FieldModal>
    </FormikProvider>
  );
};

export default MediaFieldForm;

interface CustomFieldProps {
  formData?: MediaFieldTemplate | ProgramMediaField;
  hideModal: () => void;
}
