import FieldModal from "../../../../components/FieldModal/FieldModal";
import TextField from "../../../../components/FormFields/TextField";
import DropdownField from "../../../../components/FormFields/DropdownField";
import { ProgramTextField, TextFieldTemplate } from "../ProgramInterfaces";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import ToggleSwitchField from "../../../../components/FormFields/ToggleSwitchField";
import { textTypeOptions } from "../ProgramInterfaces";
import { fieldRequired } from "../../../../components/FieldModal/FieldModal";
import { fieldTemplateValidation } from "../../../../components/FieldModal/FieldModal";
import { newFieldInitialVal } from "../../../../components/FieldModal/FieldModal";

const TextFieldForm = (props: CustomFieldProps) => {
  const formikProps = useFormik({
    initialValues: {
      // populate existing field values for update form OR use new values for new field form
      ...(props.formData ? { ...props.formData } : { ...newFieldInitialVal }),
      textType: props.formData ? String(props.formData.textType) : "",
      maxLength: props.formData ? props.formData.maxLength : "",
      formatText: props.formData ? props.formData.formatText : false,
      isRequired: props.formData ? props.formData.isRequired : false,
    },
    onSubmit: async (value) => {},
    validationSchema: Yup.object({
      ...fieldTemplateValidation,
      textType: Yup.number().typeError(fieldRequired).required(fieldRequired),
      maxLength: Yup.number()
        .required(fieldRequired)
        .min(0, "Text field max length must be greater than or equal to 0"),
    }),
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <FormikProvider value={formikProps}>
      <FieldModal
        {...(props.formData && {
          formData: props.formData,
        })}
        formTitle="Text Field Template"
        hideModal={() => props.hideModal()}
        formType="text"
        isUpdateForm={props.formData ? true : false}
      >
        <DropdownField
          className="min-w-[50%]"
          placeholder="Select Text Type"
          name="textType"
          options={textTypeOptions}
          value={formikProps.values.textType}
        />
        <TextField
          type="number"
          name="maxLength"
          placeholder="Text Field Max Length"
          value={formikProps.values.maxLength}
        />
        <div>
          <p className="mb-[1rem]">Allow Entrant to Format Text</p>
          <ToggleSwitchField
            id="formatText"
            name="formatText"
            checked={formikProps.values.formatText}
          />
        </div>
        <div>
          <p className="mb-[1rem]">Is Required?</p>
          <ToggleSwitchField
            id="isRequired"
            name="isRequired"
            checked={formikProps.values.isRequired}
          />
        </div>
      </FieldModal>
    </FormikProvider>
  );
};

export default TextFieldForm;

interface CustomFieldProps {
  formData?: TextFieldTemplate | ProgramTextField;
  hideModal: () => void;
}
