import { ProgramModel } from "./ProgramInterfaces";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import Button from "../../../components/Button/Button";
import {
  CardHeader,
  CardBody,
  CardContainer,
} from "../../../components/DashboardCard/DashboardCard";
import styled, { useTheme, css, keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import dateTostring from "../../../utils/dateToString";
import { getProgramById, getProgramSetupCardById } from "./manageProgram";
import { useHistory, useParams } from "react-router-dom";
import { DynamicTransitionDuration } from "../../../globalStyles";
import { NavLinkProps } from "../../../components/Breadcrumbs/Breadcrumbs";
import Sort, { SortContainer } from "../../../components/Sort/Sort";
import Icon from "../../../components/Icon/Icon";
import { ProgramSetupCard } from "./ProgramRootAdminNav";

const ExpandLevelContainer = styled.div<{
  childrenCount: number;
  isExpanded: boolean;
}>`
  position: relative;
  ${(p) => p.childrenCount && `height: calc(${p.childrenCount} * 112px);`};
`;

export const ProgramHierarchyContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 19px;
  background: ${({ theme }) => theme.colorBackgroundLight};
  box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
  /* margin-bottom: 30px; */
  max-height: 112px;
  /* max-width: 100%; */
  /* transition: max-height ${DynamicTransitionDuration} ease; */

  .program-title {
    width: 20%;
  }

  .sublevel-title {
    width: 38%;
  }

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .active {
    color: ${({ theme }) => theme.colorActivation};
    font-weight: bold;
  }

  .inactive {
    color: ${({ theme }) => theme.colorCopyLight};
  }
`;

const expandAnimation = (order: number) => keyframes`
  from {
    top: 112px;
  }

  to {
    top: calc(${order} * 112px);
  }
`;

const LevelContainer = styled.div<{
  childrenCount?: number;
  isExpanded: boolean;
  order: number;
  expandAnimation: any;
}>`
  position: absolute;
  width: 100%;

  &.level-0 {
    padding-left: 0 !important;

    .vertical-branch,
    .horizontal-branch {
      display: none !important;
    }
  }

  // add sub-hierarchy tree branches
  .horizontal-branch {
    display: none;
    content: "";
    position: absolute;
    left: 0;
    top: 41px;
    width: 3.09375rem;
    border-top: 1px dashed ${({ theme }) => theme.colorCopyLight};
  }

  .vertical-branch {
    display: none;
    content: "";
    position: absolute;
    top: -30px;
    left: 0;
    height: calc(82px + 28px);
    border-left: 1px dashed ${({ theme }) => theme.colorCopyLight};
  }

  &:not(.order-0) {
    padding-left: 3.09375rem;

    .vertical-branch,
    .horizontal-branch {
      display: block;
    }
  }

  ${(p) =>
    p.order &&
    css`
      // adjust animation duration according to hierarchy order
      animation: ${p.expandAnimation(p.order)} ease forwards;
      animation-duration: ${`calc(150ms * ${p.order > 8 ? 8 : p.order} )}`};
    `};
`;

// container for program hierarchies, can be expanded/collapsed
const Level = (props: LevelProps) => {
  return (
    <LevelContainer
      className={`level ${props.isExpanded ? "expanded" : ""} ${
        props.className
      }`}
      childrenCount={props.childrenCount && props.childrenCount}
      isExpanded={props.isExpanded}
      order={props.order}
      expandAnimation={expandAnimation}
    >
      {props.children}
    </LevelContainer>
  );
};

const ProgramHierarchy = (props: ProgramHierarchyProps) => {
  const theme = useTheme();
  const handleProgramExpand = () => {
    props.onClick();
  };

  return (
    <ProgramHierarchyContainer
      className={`hierarchy-card ${props.className ? props.className : ""}`}
    >
      <div className="w-[20%]">
        <Button
          className="button-light !min-w-0 max-w-full"
          iconLeft="edit"
          to={`/program/configure/${props.id}`}
        >
          Edit
        </Button>
      </div>

      <p
        className={`${props.level === 0 ? "program-title" : "sublevel-title"}`}
      >
        {props.name}
      </p>

      {props.season && props.level === 0 && (
        <p className="w-[10%]">{props.season.name}</p>
      )}
      {props.award && props.level === 0 && (
        <p className="w-[10%]">{props.award.name}</p>
      )}
      <p className={`${props.level === 0 ? "w-[15%]" : "w-[16%]"}`}>
        {props.entriesOpenDate && dateTostring(new Date(props.entriesOpenDate))}
      </p>

      {props.hasChildren && (
        <div
          className="flex justify-end items-center cursor-pointer ml-auto"
          onClick={handleProgramExpand}
        >
          <p>{props.childLevelDisplayLabel}</p>
          <Icon
            icon="caret"
            className={`ml-[.5rem] ${props.level > 0 && "rotate-[270deg]"}`}
            color={theme.colorCopyLight}
          />
        </div>
      )}
    </ProgramHierarchyContainer>
  );
};

export default function ProgramHierarchyNav() {
  const { id } = useParams<{ id: string }>();
  const [ancestorPath, setAncestorPath] = useState("/program/program-setup");
  const programHistory = useHistory();
  const theme = useTheme();
  const [breadcrumbs, setBreadcrumbs] = useState<NavLinkProps[]>([
    { label: "Programs", value: `/program/program-setup` },
  ]);
  const [program, setProgram] = useState<ProgramSetupCard>({
    id: 0,
    childLevelDisplayLabel: "",
    hasChildren: false,
    name: "",
  });

  useEffect(() => {
    getProgramSetupCardById(id)
      .then((response) => {
        // console.log("getProgramById", response);
        response.data.entriesOpenDate = new Date(response.data.entriesOpenDate);
        setProgram(response.data);
      })
      .catch((error) => {
        // console.log("getProgramById ERROR", error);
        // setErrors((old) => [...old, error]);
      });
  }, [id]);

  //update breadcrumbs when program is updated
  useEffect(() => {
    const navLinks: NavLinkProps[] = [
      { label: "Programs", value: `/program/program-setup` },
    ];

    if (program.ancestry && program.ancestry.length > 0) {
      program.ancestry.forEach((ancestor) => {
        const navLink = {
          label: ancestor.name,
          value: `/program/program-setup/${ancestor.id}`,
        };
        navLinks.push(navLink);
      });
      // if we're at a depth more than 1 (non-root program)
      if (navLinks.length > 1) {
        // sets the ancestorPath to one above current program's level
        setAncestorPath(navLinks.at(-2)!.value!.toString());
      }
      // add ancestry to breadcrumbs
      setBreadcrumbs(navLinks);
    }
  }, [program]);

  const handleClickBreadcrumb = (
    breadcrumb: {
      label: string;
      value?: string | number;
    },
    index: number
  ) => {
    breadcrumb.value?.toString() &&
      programHistory.push(breadcrumb.value?.toString());
  };

  const handleEditClick = (id: number) => {
    let path = `/program/configure/${id}`;
    programHistory.push(path);
  };

  const handleExpandClick = (id: number) => {
    let path = `/program/program-setup/${id}`;
    programHistory.push(path);
  };

  return (
    <CardContainer>
      <CardHeader>
        <h2 className="card-title">Program Setup</h2>

        <Breadcrumbs navLinks={breadcrumbs} onClick={handleClickBreadcrumb} />
      </CardHeader>
      <CardBody className="flex flex-col">
        <div className="flex gap-[1.28125rem] mb-[1rem] w-full justify-end">
          <Button
            iconLeft="plus"
            iconColor={theme.colorCopyLightLight}
            to="/program/new"
          >
            Add Program
          </Button>
        </div>

        <SortContainer className="invisible">
          <Sort className="ml-[20%]" width="20%" label="Program Name" />
          <Sort width="10%" label="Season" />
          <Sort width="10%" label="Award" />
          <Sort width="15%" label="Entries open Date" />
        </SortContainer>

        <ExpandLevelContainer
          childrenCount={
            ((program.hasChildren && program.children?.length) || 0) + 1
          }
          isExpanded={true}
        >
          {/* parent */}
          <ProgramHierarchy
            {...program}
            level={0}
            onClick={() => programHistory.push(ancestorPath)}
            isExpanded={true}
            showProgramConfig={handleEditClick}
          />

          {program.hasChildren &&
            program.children?.map((child, i) => {
              return (
                <Level
                  className={`level-1 order-${i + 1}`}
                  childrenCount={
                    (child.hasChildren && child.children?.length) || 0
                  }
                  isExpanded={false}
                  order={i + 1}
                  key={child.id?.toString()}
                >
                  <span className="vertical-branch" />
                  <span className="horizontal-branch" />
                  <ProgramHierarchy
                    {...child}
                    level={1}
                    onClick={() => handleExpandClick(child.id!)}
                    isExpanded={false}
                    showProgramConfig={handleEditClick}
                  />
                </Level>
              );
            })}
        </ExpandLevelContainer>
      </CardBody>
    </CardContainer>
  );
}

export {};

interface LevelProps {
  className?: string;
  children: React.ReactNode;
  childrenCount?: number;
  isExpanded: boolean; // full max height, depending on # of children
  order: number;
}

interface ProgramHierarchyProps extends ProgramSetupCard {
  className?: string;
  onClick: () => void;
  level: number;
  showProgramConfig: (id: number) => void;
  isExpanded: boolean; // passed down from <Level>
}
