import { useState, useEffect } from "react";
import ProgramCard from "../../../components/ProgramCard/ProgramCard";
// import { ProgramTestData } from "./ProgramTestData";
import styled, { useTheme } from "styled-components";
// import DragNDrop from "../../../components/DragNDrop/DragNDrop";
import GridLayout, { Responsive, WidthProvider } from "react-grid-layout";
import Button from "../../../components/Button/Button";
import { CSSTransition } from "react-transition-group";
import { ProgramDescription } from "../../../components/ProgramCard/ProgramCard";
import { ProgramCardProps } from "../../../components/ProgramCard/ProgramCard";
import { DynamicTransitionDuration } from "../../../globalStyles";
import { useParams } from "react-router-dom";
import TextField from "../../../components/FormFields/TextField";
import RichTextField from "../../../components/FormFields/RichTextField";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import ToggleSwitchField from "../../../components/FormFields/ToggleSwitchField";
import FileUpload from "../../../components/FormFields/FileUpload";
import * as AiIcons from "react-icons/ai";
import DashboardCard, {
	CardBody,
	CardContainer,
	CardHeader,
} from "../../../components/DashboardCard/DashboardCard";
import InfoPopup from "../../../components/InfoPopup/InfoPopup";
import Alert from "../../../components/Alert/Alerts";
import {
	getProgramDisplays,
	getProgramDisplayById,
} from "./manageProgramDisplays";
import { ProgramDisplay } from "./ProgramInterfaces";
import { ProgramModel } from "./ProgramInterfaces";
import { getProgramById } from "./manageProgram";
import ProgramDisplayForm from "./ProgramDisplayForm";

const NewProgramDisplay = () => {
	return <ProgramDisplayForm metaDescription={`New Program Card`} />;
};

export default NewProgramDisplay;
