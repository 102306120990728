import { createContext, useContext, useState } from "react";
import {
	CompanyCredit,
	IndividualCredit,
} from "../../views/Admin/Credits/CreditInterfaces";
import { MediaItem } from "../../views/MediaLibrary/mediaLibrary.model.d";
import styled from "styled-components";
import { EntrySetConfig } from "../../views/Admin/Judging/JudgingInterfaces";
import {
	CompanyCreditJoin,
	IndividualCreditJoin,
} from "../../views/Admin/Program/ProgramInterfaces";

export const SlidingCardContext = createContext<any | null>(null);

const SlidingCard = styled.div`
	&.moveDown {
		transition: transform 150ms ease;
		transform: translateY(75px);
	}

	&.moveUp {
		transition: transform 150ms ease;
		transform: translateY(-75px);
	}
`;

export const AnimationTracker = (props: AnimationTrackerProps) => {
	const defaultAnimation = {
		clickedCreditIndex: null,
		direction: null,
	};
	const [swapAnimation, setSwapAnimation] =
		useState<SwapAnimation>(defaultAnimation);

	return (
		<SlidingCardContext.Provider value={{ swapAnimation, setSwapAnimation }}>
			{props.children}
		</SlidingCardContext.Provider>
	);
};

interface AnimationTrackerProps {
	children: any;
}

const AnimateReorder = (props: AnimateReorderProps) => {
	const defaultAnimation = {
		clickedCreditIndex: null,
		direction: null,
	};
	//   const { swapAnimation, setSwapAnimation } =
	//     useContext(SlidingCardContext);
	const ctx = useContext(SlidingCardContext);

	const swapAnimation = ctx ? ctx.swapAnimation : undefined;
	const setSwapAnimation = ctx ? ctx.setSwapAnimation : undefined;

	// get re-ordering animation (up or down) depending on position/direction
	const getAnimation = (currentIndex: number) => {
		if (
			swapAnimation &&
			swapAnimation.direction !== null &&
			swapAnimation.direction !== undefined &&
			swapAnimation.clickedCreditIndex !== null &&
			swapAnimation.clickedCreditIndex !== undefined
		) {
			if (swapAnimation.direction === Direction.Up) {
				// up direction is clicked
				if (
					currentIndex === swapAnimation.clickedCreditIndex &&
					currentIndex !== 0
				) {
					return "moveUp";
				} else if (currentIndex === swapAnimation.clickedCreditIndex - 1)
					return "moveDown";
			} else {
				// down direction is clicked
				if (
					currentIndex === swapAnimation.clickedCreditIndex &&
					currentIndex < props.arr.length - 1
				) {
					return "moveDown";
				} else if (currentIndex === swapAnimation.clickedCreditIndex + 1) {
					return "moveUp";
				}
			}
		} else return "";
	};

	const reOrderCredit = (clickedCreditIndex: number, direction: Direction) => {
		const copy = [...props.arr];
		if (direction === Direction.Up) {
			// move credit up
			if (clickedCreditIndex !== 0) {
				[copy[clickedCreditIndex - 1], copy[clickedCreditIndex]] = [
					{
						...copy[clickedCreditIndex],
						weight: clickedCreditIndex - 1,
					},
					{
						...copy[clickedCreditIndex - 1],
						weight: clickedCreditIndex,
					},
				];
			}
		} else {
			// move credit down
			if (clickedCreditIndex !== props.arr.length - 1) {
				[copy[clickedCreditIndex], copy[clickedCreditIndex + 1]] = [
					{
						...copy[clickedCreditIndex + 1],
						weight: clickedCreditIndex,
					},
					{
						...copy[clickedCreditIndex],
						weight: clickedCreditIndex + 1,
					},
				];
			}
		}

		// set delay for swap animation
		setTimeout(() => {
			props.setState(copy);
			setSwapAnimation && setSwapAnimation(defaultAnimation);
		}, 200);
	};

	return (
		<SlidingCard className={getAnimation(props.index)}>
			{props.children(reOrderCredit)}
		</SlidingCard>
	);
};
export default AnimateReorder;

interface AnimateReorderProps {
	//   children: React.ReactElement[];
	children: any;
	arr:
		| CompanyCreditJoin[]
		| IndividualCreditJoin[]
		| MediaItem[]
		| EntrySetConfig[];
	setState: (arr: any) => void;
	index: number;
}

export enum Direction {
	"Up",
	"Down",
}

export interface SwapAnimation {
	clickedCreditIndex: null | number;
	direction: null | Direction;
}
