import React, { useEffect, useState } from "react";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	CartesianGrid,
	TooltipProps,
	ResponsiveContainer,
} from "recharts";
import { ReviewStatus } from "../../views/Checkout/OrderInterfaces";
import {
	LabelAndCount,
	ReviewStatusesAndAmount,
	ReviewStatusesAndTotal,
} from "./manageReports";
import { graphColors } from "../../assetsConfig";

interface BarChartComponentProps {
	labelsAndCounts?: LabelAndCount[];
	data?: ReviewStatusesAndTotal[];
	reviewStatusesAndAmount?: ReviewStatusesAndAmount[];
}

const ReportingGraphs: React.FC<BarChartComponentProps> = ({
	labelsAndCounts,
	data,
	reviewStatusesAndAmount,
}): JSX.Element => {
	const CustomizXAxisTick: React.FC<TickItemProps> = ({ x, y, payload }) => {
		const isActive = payload?.value === activeBar;
		const classes = isActive ? "font-bold" : "";
		const words = payload?.value.split(" ");
		return (
			<g transform={`translate(${x},${y})`}>
				{words?.map((word: string, index: number) => (
					<text
						x={0}
						y={index * 16}
						dy={10}
						textAnchor="middle"
						fill={graphColors.text}
						fontSize={13}
						className={classes}
					>
						{word}
					</text>
				))}
			</g>
		);
	};

	const [activeBar, setActiveBar] = useState<string | null>(null);
	const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

	const CustomizedYAxisTick: React.FC<TickItemProps> = ({ x, y, payload }) => {
		const isActive = payload?.value === activeBar;
		const classes = isActive ? "font-bold" : "";
		const width = 350;
		return (
			<g transform={`translate(${x},${y})`}>
				<foreignObject x={-width} y={-15} width={width - 10} height={30}>
					<div
						style={{
							textAlign: "right",
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
							fontSize: 13,
							lineHeight: "30px",
						}}
						className={classes}
					>
						{payload?.value}
					</div>
				</foreignObject>
			</g>
		);
	};

	const CustomYTooltip = ({
		active,
		payload,
	}: {
		active?: boolean;
		payload?: any[];
	}) => {
		if (active && payload && payload.length) {
			setActiveBar(payload[0].payload.entryProgram); // Set the active bar name
			return (
				<div className="p-3 bg-colorBackgroundLightLight text-sm font-bold border border-gray-300 rounded shadow">
					<p>{`${payload[0].payload.entryProgram}`}</p>
					{payload.map((barData: any, index: number) => (
						<div key={index}>
							<div
								style={{ color: barData.color, textTransform: "capitalize" }}
							>
								{`${barData.name}`}:{" "}
								<span className="font-mono">{`${barData.value}`}</span>
							</div>
						</div>
					))}
				</div>
			);
		} else {
			setActiveBar(null); // Unset the active bar name when the tooltip is not active
		}
		return null;
	};

	const CustomXTooltip = ({
		active,
		payload,
	}: {
		active?: boolean;
		payload?: any[];
	}) => {
		if (active && payload && payload.length) {
			setActiveBar(payload[0].payload.label); // Set the active bar name
			return (
				<div className="p-3 bg-colorBackgroundLightLight text-sm font-bold border border-gray-300 rounded shadow">
					<p>{`${payload[0].payload.label}`}</p>
					{payload.map((barData: any, index: number) => (
						<div key={index}>
							<div
								style={{ color: barData.color, textTransform: "capitalize" }}
							>
								{`${barData.name}`}:{" "}
								<span className="font-mono">{`${barData.value}`}</span>
							</div>
						</div>
					))}
				</div>
			);
		} else {
			setActiveBar(null); // Unset the active bar name when the tooltip is not active
		}
		return null;
	};

	interface TickItemProps {
		x?: number;
		y?: number;
		payload?:
			| {
					value: string;
			  }
			| undefined;
	}

	if (reviewStatusesAndAmount) {
		const chartData = reviewStatusesAndAmount.map((item) => ({
			entryProgram: item.entryProgram,
			pending: item.pending.entries,
			completed: item.completed.entries,
		}));

		return (
			<ResponsiveContainer width="100%" height={chartData.length * 40}>
				<BarChart
					className="my-5"
					height={chartData.length * 40}
					data={chartData}
					layout="vertical" // Set the layout to vertical
					margin={{
						top: 20,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis type="number" className="font-mono" />{" "}
					{/* Use 'number' type for vertical bars */}
					<YAxis
						dataKey="entryProgram"
						type="category"
						width={350}
						height={100}
						tick={<CustomizedYAxisTick />}
					/>
					{/* Use 'category' type for entryProgram */}
					<Tooltip content={<CustomYTooltip />} />
					<Legend
						className="pt-40"
						formatter={(value: string) => capitalize(value)}
					/>
					<Bar
						key="pending"
						dataKey="pending"
						stackId="a"
						fill={graphColors.statusColors.d}
					/>
					<Bar
						key="completed"
						dataKey="completed"
						stackId="a"
						fill={graphColors.statusColors.g}
					/>
				</BarChart>
			</ResponsiveContainer>
		);
	}
	if (data) {
		// Convert data to a format suitable for recharts
		const colors: { [key: string]: string } = graphColors.statusColors;

		const colorsOrder: { [key: string]: string[] } = {
			"10": ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"],
			"9": ["a", "c", "d", "e", "f", "g", "h", "i", "j"],
			"8": ["a", "d", "e", "f", "g", "h", "i", "j"],
			"7": ["a", "d", "e", "f", "g", "h", "j"],
			"6": ["a", "d", "f", "g", "h", "j"],
			"5": ["a", "d", "f", "g", "j"],
			"4": ["a", "d", "g", "j"],
			"3": ["a", "d", "g"],
			"2": ["d", "g"],
			"1": ["d"],
		};

		const chartData = data.map((item) => {
			const result: any = {
				entryProgram: item.entryProgram,
				total: item.total,
			};

			item.reviewStatusCounts.forEach((statusCount) => {
				result[statusCount.label] = statusCount.entries;
			});

			return result;
		});

		// Get all unique statuses from the data
		const reviewStatusOrder = [
			"Draft",
			"ReadyForReview",
			"FailReportPending",
			"FailReportSent",
			"Updated",
			"Passed",
			"Withdrawn",
			"InJudging",
		];

		const allStatuses: string[] = [
			...new Set(
				data.flatMap((item) => item.reviewStatusCounts.map((sc) => sc.label))
			),
		];

		const uniqueStatuses = allStatuses.sort(
			(a, b) => reviewStatusOrder.indexOf(a) - reviewStatusOrder.indexOf(b)
		);

		// Assign colors based on the number of unique labels
		const labelsCount = uniqueStatuses.length;
		console.log("labelsCount:", labelsCount);
		console.log("colorsOrder:", colorsOrder);
		const selectedColorsOrder = colorsOrder[labelsCount.toString()];

		const statusColors: { [key: string]: string } = uniqueStatuses.reduce(
			(acc: { [key: string]: string }, current, index) => {
				acc[current] = colors[selectedColorsOrder[index]];
				return acc;
			},
			{}
		);
		return (
			<ResponsiveContainer width="100%" height={data.length * 40}>
				<BarChart
					height={data.length * 40}
					data={chartData}
					layout="vertical" // Set the layout to vertical
					margin={{
						top: 20,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis type="number" className="font-mono" />{" "}
					{/* Use 'number' type for vertical bars */}
					<YAxis
						dataKey="entryProgram"
						type="category"
						width={350}
						tick={<CustomizedYAxisTick />}
					/>{" "}
					{/* Use 'category' type for entryProgram */}
					<Tooltip content={<CustomYTooltip />} />
					<Legend formatter={(value: string) => capitalize(value)} />
					{uniqueStatuses.map((status: string, i) => (
						<Bar
							key={status}
							dataKey={status}
							stackId="a"
							fill={statusColors[status]}
						/>
					))}
				</BarChart>
			</ResponsiveContainer>
		);
	} else if (labelsAndCounts) {
		return (
			<ResponsiveContainer width="100%" height={500}>
				<BarChart
					data={labelsAndCounts}
					margin={{
						top: 6,
						right: 30,
						left: 20,
						bottom: 6,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis angle={-45} dataKey="label" tick={<CustomizXAxisTick />} />
					<YAxis />
					<Tooltip content={<CustomXTooltip />} />
					<Legend
						wrapperStyle={{ paddingTop: "30px" }}
						formatter={(value: string) => capitalize(value)}
					/>
					<Bar dataKey="entries" fill={graphColors.bar} />
				</BarChart>
			</ResponsiveContainer>
		);
	} else {
		return <div>No data provided</div>;
	}
};

export default ReportingGraphs;
