import React, { useRef, useState, useEffect, useCallback } from "react";
import cn from "classnames";
import Icon from "../Icon/Icon";
import { useTheme } from "styled-components";
import { useInView } from "react-intersection-observer";

type CarouselProps = {
	children: React.ReactNode;
	isVisible?: boolean;
	darkArrows?: boolean;
	scrollDistance?: number;
	style?: React.CSSProperties;
};

const Carousel: React.FC<CarouselProps> = ({
	children,
	isVisible = true,
	darkArrows = false,
	style,
	scrollDistance = 200,
}) => {
	const theme = useTheme();
	const carouselRef = useRef<HTMLDivElement>(null);
	const [showLeftArrow, setShowLeftArrow] = useState(false);
	const [showRightArrow, setShowRightArrow] = useState(false);

	// check if first and last item are in view
	const { ref: firstItemRef, inView: firstInView } = useInView({
		root: carouselRef.current,
		threshold: 1,
	});

	const { ref: lastItemRef, inView: lastInView } = useInView({
		root: carouselRef.current,
		threshold: 1,
	});

	useEffect(() => {
		if (React.Children.count(children) > 1) {
			setShowLeftArrow(!firstInView);
			setShowRightArrow(!lastInView);
		}
	}, [children, firstInView, lastInView]);

	// handle moving carousel left and right
	const moveScrollHorizontally = useCallback((moveBy) => {
		if (carouselRef.current) {
			carouselRef.current.scrollBy({
				left: moveBy,
				behavior: "smooth",
			});
		}
	}, []);

	if (!isVisible) {
		return null;
	}

	const carouselItems = React.Children.map(children, (child, index) => {
		// if first or last item, add ref to check if in view
		const itemRef =
			index === 0
				? firstItemRef
				: index === React.Children.count(children) - 1
				? lastItemRef
				: null;

		return (
			<div className="self-end" ref={itemRef} key={index}>
				{child}
			</div>
		);
	});

	return (
		<div className="relative">
			<div
				ref={carouselRef}
				className="carousel flex flex-row justify-start overflow-hidden px-[1rem] items-stretch"
				style={style}
			>
				{carouselItems}
			</div>
			<button
				className={cn(
					"absolute left-0 top-0 bottom-0 pr-2 transition-opacity z-[999]",
					{
						"bg-colorBackgroundDarkDark": !darkArrows,
						"bg-colorBackgroundLightLight": darkArrows,
						"opacity-0": !showLeftArrow,
						"opacity-90": showLeftArrow,
					}
				)}
				onClick={() => moveScrollHorizontally(scrollDistance * -1)}
			>
				<Icon
					icon="caret"
					color={
						darkArrows ? theme.colorCopyDarkDark : theme.colorCopyLightLight
					}
					rotation={"90deg"}
					width="25px"
					height="25px"
				/>
			</button>
			<button
				className={cn(
					"absolute right-0 top-0 bottom-0 pl-2 transition-opacity z-[999]",
					{
						"bg-colorBackgroundDarkDark": !darkArrows,
						"bg-colorBackgroundLightLight": darkArrows,
						"opacity-0": !showRightArrow,
						"opacity-90": showRightArrow,
					}
				)}
				onClick={() => moveScrollHorizontally(scrollDistance)}
			>
				<Icon
					icon="caret"
					color={
						darkArrows ? theme.colorCopyDarkDark : theme.colorCopyLightLight
					}
					rotation={"270deg"}
					width="25px"
					height="25px"
				/>
			</button>
		</div>
	);
};

export default Carousel;
