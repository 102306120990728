import axios from "axios";
import { useState, useEffect } from "react";
import { authenticationResponse } from "../components/Auth/auth.models";
import { getClaims } from "../components/Auth/handleJWT";
import { urlManageCompanies } from "../endpoints";
import { CompanyModel } from "../views/Company/company.model";
import { getCompanyInfo } from "../views/Company/manageCompany";

export const useCompany = () => {
  const [company, setCompany] = useState<CompanyModel>({
    address1: "",
    address2: "",
    city: "",
    companyType: "",
    holdingCompany: "",
    financeContactName: "",
    financeContactEmail: "",
    financeContactJobTitle: "",
    financeContactPhone: "",
    entryContactName: "",
    entryContactEmail: "",
    entryContactJobTitle: "",
    entryContactPhone: "",
    invoiceContactName: "",
    invoiceEmail: "",
    invoicePhone: "",
    logo: "",
    logoData: new FormData(),
    name: "",
    // phone: "",
    postalCode: "",
    socialMedia1: "",
    socialMedia2: "",
    socialMedia3: "",
    socialMedia4: "",
    state: "",
    overridePaymentMethod: false,
    agencyNetworkId: undefined,
    holdingCompanyId: undefined
  });

  useEffect(() => {
    const getCompanyInfoAsync = async () => {
      let results = await getCompanyInfo();
      if (results) {
        results.logoData = new FormData();
        setCompany(results);
        //console.log(results);
      }
    };
    getCompanyInfoAsync();
  }, []);

  return { company, setCompany };
};
