import {
	daysDifference,
	isDateWithinYearsAndDays,
} from "../../utils/dateInRange";
import { Tag } from "../MediaLibrary/mediaLibrary.model";

export const sortOptions = {
	tagNameAsc: "Tag Name A-Z",
	tagNameDesc: "Tag Name Z-A",
	uploadDateDesc: "Date Added (Newest - Oldest)",
	uploadDateAsc: "Date Added (Oldest - Newest)",
	usedCountDesc: "Times Used (Most - Least)",
	usedCountAsc: "Times Used (Least - Most)",
} as const;

export type TagSortOptions = (typeof sortOptions)[keyof typeof sortOptions];

export const ageOptions = {
	ninetyDays: "Past 90 days",
	oneEightyDays: "Past 180 days",
	oneYear: "Past 1 year",
	twoYears: "Past 2 years",
	all: "All",
} as const;

export type TagAgeOptions = (typeof ageOptions)[keyof typeof ageOptions];

export const sortTags = (tags: Tag[], sortType: TagSortOptions) => {
	let minDate = new Date(-8640000000000000);

	switch (sortType) {
		case sortOptions.tagNameAsc:
			return tags.sort((a, b) => a.tag.localeCompare(b.tag));
		case sortOptions.tagNameDesc:
			return tags.sort((a, b) => b.tag.localeCompare(a.tag));
		case sortOptions.uploadDateDesc:
			return tags.sort(
				(a, b) =>
					(b.createdDate
						? new Date(b.createdDate).getTime()
						: minDate.getTime()) -
					(a.createdDate
						? new Date(a.createdDate).getTime()
						: minDate.getTime())
			);
		case sortOptions.uploadDateAsc:
			return tags.sort(
				(a, b) =>
					(a.createdDate
						? new Date(a.createdDate).getTime()
						: minDate.getTime()) -
					(b.createdDate
						? new Date(b.createdDate).getTime()
						: minDate.getTime())
			);
		case sortOptions.usedCountDesc:
			return tags.sort(
				(a, b) =>
					(b.companyCreditsCount || 0) +
					(b.individualCreditsCount || 0) +
					(b.mediaCount || 0) -
					((a.companyCreditsCount || 0) +
						(a.individualCreditsCount || 0) +
						(a.mediaCount || 0))
			);

		case sortOptions.usedCountAsc:
			return tags.sort(
				(a, b) =>
					(a.companyCreditsCount || 0) +
					(a.individualCreditsCount || 0) +
					(a.mediaCount || 0) -
					((b.companyCreditsCount || 0) +
						(b.individualCreditsCount || 0) +
						(b.mediaCount || 0))
			);
		default:
			return tags;
	}
};

export const filterTagByAge = (tags: Tag[], age: TagAgeOptions) => {
	switch (age) {
		case ageOptions.ninetyDays:
			return tags.filter((tag) =>
				tag.createdDate ? daysDifference(tag.createdDate) <= 90 : false
			);
		case ageOptions.oneEightyDays:
			return tags.filter((tag) =>
				tag.createdDate ? daysDifference(tag.createdDate) <= 180 : false
			);
		case ageOptions.oneYear:
			return tags.filter((tag) =>
				tag.createdDate ? isDateWithinYearsAndDays(tag.createdDate, 1) : false
			);
		case ageOptions.twoYears:
			return tags.filter((tag) =>
				tag.createdDate ? isDateWithinYearsAndDays(tag.createdDate, 2) : false
			);
		case ageOptions.all:
			return tags;
		default:
			return tags;
	}
};
