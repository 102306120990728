import { DropdownOptions } from "../../../components/FormFields/DropdownField";

export const entryStatus: DropdownOptions[] = [
	{ value: 0, label: "Unsubmitted" },
	{ value: 1, label: "Submitted" },
	{ value: 2, label: "Action Required" },
];

export const sortOrderOptions: DropdownOptions[] = [
	{ value: 0, label: "Newest to Oldest" },
	{ value: 1, label: "Oldest to Newest" },
];

export enum EntrySearchSort {
	DateCreated,
	DateUpdated,
}
