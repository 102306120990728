import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  EntryCheckout,
  LabelAndCount,
  ReviewStatusesAndAmount,
  ReviewStatusesAndTotal,
  OrderReport,
  CustomerUpload,
  headerToDataKeyMap,
  headerToKeyMap,
  DeadlineUploadReport,
  deadlineUploadHeaderToDataKeyMap,
} from "./manageReports";
import { CSVLink } from "react-csv";
import Button from "../Button/Button";
import { ReviewStatus } from "../../views/Checkout/OrderInterfaces";
import styled, { useTheme } from "styled-components";
import ProgramDisplayForm from "../../views/Admin/Program/ProgramDisplayForm";
import { getStandardDate } from "../../views/Admin/Judging/Reports/manageHeaders";

const DualScrollWrapper = styled.div`
	width: 100%;

	.dual-scroll-top {
		overflow-x: auto;
		overflow-y: hidden;
		height: 20px;
	}

	.scrollbar-spacer {
		height: 1px;
	}

	.dual-scroll-content {
		overflow-x: auto;
		height: auto;
	}
`;

export const isNumericOrDollarAmount = (value: string | number): boolean => {
	if (typeof value === "number") return true;

	if (typeof value === "string") {
		if (value.startsWith("$")) {
			value = value.slice(1);
		}

		return !isNaN(Number(value));
	}

	return false;
};

interface DualScrollProps {
	children: React.ReactNode;
	showTopScroll?: boolean;
}

const DualScroll: React.FC<DualScrollProps> = ({
	children,
	showTopScroll = true,
}) => {
	const topScrollRef = useRef<HTMLDivElement | null>(null);
	const contentScrollRef = useRef<HTMLDivElement | null>(null);
	const [contentScrollWidth, setContentScrollWidth] = useState(0);

	useLayoutEffect(() => {
		const topScrollElement = topScrollRef.current;
		const contentScrollElement = contentScrollRef.current;

		const syncScroll = (
			source: HTMLDivElement | null,
			target: HTMLDivElement | null
		) => {
			if (source && target) {
				target.scrollLeft = source.scrollLeft;
			}
		};

		const handleTopScroll = () => {
			syncScroll(topScrollElement, contentScrollElement);
		};

		const handleContentScroll = () => {
			syncScroll(contentScrollElement, topScrollElement);
		};

		topScrollElement?.addEventListener("scroll", handleTopScroll);
		contentScrollElement?.addEventListener("scroll", handleContentScroll);

		return () => {
			topScrollElement?.removeEventListener("scroll", handleTopScroll);
			contentScrollElement?.removeEventListener("scroll", handleContentScroll);
		};
	}, []);

	useLayoutEffect(() => {
		if (contentScrollRef.current) {
			setContentScrollWidth(contentScrollRef.current.scrollWidth);
		}
	}, [children]); // Re-run when the children change

	return (
		<>
			<DualScrollWrapper>
				{showTopScroll ? (
					<div className="dual-scroll-top" ref={topScrollRef}>
						<div
							className="scrollbar-spacer"
							style={{ width: contentScrollWidth + "px" }}
						></div>
					</div>
				) : null}
				<div className="dual-scroll-content" ref={contentScrollRef}>
					{children}
				</div>
			</DualScrollWrapper>
		</>
	);
};

type TableData =
  | LabelAndCount[]
  | ReviewStatusesAndTotal[]
  | EntryCheckout
  | ReviewStatusesAndAmount[]
  | OrderReport[]
  | CustomerUpload[]
  | DeadlineUploadReport[];

interface ResultsTableProps {
	data: TableData;
	disableExport?: boolean;
	programName?: string;
	showTopScroll?: boolean;
}

const isReviewStatusesAndTotal = (
	data: any
): data is ReviewStatusesAndTotal => {
	return (
		data.entryProgram !== undefined &&
		data.reviewStatusCounts !== undefined &&
		data.total !== undefined
	);
};

const isReviewStatusesAndAmount = (
	data: any
): data is ReviewStatusesAndAmount[] => {
	return (
		Array.isArray(data) &&
		data.length > 0 &&
		"entryProgram" in data[0] &&
		"incomplete" in data[0] &&
		"readyForCheckout" in data[0] &&
		"paymentPending" in data[0] &&
		"paid" in data[0] &&
		"pending" in data[0] &&
		"completed" in data[0]
	);
};

function isCustomerUpload(data: unknown): data is CustomerUpload[] {
	const isValid =
		Array.isArray(data) &&
		data.every(
			(d) =>
				typeof d === "object" && // Ensure that d is an object
				d !== null && // Ensure that d is not null
				typeof d.name === "string" &&
				typeof d.company === "string" &&
				typeof d.emailAddress === "string" &&
				typeof d.phone === "string" &&
				typeof d.street === "string" &&
				typeof d.city === "string" &&
				typeof d.state === "string" &&
				typeof d.zip === "string" &&
				typeof d.country === "string" &&
				typeof d.earliestInvoice === "string"
		);

	return isValid;
}

function isDeadlineUploadReport(data: unknown): data is DeadlineUploadReport[] {
  const isValid =
    Array.isArray(data) &&
    data.every(
      (d) =>
        typeof d === "object" && // Ensure that d is an object
        d !== null && // Ensure that d is not null
        typeof d.vertical === "string" &&
        typeof d.season === "string" &&
        typeof d.item === "string" &&
        typeof d.price === "number"
    );

  return isValid;
}

const removeQuotes = (str: any) => {
	if (typeof str !== "string") return str;
	return str.replace(/["]/g, "'");
};

const isOrderReport = (data: any): data is OrderReport[] => {
	return Array.isArray(data) && data.length > 0 && "invoiceNo" in data[0];
};

const camelCaseToSentence = (camelCaseString: string) => {
	// Insert a space before all caps
	let result = camelCaseString.replace(/([A-Z])/g, " $1");
	// Uppercase the first character
	result = result.charAt(0).toUpperCase() + result.slice(1);
	return result;
};

const ResultsTable: React.FC<ResultsTableProps> = ({
	data,
	disableExport = false,
	programName = "",
	showTopScroll,
}) => {
	const theme = useTheme();
	const [columnHeaders, setColumnHeaders] = useState<string[]>([]);
	const [totals, setTotals] = useState<{ [key: string]: number }>({});
	const [entryCheckout, setEntryCheckout] = useState<EntryCheckout | null>(
		null
	);
	const isEntryCheckout = (data: any): data is EntryCheckout => {
		return data.pending !== undefined && data.completed !== undefined;
	};
	const [csvData, setCsvData] = useState<any[]>([]); // <-- New state variable to store CSV data
	const [reportType, setReportType] = useState<string>();

	const cellPadding = ".75rem";

	useEffect(() => {
		// Prepare CSV data on mount
		setCsvData(prepareDataForCSV());
	}, []);

	useEffect(() => {
		if (isReviewStatusesAndAmount(data)) {
			let headers = ["Entry Program"];
			const statuses = [
				"incomplete",
				"readyForCheckout",
				"pending",
				"paymentPending",
				"paid",
				"completed",
			];
			statuses.forEach((status) => {
				let statusText = camelCaseToSentence(status);
				headers.push(`${statusText} Entries`);
				headers.push(`${statusText} Amount`);
			});

      setColumnHeaders(headers);
      setReportType("Review Statuses and Amount");
    } else if (isDeadlineUploadReport(data)) {
      setColumnHeaders([
        "Vertical",
        "Season",
        "Item(Product/Service)",
        "Price",
      ]);
      setReportType("Deadline Upload Report");
    } else if (
      Array.isArray(data) &&
      data.length > 0 &&
      isReviewStatusesAndTotal(data[0])
    ) {
      let newHeaders = ["Entry Program"];
      let totalSum = 0;

			const orderedStatuses = Object.keys(ReviewStatus)
				.filter((v) => isNaN(Number(v)))
				.map((v) => v.replace(/([A-Z])/g, " $1").trim());

			newHeaders.push(...orderedStatuses);
			newHeaders.push("Total");

			(data as ReviewStatusesAndTotal[]).forEach((row) => {
				totalSum += row.total;
			});

			setColumnHeaders(newHeaders);

			let newTotals: { [key: string]: number } = {};
			(data as ReviewStatusesAndTotal[]).forEach((row) => {
				row.reviewStatusCounts.forEach((status) => {
					const formattedLabel = status.label.replace(/([A-Z])/g, " $1").trim();
					newTotals[formattedLabel] =
						(newTotals[formattedLabel] || 0) + status.entries;
				});
			});
			newTotals["Total"] = totalSum;
			setTotals(newTotals);
			setReportType("Review Statuses Amounts Report");
		} else if (isOrderReport(data)) {
			const specificHeaders = [
				"InvoiceNo",
				"Customer",
				"InvoiceDate",
				"DueDate",
				"Terms",
				"Location",
				"Memo",
				"ItemDescription",
				"Item(Product/Service)",
				"ItemQuantity",
				"ItemRate",
				"ItemAmount",
				"ServiceDate",
			];
			setColumnHeaders(specificHeaders);
			setReportType("OrderReport");
		} else if (isCustomerUpload(data)) {
			setColumnHeaders([
				"Name",
				"Company",
				"Email Address",
				"Phone",
				"Street",
				"City",
				"State",
				"ZIP",
				"Country",
				"Earliest Invoice",
			]);
			setReportType("Customer Upload Report");
		} else if (Array.isArray(data) && data.length > 0) {
			setColumnHeaders(["Label", "Entries"]);
		} else if (isEntryCheckout(data)) {
			setColumnHeaders(["Status", "Entries", "Amount"]);
			setEntryCheckout(data);
		}
	}, [data]);

  const prepareDataForCSV = () => {
    if (isReviewStatusesAndAmount(data)) {
      return data.map((row) => {
        let rowObj: { [key: string]: any } = {};
        rowObj["Entry Program"] = removeQuotes(row.entryProgram);
        [
          "incomplete",
          "readyForCheckout",
          "pending",
          "paymentPending",
          "paid",
          "completed",
        ].forEach((status) => {
          rowObj[
            `${status.charAt(0).toUpperCase() + status.slice(1)} Entries`
          ] = removeQuotes(row[status].entries);
          rowObj[`${status.charAt(0).toUpperCase() + status.slice(1)} Amount`] =
            removeQuotes(`$${row[status].amount.toLocaleString()}`);
        });
        return rowObj;
      });
    } else if (isDeadlineUploadReport(data)) {
      return data.map((row) => {
        return {
          Vertical: removeQuotes(row.vertical),
          Season: removeQuotes(row.season),
          "Item(Product/Service)": removeQuotes(row.item),
          Price: row.price,
        };
      });
    } else if (entryCheckout) {
      return [
        {
          Status: "Pending",
          Entries: removeQuotes(entryCheckout.pending.entries),
          Amount: removeQuotes(
            `$${entryCheckout.pending.amount.toLocaleString()}`
          ),
        },
        {
          Status: "Completed",
          Entries: removeQuotes(entryCheckout.completed.entries),
          Amount: removeQuotes(
            `$${entryCheckout.completed.amount.toLocaleString()}`
          ),
        },
      ];
    } else if (isOrderReport(data)) {
      return data.map((order) => {
        const rowObj: { [key: string]: any } = {};

				Object.keys(headerToDataKeyMap).forEach((header) => {
					const dataKey = headerToDataKeyMap[header];
					let cellContent = order[dataKey as keyof OrderReport];

					// Remove Time from InvoiceDate and DueDate
					if (header === "InvoiceDate" || header === "DueDate") {
						if (typeof cellContent === "string" && cellContent.includes("T")) {
							cellContent = cellContent.split("T")[0];
						}
					}

					rowObj[header] = removeQuotes(cellContent);
				});

				return rowObj;
			});
		} else if (isCustomerUpload(data)) {
			return data.map((row) => {
				const date = new Date(row.earliestInvoice);
				const formattedDate = date.toISOString().split("T")[0];
				return {
					Name: row.name,
					Company: row.company,
					"Email Address": row.emailAddress,
					Phone: row.phone,
					Street: row.street,
					City: row.city,
					State: row.state,
					ZIP: row.zip,
					Country: row.country,
					"Earliest Invoice": formattedDate,
				};
			});
		} else if (Array.isArray(data)) {
			const csvData = data.map((row) => {
				const rowObj: { [key: string]: any } = {};

				if (isReviewStatusesAndTotal(row)) {
					rowObj["Entry Program"] = removeQuotes(row.entryProgram);
					columnHeaders.slice(1, -1).forEach((header) => {
						const status = row.reviewStatusCounts.find(
							(status) =>
								status.label.replace(/([A-Z])/g, " $1").trim() === header
						);
						rowObj[header] = status ? removeQuotes(status.entries) : 0;
					});
					rowObj["Total"] = removeQuotes(row.total);
				} else {
					rowObj["Label"] = removeQuotes((row as LabelAndCount).label);
					rowObj["Entries"] = removeQuotes((row as LabelAndCount).entries);
				}

				return rowObj;
			});

			if (
				Array.isArray(data) &&
				data.length > 0 &&
				isReviewStatusesAndTotal(data[0])
			) {
				const totalRow: { [key: string]: any } = {};
				totalRow["Entry Program"] = "Total";
				columnHeaders.slice(1, -1).forEach((header) => {
					totalRow[header] = totals[header] || 0;
				});
				// Skip adding up the totals since it's already computed in the 'totals' variable
				totalRow["Total"] = totals["Total"] || 0;
				csvData.push(totalRow);
			}
			return csvData;
		} else {
			return [];
		}
	};
	return (
		<>
			<DualScroll showTopScroll={showTopScroll}>
				{/* for tables that need to scroll horizontally */}
				<table className="text-left table-auto border-collapse w-full text-sm search-table">
					<thead className="bg-colorBackgroundLight">
						<tr className="border-b border-gray-300">
							{columnHeaders.map((header, index) => {
								const backgroundColor =
									index === 0
										? theme.colorBackgroundMedium
										: theme.colorBackgroundLight;
								const textColor =
									index === 0 ? theme.colorCopyLightLight : "initial";

                let textAlignClass = "";
                if (isOrderReport(data)) {
                  textAlignClass = index === 0 ? "text-left" : "";
                } else if (isCustomerUpload(data)) {
                  textAlignClass = "text-left";
                } else if (header === "Item(Product/Service)") {
                  textAlignClass = "text-left";
                } else {
                  textAlignClass = index === 0 ? "text-left" : "text-right";
                }

                const widthStyle =
                  header === "DueDate" && isOrderReport(data)
                    ? { width: "100px" }
                    : {};

								return (
									<th
										key={header}
										style={{
											...widthStyle,
											backgroundColor: backgroundColor,
											color: textColor,
										}}
										className={`p-3 ${textAlignClass} ${
											index === 0
												? "text-colorCopyLightLight bg-colorBackgroundDarkDark"
												: "bg-colorBackgroundLight"
										}`}
									>
										{header}
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody>
						{isReviewStatusesAndAmount(data) &&
							data.map((row, rowIndex) => (
								<tr className="border-b border-gray-300" key={rowIndex}>
									{columnHeaders.map((col, colIndex) => {
										const status = col
											.substring(0, col.lastIndexOf(" "))
											.toLowerCase();
										const camelCaseStatus = status
											.split(" ")
											.map((word, index) =>
												index === 0
													? word
													: word[0].toUpperCase() + word.substring(1)
											)
											.join("");
										let cellContent =
											col === "Entry Program"
												? row.entryProgram
												: col.includes("Entries")
												? row[camelCaseStatus]?.entries || 0
												: col.includes("Amount")
												? `$${(
														row[camelCaseStatus]?.amount || 0
												  ).toLocaleString()}`
												: null;
										const isCellNumericOrDollarAmount =
											isNumericOrDollarAmount(cellContent);
										return (
											<td
												style={{ padding: cellPadding }}
												key={colIndex}
												className={`${
													colIndex === 0 ? "text-left" : "text-right font-mono"
												}`}
											>
												{cellContent}
											</td>
										);
									})}
								</tr>
							))}
						{entryCheckout && (
							<>
								<tr className="border-b border-gray-300">
									<td style={{ padding: cellPadding }}>
										<div>Pending</div> (Incomplete + Ready for Checkout)
									</td>
									<td
										className={`text-right ${
											isNumericOrDollarAmount(entryCheckout.pending.entries)
												? "font-mono"
												: ""
										}`}
										style={{ padding: cellPadding }}
									>
										{entryCheckout.pending.entries}
									</td>
									<td
										className={`text-right ${
											isNumericOrDollarAmount(
												`$${entryCheckout.pending.amount}`
											)
												? "font-mono"
												: ""
										}`}
										style={{ padding: cellPadding }}
									>
										${entryCheckout.pending.amount.toLocaleString()}
									</td>
								</tr>
								<tr className="border-b border-gray-300">
									<td style={{ padding: cellPadding }}>
										<div>Completed</div> (Payment Pending + Paid)
									</td>
									<td
										className={`text-right ${
											isNumericOrDollarAmount(entryCheckout.completed.entries)
												? "font-mono"
												: ""
										}`}
										style={{ padding: cellPadding }}
									>
										{entryCheckout.completed.entries}
									</td>
									<td
										className={`text-right ${
											isNumericOrDollarAmount(
												`$${entryCheckout.completed.amount}`
											)
												? "font-mono"
												: ""
										}`}
										style={{ padding: cellPadding }}
									>
										${entryCheckout.completed.amount.toLocaleString()}
									</td>
								</tr>
							</>
						)}
						{isOrderReport(data) &&
							data.map((order, rowIndex) => (
								<tr className="border-b border-gray-300" key={rowIndex}>
									{columnHeaders.map((col, colIndex) => {
										const dataKey = headerToDataKeyMap[col];
										let cellContent = order[dataKey as keyof OrderReport];

										// Remove Time from InvoiceDate and DueDate
										if (col === "InvoiceDate" || col === "DueDate") {
											if (
												typeof cellContent === "string" &&
												cellContent.includes("T")
											) {
												cellContent = cellContent.split("T")[0];
											}
										}

										const isContentNumericOrDollar =
											(typeof cellContent === "string" ||
												typeof cellContent === "number") &&
											isNumericOrDollarAmount(cellContent);

										// Alignment conditions for content
										let textAlignClass = "";
										if (col === "InvoiceNo") {
											textAlignClass = "text-right";
										} else if (
											col === "Customer" ||
											col === "Memo" ||
											col === "ItemDescription"
										) {
											textAlignClass = "text-left";
										} else {
											textAlignClass = isContentNumericOrDollar
												? "text-right"
												: "";
										}

										const cellFontClass = isContentNumericOrDollar
											? "font-mono"
											: "";

										return (
											<td
												className={`p-3 ${textAlignClass} ${cellFontClass}`}
												style={{ padding: cellPadding }}
												key={col}
											>
												{cellContent}
											</td>
										);
									})}
								</tr>
							))}
						{isCustomerUpload(data) &&
							data.map((customer, rowIndex) => (
								<tr className="border-b border-gray-300" key={rowIndex}>
									{columnHeaders.map((col, colIndex) => {
										let cellContent = customer[headerToKeyMap[col]];

										// Remove Time from Earliest Invoice
										if (col === "Earliest Invoice") {
											if (
												typeof cellContent === "string" &&
												cellContent.includes("T")
											) {
												cellContent = cellContent.split("T")[0];
											}
										}

										const isContentNumericOrDollar =
											(typeof cellContent === "string" ||
												typeof cellContent === "number") &&
											isNumericOrDollarAmount(cellContent);

										// Alignment conditions for content
										let textAlignClass = "";
										if (
											[
												"Name",
												"Company",
												"Email Address",
												"Phone",
												"Street",
												"City",
												"State",
												"ZIP",
												"Country",
											].includes(col)
										) {
											textAlignClass = "text-left";
										} else {
											textAlignClass = isContentNumericOrDollar
												? "text-right"
												: "text-left";
										}

										const cellFontClass = isContentNumericOrDollar
											? "font-mono"
											: "";

                    return (
                      <td
                        className={`p-3 ${textAlignClass} ${cellFontClass}`}
                        style={{ padding: cellPadding }}
                        key={col}
                      >
                        {cellContent}
                      </td>
                    );
                  })}
                </tr>
              ))}
            {isDeadlineUploadReport(data) &&
              data.map((deadline, rowIndex) => (
                <tr className="border-b border-gray-300" key={rowIndex}>
                  {columnHeaders.map((col, colIndex) => {
                    const dataKey = deadlineUploadHeaderToDataKeyMap[col];
                    let cellContent = deadline[dataKey];

                    const isContentNumericOrDollar =
                      (typeof cellContent === "string" ||
                        typeof cellContent === "number") &&
                      isNumericOrDollarAmount(cellContent);

                    const textAlignClass = isContentNumericOrDollar
                      ? "text-right"
                      : "text-left";
                    const cellFontClass = isContentNumericOrDollar
                      ? "font-mono"
                      : "";

                    return (
                      <td
                        className={`p-3 ${textAlignClass} ${cellFontClass}`}
                        style={{ padding: cellPadding }}
                        key={col}
                      >
                        {cellContent}
                      </td>
                    );
                  })}
                </tr>
              ))}
            {Array.isArray(data) &&
              !isReviewStatusesAndAmount(data) &&
              !isOrderReport(data) &&
              !isCustomerUpload(data) &&
              !isDeadlineUploadReport(data) &&
              data.map((row, rowIndex) => (
                <tr className="border-b border-gray-300" key={rowIndex}>
                  {columnHeaders.map((col, colIndex) => {
                    let cellContent;
                    if (isReviewStatusesAndTotal(row)) {
                      const isHeaderForStatus = row.reviewStatusCounts.find(
                        (status) =>
                          status.label.replace(/([A-Z])/g, " $1").trim() === col
                      );
                      cellContent =
                        col === "Entry Program"
                          ? row.entryProgram
                          : col === "Total"
                          ? row.total
                          : isHeaderForStatus
                          ? isHeaderForStatus.entries
                          : 0;
                    } else {
                      cellContent =
                        col === "Label"
                          ? (row as LabelAndCount).label
                          : (row as LabelAndCount).entries;
                    }
                    const isCellNumericOrDollarAmount =
                      isNumericOrDollarAmount(cellContent);
                    return (
                      <td
                        className={`p-3 ${
                          colIndex === 0 ? "text-left" : "text-right font-mono"
                        } ${isCellNumericOrDollarAmount ? "font-mono" : ""}`}
                        style={{ padding: cellPadding }}
                        key={col}
                      >
                        {cellContent}
                      </td>
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </table>
      </DualScroll>
      {!disableExport && (
        <div className="py-5 w-5">
          <CSVLink
            className="no-underline text-colorCopyLightLight"
            data={csvData}
            filename={
              (programName ? programName + " " : "") +
              (reportType || "Report") +
              "_" +
              getStandardDate() +
              ".csv"
            }
          >
            <Button>Export to CSV</Button>
          </CSVLink>
        </div>
      )}
    </>
  );
};

export default ResultsTable;
