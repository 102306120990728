import Modal from "../../components/Modal/Modal";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { useContext, useState } from "react";
import { useTheme } from "styled-components";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import { ModalCard } from "../../components/Modal/Modal";
import TextField from "../../components/FormFields/TextField";
import { markEntryAsChild } from "./manageEntry";
import { useAlert } from "../../components/Alert/Alerts";

export const ChildEntryModal = (props: ChildEntryModalProps) => {
	const theme = useTheme();
	const { addNewAlert } = useAlert();
	const [initialValues] = useState<{ parentId: number | undefined }>({
		parentId: undefined,
	});

	let fieldRequired = "This field is required";

	const formikProps = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => {
			values.parentId &&
				markEntryAsChild(props.childId, values.parentId).then((response) => {
					if (response.status === 200) {
						formikProps.setFieldValue("parentId", "");
						addNewAlert({
							type: "success",
							message: "Successfully marked entry as child",
						});
						props.onClickHide();
						props.onSuccess(response.data);
					} else {
						addNewAlert({
							type: "error",
							message: response.response.data,
						});
					}
				});
		},
		validationSchema: Yup.object({
			parentId: Yup.number().required(fieldRequired),
		}),
		validateOnBlur: false,
		validateOnChange: false,
	});

	return (
		<FormikProvider value={formikProps}>
			<Modal show={props.show}>
				<ModalCard
					className="h-[500px]"
					title="Mark as Child"
					headerIcons={
						<Icon
							icon="close"
							color={theme.colorPrimary}
							onClick={props.onClickHide}
							width="35px"
							height="35px"
						/>
					}
				>
					<div className="flex flex-col p-[1.875rem] gap-[1.5625rem]">
						<TextField
							className="mt-[1rem]"
							name="parentId"
							placeholder="Parent ID"
							//   value={formikProps.values.parentId}
						/>
						<Button
							className="mt-[1rem]"
							type="submit"
							iconColor={theme.colorCopyLightLight}
							onClick={formikProps.submitForm}
							icon="check"
						>
							Save
						</Button>
					</div>
				</ModalCard>
			</Modal>
		</FormikProvider>
	);
};

export default ChildEntryModal;

interface ChildEntryModalProps {
	show: boolean;
	onClickHide(): void;
	onSuccess(parentEntry: any): void;
	childId: number;
}
