import { useContext } from "react";
import { ModalContext } from "../../App";
import assetsConfig from "../../assetsConfig";

const useAbstainModal = () => {
	const { setModal } = useContext(ModalContext);

	// show abstain modal for the first time
	const showAbstainModal = (castAbstainVote: () => void) => {
		if (!localStorage.getItem("showed_abstain_modal")) {
			localStorage.setItem("showed_abstain_modal", "true");
			setModal({
				title: "Abstain from Voting Definition",
				message: (
					<span className="text-center max-w-[500px]">
						We ask you to abstain from work you contributed to or which was
						submitted by your brand or agency (or agency network, if you oversee
						creative work across multiple agencies). This includes work
						completed at previous employers if you have recently changed
						positions. Please contact{" "}
						<a href={"mailto:" + assetsConfig.urls.judgingContactEmail}>
							{assetsConfig.urls.judgingContactEmail}
						</a>{" "}
						if you have specific questions on abstaining.
					</span>
				),
				headerIcon: "invalid",
				onClickClose: () => setModal(null),
				onConfirm: () => castAbstainVote(),
				confirmText: "Abstain",
			});
		}
	};

	return { showAbstainModal };
};

export default useAbstainModal;
