import { FETCH_SETTINGS_SUCCESS, SettingsActionTypes } from "./types";

const initialState: InitialState = {
	settings: {
		isDemo: false,
	},
	loading: true,
	error: null,
};

const settingsReducer = (state = initialState, action: SettingsActionTypes) => {
	switch (action.type) {
		case FETCH_SETTINGS_SUCCESS:
			return {
				...state,
				loading: false,
				settings: { ...action.payload },
				error: null,
			};
		// case FETCH_SETTINGS_FAILURE:
		// 	return {
		// 		...state,
		// 		loading: false,
		// 		error: action.payload,
		// 	};
		default:
			return state;
	}
};

export default settingsReducer;

interface InitialState {
	settings: SettingsState;
	loading: boolean;
	error: string | null;
}

// isDemo must be defined in redux store for compoonents to render
export interface SettingsState {
	id?: number;
	paymentThreshold?: number;
	pauseSlidePath?: string;
	isDemo: boolean;
}
