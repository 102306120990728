import { useState, useEffect } from "react";
import { DropdownOptions } from "../components/FormFields/DropdownField";
import {
  LiveToolsMediaItem,
  LiveToolsSettings,
} from "../views/Admin/Judging/JudgingInterfaces";
import {
  UseLiveToolsSettingsHubReturnType,
  useLiveToolsSettingsHub,
} from "./useLiveToolsSettingsHub";

export interface UseAdminLiveToolsHubReturnType
  extends UseLiveToolsSettingsHubReturnType {
  roundOptions: Array<DropdownOptions>;
  updateMedia: (mediaState: LiveToolsMediaItem) => void;
  updateLiveToolsSettings: (settings: LiveToolsSettings) => void;
  updateHighlightedRoundEntryId: (roundEntryId?: number) => void;
  updateEntriesLocked: (isLocked: boolean) => void;
  updateEntryLocked: (
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    roundEntryId: number,
    batchId: number,
    isLocked: boolean
  ) => void;
}

export function useAdminLiveToolsHub(): UseAdminLiveToolsHubReturnType {
  const settings = useLiveToolsSettingsHub();
  const scrollToTop = settings.scrollToTop;
  const [roundOptions, setRoundOptions] = useState<Array<DropdownOptions>>([]);

  useEffect(() => {
    if (settings.connection && settings.juryId) {
      settings.connection
        .invoke("GetRoundOptions", Number(settings.juryId))
        .then((options) => {
          settings.debug && console.log("GetRoundOptions", options);
          setRoundOptions(options);
        });
    }
  }, [settings.connection, settings.juryId]);

  // useEffect(() => {
  //   if (settings.connection && settings.settings.selectedRoundStructureId) {
  //     updateEntrySetSelections(settings.settings.selectedRoundStructureId);
  //   }
  // }, [settings.connection, settings.settings.selectedRoundStructureId]);

  function updateLiveToolsSettings(newSettings: LiveToolsSettings) {
    if (settings.connection) {
      settings.debug &&
        console.log("Old LiveToolsSettings:", settings.settings);
      settings.debug && console.log("New LiveToolsSettings:", newSettings);
      settings.connection.invoke(
        "UpdateLiveToolsSettings",
        Number(settings.juryId),
        newSettings
      );
    }
  }

  function updateHighlightedRoundEntryId(roundEntryId?: number) {
    var newSettings = { ...settings.settings };
    if (newSettings.highlightedRoundEntryId !== roundEntryId) {
      settings.debug &&
        console.log(
          `Highlighted RoundEntryId changed from ${newSettings.highlightedRoundEntryId} to ${roundEntryId}`
        );
      newSettings.highlightedRoundEntryId = roundEntryId;
      updateLiveToolsSettings(newSettings);
    }
  }

  function updateEntriesLocked(isLocked: boolean) {
    var newSettings = { ...settings.settings };
    if (newSettings.entriesLocked !== isLocked) {
      settings.debug &&
        console.log(
          `Highlighted RoundEntryId changed from ${newSettings.entriesLocked} to ${isLocked}`
        );
      newSettings.entriesLocked = isLocked;
      updateLiveToolsSettings(newSettings);
    }
  }

  const updateMedia = async (mediaState: LiveToolsMediaItem) => {
    if (settings.connection) {
      await settings.connection.invoke("UpdateLiveToolsMediaItem", mediaState);
    }
  };

  // single entry lock
  function updateEntryLocked(
    juryId: number,
    entrySetId: number,
    activeRoundId: number,
    roundEntryId: number,
    batchId: number,
    isLocked: boolean
  ) {
    if (settings.connection) {
      settings.connection.invoke(
        "UpdateEntryLocked",
        roundEntryId,
        batchId,
        activeRoundId,
        entrySetId,
        juryId,
        isLocked
      );
    }
  }

  return {
    ...settings,
    roundOptions,
    updateMedia,
    updateLiveToolsSettings,
    updateHighlightedRoundEntryId,
    updateEntriesLocked,
    updateEntryLocked,
    scrollToTop,
  };
}

export interface EntrySetSelectionCard {
  id: number;
  name: string;
  accordionCollapsed: boolean;
  isActive: boolean;
  batches: BatchSelectionCard[];
}
export interface BatchSelectionCard {
  id: number;
  entrySetId: number;
  label: string;
  entryCount: number;
}
