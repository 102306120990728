import { useFormikContext, FieldArray } from "formik";
import {
	ProgramCreditField,
	ProgramDateField,
	ProgramListField,
	ProgramTextField,
	ProgramLinkField,
	ProgramMediaField,
	ProgramPhysicalComponentField,
	EntryTextField,
	EntryDateField,
	EntryListField,
	EntryLinkField,
	EntryPhysicalComponentField,
	CreditType,
	EntryCompanyCreditField,
	EntryIndividualCreditField,
} from "../Admin/Program/ProgramInterfaces";
import EntryDateFieldForm from "./Fields/EntryDateFieldForm";
import EntryTextFieldForm from "./Fields/EntryTextFieldForm";
import EntryListFieldForm from "./Fields/EntryListFieldForm";
import EntryLinkFieldForm from "./Fields/EntryLinkFieldForm";
import EntryPhysicalComponentFieldForm from "./Fields/EntryPhysicalComponentFieldForm";
import EntryCompanyCreditFieldForm from "./Fields/EntryCompanyCreditFieldForm";
import EntryIndividualCreditFieldForm from "./Fields/EntryIndividualCreditFieldForm";
import { useContext } from "react";
import CheckboxField from "../../components/FormFields/CheckboxField";

export function isACompanyCreditField(obj: any): obj is ProgramCreditField {
	return (
		"creditType" in obj &&
		(obj as ProgramCreditField).creditType === CreditType.Company
	);
}

export function isAIndividualCreditField(obj: any): obj is ProgramCreditField {
	return (
		"creditType" in obj &&
		(obj as ProgramCreditField).creditType === CreditType.Individual
	);
}

const CreditFields = (props: CreditFieldsProps) => {
	return (
		<>
			{props.fieldArray?.length > 0 &&
				props.fieldArray.map((field: any, index: number) => {
					if (isACompanyCreditField(field.programField)) {
						return (
							<EntryCompanyCreditFieldForm
								field={field as EntryCompanyCreditField}
								name={`${props.name}.${index}`}
								key={`${props.name}.${index}`}
								index={index}
								readOnly={props.readOnly}
								isAdmin={props.isAdmin}
							/>
						);
					}
					if (isAIndividualCreditField(field.programField)) {
						return (
							<EntryIndividualCreditFieldForm
								field={field as EntryIndividualCreditField}
								name={`${props.name}.${index}`}
								key={index}
								index={index}
								readOnly={props.readOnly}
								isAdmin={props.isAdmin}
							/>
						);
					}
				})}
		</>
	);
};

export default CreditFields;

interface CreditFieldsProps {
	name: string;
	fieldArray: any;
	readOnly?: boolean;
	isAdmin?: boolean;
}
