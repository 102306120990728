import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import Modal, { ModalCard } from "../../../components/Modal/Modal";
import Icon from "../../../components/Icon/Icon";
import { useTheme } from "styled-components";
import { useContext, useEffect, useState } from "react";
import TextField from "../../../components/FormFields/TextField";
import DropdownField from "../../../components/FormFields/DropdownField";
import { LoadingContext } from "../../../App";
import Button from "../../../components/Button/Button";
import { SeasonModel } from "../Seasons/Seasons";
import { AwardModel } from "../Awards/Awards";
import { DuplicateProgram } from "./manageProgram";
import TZDatePickerField from "../../../components/FormFields/TZDatePickerField";
import { useAlert } from "../../../components/Alert/Alerts";

const CopyProgramModal = (props: CopyProgramModalProps) => {
	const theme = useTheme();
	const { addNewAlert } = useAlert();
	const fieldRequired = "This field is required";
	const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
	const { setLoadingMessage } = useContext(LoadingContext);
	const formikProps = useFormik<CopyProgram>({
		initialValues: {
			isProgramRootLevel: props.isProgramRootLevel,
			programName: "",
			seasonId: undefined,
			awardId: undefined,
			entriesOpenDate: undefined,
		},
		onSubmit: async (val) => duplicateProgram(val),
		enableReinitialize: true,
		validationSchema: Yup.object({
			programName: Yup.string().required("A Program Name is required"),
			seasonId: Yup.number().when("isProgramRootLevel", {
				is: true,
				then: Yup.number().required(fieldRequired),
			}),
			awardId: Yup.number().when("isProgramRootLevel", {
				is: true,
				then: Yup.number().required(fieldRequired),
			}),
			entriesOpenDate: Yup.number().when("isProgramRootLevel", {
				is: true,
				then: Yup.number().required(fieldRequired),
			}),
		}),

		validateOnBlur: false,
		validateOnChange: shouldValidateOnChange,
	});

	const duplicateProgram = (formVal: CopyProgram) => {
		setLoadingMessage(
			"Duplicating Program. This may take several minutes depending on the size and depth of the Program."
		);
		DuplicateProgram(formVal, props.programId)
			.then((resp) => {
				setLoadingMessage(null);
				if (resp.status === 200) {
					addNewAlert({
						type: "success",
						message: "Successfully duplicated program",
					});
					formikProps.resetForm();
					props.setShow(false);
				}
			})
			.catch((error) => {
				setLoadingMessage(null);
				addNewAlert({
					type: "error",
					message: "There was an error duplicating the Program",
				});
				console.log("error: ", error);
			});
	};
	return (
		<FormikProvider value={formikProps}>
			<Modal show={props.show}>
				<ModalCard
					title="Copy Program"
					headerIcons={
						<Icon
							icon="close"
							color={theme.colorPrimary}
							width="35px"
							height="35px"
							onClick={() => props.setShow(false)}
						/>
					}
					iconColor={theme.colorPrimary}
				>
					<div className="flex flex-col gap-[2rem] flex-1 p-[2rem]">
						<TextField name="programName" placeholder="New Program Name*" />
						{formikProps.values.isProgramRootLevel && (
							<>
								<DropdownField
									name="seasonId"
									placeholder="Select Season*"
									options={props.activeSeasons.map((s) => {
										return { value: s.id, label: s.name };
									})}
								/>
								<DropdownField
									name="awardId"
									placeholder="Select Award*"
									options={props.activeAwards.map((a) => {
										return { value: a.id, label: a.name };
									})}
								/>
								<TZDatePickerField
									name="entriesOpenDate"
									placeholder="Entries Open Date"
									value={formikProps.values.entriesOpenDate}
									showTimeSelect
								/>
							</>
						)}

						<Button
							className="mt-auto"
							icon="check"
							onClick={() => {
								setShouldValidateOnChange(true);
								formikProps.submitForm();
							}}
							disabled={formikProps.isSubmitting}
						>
							Save
						</Button>
					</div>
				</ModalCard>
			</Modal>
		</FormikProvider>
	);
};

export default CopyProgramModal;

export interface CopyProgram {
	isProgramRootLevel: boolean;
	programName: string;
	seasonId?: number;
	awardId?: number;
	entriesOpenDate?: number;
}

interface CopyProgramModalProps {
	show: boolean;
	setShow(show: boolean): void;
	activeSeasons: SeasonModel[];
	activeAwards: AwardModel[];
	programId: number;
	isProgramRootLevel: boolean;
}
