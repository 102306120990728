import { useParams } from "react-router-dom";
import {
	LibraryContainer,
	SortContainer,
} from "../../components/Library/Library";
import {
	UserPageContainer,
	UserPageHeader,
} from "../../components/UserPageTemplate/UserPageTemplate";
import UserMenu from "../Admin/User/UserMenu";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import DropdownField from "../../components/FormFields/DropdownField";
import SearchBar from "../../components/SearchBar/SearchBar";
import styled, { useTheme } from "styled-components";
import ToggleSwitchField from "../../components/FormFields/ToggleSwitchField";
import useTaskHandler, {
	BottomBar,
	PENDING,
	SelectableContainer,
	TASKS_COMPLETED,
	TaskHandlerReturnType,
} from "../../hooks/useTaskHandler";
import { ImgGridCSS, NoResultsText } from "../Admin/Media/MediaLibrary";
import Loading from "../../components/Loading/Loading";
import { Tag } from "../MediaLibrary/mediaLibrary.model";

import { getCompany } from "../Company/manageCompany";
import Button from "../../components/Button/Button";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import AddTagModal from "./AddTagModal";
import EditTagModal from "./EditTagModal";
import { deleteTag, getTagsForCompany } from "./manageTags";
import DeleteTagWarning from "./DeleteTagWarning";
import {
	TagAgeOptions,
	TagSortOptions,
	ageOptions,
	filterTagByAge,
	sortOptions,
	sortTags,
} from "./tagSortFilter";
import BrowserHeader from "../../components/BrowserHeader/BrowserHeader";
import assetsConfig from "../../assetsConfig";
import { useAlert } from "../../components/Alert/Alerts";

const TagGrid = styled.div`
	position: relative;
	padding: 2rem;

	${ImgGridCSS}
`;

const TagName = styled.span`
	max-width: 90%;
	overflow: hidden;
	text-overflow: ellipsis;
	user-select: none;
	white-space: nowrap;
`;

const TagCounter = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 28px;
	height: 28px;
	background: ${({ theme }) => theme.colorBorderLight};
	user-select: none;
`;

const TagCard = styled.div`
	display: flex;
	justify-content: space-between;
	height: 100%;
	background: ${({ theme }) => theme.colorBackgroundLight};
	border: 1px solid ${({ theme }) => theme.colorPrimary};
	padding: 0.75rem;
`;

const TagLibrary = (props: TagLibraryProps) => {
	const theme = useTheme();
	const { addNewAlert } = useAlert();
	const { companyId } = useParams<{
		companyId?: string;
	}>();
	const parsedCompanyId = companyId
		? parseInt(companyId)
		: Number(getCompany() || "0");
	const [tags, setTags] = useState<Tag[]>([]);
	const [filteredTags, setFilteredTags] = useState<Tag[]>([]);
	const [isFetchingTags, setIsFetchingTags] = useState(false);
	const [addTagModal, setAddTagModal] = useState(false);
	const [editTag, setEditTag] = useState<Tag | null>(null);
	const [showDeleteWarning, setShowDeleteWarning] = useState(false);

	// state to refresh list of tags after adding a new tag
	const [refreshTags, setRefreshTags] = useState(false);

	const [sort, setSort] = useState<null | TagSortOptions>(null);
	const [filter, setFilter] = useState<{
		tagName: string | null;
		age: TagAgeOptions | null;
	}>({
		tagName: null,
		age: null,
	});

	const formikProps = useFormik({
		initialValues: {},
		onSubmit: async (value) => {},
		validationSchema: Yup.object({}),
		enableReinitialize: true,
	});

	const getCompanyTags = () => {
		getTagsForCompany(parsedCompanyId)
			.then((response) => {
				if (response.status === 200 && response.data) {
					setTags(response.data);
					setIsFetchingTags(false);
				}
			})
			.catch((error) =>
				addNewAlert({ type: "error", message: "Failed to retrieve tags" })
			);
	};

	const handleDelete = useCallback((tag: Tag): Promise<Tag> => {
		return new Promise((resolve, reject) => {
			deleteTag(parsedCompanyId, tag)
				.then(() => {
					addNewAlert({
						type: "success",
						message: "Successfully deleted tag",
					});
					resolve(tag);
				})
				.catch((err) => {
					addNewAlert({
						type: "error",
						message: err,
					});
					reject(`Error Deleting Tag: ${err.message}`);
				});
		});
	}, []);

	const isTagLinked = (tags: Tag[]): boolean => {
		return tags.some((tag) => {
			if (
				(tag.companyCreditsCount || 0) +
					(tag.individualCreditsCount || 0) +
					(tag.mediaCount || 0) >
				0
			) {
				return true;
			}
		});
	};

	const {
		reset,
		loadItems,
		onSubmit,
		handleSelectItem,
		...state
	}: TaskHandlerReturnType<Tag> = useTaskHandler<Tag>();

	// Add deletion tasks when 'Delete Selected' is clicked
	const handleDeleteSelected = () => {
		if (isTagLinked(state.items)) {
			setShowDeleteWarning(true);
		} else {
			onSubmit(handleDelete);
		}
	};

	useEffect(() => {
		if (state.status === TASKS_COMPLETED) {
			// If there are no tasks left, refresh the tags
			getCompanyTags();
		}
	}, [state.status]);

	useEffect(() => {
		if (props.showAddTagModal !== undefined) {
			setAddTagModal(props.showAddTagModal);
		}
	}, [props.showAddTagModal]);

	// pass prop up hide modal for company tags page
	useEffect(() => {
		if (props.setshowAddTagModal && !addTagModal) {
			props.setshowAddTagModal(false);
		}
	}, [addTagModal]);

	// sort and filter tags
	useEffect(() => {
		let filteredAndSortedTags = tags;

		if (filter.tagName !== null) {
			filteredAndSortedTags = tags.filter((tag) =>
				tag.tag
					.toLocaleLowerCase()
					.includes(filter.tagName!.toLocaleLowerCase())
			);
		}
		if (filter.age !== null) {
			filteredAndSortedTags = filterTagByAge(filteredAndSortedTags, filter.age);
		}
		if (sort !== null) {
			filteredAndSortedTags = sortTags(filteredAndSortedTags, sort);
		}

		setFilteredTags(filteredAndSortedTags);
	}, [sort, filter, tags]);

	useEffect(() => {
		getCompanyTags();
	}, [refreshTags]);

	useEffect(() => {
		getCompanyTags();
	}, []);

	return (
		<FormikProvider value={formikProps}>
			<BrowserHeader title={props.metaDescription || `Tags`} />
			<DeleteTagWarning
				show={showDeleteWarning}
				setShow={setShowDeleteWarning}
				onConfirm={() => {
					setShowDeleteWarning(false);
					onSubmit(handleDelete);
				}}
			/>
			{editTag && (
				<EditTagModal
					tag={editTag}
					onHide={() => setEditTag(null)}
					refreshTags={getCompanyTags}
					tagArr={tags}
				/>
			)}

			<div className="w-full h-full flex flex-col">
				{parsedCompanyId && (
					<AddTagModal
						show={addTagModal}
						onClickHide={() => setAddTagModal(false)}
						companyId={parsedCompanyId}
						refreshTags={() => setRefreshTags(!refreshTags)}
						tagArr={tags}
					/>
				)}

				{/* show user menu for non-admin page */}
				{!companyId && (
					<UserMenu
						title={
							<div className="flex justify-between">
								Tag Library
								<Button
									className="ml-auto"
									icon="plus"
									onClick={() => setAddTagModal(true)}
								>
									Add Tags
								</Button>
							</div>
						}
					/>
				)}

				<UserPageContainer userPage={companyId ? false : true}>
					<LibraryContainer>
						<SortContainer>
							<DropdownField
								className="w-[270px]"
								name="tagName"
								placeholder="Sort By"
								options={Object.entries(sortOptions).map(([key, val]) => ({
									label: val,
									value: val,
								}))}
								onChange={(e) => setSort(e.target.value as TagSortOptions)}
							/>

							<DropdownField
								className="w-[270px]"
								name="age"
								placeholder="Filter By Date Created"
								options={Object.entries(ageOptions).map(([key, val]) => ({
									label: val,
									value: val,
								}))}
								onChange={(e) =>
									setFilter({ ...filter, age: e.target.value as TagAgeOptions })
								}
							/>

							<SearchBar
								className="w-[350px]"
								searchPlaceholder="Search by Tag Name"
								onChange={(e) =>
									setFilter({ ...filter, tagName: e.target.value })
								}
								// value={filter.fileName || ""}
								searchIconBG={theme.colorBackgroundLightLight}
								showBorder={false}
							/>
						</SortContainer>

						<p className="m-[2rem] mb-0">
							{assetsConfig.copy.tagLibraryInstructions}
						</p>

						<TagGrid>
							{isFetchingTags ? (
								<Loading fullScreen={false} showLogo={false} />
							) : filteredTags.length === 0 ? (
								<NoResultsText>
									No Results Found. Please try a different search criteria.
								</NoResultsText>
							) : (
								<>
									{filteredTags.map((tagObj) => {
										const usedCount =
											0 +
											(tagObj.companyCreditsCount || 0) +
											(tagObj.individualCreditsCount || 0) +
											(tagObj.mediaCount || 0);
										return (
											<SelectableContainer
												state={state}
												item={tagObj}
												className="img-container"
												key={tagObj.id}
												onClick={(event) => {
													if (event.shiftKey) {
														handleSelectItem(tagObj);
													} else {
														setEditTag(tagObj);
													}
												}}
											>
												<TagCard>
													<TagName>{tagObj.tag}</TagName>
													{usedCount > 0 ? (
														<TagCounter>{usedCount}</TagCounter>
													) : (
														<></>
													)}
												</TagCard>
											</SelectableContainer>
										);
									})}
								</>
							)}
						</TagGrid>
					</LibraryContainer>
				</UserPageContainer>

				<BottomBar show={state.items && state.items.length ? true : false}>
					<p className={`bar-text`}>{`${state.items.length} Tag${
						state.items.length > 1 ? "s" : ""
					} Selected`}</p>

					<Button
						className="button-transparent flex-col w-[175px] max-w-[175px]"
						onClick={() =>
							state.status === PENDING ? {} : handleDeleteSelected()
						}
						icon="trash"
						iconSize="20px"
						iconColor={theme.colorPrimary}
					>
						Delete
					</Button>

					<Button
						className="button-transparent flex-col w-[175px] max-w-[175px]"
						onClick={() => loadItems([])}
						icon="deselect"
						iconSize="20px"
						iconColor={theme.colorPrimary}
					>
						Deselect All
					</Button>
				</BottomBar>
			</div>
		</FormikProvider>
	);
};

export default TagLibrary;

interface TagLibraryProps {
	showAddTagModal?: boolean;
	metaDescription?: string;
	setshowAddTagModal?(show: boolean): void;
}
