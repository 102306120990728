import FieldModal from "../../../../components/FieldModal/FieldModal";
import RadioField, {
  ListContainer,
} from "../../../../components/FormFields/RadioField";
import { DateFieldTemplate, ProgramDateField } from "../ProgramInterfaces";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import ToggleSwitchField from "../../../../components/FormFields/ToggleSwitchField";
import { fieldTemplateValidation } from "../../../../components/FieldModal/FieldModal";
import { fieldRequired } from "../../../../components/FieldModal/FieldModal";
import { DateType } from "../ProgramInterfaces";
import { newFieldInitialVal } from "../../../../components/FieldModal/FieldModal";
import dateTostring from "../../../../utils/dateToString";
import { useEffect } from "react";
import TZDatePickerField from "../../../../components/FormFields/TZDatePickerField";

const DateFieldForm = (props: CustomFieldProps) => {
  const formikProps = useFormik({
    initialValues: {
      // populate existing field values for update form OR use new values for new field form
      ...(props.formData ? { ...props.formData } : { ...newFieldInitialVal }),
      dateType: props.formData ? props.formData.dateType : DateType.Single,
      displayTime: props.formData ? props.formData.displayTime : false,
      minDate:
        props.formData && props.formData.minDate
          ? props.formData.minDate
          : null,
      maxDate:
        props.formData && props.formData.maxDate
          ? props.formData.maxDate
          : null,
      isRequired: props.formData ? props.formData.isRequired : false,
    },
    onSubmit: async (value) => {},
    validationSchema: Yup.object({
      ...fieldTemplateValidation,
      dateType: Yup.string().required(fieldRequired),
      minDate: Yup.number().typeError(fieldRequired).required(fieldRequired),
      maxDate: Yup.number()
        .typeError(fieldRequired)
        .required(fieldRequired)
        .min(Yup.ref("minDate"), "Maximum Date cannot be before Minimum Date"),
    }),
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <FormikProvider value={formikProps}>
      <FieldModal
        {...(props.formData && {
          formData: props.formData,
        })}
        formTitle="Date Field Template"
        hideModal={() => props.hideModal()}
        formType="date"
        isUpdateForm={props.formData ? true : false}
      >
        <div>
          <p className="mb-[1rem]">
            Will this display as a single date or a date range for entrants
          </p>
          <ListContainer name="dateType">
            <RadioField
              name="dateType"
              value={DateType.Single}
              label="Single Date"
              checked={formikProps.values.dateType === DateType.Single}
              {...(props.formData &&
                props.formData.dateType !== undefined && {
                  disabled: true,
                })}
            />
            <RadioField
              name="dateType"
              value={DateType.Range}
              label="Date Range"
              checked={formikProps.values.dateType === DateType.Range}
              // error={formikProps.errors["dateType"]}
              {...(props.formData &&
                props.formData.dateType !== undefined && {
                  disabled: true,
                })}
              // disabled={true}
            />
          </ListContainer>
        </div>
        <div>
          <p className="mb-[1rem]">Display Time?</p>
          <ToggleSwitchField
            id="displayTime"
            name="displayTime"
            checked={formikProps.values.displayTime}
          />
        </div>

        <TZDatePickerField
          name="minDate"
          value={formikProps.values.minDate}
          placeholder="Start Date"
          // onChange={(date: Date) => formikProps.setFieldValue("minDate", date)}
        />

        <TZDatePickerField
          name="maxDate"
          value={formikProps.values.maxDate}
          placeholder="End Date"
          // onChange={(date: Date) => formikProps.setFieldValue("maxDate", date)}
        />

        <div>
          <p className="mb-[1rem]">Is Required?</p>
          <ToggleSwitchField
            id="isRequired"
            name="isRequired"
            checked={formikProps.values.isRequired}
          />
        </div>
      </FieldModal>
    </FormikProvider>
  );
};

export default DateFieldForm;

interface CustomFieldProps {
  formData?: DateFieldTemplate | ProgramDateField;
  hideModal: () => void;
}
