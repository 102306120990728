import { FormikProvider, useFormik } from "formik";
import { useEffect, useState, useContext, Fragment } from "react";
import { useParams, useLocation, useHistory, Link } from "react-router-dom";
import {
	CardBody,
	CardContainer,
	CardHeader,
} from "../../../components/DashboardCard/DashboardCard";
import Loading from "../../../components/Loading/Loading";
import * as Yup from "yup";
import styled, { useTheme } from "styled-components";
import StyledForm from "../../../components/Form/Form";
import TextField from "../../../components/FormFields/TextField";
import Button, { SquareButton } from "../../../components/Button/Button";
import DropdownField from "../../../components/FormFields/DropdownField";
import { USStateOptions } from "../../../data/StateData";
import { CountryOptions } from "../../../data/CountryData";

import dateTostring from "../../../utils/dateToString";
import {
	StickyCardBody,
	StickyContainer,
} from "../../../components/StickyCard/StickyCard";
import Icon from "../../../components/Icon/Icon";
import { EntryReviewContainer, EntryField } from "../../Entries/EntryForm";

import { NemoUser } from "../../../hooks/useCurrentUser";
import {
	UpdateUserProfileImg,
	getUser,
	makeAdmin,
	removeAdmin,
	updateIsEntryReviewer,
	updateExludeFromJuries,
	updateUser,
} from "./managerUser";
import { JobTitleOptions } from "../../../data/JobTitleData";
import CheckboxField from "../../../components/FormFields/CheckboxField";
import { useCompany } from "../../../hooks/UseCompany";
import { CompanyModel } from "../../Company/company.model";
import axios from "axios";
import {
	urlCompanies,
	urlJurorAdmin,
	urlManageCompanies,
} from "../../../endpoints";
import { CollapsibleContainer } from "../../../components/Collapsible/Collapsible";
import { NonFormikToggleSwitch } from "../../../components/FormFields/ToggleSwitchField";
import ProfilePlaceholder from "../../../assets/placeholders/profile.png";
import { UserProfileHeader } from "../../../components/UserPageTemplate/UserPageTemplate";
import FileUpload from "../../../components/FormFields/FileUpload";
import { updateProfileUrl } from "./UserInfo";
import { MediaType } from "../../MediaLibrary/mediaLibrary.model.d";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import { ReportedGenderOptions } from "../../../data/ReportedGenderData";
import { RaceAndEthnicityOptions } from "../../../data/RaceAndEthnicityData";
import { Juror } from "../Judging/JudgingInterfaces";
import Table from "../../../components/Table/Table";
import SaveJurorNotesModal from "./SaveJurorNotesModal";
import { useAlert } from "../../../components/Alert/Alerts";

const CheckBoxTip = styled.div`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.colorCopyDarkDark};
	span {
		margin-left: 0.5rem;
		font-size: ${({ theme }) => theme.xSmallSize};
		color: ${({ theme }) => theme.colorCopyLight};
	}
`;

const StyledLink = styled.a`
	color: ${({ theme }) => theme.colorActivation};
`;

const UserInfo = styled.div`
	padding: 2.25rem;
	border: solid 2px ${({ theme }) => theme.colorBorderLight};
	background: ${({ theme }) => theme.colorBackgroundLightLight};
`;

const StyledFileUpload = styled(FileUpload)`
	width: 150px !important;
	img {
		width: 90% !important;
		height: 90% !important;
	}
`;

const ImgContainer = styled.div`
	position: relative;
	.edit-icon {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 30px;
		height: 30px;
		pointer-events: none;
	}
`;

const EditUser = () => {
	const { addNewAlert } = useAlert();
	const theme = useTheme();
	const { userId } = useParams<{ userId: string }>();
	const history = useHistory();
	const [juror, setJuror] = useState<JurorUserInformation>();
	const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
	const [notesModal, setNotesModal] = useState<JuryInformation | null>(null);
	const [selfDescribedRaceAndEthnicity, setSelfDescribedRaceAndEthnicity] =
		useState("");
	const [raceAndEthnicity, setRaceAndEthnicity] = useState("");
	const [company, setCompany] = useState<CompanyModel>({
		id: 0,
		address1: "",
		address2: "",
		city: "",
		companyType: "",
		entryContactEmail: "",
		entryContactJobTitle: "",
		entryContactName: "",
		entryContactPhone: "",
		financeContactEmail: "",
		financeContactJobTitle: "",
		financeContactName: "",
		financeContactPhone: "",
		name: "",
		postalCode: "",
		socialMedia1: "",
		socialMedia2: "",
		socialMedia3: "",
		socialMedia4: "",
		state: "",
		country: "",
		holdingCompany: "",
		invoiceContactName: "",
		invoiceEmail: "",
		invoicePhone: "",
		logo: "",
		overridePaymentMethod: false,
		createdDate: "",
		createdBy: "",
		updatedDate: "",
		modifiedBy: "",
		adjustments: [],
	});
	const [user, setUser] = useState<NemoUser>({
		id: "",
		fullName: "",
		userName: "",
		jobTitle: "",
		email: "",
		address1: "",
		address2: null,
		profileUrl: "",
		city: "",
		state: "",
		postalCode: "",
		country: "",
		phone: "",
		programNotifications: true,
		clioMuse: true,
		clioAds: true,
		clioFriends: true,
		isAdmin: false,
		isEntryReviewer: false,
		excludeFromJuries: false,
		raceAndEthnicity: "",
		reportedGender: "",
		preferredJobTitle: "",
	});

	async function getCompany(companyId: number) {
		const response = axios.get<CompanyModel>(`${urlCompanies}/${companyId}`);
		return response;
	}

	async function getJurorFromUser(userId: string) {
		try {
			const response = await axios.get(`${urlJurorAdmin}/find/${userId}`);
			if (response.status === 200) {
				setJuror(response.data); // Set the juror data to state
			}

			return response;
		} catch (error) {
			console.error("Error fetching juror data", error);
			// Handle error appropriately
		}
	}

	async function UpdateUser(value: NemoUser) {
		try {
			// console.log("User Value", value);
			await updateUser(value).then((response) => {
				if (response!.status === 204) {
					addNewAlert({ type: "success", message: "User Updated" });
					setUser(value);
				}
			});
		} catch (error: any) {
			// console.log(error);
			let errorMessage = "An error occurred.";
			if (error.response && error.response.data) {
				errorMessage = error.response.data;
			}
			addNewAlert({ type: "error", message: errorMessage });
		}
	}

	useEffect(() => {
		getUser(userId)
			.then((response) => {
				if (response.status === 200) {
					setUser(response.data);
					getJurorFromUser(response.data.id);
				}
			})
			.catch((error) => {
				// error handling
			});
	}, [userId]);

	useEffect(() => {
		if (user.companyId) {
			getCompany(Number(user.companyId))
				.then((response) => {
					if (response.status === 200) {
						setCompany(response.data);
					}
				})
				.catch((error) => {
					// error handling
				});
		}
	}, [user]);

	useEffect(() => {
		// Check if the user's raceAndEthnicity exists in the options
		const raceOptionExists = RaceAndEthnicityOptions.some(
			(option) => option.value === user.raceAndEthnicity
		);

		if (!raceOptionExists && user.raceAndEthnicity) {
			// If it doesn't exist, set to "Prefer to self-describe" and use the custom value
			setRaceAndEthnicity("Prefer to self-describe");
			setSelfDescribedRaceAndEthnicity(user.raceAndEthnicity);
		} else {
			// If it exists or is blank, use it directly
			setRaceAndEthnicity(user.raceAndEthnicity || "");
		}
	}, [user]);

	const fieldRequired = "This field is required";
	const formikProps = useFormik({
		initialValues: user,
		enableReinitialize: true,
		onSubmit: async (value) => {
			// Conditionally use the selfDescribedRaceAndEthnicity
			if (raceAndEthnicity === "Prefer to self-describe") {
				value.raceAndEthnicity = selfDescribedRaceAndEthnicity;
			} else {
				value.raceAndEthnicity = raceAndEthnicity;
			}
			await UpdateUser(value);
			// Update the raceAndEthnicity state after form submission
			if (selfDescribedRaceAndEthnicity.trim()) {
				setRaceAndEthnicity("Prefer to self-describe");
			} else {
				setRaceAndEthnicity(value.raceAndEthnicity);
			}
			// console.log(value);
		},
		validationSchema: Yup.object({
			fullName: Yup.string()
				.required(fieldRequired)
				.max(100, "Full Name Maximum is 100 Characters"),
			jobTitle: Yup.string().required(fieldRequired),
			email: Yup.string().required(fieldRequired),
			address1: Yup.string().required(fieldRequired),
			city: Yup.string().required(fieldRequired),
			state: Yup.string().when("country", {
				is: (value: string) => value === "United States" || value === "Canada",
				then: Yup.string().required(fieldRequired),
			}),
			postalCode: Yup.string().when("country", {
				is: (value: string) => value === "United States" || value === "Canada",
				then: Yup.string().required(fieldRequired),
			}),
			phone: Yup.string().required(fieldRequired),
		}),
	});

	const updateUserPrivilege = (isAdmin: boolean) => {
		if (isAdmin) {
			removeAdmin(user.id)
				.then((res) => {
					if (res.status == 204) {
						setUser({ ...user, isAdmin: false });
						addNewAlert({
							type: "success",
							message: "Successfully removed user as admin",
						});
					}
				})
				.catch((err) => {
					addNewAlert({
						type: "error",
						message: "Failed to remove user as admin",
					});
				});
		} else {
			makeAdmin(user.id)
				.then((res) => {
					if (res.status == 204) {
						setUser({ ...user, isAdmin: true });
						addNewAlert({
							type: "success",
							message: "Successfully added user as admin",
						});
					}
				})
				.catch((err) => {
					addNewAlert({
						type: "error",
						message: "Failed to add user as admin",
					});
				});
		}
	};

	const updateisReviewer = (isReviewer: boolean) => {
		updateIsEntryReviewer(user.id, !isReviewer)
			.then((res) => {
				if (res.status == 204) {
					setUser({ ...user, isEntryReviewer: !isReviewer });
					addNewAlert({
						type: "success",
						message: "Successfully changed reviewer status",
					});
				}
			})
			.catch((err) => {
				addNewAlert({
					type: "error",
					message: "Failed to change reviewer status",
				});
			});
	};

	const updateExludeJuries = (excludeFromJuries: boolean) => {
		updateExludeFromJuries(user.id, !excludeFromJuries)
			.then((res) => {
				if (res.status == 204) {
					setUser({ ...user, excludeFromJuries: !excludeFromJuries });
					addNewAlert({
						type: "success",
						message: 'Successfully updated "Exclude from Juries" status',
					});
				}
			})
			.catch((err) => {
				addNewAlert({
					type: "error",
					message: 'Failed to update "Exclude from Juries" status',
				});
			});
	};

	// clear modal data after it's hidden
	useEffect(() => {
		if (!showNotesModal) {
			setTimeout(() => {
				setNotesModal(null);
			}, 150);
		}
	}, [showNotesModal]);

	return (
		(formikProps && formikProps.values.id && (
			<>
				<SaveJurorNotesModal
					userName={user.userName}
					juryInfo={notesModal}
					show={showNotesModal}
					setShow={setShowNotesModal}
					onUpdateNotes={() => getJurorFromUser(userId)}
				/>
				<CardContainer>
					<BrowserHeader title={`User: ${formikProps.values.fullName}`} />
					<CardHeader>
						<h2 className="card-title">Update User</h2>
					</CardHeader>
					<CardBody>
						<FormikProvider value={formikProps}>
							<StickyContainer className="mb-[2rem] !relative !top-0 !min-h-min">
								<CollapsibleContainer
									className="mb-[2rem]"
									title="User Information"
								>
									<StickyCardBody className="!p-0">
										<UserInfo>
											<div className="flex gap-[2rem] mb-[2rem]">
												<ImgContainer>
													<StyledFileUpload
														onChange={(files) => {
															if (!files || files.length === 0) {
																return;
															}
															const formData = new FormData();
															formData.append("FormFile", files[0].file);
															formData.append("FileName", files[0].file.name);

															UpdateUserProfileImg(formData, user.id);
														}}
														value={{
															fileName: "ProfilePicture",
															fileType: "image",
															src: user.profileUrl || ProfilePlaceholder,
															initial: true,
														}}
														fill="white"
														id={`FileUpload-UserProfileHeader-${user.id}`}
														height="150px"
														imgOnly
														allowedMediaType={MediaType.Image}
													/>
													<SquareButton className="edit-icon" icon="edit" />
												</ImgContainer>

												<div className="flex flex-col gap-[2rem] flex-1">
													<TextField
														className="flex-1"
														name="fullName"
														placeholder="Full Name*"
														value={formikProps.values.fullName}
													/>

													<DropdownField
														className="flex-1"
														name="jobTitle"
														placeholder="Job Title* "
														options={JobTitleOptions}
													/>
												</div>
											</div>

											<div className="flex gap-[3.5rem]">
												<StyledForm className="twoColumn mb-[1rem]" compact>
													<TextField
														name="email"
														placeholder="Email Address*"
														value={formikProps.values.email}
													/>

													<TextField
														name="phone"
														placeholder="Phone Number*"
														value={formikProps.values.phone}
													/>

													<TextField
														name="address1"
														placeholder="Address Line 1*"
														value={formikProps.values.address1}
													/>

													<TextField
														name="address2"
														placeholder="Address Line 2"
														value={formikProps.values.address2!}
													/>

													<TextField
														name="city"
														placeholder="City*"
														value={formikProps.values.city}
													/>
													<DropdownField
														name="country"
														placeholder="Country "
														options={CountryOptions}
													/>
													<DropdownField
														name="state"
														placeholder="State"
														options={USStateOptions}
														value={formikProps.values.state}
													/>
													<TextField
														name="postalCode"
														placeholder="Postal Code"
														value={formikProps.values.postalCode}
													/>
													<DropdownField
														name="reportedGender"
														placeholder="Reported Gender"
														options={ReportedGenderOptions}
														value={formikProps.values.reportedGender}
													/>
													<TextField
														className="w-1/2"
														name="preferredJobTitle"
														placeholder="Preferred Job Title"
														value={formikProps.values.preferredJobTitle}
													/>
													<DropdownField
														name="raceAndEthnicity"
														placeholder="Reported Race and Ethnicity"
														options={RaceAndEthnicityOptions}
														value={raceAndEthnicity}
														onChange={(e) => {
															setRaceAndEthnicity(e.target.value);
															if (
																e.target.value !== "Prefer to self-describe"
															) {
																setSelfDescribedRaceAndEthnicity("");
															}
														}}
													/>

													<TextField
														className={`w-1/2 ${
															raceAndEthnicity !== "Prefer to self-describe"
																? "invisible"
																: "visible"
														}`}
														name="selfDescribedRaceAndEthnicity"
														placeholder="Describe your Race and Ethnicity"
														value={selfDescribedRaceAndEthnicity}
														onChange={(e) =>
															setSelfDescribedRaceAndEthnicity(e.target.value)
														}
													/>

													<div className="col-w-100">
														<div className="flex gap-[1rem] items-center">
															<p>Is Admin?</p>
															<NonFormikToggleSwitch
																id="isAdmin"
																checked={user.isAdmin!}
																small
																onClick={() =>
																	updateUserPrivilege(user.isAdmin!)
																}
															/>
														</div>
													</div>
													{user.isAdmin && (
														<div className="col-w-100">
															<div className="flex gap-[1rem] items-center">
																<p>Is Reviewer?</p>
																<NonFormikToggleSwitch
																	id="isEntryReviewer"
																	checked={user.isEntryReviewer}
																	small
																	onClick={() =>
																		updateisReviewer(user.isEntryReviewer)
																	}
																/>
															</div>
														</div>
													)}
													<div className="col-w-100">
														<div className="flex gap-[1rem] items-center">
															<p>Exclude from Juries?</p>
															<NonFormikToggleSwitch
																id="excludeFromJuries"
																checked={user.excludeFromJuries}
																small
																onClick={() =>
																	updateExludeJuries(user.excludeFromJuries)
																}
															/>
														</div>
													</div>
												</StyledForm>
											</div>

											<div className="max-w-[650px] grid grid-cols-0 justify-end gap-[1rem] mt-[1rem] ml-auto">
												<Button
													onClick={formikProps.submitForm}
													disabled={
														formikProps.values.fullName === "" ||
														formikProps.values.email === "" ||
														formikProps.values.phone === "" ||
														formikProps.values.jobTitle === "" ||
														formikProps.values.address1 === "" ||
														formikProps.values.city === "" ||
														formikProps.values.country === "" ||
														formikProps.isSubmitting
													}
												>
													Update User
												</Button>
											</div>
										</UserInfo>
									</StickyCardBody>
								</CollapsibleContainer>

								{juror && (
									<CollapsibleContainer
										className="mb-[2rem]"
										title="Juror Details"
										isCollapsed
									>
										<Table
											isActive
											dualScroll
											columnLabels={[
												"Program Name",
												"Jury Name",
												"Juror Role",
												"Juror Note",
												"Config Link",
											]}
										>
											{juror.juries.map((jury, i) => (
												<Fragment key={i}>
													<div className="cell">{jury.programName}</div>
													<div className="cell">{jury.juryName}</div>
													<div className="cell">{jury.jurorRole}</div>
													<div className="cell">
														<Icon
															className="mr-[.5rem]"
															icon="edit"
															width="18px"
															height="18px"
															color={theme.colorPrimary}
															onClick={() => {
																setNotesModal(jury);
																setShowNotesModal(true);
															}}
														/>
														{jury.jurorNote}
													</div>
													<div className="cell">
														{jury.configLink && (
															<Link to={jury.configLink}>
																{jury.configLink}
															</Link>
														)}
													</div>
												</Fragment>
											))}
										</Table>
									</CollapsibleContainer>
								)}

								<CollapsibleContainer
									className="mb-[2rem]"
									title="User Metadata"
									isCollapsed
								>
									<StickyCardBody className="!p-0">
										<EntryReviewContainer className="p-[2.25rem]">
											<EntryField
												label="Date Created"
												value={
													formikProps.values.createdDate
														? dateTostring(
																new Date(formikProps.values.createdDate)
														  )
														: ""
												}
												isRequired={false}
											/>
											<EntryField
												label="Date Last Modified"
												value={
													formikProps.values.updatedDate
														? dateTostring(
																new Date(formikProps.values.updatedDate)
														  )
														: ""
												}
												isRequired={false}
											/>
											<EntryField
												label="Last Modified User"
												value={formikProps.values.modifiedByName}
												hardLink={`/edit-user/${formikProps.values.modifiedBy}`}
												isRequired={false}
											/>
											<EntryField
												label="User Id"
												value={formikProps.values.id}
												isRequired={false}
											/>
											<EntryField
												label="Company Name"
												value={formikProps.values.id}
												isRequired={false}
												hardLink={`/edit-company/${formikProps.values.companyId}`}
												linkText={company.name || "None"}
											/>
											<EntryField
												label="Company Type"
												value={company.companyType || "None"}
												isRequired={false}
											/>
										</EntryReviewContainer>
									</StickyCardBody>
								</CollapsibleContainer>
							</StickyContainer>
						</FormikProvider>
					</CardBody>
				</CardContainer>
			</>
		)) || (
			<>
				<Loading />
			</>
		)
	);
};

export default EditUser;

interface JurorUserInformation {
	juries: JuryInformation[];
}

export interface JuryInformation {
	juryId?: number;
	programName?: string;
	juryName?: string;
	jurorRole?: string;
	jurorNote?: string;
	configLink?: string;
}
