import { ReactElement, useContext, useEffect, useState } from "react";
import Company from "../../views/Company/Company";
import AuthenticationContext from "./AuthenticationContext";

export default function Authorized(props: authorizedProps) {
  const [isAuthorized, setIsAuthorized] = useState<boolean>();
  const [isCompanyCreated, setIsCompanyCreated] = useState(false);
  const { claims } = useContext(AuthenticationContext);

  useEffect(() => {
    // console.log("Claims length: " + claims.length);
    if (claims.length === 0) {
      setIsAuthorized(false);
      // console.log("creating timer");
      // const timer = setTimeout(() => {
      // 	setIsAuthorized(false);
      // }, 3000);
      // return () => clearTimeout(timer);
    }

    if (props.role) {
      const index = claims.findIndex(
        (claim) => claim.name === "role" && claim.value === props.role
      );
      setIsAuthorized(index > -1);
    } else {
      setIsAuthorized(claims.length > 0);
    }
    const companyIndex = claims.findIndex((claim) => claim.name === "company");
    setIsCompanyCreated(companyIndex > -1);
  }, [claims, props.role]);

  return (
    <>
      {isAuthorized === undefined ? (
        <></>
      ) : isAuthorized ? (
        isCompanyCreated ? (
          props.authorized
        ) : (
          <Company />
        )
      ) : (
        props.notAuthorized
      )}
    </>
  );
}

interface authorizedProps {
  authorized: ReactElement;
  notAuthorized?: ReactElement;
  role?: string;
}
