import Sort, { Alphabets, SortContainer } from "../Sort/Sort";
import styled, { css, keyframes } from "styled-components";

const ExpandCardContainer = styled.div<{
	childrenCount: number;
	childrenHeight?: string;
	disableAnimation?: boolean;
	noGap?: boolean;
}>`
	display: flex;
	flex-direction: column;
	position: relative;
	${(p) =>
		p.childrenCount &&
		!p.disableAnimation &&
		`height: calc(${p.childrenCount} * ${p.childrenHeight || "112px"});`};

	${(p) =>
		!p.noGap &&
		css`
			gap: 2rem;
		`}
	@media only screen and (max-width: ${({ theme }) => theme.md}) {
		height: unset;
	}
`;

const HierarchyCardContainer = styled.div<{
	gridColumns?: string;
	gridArea?: string;
	responsive?: boolean; // automatically wraps columns as needed
	responsiveMinWidth?: string;
}>`
	display: grid;
	align-items: center;
	padding: 15px 19px;
	background: ${({ theme }) => theme.colorBackgroundLight};
	box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
	/* max-height: 112px; */

	${(p) =>
		p.gridColumns &&
		p.gridArea &&
		css`
			display: grid;
			grid-template-columns: ${p.gridColumns};
			gap: 1rem;
			/* e.g. ". a b"; */
			grid-template-areas: "${p.gridArea}";
		`}

	.program-title {
		width: 20%;
	}

	.sublevel-title {
		width: 38%;
	}

	p {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.active {
		color: ${({ theme }) => theme.colorActivation};
		font-weight: bold;
	}

	.inactive {
		color: ${({ theme }) => theme.colorCopyLight};
	}

	& > * {
		max-width: 100%;
	}

	--grid-item--min-width: ${(p) =>
		p.responsiveMinWidth ? p.responsiveMinWidth : "110px"};

	@media only screen and (max-width: ${({ theme }) => theme.xl}) {
		${(p) =>
			p.responsive &&
			css`
				display: grid;
				grid-template-columns: repeat(
					auto-fill,
					minmax(var(--grid-item--min-width), 1fr)
				);
				grid-gap: 1rem;
				grid-template-areas: unset;
				max-height: unset;
			`}
	}
`;

export const expandAnimation = (order: number, pushDown?: boolean) => keyframes`
  from {
    top: 0;
     ${
				pushDown && `top: 86px`
			}; // start push down from 86px (height of slideOut card)
  }

  to {
    top: calc(${order} * 112px);
    ${
			pushDown && `top: calc(${order} * 112px + 86px)`
		}; // push down another 86px (height of slideOut card)
  }

`;

export const SubLevel = styled.div<{
	childrenCount?: number;
	order: number;
	expandAnimation: any;
	pushDown?: boolean; // level is pushed down to make space (e.g. entry set slideOut)
	disableAnimation?: boolean;
}>`
	position: absolute;
	width: 100%;
	padding-left: 3.09375rem;

	// add sub-hierarchy tree branches
	.horizontal-branch {
		content: "";
		position: absolute;
		left: 0;
		top: 41px;
		width: 3.09375rem;
		border-top: 1px dashed ${({ theme }) => theme.colorCopyLight};
	}

	.vertical-branch {
		content: "";
		position: absolute;
		top: -30px;
		left: 0;
		height: calc(82px + 28px);
		border-left: 1px dashed ${({ theme }) => theme.colorCopyLight};
	}

	${(p) =>
		p.order === 0 &&
		css`
			.vertical-branch {
				top: calc(-2rem - 18.39px);
				height: calc(93px + 28px + 2rem);
			}
		`};

	${(p) =>
		p.order !== undefined &&
		css`
			// adjust animation duration according to hierarchy order
			animation: ${p.expandAnimation(p.order, p.pushDown)} ease forwards;
			animation-duration: ${`calc(150ms * ${p.order > 8 ? 8 : p.order} )}`};
		`};

	${(p) =>
		p.disableAnimation &&
		css`
			position: relative;
			animation: none;
			animation-duration: 0;
		`};
`;

export const HierarchyCard = (props: HierarchyCardProps) => {
	return (
		<HierarchyCardContainer
			className={`hierarchy-card ${props.className ? props.className : ""}`}
			gridColumns={props.gridColumns}
			gridArea={props.gridArea}
			responsive={props.responsive}
			responsiveMinWidth={props.responsiveMinWidth}
		>
			{props.children}
		</HierarchyCardContainer>
	);
};

export const generateGridArea = (columns: Column[]) => {
	return columns.reduce(
		(gridArea, column, i) =>
			column.label === null ? `${gridArea}. ` : `${gridArea}${Alphabets[i]} `,
		""
	);
};

export const generateGridColumns = (columns: Column[]) => {
	return columns.reduce(
		(gridCol, column) =>
			column.width ? `${gridCol}${column.width} ` : `${gridCol} 1fr `,
		""
	);
};

export const SortHeader = (props: SortHeaderProps) => {
	return (
		<SortContainer
			className={props.className}
			isGrid={true}
			gridColumns={generateGridColumns(props.columns)}
			gridArea={generateGridArea(props.columns)}
			isIndented={props.isIndented}
			darkLabel={props.darkLabel}
			responsive={props.responsive}
			responsiveMinWidth={props.responsiveMinWidth}
		>
			{props.columns.flatMap((col, index) =>
				col.label ? (
					<Sort
						key={index}
						className={`${
							props.isCenterLabel || col.isCenter ? "justify-center" : ""
						} ${col.className || ""}`}
						label={col.label}
						ascending={false}
						gridAreaLabel={!props.responsive ? Alphabets[index] : undefined}
						noSortTriangle={props.noSortTriangle}
						width={col.width}
					/>
				) : (
					<span className={col.width ? `w-[${col.width}]` : undefined}></span>
				)
			)}
		</SortContainer>
	);
};

const HierarchyNavContainer = styled.div<{
	dashBorder?: boolean;
}>`
	display: flex;
	flex-direction: column;
	padding-top: 1rem;

	${(p) =>
		p.dashBorder &&
		`
   border-left: 1px dashed ${p.theme.colorCopyLight};
  `}
`;
const HierarchyNav = (props: HierarchyNavProps) => {
	return (
		<HierarchyNavContainer
			className={props.className}
			dashBorder={props.dashBorder}
		>
			<SortHeader {...props} />

			<ExpandCardContainer
				childrenHeight={props.childrenHeight}
				childrenCount={props.children().length}
				disableAnimation={props.disableAnimation}
				noGap={props.noGap}
			>
				{props.children(
					generateGridColumns(props.columns),
					generateGridArea(props.columns)
				)}
			</ExpandCardContainer>
		</HierarchyNavContainer>
	);
};

export default HierarchyNav;

interface SortHeaderProps {
	className?: string;
	columns: Column[];
	isIndented?: boolean; // indents child level hierarchy
	isCenterLabel?: boolean;
	noSortTriangle?: boolean;
	darkLabel?: boolean;
	responsive?: boolean;
	responsiveMinWidth?: string;
}

interface HierarchyNavProps extends SortHeaderProps {
	children: any;
	disableAnimation?: boolean;
	noGap?: boolean;
	childrenHeight?: string;
	dashBorder?: boolean;
}

interface HierarchyCardProps {
	className?: string;
	children: React.ReactNode;
	gridColumns?: string;
	gridArea?: string;
	responsive?: boolean;
	responsiveMinWidth?: string;
}

interface Column {
	label: string | null;
	width?: string;
	className?: string;
	onClick?(): void;
	isCenter?: boolean;
}
