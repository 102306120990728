import { useParams, Link } from "react-router-dom";
import MediaLibrary from "../Media/MediaLibrary";
import styled from "styled-components";
import Button from "../../../components/Button/Button";
import axios from "axios";
import { CompanyModel } from "../../Company/company.model";
import { urlManageCompanies } from "../../../endpoints";
import { useEffect, useState } from "react";
import assetsConfig from "../../../assetsConfig";

// for media/credit library
export async function getCompany(companyId: number) {
  const response = axios.get<CompanyModel>(
    `${urlManageCompanies}/getCompany/${companyId}`
  );
  return response;
}

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.colorBackgroundDarkDark};
  color: ${({ theme }) => theme.colorCopyLightLight};
  padding: 1rem 2rem;

  .link_text {
    color: ${({ theme }) => theme.colorCopyLightLight};
  }
`;

const CompanyMediaLibrary = () => {
  const { companyId } = useParams<{
    companyId?: string;
  }>();
  const [company, setCompany] = useState<CompanyModel | null>(null);

  useEffect(() => {
    getCompany(Number(companyId)).then((response) => {
      setCompany(response.data);
    });
  }, [companyId]);

  return (
    <>
      <Header>
        <div>
          <h1 className="playfair-500 mb-[1rem]">
            {assetsConfig.labels.company.singular} Media Library
          </h1>
          {company && (
            <Link className="link_text" to={`/edit-company/${companyId}`}>
              {company.name} ({companyId})
            </Link>
          )}
        </div>
      </Header>
      <MediaLibrary
        metaDescription={`Media Library: [${companyId}] ${company?.name}`}
      />
    </>
  );
};

export default CompanyMediaLibrary;
