import Button from "../../../components/Button/Button";
import styled, { useTheme } from "styled-components";
import Icon from "../../../components/Icon/Icon";
import {date} from "yup";
import {longDateToString} from "../../../utils/dateToString";

const WrapText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const FieldTemplateList = (props: FieldTemplateList) => {
  const theme = useTheme();

  return (
    <>
      <div>
        <Button
          className="button-light"
          iconLeft="edit"
          onClick={() => props.openModal()}
        >
          Edit
        </Button>
      </div>
      <p>{ props.createdDate ? longDateToString(new Date(props.createdDate!)) : ""}</p>
      <p>{props.fieldName}</p>
      <div className="flex items-center">
        <Icon
          className="mr-[1rem]"
          icon="info"
          color={theme.colorCopyDarkDark}
        />
        <WrapText>{props.adminHelpText}</WrapText>
      </div>
      <p className={`${props.isActive ? "active" : "inactive"}`}>
        {props.isActive ? "Active" : "Inactive"}
      </p>
    </>
  );
};

export default FieldTemplateList;

interface FieldTemplateList {
  fieldName: string;
  createdDate?: string;
  adminHelpText: string;
  isActive: boolean;
  openModal(): void;
}
