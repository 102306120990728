import { useFormik, FormikProvider } from "formik";
import { useContext, useState } from "react";
import { useTheme } from "styled-components";
import Button from "../../../components/Button/Button";
import TextField from "../../../components/FormFields/TextField";
import { CompanyCredit } from "./CreditInterfaces";
import { addCompanyCredit } from "./manageCreditLibrary";
import * as Yup from "yup";
import DropdownField from "../../../components/FormFields/DropdownField";
import { CompanyTypeOptions } from "../Program/ProgramInterfaces";
import { CountryOptions } from "../../../data/CountryData";
import { useAlert } from "../../../components/Alert/Alerts";

export const AddCompanyCreditForm = (props: AddCompanyCreditFormProps) => {
	const theme = useTheme();
	const { addNewAlert } = useAlert();
	const [firstValidation, setFirstValidation] = useState(false);
	const initialValues = {
		city: "",
		companyId: props.companyId,
		companyType: "",
		otherCompanyType: "",
		country: "",
		name: "",
		isActive: true,
	};

	let fieldRequired = "This field is required";

	const formikProps = useFormik({
		initialValues: initialValues,
		onSubmit: async (values, { resetForm }) => {
			addCompanyCredit(values as CompanyCredit)
				.then((response) => {
					if (response.status === 201) {
						props.setCompanyCreditsArray((prev) => [...prev, response.data]);
						resetForm();
						props.onClickHide();
						addNewAlert({
							type: "success",
							message: "Successfully added company credit",
						});
					}
				})
				.catch(() => {
					addNewAlert({
						type: "error",
						message: "Failed to add company credit. Please try again later.",
					});
				});
		},
		validationSchema: Yup.object({
			name: Yup.string().required(fieldRequired),
			companyType: Yup.string().required(fieldRequired),
			city: Yup.string().required(fieldRequired),
			country: Yup.string().required(fieldRequired),
			otherCompanyType: Yup.string().when("companyType", {
				is: "Other",
				then: Yup.string().required(fieldRequired),
			}),
		}),
		validateOnBlur: false,
		validateOnChange: firstValidation,
	});

	return (
		// TO DO: hookup to backend
		<FormikProvider value={formikProps}>
			<div className="mb-[1rem]">
				<h3 className="mb-[.625rem] font-semibold">Add Company Credit</h3>
				<p>
					Add a company credit on this form. If you are adding a required
					company credit, please make sure the Company Type matches the required
					credit type (Entrant Company, Agency Network, etc.). Click "Add
					Credit" when you've entered all the information, and drag the new
					credit from the Credit Library sidebar to the credit field.
				</p>
			</div>
			<div className="grid gap-[2.5rem]">
				<TextField name="name" placeholder="Company Name*" />
				<DropdownField
					name="companyType"
					placeholder="Company Type*"
					options={CompanyTypeOptions}
				/>
				{formikProps.values.companyType === "Other" && (
					<TextField name="otherCompanyType" placeholder="Preferred Type*" />
				)}
				<TextField name="city" placeholder="Company City*" />
				<DropdownField
					name="country"
					placeholder="Country*"
					options={CountryOptions}
				/>
				<Button
					icon="plus"
					iconColor={theme.colorCopyLightLight}
					onClick={() => {
						formikProps.submitForm();
						setFirstValidation(true);
					}}
					type="submit"
				>
					Add Credit
				</Button>
			</div>
		</FormikProvider>
	);
};

export default AddCompanyCreditForm;

interface AddCompanyCreditFormProps {
	onClickHide(): void;
	setCompanyCreditsArray: React.Dispatch<React.SetStateAction<CompanyCredit[]>>;
	companyId: number;
}
