import styled, { useTheme } from "styled-components";
import SearchBar from "../../../components/SearchBar/SearchBar";
import { useEffect, useState } from "react";
import Icon from "../../../components/Icon/Icon";

const TagFilterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  flex-basis: 100%;
  min-width: 0;
  .search-bar {
    box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
  }
`;

const TagBubble = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: fit-content;
  padding: 0.25rem 0.5rem;
  background: ${({ theme }) => theme.colorBackgroundLightLight};
  border: 1px solid ${({ theme }) => theme.colorBorderLight};
  border-radius: 50px;
  cursor: pointer;
`;

const TagFilter = (props: TagFilterProps) => {
  const theme = useTheme();
  const [tags, setTags] = useState<string[]>([]);

  const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "Enter" &&
      e.currentTarget.value &&
      !tags.includes(e.currentTarget.value)
    ) {
      const newTags = [...tags, e.currentTarget.value];
      setTags(newTags);
      props.onSearch(newTags);
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    const newTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(newTags);
    props.onSearch(newTags);
  };

  useEffect(() => {
    if (props.tags && props.tags !== tags) setTags([...props.tags]);
  }, [props.tags]);

  return (
    <TagFilterContainer className={props.className ? props.className : ""}>
      <SearchBar
        className="search-bar w-[350px]"
        searchPlaceholder={
          props.placeholder ? props.placeholder : "Search by Tag"
        }
        onKeyUp={handleSearch}
        onClickSearch={(searchVal: string) =>
          {
            if(searchVal) {
              setTags([...tags, searchVal])
              props.onSearch([...tags, searchVal])
            }
          }
        }
        searchIconBG={theme.colorBackgroundLightLight}
        showBorder={false}
        clearSearchOnEnter
        icon="plus"
      />
      {/* <div className="flex gap-[1rem] flex-wrap"> */}
      {tags.map((tag, i) => (
        <TagBubble onClick={() => handleRemoveTag(tag)} key={i}>
          {tag}
          <Icon
            icon="close"
            color={theme.colorCopyDark}
            width="12px"
            height="12px"
          />
        </TagBubble>
      ))}
      {/* </div> */}
    </TagFilterContainer>
  );
};

export default TagFilter;

interface TagFilterProps {
  className?: string;
  onSearch(tags: string[]): void;
  placeholder?: string;
  tags?: string[];
}
