import { DropdownOptions } from "../components/FormFields/DropdownField";

export const JobTitleOptions: DropdownOptions[] = [
	{ value: "Other" },
	{ value: "Account Executive" },
	{ value: "Administrative Assistant" },
	{ value: "Animator" },
	{ value: "Art Director" },
	{ value: "Artist" },
	{ value: "Associate Creative Coordinator" },
	{ value: "Associate Creative Director" },
	{ value: "Awards Manager" },
	{ value: "Awards Producer" },
	{ value: "Awards Show Coordinator" },
	{ value: "Chief Creative Officer" },
	{ value: "Chief Executive Officer" },
	{ value: "Cinematographer" },
	{ value: "Composer" },
	{ value: "Copywriter" },
	{ value: "Creative Assistant" },
	{ value: "Creative Coordinator" },
	{ value: "Creative Director" },
	{ value: "Creative Services Manager" },
	{ value: "Designer" },
	{ value: "Developer" },
	{ value: "Director" },
	{ value: "Editor" },
	{ value: "Educational Instructor" },
	{ value: "Executive Assistant" },
	{ value: "Executive Creative Director" },
	{ value: "Executive Director" },
	{ value: "Executive Producer" },
	{ value: "General Manager" },
	{ value: "Global Chief Creative Officer" },
	{ value: "Group Creative Director" },
	{ value: "Managing Director" },
	{ value: "Marketing" },
	{ value: "Music Supervisor" },
	{ value: "Office Manager" },
	{ value: "Personal Assistant" },
	{ value: "Photographer" },
	{ value: "President" },
	{ value: "Producer" },
	{ value: "Production Assistant" },
	{ value: "Production Manager" },
	{ value: "Project Manager" },
	{ value: "Public Relations" },
	{ value: "Public Relations Director" },
	{ value: "Secretary" },
	{ value: "Songwriter" },
	{ value: "Sound Designer" },
	{ value: "Student" },
	{ value: "Traffic Manager" },
	{ value: "Vice President" },
];
