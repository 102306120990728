import { DropdownOptions } from "../components/FormFields/DropdownField";

export const RaceAndEthnicityOptions: DropdownOptions[] = [
  { value: "American Indian, Alaska Native or Indigenous" },
  { value: "Black or African American" },
  { value: "East Asian" },
  { value: "Hispanic or Latino, Latina, Latinx or Latine" },
  { value: "Middle Eastern" },
  { value: "Native Hawaiian or Other Pacific Islander" },
  { value: "South Asian" },
  { value: "Southeast Asian" },
  { value: "White" },
  { value: "Prefer to self-describe" },
];
