import { DefaultTheme } from "styled-components";
import Placeholder from "./assets/placeholders/image-landscape.png";
import Spinner from "./assets/spinner-gold.svg";
import SpinnerBlack from "./assets/spinner-black.svg";
import SpinnerGray from "./assets/spinner-gray.svg";
import CheckeredBackgroundImage from "./assets/placeholders/checkered_background.png";
import { AssetsConfig } from "./config";

import { ReactComponent as EchoBg } from "./assets/Clio/echo_background.svg";
import { ReactComponent as Award360White } from "./assets/Clio/Award 360 White.svg";
import { ReactComponent as Award360Black } from "./assets/Clio/Award 360 Black.svg";
import Award360WhitePng from "./assets/Clio/Award 360 White Png.png";
import Award360BlackPng from "./assets/Clio/Award 360 Black Png.png";

// Clio specific icons
import { ReactComponent as ClioLogo } from "./assets/Clio/clios-horizontal-black.svg";
import ClioLogoPng from "./assets/Clio/CLIOS-Horizontal-Black.png";
import ClioLogoPngWhite from "./assets/Clio/CLIOS-Horizontal-White.png";
import ClioLogoPngSecondaryWhite from "./assets/Clio/CLIOS-Vertical-White.png";
import { ReactComponent as ClioBG } from "./assets/Clio/clio_background.svg";
import SpinnerLoading from "./assets/Clio/clio-loading.svg";
import { ReactComponent as ClioCreditLibrary } from "./assets/Clio/ClioCreditLibrary.svg";
import { ReactComponent as ClioStatue } from "./assets/Clio/ClioStatue.svg";

// global icons
import { ReactComponent as PlusIcon } from "./assets/icons/plus.svg";
import { ReactComponent as CaretDown } from "./assets/icons/Down.svg";
import { ReactComponent as HomeIcon } from "./assets/icons/Programs.svg";
import { ReactComponent as MediaLibIcon } from "./assets/icons/Media Library 2.svg";
import { ReactComponent as EntriesIcon } from "./assets/icons/Media Library.svg";
import { ReactComponent as CheckIcon } from "./assets/icons/Check.svg";
import { ReactComponent as UserIcon } from "./assets/icons/Person.svg";
import { ReactComponent as DownloadIcon } from "./assets/icons/Download.svg";
import { ReactComponent as ClockIcon } from "./assets/icons/Clock.svg";
import { ReactComponent as EditPencilIcon } from "./assets/icons/Write.svg";
import { ReactComponent as CalendarIcon } from "./assets/icons/calendar.svg";
import { ReactComponent as DragArrowsIcon } from "./assets/icons/DragDrop.svg";
import { ReactComponent as ExpandArrowsIcon } from "./assets/icons/Expand.svg";
import { ReactComponent as SearchIcon } from "./assets/icons/Search.svg";
import { ReactComponent as EyeIcon } from "./assets/icons/Preview.svg";
import { ReactComponent as TriangleIcon } from "./assets/icons/Triangle.svg";
import { ReactComponent as CloseIcon } from "./assets/icons/Close.svg";
import { ReactComponent as CloseLarge } from "./assets/icons/CloseLarge.svg";
import { ReactComponent as TrashIcon } from "./assets/icons/Trash.svg";
import { ReactComponent as InvalidIcon } from "./assets/icons/invalid.svg";
import { ReactComponent as InfoIcon } from "./assets/icons/Info.svg";
import { ReactComponent as InfoIcon2 } from "./assets/icons/Info2.svg";
import { ReactComponent as CropIcon } from "./assets/icons/Crop.svg";
import { ReactComponent as CopyIcon } from "./assets/icons/Copy.svg";
import { ReactComponent as UploadIcon } from "./assets/icons/Upload.svg";
import { ReactComponent as ExpandCaret } from "./assets/icons/ExpandCaret.svg";
import { ReactComponent as PlayIcon } from "./assets/icons/Play.svg";
import { ReactComponent as PauseCircleIcon } from "./assets/icons/PauseCircle.svg";
import { ReactComponent as PauseIcon } from "./assets/icons/Pause.svg";
import { ReactComponent as StopIcon } from "./assets/icons/Stop.svg";
import { ReactComponent as LockIcon } from "./assets/icons/Lock.svg";
import { ReactComponent as UnlockedIcon } from "./assets/icons/Unlocked.svg";
import { ReactComponent as SidebarSettingsIcon } from "./assets/icons/SideBarSettings.svg";
import { ReactComponent as SidebarLogoutIcon } from "./assets/icons/SideBarLogout.svg";
import { ReactComponent as SidebarLibraryIcon } from "./assets/icons/SideBarLibrary.svg";
import { ReactComponent as SidebarReportsIcon } from "./assets/icons/SideBarReports.svg";
import { ReactComponent as SidebarJudgeIcon } from "./assets/icons/SideBarJudge.svg";
import { ReactComponent as LibraryIcon } from "./assets/icons/Library.svg";
import { ReactComponent as HelpIcon } from "./assets/icons/Help.svg";
import { ReactComponent as NotesIcon } from "./assets/icons/Notes.svg";
import { ReactComponent as LinkIcon } from "./assets/icons/link.svg";
import { ReactComponent as MediaIcon } from "./assets/icons/Media.svg";
import { ReactComponent as PlayerIcon } from "./assets/icons/Player.svg";
import { ReactComponent as FilterIcon } from "./assets/icons/Filter.svg";
import { ReactComponent as StarIcon } from "./assets/icons/Star.svg";
import { ReactComponent as ListIcon } from "./assets/icons/List.svg";
import { ReactComponent as GridIcon } from "./assets/icons/Grid.svg";
import { ReactComponent as PresentIcon } from "./assets/icons/Present.svg";
import { ReactComponent as StopPresentIcon } from "./assets/icons/StopPresenting.svg";
import { ReactComponent as ZoomIcon } from "./assets/icons/Unzoom.svg";
import { ReactComponent as HideIcon } from "./assets/icons/Hide.svg";
import { ReactComponent as DeselectIcon } from "./assets/icons/Deselect.svg";
import { ReactComponent as TagIcon } from "./assets/icons/Tag.svg";
import { ReactComponent as LikeIcon } from "./assets/icons/Like.svg";
import { ReactComponent as LikedIcon } from "./assets/icons/Liked.svg";
import { ReactComponent as FlagIcon } from "./assets/icons/Flag.svg";
import { ReactComponent as FlaggedIcon } from "./assets/icons/Flagged.svg";
import { ReactComponent as EditUserIcon } from "./assets/icons/EditUser.svg";
import { ReactComponent as DocumentIcon } from "./assets/icons/Doc.svg";
import { ReactComponent as EntryReview } from "./assets/icons/EntryReview.svg";
import { ReactComponent as Cube } from "./assets/icons/Cube.svg";
import { ReactComponent as DocPlay } from "./assets/placeholders/DocPlay.svg";
import { ReactComponent as VideoPlay } from "./assets/placeholders/VideoPlay.svg";
import { ReactComponent as AudioPlay } from "./assets/placeholders/AudioPlay.svg";

// alerts
import AlertBlue from "./assets/icons/alert-blue.png";
import AlertBlack from "./assets/icons/alert-black.png";
import AlertGray from "./assets/icons/alert-gray.png";
import AlertWhite from "./assets/icons/alert-white.png";
import AlertGold from "./assets/icons/alert-gold.png";

// animated SVGs
import { ReactComponent as PauseSpinning } from "./assets/icons/Pause-spinning.svg";
import { ReactComponent as PlaySpinning } from "./assets/icons/Play-spinning.svg";
import { ReactComponent as StopSpinning } from "./assets/icons/Stop-spinning.svg";
import lowerCase from "./utils/lowerCase";

export const defaultColors = {
	colorPrimaryDark: "#221416", // chocolate
	colorPrimary: "#B7844D", // gold
	colorPrimaryTint: "rgba(183, 132, 77, 0.75)", //dark gold
	colorPrimaryAccent: "#492F38", // burgundy

	colorActivation: "#015597", // Electric Blue
	colorActivationDark: "#172537", // muted activation color

	colorButtonPrimary: "rgb(183, 132, 77)", // gold
	colorToggleBlue: "#008DC3", // baby blue
	colorButtonNavy: "#0B131D", // navy

	colorGrand: "#6E3788",
	colorNoGrand: "#363636",
	colorAwardGold: "#FBCA0E",
	colorAwardSilver: "#ACB6BA",
	colorAwardBronze: "#CD7F32",
	colorShortlist: "#000000",

	colorAwardOne: "#EBF0F6",
	colorAwardTwo: "#D7E2ED",
	colorAwardThree: "#C3D4E4",
	colorAwardFour: "#AFC5DB",
	colorAwardFive: "#9BB7D1",
	colorAwardSix: "#88A9C8",
	colorAwardSeven: "#739ABF",
	colorAwardEight: "#608CB6",
	colorAwardNine: "#4C7DAD",
	colorAwardTen: "#386FA4", // cornflower blue

	// vote button colors
	colorIn: "#014F86", // baby blue
	colorOut: "#A20202", // out red
	colorAbstain: "#666666", // dark gray

	colorDanger: "#D31F1F", // red
	colorSuccess: "#00D563", // green
	colorWarning: "#FFF3CD", // yellow

	colorProgressBackground: "#e0e0de",
	colorProgressBackgroundDark: "#3B3B3B",
	colorSelected: "#d8d8d8",

	colorLogoLightLight: "#FFFFFF",
	colorLogoDarkDark: "#000000",
};

// specific components colors for light theme toggle
const lightComponentColors = {
	colorTextField: "#FFFFFF", // white
};

// specific components colors for dark theme toggle
const darkComponentColors = {
	colorTextField: "rgba(68, 69, 70, 0.63)", // transparent gray
};

export const lightThemeColors = {
	...lightComponentColors,

	colorBackgroundDark: "#131415", // off black
	colorBackgroundDarkDark: "#000000", // black
	colorBackgroundLight: "#F6F6F5", // off white
	colorBackgroundLightLight: "#FFFFFF", // white
	colorBackgroundMedium: "#1F1F1F", // dark gray

	colorCopyDarkDark: "#000000", // black
	colorCopyDark: "#5F5F5F", // dark gray text
	colorCopyLight: "#938E85", // heathered gray
	colorCopyLightLight: "#FFFFFF", // white

	colorLinkLight: "#686868", // light gray

	colorButtonDark: "rgb(19, 19, 19)", // off black
	colorButtonLight: "#FFFFFF", // white

	colorBorder: "#979797",
	colorBorderLight: "#e5e7eb",
	colorBorderDark: "#121212",
	colorFormText: "#686868",

	colorFieldDisabled: "#CCCCCC",
	colorFieldDisabledDark: "#bbb",
	colorTextDisabled: "#000000",
	colorLabelDisabled: "rgba(0,0,0,0.75)",
	colorFieldReadOnly: "#F0F0F0",

	colorBoxShadow: "rgb(0 0 0 / 15%)", // !!!!!!!!!! NEED TO UPDATE
	gradientBackgroundLight: "rgba(255, 255, 255, 0.9)",
	gradientBackgroundDark: "rgba(0, 0, 0, 0.9)",
	colorBackdropDark: "rgba(0, 0, 0, 0.85)",
};

export const darkThemeColors = {
	...darkComponentColors,

	colorBackgroundDark: lightThemeColors.colorBackgroundLight,
	colorBackgroundDarkDark: lightThemeColors.colorBackgroundLightLight,
	colorBackgroundLight: lightThemeColors.colorBackgroundDark,
	colorBackgroundLightLight: lightThemeColors.colorBackgroundDarkDark,
	colorBackgroundMedium: lightThemeColors.colorBackgroundMedium,

	colorCopyDarkDark: lightThemeColors.colorCopyLightLight,
	colorCopyDark: lightThemeColors.colorCopyDark,
	colorCopyLight: "#938E85", // heathered gray !!!!!!!!!! NEED TO UPDATE
	colorCopyLightLight: lightThemeColors.colorCopyDarkDark,

	colorLinkLight: "#686868", // light gray

	colorButtonDark: lightThemeColors.colorButtonLight,
	colorButtonLight: lightThemeColors.colorButtonDark,

	colorBorder: "#979797", // !!!!!!!!!! NEED TO UPDATE
	colorBorderLight: "#e5e7eb", // !!!!!!!!!! NEED TO UPDATE
	colorBorderDark: "#121212", // !!!!!!!!!! NEED TO UPDATE
	colorFormText: "#686868", // !!!!!!!!!! NEED TO UPDATE

	colorFieldDisabled: lightThemeColors.colorFieldDisabled,
	colorFieldDisabledDark: lightThemeColors.colorFieldDisabledDark,
	colorTextDisabled: lightThemeColors.colorTextDisabled,
	colorLabelDisabled: lightThemeColors.colorLabelDisabled,
	colorFieldReadOnly: lightThemeColors.colorFieldReadOnly,

	colorBoxShadow: "rgb(0 0 0 / 15%)", // !!!!!!!!!! NEED TO UPDATE
	gradientBackgroundLight: lightThemeColors.gradientBackgroundDark,
	colorPatternBGDark: "rgba(11, 19, 29, 0.9)",
};

export const defaultBoxShadow =
	"0 2px 8px 0 " + lightThemeColors.colorBoxShadow;

export const lightBoxShadow =
	"rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px";

export const gradients = {
	gradientBlackGray: "linear-gradient(180deg, #22211e 0%, #000000 100%)",
	gradientBlackSlate:
		"linear-gradient(90deg, rgba(255, 255, 255, 0.5) 16.82%, black 100%)",
	mobileMenu: "linear-gradient(180deg, #21201e, #000000 20%, #000000)",
};

const hoverColors = {
	colorButtonHover: "#013f6e", // darker blue
	colorButtonDarkHover: "#212121", //dark gray
	colorButtonLightHover: "#DADADA", //slate gray
};

export const pdfColors = {
	textHighlight: "#3c4757",
	textLight: "#8e8e8e",
};

export const graphColors = {
	bar: "#131415",
	text: "#666",
	statusColors: {
		a: "#131415",
		b: "#0d2a40",
		c: "#073f6c",
		d: "#015597",
		e: "#3e657e",
		f: "#7a7466",
		g: "#b7844d",
		h: "#ce9b38",
		i: "#e4b323",
		j: "#fbca0e",
	},
};

const breakpoints = {
	// LOWest TESTED RESOLUTION IS 360
	xxs: "360px",
	xs: "480px",
	sm: "576px",
	md: "768px",
	lg: "992px",
	xl: "1200px",
	xxl: "1400px",
};

const fontSizes = {
	fontBrand: `'Playfair Display', serif`,
	fontHeading: `'Open Sans', sans-serif`,
	fontCopy: `'Open Sans', sans-serif`,
	fontBrandSrc:
		"https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap",
	fontHeadingSrc:
		"https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap",
	fontCopySrc:
		"https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap",
	fontDefaultWeight: "400",
	fontMediumWeight: "500",
	fontSemiBold: "600",

	h1Size: "30px",
	h2Size: "22px",
	h3Size: "18px",
	h4Size: "18px",
	h5Size: "16px",
	h6Size: "14px",
	pSize: "16px",
	aSize: "16px",
	spanSize: "16px",
	xSmallSize: "14px",
	xxSmallSize: "13px",
	xxxSmallSize: "12px",
};

export const lightTheme: DefaultTheme = {
	...defaultColors,
	...lightThemeColors,
	...gradients,
	...hoverColors,
	...fontSizes,
	...breakpoints,
};

export const darkTheme: DefaultTheme = {
	...defaultColors,
	...darkThemeColors,
	...gradients,
	...hoverColors,
	...fontSizes,
	...breakpoints,
};

const labels = {
	// capitalize labels by default
	entry: {
		singular: "Entry",
		plural: "Entries",
	},
	entryReview: {
		singular: "Entry Review",
		plural: "Entry Review",
	},
	company: {
		singular: "Company",
		plural: "Companies",
	},
	allPrograms: {
		singular: "Home",
		plural: "Home",
	},
	review: {
		singular: "Review",
		plural: "Reviews",
	},
	reviewer: {
		singular: "Reviewer",
		plural: "Reviewers",
	},
};

const copy = {
	loginMessage:
		'If you have created an account for the 2023 Clio Health, Music,Cannabis, or Sports programs, or for the 2024 Clio Health or Clio Awards programs, login below. Otherwise, please click "Create Account" below.',
	registerMessage:
		"To create new entries, you must fill in your contact details on this page. On the following page, you will be asked for your company or organization contact information.",
	registerAddressMessage: "Please provide your professional address.",
	registerPasswordMessage:
		" Your password must have eight or more characters, must have at least one uppercase and one lowercase letter, must include at least one number and one special character (such as !?^*$ etc).",
	companyEntryCreationMessage: "a",
	userInfoAddressMessage: "Please provide your professional address.",
	companyPointOfContactMessage:
		"If you will not be the main contact for any finance or entry questions, please unselect the boxes below and identify who we should reach out to for any financial or entry-related questions.",
	companyEntryCreationInformation: `To create new entries, please provide information about your company. If you are a student, please select the "School" company type and fill in your institution's information.<br />
  <br />If you do not have an agency network or holding company, please select “N/A” from the dropdowns below. If you do not see your agency network or holding company in the dropdown, please select Other and contact our support by clicking “Help” in the corner of your screen.`,
	entryFormTitleDescription: `<p>The entry title is a short name for this entry. Keep it memorable, descriptive and short. Do not include the brand name, agency name, or medium type.</p>
    <p>Note: If your piece is awarded, the entry title will be listed on the trophy (maximum characters: 62).</p>`,
	entryFormBrandDescription: `<p>The entry brand is the brand, organization, individual or cause the entry is advertising.</p>
  <p>Note: If your piece is awarded, the brand name will be listed on the trophy (maximum characters: 62).</p>`,
	entryFormCoverImageDescription:
		"Please provide a cover image for your entry. This image will display wherever your entry appears in a list or gallery with other entries. We recommend using an image or still from your entry, not a case board or image with text.",
	entryFormCampaignOrSingleDescription:
		"Do you want to submit a single entry, or a collection of execuctions that make up a larger campaign?",
	entryFormCampaignExecutionsDescription: `<p>
    Campaigns are entries that contain two or more
    executions. To get started with your campaign, click
    "Add Execution" below and fill out the fields that
    appear.
  </p>
  <br />
  <p>
    Give each execution a short, descriptive title, and
    add media to each execution to showcase that
    execution's unique contribution to the campaign.
  </p>
  <br />
  <p>
    Click "Add Execution" to create additional executions
    until your entry is complete.
  </p>
  <br />
  <p>
    You can change the order of your executions at the
    bottom of the page.
  </p>`,
	entryFormDisplayOrderDescription:
		"In this section, you may change the order your executions display in your campaign entry. This will impact how the entry displays for jurors and in the Winners Gallery if your entry is awarded.",
	entryFormReviewDescription:
		"You may review your entry here for any typos or other issues. You must accept the Terms and Conditions to complete your entry and add it to your cart.",
	entryFormReviewTOS: `<p className="mb-[1.25rem]">
  Entries submitted become the property of Clio and
  cannot be returned. Each entrant, by clicking the
  box to enter the contest, grants a nonexclusive,
  perpetual, irrevocable, worldwide, transferable,
  royalty-free, paid up right to Clio to reproduce,
  perform and display the entries (including,
  without limitation, in edited form or as part of
  compilations of entries) at the Clio Awards and as
  part of the live stream thereof on Clio's website,
  otherwise on Clio's website and social media
  pages, in a book compiling images of winning
  works, and otherwise for educational and editorial
  purposes and for marketing and promoting the Clio
  Awards on any platform in any media now or
  hereafter known.
</p>
<br />
<p className="mb-[1.25rem]">
  Entrant represents and warrants that it has all
  necessary rights to grant the rights granted
  herein. Without limiting the generality of the
  preceding sentence, entrant represents and
  warrants it has secured all clearances and
  permissions for any third party intellectual
  property embodied within the entry (including
  without limitation, clearances/licenses for music
  and music publishing and trademarks) and any
  rights of privacy or publicity. Entrant will fully
  defend, indemnify and hold Clio Entities harmless
  from any costs, expenses and claims incurred by or
  made against Clio, its parents, affiliated
  entities, subsidiaries, licensees and assigns
  resulting from the use of the entries as set forth
  herein. The person submitting this entry form(s)
  represents and warrants that he or she has full
  authority to enter this contest, submit the entry
  media and grants the rights granted herein. This
  entry form constitutes the entire agreement of the
  parties and the entrant warrants and represents
  that he or she has not relied on any promise or
  statement not expressly set forth herein.
</p>
<br />
<p className="mb-[1.25rem]">
  Please make sure that you enter all information
  and credits completely and accurately when
  submitting an entry so that all contributors are
  properly recognized for their efforts. Clio Awards
  is NOT responsible for errors or omissions of
  information and/or credits.
</p><br />`,
draftEntryDescription:
"Your incomplete " +
lowerCase(labels.entry.plural) +
" appear here. Click an " +
lowerCase(labels.entry.singular) +
" to complete any required fields or upload required media assets or credits. From here, you will be able to add complete " +
lowerCase(labels.entry.plural) +
" to your cart.",
cartEntryDescription:
"The " +
lowerCase(labels.entry.plural) +
" on this page are ready for checkout. You may remove any " +
lowerCase(labels.entry.singular) +
" from your cart here. Please proceed to payment when you are ready to checkout.",
ordersTabDescription:
"Your complete " +
lowerCase(labels.entry.plural) +
" appear on this page for your reference. You may perform any available actions on your " +
lowerCase(labels.entry.plural) +
" here.",
	mediaLibraryInstructions:
		"Left-click on a card to view or edit a single piece of media. Hold down SHIFT and left-click on one or more cards to select multiple pieces. You can then tag them, delete them, or mark them as inactive with the toolbar at the bottom of the screen.",
	creditLibraryInstructions:
		"Hold down SHIFT and left-click on one or more credit to select multiple pieces. You can then tag them, delete them, or mark them as inactive with the toolbar at the bottom of the screen.",
	tagLibraryInstructions:
		"Left-click on a card to view or edit a single piece of tag. Hold down SHIFT and left-click on one or more cards to select multiple pieces. You can then edit them or delete them with the toolbar at the bottom of the screen.",
	creditUploadInstructions: `<ol>
  <li>
    Download the appropriate Credit Template File:
    <ul>
      <li onClick={downloadIndividualCreditSampleCsvFile}>
        <a href="javascript:void(0)">Individual</a>
      </li>
      <li onClick={downloadCompanyCreditSampleCsvFile}>
        <a href="javascript:void(0)">Companies</a>
      </li>
    </ul>
  </li>
  <li class="list">
    Open the file in Excel, Google Sheets, or your editor of choice
  </li>
  <li class="list">
    Fill in your credit information; required information will be
    marked with an asterisk (*). Do not change the column headers
  </li>
  <li class="list">Save the file as a CSV</li>
  <li class="list">
    Upload the file with your credits on the Individual Credit or
    Company Credit pages, as appropriate
  </li>
</ol>
<p>
  After you finish your upload, your credits will appear in your
  library, and you will see messages about any errors that occurred
  during the upload. You can then add any credits you have uploaded
  to your entries.
</p>
<p class="boldedHeader">Important Notes</p>
<ul>
  <li>
    If you want to add more than one tag to a credit, separate the
    tags with a semicolon (;)
  </li>
  <li>
    If a credit you are adding already exists, it will not be added
    again
  </li>
  <li>
    You can add tags to an existing credit by including it in the
    upload with text in the “Tags” column
  </li>
  <li>
    Do not change the column headers in the template. If you do,
    your upload will fail
  </li>
</ul>`,
	judgingWelcomeText: `<p class="text-center">Welcome, and thank you for participating in [JURY NAME] judging.</p> <p class="text-center">To begin, select an option below.</p>`,
};

const assetsConfig: AssetsConfig = {
	companyName: "The Clios",
	companyNameShort: "clios",
	companyNameLong: "The Clios",
	logos: {
		local: <ClioLogo title="Logo" />,
		localPng: ClioLogoPng,
		primary: ClioLogoPng,
		primaryWhite: ClioLogoPngWhite,
		secondary: ClioLogoPngSecondaryWhite,
	},
	icons: {
		// clio icons
		creditLibrary: <ClioCreditLibrary title="Credit Library" />,
		statue: <ClioStatue title="Clio Statue" />,

		// global icons
		plus: <PlusIcon title="Plus" />,
		close: <CloseIcon title="Close" />,
		closeLarge: <CloseLarge title="Close" />,
		caret: <CaretDown title="Caret Down" />,
		expand: <ExpandCaret title="Expand" />,
		alertBlue: <img src={AlertBlue} alt="Alert" />,
		alertBlack: <img src={AlertBlack} alt="Alert" />,
		alertGold: <img src={AlertGold} alt="Alert" />,
		alertWhite: <img src={AlertWhite} alt="Alert" />,
		alertGray: <img src={AlertGray} alt="Alert" />,
		home: <HomeIcon title="Home" />,
		mediaLibrary: <MediaLibIcon title="Media Library" />,
		entries: <EntriesIcon title="Entries" />,
		editPencil: <EditPencilIcon title="Edit" />,
		check: <CheckIcon title="Check" />,
		user: <UserIcon title="User" />,
		clock: <ClockIcon title="Clock" />,
		download: <DownloadIcon title="Download" />,
		calendar: <CalendarIcon title="Calendar" />,
		eye: <EyeIcon title="Eye" />,
		triangle: <TriangleIcon title="Triangle" />,
		search: <SearchIcon title="Search" />,
		expandArrows: <ExpandArrowsIcon title="Expand" />,
		dragArrows: <DragArrowsIcon title="Drag" />,
		trash: <TrashIcon title="Trash" />,
		invalid: <InvalidIcon title="Invalid" />,
		info: <InfoIcon title="Info" />,
		info2: <InfoIcon2 title="Info" />,
		crop: <CropIcon title="Crop" />,
		copy: <CopyIcon title="Copy" />,
		upload: <UploadIcon title="Upload" />,
		play: <PlayIcon title="Play" />,
		pause: <PauseIcon title="Pause" />,
		pauseCircle: <PauseCircleIcon title="Pause" />,
		pauseSpinning: <PauseSpinning title="Pause Loading" />,
		playSpinning: <PlaySpinning title="Play Loading" />,
		stop: <StopIcon title="Stop" />,
		stopSpinning: <StopSpinning title="Stop Loading" />,
		lock: <LockIcon title="Lock" />,
		unlocked: <UnlockedIcon title="Unlocked" />,
		gear: <SidebarSettingsIcon title="Settings" />,
		logout: <SidebarLogoutIcon title="Logout" />,
		sidebarLibrary: <SidebarLibraryIcon title="Library" />,
		reports: <SidebarReportsIcon title="Reports" />,
		judge: <SidebarJudgeIcon title="Judge" />,
		library: <LibraryIcon title="Library" />,
		help: <HelpIcon title="Help" />,
		notes: <NotesIcon title="Notes" />,
		link: <LinkIcon title="Link" />,
		document: <DocumentIcon title="Documennt" />,
		docPlay: <DocPlay title="Show Document" />,
		audioPlay: <AudioPlay title="Play Audio" />,
		videoPlay: <VideoPlay title="Play Video" />,
		media: <MediaIcon title="Media" />,
		filter: <FilterIcon title="Filter" />,
		player: <PlayerIcon title="Media Player" />,
		star: <StarIcon title="Star" />,
		list: <ListIcon title="List" />,
		grid: <GridIcon title="Grid" />,
		present: <PresentIcon title="Present Media" />,
		stopPresent: <StopPresentIcon title="Stop Presenting Media" />,
		zoom: <ZoomIcon title="Zoom" />,
		hide: <HideIcon title="Hide" />,
		tag: <TagIcon title="Tag" />,
		deselect: <DeselectIcon title="Deselect" />,
		flag: <FlagIcon title="Flag" />,
		flagged: <FlaggedIcon title="Flagged" />,
		like: <LikeIcon title="Like" />,
		liked: <LikedIcon title="Liked" />,
		editUser: <EditUserIcon title="Edit User" />,
		entryReview: <EntryReview title="Entry Review" />,
		cube: <Cube title="Physical Component" />,
	},
	loading: {
		primary: SpinnerLoading,
		secondary: Spinner,
		tertiary: SpinnerBlack,
		quaternary: SpinnerGray,
	},
	backgrounds: {
		primary: ClioBG,
	},
	placeholders: {
		image: Placeholder,
		imageBackground: CheckeredBackgroundImage,
	},
	fonts: {
		pdf: {
			fontFamily: "Open Sans",
			fonts: [
				{
					src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
					fontWeight: 400,
				},
				{
					src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
					fontWeight: 600,
				},
				{
					src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-italic.ttf",
					fontWeight: 400,
					fontStyle: "italic",
				},
			],
		},
	},
	urls: {
		contactEmail: "support@clios.com",
		judgingContactEmail: "judging@clioawards.com",
	},
	copy: copy,
	labels: labels,
	colors: lightTheme,
	settings: {
		showHelpWidget: true,
	},
};

export default assetsConfig;
