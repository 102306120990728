import { useState, useEffect } from "react";
import UserMenu from "../Admin/User/UserMenu";
import styled, { useTheme } from "styled-components";
import { PatternBG } from "../../globalStyles";
import { NavTabList } from "../../components/NavTabs/NavTabs";
import { useHistory, useParams } from "react-router-dom";
import {
	Ancestor,
	SubprogramCardModel,
} from "../Admin/Program/ProgramInterfaces";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { getChildProgramDisplays } from "../Admin/Program/manageProgramDisplays";
import SubProgramCard from "../../components/SubProgramCard/SubProgramCard";
import PrimaryLogo from "../../components/PrimaryLogo/PrimaryLogo";
import BrowserHeader from "../../components/BrowserHeader/BrowserHeader";

const PageContainer = styled.div`
	width: 100%;
	${PatternBG};
`;

const ProgramCardsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 4.375rem;
	column-gap: 1.25rem;
	flex-wrap: wrap;
	padding: 0 3rem;
	margin: 6.09375rem auto;
	max-width: 1800px;

	@media only screen and (min-width: ${({ theme }) => theme.lg}) {
		grid-template-columns: 1fr 1fr;
	}

	@media only screen and (min-width: ${({ theme }) => theme.xxl}) {
		grid-template-columns: 1fr 1fr 1fr;
	}
`;

const AwardsDropdown = styled.div`
	display: flex;
	align-items: center;
	padding-right: 2rem;
	margin-left: 5rem;
	border-right: 1px solid ${({ theme }) => theme.colorBorder};
	cursor: pointer;

	h1 {
		color: ${({ theme }) => theme.colorCopyLightLight};
		margin-right: 2rem;
	}
`;

const EntrantSubprograms = () => {
	const theme = useTheme();
	const params = useParams<{ programId: string }>();
	const [subPrograms, setSubprograms] = useState<null | SubprogramCardModel[]>(
		null
	);
	const history = useHistory();
	const [breadcrumbs, setBreadcrumbs] = useState([
		{ label: "Programs", value: 0 },
	]);
	let breadcrumbArray = [{ label: "Programs", value: 0 }];

	useEffect(() => {
		if (params && params.hasOwnProperty("programId")) {
			getChildProgramDisplays(parseInt(params.programId)).then((res) => {
				if (res.data != null || undefined) {
					setSubprograms(res.data.subprogramCards);
					res.data.ancestry.map((ancestor: Ancestor) => {
						breadcrumbArray.push({
							label: ancestor.name,
							value: ancestor.id,
						});
					});
					window.scrollTo(0, 0);
					setBreadcrumbs(breadcrumbArray);
				} else {
					// console.log("enter program");
				}
			});
		}
	}, [params]);

	const handleClickBreadcrumb = (
		breadcrumb: {
			label: string;
			value?: string | number;
		},
		index: number
	) => {
		breadcrumb.value === 0 && history.push("/");
		breadcrumb.value !== 0 && history.push(String(breadcrumb.value));
	};

	return (
		<PageContainer>
			<BrowserHeader
				title={`Enter ${breadcrumbs[breadcrumbs.length - 1].label}`}
			/>
			<UserMenu />
			<NavTabList>
				<div className="flex items-center mb-[66px]">
					<PrimaryLogo color={theme.colorLogoLightLight} width="254px" />
					<Breadcrumbs
						className="ml-[5rem]"
						navLinks={breadcrumbs}
						onClick={handleClickBreadcrumb}
					/>
				</div>
			</NavTabList>
			<ProgramCardsContainer>
				{/* SUBPROGRAMS */}
				{subPrograms &&
					params.hasOwnProperty("programId") &&
					subPrograms.map((program, i: number) => {
						return <SubProgramCard key={i} {...program} />;
					})}
			</ProgramCardsContainer>
		</PageContainer>
	);
};

export default EntrantSubprograms;
