import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ConfigNavTabs } from "../../views/Admin/Program/ProgramConfig";
import {
	CardBody,
	CardContainer,
	CardHeader,
} from "../DashboardCard/DashboardCard";
import { NavTab, NavTabPanel, NavTabs } from "../NavTabs/NavTabs";
import CustomerUploadReport from "./CustomerUpload";
import DeadlineUpload from "./DeadlineUpload";
import EntryCreationCheckout from "./EntryCreationCheckout";
import EntryReview from "./EntryReview";
import OrderUpload from "./OrderUpload";
import BrowserHeader from "../BrowserHeader/BrowserHeader";

const Reports = (props: { tab: string }) => {
	const tabs = [
		"entry-creation-checkout",
		"entry-review",
		"order-upload",
		"customer-upload",
		"deadline-upload",
		"all",
	];
	const tabNames = [
		"Entry Creation & Checkout",
		"Entry Review",
		"Order Upload",
		"Customer Upload",
		"Deadline Upload",
	];

	const defaultTab = tabs.indexOf(props.tab || "entry-creation-checkout");
	const [tabIndex, setTabIndex] = useState(defaultTab);

	useEffect(() => {
		setTabIndex(defaultTab);
	}, [defaultTab]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [tabIndex]);

	return (
		<CardContainer>
			<BrowserHeader title={`${tabNames[tabIndex]} Report`} />
			<CardHeader>
				<h2 className="card-title">Reports</h2>
			</CardHeader>
			<NavTabs
				selectedIndex={tabIndex}
				onSelect={(index) => setTabIndex(index)}
			>
				<ConfigNavTabs>
					{/*           <NavTab>
            <Link className="no-underline" to="/reports/all">
              All Reports
            </Link>
          </NavTab> */}
					<NavTab>
						<Link
							className="no-underline"
							to="/reports/entry-creation-checkout"
						>
							Entry Creation & Checkout
						</Link>
					</NavTab>
					<NavTab>
						<Link className="no-underline" to="/reports/entry-review">
							Entry Review
						</Link>
					</NavTab>
					<NavTab>
						<Link className="no-underline" to="/reports/order-upload">
							Order Upload
						</Link>
					</NavTab>
					<NavTab>
						<Link className="no-underline" to="/reports/customer-upload">
							Customer Upload
						</Link>
					</NavTab>
					<NavTab>
						<Link className="no-underline" to="/reports/deadline-upload">
							Deadline Upload
						</Link>
					</NavTab>
				</ConfigNavTabs>
				{/*         <NavTabPanel>
          <CardBody></CardBody>
        </NavTabPanel> */}
				<NavTabPanel>
					<CardBody>
						<EntryCreationCheckout />
					</CardBody>
				</NavTabPanel>
				<NavTabPanel>
					<CardBody>
						<EntryReview />
					</CardBody>
				</NavTabPanel>
				<NavTabPanel>
					<CardBody>
						<OrderUpload />
					</CardBody>
				</NavTabPanel>
				<NavTabPanel>
					<CardBody>
						<CustomerUploadReport />
					</CardBody>
				</NavTabPanel>
				<NavTabPanel>
					<CardBody>
						<DeadlineUpload />
					</CardBody>
				</NavTabPanel>
			</NavTabs>
		</CardContainer>
	);
};

export default Reports;
