import StickyCard, {
	StickyCardBody,
	StickyCardHeader,
} from "../../../components/StickyCard/StickyCard";
import EntryDetailNav, { NavModalType } from "./EntryDetailNav";
import styled, { useTheme } from "styled-components";
import { FormikProvider, useFormik } from "formik";
import Button from "../../../components/Button/Button";
import Modal, {
	ModalCard,
	WarningModal,
} from "../../../components/Modal/Modal";
import cn from "classnames";
import { useState, useEffect, useContext, useRef } from "react";
import Icon from "../../../components/Icon/Icon";
import { PageContainer } from "../../EntrantProgram/EntrantProgram";
import { DynamicPadding } from "../../../globalStyles";
import {
	createEntryNote,
	getEntryById,
	getEntryDetailsForJuror,
	sendHelpEmailRequest,
} from "../../Entries/manageEntry";
import { getCorrectExecutionOrder } from "../../Entries/EditEntry";
import {
	NavTab,
	NavTabList,
	NavTabPanel,
	NavTabs,
} from "../../../components/NavTabs/NavTabs";
import { MediaItem, MediaType } from "../../MediaLibrary/mediaLibrary.model.d";
import ImagePlaceholder from "../../../assets/placeholders/image-landscape.png";
import Loading from "../../../components/Loading/Loading";

import JudgingGalleryNav from "../JudgingGalleryNav";
import CheckboxField from "../../../components/FormFields/CheckboxField";
import MediaCarousel from "../../../components/MediaCarousel/MediaCarousel";

import dateTostring, {
	dateTimeTostring,
	formatDate,
} from "../../../utils/dateToString";
import ClipboardText from "../../../components/ClipboardText/ClipboardText";
import InfoPopup from "../../../components/InfoPopup/InfoPopup";
import MediaLightBox, {
	RenderMediaThumbnail,
	RenderMediaPlayer,
} from "./MediaLightbox";
import { LinkSet, TextType } from "../../Admin/Program/ProgramInterfaces";
import TextField from "../../../components/FormFields/TextField";
import {
	EntrySetAdminCard,
	GalleryQuickVote,
	JudgingRoundStatus,
	Juror,
} from "../../Admin/Judging/JudgingInterfaces";
import { getThumbnailSrc } from "../../MediaLibrary/manageMediaLibrary";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { getToken } from "../../../components/Auth/handleJWT";
import {
	AbstainVoteCSS,
	RenderVoteSelectionButton,
	SelectedVoteButton,
	showNoGrandForAbstain,
	voteButtonColor,
} from "../GalleryCard";
import EntryNotesForm from "./EntryNotesForm";
import InfoModal from "../InfoModal";
import useAbstainModal from "../useAbstainModal";
import EntryDetailMediaSelector from "./EntryDetailMediaSelector";
import parse from "html-react-parser";
import { Label } from "../../../components/FormFields/FieldTemplate";
import { useJurorHub } from "../../../hooks/useJurorHub";
import TextSize from "../../../components/TextSize/TextSize";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import { useAlert } from "../../../components/Alert/Alerts";

// const extractTextFromTag = (tag: any) => {
//   var span = document.createElement("span");
//   tag.innerHTML = s;
//   return span.textContent || span.innerText;
// };

const StyledNavTabList = styled(NavTabList)`
	padding: 0;
	height: fit-content;
	background: transparent;
	/* padding-top: 0.25rem;
  padding-bottom: 0.25rem !important; */
	width: 100%;

	@media (min-width: 1400px) {
		width: 850px;
	}

	li {
		margin: 0 1.5rem !important;
		padding-top: 0;
		top: 0;
		padding-bottom: 4px;
	}
`;

const VoteButtonContainer = styled.div<{ isGrand: boolean }>`
	position: relative;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 1rem;
	padding: 1rem;
	padding-bottom: 51px;
	box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};

	.abstain {
		${AbstainVoteCSS};
		position: absolute;
		bottom: 0;
		left: 0;
		height: 35px;
	}

	${(p) =>
		p.isGrand &&
		`
  padding-bottom: 1rem;
  `}
`;

const HoverButton = styled(Button)`
	&:hover {
		filter: brightness(75%);
	}
`;

const PhysicalComponentFlag = (props: { show: boolean; color: string }) => {
	return props.show ? (
		<div className="flex items-center gap-[.5rem]">
			<Icon icon="cube" color={props.color} width="18px" height="18px" />
			<span className="font-bold">Physical Component Present</span>
		</div>
	) : (
		<></>
	);
};

const ContainerWithPadding = styled.div`
	/* ${DynamicPadding}; */
	padding: 2rem max(2rem, calc((100vw - 1600px) / 2));
	margin-bottom: 232px;

	@media only screen and (min-width: ${({ theme }) => theme.xs}) {
		margin-bottom: 155px;
	}

	@media only screen and (min-width: ${({ theme }) => theme.sm}) {
		padding: 4.125rem max(3rem, calc((100vw - 1600px) / 2));
	}

	@media only screen and (min-width: ${({ theme }) => theme.lg}) {
		padding: 4.125rem max(3rem, calc((100vw - 1600px) / 2));
		margin-bottom: 101px;
	}
`;

const RenderTextField = (props: RenderTextFieldProps) => {
	const theme = useTheme();
	const FieldWrapper = (fieldWrapperProps: { children: React.ReactNode }) => (
		<span className="flex items-center gap-[.5rem] flex-wrap lg:flex-nowrap">
			{fieldWrapperProps.children}
			{props.jurorHelpText && props.jurorHelpText !== " " && (
				<InfoPopup>{props.jurorHelpText}</InfoPopup>
			)}
		</span>
	);

	if (props.text && props.text !== " ") {
		if (props.textType === TextType.Paragraph) {
			return (
				<div className="flex flex-col gap-[.5rem]">
					<span className="flex items-center gap-[.5rem]">
						<p className={`text-[1.125rem] font-medium lg:whitespace-nowrap"`}>
							{props.title}
						</p>
						{props.jurorHelpText && props.jurorHelpText !== " " && (
							<InfoPopup>{props.jurorHelpText}</InfoPopup>
						)}
					</span>

					<p className="whitespace-pre-wrap mx-[2rem] tracking-[.3px] leading-[1.625rem]">
						{props.text}
					</p>
				</div>
			);
		} else
			return (
				<FieldWrapper>
					<p className={`text-[1.125rem] font-medium lg:whitespace-nowrap"`}>
						{props.title}:
					</p>
					<p className="leading-[1.125rem]">{props.text}</p>
				</FieldWrapper>
			);
	} else return <></>;
};

export const RenderLinkField = (props: LinkField) => {
	const theme = useTheme();
	const getHTTPLink = (url: string) => {
		if (url.indexOf("https://") === 0 || url.indexOf("http://") === 0) {
			return url;
		} else {
			return `https://${url}`;
		}
	};

	if (props.links && props.links.length > 0) {
		return (
			<div className="flex flex-col gap-[.5rem]">
				<span className="flex items-center gap-[.5rem] flex-wrap">
					<p className="text-[1.125rem] font-medium">{props.title}</p>

					{props.jurorHelpText && props.jurorHelpText !== " " && (
						<InfoPopup>{props.jurorHelpText}</InfoPopup>
					)}
				</span>
				{props.links.map((linkSet) => {
					return (
						<div className="flex flex-col">
							<a
								className="text-colorActivation font-semibold mr-[1rem] break-all"
								href={getHTTPLink(linkSet.link)}
								target="_blank"
								rel="noreferrer"
							>
								{linkSet.link}
							</a>
							<div className="flex items-center gap-x-[2rem] flex-wrap">
								{linkSet.username && (
									<ClipboardText
										displayText={linkSet.username}
										copyText={linkSet.username}
										toolTip="Copy username"
										copiedToolTip="Username copied!"
										icon={
											<Icon
												icon="user"
												width="15px"
												height="15px"
												color={theme.colorCopyLight}
											/>
										}
									/>
								)}
								{linkSet.password && (
									<ClipboardText
										displayText={linkSet.password}
										copyText={linkSet.password}
										toolTip="Copy password"
										copiedToolTip="Password copied!"
										icon={
											<Icon
												icon="lock"
												width="17px"
												height="17px"
												color={theme.colorCopyLight}
											/>
										}
									/>
								)}
							</div>
						</div>
					);
				})}
			</div>
		);
	} else return <></>;
};

const RenderNonMediaExecutionFields = (props: {
	activeExecution: Execution;
	isCampaign: boolean;
}) => {
	const hasNonMediaFields =
		props.activeExecution.listFields.length > 0 ||
		props.activeExecution.shortTextFields.length > 0 ||
		props.activeExecution.dateFields.length > 0 ||
		props.activeExecution.linkFields.length > 0 ||
		props.activeExecution.longTextFields.length > 0;

	return (!props.isCampaign && hasNonMediaFields) || props.isCampaign ? (
		<>
			<div className="flex flex-col p-[1.5rem] gap-[1rem]">
				{/* only for SINGLE ENTRIES, show "Executions Details" */}
				{!props.isCampaign && <h2>Execution Details</h2>}

				{props.activeExecution.title && <h2>{props.activeExecution.title}</h2>}

				{/* only for CAMPAIGNS, show Execution-Level Program Name */}
				{props.isCampaign &&
					props.activeExecution.programLabel &&
					props.activeExecution.programName && (
						<b>
							{props.activeExecution.programLabel}:{" "}
							{props.activeExecution.programName}
						</b>
					)}
				{props.activeExecution.listFields?.map((field, i) => (
					<RenderTextField {...field} key={i} />
				))}
				{props.activeExecution.shortTextFields?.map((field, i) => (
					<RenderTextField {...field} key={i} />
				))}
				{props.activeExecution.dateFields?.map((field, i) => (
					<RenderTextField {...field} key={i} />
				))}
				{props.activeExecution.linkFields?.map((field, i) => (
					<RenderLinkField {...field} key={i} />
				))}
				{props.activeExecution.longTextFields?.map((field, i) => (
					<RenderTextField {...field} key={i} />
				))}
			</div>
			<hr />
		</>
	) : (
		<></>
	);
};

const EntryDetailPage = () => {
	const {
		juryId,
		entrySetId,
		batchId,
		entryId,
		voteId,
		vote,
		entryDetails,
		jurorJuryCard: jurorViewCard,
		updateVote,
		next,
		previous,
		isRoundEntryLocked,
		isRoundEntryHidden,
		entrySetCard,
		settings,
		highlightedEntryUrl,
	} = useJurorHub();
	const { showAbstainModal } = useAbstainModal();
	const theme = useTheme();
	const history = useHistory();
	const location = useLocation();
	const [entryLocked, setEntryLocked] = useState(true);
	const { addNewAlert } = useAlert();
	const [showModal, setShowModal] = useState<false | NavModalType>(false);
	const [showLightbox, setShowLightbox] = useState(false);
	const [activeExecutionIndex, setActiveExecutionIndex] = useState(0);
	const [activeMediaPreview, setActiveMediaPreview] =
		useState<MediaItem | null>(null);
	const [isZoomed, setIsZoomed] = useState(false); // for image zoom

	// used to automatically play the first media when navigating executions
	const [firstMediaPerExecution, setFirstMediaPerExecution] = useState<
		MediaItem[]
	>([]);

	const [executions, setExecutions] = useState<Execution[] | undefined>(
		entryDetails?.executions
	);
	const [liveJudgingJurorActive, setLiveJudgingJurorActive] = useState(false);

	const formikProps = useFormik({
		initialValues: {
			...entryDetails,
			executions: executions,
			newNote: "",
			isNewNotePrivate: "", // unchecked (false) by default

			helpEmailRequest: "",
		},
		enableReinitialize: true,
		onSubmit: async (value) => {
			//console.log({value});
		},
	});

	const activeExecution = executions && executions[activeExecutionIndex];

	useEffect(() => {
		if (entrySetCard.id !== 0) {
			switch (entrySetCard.roundStatus) {
				case JudgingRoundStatus.Completed:
				case JudgingRoundStatus.Pending:
				case JudgingRoundStatus.Pause:
				case JudgingRoundStatus.Ready:
					history.push(`/judge/${juryId}`);
					break;
				default:
					break;
			}
		}
		if (isRoundEntryHidden) {
			history.push(`/judge/${juryId}`);
		}
	}, [entrySetCard.roundStatus, isRoundEntryHidden]);

	useEffect(() => {
		if (entrySetCard.id !== 0) {
			setEntryLocked(
				entrySetCard.entriesLocked ||
					isRoundEntryLocked ||
					(jurorViewCard && jurorViewCard.entriesLocked) ||
					false
			);
		}
	}, [
		entrySetCard.entriesLocked,
		isRoundEntryLocked,
		jurorViewCard?.entriesLocked,
	]);

	useEffect(() => {
		setActiveMediaPreview(null);
	}, [location]);

	useEffect(() => {
		if (settings) {
			if (settings.isActive === true && settings.jurorControl === true) {
				setLiveJudgingJurorActive(true);
			} else {
				setLiveJudgingJurorActive(false);
			}
		}
	}, [settings.isActive, settings.jurorControl]);

	const handleRequestEmail = () => {
		const messageBody = formikProps.values.helpEmailRequest;

		if (!messageBody || messageBody?.length === 0 || messageBody?.trim() == "")
			return;

		const payloadForApi: RequestEmail = {
			entryId: Number(entryId),
			messageBody: messageBody,
			jurorViewPath: `/judge/${juryId}/${entrySetId}/${batchId}/${entryId}/${voteId}`,
		};

		sendHelpEmailRequest(payloadForApi).then((response) => {
			if (response.status === 200) {
				addNewAlert({ type: "success", message: "Help request sent." });
			} else {
				addNewAlert({
					type: "error",
					message:
						"An error occurred while trying to send the email. Please try again.",
				});
			}

			setShowModal(false);
		});
	};

	// play first media when switching execution tabs
	const handleExecutionTab = (tabIndex: number) => {
		const executions = formikProps.values.executions;
		const firstMediaFieldIndex =
			executions &&
			executions[tabIndex] &&
			executions[tabIndex].mediaFields.findIndex(
				(field) => field.media.length > 0
			);

		const firstMedia =
			firstMediaFieldIndex !== undefined &&
			firstMediaFieldIndex !== -1 &&
			executions![tabIndex].mediaFields[firstMediaFieldIndex!].media[0];

		firstMedia && setActiveMediaPreview(firstMedia);
	};

	useEffect(() => {
		handleExecutionTab(0);
	}, [formikProps.values.executions]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [next, previous]);

	const hasEntryLevelMedia =
		entryDetails?.mediaFields &&
		entryDetails?.mediaFields.length > 0 &&
		entryDetails?.mediaFields.some((field) => field.media.length > 0);

	const addEntryMediaAsExecution = (
		entryDetails: EntryDetailsModel
	): Execution[] => {
		if (hasEntryLevelMedia) {
			// if there's at least 1 media item
			// add entry level media as the first execution
			const copy = [...entryDetails.executions];
			const entryMediaExecution: Execution = {
				index: 0,
				programLabel: "",
				programDescription: "",
				id: 1,
				title: "",
				programName: "",
				listFields: [],
				shortTextFields: [],
				longTextFields: [],
				dateFields: [],
				linkFields: [],
				mediaFields: entryDetails.mediaFields,
			};
			copy.unshift(entryMediaExecution);
			const newIndexedExecutions = copy.map((exe, i) => {
				const entryMediaTitle = () => {
					const isCampaign = entryDetails.executions.length > 1;
					if (i === 0) {
						return isCampaign ? "Entry Level Media" : `Media ${i + 1}`;
					} else {
						return isCampaign ? exe.title : `Media ${i + 1}`;
					}
				};

				return {
					...exe,
					index: i,
					title: entryMediaTitle(),
				};
			});

			return newIndexedExecutions;
		} else return entryDetails.executions;
	};

	// remove duplicate media
	useEffect(() => {
		if (entryDetails && entryDetails.executions) {
			const executionsWithEntryMedia = addEntryMediaAsExecution(entryDetails);

			let unqiueMediaIdArr: string[] = [];
			const filteredMediaExecutions = executionsWithEntryMedia.map(
				(execution) => {
					return {
						...execution,
						mediaFields: execution.mediaFields.map((mediaField) => {
							return {
								...mediaField,
								media: mediaField.media.flatMap((media) => {
									//   only return media items if it doesn't exist in another field or execution
									if (media.id && unqiueMediaIdArr.includes(media.id)) {
										return [];
									} else {
										media.id && unqiueMediaIdArr.push(media.id);
										return media;
									}
								}),
							};
						}),
					};
				}
			);

			setExecutions(filteredMediaExecutions);
		}
	}, [entryDetails]);

	const isOneToTen =
		vote && vote.voteOption
			? !isNaN(parseFloat(vote.voteOption.name))
			: undefined;

	const [abstainVoteIndex, setAbstainVoteIndex] = useState(
		entrySetCard.voteOptions.findIndex((option) => option.name === "Abstain")
	);

	useEffect(() => {
		setAbstainVoteIndex(
			entrySetCard.voteOptions.findIndex((option) => option.name === "Abstain")
		);
	}, [entrySetCard.voteOptions]);

	return entryDetails && Object.values(formikProps.values).length > 0 ? (
		<PageContainer>
			<BrowserHeader title={`Vote: [${entryId}] ${formikProps.values.title}`} />
			<JudgingGalleryNav
				roundName={entrySetCard.roundType}
				roundDeadline={entrySetCard.deadline!}
				entrySetCard={entrySetCard}
				entrySetId={entrySetId}
				jurorJuryCard={jurorViewCard}
				juryId={juryId}
				highlightedEntryUrl={highlightedEntryUrl}
				liveJudgingJurorActive={liveJudgingJurorActive}
				award={settings.award}
			/>
			<ContainerWithPadding>
				<FormikProvider value={formikProps}>
					<Modal show={showModal === NavModalType.Help}>
						{showModal !== false && (
							<ModalCard
								title="Need Help"
								headerIcons={
									<Icon
										icon="close"
										color={theme.colorPrimary}
										onClick={() => setShowModal(false)}
										width="35px"
										height="35px"
									/>
								}
								iconColor={theme.colorPrimary}
							>
								<div className="flex flex-col gap-[2rem] p-[2rem]">
									<b>Message us here with your questions or concerns</b>

									<TextField
										name="helpEmailRequest"
										component="textarea"
										placeholder="Add message"
									/>
								</div>

								<Button
									className="w-full mt-auto"
									icon="plus"
									onClick={() => handleRequestEmail()}
								>
									Send Message
								</Button>
							</ModalCard>
						)}
					</Modal>

					<Modal show={showModal === NavModalType.Notes}>
						{showModal !== false && (
							<EntryNotesForm
								showModal={setShowModal}
								entryId={entryId}
								juryId={juryId}
							/>
						)}
					</Modal>

					<InfoModal
						show={showModal === NavModalType.Tip}
						onHide={() => setShowModal(false)}
						roundType={entrySetCard.roundType}
						jurorInstructions={entrySetCard.jurorInstructions || ""}
					/>

					<MediaLightBox
						show={showLightbox}
						onClickHide={() => setShowLightbox(false)}
						activeExecutionIndex={activeExecutionIndex}
						executions={executions}
						selectedMediaPreview={activeMediaPreview}
						isZoomEnabled
					/>

					<div className="flex gap-[2rem] relative flex-wrap xl:flex-nowrap">
						<StickyCard className="!relative xl:!sticky !top-0 xl:!top-[2rem] !min-h-full xl:max-w-[400px]">
							<StickyCardHeader className="h-[95px]">
								<h2 className="playfair-500">Voting</h2>

								{vote && vote.voteOption && (
									<SelectedVoteButton
										className={`font-semibold ${
											isOneToTen ? "!text-colorBackgroundMedium" : ""
										}`}
										background={voteButtonColor(vote.voteOption.name, theme)}
									>
										{showNoGrandForAbstain(vote)}
									</SelectedVoteButton>
									//   <Button
									//     className={`cursor-default ${
									//       vote.voteOption.name === "In"
									//         ? "button-vote-in"
									//         : vote.voteOption.name === "Out"
									//         ? "button-vote-out"
									//         : "button-vote-abstain"
									//     }

									//   `}
									//   >
									//     {vote.voteOption.name}
									//   </Button>
								)}
							</StickyCardHeader>
							<StickyCardBody className="flex flex-col gap-[1rem] lg:!px-[1.5625rem]">
								<div className="relative flex gap-[1rem] flex-wrap xxl:flex-nowrap">
									<div className="min-w-[178px] min-h-[100px] w-[178px] h-[100px]">
										<img
											className="object-cover w-full h-full"
											src={
												formikProps.values.coverImage
													? getThumbnailSrc(formikProps.values.coverImage!)
													: ImagePlaceholder
											}
											alt="Cover Image"
										/>
									</div>

									<div className="flex flex-col gap-[1rem]">
										<p>{formikProps.values.brand}</p>
										<TextSize
											characterCount={
												formikProps.values.title
													? formikProps.values.title.length
													: 0
											}
											breakpoints={[
												{
													characterCount: 40,
													fontSize:
														Number(theme.h3Size.replaceAll("px", "")) - 2,
												},
											]}
										>
											<h3>{formikProps.values.title}</h3>
										</TextSize>
									</div>
								</div>
								{entrySetCard.isFeedbackRequired && (
									<>
										<h3>My Feedback</h3>
										<p>
											Enter your feedback/critique of this entry and explain
											your vote here.*
										</p>

										<TextField
											name="feedback"
											component="textarea"
											hiddenlabel
										/>
									</>
								)}
								{entryLocked && (
									<h3 className="flex items-center gap-[.75rem] mt-[1rem]">
										<Icon
											icon="lock"
											color={theme.colorPrimary}
											width="25px"
											height="25px"
										/>
										Entry Locked
									</h3>
								)}

								{entrySetCard.roundType === "Winner" ? (
									<>
										{!entryLocked && (
											<Button
												className="button-light"
												icon="caret"
												iconRotation="90deg"
												iconColor={theme.colorCopyDarkDark}
												to={`/judge/${entrySetCard.juryId}/${entrySetCard.id}`}
											>
												Return to Gallery
											</Button>
										)}
									</>
								) : (
									<>
										{!entryLocked && <h3 className="mt-[1rem]">My Vote</h3>}

										<VoteButtonContainer
											isGrand={entrySetCard.isSingleChoiceVoting}
										>
											{entrySetCard.isSingleChoiceVoting ? (
												<Button to={`/judge/${juryId}/${entrySetId}`}>
													Cast Vote in Gallery
												</Button>
											) : (
												<div
													className={
														entrySetCard.roundType === "1-10"
															? "flex flex-wrap justify-center xs:!grid xs:grid-cols-5 justify-items-center gap-[1rem]"
															: "flex flex-wrap justify-center gap-[1rem]"
													}
												>
													{entrySetCard.voteOptions.flatMap((voteOption) => {
														if (voteOption.name !== "Abstain") {
															return (
																<RenderVoteSelectionButton
																	disabled={entryLocked}
																	key={voteOption.id}
																	voteOption={voteOption}
																	onClick={() => {
																		updateVote(
																			Number(voteId),
																			Number(entrySetId),
																			Number(batchId),
																			entrySetCard.activeRoundId,
																			voteOption.id,
																			entrySetCard.roundType
																		);
																	}}
																/>
															);
														} else return [];
													})}
												</div>
											)}

											{!entrySetCard.isSingleChoiceVoting && !entryLocked && (
												<div
													className="abstain"
													onClick={() => {
														if (abstainVoteIndex !== -1) {
															if (
																localStorage.getItem("showed_abstain_modal")
															) {
																// Cast abstain vote without showing the modal
																updateVote(
																	Number(voteId),
																	Number(entrySetId),
																	Number(batchId),
																	entrySetCard.activeRoundId,
																	Number(
																		entrySetCard.voteOptions[abstainVoteIndex]
																			.id
																	),
																	entrySetCard.roundType
																);
															} else {
																// Show abstain modal before casting the vote
																showAbstainModal(() =>
																	updateVote(
																		Number(voteId),
																		Number(entrySetId),
																		Number(batchId),
																		entrySetCard.activeRoundId,
																		Number(
																			entrySetCard.voteOptions[abstainVoteIndex]
																				.id
																		),
																		entrySetCard.roundType
																	)
																);
															}
														} else {
															addNewAlert({
																type: "error",
																message:
																	"Failed to cast abstain vote. Please try again later",
															});
														}
													}}
												>
													Abstain from Voting
												</div>
											)}
										</VoteButtonContainer>
									</>
								)}
							</StickyCardBody>
						</StickyCard>
						{executions && (
							<StickyCard className="!top-0 !relative xl:!sticky !max-h-fit xl:min-w-[800px]">
								<div className="w-full  bg-colorBackgroundDarkDark">
									{executions.length > 1 ? (
										<>
											{!hasEntryLevelMedia && (
												<h2 className="mx-6 mt-6 text-colorCopyLightLight playfair-500">
													Campaign Executions
												</h2>
											)}
											<div className="absolute text-colorCopyLightLight top-6 right-6">{`${
												activeExecutionIndex + 1
											}/${executions.length}`}</div>

											<MediaCarousel>
												{executions &&
													executions.map((execution: Execution, i: number) => (
														<div
															className={cn(
																"text-colorCopyLight mx-6 py-4 text-lg w-full whitespace-nowrap min-w-[100px]",
																{
																	"font-bold !text-colorCopyLightLight":
																		activeExecutionIndex === i,
																	"cursor-pointer": executions?.length > 1,
																	"border-b-[5px]":
																		activeExecutionIndex === i &&
																		executions?.length > 1,
																}
															)}
															style={{ borderColor: theme.colorActivation }}
															key={`tabLabel.${i}`}
															onClick={() => setActiveExecutionIndex(i)}
														>
															{execution.title || `Execution ${i + 1}`}
														</div>
													))}
											</MediaCarousel>
										</>
									) : (
										<div
											className={cn(
												"flex justify-between playfair-500 text-colorCopyLightLight px-6 p-4 text-lg w-full whitespace-nowrap min-w-[100px]"
											)}
										>
											{hasEntryLevelMedia
												? executions[0]?.title || "Single Entry"
												: "Media"}

											<PhysicalComponentFlag
												show={
													entryDetails.hasPhysicalComponent &&
													settings.showPhysicalComponents
												}
												color={theme.colorCopyLightLight}
											/>
										</div>
									)}
								</div>
								{activeExecution?.mediaFields &&
									activeExecution.mediaFields.length > 0 && (
										<>
											<EntryDetailMediaSelector
												activeExecution={activeExecution}
												activeExecutionIndex={activeExecutionIndex}
												activeMediaPreview={activeMediaPreview}
												onMediaSelect={(mediaItem) => {
													setIsZoomed(false);
													setActiveMediaPreview(mediaItem);
												}}
											/>
											<div className="w-full h-[500px] overflow-hidden bg-colorBackgroundDarkDark pt-[1rem]">
												<RenderMediaPlayer
													media={activeMediaPreview}
													pauseMedia={showLightbox}
													isZoom={isZoomed}
													isZoomEnabled
												/>
											</div>

											<div className="flex items-center justify-end bg-colorBackgroundDarkDark p-[1rem] gap-[1rem] flex-wrap">
												{activeMediaPreview?.type === MediaType.Image && (
													<Button
														className="button-transparent button-border-light w-[160px]"
														icon="search"
														onClick={() => setIsZoomed(!isZoomed)}
													>
														{isZoomed ? "Zoom Out" : "Zoom In"}
													</Button>
												)}

												<Button
													className="button-transparent button-border-light w-[160px]"
													icon="expand-arrows"
													onClick={() => setShowLightbox(true)}
												>
													Expand
												</Button>
											</div>
										</>
									)}
								{activeExecution &&
									formikProps?.values?.executions &&
									formikProps?.values?.executions.length > 0 &&
									activeExecution.title !== "Entry Level Media" && (
										<RenderNonMediaExecutionFields
											activeExecution={activeExecution}
											isCampaign={formikProps?.values?.executions.length > 1}
										/>
									)}

								<div className="flex flex-col p-[1.5rem] gap-[2.5rem]">
									<h2>Entry Details</h2>
									<PhysicalComponentFlag
										show={
											entryDetails.hasPhysicalComponent &&
											settings.showPhysicalComponents
										}
										color={theme.colorCopyDarkDark}
									/>
									<div className="flex flex-col gap-[1rem]">
										{/* {activeExecution &&
                    formikProps?.values?.executions &&
                    formikProps?.values?.executions.length === 1 && (
                      <RenderNonMediaExecutionFields
                        activeExecution={activeExecution}
                      />
                    )} */}

										{formikProps.values.ancestry &&
											formikProps.values.ancestry.length > 0 &&
											formikProps.values.ancestry.flatMap((level, i) => (
												<span className="flex items-center gap-[.5rem]">
													<b key={i}>
														{level.programLabel}: {level.programName}
													</b>
													{level.description && (
														<InfoPopup className="p-[0.25rem]">
															{level.description
																.replaceAll("<p>", "")
																.replaceAll("</p>", "")}
														</InfoPopup>
													)}
												</span>
											))}

										{formikProps.values.entryTypeTitle &&
											formikProps.values.entryTypeValue && (
												<span className="flex items-center gap-[.5rem]">
													<b>
														{formikProps.values.entryTypeTitle}:{" "}
														{formikProps.values.entryTypeValue}
													</b>
													{formikProps.values.entryTypeJurorHelpText && (
														<InfoPopup>
															{formikProps.values.entryTypeJurorHelpText
																.replaceAll("<p>", "")
																.replaceAll("</p>", "")}
														</InfoPopup>
													)}
												</span>
											)}

										<b>Entry ID: {formikProps.values.id}</b>
									</div>
									{formikProps.values.listFields?.map((field, i) => (
										<RenderTextField {...field} key={i} />
									))}
									{formikProps.values.shortTextFields?.map((field, i) => (
										<RenderTextField {...field} key={i} />
									))}
									{formikProps.values.dateFields?.map((field, i) => (
										<RenderTextField {...field} key={i} />
									))}
									{formikProps.values.linkFields?.map((field, i) => (
										<RenderLinkField {...field} key={i} />
									))}
									{formikProps.values.longTextFields?.map((field, i) => (
										<RenderTextField {...field} key={i} />
									))}
								</div>
							</StickyCard>
						)}
					</div>
				</FormikProvider>
			</ContainerWithPadding>
			<EntryDetailNav
				showModal={(type) => setShowModal(type)}
				juryId={juryId}
				entrySetId={entrySetId}
				batchId={batchId}
				next={next}
				previous={previous}
			/>
		</PageContainer>
	) : (
		<Loading />
	);
};

export default EntryDetailPage;

export interface EntryDetailsModel extends ProgramWithFields {
	brand: string;
	isCampaign: boolean;
	coverImage: string;
	// entryType: string;
	hasPhysicalComponent: boolean;
	entryTypeTitle: string;
	entryTypeJurorHelpText?: string;
	entryTypeValue: string;
	ancestry: Ancestry[];
	executions: Execution[];
}

interface RenderTextFieldProps extends DisplayTextField {
	smallTitle?: boolean;
}

interface DisplayTextField {
	title: string;
	text: string;
	jurorHelpText: string;
	textType: TextType;
	fieldWeight?: number;
}

interface LinkField {
	title: string;
	links: LinkSet[];
	jurorHelpText: string;
	fieldWeight?: number;
}

interface ProgramWithFields {
	id: number;
	title: string;
	programName: string;
	listFields: DisplayTextField[];
	shortTextFields: DisplayTextField[];
	longTextFields: DisplayTextField[];
	dateFields: DisplayTextField[];
	linkFields: LinkField[];
	mediaFields: MediaField[];
}

export interface Execution extends ProgramWithFields {
	index: number;
	programLabel: string;
	programDescription: string;
}

interface Ancestry {
	displayOrder: number;
	description?: string;
	programLabel: string;
	programName: string;
}

export interface MediaField {
	title: string;
	media: MediaItem[];
	jurorHelpText: string;
	mediaType: MediaType;
}

interface JuryNotesData {
	title: string;
	description: string;
	isPrivate: boolean;
	date: Date;
}

export interface EntryNote {
	id: number;
	entryId: number;
	note?: string;
	isPrivate: boolean;
	createdDate?: Date;
	updatedDate?: Date;
	nameCreatedBy?: string;
	createdBy?: string;
	nameModifiedBy?: string;
	modifiedBy?: string;
}

export interface PreviousNextEntry {
	juryId: number; // will be same as this page
	entrySetId: number; // will be same as this page
	batchId: number; // might be different than this page
	entryId: number;
	voteId: number;
	coverImage?: string;
}

export interface RequestEmail {
	entryId: number;
	messageBody: string;
	jurorViewPath: string;
}
