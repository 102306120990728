import { ErrorMessage, Field } from "formik";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import styled, { useTheme, css } from "styled-components";
import {
	FocusBorder,
	Label,
	FormField,
	FieldSuccess,
	SUCCESS_MESSAGE_DURATION,
} from "./FieldTemplate";
import { svgString } from "../../globalStyles";
import { useFormikContext } from "formik";
import { FieldError } from "./FieldTemplate";
import Icon from "../Icon/Icon";
import { SquareButton } from "../Button/Button";
import FieldWrapper from "./FieldWrapper";

const TextBox = styled(Field)<TextBoxProps>`
	position: relative;
	font-size: 1rem;
	height: 56px;
	padding: 1.25rem 1rem 0.375rem;
	width: 100%;
	/* min-width: 250px; */

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	&:focus {
		outline: none;
	}

	&:-webkit-autofill {
		animation-name: onAutoFillStart;
		/* box-shadow: 0 2px 8px 0 red; */
	}

	// use default textarea height of 12.5rem if height NOT specified
	${(p) =>
		p.component === "textarea" &&
		css`
			padding: 30px 16px 6px;
			height: 12.5rem;
			/* line-height: 2.15; */
			line-height: 1.5rem;
			white-space: break-spaces;
			overflow-y: auto;
		`}

	${(p) =>
		p.height &&
		css`
			padding: 25px 16px 6px;
			height: ${p.height};
		`}

     ${(p) =>
		p.labelhidden === "true" &&
		css`
			padding: 1rem 1.25rem;
		`}

     ${(p) =>
		p.iscurrency &&
		css`
			padding: 1.25rem 1rem 0.375rem 1.75rem;
		`}


  &[type="number"]::-webkit-inner-spin-button {
		opacity: 1;
	}
`;

const TextField = (props: TextFieldProps) => {
	const {
		values,
		setFieldValue,
		errors,
		setFieldError,
		validateField,
		resetForm,
		initialValues,
	} = useFormikContext<any>();
	const [textBoxFocused, setTextBoxFocused] = useState(false);
	const [previousValue, setPreviousValue] = useState<
		string | number | undefined
	>(props.value);
	const theme = useTheme();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.onChange && props.onChange(e);

		const newValue = Number(e.currentTarget.value);

		if (props.type === "number" && props.validatePositive && newValue < 0) {
			// Don't update the form field value if it's less than or equal to 0
			return;
		}

		if (!props.nonFormik) {
			if (props.type === "number") {
				setFieldValue(props.name, Number(e.currentTarget.value));
			} else {
				setFieldValue(props.name, e.currentTarget.value);
			}
		}

		// validate for max val (default = 9999)
		if (props.type === "number" && Number(e.currentTarget.value) > 9999) {
			setFieldError(
				props.name,
				`${props.name} has a maximum of ${props.max || 9999}`
			);
		} else if (
			props.type === "string" &&
			props.max &&
			e.currentTarget.value?.length > props.max
		) {
			setFieldError(
				props.name,
				`Tag name cannot exceed ${props.max || 9999} characters`
			);
		} else {
			setFieldError(props.name, "");
		}
	};

	const handleBlur = async () => {
		setTextBoxFocused(false);
		props.setTextBoxFocused && props.setTextBoxFocused(false);
		if (props.onBlur && previousValue !== props.value) {
			try {
				setPreviousValue(props.value);
				await props.onBlur();
			} catch (error) {
				console.error("Failed to update property", error);
			}
		}
	};

	useEffect(() => {
		!props.nonFormik && setFieldValue(props.name, props.value); // set formik value
	}, [props.value]);

	return (
		<FieldWrapper
			className={props.className}
			id={`text-field-wrapper-${props.name}`}
			name={props.name}
			success={props.success}
			error={props.error}
			hideSuccessMessage={props.hideSuccessMessage}
		>
			{(success, error) => (
				<>
					<FormField
						className={`${textBoxFocused ? "focused" : ""} ${
							values[props.name] ||
							props.value ||
							props.value === 0 ||
							values[props.name] === 0
								? "hasValue"
								: ""
						}`}
						disabled={props.disabled}
						readOnly={props.readOnly}
						hiddenlabel={props.hiddenlabel ? true : false}
						iscurrency={props.iscurrency ? true : undefined}
						background={props.background}
						hideShadow={props.hideShadow}
						success={success}
						error={error}
					>
						<div className="flex content-center relative overflow-hidden">
							<Label
								className={`${
									props.component === "textarea"
										? "py-[0.5rem] !top-0  w-full"
										: ""
								}`}
								htmlFor={`text-field-${props.name}`}
							>
								{props.placeholder}
							</Label>

							<p className="dollar-symbol">$</p>
							<TextBox
								className={props.textCenter ? "text-center" : undefined}
								id={`text-field-${props.name}`}
								name={props.name}
								onFocus={() => {
									setTextBoxFocused(true);
									props.setTextBoxFocused && props.setTextBoxFocused(true);
									props.onFocus && props.onFocus();
								}}
								onBlur={handleBlur}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(e)
								}
								value={
									(props.type === "number" &&
										(props.value || props.value === 0) &&
										Number(props.value).toString()) ||
									props.value ||
									values[props.name] ||
									(values[props.name] === 0 &&
										Number(values[props.name]).toString()) ||
									""
								}
								disabled={props.disabled || props.readOnly}
								labelhidden={props.hiddenlabel ? "true" : "false"}
								max={props.max || 9999}
								iscurrency={props.iscurrency ? 1 : undefined}
								{...(props.iscurrency && {
									min: 0,
								})}
								{...(props.maxLength && {
									maxLength: props.maxLength,
								})}
								{...(props.type && {
									type: props.type,
								})}
								{...(props.component && {
									component: props.component,
								})}
								{...(props.height && {
									height: props.height,
								})}
								{...(props.onKeyDown && {
									onKeyDown: props.onKeyDown,
								})}
							/>
						</div>

						{!props.hideFocusBorder && <FocusBorder />}
					</FormField>
					{props.onClickSave &&
						initialValues[props.name] !== values[props.name] && (
							<>
								<SquareButton
									className="button-light absolute right-[2.5rem] bottom-[.5rem]"
									onClick={resetForm}
								>
									<Icon
										icon="close"
										color={theme.colorCopyDarkDark}
										width="16px"
										height="16px"
									/>
								</SquareButton>

								<SquareButton
									className="absolute right-[.5rem] bottom-[.5rem]"
									onClick={props.onClickSave}
								>
									<Icon icon="check" color={theme.colorCopyLightLight} />
								</SquareButton>
							</>
						)}
				</>
			)}
		</FieldWrapper>
	);
};

export default TextField;

interface TextFieldProps {
	id?: string;
	name: string;
	placeholder?: string; // label and placeholder
	component?: "textarea";
	type?: "number" | "password" | "string";
	iscurrency?: boolean;
	maxLength?: string;
	className?: string;
	value?: string | number;
	disabled?: boolean;
	readOnly?: boolean;
	hiddenlabel?: boolean;
	height?: string;
	isValid?: boolean;
	onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void; // e.g. pressing enter
	// onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	max?: number;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	nonFormik?: boolean;
	error?: string;
	success?: string;
	onFocus?(): void; // pass prop up for password field
	onBlur?(): void; // pass prop up for password fields
	onClickSave?(): void;
	background?: string;
	hideFocusBorder?: boolean;
	textCenter?: boolean;
	validatePositive?: boolean;
	hideShadow?: boolean;
	hideSuccessMessage?: boolean;
	setTextBoxFocused?(focused: boolean): void;
}

interface TextBoxProps {
	height?: string;
	component?: string;
	labelhidden: string;
	isValid?: boolean;
	error?: string;
	// onChange?: (value: React.ChangeEvent<HTMLFormElement>) => void;
}

export interface InputKeyUp {
	target: HTMLInputElement;
	key: string;
}
