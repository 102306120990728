import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { useTheme, css } from "styled-components";
import {
	ThumbnailSize,
	checkS3ForImg,
	getMedia,
	getPlaceholder,
	getThumbnailSrc,
} from "../../views/MediaLibrary/manageMediaLibrary";
import {
	MediaItem,
	MediaType,
	Tag,
} from "../../views/MediaLibrary/mediaLibrary.model.d";
import Icon from "../../components/Icon/Icon";
import { FileDrop } from "react-file-drop";
import useFileHandlers, {
	FILE_UPLOAD_COUNT_MESSAGE,
	MEDIA_UPLOAD_ERR,
	api,
} from "../../hooks/useFileHandlers";
import { allProgressSettled } from "../../views/Admin/Media/allProgressSettled";
import { allProgress } from "../../views/Admin/Media/allProgress";
import { UploadProgress } from "../../views/Admin/Media/MediaUpload";
import axios from "axios";
import { DragMediaItem } from "../Draggable/DraggableMedia";
import assetsConfig from "../../assetsConfig";
import { useAlert } from "../Alert/Alerts";

const CheckeredBackgroundImage = assetsConfig.placeholders.imageBackground;
export const SpinnerSrc = assetsConfig.loading.primary;

export const SpinnerContainer = styled.div<{
	show: boolean;
	background?: string;
}>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;
	background: ${(p) =>
		p.background ? p.background : p.theme.colorBackgroundLight};
	justify-content: center;
	align-items: center;
	flex-direction: column;

	display: ${(p) => (p.show ? "flex" : "none")};

	img {
		width: 75px;
		margin: 0 auto;
	}
`;

const FileUploadContainer = styled.div<{
	borderNone?: boolean;
	height?: string;
	fill?: string;
	disabled?: boolean;
}>`
	width: 100%;
	cursor: ${(p) => (p.disabled ? "default" : "pointer")};

	box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};

	${(p) =>
		p.fill &&
		css`
			background: ${p.fill};
		`}
	.upload-icon, .search-icon {
		color: ${({ theme }) => theme.colorCopyLight};
		font-size: ${({ theme }) => theme.h1Size};
		margin: 1rem auto;
	}
	.background_image {
		background-image: url(${CheckeredBackgroundImage});
		height: 100%;
		background-size: contain;
		border: 1px solid ${({ theme }) => theme.colorBorderLight} !important;
	}

	.file-drop {
		/* relatively position the container bc the contents are absolute */
		position: relative;
		height: ${(p) => (p.height ? p.height : `194px`)};
		width: 100%;
		border: ${(p) =>
			p.borderNone ? `1px solid transparent` : `1px dashed lightgrey`};
		padding: 20px;
		background: ${(p) => (p.fill ? p.fill : "transparent")};
		overflow: hidden;
	}

	.file-drop > .file-drop-target {
		/* basic styles */
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		border-radius: 2px;

		/* horizontally and vertically center all content */
		display: flex;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;

		flex-direction: column;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;

		align-items: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;

		justify-content: center;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;

		align-content: center;
		-webkit-align-content: center;
		-ms-flex-line-pack: center;

		text-align: center;
	}

	.file-drop > .file-drop-target.file-drop-dragging-over-frame {
		/* overlay a black mask when dragging over the frame */
		border: none;
		/* background: ${({ theme }) => theme.colorPrimary}; */
		box-shadow: none;
		z-index: 50;
		opacity: 1;

		/* typography */
		/* color: white; */
	}

	.file-drop > .file-drop-target.file-drop-dragging-over-target {
		/* activate background color when we are dragging over the target */
		p {
			color: ${({ theme }) => theme.colorCopyLightLight};
		}

		.search-icon svg g {
			stroke: ${({ theme }) => theme.colorCopyLightLight};
		}

		background: ${({ theme }) => theme.colorActivation};

		.upload-icon {
			svg {
				polygon {
					fill: ${({ theme }) => theme.colorCopyLightLight};
					stroke: ${({ theme }) => theme.colorCopyLightLight};
				}

				g {
					stroke: ${({ theme }) => theme.colorCopyLightLight};
				}
			}
			/* color: ${({ theme }) => theme.colorCopyLightLight}; */
		}
	}
`;

// used with the DraggableMediaList to upload files from the local computer
const LocalFileUpload = (props: LocalFileUploadProps) => {
	const theme = useTheme();
	const { addNewAlert } = useAlert();
	const {
		files,
		pending,
		next,
		uploading,
		uploaded,
		status,
		onSubmit,
		onChange,
		reset,
	} = useFileHandlers({ companyId: props.companyId });

	const fileInputRef = useRef<any>(null);

	const [acceptedFiles, setAcceptedFiles] = useState(
		"image/png, image/jpeg, application/pdf"
	);

	// for progress indicator
	const [showLoading, setShowLoading] = useState(false);
	const [filesUploaded, setFilesUploaded] = useState<number>(0);
	const [thumbnailLoaded, setThumbnailLoaded] = useState(0);

	// check if the upload file limit is reached
	const wouldExceedMax =
		props.max !== undefined && props.max !== 0
			? props.droppedMedia.length !== 0 &&
			  props.droppedMedia.length === props.max
			: false; // check if the upload file limit has been reached

	// upload media file to s3 bucket
	useEffect(() => {
		if (!wouldExceedMax) {
			if (props.onUploadSuccess && files.length > 0) {
				(async () => {
					setShowLoading(true);

					let uploadFilePromises: any[] = [];
					let numberOfDupes = 0;
					files.forEach((fileObj: FileObj, i: number) => {
						if (
							props.droppedMedia.some((x) => x.fileName === fileObj.file.name)
						) {
							numberOfDupes++;
							return;
						}
						uploadFilePromises.push(
							api.uploadFile(fileObj.file, props.companyId, props.tags)
						);
					});

					if (files.length === numberOfDupes) {
						addNewAlert({
							type: "error",
							message: "A file with that name already exists.",
						});
						setShowLoading(false);
						reset();
						return; // This will exit the useEffect hook's callback function.
					}

					// wait for file upload to settle (success or failure)
					const results = await allProgressSettled(
						uploadFilePromises,
						(fileCount) => setFilesUploaded(fileCount)
						// setLoadingMessage(`${filesUploaded}/${files.length} Files Uploaded`)
					);

					// check if any promises were rejected
					const anyRejected = results.some(
						(result) => result.status === "rejected"
					);
					const anySuccessfull = results.some(
						(result) => result.status === "fulfilled"
					);

					if (anySuccessfull) {
						const imgThumbnailPromises: any[] = [];

						results.forEach((result) => {
							if (result.status === "fulfilled") {
								// push promise to check for img thumbnail
								if (
									result.value.data.type === MediaType.Image ||
									result.value.data.type === MediaType.Video
								) {
									imgThumbnailPromises.push(
										checkS3ForImg(
											result.value.data,
											ThumbnailSize.LARGE,
											result.value.data.type
										)
									);
								} else {
									// push resolved promise
									imgThumbnailPromises.push(
										new Promise((resolve) => resolve(result.value.data))
									);
								}
							}
						});

						// wait for S3 to resolve thumbnails before rendering dropped image card
						allProgress(imgThumbnailPromises, (thumbnailCount) =>
							setThumbnailLoaded(thumbnailCount)
						)
							.then((uploadedImages: MediaItem[]) => {
								props.onUploadSuccess && props.onUploadSuccess(uploadedImages);
								setShowLoading(false);
								reset();

								// reset loading states, small delay for the progress animation
								setTimeout(() => {
									setShowLoading(false);
									setFilesUploaded(0);
									setThumbnailLoaded(0);
									reset();
								}, 250);
							})
							.catch((e) => {
								addNewAlert({
									type: "error",
									message:
										"There was a problem generating the media thumbnail. Please try uploading the media again",
								});
								setShowLoading(false);
								reset();
							});
					}

					if (anyRejected) {
						const rejectedPromises = results.filter(
							(result) => result.status === "rejected"
						);

						let errorMessage = MEDIA_UPLOAD_ERR;

						for (const rejectedPromise of rejectedPromises) {
							if (
								rejectedPromise.status === "rejected" &&
								axios.isAxiosError(rejectedPromise.reason)
							) {
								if (rejectedPromise.reason.response?.status === 409) {
									errorMessage = "Some file(s) already exists!";
									break;
								}
							}
						}

						addNewAlert({
							type: "error",
							message: errorMessage,
						});

						if (!anySuccessfull) {
							setShowLoading(false);
							reset();
						}
					}
				})();
			}
		} else if (wouldExceedMax) {
			addNewAlert({
				type: "error",
				message: FILE_UPLOAD_COUNT_MESSAGE(props.max!),
			});
			setShowLoading(false);
		} else {
			setShowLoading(false);
		}
	}, [files]);

	useEffect(() => {
		switch (props.mediaType) {
			case MediaType.Document:
				setAcceptedFiles("application/pdf");
				break;
			case MediaType.Image:
				setAcceptedFiles("image/png, image/jpeg");
				break;
			case MediaType.Audio:
				setAcceptedFiles("audio/mpeg");
				break;
			case MediaType.Video:
				setAcceptedFiles("video/mp4, video/quicktime");
				break;
		}
	}, [props.mediaType]);

	return (
		<FileUploadContainer disabled={props.disabled}>
			<FileDrop
				{...(!props.disabled && {
					onDrop: (droppedFiles, e) =>
						onChange(droppedFiles, props.max, acceptedFiles),
				})}
				onTargetClick={() => fileInputRef.current.click()}
			>
				<Icon
					className="upload-icon"
					icon="upload"
					color={theme.colorCopyLight}
				/>
				<p>
					{props.placeholder
						? props.placeholder
						: "Drag & Drop or Click to Upload Media"}
				</p>

				<input
					className="hidden"
					type="file"
					accept={acceptedFiles}
					name="img-loader-input"
					multiple
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						if (e.target.files) {
							onChange(e.target.files, props.max, acceptedFiles);
						}

						fileInputRef.current.value = null; // reset file input after "onChange" (checks the file and uploads)
					}}
					ref={fileInputRef}
				/>

				<SpinnerContainer
					show={showLoading}
					background={theme.colorBackgroundLightLight}
				>
					{showLoading && (
						<UploadProgress
							filesUploaded={filesUploaded}
							thumbnailLoaded={thumbnailLoaded}
							fileCount={files.length}
						/>
					)}
				</SpinnerContainer>
			</FileDrop>
		</FileUploadContainer>
	);
};

export default LocalFileUpload;

interface LocalFileUploadProps extends UploadState {
	droppedMedia: DragMediaItem[];
	companyId?: number;
	min?: number;
	max?: number;
	tags?: Tag[];
	mediaType?: MediaType; // only allow selected media type to be dropped
	disabled?: boolean;
	placeholder?: string;
}

export interface UploadState {
	onUploadSuccess?: (() => void) | ((uploaded: MediaItem[]) => void);
}

export interface FileObj {
	file: File;
	id: number;
	src: string;
}
