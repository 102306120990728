import { ADD_ALERT, REMOVE_ALERT, AlertActionTypes, Alert } from "./types";

export const addAlert = (alert: Alert): AlertActionTypes => {
	return {
		type: ADD_ALERT,
		payload: alert,
	};
};

export const hideAlert = (id: string): AlertActionTypes => {
	return {
		type: REMOVE_ALERT,
		payload: id,
	};
};
