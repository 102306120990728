import { FormikProvider, useFormik } from "formik";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import cn from "classnames";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import HierarchyNav, {
	HierarchyCard,
} from "../../../../components/HierarchyNav/HierarchyNav";
import {
	CardContainer,
	CardHeader,
	CardBody,
} from "../../../../components/DashboardCard/DashboardCard";
import Icon from "../../../../components/Icon/Icon";
import styled, { useTheme } from "styled-components";
import { JurorViewCard, JurorViewEntrySetCard } from "../JudgingInterfaces";
import {
	castAbstainForUncastVotes,
	getJurorViewJurors,
} from "../manageJudging";
import { useAdminHub } from "../../../../hooks/useAdminHub";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/Button/Button";
import {
	getClaims,
	saveToken,
	logout,
} from "../../../../components/Auth/handleJWT";
import AuthenticationContext from "../../../../components/Auth/AuthenticationContext";
import { authenticationResponse } from "../../../../components/Auth/auth.models";
import { urlUsers, urlJuryAdmin } from "../../../../endpoints";
import { log } from "console";
import BrowserHeader from "../../../../components/BrowserHeader/BrowserHeader";
import { useAlert } from "../../../../components/Alert/Alerts";

const displayPercentage = (num: number) => {
	return String(Math.round(num * 100)) + "%";
};

// defining grid area for aligning labels
const StyledHierarchyCard = styled(HierarchyCard)<{ show: boolean }>`
	.A {
		grid-area: A;
	}
	.B {
		grid-area: B;
	}
	.C {
		grid-area: C;
	}
	.D {
		grid-area: D;
	}
	.E {
		grid-area: E;
	}

	${(p) =>
		p.show === false &&
		`
      display: none;
    `};
`;

const EntrySetCard = (props: EntrySetCardProps) => {
	const theme = useTheme();
	const history = useHistory();
	return (
		<StyledHierarchyCard
			className="text-center"
			gridColumns="2fr 0 1fr 1fr 1fr 150px 1fr"
			gridArea="A . B C D . E"
			show={props.show || false}
		>
			<p className="A">{props.name}</p>
			<p className="B">{props.totalEntries}</p>
			<p className="C">{props.votesCast}</p>
			<p className="D">{`${displayPercentage(
				props.votesCast / props.totalEntries
			)}`}</p>
			<div
				className="flex gap-[1rem] items-center ml-auto E cursor-pointer"
				onClick={() =>
					history.push(
						`/judging/admin/${props.programId}/${props.juryId}/${props.id}/manageRound`
					)
				}
			>
				<p>View</p>
				<Icon
					className="!select-none"
					icon="caret"
					color={theme.colorCopyDarkDark}
					rotation="-90deg"
				/>
			</div>
		</StyledHierarchyCard>
	);
};

type ITemporaryPassword = {
	password?: string;
};

const TemporaryPassword = (props: ITemporaryPassword) => {
	const { password } = props;
	const [reveal, setReveal] = useState(false);

	useEffect(() => {
		if (reveal) {
			setTimeout(() => {
				setReveal(false);
			}, 10000);
		}
	}, [reveal]);

	// if no password, return empty div so that the grid is not thrown off
	if (!password) return <div></div>;

	return (
		<div
			className={cn({
				"cursor-pointer": !reveal,
			})}
			onClick={() => setReveal(true)}
		>
			{reveal ? password : "Reveal Password"}
		</div>
	);
};

const JurorCard = (props: JurorCardProps) => {
	const { temporaryPassword } = props;
	const theme = useTheme();
	const { update } = useContext(AuthenticationContext);
	const [loginJurorLoading, setLoginJurorLoading] = useState(false);
	const [abstainVoteLoading, setAbstainVoteLoading] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);
	const { addNewAlert } = useAlert();

	return (
		<div>
			<HierarchyCard
				className="text-center"
				gridColumns={props.gridColumns}
				gridArea={props.gridArea}
			>
				<Button
					loading={abstainVoteLoading}
					disabled={props.totalEntries === props.votesCast}
					className="button-light w-[150px]"
					onClick={async () => {
						setAbstainVoteLoading(true);
						try {
							const response = await castAbstainForUncastVotes(props.id);
							if (response.status === 200) {
								setAbstainVoteLoading(false);
								addNewAlert({
									type: "success",
									message: "All uncast votes have been abstained",
								});
							} else {
								setAbstainVoteLoading(false);
								addNewAlert({
									type: "error",
									message: "Error calling castAbstainForUncastVotes endpoint",
								});
							}
						} catch (error) {
							console.error(
								"Error calling castAbstainForUncastVotes endpoint:",
								error
							);
							addNewAlert({
								type: "error",
								message: "Error calling castAbstainForUncastVotes endpoint",
							});
							setAbstainVoteLoading(false);
						}
					}}
				>
					Abstain
				</Button>
				<p>{props.name}</p>
				<p>{props.companyName}</p>
				<p>{props.totalEntries}</p>
				<p>{props.votesCast}</p>
				<p>{`${displayPercentage(props.votesCast / props.totalEntries)}`}</p>
				<Button
					loading={loginJurorLoading}
					className="button-light w-[150px]"
					onClick={async () => {
						setLoginJurorLoading(true);
						try {
							const response = await axios.post<authenticationResponse>(
								`${urlUsers}/jurorLogin?jurorId=${props.id}`
							);
							if (response.data.token) {
								logout();
								saveToken(response.data);
								update(getClaims());
							} else {
								setLoginJurorLoading(false);
							}
						} catch (error) {
							console.error("Error calling JurorLogin endpoint:", error);
							setLoginJurorLoading(false);
						}
					}}
				>
					Login
				</Button>
				<TemporaryPassword password={temporaryPassword} />
				<Icon
					className="ml-auto !select-none"
					icon="caret"
					color={theme.colorCopyDarkDark}
					onClick={() => setIsExpanded(!isExpanded)}
					rotation={isExpanded ? "0deg" : "-90deg"}
				/>
			</HierarchyCard>
			{props.entrySets.map((eSet, i) => (
				<EntrySetCard
					key={i}
					{...eSet}
					show={isExpanded}
					juryId={props.juryId}
					programId={props.programId}
				/>
			))}
		</div>
	);
};

const JudgingJurorView = () => {
	const theme = useTheme();
	const { juryCard, programId, juryId, breadcrumbs, handleClickBreadcrumb } =
		useAdminHub();
	const [jury, setJury] = useState<JurorViewCard[]>([]);
	const [revertPasswordsLoading, setRevertPasswordsLoading] = useState(false);
	const containsTemporaryPassword = jury.some((j) => j.temporaryPassword);

	const fetchJury = async () => {
		getJurorViewJurors(Number(juryId)).then((response) => {
			if (response.status === 200) {
				setJury(response.data);
			}
		});
	};

	useEffect(() => {
		fetchJury();
	}, []);

	const formikProps = useFormik({
		initialValues: {},
		onSubmit: async (value) => {},
		enableReinitialize: true,
	});
	return (
		<FormikProvider value={formikProps}>
			<BrowserHeader title={`Jurors: ${juryCard.name}`} />
			<CardContainer>
				<CardHeader>
					<div className="flex flex-col">
						<h2 className="card-title">{juryCard.name}: Juror View</h2>
						<p className="text-colorCopyLight mt-[.5rem]">
							Overall:{" "}
							{displayPercentage(juryCard.votesCast / juryCard.votesPossible)}
						</p>
					</div>

					<Breadcrumbs navLinks={breadcrumbs} onClick={handleClickBreadcrumb} />
				</CardHeader>
				<CardBody>
					{containsTemporaryPassword && (
						<div className="flex justify-end w-full">
							<Button
								loading={revertPasswordsLoading}
								className="button-light w-[150px]"
								onClick={async () => {
									setRevertPasswordsLoading(true);
									try {
										const res = await axios.post<authenticationResponse>(
											`${urlJuryAdmin}/revertPasswords?juryId=${juryId}`
										);
										if (res.status === 200) {
											fetchJury();
										}
									} catch (error) {
										console.error("Error calling JurorLogin endpoint:", error);
										setRevertPasswordsLoading(true);
									}
								}}
							>
								Revert Passwords
							</Button>
						</div>
					)}
					{jury && jury.length > 0 && (
						<HierarchyNav
							columns={[
								{
									label: null,
									width: "150px",
								},
								{ label: "Juror Name" },
								{ label: "Company Name" },
								{ label: "Total Entries" },
								{
									label: "Entries Voted",
								},
								{
									label: "% Complete",
								},
								{
									label: null,
									width: "150px",
								},
								{
									label: null,
									width: "100px",
								},
								{
									label: null,
								},
							]}
							isCenterLabel
							noSortTriangle
							disableAnimation
						>
							{(gridColumns: string, gridArea: string) =>
								jury.map((juror, i) => (
									<JurorCard
										key={i}
										loginPath={""}
										gridColumns={gridColumns}
										gridArea={gridArea}
										{...juror}
										entrySets={juror.entrySets}
										juryId={juryId}
										programId={programId}
									/>
								))
							}
						</HierarchyNav>
					)}
				</CardBody>
			</CardContainer>
		</FormikProvider>
	);
};

export default JudgingJurorView;

interface EntrySetCardProps extends JurorViewEntrySetCard {
	show: boolean;
	juryId: string;
	programId: string;
}

interface JurorCardProps extends JurorViewCard {
	loginPath: string;
	gridColumns: string;
	gridArea: string;
	juryId: string;
	programId: string;
	temporaryPassword?: string;
}
