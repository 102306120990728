import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import styled from "styled-components";
import TZDatePickerField from "../FormFields/TZDatePickerField";
import Button from "../Button/Button";
import ResultsTable from "./ResultsTable";
import {
  getOrderReports,
  CustomerUpload,
  getCustomerUploadReports,
} from "./manageReports";
import config from "../../config";

const LoadingSpinner = styled.img`
  width: 100px;
`;

const spinnerSrc = config.assets.loading.primary;

const CustomerUploadReport = () => {
  const [customerUploadReports, setCustomerUploadReports] =
    useState<CustomerUpload[]>();
  const [customerReportLoading, setcustomerReportLoading] = useState(false);

  let date = new Date();

  const formikProps = useFormik({
    initialValues: {
      startDate: Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours() + 5, // EST is 5 hours behind UTC
        date.getMinutes()
      ),
      endDate: Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours() + 5, // EST is 5 hours behind UTC
        date.getMinutes()
      ),
    },
    enableReinitialize: true,
    onSubmit: async () => {},
    validateOnBlur: false,
  });

  const generateInformation = async () => {
    setcustomerReportLoading(true);
    try {
      const reports = await getCustomerUploadReports(
        formikProps.values.startDate,
        formikProps.values.endDate
      );
      setCustomerUploadReports(reports);
    } catch (error) {
      console.error("Failed to get order report: ", error);
    }
    setcustomerReportLoading(false);
  };

  return (
    <div>
      <FormikProvider value={formikProps}>
        {/* Date picker section */}
        <div className="flex items-center space-x-4 pb-5">
          <div className="flex gap-[1rem] items-center">
            <TZDatePickerField
              name="startDate"
              placeholder="Start Date"
              value={formikProps.values.startDate}
            />
            <TZDatePickerField
              name="endDate"
              placeholder="End Date"
              value={formikProps.values.endDate}
            />
          </div>
          <Button onClick={generateInformation}>Generate</Button>
        </div>
        {/* Loading and Results display section */}{" "}
        {customerReportLoading ? (
          <div className="pt-10 flex flex-col items-center justify-center">
            <div>Running Customer Upload Order Report...</div>
            <LoadingSpinner src={spinnerSrc} />
          </div>
        ) : customerUploadReports && customerUploadReports.length > 0 ? (
          <ResultsTable data={customerUploadReports} />
        ) : null}
      </FormikProvider>
    </div>
  );
};

export default CustomerUploadReport;
