import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import styled from "styled-components";
import { EntryReviewReport, getEntryReviewerCounts } from "./manageReview";
import * as Yup from "yup";
import DropdownField from "../../../components/FormFields/DropdownField";
import Button from "../../../components/Button/Button";
import { useProgramAwards } from "../../../hooks/useProgramAwards";
import { useSeasons } from "../../../hooks/useSeasons";
import ReviewResultsTable from "./ReviewResultsTable";
import config from "../../../config";

const Progress = () => {
  const awardOptions = useProgramAwards();
  const seasonOptions = useSeasons();
  const [selectedAwardId, setSelectedAwardId] = useState<number | undefined>();
  const [selectedSeasonId, setSelectedSeasonId] = useState<
    number | undefined
  >();
  const [reportLoading, setReportLoading] = useState(false);
  const [selectedAwardName, setSelectedAwardName] = useState<string>();
  const [selectedSeasonName, setSelectedSeasonName] = useState<string>();
  const [entryReviewReport, setEntryReviewReport] = useState<
    EntryReviewReport[]
  >([]);

  const LoadingSpinner = styled.img`
    width: 100px;
  `;

  const spinnerSrc = config.assets.loading.primary;

  const generateInformation = async () => {
    if (selectedSeasonId && selectedAwardId) {
      setReportLoading(true);
      try {
        const entryReviewReport: EntryReviewReport[] =
          await getEntryReviewerCounts(
            Number(selectedSeasonId),
            Number(selectedAwardId)
          );
        setEntryReviewReport(entryReviewReport);
      } catch (error) {
        console.error("Failed to get entry reviewer counts: ", error);
      }
      setReportLoading(false);
    }
  };

  const formikProps = useFormik({
    initialValues: { awardOptions, seasonOptions },
    enableReinitialize: true,
    onSubmit: async () => {},
    validationSchema: Yup.object({}),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div>
      <FormikProvider value={formikProps}>
        <div className="flex items-center space-x-4 pb-5">
          {" "}
          <DropdownField
            className="w-[250px]"
            name="season"
            options={seasonOptions}
            value={selectedSeasonId || undefined}
            placeholder="Select a Season"
            onChange={(value) => {
              const selectedValue = Number(value.target.value);
              const selectedOption = seasonOptions.find(
                (option) => option.value === selectedValue
              );
              setSelectedSeasonId(selectedValue);
              setSelectedSeasonName(String(selectedOption?.label || ""));
            }}
          />
          <DropdownField
            className="w-[250px]"
            name="award"
            options={awardOptions}
            value={selectedAwardId || undefined}
            placeholder="Select an Award"
            onChange={(value) => {
              const selectedValue = Number(value.target.value);
              const selectedOption = awardOptions.find(
                (option) => option.value === selectedValue
              );
              setSelectedAwardId(selectedValue);
              setSelectedAwardName(String(selectedOption?.label || ""));
            }}
          />
          <>
            <Button
              onClick={generateInformation}
              disabled={!selectedSeasonId || !selectedAwardId || reportLoading}
            >
              Generate
            </Button>
          </>
        </div>
        {reportLoading ? (
          <div className="pt-10 flex flex-col items-center justify-center">
            <div>Running Reports...</div>
            <LoadingSpinner src={spinnerSrc} />
          </div>
        ) : (
          <>
            {entryReviewReport && entryReviewReport.length > 0 ? (
              <div>
                <ReviewResultsTable
                  data={entryReviewReport}
                  disableExport={false}
                  showTopScroll={true}
                />
              </div>
            ) : (
              <div className="pt-10 flex flex-col items-center justify-center"></div>
            )}
          </>
        )}
      </FormikProvider>
    </div>
  );
};

export default Progress;
