import { Form } from "formik";
import styled, { css } from "styled-components";

const StyledForm = styled(Form)<{ compact?: boolean }>`
  width: 100%;

  &.oneColumn {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    gap: 2rem;

    &.extraSpacing {
      gap: 4rem;

      .subtitle {
        margin-bottom: 0;
      }
      .col-w-100 {
        gap: 1rem;
      }
    }
  }

  &.twoColumn {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 3rem;
  }

  & > div {
    min-width: 47%;
    flex: 1;

    .w-45 {
      width: 45%;
    }
  }

  .col-w-100 {
    flex: auto !important;
  }

  // a compact form with padding and spacing between fields
  ${(p) =>
    p.compact &&
    css`
      &.twoColumn {
        gap: 2rem;
      }

      & > div {
        min-width: 47%;
      }
    `};
`;

export default StyledForm;
