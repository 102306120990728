import { Form, Formik, useFormik, FormikProvider } from "formik";
import Button from "../../components/Button/Button";
import { JobTitleOptions } from "../../data/JobTitleData";
import { USStateOptions, CanadaStateOptions } from "../../data/StateData";
//import { CountryOptions } from "../../data/CountryData";
import { CountryOptions } from "../../data/CountryData";
import * as Yup from "yup";
import TextField from "../../components/FormFields/TextField";
import DropdownField, {
	DropdownOptions,
} from "../../components/FormFields/DropdownField";
import { useContext, useEffect, useState } from "react";
import AuthenticationContext from "../../components/Auth/AuthenticationContext";
import {
	authenticationResponse,
	newUserCredentials,
} from "../../components/Auth/auth.models";
import axios from "axios";
import { urlUsers } from "../../endpoints";
import { saveToken, getClaims } from "../../components/Auth/handleJWT";
import { useHistory } from "react-router-dom";
import DisplayErrors from "../../utils/DisplayErrors";
import Lightbox from "../../components/Lightbox/Lightbox";
import StyledForm from "../../components/Form/Form";
import styled, { useTheme } from "styled-components";
import { PatternBG } from "../../globalStyles";
import { programOptions } from "../Entries/EntriesTestData";
import CheckboxField from "../../components/FormFields/CheckboxField";
import Icon from "../../components/Icon/Icon";
import { CSSTransition } from "react-transition-group";
import { useAlert } from "../../components/Alert/Alerts";
import PasswordValidateField from "../../components/FormFields/PasswordValidateField";
import { PasswordValidation } from "../../components/FormFields/PasswordValidateField";
import { fieldRequired } from "../../components/FieldModal/FieldModal";

import config from "../../config";
import assetsConfig from "../../assetsConfig";

const CustomBG = styled.div`
	width: 100%;
	height: 100%;
	${PatternBG}
`;

const RegisterLightbox = styled(Lightbox)`
	flex-direction: column;
	align-items: center;
	/* width: 1000px; */
	//height: 100%;
	margin: 5rem auto;
`;

const CheckBoxTip = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	color: ${({ theme }) => theme.colorCopyDarkDark};

	label {
		margin-right: 0.5rem;
	}
	span {
		/* margin-left: 0.5rem; */
		font-size: ${({ theme }) => theme.xSmallSize};
		color: ${({ theme }) => theme.colorCopyLight};
	}
`;

const LoadingSpinnder = styled.img`
	width: 15%;
	display: inline-flex;
`;

const clioNewsDropdownOptions: DropdownOptions[] = [
	{ label: "Clio Awards News", value: "clioAwards" },
	{ label: "Clio Sports News", value: "clioSports" },
	{ label: "Clio Health News", value: "clioHealth" },
	{ label: "Clio Entertainment News", value: "clioEntertainment" },
	{ label: "Clio Music News", value: "clioMusic" },
	{ label: "Clio Cannabis News", value: "clioCannabis" },
	{ label: "Clio Fashion & Beauty News", value: "clioFashion" },
];

export default function Register() {
	const theme = useTheme();
	const [errors, setErrors] = useState<string[]>([]);
	const { update } = useContext(AuthenticationContext);
	const history = useHistory();
	const [programState, setProgramState] = useState(true);
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const { addNewAlert } = useAlert();
	const [firstValidation, setFirstValidation] = useState(false);
	const [partialCheck, setPartialCheck] = useState(false);

	const handleStateChange = () => {
		const obj = {};

		// toggle all program notifications
		const setAllCheckbox = clioNewsDropdownOptions.map((program) => ({
			[program.value]: !programState,
		}));

		const newProgramState = Object.assign({}, obj, ...setAllCheckbox);
		formikProps.setValues({
			...formikProps.values,
			...newProgramState,
		});
		setProgramState(!programState);
	};

	async function register(credentials: newUserCredentials) {
		try {
			setErrors([]);
			const response = await axios.post<authenticationResponse>(
				`${urlUsers}/create?bypass=true`,
				credentials
			);
			saveToken(response.data);
			update(getClaims());
			history.push("/");
		} catch (error: any) {
			// console.log(error);
			//setErrors(error.response.data);
		}
	}

	async function registerThenCompany(credentials: newUserCredentials) {
		// console.log("registering company");
		axios
			.post<authenticationResponse>(
				`${urlUsers}/create?bypass=false`,
				credentials
			)
			.then((response) => {
				saveToken(response.data);
				update(getClaims());
				history.push("/company");
			})
			.catch((e) => {
				// console.log("res", e.response);
				addNewAlert({ type: "error", message: e.response.data });
				formikProps.setSubmitting(false);
			});
	}

	const programNotifications = {
		clioAwards: true,
		clioSports: true,
		clioHealth: true,
		clioEntertainment: true,
		clioMusic: true,
		clioCannabis: true,
		clioFashion: true,
	};

	const formikProps = useFormik({
		initialValues: {
			fullName: "",
			jobTitle: "",
			email: "",
			emailConfirm: "",
			country: "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			postalCode: "",
			phone: "",
			password: "",
			passwordConfirm: "",
			programNotifications: true,
			...programNotifications,
			clioMuse: true,
			clioAds: true,
			clioFriends: true,
		},
		enableReinitialize: true,
		onSubmit: () => {
			registerThenCompany(formikProps.values);
		},
		validationSchema: Yup.object({
			fullName: Yup.string()
				.required(fieldRequired)
				.max(100, "Full Name Maximum is 100 Characters"),
			jobTitle: Yup.string().required(fieldRequired),
			email: Yup.string().required(fieldRequired),
			emailConfirm: Yup.string()
				.required(fieldRequired)
				.test("emailConfirmMatch", "Email doesn't match.", (value, context) =>
					value
						? value.toLowerCase() === context.parent.email.toLowerCase()
						: false
				),
			// .oneOf([Yup.ref("email")], "Email doesn't match."),
			country: Yup.string().required(fieldRequired),
			address1: Yup.string().required(fieldRequired),
			city: Yup.string().required(fieldRequired),
			state: Yup.string().when("country", {
				is: (value: string) => value === "United States" || value === "Canada",
				then: Yup.string().required(fieldRequired),
			}),
			postalCode: Yup.string().when("country", {
				is: (value: string) => value === "United States" || value === "Canada",
				then: Yup.string().required(fieldRequired),
			}),
			phone: Yup.string().required(fieldRequired),
			// .integerOnly()
			// .matches(phoneRegExp, "Phone number is not valid")
			// .min(10, "Phone number is not valid"),
			...PasswordValidation,
		}),
		validateOnBlur: false,
		validateOnChange: firstValidation,
	});

	//   const checkFormikError = (formikErrors: any) => {
	//     console.log("fomrikErrors", formikErrors);
	//     if (Object.values(formikErrors).length > 0)
	//       addNewAlert({
	//         type: "error",
	//         message: "Please review the above fields for errors",
	//       });
	//   };

	// check for partially checked checkboxes
	const checkForPartialState = () => {
		const atLeastOneChecked = Object.keys(programNotifications).some(
			(notification) =>
				formikProps.values[notification as keyof typeof formikProps.values]
		);

		const atLeastOneUnchecked = Object.keys(programNotifications).some(
			(notification) =>
				!formikProps.values[notification as keyof typeof formikProps.values]
		);

		// partially checked
		if (atLeastOneChecked && atLeastOneUnchecked) {
			setPartialCheck(true);
		}
		// all checkboxes are checked
		else if (!atLeastOneUnchecked) {
			setProgramState(true);
			setPartialCheck(false);
		}
		// iall checkboxes are unchecked
		else if (!atLeastOneChecked) {
			setProgramState(false);
			setPartialCheck(false);
		}
	};

	useEffect(() => {
		checkForPartialState();
	}, [formikProps.values]);

	return (
		<CustomBG>
			<RegisterLightbox className="relative flex-col justify-center align-center mt-5 mb-5">
				<img
					className="mb-[5rem] w-[200px] "
					src={config.assets.logos.secondary}
					alt="logo"
				/>
				<p
					className="absolute bottom-[2rem] left-[2rem]"
					style={{ color: theme.colorCopyLight }}
				></p>
				<FormikProvider value={formikProps}>
					<StyledForm className="twoColumn !gap-y-[2.5rem]">
						<div className="col-w-100">
							<h3 className="subtitle">Contact Information</h3>
							<p className="sectionText">{assetsConfig.copy.registerMessage}</p>
						</div>

						<TextField name="fullName" placeholder="Full Name* " />

						<DropdownField
							name="jobTitle"
							placeholder="Job Title* "
							options={JobTitleOptions}
						/>

						<TextField name="email" placeholder="Email*" />

						<TextField name="emailConfirm" placeholder="Confirm Email*" />

						<div className="col-w-100">
							<h3 className="subtitle">Address</h3>
							<p className="sectionText">
								{assetsConfig.copy.registerAddressMessage}
							</p>
						</div>

						<DropdownField
							name="country"
							placeholder="Country* "
							options={CountryOptions}
						/>

						<TextField name="address1" placeholder="Address 1*" />

						<TextField name="address2" placeholder="Address 2" />

						<TextField name="city" placeholder="City* " />

						{formikProps.values.country === "United States" ||
						formikProps.values.country === "Canada" ? (
							<DropdownField
								name="state"
								placeholder="State/Province/Region*"
								options={
									formikProps.values.country === "United States"
										? USStateOptions
										: CanadaStateOptions
								}
							/>
						) : (
							<TextField name="state" placeholder="State/Province/Region" />
						)}

						<TextField
							name="postalCode"
							placeholder={
								formikProps.values.country === "United States" ||
								formikProps.values.country === "Canada"
									? "Zip/Postal Code* "
									: "Zip/Postal Code "
							}
						/>

						<TextField name="phone" placeholder="Phone* " />

						<div className="col-w-100">
							<h3 className="subtitle">Create Password</h3>
							<p className="sectionText">
								{assetsConfig.copy.registerPasswordMessage}
							</p>
						</div>

						<PasswordValidateField name="password" placeholder="Password* " />
						<TextField
							className="flex-1"
							type="password"
							name="passwordConfirm"
							placeholder="Confirm Password* "
						/>

						{/* TODO: [NEMO-273] Newsletter Preferences */}

						<div className="col-w-100">
							<h3 className="subtitle">Newsletter</h3>
							<CheckBoxTip className="mb-[1.5rem]">
								<CheckboxField
									name="programNotifications"
									placeholder="Program Notifications"
									onChange={handleStateChange}
									checked={programState}
									partialCheck={partialCheck}
								/>
								<span>
									(Deadlines, medium, category changes, winners announcements,
									and event details)
								</span>
							</CheckBoxTip>
							<div className="flex flex-col gap-[1rem] ml-[2rem] mb-[1.5rem]">
								{clioNewsDropdownOptions.map((notification) => (
									<CheckboxField
										name={notification.value}
										placeholder={String(notification.label)}
										checked={
											formikProps.values[
												notification.value as keyof typeof formikProps.values
											] as boolean
										}
										// {...(programState ? { checked: true } : { checked: false })}
									/>
								))}
							</div>

							<CheckBoxTip className="mb-[1.5rem]">
								<CheckboxField
									name="clioMuse"
									placeholder="Muse by CLIO Newsletter"
								/>
								<span>
									(Showcasing the world's most creative people, ideas, and
									work.)
								</span>
							</CheckBoxTip>
							<CheckBoxTip className="mb-[1.5rem]">
								<CheckboxField
									name="clioAds"
									placeholder="Ads of the World Newsletter"
								/>
								<span>
									(Weekly newsletter showcasing the top ads of the week.)
								</span>
							</CheckBoxTip>
							<CheckBoxTip>
								<CheckboxField
									name="clioFriends"
									placeholder="Friends & Partners"
								/>
								<span>
									(Latest tips and offers from our network of creative sites and
									our partners)
								</span>
							</CheckBoxTip>
						</div>

						<div className="col-w-100 gap-[1rem]">
							<Button
								className="xs:w-[300px] md:w-[350px] ml-auto button-activation"
								type="submit"
								icon="caret"
								iconRotation="-90deg"
								iconRight
								disabled={
									//Object.values(formikProps.errors).length !== 0 ||
									formikProps.isSubmitting
								}
								onClick={() => setFirstValidation(true)}
							>
								{formikProps.isSubmitting ? (
									<LoadingSpinnder src={config.assets.loading.primary} />
								) : (
									"Next: Add Company Information"
								)}
							</Button>
							<p className="ml-auto">
								Already have an account? <a href="/login"> Log in here </a>
							</p>
						</div>
					</StyledForm>
				</FormikProvider>
				{/* <DisplayErrors errors={errors} /> */}
			</RegisterLightbox>
		</CustomBG>
	);
}
