import { createGlobalStyle, css } from "styled-components";
import { normalize } from "polished";
import { renderToStaticMarkup } from "react-dom/server";
import config from "./config";
import { darkThemeColors, defaultColors } from "./assetsConfig";

export const GlobalStyles = createGlobalStyle`
  ${normalize()}
  html {
    box-sizing: border-box;
    font-size: ${({ theme }) => theme.pSize};
    scroll-behavior: smooth;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    font-family: ${({ theme }) => theme.fontCopy};
    background-color: ${({ theme }) => theme.colorBackgroundLightLight};
    color: ${({ theme }) => theme.colorCopyDarkDark};
    
  }

  h1, h2, h3, h4, h5, h6 {
      font-weight: ${({ theme }) => theme.fontSemiBold};
      font-family: ${({ theme }) => theme.fontHeading};
      letter-spacing: -1px;
      margin: 0;
  }

  p, a, span, b {
    font-family: ${({ theme }) => theme.fontCopy};
    letter-spacing: .15px;

  }

  h1 {
    font-size: ${({ theme }) => theme.h1Size};
  }

  h2 {
    font-size: ${({ theme }) => theme.h2Size};
  }

  h3 {
    font-size: ${({ theme }) => theme.h3Size};
  }

  h4 {
    font-size: ${({ theme }) => theme.h4Size};
  }

  h5 {
    font-size: ${({ theme }) => theme.h5Size};
  }

  h6 {
    font-size: ${({ theme }) => theme.h6Size};
  }

  p {
    font-size: ${({ theme }) => theme.pSize};
    line-height: 23px;
  }

  a {
    font-size: ${({ theme }) => theme.aSize};
    line-height: 23px;
    color: ${({ theme }) => theme.colorLinkLight};
  }

  b, strong {
    font-weight: ${({ theme }) => theme.fontSemiBold};
  }

  span {
    font-size: ${({ theme }) => theme.spanSize};
        line-height: 23px;
  }

  ol, ul, menu {
    margin: 0;
    padding: 1rem;
  }

  ul {
    list-style: initial;
  }

  ol {
    list-style: decimal;
  }

  .playfair, .playfair-500 {
    letter-spacing: .15px;
  }

  .playfair {
      font-family: ${({ theme }) => theme.fontBrand} !important;
      font-weight: ${({ theme }) => theme.fontDefaultWeight} !important;
  }

    .playfair-500 {
        font-family: ${({ theme }) => theme.fontBrand} !important;
        font-weight: 500 !important;
    }

  .title {
    font-family: ${({ theme }) => theme.fontBrand};
    font-weight: ${({ theme }) => theme.fontDefaultWeight};
    font-size: ${({ theme }) => theme.h1Size};
    white-space: nowrap;
    margin-bottom: 3rem;
  }

  .subtitle {
    font-family: ${({ theme }) => theme.fontHeading};
    font-size: ${({ theme }) => theme.h2Size};
    white-space: nowrap;
    margin-right: auto;
    margin-bottom: 1rem;
  }

  .sectionText {
    font-family: ${({ theme }) => theme.fontHeading};
    font-size: ${({ theme }) => theme.pSize};
  }

  #freshworks-container {
    display: none;
    z-index: 99999 !important;
  }

  .font-italic {
    font-style: italic;
    color: ${({ theme }) => theme.colorCopyLight};
  }
`;

export const svgString = (
	SvgComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
	style?: React.CSSProperties
) => {
	return (
		"data:image/svg+xml;utf8," +
		encodeURIComponent(renderToStaticMarkup(<SvgComponent style={style} />))
	);
};

export const PatternBG = css<{ patternBGSize?: string }>`
	background: linear-gradient(
			${({ theme }) => theme.gradientBackgroundLight},
			${({ theme }) => theme.gradientBackgroundLight}
		),
		url("${svgString(config.assets.backgrounds.primary)}") center top /
			${(p) => p.patternBGSize || "30%"} repeat;
`;

export const DarkPatternBG = css<{ patternBGSize?: string }>`
	--darkBG: ${darkThemeColors.colorPatternBGDark};
	background: linear-gradient(var(--darkBG), var(--darkBG)),
		url("${svgString(config.assets.backgrounds.primary)}") center top /
			${(p) => p.patternBGSize || "30%"} repeat;
`;

// padding for grid: 1440
export const DynamicPadding = css`
	padding: 2.5rem max(2.5rem, calc((100vw - 1440px) / 2));
`;

export const DynamicTransitionDuration = "0.15s";

export const PDFUtilClasses = {
	twoCol: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		width: "100%",
	},
};
