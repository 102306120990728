import { useContext, useEffect, useState } from "react";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import Lightbox from "../../components/Lightbox/Lightbox";
import Button from "../../components/Button/Button";
import CheckboxField from "../../components/FormFields/CheckboxField";
import DropdownField, {
	DropdownOptions,
} from "../../components/FormFields/DropdownField";
import TextField from "../../components/FormFields/TextField";
import styled, { keyframes } from "styled-components";
import StyledForm from "../../components/Form/Form";
import {
	AgencyNetworkModel,
	CompanyModel,
	HoldingCompanyModel,
} from "./company.model";
import AuthenticationContext from "../../components/Auth/AuthenticationContext";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import {
	createCompany,
	getAgencyNetworks,
	getHoldingCompanies,
} from "./manageCompany";
import { saveToken, getClaims } from "../../components/Auth/handleJWT";
import { USStateOptions, CanadaStateOptions } from "../../data/StateData";
import { CountryOptions } from "../../data/CountryData";
import { JobTitleOptions } from "../../data/JobTitleData";
import { CompanyTypeOptions } from "../Admin/Program/ProgramInterfaces";
import config from "../../config";
import assetsConfig from "../../assetsConfig";

const scaleAnimationIn = keyframes`
  0% {
    transform: scale(0, 1);
    animation-timing-function: ease-out;
  }
  100% {
    transform: scale(1, 1);
  }
`;

const LoadingSpinnder = styled.img`
	width: 15%;
	display: inline-flex;
`;

export default function Company() {
	const { update } = useContext(AuthenticationContext);
	const { user } = useCurrentUser();
	const [errors, setErrors] = useState<string[]>([]);
	const [firstValidation, setFirstValidation] = useState(false);
	const [agencyNetworksOptions, setAgencyNetworksDropdownOptions] = useState<
		DropdownOptions[]
	>([]);
	const [holdingCompaniesOptions, setHoldingCompaniesDropdownOptions] =
		useState<DropdownOptions[]>([]);

	let fieldRequired = "This field is required";

	const formikProps = useFormik({
		initialValues: {
			name: "",
			companyType: "",
			country: "",
			city: "",
			state: "",
			address1: "",
			address2: "",
			postalCode: "",
			// phone: "",
			logo: "",
			socialMedia1: "",
			socialMedia2: "",
			socialMedia3: "",
			socialMedia4: "",
			financeEmailCheck: true,
			financeContactEmail: "",
			financeContactName: "",
			financeContactJobTitle: "",
			financeContactPhone: "",
			entryEmailCheck: true,
			entryContactName: "",
			entryContactEmail: "",
			entryContactJobTitle: "",
			entryContactPhone: "",
			agencyNetworkId: undefined,
			holdingCompanyId: undefined,
		},

		enableReinitialize: true,
		onSubmit: async () => {
			await create(formikProps.values);
		},
		validationSchema: Yup.object({
			name: Yup.string().required(fieldRequired),
			companyType: Yup.string().required(fieldRequired),
			city: Yup.string().required(fieldRequired),
			country: Yup.string().required(fieldRequired),
			state: Yup.string().when("country", {
				is: (value: string) => value === "United States" || value === "Canada",
				then: Yup.string().required(fieldRequired),
			}),
			address1: Yup.string().required(fieldRequired),
			postalCode: Yup.string().when("country", {
				is: (value: string) => value === "United States" || value === "Canada",
				then: Yup.string().required(fieldRequired),
			}),
			// phone: Yup.string().required(fieldRequired),
			financeContactEmail: Yup.string().when("financeEmailCheck", {
				is: (value: any) => value === false || value === undefined,
				then: Yup.string().required(fieldRequired),
			}),
			financeContactName: Yup.string().when("financeEmailCheck", {
				is: (value: any) => value === false || value === undefined,
				then: Yup.string().required(fieldRequired),
			}),
			financeContactJobTitle: Yup.string().when("financeEmailCheck", {
				is: (value: any) => value === false || value === undefined,
				then: Yup.string().required(fieldRequired),
			}),
			financeContactPhone: Yup.string().when("financeEmailCheck", {
				is: (value: any) => value === false || value === undefined,
				then: Yup.string().required(fieldRequired),
			}),
			entryContactEmail: Yup.string().when("entryEmailCheck", {
				is: (value: any) => value === false || value === undefined,
				then: Yup.string().required(fieldRequired),
			}),
			entryContactName: Yup.string().when("entryEmailCheck", {
				is: (value: any) => value === false || value === undefined,
				then: Yup.string().required(fieldRequired),
			}),
			entryContactJobTitle: Yup.string().when("entryEmailCheck", {
				is: (value: any) => value === false || value === undefined,
				then: Yup.string().required(fieldRequired),
			}),
			entryContactPhone: Yup.string().when("entryEmailCheck", {
				is: (value: any) => value === false || value === undefined,
				then: Yup.string().required(fieldRequired),
			}),
			agencyNetworkId: Yup.number()
				.min(1, fieldRequired)
				.required(fieldRequired),
			holdingCompanyId: Yup.number()
				.min(1, fieldRequired)
				.required(fieldRequired),
		}),
		validateOnBlur: false,
		validateOnChange: firstValidation,
	});

	async function create(company: CompanyModel) {
		// assign financial contact and entry contact if checkboxes are checked
		if (formikProps.values.financeEmailCheck) {
			company.financeContactEmail = user.email;
			company.financeContactName = user.fullName;
			company.financeContactPhone = user.phone;
			company.financeContactJobTitle = user.jobTitle;
		}

		if (formikProps.values.entryEmailCheck) {
			company.entryContactEmail = user.email;
			company.entryContactName = user.fullName;
			company.entryContactPhone = user.phone;
			company.entryContactJobTitle = user.jobTitle;
		}

		try {
			setErrors([]);
			const response = await createCompany(company);
			if (response.status == 200) {
				saveToken(response.data);
				update(getClaims());
				// history.push("/");
			}
		} catch (error: any) {
			// console.log(error);
			setErrors(error.response.data);
			formikProps.setSubmitting(false);
		}
	}

	const createDropdownOptions = (seasons: string[]): DropdownOptions[] => {
		return seasons.map((season) => ({ value: season, label: season }));
	};

	useEffect(() => {
		getAgencyNetworks().then((data) => {
			var dropdownOptions = data!.map((r) => ({ value: r.id, label: r.name }));
			setAgencyNetworksDropdownOptions(dropdownOptions);
		});
	}, []);

	useEffect(() => {
		getHoldingCompanies().then((data) => {
			var dropdownOptions = data!.map((r) => ({ value: r.id, label: r.name }));
			setHoldingCompaniesDropdownOptions(dropdownOptions);
		});
	}, []);

	// useEffect(() => {
	//   formikProps.setFieldValue("state", "");
	//   formikProps.validateField("state");
	// }, [formikProps.values.country]);

	// useEffect(() => {
	//   console.log(
	//     "formikProps.values.financeEmailCheck",
	//     formikProps.values.financeEmailCheck
	//   );
	// }, [formikProps.values.financeEmailCheck]);

	return (
		<Lightbox className="flex-col mx-auto h-fit self-center mt-5 mb-5">
			<img
				className="mb-[5rem] mx-auto"
				src={config.assets.logos.secondary}
				alt="logo"
				width="150px"
			/>
			<FormikProvider value={formikProps}>
				<StyledForm className="twoColumn">
					<div className="col-w-100">
						<h3 className="subtitle">Company Information</h3>
						<p
							className="sectionText"
							dangerouslySetInnerHTML={{
								__html: assetsConfig.copy.companyEntryCreationInformation,
							}}
						></p>
					</div>
					<TextField name="name" placeholder="Company Name*" />
					<DropdownField
						name="companyType"
						placeholder="Company Type*"
						options={CompanyTypeOptions}
					/>
					<DropdownField
						name="agencyNetworkId"
						placeholder="Agency Network"
						options={agencyNetworksOptions}
					/>
					<DropdownField
						name="holdingCompanyId"
						placeholder="Holding Company"
						options={holdingCompaniesOptions}
					/>
					<DropdownField
						name="country"
						placeholder="Country*"
						options={CountryOptions}
					/>
					<TextField name="address1" placeholder="Company Address 1*" />
					<TextField name="address2" placeholder="Company Address 2" />
					<TextField name="city" placeholder="Company City*" />
					{formikProps.values.country === "United States" ||
					formikProps.values.country === "Canada" ? (
						<DropdownField
							name="state"
							placeholder="State/Province/Region*"
							options={
								formikProps.values.country === "United States"
									? USStateOptions
									: CanadaStateOptions
							}
						/>
					) : (
						<TextField name="state" placeholder="State/Province/Region" />
					)}

					{/* <TextField name="postalCode" placeholder="Company Zip Code*" /> */}
					<TextField
						name="postalCode"
						placeholder={
							formikProps.values.country === "United States" ||
							formikProps.values.country === "Canada"
								? "Zip/Postal Code* "
								: "Zip/Postal Code"
						}
					/>
					<TextField name="socialMedia1" placeholder="LinkedIn" />
					<TextField name="socialMedia2" placeholder="Twitter" />
					<TextField name="socialMedia3" placeholder="Instagram" />
					<TextField name="socialMedia4" placeholder="Facebook" />

					<div className="mt-[1rem]">
						<h3 className="subtitle">Company Point of Contact</h3>
						<p className="sectionText">
							If you will not be the main contact for any finance or entry
							questions, please unselect the boxes below and identify who we
							should reach out to for any financial or entry-related questions.
						</p>
					</div>

					<div className="col-w-100">
						<CheckboxField
							name="entryEmailCheck"
							placeholder="I will be the designated entry point of contact"
						/>

						{!formikProps.values.entryEmailCheck && (
							<div className="flex flex-col gap-[3rem] mt-[3rem]">
								<TextField
									name="entryContactName"
									placeholder="Entry Contact Name*"
									className={`${
										formikProps.values.entryEmailCheck
											? "scale-down"
											: "expand-down"
									}`}
								/>
								<TextField
									name="entryContactEmail"
									placeholder="Entry Contact Email*"
									className={`${
										formikProps.values.entryEmailCheck
											? "scale-down"
											: "expand-down"
									}`}
								/>
								<DropdownField
									name="entryContactJobTitle"
									placeholder="Entry Contact Job Title*"
									className={`${
										formikProps.values.entryEmailCheck
											? "scale-down"
											: "expand-down"
									}`}
									options={JobTitleOptions}
								/>
								<TextField
									name="entryContactPhone"
									placeholder="Entry Contact Phone Number*"
									className={`${
										formikProps.values.entryEmailCheck
											? "scale-down"
											: "expand-down"
									} mb-[1.5rem]`}
								/>
							</div>
						)}
						<CheckboxField
							className="mt-[1.5rem]"
							name="financeEmailCheck"
							placeholder="I will be the designated finance point of contact"
						/>

						{!formikProps.values.financeEmailCheck && (
							<div className="flex flex-col gap-[3rem] mt-[3rem]">
								<TextField
									name="financeContactName"
									placeholder="Finance Contact Name*"
									className={`${
										formikProps.values.financeEmailCheck
											? "scale-down"
											: "expand-down"
									}`}
								/>
								<TextField
									name="financeContactEmail"
									placeholder="Finance Contact Email*"
									className={`${
										formikProps.values.financeEmailCheck
											? "scale-down"
											: "expand-down"
									}`}
								/>
								<DropdownField
									name="financeContactJobTitle"
									placeholder="Finance Job Title*"
									className={`${
										formikProps.values.financeEmailCheck
											? "scale-down"
											: "expand-down"
									}`}
									options={JobTitleOptions}
								/>
								<TextField
									name="financeContactPhone"
									placeholder="Finance Contact Phone Number*"
									className={`${
										formikProps.values.financeEmailCheck
											? "scale-down"
											: "expand-down"
									}`}
								/>
							</div>
						)}
					</div>
					<Button
						className="mb-1 w-[200px] ml-auto"
						onClick={() => setFirstValidation(true)}
						type="submit"
						disabled={formikProps.isSubmitting}
					>
						{formikProps.isSubmitting ? (
							<LoadingSpinnder src={config.assets.loading.primary} />
						) : (
							"Create Account"
						)}
					</Button>
				</StyledForm>
			</FormikProvider>
			{/* <DisplayErrors errors={errors} /> */}
		</Lightbox>
	);
}
