import styled, { css, useTheme } from "styled-components";
import { useState, useEffect, useRef, cloneElement, useContext } from "react";
import { CSSTransition } from "react-transition-group";
import { StyledHoverMenu } from "../HoverMenu/HoverMenu";
import Icon from "../Icon/Icon";
import { defaultBoxShadow } from "../../assetsConfig";
import { useSelector, useDispatch } from "react-redux";
import { addAlert, hideAlert } from "./actions";
import { RootState } from "../../store";
import { Dispatch } from "redux";
import { Alert, AlertActionTypes } from "./types";
import { v4 as uuidv4 } from "uuid";

export const useAlert = () => {
	const dispatch: Dispatch<AlertActionTypes> = useDispatch();

	const removeAlert = (id: string) => {
		dispatch(hideAlert(id));
	};

	const addNewAlert = (props: {
		type: "success" | "error" | "alert";
		message: string;
	}) => {
		const newAlert: Alert = {
			id: uuidv4(),
			type: props.type,
			message: props.message,
		};

		dispatch(addAlert(newAlert));

		// hide alert after 2.5s
		setTimeout(() => {
			removeAlert(newAlert.id);
		}, 2500);
	};

	return { addNewAlert, removeAlert };
};

const StyledInfoPopup = styled.div<{
	type: "error" | "success" | "alert";
	index: number;
}>`
	position: fixed;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	padding: 1rem;
	background: ${({ theme }) => theme.colorBackgroundLightLight};
	z-index: 999999999999999999;
	box-shadow: ${defaultBoxShadow};

	min-width: 300px;
	height: 52px;
	right: -2rem;
	${(p) => `bottom: calc(${p.index} * 62px + 2rem)`};
	/* bottom: 2rem; */

	animation: enter-leave 2.6s ease;
	transition: all 300ms ease;

	@keyframes enter-leave {
		0% {
			right: -2rem;
			opacity: 0;
		}
		7.5% {
			right: 10px;
			opacity: 1;
		}
		92.5% {
			right: 10px;
			opacity: 1;
		}
		100% {
			right: -2rem;
			opacity: 0;
		}
	}

	/* caret up  */
	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 5px;
		height: 100%;
		background: ${(p) =>
			p.type === "success"
				? p.theme.colorSuccess
				: p.type === "alert"
				? p.theme.colorActivation
				: p.theme.colorDanger};
	}
`;

const Alerts = () => {
	const theme = useTheme();
	const { removeAlert } = useAlert();
	const alerts = useSelector((state: RootState) => state.alerts.alerts);

	return (
		<>
			{alerts && alerts.length > 0
				? alerts.map((alert, index) => {
						return (
							<StyledInfoPopup key={alert.id} type={alert.type} index={index}>
								{alert.message}
								<Icon
									icon="close"
									width="20px"
									color={theme.colorCopyLight}
									onClick={() => removeAlert(alert.id)}
								/>
							</StyledInfoPopup>
						);
				  })
				: null}
		</>
	);
};

export default Alerts;
