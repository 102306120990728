import styled, { css, useTheme } from "styled-components";
import TextField from "../FormFields/TextField";
import CheckboxField from "../FormFields/CheckboxField";
import { useContext, useEffect, useState } from "react";
import Icon from "../Icon/Icon";
import ImagePlaceholder from "../../assets/placeholders/image-landscape.png";
import { Direction } from "../AnimateReorder/AnimateReorder";
import { CreditType } from "../../views/Admin/Program/ProgramInterfaces";
import {
	CompanyCredit,
	IndividualCredit,
} from "../../views/Admin/Credits/CreditInterfaces";
import { DragCard } from "./DragMediaAccordion";
import { useAlert } from "../Alert/Alerts";

export const DragCreditAccordion = (props: DragCreditAccordionProps) => {
	const theme = useTheme();
	const [collapse, setCollapse] = useState(true);
	const creditObj = props.creditObj;
	const isCompanyCredit = props.creditType === CreditType.Company;
	const { addNewAlert } = useAlert();

	const creditName = creditObj
		? isCompanyCredit
			? (creditObj as CompanyCredit).name
			: (creditObj as IndividualCredit).fullName
		: "";
	const creditType = creditObj
		? isCompanyCredit
			? (creditObj as CompanyCredit).companyType === "Other"
				? (creditObj as CompanyCredit).otherCompanyType
				: (creditObj as CompanyCredit).companyType
			: (creditObj as IndividualCredit).jobTitle === "Other"
			? (creditObj as IndividualCredit).otherJobTitle
			: (creditObj as IndividualCredit).jobTitle
		: "";
	const creditCityOrCompanyName = creditObj
		? isCompanyCredit
			? (creditObj as CompanyCredit).city
			: (creditObj as IndividualCredit).companyName
		: "";

	const creditCity = creditObj
		? isCompanyCredit
			? (creditObj as CompanyCredit).city
			: (creditObj as IndividualCredit).companyCity
		: "";

	const creditContactEmail =
		!isCompanyCredit && (creditObj as IndividualCredit).email;

	const creditCountry = isCompanyCredit && (creditObj as CompanyCredit).country;

	// set data transfer to checked or dragged credits
	const dragStartHandler = (
		e: React.DragEvent<HTMLElement>,
		checkedCreditsArr?: Array<CompanyCredit | IndividualCredit>
	) => {
		const id: string = e.currentTarget.getAttribute("data-credit-id")!;
		e.dataTransfer.setData(props.dropTarget, String(id));

		e.dataTransfer.effectAllowed = "copy";

		// set data to array of checked credits
		if (checkedCreditsArr && checkedCreditsArr.length > 0) {
			const transferData = {
				dropTarget: props.dropTarget,
				credit: checkedCreditsArr,
			};
			e.dataTransfer.setData("dropData", JSON.stringify(transferData));
		} else {
			// set data to single dropped media object

			const transferData = {
				dropTarget: props.dropTarget,
				credit: props.creditObj,
			};
			e.dataTransfer.setData("dropData", JSON.stringify(transferData));
		}

		//set public dataTransfer so it's visible before dropping: https://stackoverflow.com/questions/11065803/determine-what-is-being-dragged-from-dragenter-dragover-events
		e.dataTransfer.setData(
			CreditType[props.creditType],
			CreditType[props.creditType]
		);
	};

	return (
		<DragCard
			className={`drag-card ${props.className ? props.className : ""}`}
			draggable
			onDragStart={(e) => {
				dragStartHandler(e, props.onDrag && props.onDrag());
			}}
			data-credit-id={creditObj ? creditObj.id : ""}
			readOnly={props.readOnly}
		>
			<div className="drag-icons">
				<Icon
					icon="drag-arrows"
					color={theme.colorPrimary}
					className="drag-arrows"
				/>
			</div>

			<div className="credit-info">
				<div className="flex flex-col credit-field">
					<p className="font-semibold">{creditName}</p>
					{!isCompanyCredit ? (
						(creditObj as IndividualCredit).companyName ? (
							<p>{(creditObj as IndividualCredit).companyName}</p>
						) : (
							<p className="light">Company Name</p>
						)
					) : (
						<p>{creditCityOrCompanyName}</p>
					)}
					{!isCompanyCredit ? (
						creditObj.country ? (
							<p>{creditObj.country}</p>
						) : (
							<p className="light">Company Country</p>
						)
					) : (
						""
					)}
				</div>

				<div className="flex flex-col credit-field">
					<p className="font-semibold">{creditType}</p>

					{isCompanyCredit ? (
						<p>{creditCountry}</p>
					) : creditCity ? (
						<p>{creditCity}</p>
					) : (
						<p className="light">Company City</p>
					)}

					{!isCompanyCredit ? (
						creditContactEmail ? (
							<p>{creditContactEmail}</p>
						) : (
							<p className="light">Email</p>
						)
					) : (
						""
					)}
				</div>

				<div className="icon-container">
					<div className="flex flex-col gap-[.25rem]">
						<Icon
							className="triangle"
							icon="triangle"
							color={theme.colorPrimary}
							height="15px"
							width="15px"
							rotation="180deg"
							onClick={() => props.setIndex && props.setIndex(Direction.Up)}
							readonly={props.readOnly}
						/>

						<Icon
							className="triangle"
							icon="triangle"
							color={theme.colorPrimary}
							height="15px"
							width="15px"
							onClick={() => props.setIndex && props.setIndex(Direction.Down)}
							readonly={props.readOnly}
						/>
					</div>

					{props.onRemoveCredit && (
						<Icon
							icon="closeLarge"
							color={theme.colorPrimary}
							onClick={() => props.onRemoveCredit && props.onRemoveCredit()}
							readonly={props.readOnly}
						/>
					)}
				</div>

				{props.onCheck && (
					<CheckboxField
						className="checkbox-field"
						label="Select credit"
						name={`multiSelect${creditObj.id!}`}
						checked={creditObj.isCheckedForDrag}
						onChange={() => props.onCheck && props.onCheck()}
						nonFormik
					/>
				)}
			</div>
		</DragCard>
	);
};

interface DragCreditAccordionProps {
	dropTarget: string;
	// allCredits: CompanyCreditObj | IndividualCreditObj;
	creditObj: CompanyCredit | IndividualCredit; // specific credit object
	onRemoveCredit?: Function; // only used in drop zone
	onCheck?(): void; // only used in preview box for multiselect drag
	creditType: CreditType;
	onDrag?(): (CompanyCredit | IndividualCredit)[];
	// onDropSuccess?(): void;
	setIndex?(direction: Direction): void; // called to change the display order of a dropped credit
	className?: string;
	readOnly?: boolean;
}
