import { SettingsState } from "./settingsReducer";
import { FETCH_SETTINGS_SUCCESS, SettingsActionTypes } from "./types";

// export const fetchSettingsRequest = (): SettingsActionTypes => ({
// 	type: FETCH_SETTINGS_REQUEST,
// });

export const fetchSettingsSuccess = (
	settings: SettingsState
): SettingsActionTypes => ({
	type: FETCH_SETTINGS_SUCCESS,
	payload: settings,
});

// export const fetchSettingsFailure = (error: string): SettingsActionTypes => ({
// 	type: FETCH_SETTINGS_FAILURE,
// 	payload: error,
// });
