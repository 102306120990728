import { ReactComponent as TriangleIcon } from "../../assets/icons/Triangle.svg";
import styled, { css, useTheme } from "styled-components";
import { useEffect, useState } from "react";
import Icon from "../Icon/Icon";

export const SortContainer = styled.div<{
	isGrid?: boolean;
	gridColumns?: string;
	gridArea?: string;
	isIndented?: boolean;
	darkLabel?: boolean;
	responsive?: boolean; // automatically wraps columns as needed
	responsiveMinWidth?: string;
}>`
	display: flex;
	padding: 0 19px;
	margin-bottom: 1rem;

	& > * {
		max-width: 100%;
	}

	${(p) =>
		p.isGrid &&
		p.gridColumns &&
		p.gridArea &&
		css`
			display: grid;
			grid-template-columns: ${p.gridColumns};
			gap: 1rem;
			/* e.g. ". a b"; */
			grid-template-areas: "${p.gridArea}";
		`}

	${(p) =>
		p.isIndented &&
		css`
			margin-left: 3.09375rem;
		`}

     ${(p) =>
		p.darkLabel &&
		css`
			padding: 1rem;
			color: ${({ theme }) => theme.colorCopyLightLight};
			background: ${({ theme }) => theme.colorBackgroundDarkDark};
			margin-bottom: 0;
		`}

    --grid-item--min-width: ${(p) =>
		p.responsiveMinWidth ? p.responsiveMinWidth : "110px"};

	@media only screen and (max-width: ${({ theme }) => theme.xl}) {
		${(p) =>
			p.responsive &&
			css`
				display: grid;
				grid-template-columns: repeat(
					auto-fill,
					minmax(var(--grid-item--min-width), 1fr)
				);
				grid-gap: 1rem;
				grid-template-areas: unset;
			`}
	}
`;

const SortLabel = styled.div<{
	gridAreaLabel?: string;
	clickable: boolean;
}>`
	display: flex;
	align-items: center;
	user-select: none;
	white-space: nowrap;

	${(p) =>
		p.gridAreaLabel &&
		css`
			grid-area: ${p.gridAreaLabel};
		`}

	${(p) =>
		p.clickable &&
		css`
			cursor: pointer;
		`}
`;

const Sort = (props: SortProps) => {
	const theme = useTheme();
	return (
		<SortLabel
			className={props.className ? props.className : ""}
			onClick={() => (props.onClick ? props.onClick() : {})}
			style={{ width: props.width }}
			gridAreaLabel={props.gridAreaLabel || undefined}
			clickable={props.noSortTriangle ? false : true}
		>
			{props.label}
			{!props.noSortTriangle && (
				<Icon
					className="ml-[.5rem]"
					icon="triangle"
					color={theme.colorPrimary}
					rotation={props.ascending ? "180deg" : "0deg"}
					width={props.triangleSize}
					height={props.triangleSize}
				/>
			)}
		</SortLabel>
	);
};

export default Sort;

interface SortProps {
	className?: string;
	width?: string;
	label: string;
	onClick?(): void;
	ascending?: boolean;
	noSortTriangle?: boolean;
	triangleSize?: string;
	gridAreaLabel?: string;
}

export enum Alphabets {
	"A",
	"B",
	"C",
	"D",
	"E",
	"F",
	"G",
	"H",
	"I",
	"J",
	"K",
	"L",
	"M",
	"N",
	"O",
	"P",
	"Q",
	"R",
	"S",
	"T",
	"U",
	"V",
	"W",
	"X",
	"Y",
	"Z",
}
