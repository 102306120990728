import axios from "axios";
import { DropdownOptions } from "../../../components/FormFields/DropdownField";
import { urlFieldTemplates } from "../../../endpoints";
import {
  ProgramCreditField,
  ProgramDateField,
  ProgramLinkField,
  ProgramListField,
  ProgramMediaField,
  ProgramPhysicalComponentField,
  ProgramTextField,
} from "./ProgramInterfaces";
import {
  CreditFieldTemplate,
  DateFieldTemplate,
  LinkFieldTemplate,
  ListFieldTemplate,
  MediaFieldTemplate,
  PhysicalComponentFieldTemplate,
  TextFieldTemplate,
} from "./ProgramInterfaces";

export const FieldTemplateNames: DropdownOptions[] = [
  {
    value: 1,
    label: "Credits",
  },
  {
    value: 2,
    label: "Dates",
  },
  {
    value: 3,
    label: "List",
  },
  {
    value: 4,
    label: "Text",
  },
  {
    value: 5,
    label: "Link",
  },
  {
    value: 6,
    label: "Media",
  },
  {
    value: 7,
    label: "Physical Components",
  },
];

// #region CreditFieldTemplate
export async function getCreditFieldTemplates() {
  try {
    const response = await axios.get<CreditFieldTemplate[]>(
      `${urlFieldTemplates}/getcreditfieldtemplates`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function getActiveCreditFieldTemplates() {
  try {
    const response = await axios.get<CreditFieldTemplate[]>(
      `${urlFieldTemplates}/getactivecreditfieldtemplates`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function updateCreditFieldTemplate(
  value: CreditFieldTemplate | ProgramCreditField,
  setValue: (arg0: CreditFieldTemplate | ProgramCreditField) => void
) {
  if (!value.id) {
    // console.log("Template Id missing");
    return Promise.reject({ response: { status: "Template Id missing" } });
  }

  return axios.put(
    `${urlFieldTemplates}/creditfieldtemplates/${value.id}`,
    value
  );
  // .then((response) => {
  //   console.log("res", response);
  //   if (response.status == 204) {
  //     setValue(value);
  //   }
  // });
  //     .catch((error: any) => {
  //       console.log(error);
  //     });
}

export async function createCreditFieldTemplate(
  value: CreditFieldTemplate | ProgramCreditField
) {
  value.creditType = parseInt(value.creditType.toString());
  return await axios.post(
    `${urlFieldTemplates}/createcreditfieldtemplate`,
    value
  );
}
// #endregion CreditFieldTemplate

// #region DateFieldTemplate
export async function getDateFieldTemplates() {
  try {
    const response = await axios.get<DateFieldTemplate[]>(
      `${urlFieldTemplates}/getdatefieldtemplates`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function getActiveDateFieldTemplates() {
  try {
    const response = await axios.get<DateFieldTemplate[]>(
      `${urlFieldTemplates}/getactivedatefieldtemplates`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function updateDateFieldTemplate(
  value: DateFieldTemplate | ProgramDateField,
  setValue: (arg0: DateFieldTemplate | ProgramDateField) => void
) {
  if (!value.id) {
    // console.log("Template Id missing");
    return Promise.reject({ response: { status: "Template Id missing" } });
  }

  return axios.put(
    `${urlFieldTemplates}/datefieldtemplates/${value.id}`,
    value
  );
  // .then((response) => {
  //   if (response.status == 204) {
  //     setValue(value);
  //   }
  // })
  // .catch((error: any) => {
  //   console.log(error);
  // });
}

export async function createDateFieldTemplate(
  value: DateFieldTemplate | ProgramDateField
) {
  return await axios.post(
    `${urlFieldTemplates}/createdatefieldtemplate`,
    value
  );
}
// #endregion DateFieldTemplate

// #region LinkFieldTemplate
export async function getLinkFieldTemplates() {
  try {
    const response = await axios.get<LinkFieldTemplate[]>(
      `${urlFieldTemplates}/getlinkfieldtemplates`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function getActiveLinkFieldTemplates() {
  try {
    const response = await axios.get<LinkFieldTemplate[]>(
      `${urlFieldTemplates}/getactivelinkfieldtemplates`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function updateLinkFieldTemplate(
  value: LinkFieldTemplate | ProgramLinkField,
  setValue: (arg0: LinkFieldTemplate | ProgramLinkField) => void
) {
  if (!value.id) {
    // console.log("Template Id missing");
    return Promise.reject({ response: { status: "Template Id missing" } });
  }

  return axios.put(
    `${urlFieldTemplates}/linkfieldtemplates/${value.id}`,
    value
  );
  // .then((response) => {
  //   if (response.status == 204) {
  //     setValue(value);
  //   }
  // })
  // .catch((error: any) => {
  //   console.log(error);
  // });
}

export async function createLinkFieldTemplate(
  value: LinkFieldTemplate | ProgramLinkField
) {
  return await axios.post(
    `${urlFieldTemplates}/createlinkfieldtemplate`,
    value
  );
}
// #endregion LinkFieldTemplate

// #region ListFieldTemplate
export async function getListFieldTemplates() {
  try {
    const response = await axios.get<ListFieldTemplate[]>(
      `${urlFieldTemplates}/getlistfieldtemplates`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function getActiveListFieldTemplates() {
  try {
    const response = await axios.get<ListFieldTemplate[]>(
      `${urlFieldTemplates}/getactivelistfieldtemplates`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function updateListFieldTemplate(
  value: ListFieldTemplate | ProgramListField,
  setValue: (arg0: ListFieldTemplate | ProgramListField) => void
) {
  if (!value.id) {
    // console.log("Template Id missing");
    return Promise.reject({ response: { status: "Template Id missing" } });
  }
  return axios.put(
    `${urlFieldTemplates}/listfieldtemplates/${value.id}`,
    value
  );
  // .then((response) => {
  //   if (response.status == 204) {
  //     setValue(value);
  //   }
  // })
  // .catch((error: any) => {
  //   console.log(error);
  // });
}

export async function createListFieldTemplate(
  value: ListFieldTemplate | ProgramListField
) {
  return await axios.post(
    `${urlFieldTemplates}/createlistfieldtemplate`,
    value
  );
}
// #endregion ListFieldTemplate

// #region MediaFieldTemplate
export async function getMediaFieldTemplates() {
  try {
    const response = await axios.get<MediaFieldTemplate[]>(
      `${urlFieldTemplates}/getmediafieldtemplates`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function getActiveMediaFieldTemplates() {
  try {
    const response = await axios.get<MediaFieldTemplate[]>(
      `${urlFieldTemplates}/getactivemediafieldtemplates`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function updateMediaFieldTemplate(
  value: MediaFieldTemplate | ProgramMediaField,
  setValue: (arg0: MediaFieldTemplate | ProgramMediaField) => void
) {
  if (!value.id) {
    // console.log("Template Id missing");
    return Promise.reject({ response: { status: "Template Id missing" } });
  }

  return axios.put(
    `${urlFieldTemplates}/mediafieldtemplates/${value.id}`,
    value
  );
  // .then((response) => {
  //   if (response.status == 204) {
  //     setValue(value);
  //   }
  // })
  // .catch((error: any) => {
  //   console.log(error);
  // });
}

export async function createMediaFieldTemplate(
  value: MediaFieldTemplate | ProgramMediaField
) {
  return await axios.post(
    `${urlFieldTemplates}/createmediafieldtemplate`,
    value
  );
}
// #endregion MediaFieldTemplate

// #region PhysicalComponentFieldTemplate
export async function getPhysicalComponentFieldTemplates() {
  try {
    const response = await axios.get<PhysicalComponentFieldTemplate[]>(
      `${urlFieldTemplates}/getphysicalcomponentfieldtemplates`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function getActivePhysicalComponentFieldTemplates() {
  try {
    const response = await axios.get<PhysicalComponentFieldTemplate[]>(
      `${urlFieldTemplates}/getactivephysicalcomponentfieldtemplates`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function updatePhysicalComponentFieldTemplate(
  value: PhysicalComponentFieldTemplate | ProgramPhysicalComponentField,
  setValue: (
    arg0: PhysicalComponentFieldTemplate | ProgramPhysicalComponentField
  ) => void
) {
  if (!value.id) {
    // console.log("Template Id missing");
    return Promise.reject({ response: { status: "Template Id missing" } });
  }

  return axios.put(
    `${urlFieldTemplates}/physicalcomponentfieldtemplates/${value.id}`,
    value
  );
  // .then((response) => {
  //   if (response.status == 204) {
  //     setValue(value);
  //   }
  // })
  // .catch((error: any) => {
  //   console.log(error);
  // });
}

export async function createPhysicalComponentFieldTemplate(
  value: PhysicalComponentFieldTemplate | ProgramPhysicalComponentField
) {
  return await axios.post(
    `${urlFieldTemplates}/createphysicalcomponentfieldtemplate`,
    value
  );
}
// #endregion PhysicalComponentFieldTemplate

// #region TextFieldTemplate
export async function getTextFieldTemplates() {
  try {
    const response = await axios.get<TextFieldTemplate[]>(
      `${urlFieldTemplates}/gettextfieldtemplates`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function getActiveTextFieldTemplates() {
  try {
    const response = await axios.get<TextFieldTemplate[]>(
      `${urlFieldTemplates}/getactivetextfieldtemplates`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function updateTextFieldTemplate(
  value: TextFieldTemplate | ProgramTextField,
  setValue: (arg0: TextFieldTemplate | ProgramTextField) => void
) {
  if (!value.id) {
    // console.log("Template Id missing");
    return Promise.reject({ response: { status: "Template Id missing" } });
  }

  return axios.put(
    `${urlFieldTemplates}/textfieldtemplates/${value.id}`,
    value
  );
  // .then((response) => {
  //   if (response.status == 204) {
  //     setValue(value);
  //   }
  // })
  // .catch((error: any) => {
  //   console.log(error);
  // });
}

export async function createTextFieldTemplate(
  value: TextFieldTemplate | ProgramTextField
) {
  return await axios.post(
    `${urlFieldTemplates}/createtextfieldtemplate`,
    value
  );
}

export async function deleteProgramTextField(id: number) {
  if (!id) {
    // console.log("ProgramDisplay Id missing");
    return;
  }

  return await axios.delete(`${urlFieldTemplates}/ProgramTextFields/${id}`);
}

export async function deleteProgramCreditField(id: number) {
  if (!id) {
    // console.log("ProgramDisplay Id missing");
    return;
  }

  return await axios.delete(`${urlFieldTemplates}/ProgramCreditFields/${id}`);
}

export async function deleteProgramDateField(id: number) {
  if (!id) {
    // console.log("ProgramDisplay Id missing");
    return;
  }

  return await axios.delete(`${urlFieldTemplates}/ProgramDateFields/${id}`);
}

export async function deleteProgramListField(id: number) {
  if (!id) {
    // console.log("ProgramDisplay Id missing");
    return;
  }

  return await axios.delete(`${urlFieldTemplates}/ProgramListFields/${id}`);
}

export async function deleteProgramLinkField(id: number) {
  if (!id) {
    // console.log("ProgramDisplay Id missing");
    return;
  }

  return await axios.delete(`${urlFieldTemplates}/ProgramLinkFields/${id}`);
}

export async function deleteProgramMediaField(id: number) {
  if (!id) {
    // console.log("ProgramDisplay Id missing");
    return;
  }

  return await axios.delete(`${urlFieldTemplates}/ProgramMediaFields/${id}`);
}

export async function deleteProgramPhysicalComponentField(id: number) {
  if (!id) {
    // console.log("ProgramDisplay Id missing");
    return;
  }

  return await axios.delete(
    `${urlFieldTemplates}/ProgramPhysicalComponentFields/${id}`
  );
}

export async function getIndividualCreditFieldTemplatesOptions() {
  try {
    const response = await axios.get<DropdownOptions[]>(
      `${urlFieldTemplates}/GetIndividualCreditFieldTemplatesOptions`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function getCompanyCreditFieldTemplatesOptions() {
  try {
    const response = await axios.get<DropdownOptions[]>(
      `${urlFieldTemplates}/GetCompanyCreditFieldTemplatesOptions`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}
// #endregion TextFieldTemplate
