import Button from "../../../../components/Button/Button";

import styled from "styled-components";
import { useEffect, useState } from "react";

import { DropdownOptions } from "../../../../components/FormFields/DropdownField";
import { getRootProgramOptions } from "../../Program/manageProgram";
import DashboardCard from "../../../../components/DashboardCard/DashboardCard";
import HierarchyNav, {
	HierarchyCard,
} from "../../../../components/HierarchyNav/HierarchyNav";
import BrowserHeader from "../../../../components/BrowserHeader/BrowserHeader";

const ProgramLabel = styled.p`
	font-weight: ${({ theme }) => theme.fontSemiBold};
	white-space: pre-wrap !important;
	text-overflow: initial !important;
`;

export default function JudgingConfigLanding() {
	const [rootProgramIDs, setRootProgramIDs] = useState<DropdownOptions[]>([]);
	useEffect(() => {
		// get root program dropdown options
		getRootProgramOptions().then((res) => {
			setRootProgramIDs(res.data);
		});
	}, []);

	return (
		<DashboardCard title="Judging Configuration">
			<BrowserHeader title="Judging Configuration" />
			<div className="px-[1rem] flex justify-between mb-[1rem]"></div>
			{rootProgramIDs && rootProgramIDs.length > 0 && (
				<HierarchyNav
					responsive={true}
					columns={[{ label: "Program Title" }, { label: null }]}
				>
					{(gridColumns: string, gridArea: string) =>
						rootProgramIDs.map((program, index) => (
							<HierarchyCard
								responsive={true}
								key={index}
								gridColumns={gridColumns}
								gridArea={gridArea}
							>
								<ProgramLabel>{program.label}</ProgramLabel>
								<Button
									className="button-light !min-w-0 max-w-full ml-auto"
									iconLeft="edit"
									to={`/judging/config/${program.value}`}
								>
									Configure
								</Button>
							</HierarchyCard>
						))
					}
				</HierarchyNav>
			)}
		</DashboardCard>
	);
}
