import {
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Image,
	Font,
} from "@react-pdf/renderer";
import { OrderModel } from "./OrderInterfaces";
import { defaultColors } from "../../assetsConfig";
import { showTwoDecimals } from "../../components/CheckoutEntryCard/CheckoutEntryCard";
import { TransactionType } from "../Checkout/OrderInterfaces";
import { getHierarchy } from "./manageCheckout";
import dateTostring from "../../utils/dateToString";
import Placeholder from "../../assets/placeholders/placeholder.png";
import { getNonWebpThumbnailSrc } from "../../views/MediaLibrary/manageMediaLibrary";
import config from "../../config";
import { pdfColors } from "../../assetsConfig";
import assetsConfig from "../../assetsConfig";

Font.register({
	family: assetsConfig.fonts.pdf.fontFamily,
	fonts: assetsConfig.fonts.pdf.fonts,
});

// Create styles
const styles = StyleSheet.create({
	page: {
		display: "flex",
		flexDirection: "column",
		width: "100vw",
		flexGrow: 1,
		backgroundColor: "white",
		fontSize: "12px",
		fontFamily: "Open Sans",
	},
	pageBody: {
		width: "100%",

		padding: "0 47px",
	},
	twoCol: {
		display: "flex",
		flexDirection: "row",
		// flexGrow: 1,
		justifyContent: "space-between",
		width: "100%",
	},
	flexCol: {
		display: "flex",
		flexDirection: "column",
	},
	flexRow: {
		display: "flex",
		flexDirection: "row",
	},
	itemsCenter: {
		alignItems: "center",
	},
	justifyCenter: {
		justifyContent: "center",
	},
	bold: {
		fontWeight: 900,
	},

	editInvoiceContainer: {
		border: `2px solid ${defaultColors.colorDanger}`,
		padding: "11px",
	},
	entryCard: {
		display: "flex",
		padding: "12px",
		border: `2px solid ${defaultColors.colorAwardGold}`,
	},
	entryCoverImg: {
		width: "65px",
		height: "35px",
		marginRight: "16px",
		objectFit: "cover",
	},
	entryFirstRow: {
		justifyContent: "space-between",
		marginBottom: "7.5px",
	},

	logo: {
		width: "200px",
		height: "35px",
	},
	mtAuto: {
		marginTop: "auto",
		// justifyContent: "flex-end",
	},
	mlAuto: {
		marginLeft: "auto",
	},
	wFull: {
		width: "100%",
	},
	mr1: {
		marginRight: "16px",
	},
	mr3: {
		marginRight: "48px",
	},
	mb1: {
		marginBottom: "16px",
	},
	mb3: {
		marginBottom: "48px",
	},
	mb4: {
		marginBottom: "64px",
	},
	mbHalf: {
		marginBottom: "8px",
	},
	mt1: {
		marginTop: "16px",
	},
	mt2: {
		marginTop: "32px",
	},
	headingText: {
		fontSize: "22px",
		textTransform: "uppercase",
	},
	subHeadingText: {
		fontSize: "16px",
	},
	textHighlight: {
		color: pdfColors.textHighlight,
	},
	textLight: {
		color: pdfColors.textLight,
	},
	textDanger: {
		color: defaultColors.colorDanger,
	},
	textSmall: {
		fontSize: "10px",
	},
	orderTotalContainer: {
		padding: "5px 16px ",
		border: `1px solid ${defaultColors.colorDanger}`,
		marginLeft: "auto",
	},

	pageBorderTop: {
		width: "100vw",
		height: "32px",
		backgroundColor: defaultColors.colorActivationDark,
		marginBottom: "32px",
	},
	pageBorderBottom: {
		width: "100vw",
		height: "16px",
		backgroundColor: defaultColors.colorAwardGold,
		marginTop: "64px",
	},
	paymentBottom: {
		padding: "0 47px",
		marginLeft: "auto",
		marginTop: "auto",
	},
	wrap: {
		flexWrap: "wrap",
	},
});

export function shortString(title: string, subStrAmt: number) {
	if (title.length > subStrAmt) {
		return title.slice(0, subStrAmt).trimEnd() + "...   ";
	} else {
		return title;
	}
}

const InvoicePdf = (props: OrderModel) => {
	let date: Date = new Date();
	let now = date.toLocaleDateString();

	function returnInvoiceNotes(notes: string) {
		if (Boolean(notes)) {
			return (
				<View style={{ ...styles.editInvoiceContainer, ...styles.mbHalf }}>
					<Text style={styles.textDanger}>Invoice Notes: </Text>
					<Text>{notes}</Text>
				</View>
			);
		}
		return;
	}

	return (
		<Document
			// title={String(props.id)}
			author={assetsConfig.companyNameShort}
			// subject={`Invoice #${props.id}`}
			keywords={assetsConfig.companyNameShort + ", Invoice Entry"}
		>
			<Page size="A4" style={styles.page}>
				<View style={styles.pageBorderTop}></View>
				<View style={styles.pageBody}>
					<View style={{ ...styles.twoCol, ...styles.mb4 }}>
						<Image style={styles.logo} src={config.assets.logos.localPng} />

						<View style={{ ...styles.flexRow }}>
							<View style={{ ...styles.flexCol, ...styles.mr1 }}>
								<Text style={styles.bold}>{assetsConfig.companyNameLong}</Text>
								<Text>EIN 82-3070062</Text>
								<Text>Attn: David Heide</Text>
							</View>
							<View style={styles.flexCol}>
								<Text>104 West 27th Street</Text>
								<Text>10th Floor</Text>
								<Text>New York, NY 10001</Text>
								<Text>United States</Text>
							</View>
						</View>
					</View>

					<View style={{ ...styles.twoCol, ...styles.mb1 }}>
						<Text style={{ ...styles.headingText }}>INVOICE {props.id}</Text>

						<View style={styles.orderTotalContainer}>
							<Text
								style={{
									...styles.bold,
									...styles.subHeadingText,
									...styles.textHighlight,
								}}
							>
								Order Total: ${showTwoDecimals(props.orderAmount)}
							</Text>
						</View>
					</View>

					<View style={{ ...styles.flexRow, ...styles.mb3 }}>
						<View style={{ ...styles.flexCol, ...styles.mr3 }}>
							<Text style={{ ...styles.textSmall, ...styles.textLight }}>
								Billed to:
							</Text>
							<Text>{shortString(props.companyName, 75)}</Text>
							<Text>{props.fullName}</Text>
							<Text>{props.companyAddress1}</Text>
							<Text>{props.companyAddress2}</Text>
							<Text>
								{props.companyCity}, {props.companyState},{" "}
								{props.companyPostalCode}
							</Text>
							<Text>{props.companyCountry}</Text>
						</View>

						<View style={{ ...styles.flexCol }}>
							<View style={styles.flexCol}>
								<Text style={{ ...styles.textLight, ...styles.textSmall }}>
									Date of Issue:
								</Text>
								<Text>{dateTostring(new Date())}</Text>
							</View>
						</View>
					</View>

					{props.lineItems.map((lineItem) => (
						<View
							key={lineItem.id}
							style={{
								...styles.flexRow,
								...styles.entryCard,
								...styles.mbHalf,
							}}
						>
							<Image
								src={
									(lineItem.entry.coverImage &&
										getNonWebpThumbnailSrc(lineItem.entry.coverImage!)) ||
									Placeholder
								}
								style={styles.entryCoverImg}
							/>
							<View
								style={{
									...styles.flexCol,
									...styles.justifyCenter,
									...styles.wFull,
								}}
							>
								<View style={{ ...styles.entryFirstRow, ...styles.flexRow }}>
									{lineItem.entry.title && (
										<Text style={styles.bold}>
											{shortString(lineItem.entry.title, 35)}{" "}
										</Text>
									)}

									<Text>Entry ID: #{lineItem.entryId} </Text>

									<Text style={styles.mlAuto}>
										Amount: $ {showTwoDecimals(lineItem.totalLineAmount!)}
									</Text>
								</View>
								<View style={{ ...styles.flexRow, ...styles.wrap }}>
									{getHierarchy(
										lineItem.entry.executions!,
										lineItem.entry.ancestry!,
										lineItem.entry.isCampaign!
									).map((hierarchy, index) => {
										return (
											<Text>
												{hierarchy}{" "}
												{index < lineItem.entry.ancestry!.length - 1 && "/ "}
											</Text>
										);
									})}
								</View>
							</View>
						</View>
					))}

					<Text style={{ ...styles.mt2 }}>
						{returnInvoiceNotes(props.notes!)}
					</Text>
				</View>

				<View style={{ ...styles.paymentBottom }}>
					<Text style={{ ...styles.bold, ...styles.mbHalf }}>
						{TransactionType[props.transactionType]} Payment
					</Text>
					{props.transactionType === TransactionType.Bank && (
						<Text style={{ ...styles.textHighlight, ...styles.mbHalf }}>
							+$25.00 Transfer Fee
						</Text>
					)}
					<Text style={{ ...styles.bold, ...styles.textHighlight }}>
						Order Total: ${showTwoDecimals(props.orderAmount)}
					</Text>
				</View>
				<View style={styles.pageBorderBottom}></View>
			</Page>
		</Document>
	);
};

export default InvoicePdf;
