import enumToDropdownOptions from "../../utils/enumToDropdownOptions";

export interface MediaItem {
  id?: string;
  juryId?: number;
  companyId: number;
  fileName: string;
  dimensions?: string;
  length?: string;
  path: string;
  type: MediaType;
  size: string;
  tags?: Tag[];
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
  videoResizePath?: string;
  isActive: boolean;
  isApproved?: boolean;
  approvedFlaggedOnEntryId?: number;
  approvedFlaggedByUser?: string;

  // front-end only properties
  isCheckedForDrag?: boolean;
  droppedLocation?: Array<string>;
  dropTarget?: string;
  weight?: number;
  joinId?: number;
  hideForWinnersGallery?: boolean;
}

export interface Tag {
  id: number;
  companyId: number;
  tag: string;
  mediaCount?: number;
  individualCreditsCount?: number;
  companyCreditsCount?: number;
  createdDate?: Date;
  updatedDate?: Date;
  createdBy?: string;
  modifiedBy?: string;
}

export interface MediaCreditEntryInfoModel {
  id: number;
  title: string;
  hierarchy: string;
}

export enum MediaType {
  Video,
  Image,
  Document,
  Audio,
}

// used only for sorting media in the media lib
export enum SortedMediaType {
  Audio,
  Document,
  Image,
  Video,
}

export const mediaTypeOptions = enumToDropdownOptions(MediaType);
