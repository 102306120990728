import styled from "styled-components";
import { css } from "styled-components";

const Wrapper = styled.div<{ fontSize?: string }>`
	${(p) =>
		p.fontSize &&
		css`
			& > * {
				font-size: ${p.fontSize} !important;
			}
		`};
`;

const TextSize = (props: TextSizeProps) => {
	const getFontSize = (currentCount: number, breakpoints: BreakPoint[]) => {
		let fontSize: number | undefined = undefined;
		let highestCharacterCount = 0;

		// adjust font size up until highest character count
		breakpoints.forEach((breakpoint) => {
			if (
				currentCount >= breakpoint.characterCount &&
				(highestCharacterCount === 0 ||
					breakpoint.characterCount > highestCharacterCount)
			) {
				highestCharacterCount = breakpoint.characterCount;
				fontSize = breakpoint.fontSize;
			}
		});

		return fontSize ? fontSize + "px" : undefined;
	};

	return (
		<Wrapper fontSize={getFontSize(props.characterCount, props.breakpoints)}>
			{props.children}
		</Wrapper>
	);
};

export default TextSize;

interface TextSizeProps {
	children: React.ReactNode;
	characterCount: number;
	breakpoints: BreakPoint[];
}

interface BreakPoint {
	characterCount: number;
	fontSize: number;
}
