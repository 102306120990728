import { ButtonLink } from "../../components/Button/Button";
import {
	HoverMenu,
	HoverMenuLink,
	LinkLabel,
} from "../../components/HoverMenu/HoverMenu";
import { CSSTransition } from "react-transition-group";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ActiveBar } from "./JudgingGalleryNav";
import styled, { useTheme } from "styled-components";

import {
	JudgingRoundStatus,
	JurorCardConfig,
	JurorEntrySetSelectionCard,
} from "../Admin/Judging/JudgingInterfaces";
import Icon from "../../components/Icon/Icon";
import { getJurorEntrySetSelectionCards } from "../Admin/User/managerUser";

const StyledJudgeLink = styled(ButtonLink)`
	background: ${({ theme }) => theme.colorPrimary};
	height: 100%;
	white-space: nowrap;

	&:hover {
		background: ${({ theme }) => theme.colorPrimaryTint};
	}
`;

const StyledMenuLink = styled(HoverMenuLink)<{ lastLink?: boolean }>`
	position: relative;
	white-space: nowrap;
	width: 100%;
	gap: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 600;
	cursor: pointer;
	border-bottom: 4px solid ${({ theme }) => theme.colorBackgroundLight} !important;
	border-top: 0;

	&:hover ${ActiveBar} {
		width: 55px;
	}
`;

const JudgeNowButton = (props: JudgeNowButtonProps) => {
	const theme = useTheme();
	const [showDropdown, setShowDropdown] = useState(false);

	const { juryId } = useParams<{
		juryId: string;
	}>();
	const parsedJuryId = juryId ? parseInt(juryId) : 0;

	const [juror, setJuror] = useState<JurorCardConfig[]>([]);

	useEffect(() => {
		getJurorEntrySetSelectionCards().then((response) => {
			if (response.status == 200) {
				const juryIds = response.data.reduce((arr, eSetCard) => {
					// only show jury with entry sets left to judge
					// const juryHasEntries = eSetCard.votesCast !== eSetCard.votesPossible;
					// if (juryHasEntries) {
					// 	arr.push(eSetCard.juryId);
					// }

					// only show active rounds
					if (eSetCard.status !== JudgingRoundStatus.Pending) {
						arr.push(eSetCard.juryId);
					}

					return arr;
				}, [] as number[]);

				const uniqueJuryIds = new Set(juryIds);

				const jurorsWithEntries = props.jurors.flatMap((juror) => {
					const juryHasEntries = uniqueJuryIds.has(juror.juryId);
					if (juryHasEntries) {
						uniqueJuryIds.delete(juror.juryId);
						return juror;
					} else return [];
				});

				setJuror(jurorsWithEntries);
			}
		});
	}, [props.jurors]);

	return (
		<div
			className="relative h-full ml-auto w-full"
			onClick={() => setShowDropdown(!showDropdown)}
			onMouseLeave={() => setShowDropdown(false)}
		>
			<StyledJudgeLink
				icon="statue"
				iconHeight="40px"
				iconWidth="30px"
				iconColor={theme.colorCopyLightLight}
			>
				Judge Now
				<Icon
					icon="caret"
					color={theme.colorCopyLightLight}
					className={`ml-auto lg:ml-[1rem] ${showDropdown && "open"} `}
					rotation={showDropdown ? "0" : "180deg"}
				/>
			</StyledJudgeLink>
			<CSSTransition
				in={showDropdown}
				timeout={150}
				unmountOnExit
				classNames="hover-menu"
			>
				<HoverMenu caretColor={theme.colorActivation}>
					<div className="overflow-y-auto">
						{juror.length > 0 ? (
							juror.map((juror, i) => (
								<Link
									key={i}
									className="no-underline"
									to={`/judge/${juror.juryId}`}
								>
									<StyledMenuLink>
										<LinkLabel className="whitespace-pre-wrap md:whitespace-nowrap">
											{juror.juryName}
										</LinkLabel>

										<ActiveBar leftPosition="25px" />
									</StyledMenuLink>
								</Link>
							))
						) : (
							<Link to="" className="no-underline">
								<StyledMenuLink>
									<LinkLabel className="whitespace-pre-wrap md:whitespace-nowrap">
										No Entries to Judge
									</LinkLabel>
								</StyledMenuLink>
							</Link>
						)}

						{/* {props.juryId && (
              <Link className="no-underline" to={`/judge/${props.juryId}`}>
                <div className="flex items-center justify-center gap-[.5rem] bg-colorActivation p-[.5rem_1rem]">
                  <Icon
                    icon="caret"
                    rotation="90deg"
                    color={theme.colorCopyLightLight}
                  />
                  <p className="text-colorCopyLightLight">Back to Entry Sets</p>
                </div>
              </Link>
            )} */}
					</div>
				</HoverMenu>
			</CSSTransition>
		</div>
	);
};

export default JudgeNowButton;

interface JudgeNowButtonProps {
	juryId?: string;
	jurors: JurorCardConfig[];
}

export interface AvailableEntryRounds {
	label: string;
	path: string;
}
