import { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTheme } from "styled-components";
import styled from "styled-components";
import Icon from "../Icon/Icon";

export const Circle = styled.div<{ size?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  border: solid 7px ${({ theme }) => theme.colorPrimary};
  ${(p) =>
    p.size !== undefined &&
    `
      width: ${p.size};
      height: ${p.size};
    `};
`;

const CircleContainer = styled.div<{ size?: string }>`
  ${(p) =>
    p.size !== undefined &&
    `
      width: ${p.size};
      height: ${p.size};
    `};
`;

const StyledCircularProgressBar = styled.div<{ size?: string }>`
  cursor: default;
  .CircularProgressbar .CircularProgressbar-text {
    font-weight: ${({ theme }) => theme.fontSemiBold};
  }

  ${(p) =>
    p.size !== undefined &&
    `
      width: ${p.size};
      height: ${p.size};
    `};
`;

const ProgressProvider = (props: {
  valueStart: number;
  valueEnd: number;
  children(value: number): React.ReactElement;
}) => {
  const [value, setValue] = useState(props.valueStart);
  useEffect(() => {
    setValue(props.valueEnd);
  }, [props.valueEnd]);

  return props.children(value);
};

const ProgressCircle = (props: {
  value: number;
  text: string;
  size: string;
  textColor?: string;
  showFullProgress?: boolean; // show 100% progress instead of check mark
}) => {
  const theme = useTheme();

  return (
    <StyledCircularProgressBar size={props.size || undefined}>
      {props.value === 100 && !props.showFullProgress ? (
        <Circle size={props.size || undefined}>
          <Icon
            icon="check"
            color={theme.colorPrimary}
            width="25px"
            height="25px"
            defaultCursor
          />
        </Circle>
      ) : (
        <ProgressProvider valueStart={0} valueEnd={props.value}>
          {(value) => (
            <CircleContainer size={props.size || undefined}>
              <CircularProgressbar
                value={value}
                text={props.text}
                strokeWidth={13}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  textSize: theme.fontCopy,
                  textColor: props.textColor || theme.colorCopyDarkDark,
                  pathColor: theme.colorPrimary,
                  trailColor: theme.colorBackgroundLight,
                  pathTransitionDuration: 0.5,
                  pathTransition: "ease",
                })}
              />
            </CircleContainer>
          )}
        </ProgressProvider>
      )}
    </StyledCircularProgressBar>
  );
};

export default ProgressCircle;
