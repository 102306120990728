import { useEffect, useState } from "react";
import { getRootProgramOptions } from "../../views/Admin/Program/manageProgram";
import DropdownField, { DropdownOptions } from "../FormFields/DropdownField";
import * as Yup from "yup";
import Button from "../Button/Button"; // import your Button component
import styled, { css } from "styled-components";
import {
  getEntryCreationCheckoutNumbers,
  getEntryProgramReviewStatuses,
  getRootProgramCounts,
  LabelAndCount,
  ReviewStatusesAndTotal,
} from "./manageReports";
import ReportingGraphs from "./ReportingGraphs";
import ResultsTable from "./ResultsTable";
import { FormikProvider, useFormik } from "formik";
import config from "../../config";

const LoadingSpinner = styled.img`
  width: 100px;
`;

const spinnerSrc = config.assets.loading.primary;

const EntryReview = () => {
  const [rootProgramIDs, setRootProgramIDs] = useState<DropdownOptions[]>([]);
  const [selectedProgramId, setSelectedProgramId] = useState<
    string | number | undefined
  >();
  const [selectedProgramName, setSelectedProgramName] = useState<string>();
  const [reportCounts, setReportCounts] = useState<LabelAndCount[]>([]);
  const [programCounts, setProgramCounts] = useState<ReviewStatusesAndTotal[]>(
    []
  );
  const [programLoading, setProgramLoading] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState<
    { id: string | number; name: string } | undefined
  >();

  // Fetch root program options when the component mounts
  useEffect(() => {
    getRootProgramOptions().then((res) => {
      setRootProgramIDs(res.data);
    });
  }, []);

  const generateInformation = async () => {
    if (selectedProgramId) {
      setProgramLoading(true); // set loading to true when data fetch starts
      try {
        const counts = await getRootProgramCounts(Number(selectedProgramId));
        setReportCounts(counts);
      } catch (error) {
        console.error("Failed to get program counts: ", error);
      }
      try {
        const programCounts = await getEntryProgramReviewStatuses(
          Number(selectedProgramId)
        );
        setProgramCounts(programCounts);
      } catch (error) {
        console.error("Failed to get entry program review statuses: ", error);
      }
      // set loading to false when data fetch ends
      setProgramLoading(false);
      try {
        const checkoutCounts = await getEntryCreationCheckoutNumbers(
          Number(selectedProgramId)
        );
      } catch (error) {
        console.error("Failed to get entry program review statuses: ", error);
      }
    }
  };

  const formikProps = useFormik({
    initialValues: { rootProgramIDs },
    enableReinitialize: true,
    onSubmit: async () => {},
    validationSchema: Yup.object({}),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div>
      <FormikProvider value={formikProps}>
        <div className="flex items-center space-x-4 pb-5">
          {" "}
          <DropdownField
            name="rootProgram"
            options={rootProgramIDs}
            value={selectedProgramId || undefined}
            placeholder="Select a Program"
            onChange={(value) => {
              const selectedValue = Number(value.target.value);
              const selectedOption = rootProgramIDs.find(
                (option) => option.value === selectedValue
              );
              setSelectedProgramId(selectedValue);
              setSelectedProgramName(String(selectedOption?.label || ""));
            }}
          />
          <Button onClick={generateInformation}>Generate</Button>
        </div>
        {reportCounts.length > 0 && (
          <div className="pt-5 flex justify-center items-center space-x-4">
            <div className="w-1/2 pb-5">
              <ResultsTable
                data={reportCounts}
                disableExport={true}
                showTopScroll={false}
              />
            </div>
          </div>
        )}
        {programLoading ? (
          <div className="pt-10 flex flex-col items-center justify-center">
            <div>Running Entry Level Reports...</div>
            <LoadingSpinner src={spinnerSrc} />
          </div>
        ) : programCounts.length > 0 ? (
          <div>
            <ReportingGraphs data={programCounts} />
            <ResultsTable
              data={programCounts}
              programName={selectedProgramName}
            />
          </div>
        ) : null}
      </FormikProvider>
    </div>
  );
};

export default EntryReview;
