import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import config from "../../config";
import Icon from "../Icon/Icon";

const LoadingContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

const LoadingDiv = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	text-align: -webkit-center;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const LoadingLogo = styled.img`
	width: 350px;
`;

const LoadingSpinner = styled.img<{ size?: string }>`
	height: 100px;
	${(p) =>
		p.size &&
		`
    height: ${p.size};
  `}
`;

interface LoadingProps {
	className?: string;
	fullScreen?: boolean;
	showLogo?: boolean;
	text?: string; // text to show under the spinner
	size?: string;
}

const Loading: React.FC<LoadingProps> = ({
	className,
	// defaulting these to true so to keep the current behavior of the component and not break in places where it's used
	fullScreen = true,
	showLogo = true,
	text,
	size,
}) => {
	const theme = useTheme();
	const spinnerSrc = config.assets.loading.primary;

	const Loader = (props: { className?: string }) => (
		<LoadingDiv className={props.className}>
			{showLogo && (
				<>
					<Icon icon="logo" color={theme.colorLogoDarkDark} width="350px" />
					<br />
				</>
			)}

			<LoadingSpinner size={size} src={spinnerSrc} />
			{text && <div className="font-bold uppercase">{text}</div>}
		</LoadingDiv>
	);

	return fullScreen ? (
		<LoadingContainer className={className}>
			<Loader />
		</LoadingContainer>
	) : (
		<Loader className={className} />
	);
};

// a global fullscreen overlay component
export const LoadingOverlay = (props: LoadingOverlayProps) => {
	useEffect(() => {
		if (props.show) {
			document.documentElement.style.overflow = "hidden";
		} else {
			document.documentElement.style.overflow = "unset";
		}
	}, [props.show]);

	return props.show && props.message ? (
		<Loading
			className="!fixed !z-[999999999999] bg-colorBackgroundLightLight"
			text={props.message}
		/>
	) : (
		<></>
	);
};

export default Loading;

interface LoadingOverlayProps {
	show: boolean;
	message: string | null;
}
