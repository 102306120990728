import axios from "axios";
import {
  urlAdminProgramDisplays,
  urlProgramDisplays,
} from "../../../endpoints";
import { ProgramDisplay } from "./ProgramInterfaces";

export async function getProgramDisplayById(id: number) {
  try {
    const response = await axios.get<ProgramDisplay>(
      `${urlProgramDisplays}/${id}`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function getProgramDisplays() {
  try {
    const response = await axios.get<ProgramDisplay[]>(`${urlProgramDisplays}`);
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function getChildProgramDisplays(id: number) {
  try {
    const response = await axios.get<ProgramDisplay[]>(
      `${urlProgramDisplays}/programDisplayChildren/${id}`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function updateProgramDisplay(value: ProgramDisplay) {
  if (!value.id) {
    // console.log("ProgramDisplay Id missing");
    return;
  }

  // start formdata
  const formData = new FormData();
  formData.append("EntryKitData", value.entryKitData || "");
  value.entryKitData = undefined;
  formData.append("BestPracticesData", value.bestPracticesData || "");
  value.bestPracticesData = undefined;
  formData.append("ProgramDisplay", JSON.stringify(value) || "");

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  return await axios.put(`${urlAdminProgramDisplays}/${value.id}`, formData, {
    headers: headers,
  });
}

export async function createProgramDisplay(value: any) {
  value.program = undefined;
  // start formdata
  const formData = new FormData();
  formData.append("EntryKitData", value.entryKitData || "");
  value.entryKitData = undefined;
  formData.append("BestPracticesData", value.bestPracticesData || "");
  value.bestPracticesData = undefined;
  formData.append("ProgramDisplay", JSON.stringify(value) || "");

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  return await axios.post(`${urlAdminProgramDisplays}`, formData, {
    headers: headers,
  });
}

export async function deleteProgramDisplay(id: number) {
  if (!id) {
    // console.log("ProgramDisplay Id missing");
    return;
  }

  return await axios.delete(`${urlAdminProgramDisplays}/${id}`);
}
