import styled, { useTheme } from "styled-components";
import Button from "../../components/Button/Button";
import CheckoutEntryCard from "../../components/CheckoutEntryCard/CheckoutEntryCard";
import CompanyIcon from "../../assets/placeholders/placeholder.png";
import { useContext, useEffect, useState } from "react";

import {
	StickyCardBody,
	StickyHeaderBar,
	StickyContainer,
} from "../../components/StickyCard/StickyCard";
import dateTostring, { longDateToString } from "../../utils/dateToString";
import { CampaignExecutionCard } from "../../components/CheckoutEntryCard/CheckoutEntryCard";
import { DottedLines } from "../../components/CheckoutEntryCard/CheckoutEntryCard";
import Icon from "../../components/Icon/Icon";
import { getCompanyOrders } from "./manageCheckout";
import { OrderModel, TransactionType } from "./OrderInterfaces";
import HierarchyDropDownModal from "../../components/HierarchyDropDownModal/HierarchyDropDownModal";
import Loading from "../../components/Loading/Loading";
import { EntryAction } from "../../components/HierarchyDropDownModal/HierarchyDropDown";
import { tr } from "date-fns/locale";
import { set } from "date-fns";
import { CollapsibleContainer } from "../../components/Collapsible/Collapsible";
import DownloadPhysicalEntryPDF from "../Admin/Orders/DownloadPhysicalEntryPDF";
import { CheckoutEntryCardAlertContext } from "./CheckoutContext";
import DropdownField from "../../components/FormFields/DropdownField";
import { FormikProvider, useFormik } from "formik";
import { filterOrderByDate, filterOrderByDateOptions } from "./sortOrders";
import assetsConfig from "../../assetsConfig";
import { commafy } from "../../utils/commafy";

const OrdersTab = () => {
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [companyOrders, setCompanyOrders] = useState<OrderModel[]>([]);
	const [filteredCompanyOrders, setFilteredCompanyOrders] = useState<
		OrderModel[]
	>([]);
	const legalPdf = process.env.REACT_APP_S3_HOST + "assets/LegalDisclaimer.pdf";
	const [entryIdToDuplicate, setEntryIdToDuplicate] = useState<null | number>(
		null
	);
	const { addCounter } = useContext(CheckoutEntryCardAlertContext);

	const formikProps = useFormik<{ sortOrders: number | undefined }>({
		initialValues: { sortOrders: undefined },
		enableReinitialize: true,
		onSubmit: async (value) => {},
	});

	useEffect(() => {
		getCompanyOrders()
			.then((response) => {
				setIsLoading(false);
				// const ids = response.data.map((order) => String(order.id));
				// setCollapse(ids);
				// console.log("getProgramById", response);
				setCompanyOrders(response.data);
			})
			.catch((error) => {
				setIsLoading(false);
				// console.log("getCompanyOrders ERROR", error);
				// setErrors((old) => [...old, error]);
			});
	}, []);

	// update user menu counters
	useEffect(() => {
		addCounter();
	}, [companyOrders]);

	useEffect(() => {
		if (formikProps.values.sortOrders === undefined) {
			setFilteredCompanyOrders(companyOrders);
		} else {
			setFilteredCompanyOrders(
				filterOrderByDate(
					companyOrders,
					formikProps.values.sortOrders as number
				)
			);
		}
	}, [formikProps.values.sortOrders, companyOrders]);

	return (
		<div>
			<HierarchyDropDownModal
				entryId={entryIdToDuplicate}
				setEntryId={setEntryIdToDuplicate}
				action={EntryAction.Duplicate}
			/>
			<div className="flex justify-between">
				<div>
					<h2>Completed Orders</h2>
					<p className="my-[1rem] ">{assetsConfig.copy.ordersTabDescription}</p>
					<a href={legalPdf}>View Our Legal Disclaimer</a>
				</div>

				<FormikProvider value={formikProps}>
					<DropdownField
						className="w-[250px] h-fit"
						name="sortOrders"
						options={filterOrderByDateOptions}
						placeholder="Sort Orders"
					/>
				</FormikProvider>
			</div>

			<StickyContainer
				maxH={1000 * 3000 + "px"}
				className="mb-[200px] mt-[3rem]"
			>
				{isLoading && (
					<Loading text="Loading Orders" fullScreen={false} showLogo={false} />
				)}
				{filteredCompanyOrders.map((order) => (
					<CollapsibleContainer
						key={order.id}
						className="mb-[2rem]"
						title={`ORDER ID: #${String(order.id)}`}
						icons={[
							...(order.hasPhysicalComponent
								? [
										<DownloadPhysicalEntryPDF
											order={order}
											title="Download Physical Component PDF"
											buttonClass="button-gold !text-colorCopyLightLight"
											iconColor={theme.colorCopyLightLight}
										/>,
								  ]
								: []),
							<Button
								className="button-gold !text-colorCopyLightLight"
								iconLeft="download"
								iconColor={theme.colorCopyLightLight}
								download={order.invoicePDF}
							>
								Download Invoice
							</Button>,
						]}
						// isCollapsed={collapse.includes(String(order.id))}
						// onClickCollapse={() => {
						//   const isCollapsed = collapse.includes(String(order.id));
						//   setCollapse(
						//     isCollapsed
						//       ? collapse.filter((id) => id !== String(order.id))
						//       : [...collapse, String(order.id)]
						//   );
						// }}
					>
						<StickyCardBody className="flex flex-col gap-[1.875rem]">
							<h2>
								Order Date: {longDateToString(new Date(order.createdDate!))}
							</h2>
							<hr />
							<h2 className="uppercase font-semibold">Entries</h2>
							{order.lineItems.map((lineItem) => (
								<CheckoutEntryCard
									buttonDisabled={true}
									key={lineItem.entryId}
									entry={lineItem.entry}
									totalLineAmount={lineItem.totalLineAmount!}
									setEntryIdToDuplicate={setEntryIdToDuplicate}
									awardName={lineItem.entry.award!}
								/>
							))}
							<div className="flex flex-col ml-auto gap-[1rem]">
								{order.transactionType === TransactionType.Bank && (
									<h2 className="whitespace-nowrap">
										Wire Transfer Fee: <b>$25</b>
									</h2>
								)}
								<h2 className="whitespace-nowrap">
									Order Total: <b>${commafy(order.orderAmount)}</b>
								</h2>
							</div>
						</StickyCardBody>
					</CollapsibleContainer>
				))}
			</StickyContainer>
		</div>
	);
};

export default OrdersTab;
