import { useEffect } from "react";
import {
  GalleryBatchCard,
  GalleryEntryCard,
  LiveToolsSettings,
} from "../../Admin/Judging/JudgingInterfaces";
import EntryView from "../../Admin/Judging/LiveTools/EntryView";

const ScoreboardView = (props: {
  settings: LiveToolsSettings;
  batches: Array<GalleryBatchCard>;
  roundEntries: Array<GalleryEntryCard>;
}) => {
  // scroll to highlighted entry with some padding
  useEffect(() => {
    if (props.settings.highlightedRoundEntryId !== undefined) {
      const element = document.getElementById(
        String(props.settings.highlightedRoundEntryId)
      );
      if (element) {
        const yOffset = -200;
        const newPosition =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: newPosition, behavior: "smooth" });
      }
    }
  }, [props.settings.highlightedRoundEntryId]);
  return (
    <EntryView
      batches={props.batches}
      roundEntries={props.roundEntries}
      settings={props.settings}
      scoreboardView
    />
  );
};

export default ScoreboardView;
