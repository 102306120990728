import { useState, useEffect } from "react";
import axios from "axios";
import { urlEntries } from "../endpoints";

export interface DropdownOptions {
  value: any;
  label?: string | number;
  statusColor?: string;
}

export const getAwardableAwards = async (): Promise<string[]> => {
  const response = await axios.get(`${urlEntries}/getAwardableAwards`);
  return response.data;
};

const createDropdownOptions = (awards: string[]): DropdownOptions[] => {
  return awards.map((award) => ({ value: award, label: award }));
};

export const useAwardableAwards = () => {
  const [awards, setAwards] = useState<string[]>([]);
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOptions[]>([]);

  useEffect(() => {
    const fetchAwards = async () => {
      const awardableAwards = await getAwardableAwards();
      setAwards(awardableAwards);
    };

    fetchAwards();
  }, []);

  useEffect(() => {
    setDropdownOptions(createDropdownOptions(awards));
  }, [awards]);

  return dropdownOptions;
};
