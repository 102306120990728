import Modal, { ModalContainer } from "../../../../components/Modal/Modal";
import Icon from "../../../../components/Icon/Icon";
import styled, { useTheme } from "styled-components";
import { useState, Fragment, useEffect } from "react";
import { ModalCard } from "../../../../components/Modal/Modal";
import TextField from "../../../../components/FormFields/TextField";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { MediaType } from "../../../MediaLibrary/mediaLibrary.model";
import Button from "../../../../components/Button/Button";
import PlaceholderImg from "../../../../assets/placeholders/placeholder.png";
import { GalleryEntryCard, VoteOption } from "../JudgingInterfaces";
import { getThumbnailSrc } from "../../../MediaLibrary/manageMediaLibrary";
import ImagePlaceholder from "../../../../assets/placeholders/image-landscape.png";
import DropdownField from "../../../../components/FormFields/DropdownField";

const JurorVoteTable = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 30% 20% 30% 20%;
  .gray {
    color: ${({ theme }) => theme.colorCopyLight};
  }
`;
const StyledVote = styled.p<{ color: string }>`
  color: ${(p) => p.color};
`;

const EntryDetailsModal = ({
  entry,
  showDetailsModal,
  setShowDetailsModal,
  voteOptions,
  updateVote,
  roundType,
  disabled,
}: {
  entry: GalleryEntryCard;
  showDetailsModal: boolean;
  setShowDetailsModal: (value: boolean) => void;
  voteOptions: Array<VoteOption>;
  updateVote: (
    voteId: number,
    entrySetId: number,
    batchId: number,
    roundId: number,
    voteOptionId?: number,
    roundType?: string
  ) => void;
  roundType?: string;
  disabled: boolean;
}) => {
  const theme = useTheme();
  const formikProps = useFormik({
    initialValues: entry,
    enableReinitialize: true,
    onSubmit: async () => {},
    validationSchema: Yup.object({}),
  });

  return (
    <FormikProvider value={formikProps}>
      <Modal show={showDetailsModal}>
        <ModalCard
          className="!max-h-full"
          title={`Entry ${entry.entryId}`}
          headerIcons={
            <Icon
              icon="close"
              color={theme.colorPrimary}
              onClick={() => setShowDetailsModal(false)}
              width="35px"
              height="35px"
            />
          }
          iconColor={theme.colorPrimary}
        >
          <div className="flex flex-col gap-[1rem] p-[2rem]">
            <div className="flex gap-[1rem]">
              <img
                className="object-cover h-[140.625px] w-[250px]"
                src={
                  entry.coverImage
                    ? getThumbnailSrc(entry.coverImage!)
                    : ImagePlaceholder
                }
                alt="Cover Image"
              />
              <div className="flex flex-col gap-[.5rem] p-[.5rem]">
                <h3>Title: {entry.title}</h3>
                {/* <h3>Brand: {entry.brand}</h3> */}
                <Button
                  className="ml-auto"
                  icon="eye"
                  iconSize="20px"
                  onClick={() =>
                    window.open(
                      `/admin/edit-entry?entryId=${entry.entryId}`,
                      "_blank"
                    )
                  }
                >
                  See Full Entry
                </Button>
              </div>
            </div>
            <TextField
              name="judgingNote"
              placeholder="Judging Note"
              component="textarea"
              height="100px"
              readOnly
              value={entry.judgingNote}
            />
            {/* <TextField
              name="synopsis"
              placeholder="Synopsis"
              component="textarea"
              // height="200px"
              readOnly
              value={entry.synopsis}
            /> */}
            <b>Juror Votes</b>
            <JurorVoteTable>
              {formikProps.values.votes &&
                formikProps.values.votes.map((vote, i) => (
                  <Fragment key={vote.id}>
                    <p className="gray self-center">
                      {vote.jurorName ? vote.jurorName : vote.jurorId}
                    </p>
                    {/* <StyledVote
                    className="text-left"
                    color={
                      vote.voteOption
                        ? theme.colorCopyDarkDark
                        : theme.colorDanger
                    }
                  >
                    {vote.voteOption ? vote.voteOption.name : "Has Not Voted"}
                  </StyledVote> */}
                    <DropdownField
                      className="w-[110px]"
                      name={`votes[${i}].voteOptionId`}
                      value={vote.voteOptionId}
                      disabled={disabled}
                      // need to generate options based on voteOptions
                      options={voteOptions.map((o) => {
                        return { label: o.name, value: o.id };
                      })}
                      placeholder="Vote"
                      onChange={(event) => {
                        updateVote(
                          vote.id,
                          vote.entrySetId,
                          vote.batchId,
                          vote.roundId,
                          event.currentTarget.value
                            ? Number(event.currentTarget.value)
                            : undefined,
                          roundType
                        );
                      }}
                    />
                  </Fragment>
                ))}
            </JurorVoteTable>
          </div>
        </ModalCard>
      </Modal>
      {/* <Button
        className="button-light"
        icon="eye"
        iconSize="20px"
        iconColor={theme.colorPrimary}
        onClick={() => setShowDetailsModal(true)}
      >
        View
      </Button> */}
    </FormikProvider>
  );
};

export default EntryDetailsModal;
