import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import * as Yup from "yup";
import Modal, { ModalCard } from "../../../components/Modal/Modal";
import { CreditType } from "../Program/ProgramInterfaces";
import Icon from "../../../components/Icon/Icon";
import {
  NavTab,
  NavTabList,
  NavTabPanel,
  NavTabs,
} from "../../../components/NavTabs/NavTabs";
import AddCompanyCreditForm from "./AddCompanyCreditForm";
import AddIndividualCreditForm from "./AddIndividualCreditForm";
import { CompanyCredit, IndividualCredit } from "./CreditInterfaces";
import { useFormik } from "formik";

export const TabModalCard = styled(ModalCard)`
  overflow-y: auto;
  div[role="tabpanel"] {
    overflow: auto;
  }
`;

const AddCreditModal = (props: AddCreditModalProps) => {
  const theme = useTheme();
  const [errors, setErrors] = useState<string[]>([]);

  const [tabIndex, setTabIndex] = useState(0);

  async function AddNewCredit(newCreditData: any) {
    try {
      setErrors([]);
      /*TODO: Hookup to backend. */
    } catch (error: any) {
      // console.log(error);

      setErrors(error.response.data);
    }
  }

  let fieldRequired = "This field is required";

  const formikProps = useFormik({
    initialValues: {},
    onSubmit: async () => {
      // console.log(formikProps.values);
    },
    validationSchema: Yup.object({
      awardName: Yup.string().required(fieldRequired),
      awardLogo: Yup.string().required(fieldRequired),
    }),
  });

  useEffect(() => {
    // console.log("props.defaultCreditTab", props.defaultCreditTab);
    setTabIndex(props.defaultCreditTab === CreditType.Company ? 0 : 1);
  }, [props.defaultCreditTab]);

  return (
    <Modal show={props.show}>
      <TabModalCard
        title="Add Credit"
        headerIcons={
          <Icon
            icon="close"
            color={theme.colorPrimary}
            onClick={props.onClickHide}
            width="35px"
            height="35px"
          />
        }
        iconColor={theme.colorPrimary}
      >
        <NavTabs
          className="credit-db relative overflow-auto"
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
        >
          <NavTabList>
            <NavTab>Company Credits</NavTab>
            <NavTab>Individual Credits</NavTab>
          </NavTabList>
          <NavTabPanel>
            <AddCompanyCreditForm
              companyId={props.companyId}
              onClickHide={props.onClickHide}
              setCompanyCreditsArray={props.setCompanyCreditsArray}
            />
          </NavTabPanel>
          <NavTabPanel>
            <AddIndividualCreditForm
              companyId={props.companyId}
              onClickHide={props.onClickHide}
              setIndividualCreditsArray={props.setIndividualCreditsArray}
            />
          </NavTabPanel>
        </NavTabs>
      </TabModalCard>
    </Modal>
  );
};

export default AddCreditModal;

export interface AddCreditModalProps {
  show: boolean;
  onClickHide(): void;
  setCompanyCreditsArray: React.Dispatch<React.SetStateAction<CompanyCredit[]>>;
  setIndividualCreditsArray: React.Dispatch<
    React.SetStateAction<IndividualCredit[]>
  >;
  defaultCreditTab: CreditType;
  companyId: number;
}
