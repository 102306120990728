import React, { useCallback, useContext, useEffect, useState } from "react";
import Table, {
	SortDirection,
	TableCheckBox,
	TablePlaceholder,
} from "../../../components/Table/Table";
import Loading from "../../../components/Loading/Loading";
import axios from "axios";
import { urlEntries } from "../../../endpoints";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import { CsvExport, UserSearchRequest } from "../Search/UserSearchTab";
import {
	getStoredValue,
	setStoredValue,
} from "../../../components/LocalStorageStorage/LocalStorageStore";
import { DropdownOptions } from "../../../hooks/useAwardableAwards";
import { useProgramAwards } from "../../../hooks/useProgramAwards";
import { useSeasons } from "../../../hooks/useSeasons";
import { getEntryReviewers } from "../User/managerUser";
import {
	ReviewStatus,
	ReviewStatusOptions,
} from "../../Checkout/OrderInterfaces";
import Button from "../../../components/Button/Button";
import TextField from "../../../components/FormFields/TextField";
import MultiDropdown from "../../../components/MultiDropdown/MultiDropdown";
import dateTostring from "../../../utils/dateToString";
import {
	AdminPageControls,
	SearchFieldsContainer,
} from "../Search/AdminSearch";
import { StyledLink } from "../Search/EntrySearchTab";
import CheckboxField, {
	CheckBox,
	RegularCheckBox,
} from "../../../components/FormFields/CheckboxField";
import useTaskHandler, {
	BottomBar,
	PENDING,
	SelectableContainer,
	TaskHandlerReturnType,
} from "../../../hooks/useTaskHandler";
import DropdownField, {
	TransparentDropdown,
} from "../../../components/FormFields/DropdownField";
import { useTheme } from "styled-components";
import {
	UpdatePropertyRequest,
	updateEntryProperty,
} from "../../Entries/manageEntry";

import SpinnerSrc from "../../../assets/spinner-gold.svg";
import config from "../../../config";
import { useAlert } from "../../../components/Alert/Alerts";
import assetsConfig from "../../../assetsConfig";

import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const getScrubbedRequest = (
	request: AssignmentSearchRequest
): AssignmentSearchRequest => {
	let result: AssignmentSearchRequest = {
		seasons:
			request.seasons && request.seasons.length ? request.seasons : undefined,
		awards:
			request.awards && request.awards.length ? request.awards : undefined,
		hierarchyKeyword: request.hierarchyKeyword
			? request.hierarchyKeyword
			: undefined,
		authorEmail: request.authorEmail ? request.authorEmail : undefined,
		authorCompany: request.authorCompany ? request.authorCompany : undefined,
		orderId: request.orderId ? Number(request.orderId) : undefined,
		reviewers:
			request.reviewers && request.reviewers.length
				? request.reviewers
				: undefined,
		reviewStatuses:
			request.reviewStatuses && request.reviewStatuses.length
				? request.reviewStatuses
				: undefined,
		reviewNote: request.reviewNote ? request.reviewNote : undefined,
		entryType: request.entryType ? request.entryType : undefined,
	};

	return result;
};

const initialSearch: AssignmentSearchRequest = {
	seasons: [],
	awards: [],
	hierarchyKeyword: undefined,
	authorEmail: undefined,
	authorCompany: undefined,
	orderId: undefined,
	reviewers: [],
	reviewStatuses: [],
	reviewNote: undefined,
	entryType: undefined,
};

const MultiSelectWrapper = (props: {
	children: (formikValues: TableCheckBox) => React.ReactNode;
	onClickDeselect: boolean;
	setSelectedEntries(entries: TableCheckBox): void;
}) => {
	const { values, setFieldValue } = useFormikContext<TableCheckBox>();

	// selects or deselects all checkboxes
	const handleAllCheckBox = (checked: boolean) => {
		if (Object.values(values).length > 0) {
			Object.keys(values).forEach((key) => {
				setFieldValue(key, checked);
			});
		}
	};

	useEffect(() => {
		handleAllCheckBox(false);
	}, [props.onClickDeselect]);

	useEffect(() => {
		props.setSelectedEntries(values);
	}, [values]);

	return <>{props.children(values)}</>;
};

const Assignment = () => {
	const { settings } = useSelector((state: RootState) => state.settings);
	const theme = useTheme();
	const { addNewAlert } = useAlert();
	const [isLoading, setIsLoading] = useState(false);
	const [isActive, setActive] = useState(false);
	const [assignments, setAssignments] = useState<AssignmentSearchResult[]>([]);
	const [page, setPage] = useState(1);
	const [totalAmountOfPages, settotalAmountOfPages] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState(50);
	const [totalRecords, setTotalRecords] = useState(0);
	const [activeSort, setActiveSort] = useState<string | null>(null);
	const [activeSortDirection, setActiveSortDirection] = useState<SortDirection>(
		SortDirection.Asc
	);
	const [csvAssignments, setCsvAssignments] = useState<any[]>([]);
	const [csvLoading, setCsvLoading] = useState(false);
	const initialSearch: AssignmentSearchRequest = {
		seasons: [],
		awards: [],
		hierarchyKeyword: undefined,
		authorEmail: undefined,
		authorCompany: undefined,
		orderId: undefined,
		reviewers: [],
		reviewStatuses: [],
		reviewNote: undefined,
	};
	const [reviewerOptions, setReviewerOptions] = useState<DropdownOptions[]>([]);
	const awardOptions = useProgramAwards();
	const seasonOptions = useSeasons();

	const [isAssigning, setIsAssigning] = useState(false);

	const {
		reset,
		loadItems,
		onSubmit,
		handleSelectItem,
		...state
	}: TaskHandlerReturnType<SelectedEntry> = useTaskHandler<SelectedEntry>();

	useEffect(() => {
		getEntryReviewers()
			.then((resp) => {
				if (resp.status === 200) {
					setReviewerOptions([
						...resp.data,
						{ label: "Unassigned", value: null },
					]);
				}
			})
			.catch((error) => {
				console.log("getEntryReviewers error", error);
			});
	}, []);

	const [searchVal, setSearchVal] = useState<AssignmentSearchRequest>(
		getStoredValue("assignmentSearch") || initialSearch
	);

	async function PostSearch(
		request: AssignmentSearchRequest,
		activeSort?: number,
		sortDirection?: SortDirection,
		loadCsv?: boolean
	) {
		setIsLoading(true);
		//console.log("pre-request ", request);
		try {
			const newRequest = getScrubbedRequest(request);

			const sortField =
				activeSort !== undefined && activeSort !== null
					? activeSort
					: undefined;

			console.log("sortfield", sortField);
			//console.log("request ", newRequest);
			setActive(false);
			const response = await axios.post(
				`${urlEntries}/search/assignments`,
				newRequest,
				{
					params: {
						page,
						recordsPerPage,
						sortField,
						sortDirection,
					},
				}
			);
			if (response.status === 200) {
				setAssignments(response.data);
				setActive(true);
				setIsLoading(false);
				const total = parseInt(response.headers["totalamountofrecords"]);
				setTotalRecords(total);
				settotalAmountOfPages(Math.ceil(total / recordsPerPage));
			}

			if (loadCsv) {
				setCsvLoading(true);

				const getAssignmentsCSV = await axios.post(
					`${urlEntries}/search/assignments/export`,
					newRequest
				);

				if (getAssignmentsCSV.status === 200) {
					setCsvLoading(false); // enable export button after csv entries are retrieved
					setCsvAssignments(getAssignmentsCSV.data);
				}
			}
		} catch (error: any) {
			console.log(error);
			setIsLoading(false);
			setCsvLoading(false);
		}
	}

	const formikProps = useFormik({
		initialValues: searchVal,
		onSubmit: (values) => {
			PostSearch(values, undefined, undefined, true);
		},
	});

	useEffect(() => {
		if (assignments.length > 1) {
			PostSearch(
				formikProps.values,
				activeSort !== null
					? AssignmentsSearchSortOptions[
							activeSort as keyof typeof AssignmentsSearchSortOptions
					  ]
					: undefined,
				activeSort !== null ? activeSortDirection : undefined
			);
		}
	}, [page, recordsPerPage]);

	useEffect(() => {
		if (activeSort !== null) {
			PostSearch(
				formikProps.values,
				AssignmentsSearchSortOptions[
					activeSort as keyof typeof AssignmentsSearchSortOptions
				],
				activeSortDirection
			);
		}
	}, [activeSort, activeSortDirection]);

	const [selectedEntries, setSelectedEntries] = useState<TableCheckBox>({});
	const [selectedUserId, setSelectedUserId] = useState<string>("Unassigned");
	const selectedEntriesArr = Object.entries(selectedEntries).flatMap(
		([key, isChecked]) => {
			if (isChecked) {
				return key;
			} else {
				return [];
			}
		}
	);

	const assignReviewer = useCallback(
		(entryId: number, userId: string): Promise<number> => {
			return new Promise((resolve, reject) => {
				updateEntryProperty({
					id: entryId,
					propertyName: "reviewerId",
					propertyValue: userId === "Unassigned" ? null : userId,
				})
					.then((res) => {
						resolve(entryId);
					})
					.catch((e) =>
						reject(`Error Assigning Reviewer to Entry: ${e.message}`)
					);
			});
		},
		[]
	);

	const handleAssignReviewer = () => {
		setIsAssigning(true);
		Promise.all(
			selectedEntriesArr.map((entryId) =>
				assignReviewer(Number(entryId), selectedUserId)
			)
		)
			.then((res) => {
				addNewAlert({
					type: "success",
					message: "Successfully updated entry reviewers",
				});
				setIsAssigning(false);
				setSelectedUserId("Unassigned");
				formikProps.submitForm();
			})
			.catch((err) => {
				addNewAlert({ type: "error", message: err });
			});
	};

	const [unCheckAll, setUncheckAll] = useState(false);

	return (
		<>
			<FormikProvider value={formikProps}>
				<SearchFieldsContainer>
					{settings.isDemo ? (
						<></>
					) : (
						<>
							<MultiDropdown
								placeholder="Seasons"
								options={seasonOptions}
								selectedValues={
									Array.isArray(formikProps.values.seasons)
										? formikProps.values.seasons.flatMap((value: number) => {
												const correspondingOption = seasonOptions.find(
													(option: DropdownOptions) => option.value === value
												);
												return correspondingOption ? [correspondingOption] : [];
										  })
										: []
								}
								onChange={(selectedList) => {
									formikProps.setFieldValue(
										"seasons",
										selectedList.map((x) => x.value)
									);
								}}
							/>

							<MultiDropdown
								placeholder="Awards"
								options={awardOptions}
								selectedValues={
									Array.isArray(formikProps.values.awards)
										? formikProps.values.awards.flatMap((value: number) => {
												const correspondingOption = awardOptions.find(
													(option: DropdownOptions) => option.value === value
												);
												return correspondingOption ? [correspondingOption] : [];
										  })
										: []
								}
								onChange={(selectedList) => {
									formikProps.setFieldValue(
										"awards",
										selectedList.map((x) => x.value)
									);
								}}
							/>

							<TextField
								name="entryType"
								placeholder={assetsConfig.labels.entry.singular + " Type"}
								value={formikProps.values.entryType}
							/>

							<TextField
								name="hierarchyKeyword"
								placeholder="Hierarchy Keyword"
								value={formikProps.values.hierarchyKeyword}
							/>
						</>
					)}

					<TextField
						name="authorEmail"
						placeholder="Author Email"
						value={formikProps.values.authorEmail}
					/>

					<TextField
						name="authorCompany"
						placeholder="Author Company"
						value={formikProps.values.authorCompany}
					/>

					{settings.isDemo ? (
						<></>
					) : (
						<TextField
							name="orderId"
							placeholder="Order ID"
							value={formikProps.values.orderId}
						/>
					)}

					<MultiDropdown
						placeholder="Reviewers"
						options={reviewerOptions}
						selectedValues={
							Array.isArray(formikProps.values.reviewers)
								? formikProps.values.reviewers.flatMap((value: string) => {
										const correspondingOption = reviewerOptions.find(
											(option: DropdownOptions) => option.value === value
										);
										return correspondingOption ? [correspondingOption] : [];
								  })
								: []
						}
						onChange={(selectedList) => {
							formikProps.setFieldValue(
								"reviewers",
								selectedList.map((x: DropdownOptions) => x.value)
							);
						}}
					/>

					<MultiDropdown
						placeholder="Review Statuses"
						options={ReviewStatusOptions.filter((x) => x.label !== "Draft")}
						selectedValues={
							Array.isArray(formikProps.values.reviewStatuses)
								? formikProps.values.reviewStatuses.flatMap(
										(value: ReviewStatus) => {
											const correspondingOption = ReviewStatusOptions.find(
												(option: DropdownOptions) => option.value === value
											);
											return correspondingOption ? [correspondingOption] : [];
										}
								  )
								: []
						}
						onChange={(selectedList) => {
							formikProps.setFieldValue(
								"reviewStatuses",
								selectedList.map((x) => x.value)
							);
						}}
					/>

					<TextField
						name="reviewNote"
						placeholder="Review Note"
						value={formikProps.values.reviewNote}
					/>
				</SearchFieldsContainer>
				<div className="my-[1rem] flex gap-[1rem] justify-end flex-wrap">
					<Button
						className="button-light w-[150px]"
						type="button"
						onClick={() => {
							formikProps.setValues(initialSearch);
							setSearchVal(initialSearch);
							setAssignments([]);
							setActive(false);
							setActiveSort(null);
							setActiveSortDirection(SortDirection.Asc);
							setStoredValue("assignmentSearch", initialSearch);
						}}
					>
						Reset Search
					</Button>
					<CsvExport
						className="no-underline"
						data={csvAssignments}
						// headers={csvHeaders}
						filename={`Assignments Search Export ${dateTostring(
							new Date()
						)}.csv`}
						disabled={csvAssignments.length < 1}
					>
						<Button
							className="button-light w-[150px]"
							disabled={csvAssignments.length < 1}
						>
							<div className="flex items-center gap-[.5rem]">
								Export
								{csvLoading && (
									<img
										className="w-[16px]"
										src={config.assets.loading.primary}
									/>
								)}
							</div>
						</Button>
					</CsvExport>
					<Button
						className="w-[150px]"
						type="submit"
						onClick={() => {
							setIsLoading(true);
							formikProps.submitForm();
							setActiveSortDirection(SortDirection.Asc);
						}}
					>
						Search
					</Button>
				</div>
			</FormikProvider>

			{isActive && (
				<AdminPageControls
					totalRecords={totalRecords}
					totalAmountOfPages={totalAmountOfPages}
					currentPage={page}
					setPage={(newPage) => setPage(newPage)}
					setIsLoading={(isLoading) => setIsLoading(isLoading)}
				/>
			)}

			{!isLoading &&
				(assignments && assignments.length > 0 ? (
					<Table
						isActive={isActive}
						dualScroll
						{...(settings.isDemo
							? {
									columnLabels: [
										"Id",
										"Entry Title",
										"Reviewer",
										"Review Status",
										"Author Company",
										"Author Email",
										"Brand",
									],
							  }
							: {
									columnLabels: [
										"Id",
										"Reviewer",
										"Review Status",
										"Author Company",
										"Author Email",
										"Brand",
										"Root Program",
										"Entry Level Program",
										"Entry Type",
										"Order Number",
									],
							  })}
						{...(settings.isDemo && {
							labelAlias: [
								"Id",
								assetsConfig.labels.entry.singular + " Title",
								"Reviewer",
								"Review Status",
								"Author Company",
								"Author Email",
								"Brand",
							],
						})}
						onClickSort={(sortLabel, sortDirection) => {
							setActiveSort(sortLabel);
							setActiveSortDirection(sortDirection);
						}}
						sortTriangleSize="14px"
						{...(activeSort !== null && {
							activeSort: {
								label: activeSort,
								direction: activeSortDirection,
							},
						})}
						allowMultiselect
						unCheckMultiselect={unCheckAll}
					>
						<MultiSelectWrapper
							onClickDeselect={unCheckAll}
							setSelectedEntries={setSelectedEntries}
						>
							{(formikValues) => {
								return (
									<>
										{assignments.map((assignment, index) => (
											<>
												<div className="cell">
													<CheckboxField
														className="mx-auto"
														name={`${assignment.id}`}
														label="assignment"
														checked={
															formikValues[assignment.id] !== undefined
																? formikValues[assignment.id]
																: false
														}
													/>
												</div>

												<div className="cell">
													<StyledLink
														to={`/admin/edit-entry?entryId=${assignment.id}`}
														target="_blank"
													>
														{assignment.id}
													</StyledLink>
												</div>
												{settings.isDemo && (
													<div className="cell">
														<span>{assignment.entryTitle || ""}</span>
													</div>
												)}
												<div className="cell">
													<span>{assignment.reviewer}</span>
												</div>
												<div className="cell">
													<span>{assignment.reviewStatus}</span>
												</div>
												<div className="cell">
													<span>{assignment.authorCompany}</span>
												</div>
												<div className="cell">
													<span>{assignment.authorEmail}</span>
												</div>
												<div className="cell">
													<span>{assignment.brand}</span>
												</div>
												{!settings.isDemo && (
													<>
														<div className="cell">
															<span>{assignment.rootProgram}</span>
														</div>
														<div className="cell">
															<span>{assignment.entryLevelProgram}</span>
														</div>
														<div className="cell">
															<span>{assignment.entryType}</span>
														</div>
														<div className="cell">
															<span>{assignment.orderId}</span>
														</div>
													</>
												)}
											</>
										))}
									</>
								);
							}}
						</MultiSelectWrapper>
					</Table>
				) : (
					<TablePlaceholder active={isActive}>
						Please add a search to see result table.
					</TablePlaceholder>
				))}

			{isLoading && (
				<TablePlaceholder active={false}>
					<Loading fullScreen={false} showLogo={false} />
				</TablePlaceholder>
			)}

			{/* bottom pagination controls */}
			{isActive && !isLoading && (
				<AdminPageControls
					totalRecords={totalRecords}
					totalAmountOfPages={totalAmountOfPages}
					currentPage={page}
					setPage={(newPage) => setPage(newPage)}
					setIsLoading={(isLoading) => setIsLoading(isLoading)}
				/>
			)}

			<BottomBar
				className="right-0"
				show={
					selectedEntriesArr && selectedEntriesArr.length && !isLoading
						? true
						: false
				}
			>
				{isAssigning ? (
					<>
						<p>Processing Request...</p>
						<img className="h-[80%]" src={SpinnerSrc} alt="spinner" />
					</>
				) : (
					<>
						<p className={`bar-text whitespace-nowrap`}>{`${
							selectedEntriesArr.length
						} Entr${selectedEntriesArr.length > 1 ? "ies" : "y"} Selected`}</p>

						<p className="ml-[2rem] mr-[1rem]">
							Assign Selected Entries to Reviewer
						</p>
						<TransparentDropdown
							className="border border-white !text-colorCopyLightLight mr-[1rem] pl-[1rem]"
							id="assign-reviewer-dropdown"
							options={reviewerOptions}
							onChange={(selectedValue) => {
								setSelectedUserId(String(selectedValue));
							}}
							value={selectedUserId}
						/>
						<Button
							className="button-transparent flex-col w-[175px] max-w-[175px]"
							onClick={() => handleAssignReviewer()}
							icon="check"
							iconSize="20px"
							iconColor={theme.colorPrimary}
						>
							Assign
						</Button>

						<Button
							className="button-transparent flex-col w-[175px] max-w-[175px]"
							onClick={() => setUncheckAll(!unCheckAll)}
							icon="deselect"
							iconSize="20px"
							iconColor={theme.colorPrimary}
						>
							Deselect All
						</Button>
					</>
				)}
			</BottomBar>
		</>
	);
};

export default Assignment;

interface AssignmentSearchResult {
	reviewStatus: string; // Review Status
	reviewer: string; // Reviewer's name (or “Unassigned”)
	id: number; // ID for the entry
	entryTitle?: string;
	authorCompany: string; // Author Company
	authorEmail: string; // Author Email
	brand?: string; // Brand
	rootProgram: string; // Root Program
	entryLevelProgram: string; // Entry Level Program
	entryType?: string;
	orderId?: number; // Order Number
}

export interface AssignmentSearchRequest {
	seasons?: number[];
	awards?: number[];
	hierarchyKeyword?: string;
	authorEmail?: string;
	authorCompany?: string;
	orderId?: number;
	reviewers?: string[];
	reviewStatuses?: ReviewStatus[];
	reviewNote?: string;
	entryType?: string;
}

enum AssignmentsSearchSortOptions {
	"Review Status",
	"Reviewer",
	"Id",
	"Author Company",
	"Author Email",
	"Brand",
	"Root Program",
	"Entry Level Program",
	"Entry Type",
	"Order Number",
	"Entry Title",
}

interface SelectedEntry {
	id: number;
}
