import { FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import DropdownField, {
  DropdownOptions,
} from "../../../../components/FormFields/DropdownField";
import {
  getJuryDropdownByProgramId,
  getJuryVoteReports,
  getRootProgramOptionsWithJury,
  getRoundDropdownByJuryId,
} from "../../Program/manageProgram";
import styled, { css } from "styled-components";
import * as Yup from "yup";
import Button from "../../../../components/Button/Button";
import JudgingResultsTable from "./JudgingResultsTable";
import config from "../../../../config";

const JuryVotes = () => {
  const [rootProgramIDs, setRootProgramIDs] = useState<DropdownOptions[]>([]);
  const [juryDropdownOptions, setJuryDropdownOptions] = useState<
    DropdownOptions[]
  >([]);
  const [roundDropdownOptions, setRoundDropdownOptions] = useState<
    DropdownOptions[]
  >([]);
  const [selectedProgramId, setSelectedProgramId] = useState<
    number | undefined
  >();
  const [selectedProgramName, setSelectedProgramName] = useState<string>();
  const [selectedJuryId, setSelectedJuryId] = useState<number | undefined>();
  const [selectedRoundId, setSelectedRoundId] = useState<number | undefined>();
  const [reportLoading, setReportLoading] = useState(false);
  const [juryVoteReport, setJuryVoteReport] = useState<JuryVoteReport[]>([]);
  const [selectedRoundName, setSelectedRoundName] = useState<
    string | undefined
  >();

  const LoadingSpinner = styled.img`
    width: 100px;
  `;

  const spinnerSrc = config.assets.loading.primary;

  // Fetch root program options when the component mounts
  useEffect(() => {
    getRootProgramOptionsWithJury().then((res) => {
      setRootProgramIDs(res.data);
    });
  }, []);

  useEffect(() => {
    if (selectedProgramId) {
      getJuryDropdownByProgramId(selectedProgramId).then((data) => {
        if (data.length === 0) {
          setJuryDropdownOptions([
            { value: "", label: "No Juries Exist for Program" },
          ]);
        } else {
          setJuryDropdownOptions(data);
        }
      });
    }
  }, [selectedProgramId]);

  useEffect(() => {
    if (selectedJuryId) {
      getRoundDropdownByJuryId(selectedJuryId).then((data) => {
        if (data.length === 0) {
          setRoundDropdownOptions([
            { value: "", label: "No Rounds Exist for Jury" },
          ]);
        } else {
          setRoundDropdownOptions(data);
        }
      });
    }
  }, [selectedJuryId]);

  useEffect(() => {
    setSelectedJuryId(undefined);
    setSelectedRoundId(undefined);
    setJuryDropdownOptions([]);
    setRoundDropdownOptions([]);
    setJuryVoteReport([]);
  }, [selectedProgramId]);

  useEffect(() => {
    setSelectedRoundId(undefined);
    setRoundDropdownOptions([]);
    setJuryVoteReport([]);
  }, [selectedJuryId]);

  const generateInformation = async () => {
    if (selectedProgramId && selectedJuryId && selectedRoundId) {
      setReportLoading(true);
      try {
        const juryVoteReport: JuryVoteReport[] = await getJuryVoteReports(
          Number(selectedRoundId),
          Number(selectedJuryId)
        );
        setJuryVoteReport(juryVoteReport);
      } catch (error) {
        console.error("Failed to get entry program review statuses: ", error);
      }
      setReportLoading(false);
    }
  };

  const formikProps = useFormik({
    initialValues: { rootProgramIDs },
    enableReinitialize: true,
    onSubmit: async () => {},
    validationSchema: Yup.object({}),
    validateOnBlur: false,
    validateOnChange: false,
  });

  const juryVotesName = `${selectedProgramName}_Jury Id ${selectedJuryId}_Round: ${selectedRoundName}`;

  return (
    <div>
      <FormikProvider value={formikProps}>
        <div className="flex items-center space-x-4 pb-5">
          {" "}
          <DropdownField
            name="rootProgram"
            options={rootProgramIDs}
            value={selectedProgramId || undefined}
            placeholder="Select a Program"
            onChange={(value) => {
              const selectedValue = Number(value.target.value);
              const selectedOption = rootProgramIDs.find(
                (option) => option.value === selectedValue
              );
              setSelectedProgramId(selectedValue);
              setSelectedProgramName(String(selectedOption?.label || ""));
            }}
          />
          {juryDropdownOptions.length > 0 && (
            <DropdownField
              name="jury"
              options={juryDropdownOptions}
              placeholder="Select a Jury"
              disabled={!selectedProgramId || juryDropdownOptions.length === 0}
              onChange={(value) => {
                setSelectedJuryId(Number(value.target.value));
              }}
            />
          )}
          {roundDropdownOptions.length > 0 && (
            <>
              <DropdownField
                name="round"
                options={roundDropdownOptions}
                placeholder="Round"
                disabled={!selectedJuryId || roundDropdownOptions.length === 0}
                onChange={(value) => {
                  const selectedValue = Number(value.target.value);
                  setSelectedRoundId(selectedValue);
                  const selectedOption = roundDropdownOptions.find(
                    (option) => option.value === selectedValue
                  );
                  setSelectedRoundName(String(selectedOption?.label || ""));
                }}
              />
              <Button
                onClick={generateInformation}
                disabled={
                  !selectedProgramId ||
                  !selectedJuryId ||
                  !selectedRoundId ||
                  reportLoading
                }
              >
                Generate
              </Button>
            </>
          )}
        </div>
        {reportLoading ? (
          <div className="pt-10 flex flex-col items-center justify-center">
            <div>Running Reports...</div>
            <LoadingSpinner src={spinnerSrc} />
          </div>
        ) : (
          <>
            {juryVoteReport && juryVoteReport.length > 0 ? (
              <div>
                <JudgingResultsTable
                  juryVotesName={juryVotesName}
                  data={juryVoteReport}
                  disableExport={false}
                  showTopScroll={true}
                />
              </div>
            ) : (
              <div className="pt-10 flex flex-col items-center justify-center"></div>
            )}
          </>
        )}
      </FormikProvider>
    </div>
  );
};

export interface JuryVoteReport {
  entryId: number;
  isCampaign: boolean;
  entrySet: string;
  entryLevelProgram: string;
  entryType: string;
  executionProgram: string;
  entrantCompanies: string;
  brand: string;
  title: string;
  numericScore: number;
  roundScore: number;
  previousRoundScore: number;
  alsoIn: string;
  abstains: string;
  chairVote: string;
  batchLabel: string;
}

export default JuryVotes;
