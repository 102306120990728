import axios from "axios";
import { useState } from "react";
import {
	urlAwards,
	urlJuryAdmin,
	urlPrograms,
	urlReports,
	urlSeasons,
} from "../../../endpoints";
import { SeasonModel } from "../Seasons/Seasons";
import {
	CreditFieldTemplate,
	DateFieldTemplate,
	FieldTemplate,
	LinkFieldTemplate,
	ListFieldTemplate,
	MediaFieldTemplate,
	PhysicalComponentFieldTemplate,
	ProgramCreditField,
	ProgramDateField,
	ProgramLinkField,
	ProgramListField,
	ProgramMediaField,
	ProgramModel,
	ProgramPhysicalComponentField,
	ProgramTextField,
	TextFieldTemplate,
} from "./ProgramInterfaces";
import { AwardModel } from "../Awards/Awards";
import { DropdownOptions } from "../../../components/FormFields/DropdownField";
import { ProgramSetupCard } from "./ProgramRootAdminNav";
import { ProgramConfigureProps } from "./ProgramConfig";
import { CopyProgram } from "./CopyProgramModal";
import { JuryVoteReport } from "../Judging/Reports/JuryVotes";

export async function PostProgram(programData: ProgramModel) {
	try {
		// start formdata
		const formData = new FormData();

		formData.append("ImageData", programData.imageData || "");
		programData.imageData = undefined;
		formData.append("Program", JSON.stringify(programData) || "");

		const headers = {
			"Content-Type": "multipart/form-data",
		};

		return axios
			.post(`${urlPrograms}`, formData, {
				headers: headers,
			})
			.then((res) => res)
			.catch((err) => err);
	} catch (error: any) {
		// console.log(error);
	}
}

export async function getRootPrograms() {
	try {
		const response = await axios.get<ProgramModel[]>(`${urlPrograms}/`);
		return response;
	} catch (error: any) {
		// console.log(error);
		return error;
	}
}

export async function getRootProgramSetupCards() {
	try {
		const response = await axios.get<ProgramSetupCard[]>(
			`${urlPrograms}/ProgramSetupCards`
		);
		return response;
	} catch (error: any) {
		// console.log(error);
		return error;
	}
}

export async function getRootProgramOptions() {
	try {
		const response = await axios.get<DropdownOptions[]>(
			`${urlPrograms}/getRootProgramOptions`
		);
		return response;
	} catch (error: any) {
		// console.log(error);
		return error;
	}
}

export async function getRootProgramOptionsWithJury() {
	try {
		const response = await axios.get<DropdownOptions[]>(
			`${urlJuryAdmin}/getRootProgramOptionsWithJury`
		);
		return response;
	} catch (error: any) {
		// console.log(error);
		return error;
	}
}

export const getJuryDropdownByProgramId = async (
	id: number
): Promise<DropdownOptions[]> => {
	try {
		const response = await axios.get<DropdownOptions[]>(
			`${urlJuryAdmin}/getJuryDropdownByProgramId/${id}`
		);
		return response.data;
	} catch (error) {
		console.error("An error occurred while fetching data: ", error);
		return [];
	}
};

export const getRoundDropdownByJuryId = async (
	juryId: number
): Promise<DropdownOptions[]> => {
	try {
		const response = await axios.get<DropdownOptions[]>(
			`${urlJuryAdmin}/getRoundDropdownByJuryId/${juryId}`
		);
		return response.data;
	} catch (error) {
		console.error("An error occurred while fetching data: ", error);
		return [];
	}
};

export const getJuryVoteReports = async (
	roundId: number,
	juryId: number
): Promise<JuryVoteReport[]> => {
	try {
		const response = await axios.get<JuryVoteReport[]>(
			`${urlReports}/jury-vote-report/${roundId}/${juryId}`
		);
		return response.data;
	} catch (error) {
		console.error(
			"An error occurred while fetching jury vote reports: ",
			error
		);
		return [];
	}
};

export async function createNewProgram(value: ProgramModel) {
	return await axios.post(`${urlPrograms}`, value);
}

export async function deleteProgram(programId: number) {
	return await axios.delete(`${urlPrograms}/${programId}`);
}

export async function updateProgram(programData: ProgramConfigureProps) {
	// start formdata
	const formData = new FormData();
	formData.append("ImageData", programData.imageData || "");
	programData.imageData = undefined;
	formData.append("Program", JSON.stringify(programData) || "");

	// console.log(programData);
	// for (var pair of formData.entries()) {
	//   console.log(pair[0] + ", " + pair[1]);
	// }
	const headers = {
		"Content-Type": "multipart/form-data",
	};

	return await axios.put(`${urlPrograms}/${programData.id}`, formData, {
		headers: headers,
	});
}

export async function markEntriesAsAbandoned(programId: number) {
	return await axios.post(`${urlPrograms}/markEntriesAsAbandoned/${programId}`);
}

export async function setJudgingResultsReleased(programId: number) {
	return await axios.post(
		`${urlPrograms}/setJudgingResultsReleased/${programId}`
	);
}

export async function getProgramById(id: string) {
	try {
		const response = await axios.get<ProgramModel>(`${urlPrograms}/${id}`);
		return response;
	} catch (error: any) {
		// console.log(error);
		return error;
	}
}

export async function getProgramConfigById(id: string) {
	try {
		const response = await axios.get<ProgramConfigureProps>(
			`${urlPrograms}/getProgramConfig/${id}`
		);
		return response;
	} catch (error: any) {
		// console.log(error);
		return error;
	}
}

export async function getProgramSetupCardById(id: string) {
	try {
		const response = await axios.get<ProgramSetupCard>(
			`${urlPrograms}/getProgramCardById/${id}`
		);
		return response;
	} catch (error: any) {
		// console.log(error);
		return error;
	}
}

export async function getRawProgramById(id: string) {
	try {
		const response = await axios.get<ProgramModel>(
			`${urlPrograms}/getrawprogram/${id}`
		);
		return response;
	} catch (error: any) {
		// console.log(error);
		return error;
	}
}

export async function getActiveSeasons() {
	try {
		const response = await axios.get<SeasonModel[]>(
			`${urlSeasons}/getactiveseasons`
		);
		return response;
	} catch (error: any) {
		// console.log(error);
		return error;
	}
}

export async function getSeasons() {
	try {
		const response = await axios.get<SeasonModel[]>(`${urlSeasons}`);
		return response;
	} catch (error: any) {
		// console.log(error);
		return error;
	}
}

export async function getActiveAwards() {
	try {
		const response = await axios.get<AwardModel[]>(
			`${urlAwards}/getactiveawards`
		);
		return response;
	} catch (error: any) {
		// console.log(error);
		return error;
	}
}

export async function DuplicateProgram(value: CopyProgram, programId: number) {
	const request = {
		newName: value.programName,
		seasonId: value.seasonId,
		awardId: value.awardId,
		entriesOpenDate: value.entriesOpenDate,
	};
	console.log("Request: ", request);
	return await axios.post(`${urlPrograms}/duplicate/${programId}`, request);
}

export type ProgramFieldType =
	| ProgramMediaField
	| ProgramTextField
	| ProgramDateField
	| ProgramListField
	| ProgramCreditField
	| ProgramLinkField
	| ProgramPhysicalComponentField;

export function sortProgramFields(
	fields: ProgramFieldType[]
): ProgramFieldType[] {
	const sortFields = (a: ProgramFieldType, b: ProgramFieldType) => {
		const fieldWeightA =
			a.fieldWeight !== undefined ? a.fieldWeight : Number.MAX_SAFE_INTEGER;
		const fieldWeightB =
			b.fieldWeight !== undefined ? b.fieldWeight : Number.MAX_SAFE_INTEGER;

		if (fieldWeightA !== fieldWeightB) {
			// Sort by fieldWeight in descending order (larger weights first)
			return fieldWeightB - fieldWeightA;
		}

		// For fields without fieldWeight, sort by createdDate in ascending order
		const createdDateA = a.createdDate
			? new Date(a.createdDate).getTime()
			: Infinity;
		const createdDateB = b.createdDate
			? new Date(b.createdDate).getTime()
			: Infinity;

		return createdDateA - createdDateB;
	};

	return fields.sort(sortFields);
}
