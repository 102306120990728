import FieldModal from "../../../../components/FieldModal/FieldModal";
import RadioField, {
	ListContainer,
} from "../../../../components/FormFields/RadioField";
import { CreditFieldTemplate, ProgramCreditField } from "../ProgramInterfaces";
import { CreditType } from "../ProgramInterfaces";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import ToggleSwitchField from "../../../../components/FormFields/ToggleSwitchField";
import DropdownField, {
	DropdownOptions,
} from "../../../../components/FormFields/DropdownField";
import { useEffect, useState } from "react";
import { CompanyTypeData, IndividualTypeData } from "../ProgramInterfaces";
import { fieldRequired } from "../../../../components/FieldModal/FieldModal";
import { fieldTemplateValidation } from "../../../../components/FieldModal/FieldModal";
import { newFieldInitialVal } from "../../../../components/FieldModal/FieldModal";
import TextField from "../../../../components/FormFields/TextField";

const CreditFieldForm = (props: CustomFieldProps) => {
	const formikProps = useFormik({
		initialValues: {
			// populate existing field values for update form OR use new values for new field form
			...(props.formData ? { ...props.formData } : { ...newFieldInitialVal }),
			creditType: props.formData ? props.formData.creditType : 0,
			//   isRequired: props.formData ? props.formData.isRequired : false,
			isUnlocked: props.formData ? props.formData.isUnlocked : false,
			requiredCreditClassification: props.formData
				? props.formData.requiredCreditClassification
				: "",
			minCredits: props.formData ? props.formData.minCredits : "",
			maxCredits: props.formData ? props.formData.maxCredits : "",
			displayNotApplicable: props.formData
				? props.formData.displayNotApplicable ||
				  props.formData.displayNotApplicable == null
				: false,
		},
		onSubmit: async (value) => {},
		validationSchema: Yup.object({
			...fieldTemplateValidation,
			creditType: Yup.string().required(fieldRequired),
			minCredits: Yup.number()
				.min(0, "Minimum must be greater than or equal to 0")
				.required(fieldRequired),
			maxCredits: Yup.number()
				.when({
					is: (value: number) => value > 0,
					then: Yup.number()
						.min(
							Yup.ref("minCredits"),
							"Maximum cannot be less than minimum quantity"
						)
						.required(fieldRequired),
				})
				.min(0, "Maximum must be greater than or equal to 0"),
			displayNotApplicable: Yup.boolean(),
		}),
		validateOnChange: false,
		validateOnBlur: false,
	});

	const [creditClassifications, setCreditClassifications] = useState<
		DropdownOptions[]
	>([]);

	const isEntrantCompanyField = formikProps.values?.title === "Entrant Company";

	useEffect(() => {
		if (formikProps.values.creditType === CreditType.Company) {
			const companyTypes = CompanyTypeData.map((type) => ({
				label: type,
				value: type,
			}));
			setCreditClassifications(companyTypes);
		}

		if (formikProps.values.creditType === CreditType.Individual) {
			const individualTypes = IndividualTypeData.map((type) => ({
				label: type,
				value: type,
			}));
			setCreditClassifications(individualTypes);
		}
	}, [formikProps.values.creditType]);

	// useEffect(() => {
	//   console.log("updated credit values ", formikProps.values);
	// }, [formikProps.values]);

	return (
		<FormikProvider value={formikProps}>
			<FieldModal
				formTitle="Credit Field Template"
				hideModal={() => props.hideModal()}
				formType="credit"
				isUpdateForm={props.formData ? true : false}
			>
				<div>
					<p className="mb-[1rem]">Credit Type:</p>
					<ListContainer name="creditType">
						<RadioField
							name="creditType"
							value={CreditType.Individual}
							label="Individual Credit"
							checked={formikProps.values.creditType === CreditType.Individual}
							{...(props.formData &&
								props.formData.creditType !== undefined && {
									disabled: true,
								})}
						/>
						<RadioField
							name="creditType"
							value={CreditType.Company}
							label="Company Credit"
							checked={formikProps.values.creditType === CreditType.Company}
							{...(props.formData &&
								props.formData.creditType !== undefined && {
									disabled: true,
								})}
						/>
					</ListContainer>
				</div>
				<DropdownField
					className="min-w-[50%]"
					placeholder="Required Credit Classification"
					name="requiredCreditClassification"
					options={creditClassifications}
					value={formikProps.values.requiredCreditClassification}
					{...(props.formData &&
						props.formData.requiredCreditClassification !== undefined && {
							readOnly: true,
						})}
				/>
				{/* <div>
          <p className="mb-[1rem]">Is Required?</p>
          <ToggleSwitchField
            id="isRequired"
            name="isRequired"
            checked={formikProps.values.isRequired}
          />
        </div> */}
				<TextField
					className="min-w-[50%]"
					placeholder="Minimum Number of Credits"
					name="minCredits"
					type="number"
					value={formikProps.values.minCredits}
				/>
				<TextField
					className="min-w-[50%]"
					placeholder="Maximum Number of Credits (zero is infinite)"
					name="maxCredits"
					type="number"
					value={formikProps.values.maxCredits}
				/>
				<div>
					<p className="mb-[1rem]">Is Unlocked?</p>
					<ToggleSwitchField
						id="isUnlocked"
						name="isUnlocked"
						checked={formikProps.values.isUnlocked}
					/>
				</div>
				{formikProps.values.minCredits !== "" &&
					Number(formikProps.values.minCredits) > 0 &&
					!isEntrantCompanyField && (
						<div>
							<p className="mb-[1rem]">Display "N/A"?</p>
							<ToggleSwitchField
								id="displayNotApplicable"
								name="displayNotApplicable"
								checked={formikProps.values.displayNotApplicable}
							/>
						</div>
					)}
			</FieldModal>
		</FormikProvider>
	);
};

export default CreditFieldForm;

interface CustomFieldProps {
	formData?: CreditFieldTemplate | ProgramCreditField;
	hideModal: () => void;
}
