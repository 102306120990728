import DropdownField, {
  DropdownOptions,
} from "../../components/FormFields/DropdownField";
import Modal, { ModalContainer } from "../../components/Modal/Modal";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import styled, { useTheme } from "styled-components";
import StickyCard from "../../components/StickyCard/StickyCard";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import { ModalCard } from "../../components/Modal/Modal";

export const SelectExecutionModal = (props: SelectExecutionModalProps) => {
  const theme = useTheme();
  const [initialValues] = useState<{ id: number | undefined }>({
    id: undefined,
  });

  let fieldRequired = "This field is required";

  const formikProps = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
      props.addExecution(formikProps.values.id!);
      props.onClickHide();
    },
    validationSchema: Yup.object({
      id: Yup.number().required(fieldRequired),
    }),
  });

  return (
    <FormikProvider value={formikProps}>
      <Modal
        show={props.show}
        // onClick={(e) => e.stopPropagation()}
      >
        <ModalCard
          className="h-[500px]"
          title="Add Execution"
          headerIcons={
            <Icon
              icon="close"
              color={theme.colorCopyLightLight}
              onClick={props.onClickHide}
            />
          }
          iconColor={theme.colorCopyLightLight}
        >
          <div className="flex flex-col py-[2.625rem] px-[1.875rem] gap-[1.5625rem]">
            <div className="col-w-100">
              <h3 className="subtitle font-semibold">
                {props.childLevelDisplayLabel}
              </h3>
              <p>
                Please choose one item from the list below to indicate the type
                of work contained within the execution.
              </p>
            </div>

            <DropdownField
              name="id"
              placeholder="Select Type"
              options={props.options}
            />
            <Button
              type="submit"
              icon="plus"
              iconColor={theme.colorCopyLightLight}
              onClick={() => formikProps.submitForm()}
            >
              Add Execution
            </Button>
          </div>
        </ModalCard>
      </Modal>
    </FormikProvider>
  );
};

export default SelectExecutionModal;

interface SelectExecutionModalProps {
  show: boolean;
  childLevelDisplayLabel: string;
  onClickHide(): void;
  options: DropdownOptions[];
  addExecution(programId: number): void;
}
