const msToTime = (ms: number) => {
  const s = ms / 1000;
  const m = s / 60;
  const h = m / 60;
  const d = h / 24;
  // console.log("s, m, h, d", s, m, h, d);
  return {
    hours: h,
    days: d,
  };
};

export const getCountdownTimer = (targetDate: Date) => {
  const inputDate =
    targetDate instanceof Date ? targetDate : new Date(targetDate);

  const dateNow = new Date().getTime();
  return msToTime(inputDate.getTime() - dateNow);
};

// rounds the remaining time to the nearest hour/day/month

// >= 1 Hour && < 24 Hours <X> Hour(s)
// >=24 Hours && < 31 Days <X> Day(s)
// >= 28 Days && <X> Week(s) <Y> Day(s)

export const roundTime = (targetDate: Date) => {
  const countdown = getCountdownTimer(targetDate);
  if (countdown.days >= 28) {
    const day = () => {
      if (
        Math.floor(countdown.days % 7) === 0 ||
        Math.floor(countdown.days % 28) === 0
      ) {
        return "";
      } else {
        return `${Math.floor(countdown.days % 7)} Days`;
      }
    };

    return `${Math.floor(countdown.days / 7)} Weeks ${day()}`;
  } else if (countdown.days >= 1) {
    const daysRoundDown = Math.floor(countdown.days);
    return daysRoundDown > 1 ? `${daysRoundDown} Days` : `${daysRoundDown} Day`;
  } else if (countdown.hours >= 1) {
    const hoursRoundDown = Math.floor(countdown.hours);
    return hoursRoundDown > 1
      ? `${hoursRoundDown} Hours`
      : `${hoursRoundDown} Hour`;
  } else {
    return 0;
  }
};
