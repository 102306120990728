import React, { useEffect, useState } from "react";
import {
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Image,
	Font,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import Placeholder from "../../../assets/placeholders/image-landscape.png";
import { Ancestor } from "../../Admin/Program/ProgramInterfaces";
import { useTheme } from "styled-components";
import { OrderModel } from "../../Checkout/OrderInterfaces";
import { urlCompanies } from "../../../endpoints";
import axios from "axios";
import { CompanyModel } from "../../Company/company.model";
import CompanyUpdate from "../../Company/CompanyUpdate";
import { defaultColors, lightTheme } from "../../../assetsConfig";
import QRCode from "qrcode";
import { getNonWebpThumbnailSrc } from "../../MediaLibrary/manageMediaLibrary";
import config from "../../../config";
import assetsConfig from "../../../assetsConfig";

Font.register({
	family: assetsConfig.fonts.pdf.fontFamily,
	fonts: assetsConfig.fonts.pdf.fonts,
});

const rem = 16;

// Create styles
const styles = StyleSheet.create({
	page: {
		display: "flex",
		flexDirection: "column",
		width: "100vw",
		flexGrow: 1,
		backgroundColor: "white",
		fontSize: "12px",
		fontFamily: "Open Sans",
		// paddingBottom: rem + "px",
	},
	flexCol: {
		display: "flex",
		flexDirection: "column",
	},
	flexRow: {
		display: "flex",
		flexDirection: "row",
	},
	itemsCenter: {
		alignItems: "center",
	},
	justifyCenter: {
		justifyContent: "center",
	},

	justifyBetween: {
		justifyContent: "space-between",
	},
	bold: {
		fontWeight: 900,
	},
	italics: {
		fontStyle: "italic",
	},
	entryCoverImg: {
		width: "100px",
		height: "56px",
		objectFit: "cover",
	},
	entryFirstRow: {
		justifyContent: "space-between",
		marginBottom: "7.5px",
	},
	logo: {
		width: "200px",
		height: "35px",
	},
	mtAuto: {
		marginTop: "auto",
	},
	mlAuto: {
		marginLeft: "auto",
	},
	wFull: {
		width: "100%",
	},
	mrHalf: {
		marginRight: "8px",
	},
	mr1: {
		marginRight: rem + "px",
	},
	mr3: {
		marginRight: "48px",
	},
	mb1: {
		marginBottom: rem + "px",
	},
	mb3: {
		marginBottom: "48px",
	},
	mb4: {
		marginBottom: "64px",
	},
	mbHalf: {
		marginBottom: "8px",
	},
	mt1: {
		marginTop: rem + "px",
	},
	mt2: {
		marginTop: "32px",
	},
	mtHalf: {
		marginTop: "8px",
	},
	headingText: {
		fontSize: rem + "px",
		textTransform: "uppercase",
		color: lightTheme.colorCopyLight,
	},
	subHeadingText: {
		fontSize: "14px",
	},

	textBlue: {
		color: defaultColors.colorActivationDark,
	},
	textGray: {
		color: lightTheme.colorCopyLight,
	},
	textDanger: {
		color: defaultColors.colorDanger,
	},
	textSmall: {
		fontSize: rem * 0.6 + "px",
	},
	textXSmall: {
		fontSize: rem * 0.5 + "px",
	},

	pageBorderTop: {
		width: "100vw",
		height: rem * 1.5 + "px",
		backgroundColor: defaultColors.colorActivationDark,
		marginBottom: rem / 2 + "px",
	},
	pageBorderBottom: {
		width: "100vw",
		height: rem + "px",
		backgroundColor: defaultColors.colorAwardGold,
		marginTop: "auto",
	},
	paymentBottom: {
		padding: "0 47px",
		marginLeft: "auto",
		marginTop: "auto",
	},
	wrap: {
		flexWrap: "wrap",
	},

	hr: {
		width: "100%",
		borderBottom: "1px",
		borderBottomColor: lightTheme.colorBorderLight,
	},
	cutOut: {
		margin: rem + "px",
		border: `1px dashed ${lightTheme.colorBorderDark}`,
	},
	border: {
		border: `1px solid ${lightTheme.colorBorderDark}`,
	},
	section: {
		width: "100%",
		paddingVertical: rem + "px",
		paddingHorizontal: rem * 2 + "px",
	},
	sectionSmallPadding: {
		width: "100%",
		paddingVertical: rem / 2 + "px",
		paddingHorizontal: rem + "px",
	},
	textActivation: {
		color: lightTheme.colorActivation,
	},
	checkBox: {
		width: rem / 2 + "px",
		height: rem / 2 + "px",
		border: `1px solid ${lightTheme.colorBorderDark}`,
		top: "1.5px",
	},
	mxAuto: {
		marginHorizontal: "auto",
	},
	divider: {
		borderBottom: `1px solid ${lightTheme.colorBorderLight}`,
	},
	dashedDivider: {
		borderBottom: `1px dashed ${lightTheme.colorBorderLight}`,
	},
	qrCode: {
		width: "80x",
		height: "80x",
		marginLeft: "auto",
	},
	shippingLabel: {
		width: "75vw",
		marginHorizontal: "auto",
		marginTop: rem + "px",
	},
	m1: {
		margin: rem + "px",
	},
});

const Sections = (props: {
	sections: PDFSection[];
	styles?: { [key: string]: string };
}) => {
	return (
		<>
			{props.sections.map((section, i) => (
				<React.Fragment
					key={section.label ? section.label + section.value : section.value}
				>
					<View
						style={{ ...styles.section, ...props.styles, ...section.styles }}
					>
						<Text style={{ ...styles.subHeadingText }}>{section.label}</Text>
						<Text>{section.value}</Text>
					</View>
					{section.divider && <View style={styles.divider} />}
				</React.Fragment>
			))}
		</>
	);
};

const ShippingLabel = (props: ShippingLabelProps) => {
	return (
		<View style={styles.shippingLabel}>
			<Text style={{ ...styles.headingText, ...styles.mxAuto, ...styles.mb1 }}>
				{props.instructions.label}
			</Text>
			<Sections
				styles={{
					paddingVertical: "0",
					paddingHorizontal: "0",
					...styles.textSmall,
				}}
				sections={props.instructions.sections}
			/>

			<View style={styles.cutOut}>
				<View
					style={{
						...styles.flexRow,
						...styles.sectionSmallPadding,
						...styles.divider,
						...styles.textXSmall,
					}}
				>
					<View
						style={{
							marginVertical: "auto",
						}}
					>
						<Image
							style={{
								width: "150px",
								height: "26.25px",
								...styles.mbHalf,
							}}
							src={config.assets.logos.localPng}
						/>

						<Text
							style={{
								...styles.headingText,
								...styles.mxAuto,
								...styles.textGray,
								...styles.textSmall,
							}}
						>
							{props.shippingLabel.label}
						</Text>
					</View>

					<View
						style={{
							marginLeft: "auto",
						}}
					>
						<Text>{props.shippingLabel.sections[0].label}</Text>
						<Text>{props.shippingLabel.sections[0].value}</Text>
					</View>
				</View>
				<Sections
					styles={styles.sectionSmallPadding}
					sections={[props.shippingLabel.sections[1]]}
				/>
				{/* <Sections sections={props.shippingLabel.sections} /> */}

				<View style={{ ...styles.sectionSmallPadding, ...styles.textXSmall }}>
					<Text style={styles.subHeadingText}>Order Id: {props.order.id}</Text>
					<Text style={{ ...styles.textActivation, ...styles.mbHalf }}>
						{props.order.orderInstructions}
					</Text>
					{props.order.entries
						.filter((entry) => entry.programHasPhysicalComponent)
						.map((entry) => (
							<View
								key={entry.id}
								style={{
									...styles.flexRow,
									...styles.mbHalf,
								}}
							>
								<View
									style={{
										...styles.checkBox,
										...styles.mrHalf,
									}}
								/>
								<Text>Entry {entry.id} - </Text>
								<View
									style={{
										...styles.flexRow,
										flexWrap: "wrap",
										maxWidth: "85%",
									}}
								>
									{entry.hierarchy.map((hierarchy, index) => {
										return (
											<Text key={index}>
												{hierarchy}{" "}
												{index < entry.hierarchy!.length - 1 && "/ "}
											</Text>
										);
									})}
								</View>
							</View>
						))}
				</View>
			</View>
		</View>
	);
};

const EntryManifest = (props: EntryManifestProps) => {
	return (
		<View style={styles.section} wrap={false}>
			<Text style={{ ...styles.headingText, ...styles.mb1, ...styles.mxAuto }}>
				{props.pageLabel}
			</Text>

			<View
				key={props.entry.id}
				style={{
					...styles.flexRow,
					...styles.justifyBetween,
				}}
			>
				<View>
					<Text>{props.entry.brand}</Text>
					<Text style={{ ...styles.subHeadingText, ...styles.bold }}>
						{props.entry.title}
					</Text>
					<Text>Entry ID: {props.entry.id}</Text>
					{props.entry.hierarchy.map((hierarchy, index) => {
						return (
							<Text
								style={{
									...styles.textSmall,
								}}
								key={index}
							>
								{hierarchy}
							</Text>
						);
					})}
					{props.entry.entryType && (
						<Text
							style={{
								...styles.textSmall,
								...styles.italics,
							}}
						>
							{props.entry.entryType}
						</Text>
					)}
				</View>
				<Image
					style={styles.entryCoverImg}
					src={
						(props.entry.coverImage &&
							getNonWebpThumbnailSrc(props.entry.coverImage!)) ||
						Placeholder
					}
				/>
			</View>

			<Text style={{ ...styles.textSmall, ...styles.mt1 }}>
				{props.entry.physicalItemDescription}
			</Text>
		</View>
	);
};

const EntrantDetails = (props: EntrantDetailsProps) => {
	const details = props.details;
	return (
		<View style={styles.section} wrap={false}>
			<View style={styles.divider} />
			<Text
				style={{
					...styles.subHeadingText,
					...styles.mbHalf,
					...styles.mt1,
					...styles.textGray,
				}}
			>
				{props.pageLabel}
			</Text>
			<View
				key={details.companyId}
				style={{
					...styles.flexRow,
					...styles.mbHalf,
					...styles.justifyBetween,
				}}
			>
				<View>
					<Text style={{ ...styles.subHeadingText }}>
						{details.entryContactName}
					</Text>
					<Text>{details.companyName}</Text>
					<Text>{details.companyAddress1}</Text>
					<Text>{details.companyAddress2}</Text>
					<Text>
						{details.companyCity}, {details.companyState}{" "}
						{details.companyPostalCode}
					</Text>
					<Text>{details.companyCountry}</Text>
				</View>
				<View>
					<Text>{details.entryContactPhone}</Text>
					<Text>{details.entryContactEmail}</Text>
				</View>
			</View>
		</View>
	);
};
const AdminSection = (props: AdminSectionProps) => {
	const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(
		props.entry.qrCodeUrl || null
	);
	const baseUrl = process.env.REACT_APP_BASE_URL;
	const path = `judgingredirect/${props.entry.id}`;
	const QRvalue = `${baseUrl}${path}`;

	useEffect(() => {
		if (!props.entry.qrCodeUrl) {
			QRCode.toDataURL(QRvalue)
				.then((url) => {
					setQrCodeUrl(url);
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [QRvalue]);

	return (
		<>
			<Text style={{ ...styles.mxAuto, ...styles.textXSmall }}>
				{props.dividerLabelTop}
			</Text>
			<View
				style={{
					...styles.dashedDivider,
					...styles.mbHalf,
					...styles.mtHalf,
				}}
			/>
			<Text style={{ ...styles.mxAuto, ...styles.textXSmall }}>
				{props.dividerLabelBottom}
			</Text>
			<View style={styles.mt1}>
				<Image
					style={{ ...styles.mxAuto, width: "100px", height: "17.5px" }}
					src={config.assets.logos.localPng}
				/>
				{/* <Text
          style={{
            ...styles.mxAuto,
            ...styles.mt1,
            fontSize: rem * 0.65 + "px",
          }}
        >
          {props.dividerLabelBottom}
        </Text> */}

				<View style={styles.section}>
					<View
						key={props.entry.id}
						style={{
							...styles.flexRow,
							...styles.mbHalf,
							...styles.justifyBetween,
							...styles.border,
							...styles.section,
						}}
						wrap={false}
					>
						<View>
							<Text>{props.entry.brand}</Text>
							<Text style={{ ...styles.subHeadingText, ...styles.bold }}>
								{props.entry.title}
							</Text>
							<Text>Entry ID: {props.entry.id}</Text>
							{props.entry.hierarchy.map((hierarchy, index) => {
								return (
									<Text
										style={{
											...styles.textSmall,
										}}
										key={index}
									>
										{hierarchy}
									</Text>
								);
							})}
							{props.entry.entryType && (
								<Text
									style={{
										...styles.textSmall,
										...styles.italics,
									}}
								>
									{props.entry.entryType}
								</Text>
							)}
						</View>
						<View>
							<Image
								style={{ ...styles.entryCoverImg, ...styles.mb1 }}
								src={
									(props.entry.coverImage &&
										getNonWebpThumbnailSrc(props.entry.coverImage!)) ||
									Placeholder
								}
							/>

							{qrCodeUrl && <Image style={styles.qrCode} src={qrCodeUrl} />}
						</View>
					</View>
				</View>
			</View>
		</>
	);
};

const PhysicalEntryPDF = (props: PhysicalEntryPDFProps) => {
	const [company, setCompany] = useState<CompanyModel>(
		props.company ?? ({} as CompanyModel)
	);
	const fromAddress = `${company?.name || ""} \n ${
		company?.address1 || ""
	} \n ${company?.address2 || ""} \n ${company?.city || ""}, ${
		company?.postalCode || ""
	} \n ${company?.country || ""}`;

	async function getCompany(companyId: number) {
		const response = axios.get<CompanyModel>(`${urlCompanies}/${companyId}`);
		return response;
	}

	useEffect(() => {
		getCompany(Number(props.order.companyId)).then((response) => {
			if (response?.status === 200) {
				setCompany(response.data);
			}
		});
	}, []);

	const PhysicalPDFCompleteData = {
		id: props.order.id,

		company: {
			companyId: Number(company?.id) || 0,
			companyName: company?.name || "",
			companyAddress1: company?.address1 || "",
			companyAddress2: company?.address2 || "",
			companyCity: company?.city || "",
			companyCountry: company?.country || "",
			companyPostalCode: company?.postalCode || "",
			companyState: company?.state || "",
			entryContactName: company?.entryContactName || "",
			entryContactEmail: company?.entryContactEmail || "",
			entryContactPhone: company?.entryContactPhone || "",
		},

		order: {
			id: props.order.id || 0,
			orderInstructions: "Mark the entries that are included in this package.",
			entries: props.order.lineItems.map((lineItem) => {
				return {
					id: lineItem.entryId,
					title: lineItem.entry.title || "",
					brand: lineItem.entry.brand || "",
					entryType: lineItem.entry.entryType || "",
					hierarchy: lineItem.entry.hierarchy || [],
					coverImage: lineItem.entry.coverImage || "",
					physicalItemDescription: lineItem.entry.physicalItemDescription || "",
					programHasPhysicalComponent: lineItem.programHasPhysicalComponent,
					qrCodeUrl: lineItem.entry.qrCodeUrl || "",
					isCampaign:
						lineItem.entry.isCampaign !== undefined
							? lineItem.entry.isCampaign
							: false,
				};
			}),
		},

		// admin section
		adminSection: {
			dividerLabelTop:
				"Do not cut, fold, or permanently attach to your submission.",
			dividerLabelBottom: "For Administrative Purposes Only",
		},

		shippingInstructions: {
			label: "Shipping Instructions",
			sections: [
				{
					value: `1. Print the Shipping Label below and all Entry Manifest Pages`,
				},
				{
					value: `2. Pack your physical entry and include the appropriate Entry Manifest Pages with your entry packaging`,
				},
				{
					value: `3. Cut out and attach the Shipping Label to your package along with appropriate postage`,
				},
			],
		},

		shippingLabel: {
			label: "Shipping Label",

			sections: [
				{
					label: "From: ",
					value: fromAddress,
					divider: true,
				},
				{
					label: "Ship To:",
					value:
						assetsConfig.companyNameLong +
						" \n 104 West 27th Street \n 10th Floor \n New York, NY 10001 \n United States",
					divider: true,
					styles: {
						fontSize: rem + "px",
						fontWeight: "700",
					},
				},
			],
		},
	};

	return (
		<Document
			title={`Physical Entry Order ${String(props.order.id)}`}
			author={assetsConfig.companyNameShort}
			keywords={assetsConfig.companyNameShort + ", Physical Entry PDF"}
		>
			<Page size="A4" style={styles.page}>
				<View style={styles.pageBorderTop} />
				<View
					style={{
						...styles.flexRow,
						...styles.justifyBetween,
						...styles.wFull,
						...styles.itemsCenter,
						...styles.section,
						...styles.mbHalf,
					}}
				>
					<Image
						style={{ ...styles.logo }}
						src={config.assets.logos.localPng}
					/>
					<Text style={{ ...styles.headingText }}>{props.pageTitle}</Text>
				</View>
				<View style={styles.divider} />

				<ShippingLabel
					order={PhysicalPDFCompleteData.order}
					instructions={{
						label: PhysicalPDFCompleteData.shippingInstructions.label,
						sections: PhysicalPDFCompleteData.shippingInstructions.sections,
					}}
					shippingLabel={{
						label: PhysicalPDFCompleteData.shippingLabel.label,
						sections: PhysicalPDFCompleteData.shippingLabel.sections,
					}}
				/>
				{PhysicalPDFCompleteData.order.entries
					.filter((entry) => entry.programHasPhysicalComponent)
					.map((entry) => (
						<View key={entry.id} break>
							<EntryManifest pageLabel="Entry Manifest" entry={entry} />
							<EntrantDetails
								pageLabel="Entrant Details"
								details={PhysicalPDFCompleteData.company}
							/>
							<AdminSection
								dividerLabelTop={
									PhysicalPDFCompleteData.adminSection.dividerLabelTop
								}
								dividerLabelBottom={
									PhysicalPDFCompleteData.adminSection.dividerLabelBottom
								}
								entry={entry}
							/>
						</View>
					))}

				{/* <View style={styles.pageBorderBottom}></View> */}
			</Page>
		</Document>
	);
};

export default PhysicalEntryPDF;

interface EntryItem {
	id: number;
	title: string;
	brand: string;
	entryType?: string;
	// ancestry: Ancestor[];
	hierarchy: string[];
	coverImage: string;
	physicalItemDescription?: string;
	programHasPhysicalComponent?: boolean;
	qrCodeUrl?: string;
	isCampaign: boolean;
}

interface PhysicalEntryOrderSection {
	id: number;
	orderInstructions: string;
	entries: EntryItem[];
}

interface PDFSection {
	label?: string;
	value: string;
	divider?: boolean;
	styles?: {
		[key: string]: string | undefined;
	};
	//   fontSize?: string;
}

export interface PhysicalEntryPDFProps {
	order: OrderModel;
	pageTitle?: string;
	title?: string;
	buttonClass?: string;
	iconColor: string;
	company?: CompanyModel;
}

interface EntryManifestProps {
	pageLabel: string;
	entry: EntryItem;
}

interface ShippingLabelProps {
	order: PhysicalEntryOrderSection;
	instructions: {
		label: string;
		sections: PDFSection[];
	};
	shippingLabel: {
		label: string;
		sections: PDFSection[];
	};
}

interface EntrantDetailsProps {
	pageLabel: string;
	details: {
		companyId: number;
		companyName: string;
		companyAddress1: string;
		companyAddress2?: string;
		companyCity: string;
		companyCountry: string;
		companyPostalCode: string;
		companyState: string;

		entryContactName: string;
		entryContactEmail: string;
		entryContactPhone: string;
	};
}

interface AdminSectionProps {
	entry: EntryItem;
	dividerLabelTop: string;
	dividerLabelBottom: string;
}
