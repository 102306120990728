import { DragCreditAccordion } from "../../../components/DragAccordion/DragCreditAccordion";
import {
  isACompanyCreditField,
  isAIndividualCreditField,
} from "../../Entries/CreditFields";
import { CompanyCredit } from "../Credits/CreditInterfaces";
import {
  CreditType,
  EntryCompanyCreditField,
  EntryIndividualCreditField,
} from "../Program/ProgramInterfaces";
import styled from "styled-components";

const Divider = styled.div`
  width: 100%;
  border-top: 2px solid ${({ theme }) => theme.colorBorderLight};
  padding: 0 2rem;
`;

const CreditsContainer = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const RenderCreditFields = (props: RenderCreditFieldsProps) => {
  // if no ind credits, return "No Credit" text
  const noIndCredits = props.creditFields.every((field: any) => {
    if (isAIndividualCreditField(field.programField)) {
      const indCredits = (field as EntryIndividualCreditField).credits;
      return indCredits.length === 0;
    } else return true;
  });

  return (
    <>
      <CreditsContainer>
        <h2 className="mb-[1rem] font-normal">Company Credits</h2>
        {props.creditFields.length > 0 &&
          props.creditFields.flatMap((field: any) => {
            if (isACompanyCreditField(field.programField)) {
              const companyCredits = (field as EntryCompanyCreditField).credits;

              return (
                <div>
                  <h3 className="mb-[.5rem]">
                    {field.programField.title}
                    {(field as EntryCompanyCreditField).programField
                      .minCredits > 0
                      ? "*"
                      : ""}
                  </h3>
                  {companyCredits.length > 0 ? (
                    companyCredits.map((creditJoin) => (
                      <DragCreditAccordion
                        className="dropped"
                        key={creditJoin.credit.id}
                        dropTarget=""
                        creditObj={creditJoin.credit}
                        creditType={CreditType.Company}
                        readOnly
                      />
                    ))
                  ) : field.notApplicableAttestation ? (
                    <p className="ml-[1rem]">Selected N/A</p>
                  ) : (
                    <p className="ml-[1rem]">No Credits</p>
                  )}
                </div>
              );
            } else return [];
          })}
      </CreditsContainer>

      <Divider />

      <CreditsContainer>
        <h2 className="mb-[1rem] font-normal">Individual Credits</h2>
        {props.creditFields.length > 0 &&
          props.creditFields.flatMap((field: any, i: number) => {
            if (isAIndividualCreditField(field.programField)) {
              const indCredits = (field as EntryIndividualCreditField).credits;

              return (
                <div>
                  <h3 className="mb-[.5rem]">
                    {field.programField.title}
                    {(field as EntryIndividualCreditField).programField
                      .minCredits > 0
                      ? "*"
                      : ""}
                  </h3>
                  {indCredits.length > 0 ? (
                    indCredits.map((creditJoin) => (
                      <DragCreditAccordion
                        className="dropped"
                        key={creditJoin.credit.id}
                        dropTarget=""
                        creditObj={creditJoin.credit}
                        creditType={CreditType.Individual}
                        readOnly
                      />
                    ))
                  ) : (
                    <p className="ml-[1rem]">No Credits</p>
                  )}
                </div>
              );
            } else return [];
          })}
      </CreditsContainer>
    </>
  );
};

export default RenderCreditFields;

interface RenderCreditFieldsProps {
  name: string;
  creditFields: (EntryCompanyCreditField | EntryIndividualCreditField)[];
}
