// check if substring exists in another string
export const isSubStringInString = (string1: string, string2: string) => {
  if (
    string1 !== null &&
    string1 !== undefined &&
    string2 !== null &&
    string2 !== undefined
  ) {
    return string1.toUpperCase().includes(string2.toUpperCase());
  } else return false;
};
