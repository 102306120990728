import React, { useEffect, useState } from "react";
import styled, { css, useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import Icon from "../Icon/Icon";
import { DynamicTransitionDuration } from "../../globalStyles";
import config from "../../config";
import stringToId from "../../utils/stringToId";

// https://stackoverflow.com/questions/62515517/darken-and-lighten-colors-in-javascript
// lighten or darken a color
export const adjustColor = (
	color: string,
	amount: number = -30 // default darken by 30%
) => {
	const clamp = (val: number) => Math.min(Math.max(val, 0), 0xff);
	const fill = (str: string) => ("00" + str).slice(-2);

	const num = parseInt(color.substr(1), 16);
	const red = clamp((num >> 16) + amount);
	const green = clamp(((num >> 8) & 0x00ff) + amount);
	const blue = clamp((num & 0x0000ff) + amount);

	return (
		"#" +
		fill(red.toString(16)) +
		fill(green.toString(16)) +
		fill(blue.toString(16))
	);
};

const StyledButton = styled.button<{ disabled: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem 2.5rem;
	min-width: 125px;
	font-size: ${({ theme }) => theme.h5Size};
	color: ${({ theme }) => theme.colorCopyLightLight};
	background: ${({ theme }) => theme.colorActivation};
	transition: all ${DynamicTransitionDuration} ease-in;
	box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};

	&:hover {
		background: ${(p) => adjustColor(p.theme.colorActivation)};
	}

	&.button-dark {
		background: ${({ theme }) => theme.colorButtonDark};
		box-shadow: 0 0 0 2px ${({ theme }) => theme.colorActivation} inset,
			0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};

		&:hover {
			background: ${(p) => adjustColor(p.theme.colorButtonDark)};
		}
	}

	&.button-light {
		color: ${({ theme }) => theme.colorCopyDarkDark};
		background: ${({ theme }) => theme.colorButtonLight};
		box-shadow: 0 0 0 1px ${({ theme }) => theme.colorActivation} inset,
			0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};

		&:hover {
			background: ${(p) => adjustColor(p.theme.colorButtonLight)};
		}
	}

	&.button-gold {
		color: ${({ theme }) => theme.colorCopyDarkDark};
		box-shadow: 0 0 0 2px ${({ theme }) => theme.colorPrimary} inset,
			0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
		background: transparent;
	}

	&.button-navy {
		background: ${(p) => adjustColor(p.theme.colorButtonNavy)};
	}

	&.button-danger {
		background: ${(p) => adjustColor(p.theme.colorDanger)};
	}

	&.button-transparent {
		background: transparent;
	}

	&.button-dark-gray {
		background: ${({ theme }) => theme.colorNoGrand};

		&:hover {
			background: ${(p) => adjustColor(p.theme.colorNoGrand, -20)};
		}
	}

	&.button-border-light {
		box-shadow: 0 0 0 2px ${({ theme }) => theme.colorBorderLight} inset,
			0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
	}

	&.dark-fill {
		color: ${({ theme }) => theme.colorCopyLightLight};
		background: ${({ theme }) => theme.colorBackgroundDarkDark};

		&:hover {
			background: ${(p) => adjustColor(p.theme.colorBackgroundDarkDark, 30)};
		}
	}

	.icon-left {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		order: 0;
		margin-right: 0.5rem;
	}

	${(p) =>
		p.disabled &&
		css`
			user-select: none;
			background: ${({ theme }) => theme.colorFieldDisabled} !important;
			color: ${({ theme }) => theme.colorCopyLight};
		`};
`;

/* wrapper to change svg stroke color */
export const IconWrapper = styled.span<iconWrapperProps>`
	cursor: pointer;
	svg {
		width: ${(p) => p.width};
		height: ${(p) => p.height};
		path {
			/* fill: ${(p) => p.color};
      fill: "red"; */
			/* stroke: ${(p) => p.color}; */
			/* stroke: red; */
			/* fill: red; */
		}

		g {
			/* fill: ${(p) => (p.solidG ? p.color : `transparent`)}; */
			/* stroke: red; */
			stroke: ${(p) => p.color};
		}
	}
`;

const StyledNavLink = styled.div<{ disabled: boolean }>`
	display: flex;
	align-items: center;
	cursor: pointer;
	min-width: 0 !important;

	a {
		font-size: 1rem;
		margin-right: 0.5rem;
	}

	${(p) =>
		p.disabled &&
		css`
			a {
				cursor: default;
				color: ${({ theme }) => theme.colorFieldDisabled};
			}
		`};
`;

const loadingSpinnerIcon = config.assets.loading.primary;
const LoadingSpinner = styled.img`
	height: 23px;
`;

export const CustomNavLink = (props: CustomNavLinkProps) => {
	const theme = useTheme();
	return (
		<StyledNavLink
			className={props.className ? props.className : ""}
			onClick={props.onClick}
			id={`customNavLink-${stringToId(props.label)}`}
			disabled={props.disabled || false}
		>
			{props.icon && props.icon === "prev" && (
				<Icon icon="caret" color={theme.colorActivation} rotation="90deg" />
				// <Icon icon="caret-left" color={theme.colorActivation} />
			)}
			<a href={props.href}>{props.label}</a>
			{props.icon && props.icon === "next" && (
				<Icon icon="caret" color={theme.colorActivation} rotation="270deg" />
				// <Icon icon="caret-right" color={theme.colorActivation} />
			)}
		</StyledNavLink>
	);
};

const Button = (props: buttonProps) => {
	const history = useHistory();
	const theme = useTheme();
	const [iconColor, setIconColor] = useState("");

	const setDefaultColor = () => {
		const isButtonLight = props.className?.includes("button-light");
		setIconColor(
			isButtonLight ? theme.colorActivation : theme.colorCopyLightLight
		);
	};

	// set default icon color on render
	useEffect(() => {
		setDefaultColor();
	}, []);

	const handleClick = () => {
		props.onClick && props.onClick();
		props.to && history.push(props.to);
		props.download &&
			window.open(props.download, "_blank", "noopener,noreferrer");
	};

	useEffect(() => {
		setDefaultColor();
		props.iconColor && setIconColor(props.iconColor);
		props.disabled && setIconColor(theme.colorCopyLight);
	}, [props.iconColor, props.disabled]);

	return (
		<StyledButton
			className={`${props.className} flex items-center`}
			type={props.type}
			onClick={handleClick}
			disabled={props.disabled ? true : false}
		>
			{props.iconLeft && (
				<span className="icon-left">
					{
						<Icon
							icon={props.iconLeft}
							color={iconColor}
							width={props.iconSize ? props.iconSize : "12px"}
							height={props.iconSize ? props.iconSize : "12px"}
							defaultCursor={props.disabled}
						/>
					}
				</span>
			)}
			{props.icon &&
				iconColor && (
					<Icon
						icon={props.icon}
						color={iconColor}
						rotation={props.iconRotation ? props.iconRotation : undefined}
						width={props.iconSize ? props.iconSize : "12px"}
						height={props.iconSize ? props.iconSize : "12px"}
						defaultCursor={props.disabled}
					/>
				) && (
					<span
						className={`${
							props.iconRight ? "order-2 mr-0 ml-1 ml-2" : "icon-left"
						}`}
					>
						{
							<Icon
								icon={props.icon}
								color={iconColor}
								rotation={props.iconRotation ? props.iconRotation : undefined}
								width={props.iconSize ? props.iconSize : "12px"}
								height={props.iconSize ? props.iconSize : "12px"}
								defaultCursor={props.disabled}
							/>
						}
					</span>
				)}
			<span className="order-1 whitespace-nowrap">
				{props.loading ? (
					<LoadingSpinner src={loadingSpinnerIcon} />
				) : (
					props.children
				)}
			</span>
		</StyledButton>
	);
};

export const ButtonLink = (props: buttonProps) => {
	const theme = useTheme();
	const history = useHistory();
	const iconColor = props.iconColor === undefined ? "" : props.iconColor;

	const handleClick = () => {
		props.onClick && props.onClick();
		props.to && history.push(props.to);
		props.download &&
			window.open(props.download, "_blank", "noopener,noreferrer");
	};

	return (
		<>
			<StyledButton
				className={`${
					props.className ? props.className : ""
				} flex !justify-start w-full`}
				type={props.type}
				onClick={handleClick}
				disabled={props.disabled ? true : false}
			>
				{props.iconLeft && (
					<span className="icon-left">
						{
							<Icon
								icon={props.iconLeft}
								color={iconColor}
								defaultCursor={props.disabled}
							/>
						}
					</span>
				)}
				{props.icon &&
					props.iconColor && (
						<Icon
							icon={props.icon}
							color={iconColor}
							{...(props.className?.includes("button-light") && {
								color: theme.colorActivation,
							})}
							defaultCursor={props.disabled}
						/>
					) && (
						<span
							className={`${
								props.iconRight ? "order-2 mr-0 ml-1 ml-2 abcs" : "icon-left"
							}`}
						>
							{
								<Icon
									icon={props.icon}
									color={iconColor}
									width={props.iconWidth}
									height={props.iconHeight}
									defaultCursor={props.disabled}
								/>
							}
						</span>
					)}
				<span className="flex order-1 w-full items-center">
					{props.children}
				</span>
			</StyledButton>
		</>
	);
};

export const SquareButton = styled(Button)`
	min-width: 25px !important;
	min-height: 25px !important;
	padding: 0 !important;
	box-shadow: none;

	.icon-left,
	span {
		margin: 0px !important;
	}
`;

export default Button;

// StyledButton.defaultProps = {
//   type: "button",
//   disabled: false,
// };

export interface buttonProps {
	href?: string;
	children?: React.ReactNode;
	onClick?(): void;
	type?: "button" | "submit"; // temp fix, set to optional type due to failed test
	disabled?: boolean;
	className?: string;
	icon?: string;
	iconLeft?: string;
	iconRight?: boolean;
	iconColor?: string;
	iconRotation?: string;
	iconSize?: string;
	iconHeight?: string;
	iconWidth?: string;
	to?: string;
	download?: string;
	loading?: boolean;
}

interface iconWrapperProps {
	color: string;
	width?: string;
	height?: string;
	solidG?: boolean;
	onClick?(): void;
}

interface CustomNavLinkProps {
	className?: string;
	label: string;
	href?: string;
	icon?: "prev" | "next";
	onClick?(): void;
	disabled?: boolean;
}
