import { useTheme } from "styled-components";
import Icon from "../Icon/Icon";
import { useState } from "react";
import { updateHideMediaForWinnersGallery } from "../../views/Entries/manageEntry";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { AlertActionTypes } from "../Alert/types";
import { useAlert } from "../Alert/Alerts";

const useHideMedia = (defaultHidden: boolean) => {
	const [hideInWinnerGallery, setHideInWinnerGallery] = useState(defaultHidden);
	const { addNewAlert } = useAlert();

	const toggleMediaForWinnersGallery = (mediaId: string) => {
		updateHideMediaForWinnersGallery(mediaId, !hideInWinnerGallery)
			.then((res) => {
				if (res.status === 200) {
					addNewAlert({
						type: "success",
						message: `Media will be ${
							!hideInWinnerGallery ? "hidden" : "shown"
						} in Winner's Gallery`,
					});

					setHideInWinnerGallery(!hideInWinnerGallery);
				} else {
					addNewAlert({
						type: "error",
						message: "Problem updating Hide Media",
					});
					setHideInWinnerGallery(hideInWinnerGallery);
				}
			})
			.catch((err) => {
				addNewAlert({ type: "error", message: "Problem updating Hide Media" });
				setHideInWinnerGallery(hideInWinnerGallery);
			});
	};

	return { hideInWinnerGallery, toggleMediaForWinnersGallery };
};

const HideMediaButton = (props: HideMediaProps) => {
	const theme = useTheme();
	const { hideInWinnerGallery, toggleMediaForWinnersGallery } = useHideMedia(
		props.hideForWinnersGallery !== undefined
			? props.hideForWinnersGallery
			: false
	);

	return (
		<Icon
			className={props.className}
			icon={hideInWinnerGallery ? "hide" : "eye"}
			color={hideInWinnerGallery ? theme.colorCopyLight : theme.colorPrimary}
			height="25px"
			width="25px"
			onClick={() => toggleMediaForWinnersGallery(props.mediaId)}
		/>
	);
};

export default HideMediaButton;

interface HideMediaProps {
	className?: string;
	hideForWinnersGallery?: boolean;
	mediaId: string;
}
