import styled from "styled-components";
import { CardContainer } from "../DashboardCard/DashboardCard";

const DashboardBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow-x: clip;

  ${CardContainer} {
    flex: 1;
  }
`;

export default DashboardBody;
