export type IStoredSearch =
  | "entrySearch"
  | "orderSearch"
  | "companySearch"
  | "userSearch"
  | "companyCreditsSearch"
  | "individualCreditsSearch"
  | "selectedExportColumns"
  | "selectedCompanyCreditExportColumns"
  | "selectedIndividualCreditExportColumns"
  | "assignmentSearch"
  | "jurorSearch"
  | "mediaSearch";

export const getStoredValue = (storedSearch: IStoredSearch) => {
  const savedStoredSearch = localStorage.getItem(storedSearch);
  if (savedStoredSearch) {
    return JSON.parse(savedStoredSearch);
  }
};

export const setStoredValue = (searchTab: IStoredSearch, values: any) => {
  try {
    localStorage.setItem(searchTab, JSON.stringify(values));
  } catch (error) {
    console.error(`Error saving to localStorage: ${searchTab}`);
  }
};
