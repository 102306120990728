import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { FormikProvider, useFormik } from "formik";
import Modal, { ModalCard } from "../../../components/Modal/Modal";
import Icon from "../../../components/Icon/Icon";
import CheckboxField from "../../../components/FormFields/CheckboxField";
import Button from "../../../components/Button/Button";
import axios from "axios";
import { urlEntries } from "../../../endpoints";
import {
	IStoredSearch,
	setStoredValue,
} from "../../../components/LocalStorageStorage/LocalStorageStore";

export const TabModalCard = styled(ModalCard)`
	overflow-y: auto;
	div[role="tabpanel"] {
		overflow: auto;
	}
`;

const EntryExportColumnsModal = (props: EntryExportColumnsModalProps) => {
	const theme = useTheme();
	const [exportColumns, setExportColumns] = useState<EntryExportColumn[]>([]);
	const [exportGroups, setExportGroups] = useState<EntryExportGroup[]>([]);
	const [selectedColumns, setSelectedColumns] = useState(
		props.selectedExportColumns || []
	);

	useEffect(() => {
		loadEntryExportColumns();
	}, []);

	async function loadEntryExportColumns() {
		try {
			const response = await axios.get(props.getExportGroupsEndpoint);

			if (response.status === 200) {
				console.log("export groups", response.data);
				setExportGroups(response.data);

				if (!localStorage.getItem(props.localStorageKey)) {
					const allColumns = response.data.flatMap(
						(group: EntryExportGroup) => group.columns
					);
					if (props.localStorageKey) {
						setStoredValue(props.localStorageKey, allColumns);
					}

					setSelectedColumns(allColumns);
				}
			}
		} catch (error: any) {}
	}

	function toggleColumn(item: EntryExportColumn) {
		setSelectedColumns((current) => {
			return selectedColumns.findIndex((s) => s.column == item.column) > -1
				? selectedColumns.filter((c) => c.column != item.column)
				: [...selectedColumns, item];
		});
	}

	const formikProps = useFormik({
		initialValues: {},
		onSubmit: async (value) => {},
	});

	return (
		<FormikProvider value={formikProps}>
			<Modal show={props.show}>
				<TabModalCard
					title="Export Columns"
					headerIcons={
						<Icon
							icon="close"
							color={theme.colorPrimary}
							onClick={props.onClickHide}
							width="35px"
							height="35px"
						/>
					}
					iconColor={theme.colorPrimary}
				>
					<div className="p-4">
						{exportGroups &&
							exportGroups.map((group, groupIndex) => (
								<div key={groupIndex}>
									<h3 className="mb-1">{group.group}</h3>
									<div className="grid grid-cols-3 mb-10">
										{group.columns.map((item, index) => {
											const checkBoxLabel =
												item.displayName === "Transaction Type"
													? "Payment Method"
													: item.displayName;
											return (
												<CheckboxField
													key={index}
													className="checkbox-field m-3"
													name={`multiSelect-${item.column}`}
													checked={
														selectedColumns.length > 0 &&
														selectedColumns.findIndex(
															(c) => c.column == item.column
														) > -1
													}
													onChange={() => toggleColumn(item)}
													placeholder={checkBoxLabel}
													nonFormik
												/>
											);
										})}
									</div>
								</div>
							))}
						<div className="flex justify-between">
							<div className="flex justify-normal space-x-6">
								<Button
									onClick={() => {
										const allColumns = exportGroups.flatMap(
											(group) => group.columns
										);
										setSelectedColumns(allColumns);
									}}
								>
									Select all
								</Button>
								<Button onClick={() => setSelectedColumns([])}>
									Clear selection
								</Button>
							</div>
							<div>
								<Button
									onClick={() => {
										props.onSelectionChange(selectedColumns);
										props.onClickHide();
									}}
									disabled={selectedColumns.length < 1}
								>
									Export
								</Button>
							</div>
						</div>
					</div>
				</TabModalCard>
			</Modal>
		</FormikProvider>
	);
};

export default EntryExportColumnsModal;

export interface EntryExportColumn {
	column: string;
	displayName: string;
	group: string;
}

export interface EntryExportGroup {
	group: string;
	columns: EntryExportColumn[];
}

export interface EntryExportColumnsModalProps {
	show: boolean;
	onClickHide(): void;
	selectedExportColumns: EntryExportColumn[];
	onSelectionChange(columns: EntryExportColumn[]): void;
	localStorageKey: IStoredSearch;
	getExportGroupsEndpoint: string;
}
