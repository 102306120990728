import { ReactElement } from "react";
import styled, { css } from "styled-components";
import { urlUsers } from "../../endpoints";
import { PatternBG } from "../../globalStyles";
import { DynamicPadding } from "../../globalStyles";
import FileUpload from "../FormFields/FileUpload";
import { MediaType } from "../../views/MediaLibrary/mediaLibrary.model.d";
import { defaultBoxShadow } from "../../assetsConfig";

export const UserPageContainer = styled.div<{
	userPage?: boolean;
}>`
	${(p) =>
		p.userPage
			? `
    padding: 4.125rem max(3rem, calc((100vw - 1920px) / 2));
  `
			: `
   padding: 4.125rem max(3rem, calc((100vw - 2560px) / 2));
  `}

	${PatternBG};
	flex: 1;

	@media (max-width: 520px) {
		padding: 1.125rem max(1rem, calc((100vw - 1920px) / 2));
		${PatternBG};
	}
`;
export const LibraryContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	height: 100%;

	.fileUploadBg {
		background: ${({ theme }) => theme.colorBackgroundLightLight};
	}
`;

export const UserPageHeader = styled.div`
	padding: 2rem 4rem;
	background: ${({ theme }) => theme.colorBackgroundDarkDark};

	.header {
		color: ${({ theme }) => theme.colorCopyLightLight};
		// playfair-500
		font-family: ${({ theme }) => theme.fontBrand};
		font-weight: 500;
		letter-spacing: 0.15px;
	}
`;

const ProfileTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 1.875rem;

	.heading {
		font-family: "Playfair Display", serif;
		margin-bottom: 0.625rem;
	}
`;

const ProfileSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex: 1;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto;
	${DynamicPadding};
	background: ${({ theme }) => theme.colorBackgroundLight};
	width: 100%;

	img {
		width: 100px;
		height: 100px;
	}

	@media only screen and (min-width: ${({ theme }) => theme.md}) {
		img {
			width: 150px;
			height: 150px;
		}
	}
`;

export const UserProfileBody = styled.div`
	box-shadow: inset ${defaultBoxShadow};
	${DynamicPadding};
	${PatternBG};

	&.has-preview-card {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 2.5rem;
	}

	@media only screen and (max-width: ${({ theme }) => theme.lg}) {
		&.has-preview-card {
			grid-template-columns: repeat(1, minmax(0, 1fr));
			gap: 5rem;
			.entryPreviewMd {
				top: 0;
				grid-column-start: 1;
				grid-row-start: 1;
			}
		}
	}
`;

export const UserProfileHeader = (props: UserProfileHeaderProps) => {
	return (
		<ProfileSection>
			<div className="flex">
				{(props.updateImage && props.setValue && (
					<FileUpload
						onChange={(files) => {
							if (!files || files.length === 0) {
								return;
							}
							const formData = new FormData();
							formData.append("FormFile", files[0].file);
							formData.append("FileName", files[0].file.name);
							props.updateImage && props.updateImage(formData, props.setValue!);
						}}
						{...(props.img && {
							value: {
								fileName: "ProfilePicture",
								fileType: "image",
								src: props.img,
								initial: true,
							},
						})}
						id="FileUpload-UserProfileHeader"
						fill="white"
						height="150px"
						imgOnly
						allowedMediaType={MediaType.Image}
					/>
				)) || <img src={props.img} alt="Profile Picture" />}
				<ProfileTextContainer>
					{props.heading && <h1 className="heading">{props.heading}</h1>}
					{props.subheading && <h2>{props.subheading}</h2>}
				</ProfileTextContainer>
			</div>
			{props.contentRight && (
				<div className="ml-auto">{props.contentRight}</div>
			)}
		</ProfileSection>
	);
};

interface UserProfileHeaderProps {
	img?: string;
	heading?: string;
	subheading?: string;
	updateImage?(value: FormData, setValue: (arg0: string) => void): void;
	setValue?: (arg0: string) => void;
	contentRight?: ReactElement | HTMLElement;
}
