import { useFormikContext, FieldArray } from "formik";
import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import Button from "../../../components/Button/Button";
import DropdownField, {
  DropdownOptions,
} from "../../../components/FormFields/DropdownField";
import TZDatePickerField from "../../../components/FormFields/TZDatePickerField";
import Icon from "../../../components/Icon/Icon";
import { CompanyModel } from "../../Company/company.model";
import { getRootProgramOptions } from "../Program/manageProgram";
import { getClaims } from "../../../components/Auth/handleJWT";

const AdjustmentFields = (props: AdjustmentProps) => {
  const theme = useTheme();
  const [rootProgramIDs, setRootProgramIDs] = useState<DropdownOptions[]>([]);
  const { values } = useFormikContext<CompanyModel>();
	useEffect(() => {
		// get root program dropdown options
		getRootProgramOptions().then((res) => {
			// console.log("root programs", res);
			setRootProgramIDs(res.data); 
		});
	}, []);
  useEffect(() => {
    // get root program dropdown options
    getRootProgramOptions().then((res) => {
      // console.log("root programs", res);
      setRootProgramIDs(res.data);
    });
  }, []);

	return (
		<FieldArray name="adjustments">
			{({ push, remove }) => (
				<>
					{values.adjustments &&
						values.adjustments.map((adjustment, index) => (
							<div
								className="flex w-full flex-1 gap-[1.5rem] items-center"
								key={index}
							>
								<div className="w-full">
									<DropdownField
										name={`adjustments.${index}.programId`}
										options={rootProgramIDs}
										placeholder="Program"
										value={adjustment.programId}
									/>
								</div>
								<div className="w-full">
									<TZDatePickerField
										className="!max-w-none"
										name={`adjustments.${index}.adjustmentDate`}
										placeholder="Adjustment Date"
										value={adjustment.adjustmentDate}
										showTimeSelect
									/>
								</div>
								<div className="w-full">
									<TZDatePickerField
										className="!max-w-none"
										name={`adjustments.${index}.expirationDate`}
										placeholder="Expiration Date"
										value={adjustment.expirationDate}
										showTimeSelect
									/>
								</div>
								<Icon
									icon="close"
									color={theme.colorPrimary}
									onClick={() => remove(index)}
								/>
							</div>
						))}
					<div className="flex flex-row gap-[1.5rem]">
						<Button
							type="button"
							className="w-[200px] button-light"
							icon="plus"
							iconColor={theme.colorActivation}
							onClick={() => 
								push({
									id: 0,
									adjustmentDate: undefined,
									expirationDate: undefined,
									programId: undefined,
									companyId: props.companyId,
								})
							}
						>
							Add Adjustment
						</Button>
					</div>
				</>
			)}
		</FieldArray>
	);

};

export default AdjustmentFields;

interface AdjustmentProps {
  companyId: number;
}
