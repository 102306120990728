import axios from "axios";
import { getClaims } from "../../components/Auth/handleJWT";
import { urlMedia } from "../../endpoints";
import {
  MediaCreditEntryInfoModel,
  MediaItem,
  MediaType,
} from "./mediaLibrary.model.d";
import VideoPlaceholder from "../../assets/placeholders/video-landscape.png";
import AudioPlaceholder from "../../assets/placeholders/audio-landscape.png";
import ImagePlaceholder from "../../assets/placeholders/image-landscape.png";
import DocumentPlaceholder from "../../assets/placeholders/document-landscape.png";
import ImageComponent from "../../components/Image/Image";
import {
  ImgOverlay,
  VIDEO_EXTENSIONS,
  isVideoAvailable,
} from "../../components/MediaEnlargeModal/MediaEnlargeModal";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Icon from "../../components/Icon/Icon";
import { ThemeProps } from "styled-components";
import Button from "../../components/Button/Button";
import Placeholder from "../../assets/placeholders/placeholder.png";

export async function updateMediaFileName(
  newFileName: string,
  mediaId: string
) {}

export async function deleteMediaItem(
  value: MediaItem,
  linkedEntries: MediaCreditEntryInfoModel[]
) {
  if (!linkedEntries || linkedEntries.length > 0) {
    throw new Error("Media linked to active entries can't be deleted.");
  }

  return await axios.delete(
    `${urlMedia}/DeleteMedia/${value.id}${"?companyId=" + value.companyId}`
  );
}

export async function EditMediaItem(value: MediaItem) {
  return await axios.put(
    `${urlMedia}/${value.id}${"?companyId=" + value.companyId}`,
    value
  );
}

export async function getMedia(companyId?: number) {
  return await axios.get<MediaItem[]>(
    `${urlMedia}${companyId ? "?companyId=" + companyId : ""}`
  );
}

export async function getMediaWithInfo(companyId?: number) {
  return await axios.get<MediaItem[]>(
    `${urlMedia}/GetMediaLibrary${companyId ? "?companyId=" + companyId : ""}`
  );
}

export async function getLinkedEntries(companyId: number, mediaId: string) {
  return await axios.get<MediaCreditEntryInfoModel[]>(
    `${urlMedia}/GetLinkedEntries/${mediaId}?companyId=${companyId}`
  );
}

export async function checkImage(url: string) {
  const res = await fetch(url);
  const buff = await res.blob();

  return buff.type.startsWith("image/");
}

export enum ThumbnailSize {
  SMALL,
  MEDIUM,
  LARGE,
  X_LARGE,
}

export function getMediaSrc(
  mediaObj: MediaItem,
  size: ThumbnailSize = ThumbnailSize.SMALL
) {
  if (mediaObj.type === MediaType.Image) {
    let imagePath = mediaObj.path.replace(/\.[^/.]+$/, "");
    let imageExt = mediaObj.path.split(".").pop();
    let sizeSuffix = "";
    switch (size) {
      case ThumbnailSize.SMALL:
        sizeSuffix = "-small";
        break;
      case ThumbnailSize.MEDIUM:
        sizeSuffix = "-medium";
        break;
      case ThumbnailSize.LARGE:
        sizeSuffix = "-large";
        break;
      case ThumbnailSize.X_LARGE:
        sizeSuffix = "-xlarge";
        break;
      default:
        sizeSuffix = "-small";
        break;
    }
    const fullImgSrc =
      process.env.REACT_APP_S3_RESIZED_HOST +
      imagePath +
      sizeSuffix +
      "." +
      imageExt +
      ".webp";

    return fullImgSrc;
  } else if (mediaObj.type === MediaType.Video) {
    // resized video should be mp4
    if (mediaObj.videoResizePath) {
      const matchVideoExtension = /([^.]+$)/g;
      return (
        process.env.REACT_APP_S3_RESIZED_HOST +
        mediaObj.path.replaceAll(matchVideoExtension, "mp4")
      );
    } else return process.env.REACT_APP_S3_HOST + mediaObj.path;
  } else {
    return process.env.REACT_APP_S3_HOST + mediaObj.path;
  }
}

const getVideoThumbnailSrcAdminEntryEdit = async (mediaPath: string) => {
  // video thumbnail
  const matchVideoExtension = /media\/(.*?)(?=\.)/g;
  const matchFileFolder = /.+?(?=\/media)/g;

  const uploadedFileName = mediaPath.match(matchVideoExtension);
  const fileFolder = mediaPath.match(matchFileFolder);

  if (!uploadedFileName || !fileFolder) {
    // Invalid mediaPath, return VideoPlaceholder
    return VideoPlaceholder;
  }

  const thumbnail =
    process.env.REACT_APP_S3_RESIZED_HOST! +
    fileFolder +
    "/" +
    uploadedFileName +
    "-thumbnail.0000001.jpg";

  try {
    // Attempt to fetch the video to check if it's broken
    const response = await fetch(thumbnail);

    if (!response.ok) {
      // Video link is broken, return VideoPlaceholder
      return VideoPlaceholder;
    }

    // Video link is valid, return the thumbnail
    return thumbnail;
  } catch (error) {
    // An error occurred during the fetch, return VideoPlaceholder
    console.error("Error fetching video:", error);
    return VideoPlaceholder;
  }
};

export function getThumbnailOrPlaceholder(
  mediaType: MediaType,
  mediaPath: string
) {
  switch (mediaType) {
    case MediaType.Image:
      return getThumbnailSrc(mediaPath, ThumbnailSize.SMALL);
    case MediaType.Video:
      return getVideoThumbnailSrc(mediaPath);
    case MediaType.Audio:
      return AudioPlaceholder;
    case MediaType.Document:
      return getPdfThumbnail(mediaPath);
    default:
      return Placeholder; // A generic placeholder if the type is not recognized
  }
}

const getVideoThumbnailSrc = (mediaPath: string) => {
  // video thumbnail
  const matchVideoExtension = /media\/(.*?)(?=\.)/g;
  const matchFileFolder = /.+?(?=\/media)/g;

  const uploadedFileName = mediaPath.match(matchVideoExtension);
  const fileFolder = mediaPath.match(matchFileFolder);

  const thumbnail =
    process.env.REACT_APP_S3_RESIZED_HOST! +
    fileFolder +
    "/" +
    uploadedFileName +
    "-thumbnail.0000001.jpg";
  if (uploadedFileName && fileFolder) {
    return thumbnail;
  } else return VideoPlaceholder;
};

export function getThumbnailSrcNested(
  mediaObj: string | MediaItem,
  size: ThumbnailSize = ThumbnailSize.SMALL
) {
  // image thumbnail
  if (
    typeof mediaObj === "string" ||
    (mediaObj as MediaItem).type === MediaType.Image
  ) {
    const isMediaObj =
      typeof mediaObj !== "string" &&
      (mediaObj as MediaItem).type === MediaType.Image;

    const mediaPath = isMediaObj ? mediaObj.path : (mediaObj as string);

    // if (media.type === MediaType.Image) mediaObj = (mediaObj as MediaItem).path;
    let imagePath = mediaPath.replace(/\.[^/.]+$/, "");
    let imageExt = mediaPath.split(".").pop();
    let sizeSuffix = "";
    switch (size) {
      case ThumbnailSize.SMALL:
        sizeSuffix = "-small";
        break;
      case ThumbnailSize.MEDIUM:
        sizeSuffix = "-medium";
        break;
      case ThumbnailSize.LARGE:
        sizeSuffix = "-large";
        break;
      case ThumbnailSize.X_LARGE:
        sizeSuffix = "-xlarge";
        break;
      default:
        sizeSuffix = "-small";
        break;
    }

    return (
      process.env.REACT_APP_S3_RESIZED_HOST +
      imagePath +
      sizeSuffix +
      "." +
      imageExt +
      ".webp"
    );
  } else {
    const media = mediaObj as MediaItem;

    if (media.type === MediaType.Video) {
      return getVideoThumbnailSrcAdminEntryEdit(mediaObj.path);
    } else if (media.type === MediaType.Audio) {
      return AudioPlaceholder;
    } else if (media.type === MediaType.Document) {
      return DocumentPlaceholder;
    } else return mediaObj.path;
  }
}

export function getPdfThumbnail(path: string, sizeSuffix: string = "-xlarge") {
  let imagePath = path.replace(/\.[^/.]+$/, "");

  return (
    process.env.REACT_APP_S3_RESIZED_HOST + imagePath + sizeSuffix + "." + "png"
  );
}

export function getThumbnailSrc(
  mediaObj: string | MediaItem,
  size: ThumbnailSize = ThumbnailSize.SMALL,
  isNestedCheck?: boolean
) {
  let sizeSuffix = "";
  switch (size) {
    case ThumbnailSize.SMALL:
      sizeSuffix = "-small";
      break;
    case ThumbnailSize.MEDIUM:
      sizeSuffix = "-medium";
      break;
    case ThumbnailSize.LARGE:
      sizeSuffix = "-large";
      break;
    case ThumbnailSize.X_LARGE:
      sizeSuffix = "-xlarge";
      break;
    default:
      sizeSuffix = "-small";
      break;
  }

  // image thumbnail
  if (
    typeof mediaObj === "string" ||
    (mediaObj as MediaItem).type === MediaType.Image
  ) {
    const isMediaObj =
      typeof mediaObj !== "string" &&
      (mediaObj as MediaItem).type === MediaType.Image;

    const mediaPath = isMediaObj ? mediaObj.path : (mediaObj as string);

    // if (media.type === MediaType.Image) mediaObj = (mediaObj as MediaItem).path;
    let imagePath = mediaPath.replace(/\.[^/.]+$/, "");
    let imageExt = mediaPath.split(".").pop();

    return (
      process.env.REACT_APP_S3_RESIZED_HOST +
      imagePath +
      sizeSuffix +
      "." +
      imageExt +
      ".webp"
    );
  } else {
    const media = mediaObj as MediaItem;

    if (media.type === MediaType.Video) {
      return getVideoThumbnailSrc(mediaObj.path);
    } else if (media.type === MediaType.Audio) {
      return AudioPlaceholder;
    } else if (media.type === MediaType.Document) {
      return getPdfThumbnail(mediaObj.path, sizeSuffix);
    } else return mediaObj.path;
  }
}

export function getNonWebpThumbnailSrc(mediaObj: string) {
  let imagePath = mediaObj.replace(/\.[^/.]+$/, "");
  let imageExt = mediaObj.split(".").pop();
  return (
    process.env.REACT_APP_S3_RESIZED_HOST + imagePath + "-small." + imageExt
  );
}

// check S3 bucket for uploaded image
export const checkS3ForImg = (
  mediaObj: MediaItem,
  thumbnailSize: ThumbnailSize,
  mediaType?: MediaType
) => {
  return new Promise((resolve, reject) => {
    // rejects the promise if the mediaItem is not an image or video
    if (mediaObj.type !== MediaType.Image && mediaObj.type !== MediaType.Video)
      reject("only images and videos have thumbnails");

    const initialImg = new Image();
    initialImg.src = mediaObj.path;

    // check for newly uploaded image in S3 url
    initialImg.onerror = () => {
      let checkForImgCount = 10;
      const interval = 1000;
      const newImg = new Image();
      let isImgLoaded = false;
      let i = 0;

      // check image
      const checkImg = setInterval(function () {
        if (i < checkForImgCount && !isImgLoaded) {
          if (mediaType !== undefined && mediaType === MediaType.Video) {
            newImg.src = getVideoThumbnailSrc(mediaObj.path) || "";
          } else {
            newImg.src = getThumbnailSrc(mediaObj.path, thumbnailSize) || "";
          }

          //   console.log("new img src", newImg.src);
          i++;
        } else {
          clearInterval(checkImg);
          reject(
            `TImeout: Checked S3 bucket for image ${checkForImgCount} times every ${
              interval / 1000
            } second. S3 taking too long to generate image thumbnail.`
          );
        }
      }, interval);

      newImg.onload = () => {
        isImgLoaded = true;
        resolve(mediaObj);
      };
    };
  }).catch((e) => console.log("checkS3ForImg err", e));
};

export const getPlaceholder = (mediaType: MediaType) => {
  if (mediaType === MediaType.Image) {
    return ImagePlaceholder;
  } else if (mediaType === MediaType.Video) {
    return VideoPlaceholder;
  } else if (mediaType === MediaType.Audio) {
    return AudioPlaceholder;
  } else if (mediaType === MediaType.Document) {
    return DocumentPlaceholder;
  } else {
    return "";
  }
};

export const getPlaceholderFromFile = (fileType: string) => {
  let placeholder = "";
  switch (fileType) {
    case "video/mp4":
      placeholder = VideoPlaceholder;
      break;
    case "video/quicktime":
      placeholder = VideoPlaceholder;
      break;
    case "video":
      placeholder = VideoPlaceholder;
      break;
    case "audio/mpeg":
      placeholder = AudioPlaceholder;
      break;
    case "image/jpeg":
    case "image/png":
      placeholder = ImagePlaceholder;
      break;
    case "application/pdf":
      placeholder = DocumentPlaceholder;
      break;
  }

  return placeholder;
};

export const MediaContainer = styled.div<{
  height?: string;
  objectFit?: string;
}>`
  height: ${(p) => (p.height ? p.height : "500px")};

  img {
    width: 100%;
    height: 100%;
    object-fit: ${(p) => (p.objectFit ? p.objectFit : "cover")};
    aspect-ratio: 16/ 9;
  }
`;

export const RenderMediaPreview = (props: {
  mediaItem: MediaItem;
  height?: string;
  onClickImg?: () => void;
  theme?: any;
  hideOverlay?: boolean;
}) => {
  switch (props.mediaItem.type) {
    case MediaType.Image:
      return (
        <ImgOverlay show={!props.hideOverlay}>
          <MediaContainer onClick={props.onClickImg} height={props.height}>
            <ImageComponent
              key={props.mediaItem.id}
              src={getThumbnailSrc(props.mediaItem.path, ThumbnailSize.X_LARGE)}
              placeholder={ImagePlaceholder}
              alt={props.mediaItem.fileName}
              lazy
            />
          </MediaContainer>
          <Icon
            className="expand-icon"
            icon="expand-arrows"
            color={props.theme?.colorPrimary}
            width="25px"
            height="25px"
            onClick={() => props.onClickImg && props.onClickImg()}
          />
        </ImgOverlay>
      );
    case MediaType.Document:
      return (
        <ImgOverlay show>
          <ImageComponent
            className="mx-auto h-[400px]"
            key={props.mediaItem.id}
            src={getThumbnailSrc(props.mediaItem)}
            placeholder={DocumentPlaceholder}
            alt={props.mediaItem.fileName}
            lazy
          />
          <Button
            className="pdf-link"
            onClick={() => window.open(getMediaSrc(props.mediaItem), "_blank")}
          >
            Open PDF In New Tab
          </Button>
        </ImgOverlay>
      );

    case MediaType.Audio:
      return (
        <div className="m-auto">
          <ImageComponent
            key={props.mediaItem.id}
            src={AudioPlaceholder}
            placeholder={AudioPlaceholder}
            alt="Audio Placeholder"
            lazy
          />
          <audio
            className="m-auto mt-[1rem] w-full"
            controls
            src={getMediaSrc(props.mediaItem)}
          />
        </div>
      );
    case MediaType.Video:
      return isVideoAvailable(props.mediaItem.path) ? (
        <video key={props.mediaItem.id} controls width="100%" height="100%">
          {VIDEO_EXTENSIONS.map((extension) => (
            <source
              key={props.mediaItem.id}
              src={getMediaSrc(props.mediaItem)}
              type={`video/${extension}`}
            />
          ))}
          Your browser does not support the video tag.
        </video>
      ) : (
        <div className="flex justify-center items-center h-[375px]">
          An error occurred with the video url. The video preview is
          unavailable.
        </div>
      );
    default:
      return <></>;
  }
};
