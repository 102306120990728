import styled from "styled-components";

export const ProgressInfo = styled.div<{ background?: string }>`
	display: flex;
	justify-content: space-between;
	/* padding: 1rem 0 0.5rem 0; */
	background: ${(p) =>
		p.background ? p.background : p.theme.colorBackgroundLightLight};
`;

export const RoundInfo = styled.div<{ background?: string }>`
	display: flex;
	justify-content: flex-start;
	padding-left: 2rem;
	background: ${(p) =>
		p.background ? p.background : p.theme.colorBackgroundLightLight};
`;

export const ProgressContainer = styled.div<{ background?: string }>`
	display: flex;
	flex-direction: column;
	padding-right: 1.5rem;
	background: ${(p) =>
		p.background ? p.background : p.theme.colorBackgroundLightLight};
`;

export const RoundNumber = styled.span`
	color: ${({ theme }) => theme.colorCopyLight};
	font-size: 14px;
	padding-left: 1rem;
`;

export const ProgressIndicator = styled.div`
	height: 20px;
	width: 100%;
	background: ${({ theme }) => theme.colorProgressBackground};
	width: 100%;
`;

export const ProgressFill = styled.div.attrs(
	(props: { size: number }) => props
)`
	height: 100%;
	width: ${(props) => props.size}%;
	background-color: ${({ theme }) => theme.colorActivation};
`;

const Progressbar = (props: ProgressbarProps) => {
	return (
		<ProgressContainer
			className={props.className ? props.className : ""}
			background={props.background}
		>
			<ProgressInfo background={props.background}>
				<b>{props.completionPercent}% Complete</b>
				<RoundInfo background={props.background}>
					<RoundNumber>
						Round {props.currentRound}/{props.totalRounds}
					</RoundNumber>
				</RoundInfo>
			</ProgressInfo>
			<ProgressIndicator>
				<ProgressFill
					className="progress-fill"
					size={props.completionPercent}
				></ProgressFill>
			</ProgressIndicator>
		</ProgressContainer>
	);
};

export default Progressbar;

interface ProgressbarProps {
	//children: React.ReactNode;
	className?: string;
	completionPercent: number;
	// currentOrdinal: number;
	// totalOrdinal: number;
	currentRound: number;
	totalRounds: number;
	background?: string;
}
