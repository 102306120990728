import styled, { useTheme } from "styled-components";
import {
  NavTabs,
  NavTab,
  NavTabList,
  NavTabPanel,
} from "../../../components/NavTabs/NavTabs";
import {
  CardHeader,
  CardBody,
  CardContainer,
} from "../../../components/DashboardCard/DashboardCard";
import TextField from "../../../components/FormFields/TextField";
import FileUpload from "../../../components/FormFields/FileUpload";
import StickyCard, {
  StickyHeaderBar,
  StickyCardBody,
} from "../../../components/StickyCard/StickyCard";
import { FormikProvider, useFormik } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import DropdownField from "../../../components/FormFields/DropdownField";
import CheckboxField from "../../../components/FormFields/CheckboxField";
import Button from "../../../components/Button/Button";
import { SortContainer } from "../../../components/Sort/Sort";
import Sort from "../../../components/Sort/Sort";
import { ProgramHierarchyContainer } from "./ProgramRootAdminNav";
import Modal, { ModalCard } from "../../../components/Modal/Modal";
import { ModalContainer } from "../../../components/Modal/Modal";
import "../../../../node_modules/react-simple-tree-menu/dist/main.css";
import { WarningModal } from "../../../components/Modal/Modal";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  deleteProgram,
  getActiveAwards,
  getActiveSeasons,
  getProgramById,
  getProgramConfigById,
  markEntriesAsAbandoned,
  PostProgram,
  updateProgram,
  setJudgingResultsReleased,
  sortProgramFields,
  ProgramFieldType,
} from "./manageProgram";
import {
  CreditFieldTemplate,
  DateFieldTemplate,
  LinkFieldTemplate,
  ListFieldTemplate,
  MediaFieldTemplate,
  PhysicalComponentFieldTemplate,
  ProgramModel,
  ProgramCreditField,
  TextFieldTemplate,
  ProgramDateField,
  ProgramListField,
  ProgramTextField,
  ProgramLinkField,
  ProgramMediaField,
  ProgramPhysicalComponentField,
  ProgramDeadline,
} from "./ProgramInterfaces";
import { SeasonModel } from "../Seasons/Seasons";
import { AwardModel } from "../Awards/Awards";
import RichTextField from "../../../components/FormFields/RichTextField";
import * as Yup from "yup";
import {
  createCreditFieldTemplate,
  createDateFieldTemplate,
  createLinkFieldTemplate,
  createListFieldTemplate,
  createMediaFieldTemplate,
  createPhysicalComponentFieldTemplate,
  createTextFieldTemplate,
  FieldTemplateNames,
  getActiveCreditFieldTemplates,
  getActiveDateFieldTemplates,
  getActiveLinkFieldTemplates,
  getActiveListFieldTemplates,
  getActiveMediaFieldTemplates,
  getActivePhysicalComponentFieldTemplates,
  getActiveTextFieldTemplates,
  deleteProgramTextField,
  deleteProgramCreditField,
  deleteProgramDateField,
  deleteProgramListField,
  deleteProgramLinkField,
  deleteProgramMediaField,
  deleteProgramPhysicalComponentField,
} from "./manageFieldTemplates";
import { FieldTemplatesFormDataType } from "../../../data/FieldTemplatesFormData";
import DateFieldForm from "./FieldTemplateForms/DateFieldForm";
import TextFieldForm from "./FieldTemplateForms/TextFieldTemplate";
import MediaFieldForm from "./FieldTemplateForms/MediaFieldForm";
import CreditFieldForm from "./FieldTemplateForms/CreditFieldForm";
import ListFieldForm from "./FieldTemplateForms/ListFieldForm";
import LinkFieldForm from "./FieldTemplateForms/LinkFieldForm";
import PhysicalComponentFieldForm from "./FieldTemplateForms/PhysicalComponentFieldForm";
import { LoadingContext } from "../../../App";
import DeadlineFields from "./DeadlineFields";
import { programValidationSchema } from "./NewProgram";
import ToggleSwitchField from "../../../components/FormFields/ToggleSwitchField";
import Icon from "../../../components/Icon/Icon";
import { MediaType } from "../../MediaLibrary/mediaLibrary.model.d";
import TZDatePickerField from "../../../components/FormFields/TZDatePickerField";
import CopyProgramModal from "./CopyProgramModal";
import config from "../../../config";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import { ThumbnailPopup } from "../../../components/InfoPopup/InfoPopup";
import TextSize from "../../../components/TextSize/TextSize";
import { useAlert } from "../../../components/Alert/Alerts";

export const ConfigNavTabs = styled(NavTabList)`
  height: max-content;
  background: ${({ theme }) => theme.colorBackgroundDarkDark};
  padding-left: 3.09375rem;
  padding-right: 3.09375rem;
`;

export const TabBody = styled(CardBody)`
  display: grid;
  gap: 1.8125rem;
  max-width: 950px;
`;

const ProgramFieldCardContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 19px;
  /* background: ${({ theme }) => theme.colorBackgroundLight}; */
  box-shadow: 0 2px 2px 0 ${({ theme }) => theme.colorBoxShadow};
  /* margin-bottom: 30px; */
  max-height: 112px;

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const ProgramTitleName = styled.div`
  font-size: 2rem;
  font-weight: bold;
`;

const BorderStickyCardBody = styled(StickyCardBody)`
  display: flex;
  /* justify-content: space-between; */
  border-left: 4px solid ${({ theme }) => theme.colorActivation};
  padding: 3.1875rem 3.625rem;

  .active {
    color: ${({ theme }) => theme.colorActivation};
    font-weight: bold;
  }

  .inactive {
    color: ${({ theme }) => theme.colorCopyLight};
  }
`;

const Level = styled.div`
  position: relative;
  overflow: hidden;
  /* max-height: 112px; */
  padding-left: 3.09375rem;
  // add sub-hierarchy tree branches
  .horizontal-branch {
    content: "";
    position: absolute;
    left: 0;
    top: 41px;
    width: 3.09375rem;
    border-top: 1px dashed ${({ theme }) => theme.colorCopyLight};
  }

  .vertical-branch {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: calc(30px + 82px);
    border-left: 1px dashed ${({ theme }) => theme.colorCopyLight};

    &.last-branch {
      height: 41px;
    }
  }
`;

const HierarchyHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1.28125rem;
  padding: 1.25rem 0 1.8125rem 3.09375rem;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 1px dashed ${({ theme }) => theme.colorCopyLight};
  }
`;

const SquareCounter = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 45px;
  min-height: 45px;
  border: 2px solid ${({ theme }) => theme.colorCopyLight};
  font-weight: ${({ theme }) => theme.fontSemiBold};
  padding: 0.25rem;
  aspect-ratio: 1 / 1;
`;

const ProgramFieldCard = (props: ProgramFieldCardProps) => {
  const theme = useTheme();
  return (
    <ProgramFieldCardContainer
      className={props.className ? props.className : ""}
    >
      <div className="w-[165px]">
        <Button
          disabled={props.disableEdit ? true : false}
          className="button-gold"
          iconLeft="edit"
          iconColor={theme.colorPrimary}
          onClick={() => {
            props.onClickEditField && props.onClickEditField();
          }}
        >
          Edit
        </Button>
      </div>

      {props.weight !== undefined && props.weight !== null ? (
        <ThumbnailPopup
          className="mr-[1.3125rem]"
          alt="Field Weight"
          top="110%"
        >
          <TextSize
            characterCount={String(props.weight).length}
            breakpoints={[
              {
                characterCount: 4,
                fontSize: 14,
              },
              {
                characterCount: 5,
                fontSize: 12,
              },
            ]}
          >
            <SquareCounter>{props.weight}</SquareCounter>
          </TextSize>
        </ThumbnailPopup>
      ) : (
        <></>
      )}

      <p className="w-[20%]">{props.fieldName}</p>
      <div className="flex items-center gap-[2rem] ml-auto">
        {props.inheritedProgramId && (
          <Link to={`/program/configure/${props.inheritedProgramId}`}>
            Jump to Program
          </Link>
        )}

        <Button
          disabled={props.disableDelete ? true : false}
          className="button-light"
          iconLeft="trash"
          onClick={() => {
            props.onClickDeleteField && props.onClickDeleteField();
          }}
        >
          Delete
        </Button>
      </div>
    </ProgramFieldCardContainer>
  );
};

const AddProgramField = (programFieldProps: ProgramFieldProps) => {
  const theme = useTheme();
  // const [errors, setErrors] = useState<string[]>([]);
  const fieldRequired = "This field is required";
  const [fieldDropdownData, setFieldDropdownData] = useState(0);

  async function PostAddProgramField(programFieldToAdd: any) {
    try {
      //console.log("Console log of submit: ", programFieldToAdd);
      let fieldToAdd: any;
      switch (fieldDropdownData) {
        case 1:
          const creditTemplate = programFieldProps.activeCredits.find(
            (c) => c.id === programFieldToAdd.creditToAdd
          )!;
          const creditField: ProgramCreditField = {
            ...creditTemplate,
            id: undefined,
            modifiedBy: undefined,
            modifiedDate: undefined,
            createdBy: undefined,
            createdDate: undefined,
            programId: programFieldProps.program.id!,
            templateId: programFieldToAdd.creditToAdd,
          };
          createCreditFieldTemplate(creditField).then((response) => {
            if (response.status == 201) {
              fieldToAdd = response.data;
            }
          });
          break;
        case 2:
          const dateTemplate = programFieldProps.activeDates.find(
            (c) => c.id === programFieldToAdd.dateToAdd
          )!;
          const dateField: ProgramDateField = {
            ...dateTemplate,
            id: undefined,
            modifiedBy: undefined,
            modifiedDate: undefined,
            createdBy: undefined,
            createdDate: undefined,
            programId: programFieldProps.program.id!,
            templateId: programFieldToAdd.dateToAdd,
          };
          createDateFieldTemplate(dateField).then((response) => {
            if (response.status == 201) {
              fieldToAdd = response.data;
            }
          });
          break;
        case 3:
          const listTemplate = programFieldProps.activeLists.find(
            (c) => c.id === programFieldToAdd.listToAdd
          )!;
          const listField: ProgramListField = {
            ...listTemplate,
            id: undefined,
            modifiedBy: undefined,
            modifiedDate: undefined,
            createdBy: undefined,
            createdDate: undefined,
            programId: programFieldProps.program.id!,
            templateId: programFieldToAdd.listToAdd,
          };
          //console.log("List Temlate: ", listTemplate);
          //console.log("List Field: ", listField);
          createListFieldTemplate(listField).then((response) => {
            if (response.status == 201) {
              fieldToAdd = response.data;
            }
          });
          break;
        case 4:
          const textTemplate = programFieldProps.activeTexts.find(
            (c) => c.id === programFieldToAdd.textToAdd
          )!;
          const textField: ProgramTextField = {
            ...textTemplate,
            id: undefined,
            modifiedBy: undefined,
            modifiedDate: undefined,
            createdBy: undefined,
            createdDate: undefined,
            programId: programFieldProps.program.id!,
            templateId: programFieldToAdd.textToAdd,
          };
          createTextFieldTemplate(textField).then((response) => {
            if (response.status == 201) {
              fieldToAdd = response.data;
            }
          });
          break;
        case 5:
          const linkTemplate = programFieldProps.activeLinks.find(
            (c) => c.id === programFieldToAdd.linkToAdd
          )!;
          const linkField: ProgramLinkField = {
            ...linkTemplate,
            id: undefined,
            modifiedBy: undefined,
            modifiedDate: undefined,
            createdBy: undefined,
            createdDate: undefined,
            programId: programFieldProps.program.id!,
            templateId: programFieldToAdd.linkToAdd,
          };
          createLinkFieldTemplate(linkField).then((response) => {
            if (response.status == 201) {
              fieldToAdd = response.data;
            }
          });
          break;
        case 6:
          const mediaTemplate = programFieldProps.activeMedia.find(
            (c) => c.id === programFieldToAdd.mediaToAdd
          )!;
          const mediaField: ProgramMediaField = {
            ...mediaTemplate,
            id: undefined,
            modifiedBy: undefined,
            modifiedDate: undefined,
            createdBy: undefined,
            createdDate: undefined,
            programId: programFieldProps.program.id!,
            templateId: programFieldToAdd.mediaToAdd,
          };
          createMediaFieldTemplate(mediaField).then((response) => {
            if (response.status == 201) {
              fieldToAdd = response.data;
            }
          });
          break;
        case 7:
          const physicalComponentTemplate =
            programFieldProps.activePhysicalComponents.find(
              (c) => c.id === programFieldToAdd.physicalComponentToAdd
            )!;
          const physicalComponentField: ProgramPhysicalComponentField = {
            ...physicalComponentTemplate,
            id: undefined,
            modifiedBy: undefined,
            modifiedDate: undefined,
            createdBy: undefined,
            createdDate: undefined,
            programId: programFieldProps.program.id!,
            templateId: programFieldToAdd.physicalComponentToAdd,
          };
          createPhysicalComponentFieldTemplate(physicalComponentField).then(
            (response) => {
              if (response.status == 201) {
                fieldToAdd = response.data;
              }
            }
          );
          break;
      }

      if (fieldToAdd) {
        updateProgram(fieldToAdd);
        return new Promise((resolve) => {
          resolve("Successfully Added Field to Program");
        });
      } else {
        return new Promise((reject) => {
          reject("Failed to Add Field to Program");
        });
      }

      /*TODO: Hookup to backend. */
    } catch (error: any) {
      // console.log(error);
      // setErrors(error.response.data);
    }
  }
  const updateProgram = (field: any) => {
    programFieldProps.setProgram((prevState) => {
      return {
        ...prevState,
        ...{
          fields: [prevState.fields, field],
        },
      };
    });
  };

  const formikProps = useFormik({
    initialValues: {
      program: programFieldProps.program,
      programId: programFieldProps.program.id,
      creditToAdd: [],
      dateToAdd: [],
      listToAdd: [],
      textToAdd: [],
      linkToAdd: [],
      mediaToAdd: [],
      physicalComponentToAdd: [],
    },
    enableReinitialize: true,
    onSubmit: () => {
      PostAddProgramField(formikProps.values).then(() =>
        programFieldProps.close()
      );
    },
    validationSchema: Yup.object({
      fieldType: Yup.string().required("Field Type is Required"),
    }).shape(programValidationSchema),
    validateOnBlur: false,
    validateOnChange: false,
  });

  function handleFieldDropdownData(selectedOption: any) {
    if (selectedOption.target.value.toString() == "") {
      setFieldDropdownData(0);
    } else {
      setFieldDropdownData(parseInt(selectedOption.target.value));
    }
  }

  return (
    <FormikProvider value={formikProps}>
      <h2>Add Field</h2>
      <DropdownField
        name="fieldType"
        placeholder="Select Field Type"
        options={FieldTemplateNames.map((a) => {
          return { value: a.value, label: a.label };
        })}
        onChange={handleFieldDropdownData}
      />
      {fieldDropdownData != 0 && <div></div>}
      {fieldDropdownData == 1 && (
        <div>
          <DropdownField
            name="creditToAdd"
            placeholder="Select Credit Field Type"
            options={programFieldProps.activeCredits.map((a) => {
              return { value: a.id, label: a.name };
            })}
          />
        </div>
      )}
      {fieldDropdownData == 2 && (
        <div>
          <DropdownField
            name="dateToAdd"
            placeholder="Select Date Field Type"
            options={programFieldProps.activeDates.map((a) => {
              return { value: a.id, label: a.name };
            })}
          />
        </div>
      )}
      {fieldDropdownData == 3 && (
        <div>
          <DropdownField
            name="listToAdd"
            placeholder="Select List Field Type"
            options={programFieldProps.activeLists.map((a) => {
              return { value: a.id, label: a.name };
            })}
          />
        </div>
      )}
      {fieldDropdownData == 4 && (
        <div>
          <DropdownField
            name="textToAdd"
            placeholder="Select Text Field Type"
            options={programFieldProps.activeTexts.map((a) => {
              return { value: a.id, label: a.name };
            })}
          />
        </div>
      )}
      {fieldDropdownData == 5 && (
        <div>
          <DropdownField
            name="linkToAdd"
            placeholder="Select Link Field Type"
            options={programFieldProps.activeLinks.map((a) => {
              return { value: a.id, label: a.name };
            })}
          />
        </div>
      )}
      {fieldDropdownData == 6 && (
        <div>
          <DropdownField
            name="mediaToAdd"
            placeholder="Select Media Field Type"
            options={programFieldProps.activeMedia.map((a) => {
              return { value: a.id, label: a.name };
            })}
          />
        </div>
      )}
      {fieldDropdownData == 7 && (
        <div>
          <DropdownField
            name="physicalComponentToAdd"
            placeholder="Select Physical Component Field Type"
            options={programFieldProps.activePhysicalComponents.map((a) => {
              return { value: a.id, label: a.name };
            })}
          />
        </div>
      )}

      <Button
        className="w-auto ml-auto"
        iconLeft="plus"
        iconColor={theme.colorCopyLightLight}
        onClick={async () => await PostAddProgramField(formikProps.values)}
        type="submit"
      >
        Add Field
      </Button>
    </FormikProvider>
  );
};

const AddHierarchy = (props: AddHierarchyProps) => {
  const theme = useTheme();
  // const [errors, setErrors] = useState<string[]>([]);
  const fieldRequired = "This field is required";

  async function PostAddHierarchy(AddHierarchyData: any) {
    try {
      const newProgram: ProgramModel = {
        ...props.parent,
        children: [],
        ancestry: [],
        createdBy: "",
        createdDate: "",
        depth: props.parent.depth! + 1,
        description: "",
        award: undefined,
        season: undefined,
        hasChildren: false,
        fields: [],
        deadlines: [],
        inheritedFields: [],
        id: undefined,
        isExecution: props.parent.isEntryLevel ? true : false,
        isEntryLevel: false,
        modifiedBy: "",
        modifiedDate: "",
        image: "",
        name: AddHierarchyData.name,
        parentId: props.parent.id,
        rootProgramId: props.parent.rootProgramId,
        physicalComponentAllowed: false,
        physicalComponentEntrantHelpText: undefined,
        isCampaignOnly: false,
      };

      if (
        props.parent &&
        !props.parent.isEntryLevel &&
        props.parent.deadlines &&
        props.parent.deadlines.length > 0
      ) {
        for (const deadline of props.parent.deadlines) {
          const tempDeadline: ProgramDeadline = {
            ...deadline,
            id: undefined,
            programId: undefined,
          };
          newProgram.deadlines.push(tempDeadline);
        }
      }

      const response = await PostProgram(newProgram);
      if (response?.status == 201) {
        props.setProgram((prevState) => {
          return {
            ...prevState,
            children: [...prevState.children, response.data],
            hasChildren: true,
          };
        });

        props.onSubmit();
      }
    } catch (error: any) {
      //console.log(error);
      // setErrors(error.response.data);
    }
  }

  const formikProps = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit: () => {},
    validationSchema: Yup.object({
      name: Yup.string().required(fieldRequired),
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <FormikProvider value={formikProps}>
      <h2>{props.parent.childLevelDisplayLabel} Name</h2>
      <TextField
        name="name"
        placeholder={`${props.parent.childLevelDisplayLabel} Name`}
        // onKeyUp={(e) =>
        // 	formikProps.setFieldValue("name", e.target.value)
        // }
      />
      <Button
        className="w-auto ml-auto"
        iconLeft="plus"
        iconColor={theme.colorCopyLightLight}
        onClick={async () => await PostAddHierarchy(formikProps.values)}
        type="submit"
      >
        Add
      </Button>
    </FormikProvider>
  );
};

function isAProgramCreditField(obj: any): obj is ProgramCreditField {
  return "creditType" in obj;
}

function isAProgramDateField(obj: any): obj is ProgramDateField {
  return "dateType" in obj;
}

function isAProgramListField(obj: any): obj is ProgramListField {
  return "listType" in obj;
}

function isAProgramTextField(obj: any): obj is ProgramTextField {
  return "textType" in obj;
}

function isAProgramLinkField(obj: any): obj is ProgramLinkField {
  return "maxLinks" in obj;
}

function isAProgramMediaField(obj: any): obj is ProgramMediaField {
  return "mediaType" in obj;
}

function isAProgramPhysicalComponentField(
  obj: any
): obj is ProgramPhysicalComponentField {
  return "isRequired" in obj;
}

const FieldDisplay = (props: {
  fields: ProgramFieldType[];
  title: string;
  handleModal(form: string, field: ProgramFieldType): void;
  setReloadFields(time: number): void;
  parentId?: number;
}) => {
  return (
    <StickyCard
      className="!min-h-fit !relative !top-0 !max-h-none"
      title={props.title}
    >
      <StickyCardBody className="!p-0 !overflow-visible">
        {props.fields.length > 0 &&
          props.fields.map((field) => {
            return (
              <ProgramFieldCard
                inheritedProgramId={
                  field.isInheritedField && props.parentId
                    ? props.parentId
                    : undefined
                }
                disableEdit={field.isInheritedField}
                disableDelete={field.isInheritedField}
                info={field.adminHelpText}
                key={field.id}
                fieldName={field.name}
                weight={field.fieldWeight}
                onClickEditField={() => {
                  if (isAProgramCreditField(field)) {
                    return props.handleModal("creditFieldForm", field);
                  }
                  if (isAProgramDateField(field)) {
                    return props.handleModal("dateFieldForm", field);
                  }
                  if (isAProgramListField(field)) {
                    return props.handleModal("listFieldForm", field);
                  }
                  if (isAProgramTextField(field)) {
                    return props.handleModal("textFieldForm", field);
                  }
                  if (isAProgramLinkField(field)) {
                    return props.handleModal("externalLinkForm", field);
                  }
                  if (isAProgramMediaField(field)) {
                    return props.handleModal("mediaFieldForm", field);
                  }
                  if (isAProgramPhysicalComponentField(field)) {
                    return props.handleModal("physicalEntriesForm", field);
                  }
                }}
                onClickDeleteField={() => {
                  // console.log("On click templateData: ", field);
                  if (isAProgramCreditField(field)) {
                    deleteProgramCreditField(field.id!).then(() =>
                      props.setReloadFields(new Date().getTime())
                    );
                  }
                  if (isAProgramDateField(field)) {
                    deleteProgramDateField(field.id!).then(() =>
                      props.setReloadFields(new Date().getTime())
                    );
                  }
                  if (isAProgramListField(field)) {
                    deleteProgramListField(field.id!).then(() =>
                      props.setReloadFields(new Date().getTime())
                    );
                  }
                  if (isAProgramTextField(field)) {
                    deleteProgramTextField(field.id!).then(() =>
                      props.setReloadFields(new Date().getTime())
                    );
                  }
                  if (isAProgramLinkField(field)) {
                    deleteProgramLinkField(field.id!).then(() =>
                      props.setReloadFields(new Date().getTime())
                    );
                  }
                  if (isAProgramMediaField(field)) {
                    deleteProgramMediaField(field.id!).then(() =>
                      props.setReloadFields(new Date().getTime())
                    );
                  }
                  if (isAProgramPhysicalComponentField(field)) {
                    deleteProgramPhysicalComponentField(field.id!).then(() =>
                      props.setReloadFields(new Date().getTime())
                    );
                  }
                }}
              />
            );
          })}
      </StickyCardBody>
    </StickyCard>
  );
};

const ProgramConfig = () => {
  const theme = useTheme();
  const modalState = {
    add: {
      show: false,
      title: "",
    },
    copy: {
      show: false,
      title: "",
    },
    edit: {
      show: false,
      title: "Change Hierarchy",
    },
    delete: {
      show: false,
      title: "Delete Program",
    },
  };
  const { id } = useParams<{ id: string }>();
  let programHistory = useHistory();
  const [modalForm, setModalForm] = useState("");
  const { addNewAlert } = useAlert();
  const [reloadFields, setReloadFields] = useState(new Date().getTime());

  // reload field templates when modal is closed
  const handleCloseModal = () => {
    setShowFieldEditModal(false);
    // addNewAlert({
    //   type: "success",
    //   message: "Field Template was successfully created/updated.",
    // });
  };

  const [isReleasingEntries, setReleasingEntries] = useState(false);
  const [showModal, setShowModal] = useState(modalState);
  const [showFieldModal, setShowFieldModal] = useState(modalState);
  const [showFieldEditModal, setShowFieldEditModal] = useState(false);
  const { setLoadingMessage } = useContext(LoadingContext);
  const [showCopyProgramModal, setShowCopyProgramModal] = useState(false);
  const [formReady, setFormReady] = useState(false);
  const [program, setProgram] = useState<ProgramConfigureProps>({
    id: 0,
    isCampaignEligible: false,
    isMixedCampaignEligible: false,
    childLevelDisplayLabel: "",
    deadlines: [],
    description: "",
    titleNameOverride: "",
    brandNameOverride: "",
    titleDescriptionOverride: "",
    brandDescriptionOverride: "",
    hasChildren: false,
    hasRelatedEntries: true,
    fields: [],
    inheritedFields: [],
    imageDisplaysForEntrants: false,
    imageDisplaysForJurors: false,
    imageDisplaysForWinnersGallery: false,
    isEntryLevel: false,
    isExecution: false,
    name: "",
    entriesClosed: false,
    award: undefined,
    entriesOpenDate: 0,
    depth: 0,
    physicalComponentAllowed: false,
    physicalComponentEntrantHelpText: undefined,
    children: [],
    seasonId: 0,
    awardId: 0,
    rootProgramId: 0,
    isCampaignOnly: false,
  });

  const [activeSeasons, setActiveSeasons] = useState<SeasonModel[]>([]);
  const [activeAwards, setActiveAwards] = useState<AwardModel[]>([]);
  const [activeCredits, setActiveCredits] = useState<CreditFieldTemplate[]>([]);
  const [activeDates, setActiveDates] = useState<DateFieldTemplate[]>([]);
  const [activeLists, setActiveLists] = useState<ListFieldTemplate[]>([]);
  const [activeText, setActiveText] = useState<TextFieldTemplate[]>([]);
  const [activeLinks, setActiveLinks] = useState<LinkFieldTemplate[]>([]);
  const [activeMedia, setActiveMedia] = useState<MediaFieldTemplate[]>([]);
  const [activePhysicalComponents, setActivePhysicalComponents] = useState<
    PhysicalComponentFieldTemplate[]
  >([]);

  const formFieldList: {
    // "formName" must be in "FieldTemplatesFormDataType" keys
    [formName in FieldTemplatesFormDataType as string]: {
      formComponent: React.ReactNode;
    };
  } = {
    dateFieldForm: {
      formComponent: (
        templateData?: DateFieldTemplate | ProgramDateField // update form if template data exists
      ) => (
        <DateFieldForm
          key={templateData && String(templateData.id)} // re-renders component when isNewField changes
          {...(templateData && {
            formData: templateData,
          })} // edit template if formdata is passed in
          hideModal={() => handleCloseModal()}
        />
      ),
    },
    textFieldForm: {
      formComponent: (templateData?: TextFieldTemplate | ProgramTextField) => (
        <TextFieldForm
          key={templateData && String(templateData.id)}
          {...(templateData && {
            formData: templateData,
          })}
          hideModal={() => handleCloseModal()}
        />
      ),
    },
    mediaFieldForm: {
      formComponent: (
        templateData?: MediaFieldTemplate | ProgramMediaField
      ) => (
        <MediaFieldForm
          key={templateData && String(templateData.id)}
          {...(templateData && {
            formData: templateData,
          })}
          hideModal={() => handleCloseModal()}
        />
      ),
    },
    creditFieldForm: {
      formComponent: (
        templateData?: CreditFieldTemplate | ProgramCreditField
      ) => (
        <CreditFieldForm
          key={templateData && String(templateData.id)}
          {...(templateData && {
            formData: templateData,
          })}
          hideModal={() => handleCloseModal()}
        />
      ),
    },
    listFieldForm: {
      formComponent: (
        templateData?: ListFieldTemplate | ProgramListField // update form if template data exists
      ) => (
        <ListFieldForm
          key={templateData && String(templateData.id)} // re-renders component when isNewField changes
          {...(templateData && {
            formData: templateData,
          })}
          hideModal={() => handleCloseModal()}
        />
      ),
    },
    externalLinkForm: {
      formComponent: (templateData?: LinkFieldTemplate | ProgramLinkField) => (
        <LinkFieldForm
          key={templateData && String(templateData.id)}
          {...(templateData && {
            formData: templateData,
          })}
          hideModal={() => handleCloseModal()}
        />
      ),
    },
    physicalEntriesForm: {
      formComponent: (
        templateData?:
          | PhysicalComponentFieldTemplate
          | ProgramPhysicalComponentField
      ) => (
        <PhysicalComponentFieldForm
          key={templateData && String(templateData.id)}
          {...(templateData && {
            formData: templateData,
          })}
          hideModal={() => handleCloseModal()}
        />
      ),
    },
  };

  const handleModal = (formName: string, templateData?: any) => {
    const formField = formFieldList[formName];

    try {
      if (typeof formField.formComponent === "function") {
        // render edit/new field modal, depending on the button clicked
        setModalForm(
          formField.formComponent(templateData ? templateData : undefined)
        );
      }
      // else {
      // 	console.log("not form component", typeof formField.formComponent);
      // }
    } catch (e) {
      //console.log(e);
    }
  };

  useEffect(() => {
    modalForm && setShowFieldEditModal(!showFieldEditModal);
  }, [modalForm]);

  useEffect(() => {
    // console.log("getting program", reloadFields);
    getProgramConfigById(id)
      .then((response) => {
        // console.log("getProgramById", response);
        setProgram(response.data);
      })
      .catch((error) => {
        // console.log("getProgramById ERROR", error);
        // setErrors((old) => [...old, error]);
      });

    getActiveSeasons()
      .then((response) => {
        setActiveSeasons(response.data);
      })
      .catch((error) => {
        // console.log("getActiveSeasons ERROR", error);
        // setErrors((old) => [...old, error]);
      });

    getActiveAwards()
      .then((response) => {
        setActiveAwards(response.data);
      })
      .catch((error) => {
        // console.log("getActiveAwards ERROR", error);
        // setErrors((old) => [...old, error]);
      });

    getActiveCreditFieldTemplates()
      .then((response) => {
        setActiveCredits(response.data);
      })
      .catch((error) => {
        // console.log("getActiveCreditFieldTemplates ERROR", error);
      });

    getActiveDateFieldTemplates()
      .then((response) => {
        setActiveDates(response.data);
      })
      .catch((error) => {
        // console.log("getActiveDateFieldTemplates ERROR", error);
      });

    getActiveListFieldTemplates()
      .then((response) => {
        setActiveLists(response.data);
      })
      .catch((error) => {
        // console.log("getActiveListFieldTemplates ERROR", error);
      });

    getActiveTextFieldTemplates()
      .then((response) => {
        setActiveText(response.data);
      })
      .catch((error) => {
        // console.log("getActiveTextFieldTemplates ERROR", error);
      });

    getActiveLinkFieldTemplates()
      .then((response) => {
        setActiveLinks(response.data);
      })
      .catch((error) => {
        // console.log("getActiveLinkFieldTemplates ERROR", error);
      });

    getActiveMediaFieldTemplates()
      .then((response) => {
        setActiveMedia(response.data);
      })
      .catch((error) => {
        // console.log("getActiveMediaFieldTemplates ERROR", error);
      });
    getActivePhysicalComponentFieldTemplates()
      .then((response) => {
        setActivePhysicalComponents(response.data);
      })
      .catch((error) => {
        // console.log("getActivePhysicalComponentFieldTemplates ERROR", error);
      });
  }, [id, reloadFields]);

  async function UpdateProgram(value: ProgramConfigureProps) {
    // setErrors([]);
    // console.log("Program: ", program);
    value.id = program.id;
    value.award = undefined;
    value.season = undefined;
    // console.log("Update Program Response: ", value);
    updateProgram(value)
      .then((response) => {
        if (response.status === 200) {
          setProgram(response.data);
          // console.log("response.data", response.data);
          addNewAlert({
            type: "success",
            message: "Program Updated Successfully",
          });
        }
      })
      .catch((error) => {
        // console.log("error", error, typeof error, error.response);
        addNewAlert({
          type: "error",
          message: `${error.response.status} Error`,
        });
      });
  }

  async function DeleteProgram() {
    if (program.id) {
      setLoadingMessage(
        "Deleting Program. This may take several minutes depending on the size and depth of the Program."
      );
      deleteProgram(program.id)
        .then((response) => {
          setLoadingMessage(null);
          if (response.status === 204) {
            addNewAlert({
              type: "success",
              message: "Program Deleted",
            });
            programHistory.push("/program/program-setup");
          }
        })
        .catch((error) => {
          // console.log("error", error, typeof error, error.response);
          setLoadingMessage(null);
          addNewAlert({
            type: "error",
            message: `${error?.response?.status} Error`,
          });
        });
    }
  }

  const routeChange = (id: number) => {
    let path = `/program/configure/${id}`;
    programHistory.push(path);
  };

  const fieldRequired = "This field is required";
  const formikProps = useFormik({
    initialValues: program,
    onSubmit: async () => {},
    enableReinitialize: true,
    validationSchema: Yup.object(programValidationSchema),
    validateOnBlur: false,
    validateOnChange: false,
  });

  // if the program is campaign only, by default campaigns are eligible
  useEffect(() => {
    if (formikProps.values.isCampaignOnly) {
      formikProps.setFieldValue("isCampaignEligible", true);
    }
  }, [formikProps.values.isCampaignOnly]);

  //  console.log("Program props: ", program);

  const handleSubmit = () => {
    formikProps
      .validateForm()
      .then((res) => {
        // console.log("program config", formikProps.values);
        // update program if validation passes
        if (Object.values(res).length === 0) {
          UpdateProgram(formikProps.values);
        } else {
          addNewAlert({
            type: "error",
            message: "Please fill in the require fields",
          });
        }
      })
      .catch((e) => {
        addNewAlert({ type: "error", message: "Unknown Error" });
      });
  };

  const handleMarkEntriesAsAbandoned = () => {
    if (program.id) {
      markEntriesAsAbandoned(program.id)
        .then((res) => {
          if (res.status == 200) {
            addNewAlert({
              type: "success",
              message: "Entries marked as abandoned",
            });
          }
        })
        .catch((e) => {
          addNewAlert({ type: "error", message: "Unknown Error" });
        });
    }
  };

  const handleJudgingResultsReleased = () => {
    if (program.id) {
      setReleasingEntries(true);
      setJudgingResultsReleased(program.id)
        .then((res) => {
          setReleasingEntries(false);
          if (res.status == 200) {
            addNewAlert({
              type: "success",
              message: "Entries marked as released",
            });
          }
        })
        .catch((e) => {
          addNewAlert({ type: "error", message: "Unknown Error" });
          setReleasingEntries(false);
        });
    }
  };

  const [sortedProgramFields, setSortedProgramFields] = useState<
    ProgramFieldType[]
  >([]);

  useEffect(() => {
    const extendedValues = formikProps.values;
    const programFields: ProgramFieldType[] = extendedValues.fields
      ? extendedValues.fields
      : [];

    // add isInherited flag
    const inheritedFields: ProgramFieldType[] = extendedValues.inheritedFields
      ? extendedValues.inheritedFields.map((field) => ({
          ...field,
          isInheritedField: true,
        }))
      : [];

    let sortedFields: ProgramFieldType[] = [
      ...programFields,
      ...inheritedFields,
    ];

    // sort arr of program and inherited fields
    sortedFields = sortProgramFields(sortedFields);

    setSortedProgramFields(sortedFields);
  }, [formikProps.values]);

  // useEffect(() => {
  // 	const extendedValues = formikProps.values;
  // 	if (extendedValues.inheritedFields) {
  // 		const sortedFields = sortProgramFields(extendedValues.inheritedFields);
  // 		setSortedInheritedProgramFields(sortedFields);
  // 	}
  // }, [formikProps.values]);

  const [creditFields, setCreditFields] = useState<ProgramFieldType[]>([]);
  const [mediaFields, setMediaFields] = useState<ProgramFieldType[]>([]);
  const [additionalInfoFields, setAdditionalInfoFields] = useState<
    ProgramFieldType[]
  >([]);

  // sort fields by
  // Additional Info
  // Credits
  // Media / Execution Level
  useEffect(() => {
    const filteredCreditFields = sortedProgramFields.filter((field) =>
      isAProgramCreditField(field)
    );

    const filteredMediaFields = sortedProgramFields.filter((field) =>
      isAProgramMediaField(field)
    );

    const filteredAdditionalInfoFields = sortedProgramFields.filter(
      (field) => !isAProgramCreditField(field) && !isAProgramMediaField(field)
    );

    setCreditFields(filteredCreditFields);
    setMediaFields(filteredMediaFields);
    setAdditionalInfoFields(filteredAdditionalInfoFields);
  }, [sortedProgramFields]);

  return (
    <CardContainer>
      <BrowserHeader title={`Configure ${program.name}`} />
      <Modal show={showModal.add.show === true || showModal.copy.show === true}>
        <ModalContainer>
          <Icon
            icon="close"
            color={theme.colorPrimary}
            className="m-[1.5rem] ml-auto cursor-pointer"
            onClick={() => setShowModal(modalState)}
          />

          <div className="inner-container">
            {showModal.add.show ? (
              <AddHierarchy
                parent={program}
                setProgram={setProgram}
                onSubmit={() => setShowModal(modalState)}
              />
            ) : (
              <></>
            )}
          </div>
        </ModalContainer>
      </Modal>
      <Modal show={showFieldEditModal}>{modalForm}</Modal>
      <Modal
        show={
          showFieldModal.add.show === true || showFieldModal.copy.show === true
        }
      >
        <ModalContainer>
          <Icon
            icon="close"
            color={theme.colorPrimary}
            className="m-[1.5rem] ml-auto cursor-pointer"
            onClick={() => setShowFieldModal(modalState)}
          />
          <div className="inner-container">
            {showFieldModal.add.show ? (
              <AddProgramField
                program={program}
                setProgram={setProgram}
                activeCredits={activeCredits}
                activeDates={activeDates}
                activeTexts={activeText}
                activeLinks={activeLinks}
                activeLists={activeLists}
                activeMedia={activeMedia}
                activePhysicalComponents={activePhysicalComponents}
                close={() => {
                  setShowFieldModal(modalState);
                  setReloadFields(new Date().getTime());
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </ModalContainer>
      </Modal>
      <WarningModal
        show={showModal.edit.show === true}
        title={showModal.edit.title}
        message={`Are you sure you want to change. 
This action will affect judging and result in loss of data.`}
        close={() => setShowModal(modalState)}
      >
        <Button>Change</Button>
        <Button className="button-light">Cancel</Button>
      </WarningModal>

      <WarningModal
        show={showModal.delete.show === true}
        title={showModal.delete.title}
        message={`Are you sure you want to delete this Program and all its children?`}
        close={() => setShowModal(modalState)}
      >
        <Button onClick={() => DeleteProgram()}>Delete</Button>
        <Button
          onClick={() => setShowModal(modalState)}
          className="button-light"
        >
          Cancel
        </Button>
      </WarningModal>

      <CopyProgramModal
        show={showCopyProgramModal}
        setShow={(isShow) => setShowCopyProgramModal(isShow)}
        programId={program.id ? program.id : 0}
        isProgramRootLevel={program.parentId ? false : true}
        activeAwards={activeAwards}
        activeSeasons={activeSeasons}
      />

      <CardHeader className="justify-between">
        <h2 className="card-title">Configure</h2>
        <Button icon="copy" onClick={() => setShowCopyProgramModal(true)}>
          Copy Program
        </Button>
      </CardHeader>

      <NavTabs>
        <ConfigNavTabs>
          <NavTab>Basic Info</NavTab>
          <NavTab>Configure Fields</NavTab>
          <NavTab>Add {formikProps.values.childLevelDisplayLabel}</NavTab>
        </ConfigNavTabs>
        <NavTabPanel>
          <TabBody>
            <FormikProvider value={formikProps}>
              <div className="col-w-100">
                <h3 className="subtitle font-semibold !mb-0">
                  Basic Program Information
                </h3>
              </div>
              <TextField
                name="name"
                placeholder="Name"
                value={formikProps.values.name}
              />
              <div className="grid grid-cols-2 gap-[1rem]">
                <DropdownField
                  name="seasonId"
                  placeholder="Select Season"
                  options={activeSeasons.map((s) => {
                    return { value: s.id, label: s.name };
                  })}
                  value={formikProps.values.seasonId}
                />
                <DropdownField
                  name="awardId"
                  placeholder="Select Award"
                  options={activeAwards.map((a) => {
                    return { value: a.id, label: a.name };
                  })}
                  value={formikProps.values.awardId}
                />
              </div>
              <RichTextField
                name="description"
                placeholder="Program Description"
                value={formikProps.values.description}
                height="200px"
              />

              <TZDatePickerField
                name="entriesOpenDate"
                placeholder="Entries Open Date"
                value={formikProps.values.entriesOpenDate}
                showTimeSelect
              />
              <div className="col-w-100">
                <h3 className="subtitle font-semibold !mb-0">
                  Program Deadlines
                </h3>
              </div>

              <DeadlineFields name="deadlines" />

              <div className="col-w-100">
                <h3 className="subtitle font-semibold !mb-0">
                  Image or Sponsor Logo
                </h3>
              </div>
              <FileUpload
                height="173px"
                id="FileUpload-ProgramConfig"
                onChange={(files) => {
                  if (!files || files.length === 0) {
                    return;
                  }
                  formikProps.setFieldValue("imageData", files[0].file);
                }}
                {...(formikProps.values.image && {
                  value: {
                    fileName: "Program Logo",
                    fileType: "image",
                    src: formikProps.values.image,
                    initial: true,
                  },
                  disabled: false,
                })}
                remove={() => {
                  formikProps.setFieldValue("image", undefined);
                  formikProps.setFieldValue("imageData", undefined);
                }}
                allowedMediaType={MediaType.Image}
              />
              <div className="flex gap-[1.11rem]">
                <p className="font-semibold mr-[.765rem]">
                  {" "}
                  Image displays for
                </p>

                <CheckboxField
                  name="imageDisplaysForEntrants"
                  placeholder="Entrants"
                  checked={formikProps.values.imageDisplaysForEntrants}
                />

                <CheckboxField
                  name="imageDisplaysForJurors"
                  placeholder="Jurors"
                  checked={formikProps.values.imageDisplaysForJurors}
                />

                <CheckboxField
                  name="imageDisplaysForWinnersGallery"
                  placeholder="Winners Gallery"
                  checked={formikProps.values.imageDisplaysForWinnersGallery}
                />
              </div>
              <div className="col-w-100">
                <h3 className="subtitle font-semibold !mb-0">Entry Settings</h3>
              </div>
              <ToggleSwitchField
                id="isEntryLevel"
                name="isEntryLevel"
                checked={formikProps.values.isEntryLevel}
                label="Is Entry Level?"
              />

              {formikProps.values.isEntryLevel && (
                <div className="col-w-100">
                  <div className="flex gap-[1rem] items-center">
                    <p>Are Physical Components allowed?</p>
                    <ToggleSwitchField
                      id="physicalComponentAllowed"
                      name="physicalComponentAllowed"
                      checked={formikProps.values.physicalComponentAllowed}
                    />
                  </div>
                </div>
              )}

              {formikProps.values.isEntryLevel &&
                formikProps.values.physicalComponentAllowed && (
                  <RichTextField
                    name="physicalComponentEntrantHelpText"
                    placeholder="Physical Component Entrant Help Text"
                    value={formikProps.values.physicalComponentEntrantHelpText}
                    height="200px"
                  />
                )}
              <ToggleSwitchField
                id="isExecution"
                name="isExecution"
                checked={formikProps.values.isExecution}
                label="Is Execution?"
              />

              <ToggleSwitchField
                id="isCampaignOnly"
                name="isCampaignOnly"
                label="Is Campaign Only?"
                checked={formikProps.values.isCampaignOnly}
              />
              <ToggleSwitchField
                id="isCampaignEligible"
                name="isCampaignEligible"
                label="Is Campaign Eligible"
                disabled={formikProps.values.isCampaignOnly}
                checked={formikProps.values.isCampaignEligible}
              />
              <ToggleSwitchField
                id="isMixedCampaignEligible"
                name="isMixedCampaignEligible"
                label="Is Mixed Campaign Eligible"
                checked={formikProps.values.isMixedCampaignEligible}
              />
              <ToggleSwitchField
                id="entriesClosed"
                name="entriesClosed"
                label="Entries Closed"
                checked={formikProps.values.entriesClosed!}
              />
              <Button
                className="w-[180px]  button-activation"
                type="submit"
                disabled={!formikProps.values.entriesClosed!}
                onClick={() => handleMarkEntriesAsAbandoned()}
              >
                Mark as Abandoned
              </Button>
              <Button
                className="w-[240px]  button-activation"
                type="submit"
                disabled={isReleasingEntries}
                onClick={() => handleJudgingResultsReleased()}
              >
                <div className="flex items-center gap-[.5rem]">
                  Judging Results Released
                  {isReleasingEntries && (
                    <img
                      className="w-[16px]"
                      src={config.assets.loading.primary}
                    />
                  )}
                </div>
              </Button>
              <TextField
                name="titleNameOverride"
                placeholder="Title Name Override"
                value={formikProps.values.titleNameOverride}
              />
              <RichTextField
                name="titleDescriptionOverride"
                placeholder="Entry Title Description Override"
                value={formikProps.values.titleDescriptionOverride}
                height="200px"
              />
              <TextField
                name="brandNameOverride"
                placeholder="Brand Name Override"
                value={formikProps.values.brandNameOverride}
              />
              <RichTextField
                name="brandDescriptionOverride"
                placeholder="Brand Description Override"
                value={formikProps.values.brandDescriptionOverride}
                height="200px"
              />
              <TextField
                name="childLevelDisplayLabel"
                placeholder="Child-level Display Name"
                value={formikProps.values.childLevelDisplayLabel}
              />
              <div className="flex gap-[1rem] ml-auto">
                <Button
                  type="button"
                  className="w-[100px] button-light"
                  disabled={formikProps.values.hasRelatedEntries}
                  // icon="close"
                  // iconColor={theme.colorActivation}
                  onClick={() =>
                    setShowModal({
                      ...showModal,
                      delete: { ...showModal.delete, show: true },
                    })
                  }
                >
                  Delete
                </Button>
                <Button
                  type="button"
                  className="w-[100px] button-light"
                  // icon="close"
                  // iconColor={theme.colorActivation}
                  onClick={() => formikProps.resetForm()}
                >
                  Reset
                </Button>
                <Button
                  className="w-[100px]  button-activation"
                  type="submit"
                  onClick={() => handleSubmit()}
                  // icon="check"
                  // iconColor={theme.colorCopyLightLight}
                >
                  Update
                </Button>
              </div>
            </FormikProvider>

            {/* <StickyCard
							className="!min-h-fit !relative !top-0"
							title={`Status`}
						>
							<StickyCardBody className="grid">
								<p className="text-colorDanger italic mb-[.625rem]">Warning!</p>
								<p className="mb-[5.625rem]">
									This is currently open any
									edits made during this period will effect entry data.
								</p>
								<div className="flex gap-[1.11rem] ml-auto">
									<Button
										className="button-light"
										iconLeft="check"
										iconColor={theme.colorPrimary}
										onClick={() =>
											setShowModal({
												...showModal,
												edit: { ...showModal.edit, show: true },
											})
										}
									>
										Yes, Delete
									</Button>
									<Button
										
										iconLeft="close"
										iconColor={theme.colorCopyLightLight}
									>
										NO, do not Delete
									</Button>
								</div>
							</StickyCardBody>
						</StickyCard> */}
          </TabBody>
        </NavTabPanel>
        <NavTabPanel>
          <CardBody className="grid gap-[2rem]">
            <div className="flex gap-[1.28125rem] mb-[1rem] w-full justify-between items-center">
              <ProgramTitleName>{formikProps.values.name}</ProgramTitleName>
              <Button
                iconLeft="plus"
                iconColor={theme.colorCopyLightLight}
                onClick={() =>
                  setShowFieldModal({
                    ...showFieldModal,
                    add: {
                      show: true,
                      title: `Add Field`,
                    },
                  })
                }
              >
                Add Field
              </Button>
            </div>

            <FieldDisplay
              fields={additionalInfoFields}
              title="Additional Info Fields"
              handleModal={handleModal}
              setReloadFields={setReloadFields}
              parentId={formikProps.values.parentId}
            />
            <FieldDisplay
              fields={creditFields}
              title="Credit Fields"
              handleModal={handleModal}
              setReloadFields={setReloadFields}
              parentId={formikProps.values.parentId}
            />
            <FieldDisplay
              fields={mediaFields}
              title="Media Fields"
              handleModal={handleModal}
              setReloadFields={setReloadFields}
              parentId={formikProps.values.parentId}
            />
          </CardBody>
        </NavTabPanel>
        <NavTabPanel>
          <CardBody className="grid">
            <StickyCard className="!min-h-fit !relative !top-0">
              <StickyHeaderBar className="!p-0">
                <SortContainer className="!text-colorCopyLightLight !px-[3.75rem] !py-[2rem] !mb-0">
                  <Sort width="35%" label={`Name`} />
                </SortContainer>
              </StickyHeaderBar>
              <BorderStickyCardBody>
                <h3 className="font-semibold w-[35%]">
                  {formikProps.values.name}
                </h3>
              </BorderStickyCardBody>
            </StickyCard>
            <HierarchyHeader>
              <h3 className="font-semibold">
                {formikProps.values.childLevelDisplayLabel}
              </h3>
              <Button
                iconLeft="plus"
                iconColor={theme.colorCopyLightLight}
                onClick={() =>
                  setShowModal({
                    ...showModal,
                    add: {
                      show: true,
                      title: `Add ${formikProps.values.childLevelDisplayLabel}`,
                    },
                  })
                }
              >
                Add {formikProps.values.childLevelDisplayLabel}
              </Button>
            </HierarchyHeader>

            {formikProps.values.hasOwnProperty("children") &&
              formikProps.values.children!.map(
                (hierarchy: ProgramConfigureChild, i: number) => {
                  return (
                    <Level key={hierarchy.id}>
                      <span className="horizontal-branch" />
                      <ProgramHierarchyContainer className="justify-between">
                        <div className="w-[220px]">
                          <Button
                            className="button-light"
                            iconLeft="edit"
                            onClick={() => routeChange(hierarchy.id!)}
                          >
                            Configure
                          </Button>
                        </div>
                        <p className="w-[30%]">{hierarchy.name}</p>
                      </ProgramHierarchyContainer>
                    </Level>
                  );
                }
              )}
          </CardBody>
        </NavTabPanel>
      </NavTabs>
    </CardContainer>
  );
};

export default ProgramConfig;

interface ProgramFieldCardProps {
  className?: string;
  info?: string;
  fieldName: string;
  fieldTemplateKey?: string;
  fieldTemplateValues?: object;
  status?: "valid" | "invalid";
  onClickEditField?(): void;
  disableEdit?: boolean;
  onClickDeleteField?(): void;
  disableDelete?: boolean;
  inheritedProgramId?: number;
  weight?: number;
}

interface ProgramFieldProps {
  program: ProgramConfigureProps;
  setProgram: React.Dispatch<React.SetStateAction<ProgramConfigureProps>>;
  activeCredits: CreditFieldTemplate[];
  activeDates: DateFieldTemplate[];
  activeLists: ListFieldTemplate[];
  activeTexts: TextFieldTemplate[];
  activeLinks: LinkFieldTemplate[];
  activeMedia: MediaFieldTemplate[];
  activePhysicalComponents: PhysicalComponentFieldTemplate[];
  close(): void;
}

interface AddHierarchyProps {
  parent: ProgramConfigureProps;
  setProgram: React.Dispatch<React.SetStateAction<ProgramConfigureProps>>;
  onSubmit(): void;
}

export interface AddProgramFieldProps {
  programId: number | undefined;
  program: ProgramModel;
  setProgram: React.Dispatch<React.SetStateAction<ProgramConfigureProps>>;
  creditToAdd: CreditFieldTemplate;
  dateToAdd: DateFieldTemplate;
  listToAdd: ListFieldTemplate;
  textToAdd: TextFieldTemplate;
  linkToAdd: LinkFieldTemplate;
  mediaToAdd: MediaFieldTemplate;
  physicalComponentToAdd: PhysicalComponentFieldTemplate;
}

export interface ProgramConfigureProps {
  id?: number;
  name: string;
  description: string;
  titleNameOverride?: string;
  brandNameOverride?: string;
  titleDescriptionOverride?: string;
  brandDescriptionOverride?: string;
  image?: string;
  imageData?: File;
  imageDisplaysForEntrants: boolean;
  imageDisplaysForJurors: boolean;
  imageDisplaysForWinnersGallery: boolean;
  isCampaignEligible: boolean;
  isMixedCampaignEligible: boolean;
  isEntryLevel: boolean;
  isExecution: boolean;
  entriesOpenDate: number;
  entriesClosed: boolean;
  deadlines: ProgramDeadline[];
  seasonId: number;
  season?: SeasonModel;
  awardId: number;
  award?: AwardModel;
  parentId?: number;
  hasChildren: boolean;
  children: ProgramConfigureChild[];
  hasRelatedEntries: boolean;
  childLevelDisplayLabel: string;
  depth: number;
  physicalComponentAllowed: boolean;
  physicalComponentEntrantHelpText?: string;
  fields?: (
    | ProgramMediaField
    | ProgramTextField
    | ProgramDateField
    | ProgramListField
    | ProgramCreditField
    | ProgramLinkField
    | ProgramPhysicalComponentField
  )[];
  inheritedFields?: (
    | ProgramMediaField
    | ProgramTextField
    | ProgramDateField
    | ProgramListField
    | ProgramCreditField
    | ProgramLinkField
    | ProgramPhysicalComponentField
  )[];
  createdDate?: Date;
  updatedDate?: Date;
  createdBy?: string;
  modifiedBy?: string;
  rootProgramId: number;
  isCampaignOnly: false;
}

export interface ProgramConfigureChild {
  id: number;
  name: string;
}
