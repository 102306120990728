import styled, { useTheme } from "styled-components";
import Button from "../../components/Button/Button";
import { EntryCardProps } from "../../components/CheckoutEntryCard/CheckoutEntryCard";
import { useContext, useEffect, useState } from "react";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import TextField from "../../components/FormFields/TextField";
import StyledForm from "../../components/Form/Form";
import DropdownField from "../../components/FormFields/DropdownField";
import { CountryOptions } from "../../data/CountryData";
import { USStateOptions } from "../../data/StateData";
import UserMenu from "../Admin/User/UserMenu";
import { NavTabList } from "../../components/NavTabs/NavTabs";
import { UserPageContainer } from "../../components/UserPageTemplate/UserPageTemplate";
import SubmissionLightbox from "./SubmisisonLightbox";
import {
	createOrderandPdf,
	getFinalPrice,
	getDeadlinePrice,
	getCheckoutEntryCards,
} from "./manageCheckout";
import { OrderLineItem, OrderModel, TransactionType } from "./OrderInterfaces";
import { useCompany } from "../../hooks/UseCompany";
import { OrderSummary } from "./CartTab";

import PrimaryLogo from "../../components/PrimaryLogo/PrimaryLogo";
import { WarningModal } from "../../components/Modal/Modal";
import { useLocation } from "react-router-dom";
import config from "../../config";
import { commafy } from "../../utils/commafy";
import { useAlert } from "../../components/Alert/Alerts";

const PaymentInfo = styled.div`
	padding: 2.25rem;
	border: solid 2px ${({ theme }) => theme.colorBorderLight};
	background: ${({ theme }) => theme.colorBackgroundLightLight};
`;

const LoadingSpinner = styled.img`
	width: 11%;
	display: inline-flex;
`;

const Payment = (props: PaymentProps) => {
	const theme = useTheme();
	const location = useLocation<LocationState>();
	const { addNewAlert } = useAlert();
	const { company, setCompany } = useCompany();
	const [order, setOrder] = useState<OrderModel>({
		companyAddress1: "",
		companyCity: "",
		companyCountry: "",
		companyName: "",
		companyPostalCode: "",
		companyState: "",
		email: "",
		fullName: "",
		lineItems: [],
		orderAmount: 0,
		phone: "",
		statusId: 0,
		transactionType: props.transactionType,
		bankCity: "",
		bankName: "",
		cardInfo: {
			cardCode: "",
			cardNumber: "",
			expirationDate: "",
		},
		companyAddress2: "",
		notes: "",
	});
	const [orderAmount, setOrderAmount] = useState<number>(0);
	const [itemCount, setItemCount] = useState<number>(0);
	const [itemText, setItemText] = useState("");
	const [priceChangeModal, setPriceChangeModal] = useState(false);

	const handleGetCheckoutEntryCards = () => {
		getCheckoutEntryCards().then((response) => {
			if (response.status == 200) {
				let orderLineItems: OrderLineItem[] = [];
				let tempOrderAmount =
					props.transactionType == TransactionType.Bank ? 25 : 0;
				response.data.forEach((entry: EntryCardProps) => {
					let orderLineItem: OrderLineItem = {
						entryId: entry.id,
						totalLineAmount: getFinalPrice(
							getDeadlinePrice(entry.deadlines!, entry.adjustment),
							entry.isCampaign!
						),
						entry: entry,
					};
					orderLineItems.push(orderLineItem);
					tempOrderAmount = tempOrderAmount + orderLineItem.totalLineAmount!;
				});
				// notify users of a price difference b/w cart and checkout
				if (tempOrderAmount !== location.state.orderTotal) {
					setPriceChangeModal(true);
				}

				setItemCount(response.data.length);
				if (response.data.length > 1) {
					setItemText(response.data.length + " items in your cart");
				} else {
					setItemText(response.data.length + " item in your cart");
				}

				setOrderAmount(tempOrderAmount); // update display order amount

				setOrder((prev) => {
					return {
						...prev,
						lineItems: orderLineItems,
						orderAmount: tempOrderAmount, // update formik order amount (payload for backend)
					};
				});
			}
		});
	};

	useEffect(() => {
		handleGetCheckoutEntryCards();
	}, []);

	useEffect(() => {
		setOrder((prev) => {
			return {
				...prev,
				orderAmount: orderAmount,
				companyAddress1: company.address1,
				companyCity: company.city,
				companyCountry: company.country || "",
				companyName: company.name,
				companyPostalCode: company.postalCode,
				companyState: company.state,
				email: company.financeContactEmail,
				phone: company.financeContactPhone || "",
				companyAddress2: company.address2 || "",
				fullName: company.financeContactName || "",
			};
		});
	}, [company]);

	async function PostPayment(PaymentData: OrderModel) {
		// re-calculate price when submit payment is clicked
		let calculatedPrice =
			props.transactionType === TransactionType.Bank ? 25 : 0;

		PaymentData.lineItems.forEach((orderLineItem: OrderLineItem) => {
			calculatedPrice += getFinalPrice(
				getDeadlinePrice(
					orderLineItem.entry.deadlines,
					orderLineItem.entry.adjustment
				),
				orderLineItem.entry.isCampaign || false
			);
		});

		// only submit payment if display order total is the same as calculated price
		if (calculatedPrice === PaymentData.orderAmount) {
			try {
				try {
					// show confirmation modal once pdf is created
					const processedOrder = await createOrderandPdf(PaymentData);

					// console.log("AAAAAAAAAAA createOrderandPdf", processedOrder);
					processedOrder.paymentDate = new Date(processedOrder.paymentDate);
					// console.log("processedOrder.data", processedOrder.data);

					if (!processedOrder.data.isError) {
						setOrder(processedOrder.data);
					} else {
						addNewAlert({
							type: "error",
							message: processedOrder.data.authMessage,
						});
					}
					// order.data.isError === false
					// 	? setOrder(order.data.order)
					// 	: setErrors(order.data.authMessage);
				} catch (err) {
					addNewAlert({ type: "error", message: String(err) });
				}
			} catch (error: any) {
				addNewAlert({ type: "error", message: error.response.data });
			}
		} else {
			setPriceChangeModal(true);
			handleGetCheckoutEntryCards(); // get entry cards again to update order total
		}
	}

	// initial values for formik
	const creditCardValues = {
		cardNumber: "",
		expiration: "",
		cvv: "",
		cardZip: "",
	};

	// initial values for formik
	const bankTransferValues = {
		bankName: "",
		bankCity: "",
	};

	const fieldRequired = "This field is required";

	const creditCardValidation = {
		cardInfo: Yup.object().shape({
			cardNumber: Yup.string()
				.required(fieldRequired)
				.test("checkForNonDigits", "Must contain digits only", (val) => {
					return val === undefined ? false : /^\d+$/.test(val);
				})
				.test("checkLength", "Must be between 15 and 16 digits", (val) => {
					return val === undefined
						? false
						: val.length >= 15 && val.length <= 16;
				}),
			expirationDate: Yup.string()
				.required(fieldRequired)
				.test("checkForNonDigits", "Must contain digits only", (val) => {
					return val === undefined ? false : /^\d+$/.test(val);
				})
				.test("checkLength", "Must be exactly 4 digits", (val) => {
					return val === undefined ? false : val.length === 4;
				}),
			cardCode: Yup.string()
				.required(fieldRequired)
				.test("checkForNonDigits", "Must contain digits only", (val) => {
					return val === undefined ? false : /^\d+$/.test(val);
				})
				.test("checkLength", "Must be between 3 and 4 digits", (val) => {
					return val === undefined ? false : val.length >= 3 && val.length <= 4;
				}),
		}),
	};

	const formikProps = useFormik({
		initialValues: order,
		enableReinitialize: true,
		onSubmit: async (value) => {
			await PostPayment(value);
		},
		validationSchema: Yup.object({
			fullName: Yup.string()
				.required(fieldRequired)
				.max(100, "Full Name Maximum is 100 Characters"),
			email: Yup.string().email().required(fieldRequired),
			phone: Yup.string().required(fieldRequired),
			companyName: Yup.string().required(fieldRequired),
			companyAddress1: Yup.string().required(fieldRequired),
			companyCity: Yup.string().required(fieldRequired),
			companyCountry: Yup.string().required(fieldRequired),

			// use credit card validation
			...(order.transactionType === TransactionType.Credit && {
				...creditCardValidation,
			}),
		}),
		validateOnChange: true,
	});

	return (
		<div className="flex flex-col w-full">
			<WarningModal
				show={priceChangeModal}
				title="Price Change"
				message="The price of some of the entries in your cart may have changed. Please review before proceeding."
				close={() => setPriceChangeModal(false)}
			>
				<Button
					className="w-[225px]"
					onClick={() => setPriceChangeModal(false)}
				>
					Review Payment
				</Button>
			</WarningModal>
			{order.id && <SubmissionLightbox order={order} show={order !== null} />}

			<UserMenu />
			<NavTabList>
				<div className="flex items-center mb-[66px]">
					<PrimaryLogo color={theme.colorLogoLightLight} width="254px" />
					<h1 className="ml-[5rem] text-colorCopyLightLight">Checkout</h1>
				</div>
			</NavTabList>
			<UserPageContainer className="flex-1">
				<FormikProvider value={formikProps}>
					<div className="inner-container flex-row">
						<h2 className="font-semibold">
							{TransactionType[props.transactionType]} Payment
						</h2>
						{TransactionType[props.transactionType] === TransactionType[0] && (
							// Credit Payment
							<p className="mt-[1rem]">
								Please fill in the required credit card fields and invoice payor
								information. The invoice payor information and anything you
								enter in Invoice Notes will appear on your invoice.
							</p>
						)}
						{TransactionType[props.transactionType] === TransactionType[1] && (
							// Check Payment
							<p className="mt-[1rem]">
								By selecting Check Payment, your order will be moved to your
								Orders tab, but its status will remain pending until we receive
								and verify your payment. <br />
								The invoice payor information and anything you enter in Invoice
								Notes will appear on your invoice. <br />
								Be sure to include your Order ID in your check's note field.
							</p>
						)}
						{TransactionType[props.transactionType] === TransactionType[2] && (
							// Bank Payment
							<p className="mt-[1rem]">
								By selecting Bank Transfer your order will be moved to your
								Orders tab, but its status will remain pending until we receive
								and verify your payment. <br />
								The invoice payor information and anything you enter in Invoice
								Notes will appear on your invoice. <br />
								Be sure to include your Order ID in your transfer note.
							</p>
						)}

						<OrderSummary>
							<div className="flex flex-col gap-[1rem]">
								<h2>My Cart</h2>
								<p>{itemText}</p>
							</div>

							<div className="flex gap-[3rem]">
								<div className="flex flex-col mt-auto gap-[1rem]">
									<h2 className="whitespace-nowrap ml-auto ">
										Order Total: <b>${commafy(orderAmount)}</b>
									</h2>

									<div className="flex gap-[1rem]">
										<Button
											className="button-light w-[250px] ml-auto"
											to="/entries/cart"
											icon="close"
											iconSize="18px"
										>
											Cancel (Return to Cart)
										</Button>
										<Button
											className="w-[250px]"
											icon={formikProps.isSubmitting ? "" : "check"}
											onClick={() => formikProps.submitForm()}
											disabled={formikProps.isSubmitting || itemCount === 0}
											type="submit"
										>
											{formikProps.isSubmitting ? (
												<LoadingSpinner src={config.assets.loading.primary} />
											) : (
												"Submit Payment"
											)}
										</Button>
									</div>
								</div>
							</div>
						</OrderSummary>

						<PaymentInfo className="mb-[160px] mt-[3rem]">
							<div className="flex gap-[3.5rem]">
								<StyledForm className="twoColumn mb-[1rem]" compact>
									<h2 className="font-semibold">Invoice Payor Information</h2>
									<div className="col-w-100">
										<TextField
											name="fullName"
											placeholder="Full Name*"
											value={formikProps.values.fullName}
										/>
									</div>

									<TextField
										name="email"
										placeholder="Email Address*"
										value={formikProps.values.email}
									/>

									<TextField
										name="phone"
										placeholder="Phone Number*"
										value={formikProps.values.phone}
									/>
									<div className="col-w-100">
										<TextField
											name="companyName"
											placeholder="Company Name*"
											value={formikProps.values.companyName}
										/>
									</div>
									<div className="col-w-100">
										<TextField
											name="companyAddress1"
											placeholder="Address Line 1*"
											value={formikProps.values.companyAddress1}
										/>
									</div>
									<div className="col-w-100">
										<TextField
											name="companyAddress2"
											placeholder="Address Line 2"
											value={formikProps.values.companyAddress2}
										/>
									</div>

									<TextField
										name="companyCity"
										placeholder="Company City*"
										value={formikProps.values.companyCity}
									/>
									<DropdownField
										name="companyState"
										placeholder="Company State"
										options={USStateOptions}
										value={formikProps.values.companyState}
									/>
									<TextField
										name="companyPostalCode"
										placeholder="Company Zip Code"
										value={formikProps.values.companyPostalCode}
									/>
									<DropdownField
										name="companyCountry"
										placeholder="Company Country*"
										options={CountryOptions}
										value={formikProps.values.companyCountry}
									/>
								</StyledForm>

								{props.transactionType == TransactionType.Credit && (
									<StyledForm className="twoColumn mb-[1rem] h-min" compact>
										<h2 className="font-semibold">Credit Card Information</h2>
										<div className="col-w-100">
											<TextField
												name="cardInfo.cardNumber"
												placeholder="Card Number*"
												value={formikProps.values.cardInfo?.cardNumber}
												error={
													formikProps.errors.cardInfo &&
													(formikProps.errors.cardInfo as any).cardNumber
												}
											/>
										</div>

										<TextField
											name="cardInfo.expirationDate"
											placeholder="Expiry Date* (MMYY)"
											value={formikProps.values.cardInfo?.expirationDate}
											error={
												formikProps.errors.cardInfo &&
												(formikProps.errors.cardInfo as any).expirationDate
											}
										/>

										<TextField
											name="cardInfo.cardCode"
											placeholder="CVV*"
											value={formikProps.values.cardInfo?.cardCode}
											error={
												formikProps.errors.cardInfo &&
												(formikProps.errors.cardInfo as any).cardCode
											}
										/>

										<div className="col-w-100">
											<TextField
												className="mb-[1rem]"
												name="notes"
												placeholder="Invoice Notes"
												component="textarea"
												height="200px"
												value={formikProps.values.notes}
											/>
											<p>
												Use this area to enter any information you need to
												include on an invoice: Tax ID, Purchase Order Number, a
												“Date of Delivery”, etc.
											</p>
										</div>
									</StyledForm>
								)}

								{props.transactionType == TransactionType.Check && (
									<div className="col-w-100">
										<TextField
											className="mb-[1rem]"
											name="notes"
											placeholder="Invoice Notes"
											component="textarea"
											height="200px"
											value={formikProps.values.notes}
										/>
										<p>
											Use this area to enter any information you need to include
											on an invoice: Tax ID, Purchase Order Number, a "Date of
											Delivery", etc.
										</p>
									</div>
								)}

								{props.transactionType == TransactionType.Bank && (
									<StyledForm className="oneColumn mb-[1rem]" compact>
										<h2 className="font-semibold">Bank Information</h2>
										<div className="col-w-100">
											<TextField
												name="bankName"
												placeholder="Bank Name"
												value={formikProps.values.bankName}
											/>
										</div>
										<div className="col-w-100">
											<TextField
												name="bankCity"
												placeholder="Bank City"
												value={formikProps.values.bankCity}
											/>
										</div>
										<div className="col-w-100">
											<TextField
												className="mb-[1rem]"
												name="notes"
												placeholder="Invoice Notes"
												component="textarea"
												height="200px"
												value={formikProps.values.notes}
											/>
											<p>
												Use this area to enter any information you need to
												include on an invoice: Tax ID, Purchase Order Number, a
												“Date of Delivery”, etc.
											</p>
										</div>
									</StyledForm>
								)}
							</div>
						</PaymentInfo>
					</div>
				</FormikProvider>
			</UserPageContainer>
		</div>
	);
};

export default Payment;

export interface PaymentProps {
	transactionType: TransactionType;
}

interface LocationState {
	orderTotal: number;
}
