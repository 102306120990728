import { useFormik, FormikProvider } from "formik";
import { useContext, useState } from "react";
import { useTheme } from "styled-components";
import Button from "../../../components/Button/Button";
import TextField from "../../../components/FormFields/TextField";
import { CompanyCredit, IndividualCredit } from "./CreditInterfaces";
import { addCompanyCredit, addIndividualCredit } from "./manageCreditLibrary";
import * as Yup from "yup";
import DropdownField from "../../../components/FormFields/DropdownField";
import { CountryOptions } from "../../../data/CountryData";
import { JobTitleOptions } from "../../../data/JobTitleData";
import { useAlert } from "../../../components/Alert/Alerts";

export const AddIndividualCreditForm = (
	props: AddIndividualCreditFormProps
) => {
	const theme = useTheme();
	const { addNewAlert } = useAlert();
	const [firstValidation, setFirstValidation] = useState(false);
	const initialValues = {
		companyId: props.companyId,
		jobTitle: "",
		otherJobTitle: "",
		companyCity: "",
		fullName: "",
		country: "",
		companyName: "",
		email: "",
		isActive: true,
	};

	let fieldRequired = "This field is required";

	const formikProps = useFormik({
		initialValues: initialValues,
		onSubmit: async (values, { resetForm }) => {
			addIndividualCredit(values)
				.then((response) => {
					if (response.status === 201) {
						props.setIndividualCreditsArray((prev) => [...prev, response.data]);
						resetForm();
						props.onClickHide();
						addNewAlert({
							type: "success",
							message: "Successfully added individual credit",
						});
					}
				})
				.catch(() => {
					addNewAlert({
						type: "error",
						message: "Failed to add individual credit. Please try again later.",
					});
				});
		},
		validationSchema: Yup.object({
			fullName: Yup.string().required(fieldRequired),
			jobTitle: Yup.string().required(fieldRequired),
			otherJobTitle: Yup.string().when("jobTitle", {
				is: "Other",
				then: Yup.string().required(fieldRequired),
			}),
		}),
		validateOnBlur: false,
		validateOnChange: firstValidation,
	});

	return (
		// TO DO: hookup to backend
		<FormikProvider value={formikProps}>
			<div className="mb-[1rem]">
				<h3 className="mb-[.625rem] font-semibold">Add Individual Credit</h3>
				<p>
					Add an individual credit on this form. If you are adding a required
					credit, please make sure the Job Title matches the required credit
					type. Click "Add Credit" when you've entered all the information, and
					drag the new credit from the Credit Library sidebar to the credit
					field.
				</p>
			</div>
			<div className="grid gap-[2.5rem]">
				<TextField name="fullName" placeholder="Name*" />
				<DropdownField
					name="jobTitle"
					placeholder="Job Title*"
					options={JobTitleOptions}
				/>
				{formikProps.values.jobTitle === "Other" && (
					<TextField name="otherJobTitle" placeholder="Preferred Title*" />
				)}
				<TextField name="companyName" placeholder="Company Name" />
				<TextField name="companyCity" placeholder="Company City" />
				<DropdownField
					name="country"
					placeholder="Company Country"
					options={CountryOptions}
				/>
				<TextField name="email" placeholder="Email Address" />
				<Button
					icon="plus"
					iconColor={theme.colorCopyLightLight}
					onClick={() => {
						formikProps.submitForm();
						setFirstValidation(true);
					}}
					type="submit"
				>
					Add Credit
				</Button>
			</div>
		</FormikProvider>
	);
};

export default AddIndividualCreditForm;

export interface AddIndividualCreditFormProps {
	onClickHide(): void;
	setIndividualCreditsArray: React.Dispatch<
		React.SetStateAction<IndividualCredit[]>
	>;
	companyId: number;
}
