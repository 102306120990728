import { useState, useEffect, useContext } from "react";
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";
import { useParams } from "react-router-dom";
import { getToken } from "../components/Auth/handleJWT";
import {
  EntrySetAdminCard,
  GalleryBatchCard,
  JudgingRoundStatus,
} from "../views/Admin/Judging/JudgingInterfaces";
import { LoadingContext } from "../App";

export type UseJuryHubReturnType = {
  debug: boolean;
  programId: string;
  juryId: string;
  entrySetId: string;
  batchId: string;
  entryId: string;
  voteId: string;
  blankEntrySetCard: EntrySetAdminCard;
  connection: HubConnection | null;
  reconnecting: boolean;
  updateVote: (
    voteId: number,
    entrySetId: number,
    batchId: number,
    roundId: number,
    voteOptionId?: number,
    roundType?: string,
    galleryCards?: Array<GalleryBatchCard>
  ) => void;
};

export function useJuryHub(disabled?: boolean): UseJuryHubReturnType {
  const debug = process.env.NODE_ENV === "development";
  const { programId, juryId, entrySetId, batchId, entryId, voteId } =
    useParams<{
      programId: string;
      juryId: string;
      entrySetId: string;
      batchId: string;
      entryId: string;
      voteId: string;
    }>();
  const { setLoadingMessage } = useContext(LoadingContext);

  const blankEntrySetCard: EntrySetAdminCard = {
    id: 0,
    activeEntries: 0,
    activeJurors: 0,
    activeRoundId: 0,
    activeRoundOrder: 0,
    countAbstainAndNoVotes: false,
    votesCast: 0,
    votesPossible: 0,
    isFeedbackRequired: false,
    juryId: 0,
    name: "",
    roundStatus: JudgingRoundStatus.Pending,
    roundType: "",
    totalRounds: 0,
    voteOptions: [],
    entriesLocked: false,
    showControls: false,
    isSingleChoiceVoting: false,
  };

  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [reconnecting, setReconnecting] = useState(false);

  const buildConnection = () => {
    // Build the SignalR connection
    const newConnection = new HubConnectionBuilder()
      .withUrl(
        process.env.REACT_APP_BACKEND_URL + `/juryhub?juryId=${juryId}`,
        {
          accessTokenFactory: () => getToken() || "", // set the JWT token as a bearer token in the request headers
          transport: HttpTransportType.WebSockets,
        }
      )
      .withAutomaticReconnect([
        0, 2000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000,
      ])
      .build();

    newConnection.onreconnecting(() => {
      debug && console.log("Reconnecting...");
      setReconnecting(true);
    });

    newConnection.onreconnected((resp) => {
      debug && console.log("Reconnected!", resp);
      debug && console.log("Existing Connection: ", newConnection);

      newConnection.stop();
      buildConnection();
    });

    newConnection.onclose((error) => {
      debug && console.log("Connection closed.");
    });

    newConnection
      .start()
      .then(() => {
        setConnection(newConnection);
        setReconnecting(false);
      })
      .catch((error) => debug && console.error("Error Happened", error));
  };

  useEffect(() => {
    if (juryId && !disabled) {
      buildConnection();
    }

    // Clean up the connection on unmount
    return () => {
      debug && console.log("Closing connection", juryId);
      if (connection) {
        connection.stop();
        setConnection(null);
      }
    };
  }, [juryId, disabled]);

  useEffect(() => {
    debug && console.log(`Connection Changed`, connection);
    debug && console.log(`Connection state`, connection?.state);
    debug && console.log(`Connection connectionId`, connection?.connectionId);
  }, [connection?.connectionId, connection?.state]);

  function updateVote(
    voteId: number,
    entrySetId: number,
    batchId: number,
    roundId: number,
    voteOptionId?: number,
    roundType?: string,
    galleryCards?: Array<GalleryBatchCard>
  ) {
    if (connection) {
      switch (roundType) {
        case "Grand":
        case "Winner":
          connection.invoke(
            "OneVoteToRuleThemAll",
            voteId,
            Number(juryId),
            entrySetId,
            batchId,
            roundId,
            voteOptionId || null
          );
          break;
        default:
          connection.invoke(
            "UpdateVote",
            voteId,
            Number(juryId),
            entrySetId,
            batchId,
            voteOptionId || null,
            galleryCards
          );
          break;
      }
    }
  }

  // toggles a global overlay loading screen
  useEffect(() => {
    if (reconnecting) {
      setLoadingMessage(
        "You are currently being reconnected to the judging portal. To accelerate the reconnection process, please try refreshing your page to access judging."
      );
    } else {
      setLoadingMessage(null);
    }
  }, [reconnecting]);

  return {
    connection,
    programId,
    juryId,
    blankEntrySetCard,
    entrySetId,
    batchId,
    entryId,
    voteId,
    reconnecting,
    debug,
    updateVote,
  };
}
