import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import cn from "classnames";
import styled from "styled-components";

const PageLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: ${({ theme }) => theme.colorBackgroundMedium}; */
  color: ${({ theme }) => theme.colorActivation};
  font-size: ${({ theme }) => theme.xSmallSize};
  border-style: solid;
  border-color: ${({ theme }) => theme.colorActivation};
  padding: 6px;
  min-width: 30px;
  cursor: pointer;

  &:hover {
    transition: 150ms all;
    color: ${({ theme }) => theme.colorCopyLightLight};
    background: ${({ theme }) => theme.colorActivation};
  }
`;

// https://github.com/gavilanch/React-and-ASP.NET-Core/blob/main/React%2017%20and%20ASP.NET%20Core%206/Module%208%20-%20HTTP/End/react-movies/src/utils/Pagination.tsx
export default function Pagination(props: paginationProps) {
  const [linkModels, setLinkModels] = useState<linkModel[]>([]);

  function selectPage(link: linkModel) {
    if (link.page === props.currentPage) {
      return;
    }

    if (!link.enabled) {
      return;
    }

    props.onChange(link.page);
  }

  function getClass(link: linkModel) {
    if (link.active) {
      return "active pointer";
    }

    if (!link.enabled) {
      return "disabled";
    }

    return "pointer";
  }

  useEffect(() => {
    const previousPageEnabled = props.currentPage !== 1;
    const previousPage = props.currentPage - 1;
    const links: linkModel[] = [];

    links.push({
      text: "First",
      enabled: previousPageEnabled,
      page: 1,
      active: false,
    });

    links.push({
      text: "Previous",
      enabled: previousPageEnabled,
      page: previousPage,
      active: false,
    });

    for (let i = 1; i <= props.totalAmountOfPages; i++) {
      if (
        i >= props.currentPage - props.radio &&
        i <= props.currentPage + props.radio
      ) {
        links.push({
          text: `${i}`,
          active: props.currentPage === i,
          enabled: true,
          page: i,
        });
      }
    }

    const nextPageEnabled =
      props.currentPage !== props.totalAmountOfPages &&
      props.totalAmountOfPages > 0;
    const nextPage = props.currentPage + 1;

    links.push({
      text: "Next",
      page: nextPage,
      enabled: nextPageEnabled,
      active: false,
    });

    links.push({
      text: "Last",
      enabled: nextPageEnabled,
      page: props.totalAmountOfPages,
      active: false,
    });

    setLinkModels(links);
  }, [props.currentPage, props.totalAmountOfPages, props.radio]);

  // set loading to false once pages are loaded
  useEffect(() => {
    if (linkModels.length > 0 && props.setIsLoading) {
      props.setIsLoading(false);
    }
  }, [linkModels]);

  return (
    <div
      className={`flex gap-[.5rem] ${props.className ? props.className : ""}`}
    >
      {linkModels.map((link) => {
        return (
          <PageLink
            id={props.id + link.text}
            key={props.id + link.text}
            onClick={() => {
              props.setIsLoading && props.setIsLoading(true);
              selectPage(link);
            }}
            className={cn({
              "border-2 font-semibold": link.active,
              border: !link.active,
              "opacity-20 pointer-events-none cursor-none": !link.enabled,
            })}
          >
            {link.text}
          </PageLink>
        );
      })}
    </div>
  );
}

interface linkModel {
  page: number;
  enabled: boolean;
  text: string;
  active: boolean;
}

interface paginationProps {
  className?: string;
  id?: string;
  currentPage: number;
  totalAmountOfPages: number;
  radio: number;
  onChange(page: number): void;
  setIsLoading?(isLoading: boolean): void;
}

Pagination.defaultProps = {
  radio: 1,
};
