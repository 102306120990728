import FieldModal from "../../../../components/FieldModal/FieldModal";
import TextField from "../../../../components/FormFields/TextField";
import { LinkFieldTemplate, ProgramLinkField } from "../ProgramInterfaces";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { fieldRequired } from "../../../../components/FieldModal/FieldModal";
import { fieldTemplateValidation } from "../../../../components/FieldModal/FieldModal";
import { newFieldInitialVal } from "../../../../components/FieldModal/FieldModal";

const LinkFieldForm = (props: CustomFieldProps) => {
  const formikProps = useFormik({
    initialValues: {
      // populate existing field values for update form OR use new values for new field form
      ...(props.formData ? { ...props.formData } : { ...newFieldInitialVal }),
      minLinks: props.formData ? props.formData.minLinks : 0,
      maxLinks: props.formData ? props.formData.maxLinks : 1,
    },
    onSubmit: async (value) => {},
    validationSchema: Yup.object({
      ...fieldTemplateValidation,
      minLinks: Yup.number()
        .required(fieldRequired)
        .min(0, "Minimum Links must be greater than or equal to 0"),
      maxLinks: Yup.number()
        .required(fieldRequired)
        .min(
          Yup.ref("minLinks"),
          "Maximum links cannot be less than minimum links"
        ),
    }),
    validateOnChange: false,
    validateOnBlur: false,
  });

  // console.log(
  //   "formikProps.values.minLinks",
  //   formikProps.values.minLinks,
  //   typeof formikProps.values.minLinks
  // );

  return (
    <FormikProvider value={formikProps}>
      <FieldModal
        {...(props.formData && {
          formData: props.formData,
        })}
        formTitle="Link Field Template"
        hideModal={() => props.hideModal()}
        formType="link"
        isUpdateForm={props.formData ? true : false}
      >
        <TextField
          className="min-w-[50%]"
          placeholder="Minimum Number of Links"
          name="minLinks"
          type="number"
          value={formikProps.values.minLinks}
        />
        <TextField
          className="min-w-[50%]"
          placeholder="Maximum Number of Links"
          name="maxLinks"
          type="number"
          value={formikProps.values.maxLinks}
        />
      </FieldModal>
    </FormikProvider>
  );
};

export default LinkFieldForm;

interface CustomFieldProps {
  formData?: LinkFieldTemplate | ProgramLinkField;
  hideModal: () => void;
}
