import Icon from "../../../components/Icon/Icon";
import { ModalCard } from "../../../components/Modal/Modal";
import { FormikProvider, useFormik } from "formik";
import { NavModalType } from "./EntryDetailNav";
import { useTheme } from "styled-components";
import TextField from "../../../components/FormFields/TextField";
import CheckboxField from "../../../components/FormFields/CheckboxField";
import Button from "../../../components/Button/Button";
import { useState, useEffect, useContext } from "react";
import { EntryNote } from "./EntryDetail";
import { createEntryNote, getEntryNotes } from "../../Entries/manageEntry";
import { formatDate } from "../../../utils/dateToString";
import { useAlert } from "../../../components/Alert/Alerts";

export const JurorNotes = (props: {
	notes: Array<EntryNote>;
	selectedJuror?: string;
}) => {
	const theme = useTheme();

	return (
		<div className="mt-4 w-full">
			{props.notes &&
				props.notes.length > 0 &&
				props.notes.flatMap((data, index) => {
					return (props.selectedJuror &&
						props.selectedJuror === data.nameCreatedBy) ||
						!props.selectedJuror ? (
						<div key={index} className="w-full mb-4 pb-4 border-b-2">
							<div className="flex justify-center w-full">
								<div className="flex items-center gap-4 w-full">
									<h3>{data.nameCreatedBy}</h3>
									<div className="flex gap-1 items-center">
										<Icon
											icon={data.isPrivate ? "lock" : "eye"}
											color={theme.colorPrimary}
											width="20px"
											height="20px"
										/>
										<p className="italic">
											{data.isPrivate ? "Private" : "Public"}
										</p>
									</div>
								</div>
								<p className="font-semibold">
									{formatDate(new Date(data.createdDate!))}
								</p>
							</div>
							<p className="mt-2">{data.note}</p>
						</div>
					) : (
						[]
					);
				})}
		</div>
	);
};

const EntryNotesForm = (props: EntryNotesFormProps) => {
	const theme = useTheme();
	const { addNewAlert } = useAlert();

	const blankNote = {
		newNote: "",
		isNewNotePrivate: false,
	};

	const [entryNotes, setEntryNotes] = useState<Array<EntryNote>>([]);

	const handleSubmitNotes = () => {
		// call api to save note
		const payloadForApi: EntryNote = {
			entryId: Number(props.entryId),
			id: 0,
			note: formikProps.values.newNote,
			isPrivate: formikProps.values.isNewNotePrivate,
		};

		createEntryNote(
			Number(props.entryId),
			Number(props.juryId),
			payloadForApi
		).then((response) => {
			if (response.status === 200) {
				// on note save success
				setEntryNotes((prev) => [response.data, ...prev]);
				formikProps.resetForm();
				addNewAlert({ type: "success", message: "Note saved" });
				// props.showModal(false);
			}
		});

		console.log("payloadForApi", payloadForApi);
	};

	useEffect(() => {
		getEntryNotes(Number(props.entryId), Number(props.juryId)).then(
			(response) => {
				if (response.status === 200) {
					setEntryNotes(response.data);
				}
			}
		);
	}, [props.entryId, props.juryId]);

	const formikProps = useFormik({
		initialValues: blankNote,
		enableReinitialize: true,
		onSubmit: async (value) => {},
	});

	const isSaveNoteEnabled = formikProps.values.newNote?.length > 0;

	return (
		<FormikProvider value={formikProps}>
			<ModalCard
				className=" !min-h-fit !max-h-fit"
				title="Notes"
				headerIcons={
					<Icon
						icon="close"
						color={theme.colorPrimary}
						onClick={() => props.showModal(false)}
						width="35px"
						height="35px"
					/>
				}
				iconColor={theme.colorPrimary}
			>
				<div className="flex flex-col gap-[2rem] p-[2rem]">
					<TextField
						name="newNote"
						height="100px"
						component="textarea"
						placeholder="Add Notes"
						// value={formikProps.values.newNote}
					/>
					<div className="flex justify-between items-center">
						<CheckboxField
							className="checkbox-field"
							name="isNewNotePrivate"
							//   checked={formikProps.values.isNewNotePrivate}
							placeholder="Save as a private note. Only you can see."
						/>

						<Button
							onClick={() => handleSubmitNotes()}
							className="mt-auto"
							icon="check"
							disabled={!isSaveNoteEnabled}
						>
							Save
						</Button>
					</div>
					<JurorNotes notes={entryNotes} />
				</div>
			</ModalCard>
		</FormikProvider>
	);
};
export default EntryNotesForm;

interface EntryNotesFormProps {
	showModal(type: false | NavModalType): void;
	entryId: string;
	juryId: string;
}
